import { useCallback, useState } from 'react'

export const useApprovingState = (): [boolean, () => void, () => void] => {
  const [isApproving, changeApprovingState] = useState(false)

  const approving = useCallback(() => changeApprovingState(true), [])

  const denying = useCallback(() => changeApprovingState(false), [])

  return [isApproving, approving, denying]
}
