import { useEffect, useState } from 'react'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useEffectNonNull } from '../../../../../Common/hooks/useEffectNonNull'
import { logger } from '../../../../../Common/log/Log'
import { useQueryParams } from '../../../../../Common/hooks/useQueryParams'
import { useEmailDeactivationMutation } from './useTwoFactorVerifiedMutation'

const defaultErrorText = 'Error during requesting two factor deactivation!'

interface UseEmailDeactivationResult {
  isTokenInvalid: boolean
}

export const useEmailDeactivation = (): UseEmailDeactivationResult => {
  const { token } = useQueryParams()
  const [isTokenInvalid, setValidToken] = useState(false)
  const INVALID_TOKEN_ERR_CODE = '07320'

  const [EmailDeactivationMutation, { error, graphQLErrors }] = useEmailDeactivationMutation()

  useEffectNonNull(() => {
    const invalidToken =
      graphQLErrors?.some((error) => error.errcode === INVALID_TOKEN_ERR_CODE) || false
    setValidToken(invalidToken)
  }, [graphQLErrors])

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [INVALID_TOKEN_ERR_CODE],
    graphQLErrors,
    defaultErrorText
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!')
  }, [error])

  useEffect(() => {
    const deactivate = async () => {
      try {
        await EmailDeactivationMutation({
          input: { authVerificationToken: token }
        })
      } catch (e) {
        logger.error(e)
      }
    }

    deactivate()
  }, [EmailDeactivationMutation, token])

  return { isTokenInvalid }
}
