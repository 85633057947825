/* eslint-disable */

const Text = require('../tools/Text')

// overriding method to set 'transparent' background for textarea when typing
Text.prototype._setShapesInProgress = function (lc) {
  switch (this.currentShapeState) {
    case 'selected':
      return lc.setShapesInProgress([this._getSelectionShape(lc.ctx), this.currentShape])
    case 'editing':
      return lc.setShapesInProgress([this._getSelectionShape(lc.ctx)])
    default:
      return lc.setShapesInProgress([this.currentShape])
  }
}
