import React from 'react'
import SuccessNotification from '../../../../../Components/SuccessNotification/SuccessNotification'

/**
 * Evercast verify email success view
 * @constructor
 */
const VerifyEmailSuccess: React.FC = () => {
  return <SuccessNotification title="Thank you!" button="Go to sign in">Your account is now verified. You can now sign in.</SuccessNotification>
}

export default React.memo(VerifyEmailSuccess)
