const ensureSurroundCodec = (sdp, codec) => {
  return sdp
    .replace(/m=audio 9 UDP\/TLS\/RTP\/SAVPF \d.*/, 'm=audio 9 UDP/TLS/RTP/SAVPF 111')
    .replace('a=mid:audio', codec)
}

export const setMultiChannelSDP = (sdp, offerSdp) => {
  const multiopusSixChannelLine = 'a=rtpmap:111 multiopus/48000/6'
  const multiopusEightChannelLine = 'a=rtpmap:111 multiopus/48000/8'

  if (offerSdp.includes(multiopusSixChannelLine)) {
    // Six channel opus desired - it will have been pruned out when our answer SDP was generated, so add it in.
    return ensureSurroundCodec(
      sdp,
      'a=mid:audio\r\n' +
        'a=rtpmap:111 multiopus/48000/6\r\n' +
        'a=fmtp:111 minptime=10;useinbandfec=1;channel_mapping=0,4,1,2,3,5;num_streams=4;coupled_streams=2'
    )
  } else if (offerSdp.includes(multiopusEightChannelLine)) {
    // Eight channel - same as six
    return ensureSurroundCodec(
      sdp,
      'a=mid:audio\r\n' +
        'a=rtpmap:111 multiopus/48000/8\r\n' +
        'a=fmtp:111 minptime=10;useinbandfec=1;channel_mapping=0,6,1,2,3,4,5,7;num_streams=5;coupled_streams=3'
    )
  }

  // If not 6/8 channel, maybe stereo.
  return setStereoSDP(sdp)
}

export const setStereoSDP = (sdp) => {
  const sdpLines = sdp.split('\r\n')
  // Find opus payload.
  const opusIndex = findLine(sdpLines, 'a=rtpmap', 'opus/48000/2')
  let opusPayload
  if (opusIndex) {
    opusPayload = getCodecPayloadType(sdpLines[opusIndex])
  }
  if (opusPayload === undefined) {
    return sdp
  }
  // Find the payload in fmtp line.
  const fmtpLineIndex = findLine(sdpLines, 'a=fmtp:' + opusPayload.toString())
  if (fmtpLineIndex === null) {
    sdpLines.splice(opusIndex + 1, 0, `a=fmtp:${opusPayload.toString()} stereo=1;sprop-stereo=1`)
  } else {
    sdpLines[fmtpLineIndex] = sdpLines[fmtpLineIndex].concat(`;stereo=1;sprop-stereo=1`)
  }
  // Append stereo=1 to fmtp line.
  // added stereo=1 here for stereo audio
  sdp = sdpLines.join('\r\n')
  return sdp
}

export const setBaselineProfileForH264 = (sdp) => {
  // Matches a 6 character long hexadecimal value after profile property
  const profilePattern = new RegExp('(profile-level-id=)([0-9a-fA-F]{6})')
  const baselineProfile = '$142e01f'
  sdp = sdp.replace(profilePattern, baselineProfile)
  return sdp
}

// Find the line in sdpLines that starts with |prefix|, and, if specified,
// contains |substr| (case-insensitive search).
const findLine = (sdpLines, prefix, substr?) => {
  return findLineInRange(sdpLines, 0, -1, prefix, substr)
}

// Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
// and, if specified, contains |substr| (case-insensitive search).
const findLineInRange = (sdpLines, startLine, endLine, prefix, substr) => {
  const realEndLine = endLine !== -1 ? endLine : sdpLines.length
  for (let i = startLine; i < realEndLine; ++i) {
    if (sdpLines[i].indexOf(prefix) === 0) {
      if (!substr || sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
        return i
      }
    }
  }
  return null
}

// Gets the codec payload type from an a=rtpmap:X line.
const getCodecPayloadType = (sdpLine) => {
  const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+')
  const result = sdpLine.match(pattern)
  return result && result.length === 2 ? result[1] : null
}
