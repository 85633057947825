import { useRoomFeatureFlag } from '../../../../../../../Common/utils/featureFlags'
import { RoomFeatureFlag } from '../../../../../../../Models/apiEntities'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useIsMounted } from '../../../../../../../Common/hooks/useIsMounted'
import { logger } from '../../../../../../../Common/log/Log'
import { Participant } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'

export interface ActiveSpeakerDetectionControl {
  currentlySpeakingParticipant: Participant | null
  enableActiveSpeakerDetection: () => void
  disableActiveSpeakerDetection: () => void
  resetCurrentlySpeakingParticipant: () => void
}

const volumeTreshold = 80
const defaultActiveSpeakerDetectionIntervalMs = 1000

export const useActiveSpeakerDetection = ({ participants }: { participants: Participant[] }) => {
  const isMounted = useIsMounted()
  const [
    currentlySpeakingParticipant,
    setCurrentlySpeakingParticipant
  ] = useState<Participant | null>(null)
  // TODO: wire up feature flag when it's available in API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveSpeakerDetectionFeatureEnabled, customIntervalMs] = useRoomFeatureFlag(
    RoomFeatureFlag.activeSpeakerDetection
  )

  const [isActiveSpeakerDetectionEnabled, setIsActiveSpeakerDetectionEnabled] = useState(true)
  const activeSpeakerDetectionIntervalMs = useMemo(() => {
    if (!customIntervalMs) {
      return defaultActiveSpeakerDetectionIntervalMs
    }

    return parseInt(customIntervalMs)
  }, [customIntervalMs])

  useEffect(() => {
    if (!isActiveSpeakerDetectionEnabled) {
      return
    }

    const interval = setInterval(async () => {
      // TODO: DAMStreams don't have a getVolume. Optional chaining is not idea.
      const allGetVolumePromises = new Array<Promise<number>>(
        ...participants.map((participant) => participant.getVolume?.() || 1)
      )

      try {
        const volumes = await Promise.all(allGetVolumePromises)
        const highestVolume = volumes.reduce((highestVolume, volume) => {
          if (volume > highestVolume && volume > volumeTreshold) {
            highestVolume = volume
          }
          return highestVolume
        }, 0)

        if (!highestVolume) {
          return
        }

        if (isMounted.current) {
          const speakingParticipantIndex = volumes.indexOf(highestVolume)
          const currentlySpeakingParticipant = participants[speakingParticipantIndex]
          setCurrentlySpeakingParticipant(currentlySpeakingParticipant)
        }
      } catch (e) {
        logger.error(e)
      }
    }, activeSpeakerDetectionIntervalMs)

    return () => {
      clearInterval(interval)
    }
  }, [activeSpeakerDetectionIntervalMs, isActiveSpeakerDetectionEnabled, isMounted, participants])

  useEffect(() => {
    logger.log('Currently speaking participant:', currentlySpeakingParticipant)
  }, [currentlySpeakingParticipant])

  const enableActiveSpeakerDetection = useCallback(() => {
    logger.log('Enabling active speaker detection.')
    setIsActiveSpeakerDetectionEnabled(true)
  }, [])

  const disableActiveSpeakerDetection = useCallback(() => {
    logger.log('Disabling active speaker detection.')
    setCurrentlySpeakingParticipant(null)
    setIsActiveSpeakerDetectionEnabled(false)
  }, [])

  const resetCurrentlySpeakingParticipant = useCallback(() => {
    logger.log('Resetting currently speaking participant.')
    setCurrentlySpeakingParticipant(null)
  }, [])

  return useMemo(
    () =>
      ({
        currentlySpeakingParticipant,
        enableActiveSpeakerDetection,
        disableActiveSpeakerDetection,
        resetCurrentlySpeakingParticipant
      } as ActiveSpeakerDetectionControl),
    [
      currentlySpeakingParticipant,
      disableActiveSpeakerDetection,
      enableActiveSpeakerDetection,
      resetCurrentlySpeakingParticipant
    ]
  )
}
