import React from 'react'
import JitSignUpView from '../JitSignUpView/JitSignUpView'
import usePostSSOMessage from './hooks/usePostSSOMessage'
import { SSOVerificationState, useSSOAction } from './hooks/useSSOAction'
import PostSSOError from './PostSSOError/PostSSOError'

/**
 * PostSSO View
 * This component renders after a user unsuccessfully attempts to login with sso
 */
const PostSSO: React.FC = () => {
  const { state } = useSSOAction()
  const { message } = usePostSSOMessage(state)
  //if user does not have account, route user to SSO account creation
  if (state === SSOVerificationState.noAccount) {
    return <JitSignUpView />
  } else {
    return <PostSSOError message={message} />
  }
}

export default React.memo(PostSSO)
