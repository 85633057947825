import { useCallback, useState, useEffect } from 'react'
import { Recording } from '../../janus/clients/liveroom/LiveroomClient'
import { RecordingTimer, RECORDING_TIMER_INTERVAL } from '../RecordingTimer'

export const useRecordingTimer = () => {
  const [recordingTimer] = useState<RecordingTimer>(new RecordingTimer())

  const startRecordingTimer = useCallback(
    (recording: Recording) => {
      const recordingCurrentTimeInSeconds = Math.floor(
        (+recording.currentTime - +recording.recordingStartTime) / RECORDING_TIMER_INTERVAL
      )
      recordingTimer.startTimer({ currentTime: recordingCurrentTimeInSeconds })
    },
    [recordingTimer]
  )

  const stopRecordingTimer = useCallback(() => {
    recordingTimer.stopTimer()
  }, [recordingTimer])

  useEffect(() => {
    return () => stopRecordingTimer()
  }, [stopRecordingTimer])

  return {
    startRecordingTimer,
    stopRecordingTimer,
    getRecordingCurrentTime: recordingTimer.getCurrentTime,
    onRecordingTimerUpdate: recordingTimer.onTimerUpdate
  }
}
