import React, { useEffect, useState } from 'react'
import useApi from '../../../API/useApi'
import { useRoom } from '../../../Providers/RoomProvider'
import { ThumbnailConnectivity } from '../../ThumbnailConnectivity/ThumbnailConnectivity'
import NetworkConnectivityIndicator from '../NetworkConnectivityIndicator'
import { displayNetworkConnectivityStats } from './useDisplayNetworkConnectivityStats'

interface UseGetParticipantStatsProps {
  participantId: string
  isParticipantStreamOnly: boolean
}

export const useGetParticipantConnectivityStats = (props: UseGetParticipantStatsProps) => {
  const { participantId } = props
  const { isParticipantStreamOnly } = props

  const [connectivityStatsProps, setConnectivityStatsProps] = useState({})
  const [shouldShowConnectivityStats, setShouldShowConnectivityStats] = useState(false)

  const api = useApi()
  const room = useRoom()

  useEffect(() => {
    let updateStats
    if (shouldShowConnectivityStats) {
      updateStats = setInterval(() => {
        api.ConnectivityLogs.getLogByStreamId(room.id, participantId).then((result) => {
          const humanReadable = displayNetworkConnectivityStats(result)
          setConnectivityStatsProps(humanReadable)
        })
      }, 10000)
    }
    return () => clearInterval(updateStats)
  }, [shouldShowConnectivityStats, api.ConnectivityLogs, participantId, room.id])

  const getStats = () => {
    setShouldShowConnectivityStats(true)
    api.ConnectivityLogs.getLogByStreamId(room.id, participantId).then((result) => {
      const humanReadable = displayNetworkConnectivityStats(result)
      setConnectivityStatsProps(humanReadable)
    })
  }

  const ThumbnailConnectivityStatsEl = (
    <ThumbnailConnectivity
      close={() => setShouldShowConnectivityStats(false)}
      {...connectivityStatsProps}
    />
  )

  const NetworkConnectivityIndicatorEl = isParticipantStreamOnly ? undefined : (
    <NetworkConnectivityIndicator onClick={getStats} />
  )

  return {
    getStats,
    ThumbnailConnectivityStatsEl,
    shouldShowConnectivityStats,
    NetworkConnectivityIndicatorEl,
  }
}
