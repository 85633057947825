import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Fade, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useDelayedEffect } from '../../../../../../../Common/hooks/useDelayedEffect'
import { PulseLoader } from './PulseLoader'
import { Animations } from '../../../../../../../Common/constants/animations'
import './LiveroomTransition.scss'
import { LiveroomState } from '../hooks/useLiveroomClient'
import { useEffectWithPredicate } from '../../../../../../../Common/hooks/useEffectWithPredicate'
import { LocalDevicesActions, LocalDevicesState } from '../../hooks/useLocalDevicesState'
import { useSyncLocalDevicesStateWithEntryAnimation } from './hooks/useSyncLocalDevicesStateWithEntryAnimation'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'

const times = {
  loaderEnds: 0,
  squareDuration: 1500,
}

interface Keyframe {
  opacity?: number
  transform?: string
  offset: number
}

interface IOrigin {
  x: number
  y: number
}

interface IAnimatedTransition {
  color?: string
  duration?: number
  keyframes?: Keyframe[]
  origin?: IOrigin
}

interface SVGAnimateElement extends SVGElement {
  beginElement(): SVGElement
}

/**
 * The base implementation of `_.clamp` which doesn't coerce arguments.
 *
 * @private
 * @param {number} number The number to clamp.
 * @param {number} [lower] The lower bound.
 * @param {number} upper The upper bound.
 * @returns {number} Returns the clamped number.
 */

const parseOrigin: any = ({ x, y }: any) =>
  // @ts-expect-error
  `${parseInt(x * 100, 10)}% ${parseInt(y * 100, 10)}%`

const defaultOrigin = {
  x: 0.5,
  y: 0.5
}

const defaultKeyframes = [
  {
    opacity: 0.0,
    transform: `scale(0.1)`,
    offset: 0.0
  },
  {
    offset: 0.05,
    opacity: 1
  },
  {
    offset: 0.8,
    opacity: 1
  },
  {
    offset: 1.0,
    opacity: 0,
    transform: `scale(1.5)`
  }
]

export const AnimatedTransition: React.FC<IAnimatedTransition> = ({
  color = '#e73701',
  duration = times.squareDuration,
  origin = defaultOrigin,
  keyframes = defaultKeyframes
}) => {
  const active = useRef<boolean>(false)
  const animation = useRef<Animation>(null!)
  const wrapper = useRef<any>(null!)
  const svgAnimation = useRef<SVGAnimateElement>(null!)

  useEffect(() => {
    if (active.current) {
      animation.current.cancel()
    }
    active.current = true
    animation.current = wrapper.current.animate(keyframes, {
      duration,
      iterations: 1,
      easing: 'cubic-bezier(0.76, 0, 0.24, 1)',
      fill: 'forwards'
    })
    animation.current.onfinish = () => {
      active.current = false
    }
    svgAnimation.current.beginElement()
    return () => {
      if (animation.current) {
        animation.current.cancel()
      }
    }
  }, [active, animation, wrapper, svgAnimation, keyframes, duration])

  return (
    <svg
      className="entry-container"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1={0}
          y1={0.5}
          x2={0}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0" stopColor={color} stopOpacity="0">
            <animate
              ref={svgAnimation}
              attributeName="offset"
              values={`0.9 ; 0.3; 0.3; 0.3`}
              dur={`${duration}ms`}
              min={`${duration}ms`}
              max={`${duration}ms`}
              repeatCount="1"
              repeatDur={`${duration}s`}
              fill="freeze"
              begin="indefinite"
            />
          </stop>
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="linear-gradient-1"
          gradientTransform={`translate(0, 0) scale(${origin.y / 0.5}) `}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-2"
          gradientTransform={`translate(0, 0) scale(${origin.x / 0.5}) rotate(-90)`}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          gradientTransform={`translate(0, 1) rotate(180) scale(${(1.0 - origin.y) * 2})`}
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          gradientTransform={`translate(1, 0) scale(${(1.0 - origin.x) * 2}) rotate(90)`}
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g
        ref={wrapper}
        style={{
          animationDuration: `${duration}ms`,
          transformOrigin: parseOrigin(origin)
        }}
        className={'inner'}
      >
        <polygon
          className={'gradient-1'}
          points={`${origin.x} ${origin.y} ${origin.x} ${origin.y} 0 0 1 0 ${origin.x} ${origin.y}`}
        />
        <polygon
          className={'gradient-2'}
          points={`${origin.x} ${origin.y} ${origin.x} ${origin.y} 0 1 0 0 ${origin.x} ${origin.y}`}
        />
        <polygon
          className={'gradient-3'}
          points={`${origin.x} ${origin.y} ${origin.x} ${origin.y} 1 1 0 1 ${origin.x} ${origin.y}`}
        />
        <polygon
          className={'gradient-4'}
          points={`${origin.x} ${origin.y} ${origin.x} ${origin.y} 1 0 1 1 ${origin.x} ${origin.y}`}
        />
      </g>
    </svg>
  )
}


const Master = styled.div<{ visible?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  transform: scale(1.2);
  ${({ visible }) => `
    transform: scale(${visible ? 1.0 : 1.2});
    opacity: ${visible ? 1.0 : 0.0};
  `}
`

const LoadingAnimation = ({ isReady, onExited }) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    setPercentage(100)
    const intervalId = setInterval(() => {
      setPercentage(prevValue => {
        if (prevValue >= 100) {
          return 0
        }
        return 100
      })
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  useDelayedEffect(
    {
      delay: 400,
      predicate: () => isReady,
      effect: () => onExited()
    },
    [isReady]
  )

  return (
    <Master visible={!isReady}>
      <PulseLoader percentage={percentage} />
    </Master>
  )
}

const WelcomeAnimation = ({ onExited }) => {
  useDelayedEffect(
    {
      delay: times.squareDuration,
      effect: () => onExited()
    },
    []
  )
  return (
    <>
      <AnimatedTransition />
    </>
  )
}

const BackButton = ({ }) => {
  const history = useHistory()

  return (
    <Button
      onClick={() => { history.push(routes.dashboard.path) }}
      startIcon={< ArrowBack />}
      style={{ marginTop: 35, marginLeft: 35, border: '1px solid rgba(177, 177, 177, 0.5)' }}
    >
      <Typography variant="button">
        Back
      </Typography>
    </Button >
  )
}

interface LiveRoomTransitionProps {
  liveroomState: LiveroomState
  localDevicesState: LocalDevicesState
  localDevicesActions: LocalDevicesActions
  children: React.ReactElement<any, any>
}

export enum TransitionState {
  loading,
  welcome,
  liveroom
}

export const LiveRoomTransition: React.FC<LiveRoomTransitionProps> = ({
  liveroomState,
  localDevicesActions,
  localDevicesState,
  children
}) => {
  const [isReady, setIsReady] = useState(false)
  const [transitionState, setTransitionState] = useState(TransitionState.loading)

  useSyncLocalDevicesStateWithEntryAnimation({
    localDevicesState,
    localDevicesActions,
    transitionState
  })

  useEffectWithPredicate(
    {
      predicate: () => liveroomState === LiveroomState.joined,
      effect: () => setIsReady(true)
    },
    [liveroomState]
  )

  return (
    <>
      <Fade in={transitionState === TransitionState.liveroom} timeout={Animations.regular}>
        {children}
      </Fade>
      {transitionState !== TransitionState.liveroom && (
        <>
          {transitionState === TransitionState.loading && (
            <Box width="100%" height="100%" position="absolute" left={0} top={0} zIndex={6}>
              <BackButton />
            </Box>
          )}
          <Box width="100%" height="100%" position="absolute" left={0} top={0} zIndex={5}>
            {transitionState === TransitionState.loading && (
              <LoadingAnimation
                isReady={isReady}
                onExited={() => setTransitionState(TransitionState.welcome)}
              />
            )}
            {transitionState === TransitionState.welcome && (
              <WelcomeAnimation onExited={() => setTransitionState(TransitionState.liveroom)} />
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default React.memo(LiveRoomTransition)
