import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { Animations } from '../../../../../../../Common/constants/animations'
import LiveRoomControlBar from '../LiveRoomControlBar/LiveRoomControlBar'
import HorizontalLayout from '../../../../../../../Components/HorizontalLayout/HorizontalLayout'
import MouseOverSlide from '../../../../../../../Components/MouseOverSlide/MouseOverSlide'
import RightDrawer from '../RightDrawer/RightDrawer'
import DrawToolDrawer from '../LiveRoomDrawer/LiveRoomDrawer'
import MainVideoStream from '../MainVideoStream/MainVideoStream'
import ParticipantsList from '../ParticipantsList/ParticipantsList'
import { useLiveRoomDefaultLayout } from './hooks/useLiveRoomDefaultLayout'
import SlideAndPush from '../../../../../../../Components/SlideAndPush/SlideAndPush'
import { BaseDialog } from '../../../../../../../Components/Dialogs/BaseDialog/BaseDialog'
import { useDialog } from '../../../../../../../Components/RoomDialog/hooks/useDialog'
import { usePPTShortcut } from '../../../DeviceSelectionDialog/hooks/usePTTShortcut'
import Checkbox from '@material-ui/core/Checkbox'
import {
  AppleTVLoginControl,
  AudioControl,
  AudioMixerControl,
  BrowserStreamControl,
  CameraControl,
  DeviceSelectionControl,
  DnDAccessControl,
  EbsKeysControl,
  FullScreenModeControl,
  InstantHelpControl,
  MicrophoneControl,
  RecordingControl,
  RoomAccessControl,
  RoomSettingsControl,
  ScreenShareControl,
  StreamSelectionControl,
  ThumbnailsControl,
} from '../hooks/useLiveroom'
import { LiveroomParticipant } from '../hooks/useLiveroomParticipants'
import { LiveroomPublisher } from '../hooks/useLiveroomPublisher'
import { useDrawCanvasToolsContext } from '../../../../../../../Providers/DrawCanvasToolsProvider'
import { ChatDecisionInfo, NoteDecisionInfo } from '../hooks/useLiveroomPermissions'
import useUploadConnectivityInfo from '../hooks/useUploadConnectivityInfo'
import usePermissionsRest, { PermissionType } from '../../../../../../../Common/hooks/usePermissionsRest'
import { useRoomFeatureFlagsRest } from '../../../../../../../Common/utils/featureFlags'
import PeopleListContainer from '../../../../../../../Components/PeopleList/PeopleListContainer'

const DRAWER_WIDTH = 240
interface Props {
  participants: LiveroomParticipant[]
  publisher: LiveroomPublisher
  room: {
    id: string
    isPublic: boolean
    hash: string
    displayName: string
  }
  currentProfile: {
    id: string
    displayName: string
    email: string | null
  }
  streamSelection: StreamSelectionControl
  camera: CameraControl
  microphone: MicrophoneControl
  audio: AudioControl
  fullScreenMode: FullScreenModeControl
  deviceSelection: DeviceSelectionControl
  roomAccess: RoomAccessControl
  roomSettings: RoomSettingsControl
  ebsKeys: EbsKeysControl
  screenShare: ScreenShareControl
  browserStream: BrowserStreamControl
  recording: RecordingControl
  instantHelp: InstantHelpControl
  thumbnails: ThumbnailsControl
  chatPermissionInfo: ChatDecisionInfo
  notePermissionInfo: NoteDecisionInfo
  appleTVLogin: AppleTVLoginControl
  audioMixer: AudioMixerControl
  participantsManager
  kickPermissionInfo
  mutePermissionInfo
  dndControl: DnDAccessControl
  canPublishInfo
}

const LiveRoomDefaultLayout: React.FC<Props> = ({
  streamSelection,
  room,
  currentProfile,
  participants,
  participantsManager,
  publisher,
  camera,
  microphone,
  audio,
  fullScreenMode,
  deviceSelection,
  roomSettings,
  roomAccess,
  ebsKeys,
  screenShare,
  browserStream,
  recording,
  instantHelp,
  thumbnails,
  chatPermissionInfo,
  notePermissionInfo,
  appleTVLogin,
  audioMixer,
  kickPermissionInfo,
  mutePermissionInfo,
  dndControl,
  canPublishInfo
}) => {
  const {
    displayFormat: { isLargeDesktop, isDesktop },
    actions: {
      chat: { handleChatClick, handleChatNotification },
      handleDrawerClick,
      setIsThumbnailsScrollbarVisible,
      setIsToolbarShowAlways,
      setIsParticipantListOpen,
      setIsChatOpenDesktop,
      handleParticipantListClick,
    },
    state: {
      chat: { isChatOpen, hasChatNotification },
      isDrawerOpen,
      isThumbnailOpen,
      isThumbnailsScrollbarVisible,
      isToolbarShowAlways,
      isParticipantListOpen,
    },
  } = useLiveRoomDefaultLayout({ thumbnails })

  const { setVideoNode, setLocalCanvasNode, setParticipantCanvasNode } = useDrawCanvasToolsContext()
  const [isOpen, openDialog, closeDialog] = useDialog()
  const [dontShowPTT, setDontShowPTT] = useState(false)
  const { PPTKeyName } = usePPTShortcut({ currentMediaSelection: microphone })
  const { authorize } = usePermissionsRest()
  const hasInvitePermission = authorize(room, PermissionType.ROOM_INVITE_CREATE)
  const hasClearAllDrawingPermission = authorize(room, PermissionType.ROOM_DRAWING_CLEAR_ALL)
  const { ALLOW_ROOM_DRAW_TOOL: shouldAllowRoomDrawTool } = useRoomFeatureFlagsRest()

  const theme = useTheme()
  const chatWidth = isLargeDesktop ? 480 : isDesktop ? 380 : 320

  useEffect(() => {
    let initialPTTShowStatus
    if (localStorage.getItem('dontShowPTT') !== null) {
      initialPTTShowStatus = localStorage.getItem('dontShowPTT')
    } else {
      initialPTTShowStatus = 'false'
    }

    // if PTT set to "None" make sure localStorage value is reset
    if (PPTKeyName === 'None' || !PPTKeyName) {
      localStorage.setItem('dontShowPTT', 'false')
    }
    if (PPTKeyName && PPTKeyName !== 'None' && initialPTTShowStatus !== 'true') {
      setTimeout(() => {
        openDialog()
      }, 5000)
    }
  }, [PPTKeyName, openDialog])

  const handleCloseDialog = () => {
    const PTTstatus = dontShowPTT ? 'true' : 'false'
    localStorage.setItem('dontShowPTT', PTTstatus)
    closeDialog()
  }

  const chat = useMemo(() => {
    return {
      isAvailable: chatPermissionInfo.isAvailable && chatPermissionInfo.isAllowed,
      isOpened: isChatOpen,
      hasNotification: hasChatNotification,
      toggleVisibility: handleChatClick,
    }
  }, [isChatOpen, handleChatClick, hasChatNotification, chatPermissionInfo.isAvailable, chatPermissionInfo.isAllowed])

  const drawTool = useMemo(() => {
    return {
      isAvailable: shouldAllowRoomDrawTool && canPublishInfo.isAllowed,
      isOpened: isDrawerOpen,
      toggleVisibility: handleDrawerClick,
      canClearAll: hasClearAllDrawingPermission,
    }
  }, [isDrawerOpen, handleDrawerClick, shouldAllowRoomDrawTool, canPublishInfo.isAllowed, hasClearAllDrawingPermission])

  const participantList = useMemo(() => {
    return {
      isAvailable: true,
      isOpened: isParticipantListOpen,
      toggleVisibility: handleParticipantListClick,
    }
  }, [isParticipantListOpen, handleParticipantListClick])

  const toolbar = useMemo(() => {
    return {
      isShowAlways: isToolbarShowAlways,
      toggleVisibility: () => setIsToolbarShowAlways(!isToolbarShowAlways),
    }
  }, [isToolbarShowAlways, setIsToolbarShowAlways])

  useUploadConnectivityInfo(currentProfile, publisher.id)

  return (
    <HorizontalLayout height="100%" width="100%" overflow="hidden">
      <BaseDialog isOpen={isOpen} fullWidth={true} maxWidth="xs" classNames="">
        Push-To-Talk is currently enabled, press {PPTKeyName?.toUpperCase()} to unmute when talking.
        <Box
          display="flex"
          justifyContent="space-between"
          paddingTop={2}
          style={{ marginLeft: '-9px' }}
        >
          <Typography variant="body2" color="textPrimary">
            <Checkbox
              checked={dontShowPTT}
              onChange={() => setDontShowPTT(!dontShowPTT)}
              name="no-show"
            />
            Don't show again
          </Typography>
          <Button
            className={classnames('progress-button')}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Close
          </Button>
        </Box>
      </BaseDialog>
      <SlideAndPush
        isOpen={isDrawerOpen}
        width={DRAWER_WIDTH}
        height="100%"
        from="left"
        position="fixed"
        zIndex={2}
        timeout={Animations.fastest}
      >
        <DrawToolDrawer onClickCloseBtn={handleDrawerClick} canClearAll={drawTool.canClearAll} />
      </SlideAndPush>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={100}
        minWidth={0}
        minHeight={0}
        maxHeight="100%"
        position="relative"
      >
        <SlideAndPush
          isOpen={isThumbnailOpen}
          height={160 + (isThumbnailsScrollbarVisible ? theme.spacing() * 2 : 0)}
          width="100%"
          from="top"
          position="relative"
          zIndex={2}
          timeout={Animations.fastest}
        >
          <ParticipantsList
            isVisible={isThumbnailOpen}
            publisher={publisher}
            participants={participants}
            participantsManager={participantsManager}
            streamSelection={streamSelection}
            liveroomAudioEnabled={audio.isEnabled}
            setIsThumbnailsScrollbarVisible={setIsThumbnailsScrollbarVisible}
            isDrawerOpen={isDrawerOpen}
            drawerWidth={DRAWER_WIDTH}
            isPublisherMuted={!microphone.isEnabled}
            kickPermissionInfo={kickPermissionInfo}
            mutePermissionInfo={mutePermissionInfo}
            canPublishInfo={canPublishInfo}
          />
        </SlideAndPush>
        <MainVideoStream
          stream={streamSelection}
          isDrawerOpen={isDrawerOpen}
          participants={participants}
          canPublish={canPublishInfo.isAllowed}
          setVideoNode={setVideoNode}
          setLocalCanvasNode={setLocalCanvasNode}
          setParticipantCanvasNode={setParticipantCanvasNode}
          setIsToolbarShowAlways={setIsToolbarShowAlways}
          setIsParticipantListOpen={setIsParticipantListOpen}
          setIsChatOpenDesktop={setIsChatOpenDesktop}
        />
        <SlideAndPush
          isOpen={isDrawerOpen}
          height={88}
          width="100%"
          from="bottom"
          position="relative"
          zIndex={2}
          timeout={Animations.fastest}
        />
        <Box position="absolute" width="100%" bottom={0} left={0} zIndex={3}>
          <MouseOverSlide keepOpen={isToolbarShowAlways}>
            <LiveRoomControlBar
              chat={chat}
              drawTool={drawTool}
              participantList={participantList}
              thumbnails={thumbnails}
              toolbar={toolbar}
              camera={camera}
              microphone={microphone}
              audio={audio}
              fullScreenMode={fullScreenMode}
              deviceSelection={deviceSelection}
              roomAccess={roomAccess}
              screenShare={screenShare}
              browserStream={browserStream}
              ebsKeys={ebsKeys}
              roomSettings={roomSettings}
              recording={recording}
              instantHelp={instantHelp}
              appleTVLogin={appleTVLogin}
              audioMixer={audioMixer}
              roomId={room.id}
              roomName={room.displayName}
              hasInvitePermission={hasInvitePermission}
              isPublic={room.isPublic}
              roomHash={room.hash}
              currentProfile={currentProfile}
              participantsManager={participantsManager}
              mutePermissionInfo={mutePermissionInfo}
              dndControl={dndControl}
              showRecordingsClips={recording.canWatchList}
              participants={participants}
            />
          </MouseOverSlide>
        </Box>
      </Box>
      <SlideAndPush
        isOpen={isChatOpen}
        width={chatWidth}
        height="100%"
        from="right"
        position="fixed"
        zIndex={2}
        timeout={Animations.fastest}
      >
        <RightDrawer
          room={room}
          currentProfile={currentProfile}
          isVisible={isChatOpen}
          handleChatNotification={handleChatNotification}
          chatPermissionInfo={chatPermissionInfo}
          notePermissionInfo={notePermissionInfo}
        />
      </SlideAndPush>
      <SlideAndPush
        isOpen={isParticipantListOpen}
        width={chatWidth}
        height="100%"
        from="right"
        position="fixed"
        zIndex={2}
        timeout={Animations.fastest}
      >
        <PeopleListContainer
          kickPermissionInfo={kickPermissionInfo}
          participantsManager={participantsManager}
          participants={participants}
          mutePermissionInfo={mutePermissionInfo}
          currentProfile={{ ...currentProfile, canPublish: canPublishInfo.isAllowed, type: null }}>
        </PeopleListContainer>
      </SlideAndPush>
    </HorizontalLayout>
  )
}

export default React.memo(LiveRoomDefaultLayout)
