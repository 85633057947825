import { useMemo } from 'react'
import { routes } from '../../../../../Views/MainView/AuthenticatedView/routes'
import { Message } from '../../../../../Models/apiEntities'
import { format, setSeconds, parseJSON } from 'date-fns'
import Autolinker from 'autolinker'
import { useRoom } from '../../../../../Providers/RoomProvider'

const playbackPathWithTime = (roomId, sessionId, time) => {
  const {
    recordings: {
      path: recordingsPath,
      playback: { path: playbackPath }
    }
  } = routes
  return `${recordingsPath}${playbackPath}?startTime=${time}`
    .replace(':roomId', roomId)
    .replace(':sessionId', sessionId)
}

const LINK_MAX_LENGTH = 80

export const useChatMessageItem = (message: Message) => {
  const { sessionTime, sessionId, createdAt, updatedAt, profileDisplayName, content } = message

  const room = useRoom()

  const formattedRecordingTime = useMemo(() => {
    const today = new Date()
    return sessionTime
      ? format(
          setSeconds(
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            sessionTime
          ),
          'HH:mm:ss'
        )
      : null
  }, [sessionTime])

  const playbackLink = useMemo(
    () => (sessionId ? playbackPathWithTime(room.id, sessionId, sessionTime) : null),
    [sessionId, sessionTime, room]
  )

  const formattedMessageDate = useMemo(
    () => (createdAt ? format(parseJSON(createdAt), "MMM dd 'at' hh:mm a") : null),
    [createdAt]
  )

  const messageAuthorName = profileDisplayName || 'Participant'

  const parsedContent = useMemo(
    () =>
      Autolinker.link(content, {
        phone: false,
        truncate: { length: LINK_MAX_LENGTH, location: 'end' },
        stripPrefix: false,
        sanitizeHtml: true
      }),
    [content]
  )

  const isEdited = updatedAt && updatedAt !== createdAt

  return {
    hasPlaybackLink: !!(playbackLink && formattedRecordingTime),
    playbackLink,
    formattedRecordingTime,
    formattedMessageDate,
    messageAuthorName,
    parsedContent,
    isEdited
  }
}
