/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteInviteByIdInput = {
    clientMutationId?: string | null;
    id: unknown;
};
export type DeleteRoomInviteMutationVariables = {
    input: DeleteInviteByIdInput;
    id: unknown;
};
export type DeleteRoomInviteMutationResponse = {
    readonly deleteInviteById: {
        readonly deletedInviteId: string | null;
        readonly query: {
            readonly liveroomById: {
                readonly hash: unknown;
                readonly invitesByRoomId: {
                    readonly nodes: ReadonlyArray<{
                        readonly email: string | null;
                        readonly id: unknown;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type DeleteRoomInviteMutation = {
    readonly response: DeleteRoomInviteMutationResponse;
    readonly variables: DeleteRoomInviteMutationVariables;
};



/*
mutation DeleteRoomInviteMutation(
  $input: DeleteInviteByIdInput!
  $id: UUID!
) {
  deleteInviteById(input: $input) {
    deletedInviteId
    query {
      liveroomById(id: $id) {
        hash
        invitesByRoomId {
          nodes {
            email
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteInvitePayload",
    "kind": "LinkedField",
    "name": "deleteInviteById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedInviteId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              }
            ],
            "concreteType": "Liveroom",
            "kind": "LinkedField",
            "name": "liveroomById",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InvitesConnection",
                "kind": "LinkedField",
                "name": "invitesByRoomId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Invite",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRoomInviteMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteRoomInviteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c46d6c5860193a01a712e96919f1ce6e",
    "id": null,
    "metadata": {},
    "name": "DeleteRoomInviteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRoomInviteMutation(\n  $input: DeleteInviteByIdInput!\n  $id: UUID!\n) {\n  deleteInviteById(input: $input) {\n    deletedInviteId\n    query {\n      liveroomById(id: $id) {\n        hash\n        invitesByRoomId {\n          nodes {\n            email\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b405bad15248d0b4e22534e25c2b6f3a';
export default node;
