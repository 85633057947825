import React from 'react'

import OpenRoomLogin from './OpenRoomLogin/OpenRoomLogin'
import { Redirect } from 'react-router-dom'
import useRedirectUser from '../../../../../Components/LoginForm/hooks/useRedirectUser'
import { useQueryParams } from '../../../../../Common/hooks/useQueryParams'

export interface RoomLoginProps {
  onLogin: () => void
  isPublic: boolean | null
  hash: string
}

/**
 * Displays a proper login form depending whether the room is open or private.
 * @param isPublic
 * @param onLogin
 * @param hash
 * @constructor
 */
const RoomLogin: React.FC<RoomLoginProps> = ({ isPublic, onLogin, hash }) => {
  const { makeRedirectQuery } = useRedirectUser()
  const { invite } = useQueryParams()

  if (isPublic || invite) {
    return <OpenRoomLogin onLogin={onLogin} />
  }

  return (
    <Redirect
      to={{
        pathname: '/',
        search: makeRedirectQuery() + '&private-room=true'
      }}
    />
  )
}

export default React.memo(RoomLogin)
