import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../Dialogs'

interface KickFromRoomConfirmationProps {
  name: string
  isOpen?: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

/**
 * Displays a ConfirmationDialog. A dialog with a text header and only two options (yes or no)
 * @constructor
 */
const KickFromRoomConfirmationDialog: React.FC<KickFromRoomConfirmationProps> = ({
  name = 'this participant',
  isOpen = false,
  onConfirm,
  onCancel
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      disableEscapeKeyDown={true}
      header={
        <DefaultDialogHeader
          text={'Remove from room'}
          divider
          onClose={onCancel}
        ></DefaultDialogHeader>
      }
      footer={
        <DefaultDialogFooter
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmButtonText="Remove"
          cancelButtonText="Cancel"
          cancelButtonProps={{
            variant: 'outlined',
          }}
          confirmButtonProps={{
            variant: 'contained',
            color: 'secondary',
          }}
        />
      }
    >
      Remove {name} from this room, effective immediately?
    </BaseDialog>
  )
}

export default React.memo(KickFromRoomConfirmationDialog)
