import { graphql } from 'babel-plugin-relay/macro'

const deleteRoomInviteMutation = graphql`
  mutation DeleteRoomInviteMutation($input: DeleteInviteByIdInput!, $id: UUID!) {
    deleteInviteById(input: $input) {
      deletedInviteId

      query {
        liveroomById(id: $id) {
          hash

          invitesByRoomId {
            nodes {
              email
              id
            }
          }
        }
      }
    }
  }
`
export default deleteRoomInviteMutation
