import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { useEnvironment } from '../../Providers/EnvironmentProvider'

export const useCompanySsoRedirect = () => {
  const { search } = useLocation()
  const query = qs.parse(search)

  delete query['company-sso-error']
  delete query['not-company-sso']
  delete query.email
  delete query.noBrowserCheck
  if (query.noBrowserCheck) {
    query.noBrowserCheck = 'true'
  }

  const redirectQuery = qs.stringify(query)
  const authURL = useEnvironment().authUrl

  return {
    redirect: (email) => {
      window.location.href =
        authURL + '/sso/login/' + email + (redirectQuery ? '?' + redirectQuery : '')
    }
  }
}
