import React, { useState, useEffect, useRef } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { GraphQLTaggedNode } from 'relay-runtime'
import { useLazyLoadQuery } from 'react-relay/lib/relay-experimental'
import { ExportType } from './DownloaderContainer'
import { Message } from '../../../../Models/apiEntities'
import moment from 'moment'

const notesQuery = graphql`
  query NoteMessagesQuery($roomHash: UUID!, $condition: ExportMessagesCondition) {
    getNoteMessageByRoomHash(roomHash: $roomHash, condition: $condition, orderBy: "created_at desc") {
      nodes {
        profileDisplayName
        createdAt
        content
      }
    }
  }
`

export interface NoteMessagesProps {
  roomHash: string
  exportType: ExportType
  startDate: string
  endDate?: string
  fetchKey?: number
  onQueryLoad?: (messages: Message[]) => void
  fileName?: string
  fileContents?: string
}

const NoteMessages: React.FC<NoteMessagesProps> = (props) => {
  const downloadNoteEl = useRef<HTMLAnchorElement | null>(null)
  const [contents, setContents] = useState('')
  const [name, setName] = useState('')
  const { roomHash, onQueryLoad, fileName, fetchKey, fileContents, ...condition } = props

  const endDate = condition.endDate || condition.startDate
  const startDate = moment(condition.startDate+'T00:00:00').subtract({ seconds: (fetchKey! % 2) }).format('Y-MM-DDTHH:mm:ss')
  const variables = {
    roomHash,
    condition: {
      duration: {
        start: {value:startDate, inclusive: true},
        end: {value:endDate+'T23:59:59', inclusive: true}
      }
    }
  }
  const data = useLazyLoadQuery<GraphQLTaggedNode>(notesQuery, variables, {
    fetchKey //need to specify unique key to rerender query
  })

  useEffect(() => {
    if (data.getNoteMessageByRoomHash !== null) {
      const ext = condition.exportType === ExportType.CSV ? '.csv' : '.txt'
      const _fileName = fileName || condition.exportType+'-NoteDownload-'+moment().format('MMDDYHHmmss')+ext
      setName(_fileName)
      if (onQueryLoad !== undefined) {
        onQueryLoad(data.getNoteMessageByRoomHash.nodes)
      }
    } else if (onQueryLoad !== undefined) {
      onQueryLoad([])
    }

  }, [data, fileContents, fileName, condition, onQueryLoad])

  useEffect(() => {
    if (fileContents) {
      setContents(fileContents)
    }
  }, [fileContents, setContents])

  useEffect(() => {
    if (downloadNoteEl !== null && contents) {
      downloadNoteEl!.current!.click()
      setContents('')
    }
  }, [downloadNoteEl, contents])

  return <a 
   ref={downloadNoteEl} download={name}
   href={contents}
   style={{height:1, width:1}}> </a>
}

export default React.memo(NoteMessages)