import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M13.1929 16H13.9266C14.0239 16 14.1172 15.9605 14.186 15.8902C14.2548 15.8198 14.2935 15.7245 14.2935 15.625V8.875C14.2935 8.77554 14.2548 8.68016 14.186 8.60984C14.1172 8.53951 14.0239 8.5 13.9266 8.5H13.1929C13.0956 8.5 13.0023 8.53951 12.9335 8.60984C12.8647 8.68016 12.8261 8.77554 12.8261 8.875V15.625C12.8261 15.7245 12.8647 15.8198 12.9335 15.8902C13.0023 15.9605 13.0956 16 13.1929 16ZM18.2065 5.5H15.6872L14.6478 3.72813C14.5174 3.50594 14.3328 3.32209 14.1122 3.19449C13.8916 3.06688 13.6424 2.99988 13.3889 3H10.3068C10.0534 2.99998 9.8043 3.06704 9.58379 3.19464C9.36329 3.32224 9.17886 3.50603 9.04847 3.72813L8.00846 5.5H5.48913C5.3594 5.5 5.23499 5.55268 5.14326 5.64645C5.05153 5.74022 5 5.86739 5 6V6.5C5 6.63261 5.05153 6.75979 5.14326 6.85355C5.23499 6.94732 5.3594 7 5.48913 7H5.97826V17.5C5.97826 17.8978 6.13286 18.2794 6.40805 18.5607C6.68324 18.842 7.05648 19 7.44565 19H16.25C16.6392 19 17.0124 18.842 17.2876 18.5607C17.5628 18.2794 17.7174 17.8978 17.7174 17.5V7H18.2065C18.3362 7 18.4607 6.94732 18.5524 6.85355C18.6441 6.75979 18.6957 6.63261 18.6957 6.5V6C18.6957 5.86739 18.6441 5.74022 18.5524 5.64645C18.4607 5.55268 18.3362 5.5 18.2065 5.5ZM10.2533 4.59094C10.2696 4.56312 10.2927 4.54012 10.3204 4.52418C10.348 4.50824 10.3793 4.49991 10.411 4.5H13.2846C13.3163 4.49996 13.3475 4.50832 13.3751 4.52426C13.4027 4.54019 13.4258 4.56317 13.4421 4.59094L13.9758 5.5H9.7198L10.2533 4.59094ZM16.25 17.5H7.44565V7H16.25V17.5ZM9.76902 16H10.5027C10.6 16 10.6933 15.9605 10.7621 15.8902C10.8309 15.8198 10.8696 15.7245 10.8696 15.625V8.875C10.8696 8.77554 10.8309 8.68016 10.7621 8.60984C10.6933 8.53951 10.6 8.5 10.5027 8.5H9.76902C9.67173 8.5 9.57842 8.53951 9.50962 8.60984C9.44082 8.68016 9.40217 8.77554 9.40217 8.875V15.625C9.40217 15.7245 9.44082 15.8198 9.50962 15.8902C9.57842 15.9605 9.67173 16 9.76902 16Z" />
  </SvgIcon>
)

export default Icon
