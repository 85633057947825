/* eslint-disable */

var _drawRawLinePath,
  defineCanvasRenderer,
  drawErasedLinePath,
  drawErasedLinePathLatest,
  drawLinePath,
  drawLinePathLatest,
  lineEndCapShapes,
  noop,
  renderShapeToCanvas,
  renderShapeToContext,
  renderers

lineEndCapShapes = require('./lineEndCapShapes')

renderers = {}

defineCanvasRenderer = function (shapeName, drawFunc, drawLatestFunc) {
  return (renderers[shapeName] = {
    drawFunc: drawFunc,
    drawLatestFunc: drawLatestFunc,
  })
}

noop = function () {}

renderShapeToContext = function (ctx, shape, opts) {
  var bufferCtx
  if (opts == null) {
    opts = {}
  }
  if (opts.shouldIgnoreUnsupportedShapes == null) {
    opts.shouldIgnoreUnsupportedShapes = false
  }
  if (opts.retryCallback == null) {
    opts.retryCallback = noop
  }
  if (opts.shouldOnlyDrawLatest == null) {
    opts.shouldOnlyDrawLatest = false
  }
  if (opts.bufferCtx == null) {
    opts.bufferCtx = null
  }
  bufferCtx = opts.bufferCtx
  if (renderers[shape.className]) {
    if (opts.shouldOnlyDrawLatest && renderers[shape.className].drawLatestFunc) {
      return renderers[shape.className].drawLatestFunc(ctx, bufferCtx, shape, opts.retryCallback)
    } else {
      return renderers[shape.className].drawFunc(ctx, shape, opts.retryCallback)
    }
  } else if (opts.shouldIgnoreUnsupportedShapes) {
    return console.warn("Can't render shape of type " + shape.className + ' to canvas')
  } else {
    throw "Can't render shape of type " + shape.className + ' to canvas'
  }
}

renderShapeToCanvas = function (canvas, shape, opts) {
  return renderShapeToContext(canvas.getContext('2d'), shape, opts)
}

defineCanvasRenderer('Rectangle', function (ctx, shape) {
  /* Because desktop does not include lineWidth in the sizing, we need to coerce the raw data to display it correctly with canvas context rules */
  const wSign = shape.width > 0 ? 1 : -1
  const hSign = shape.height > 0 ? 1 : -1
  const contentWidth = Math.abs(shape.width) - shape.strokeWidth
  const contentHeight = Math.abs(shape.height) - shape.strokeWidth
  const width = contentWidth * wSign
  const height = contentHeight * hSign
  let x = shape.x + (shape.strokeWidth / 2) * wSign
  let y = shape.y + (shape.strokeWidth / 2) * hSign
  if (shape.strokeWidth % 2 !== 0) {
    x += 0.5
    y += 0.5
  }
  ctx.fillStyle = shape.fillColor
  ctx.fillRect(x, y, width, height)
  ctx.lineWidth = shape.strokeWidth
  ctx.strokeStyle = shape.strokeColor
  return ctx.strokeRect(x, y, width, height)
})

defineCanvasRenderer('Ellipse', function (ctx, shape) {
  const wSign = shape.width > 0 ? 1 : -1
  const hSign = shape.height > 0 ? 1 : -1
  ctx.save()
  /* Do we need to floor the halfWidth and halfHeight? */
  const halfWidth = Math.abs(Math.floor(shape.width / 2))
  const halfHeight = Math.abs(Math.floor(shape.height / 2))
  const centerX = shape.x + halfWidth * wSign
  const centerY = shape.y + halfHeight * hSign
  const halfStrokeWidth = shape.strokeWidth / 2
  ctx.beginPath()
  ctx.ellipse(
    centerX,
    centerY,
    Math.max(halfWidth - halfStrokeWidth, 0),
    Math.max(halfHeight - halfStrokeWidth, 0),
    0,
    0,
    2 * Math.PI
  )
  ctx.closePath()
  ctx.restore()
  ctx.fillStyle = shape.fillColor
  ctx.fill()
  ctx.lineWidth = shape.strokeWidth
  ctx.strokeStyle = shape.strokeColor
  return ctx.stroke()
})

defineCanvasRenderer(
  'SelectionBox',
  (function () {
    var _drawHandle
    _drawHandle = function (ctx, arg, handleSize) {
      var x, y
      ;(x = arg.x), (y = arg.y)
      if (handleSize === 0) {
        return
      }
      ctx.fillStyle = '#fff'
      ctx.fillRect(x, y, handleSize, handleSize)
      ctx.strokeStyle = '#000'
      return ctx.strokeRect(x, y, handleSize, handleSize)
    }
    return function (ctx, shape) {
      _drawHandle(ctx, shape.getTopLeftHandleRect(), shape.handleSize)
      _drawHandle(ctx, shape.getTopRightHandleRect(), shape.handleSize)
      _drawHandle(ctx, shape.getBottomLeftHandleRect(), shape.handleSize)
      _drawHandle(ctx, shape.getBottomRightHandleRect(), shape.handleSize)
      if (shape.backgroundColor) {
        ctx.fillStyle = shape.backgroundColor
        ctx.fillRect(
          shape._br.x - shape.margin,
          shape._br.y - shape.margin,
          shape._br.width + shape.margin * 2,
          shape._br.height + shape.margin * 2
        )
      }
      ctx.lineWidth = 1
      ctx.strokeStyle = '#000'
      ctx.setLineDash([2, 4])
      ctx.strokeRect(
        shape._br.x - shape.margin,
        shape._br.y - shape.margin,
        shape._br.width + shape.margin * 2,
        shape._br.height + shape.margin * 2
      )
      return ctx.setLineDash([])
    }
  })()
)

defineCanvasRenderer('Image', function (ctx, shape, retryCallback) {
  if (shape.image.width) {
    if (shape.scale === 1) {
      return ctx.drawImage(shape.image, shape.x, shape.y)
    } else {
      return ctx.drawImage(
        shape.image,
        shape.x,
        shape.y,
        shape.image.width * shape.scale,
        shape.image.height * shape.scale
      )
    }
  } else if (retryCallback) {
    return (shape.image.onload = retryCallback)
  }
})

defineCanvasRenderer('Line', function (ctx, shape) {
  var arrowWidth, x1, x2, y1, y2
  if (shape.x1 === shape.x2 && shape.y1 === shape.y2) {
    return
  }
  x1 = shape.x1
  x2 = shape.x2
  y1 = shape.y1
  y2 = shape.y2
  if (shape.strokeWidth % 2 !== 0) {
    x1 += 0.5
    x2 += 0.5
    y1 += 0.5
    y2 += 0.5
  }
  ctx.lineWidth = shape.strokeWidth
  ctx.strokeStyle = shape.color
  ctx.lineCap = shape.capStyle
  if (shape.dash) {
    ctx.setLineDash(shape.dash)
  }
  ctx.beginPath()
  ctx.moveTo(x1, y1)
  ctx.lineTo(x2, y2)
  ctx.stroke()
  if (shape.dash) {
    ctx.setLineDash([])
  }
  arrowWidth = Math.max(shape.strokeWidth * 2.2, 5)
  if (shape.endCapShapes[0]) {
    lineEndCapShapes[shape.endCapShapes[0]].drawToCanvas(
      ctx,
      x1,
      y1,
      Math.atan2(y1 - y2, x1 - x2),
      arrowWidth,
      shape.color
    )
  }
  if (shape.endCapShapes[1]) {
    return lineEndCapShapes[shape.endCapShapes[1]].drawToCanvas(
      ctx,
      x2,
      y2,
      Math.atan2(y2 - y1, x2 - x1),
      arrowWidth,
      shape.color
    )
  }
})

_drawRawLinePath = function (ctx, points, close, lineCap) {
  var i, len, point, ref
  if (close == null) {
    close = false
  }
  if (lineCap == null) {
    lineCap = 'round'
  }
  if (!points.length) {
    return
  }
  ctx.lineCap = lineCap
  ctx.strokeStyle = points[0].color
  ctx.lineWidth = points[0].size
  ctx.beginPath()
  if (points[0].size % 2 === 0) {
    ctx.moveTo(points[0].x, points[0].y)
  } else {
    ctx.moveTo(points[0].x + 0.5, points[0].y + 0.5)
  }
  ref = points.slice(1)
  for (i = 0, len = ref.length; i < len; i++) {
    point = ref[i]
    if (points[0].size % 2 === 0) {
      ctx.lineTo(point.x, point.y)
    } else {
      ctx.lineTo(point.x + 0.5, point.y + 0.5)
    }
  }
  if (close) {
    return ctx.closePath()
  }
}

drawLinePath = function (ctx, shape) {
  _drawRawLinePath(ctx, shape.smoothedPoints)
  return ctx.stroke()
}

drawLinePathLatest = function (ctx, bufferCtx, shape) {
  var drawEnd, drawStart, segmentStart
  if (shape.tail) {
    segmentStart = shape.smoothedPoints.length - shape.segmentSize * shape.tailSize
    drawStart = segmentStart < shape.segmentSize * 2 ? 0 : segmentStart
    drawEnd = segmentStart + shape.segmentSize + 1
    _drawRawLinePath(bufferCtx, shape.smoothedPoints.slice(drawStart, drawEnd))
    return bufferCtx.stroke()
  } else {
    _drawRawLinePath(bufferCtx, shape.smoothedPoints)
    return bufferCtx.stroke()
  }
}

defineCanvasRenderer('LinePath', drawLinePath, drawLinePathLatest)

drawErasedLinePath = function (ctx, shape) {
  ctx.save()
  ctx.globalCompositeOperation = 'destination-out'
  drawLinePath(ctx, shape)
  return ctx.restore()
}

drawErasedLinePathLatest = function (ctx, bufferCtx, shape) {
  ctx.save()
  ctx.globalCompositeOperation = 'destination-out'
  bufferCtx.save()
  bufferCtx.globalCompositeOperation = 'destination-out'
  drawLinePathLatest(ctx, bufferCtx, shape)
  ctx.restore()
  return bufferCtx.restore()
}

defineCanvasRenderer('ErasedLinePath', drawErasedLinePath, drawErasedLinePathLatest)

defineCanvasRenderer('Text', function (ctx, shape) {
  if (!shape.renderer) {
    shape._makeRenderer(ctx)
  }
  ctx.fillStyle = shape.color
  return shape.renderer.draw(ctx, shape.x, shape.y)
})

defineCanvasRenderer('Polygon', function (ctx, shape) {
  ctx.fillStyle = shape.fillColor
  _drawRawLinePath(ctx, shape.points, shape.isClosed, 'butt')
  ctx.fill()
  return ctx.stroke()
})

module.exports = {
  defineCanvasRenderer: defineCanvasRenderer,
  renderShapeToCanvas: renderShapeToCanvas,
  renderShapeToContext: renderShapeToContext,
}
