/* eslint-disable react-hooks/exhaustive-deps */
import { ValidationRules, UseFormMethods, FieldError } from 'react-hook-form'
import { useMemo, useCallback, useEffect, DependencyList, useRef } from 'react'

export interface FormInputsConfig {
  [key: string]: ValidationRules
}

export interface InputProps {
  name: string
  error: boolean
  helperText: string
  inputRef: (ref) => void
}

interface CustomValidationParams<T> {
  name: string
  rules: ValidationRules
  defaultValue?: T
}

export const emailAddressPattern = /\S+@\S+\.\S+/
export const noWhitespacePattern = /\S/

/**
 * Build props to be used in error handling for material ui input components
 *
 * @param methods - UseForm methods returned by a useForm hook call
 * @param config - object with pairs of input name and validation rules
 * @returns object with pairs of input name and props to be assigned to material ui input components
 */
export const useFormValidation = <
  T extends FormInputsConfig,
  R extends { [key in keyof T]: InputProps }
>(
  methods: UseFormMethods,
  config: T
): R => {
  const { errors, register } = methods
  const buildProps = useCallback(
    (name: string, rules: ValidationRules) =>
      ({
        name,
        error: !!errors[name],
        helperText: errors[name]?.message,
        inputRef: register(rules)
      } as InputProps),
    [register, errors]
  )

  return useMemo(() => {
    const props = {}
    Object.keys(config).forEach((name) => {
      props[name] = buildProps(name, config[name])
    })
    return props
  }, [buildProps, config]) as R
}

/**
 * Adds custom field validation to form
 *
 * @param methods - UseForm methods returned by a useForm hook call
 * @param {CustomValidationParams} - object with name, optional default value and validation rules for custom field
 * @returns tuple of current error and setter function for custom field
 */
export const useCustomValidation = <T>(
  methods: UseFormMethods,
  { name, rules, defaultValue }: CustomValidationParams<T>
) => {
  const { register, errors, setValue, formState } = methods

  useEffect(() => {
    register({ name, defaultValue, type: 'custom' }, rules)
  }, [register, defaultValue, name])

  // Required to always reference latest state of shouldValidate
  const shouldValidateRef = useRef(false)
  shouldValidateRef.current = formState.isSubmitted

  const _setValue = useCallback(
    (value: T) => {
      setValue(name, value, { shouldValidate: shouldValidateRef.current })
    },
    [setValue, name, shouldValidateRef]
  )

  return [errors[name], _setValue] as [FieldError, (value: T) => void]
}

/**
 * Trigger validation to run on given field name
 *
 * @param methods - UseForm methods returned by a useForm hook call
 * @param name - name of field to run validations on
 * @param dependencies - list of dependencies to be checked and trigger validations if changed
 */
export const useTriggerValidation = (
  methods: UseFormMethods,
  name: string,
  dependencies: DependencyList
) => {
  const { trigger, formState } = methods
  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(name)
    }
  }, [...dependencies, formState.isSubmitted, trigger, name])
}

/**
 * Util to assign errors if present to given input props
 *
 * @param inputProps - props to be assigned to material ui input components
 * @param errors - errors to check and assign by order priority
 * @returns new input props with joined errors
 */
export const joinInputErrors = (inputProps: InputProps, ...errors: (FieldError | undefined)[]) => {
  const error = errors.find((err) => !!err)
  return {
    ...inputProps,
    error: inputProps.error || !!error,
    helperText: inputProps.helperText || error?.message
  }
}
