import { logger } from '../../log/Log'
import Janus from './lib/janus'

export const KEEP_ALIVE_PERIOD = 12000 // janus.js defaults to 25000
export const MAX_SESSION_RECLAIM_ATTEMPTS = 5
export const RETRY_INTERVAL_MS = 3000
export const CONNECTION_LOST_ERROR = 'Lost connection to the server (is it down?)'
export const CONNECTION_ERROR =
  'Error connecting to the Janus WebSockets server: Is the server down?'

export enum PluginType {
  EvercastVideoRoom = 'janus.plugin.lua'
}

/**
 * This Handle object has several methods you can use to interact with the plugin or check the state of the session handle
 */
export interface PluginHandle {
  webrtcStuff: {
    myStream?: MediaStreamTrack
    pc?: RTCPeerConnection | null
  }
  /**
   * Returns the unique handle identifier;
   */
  getId: () => string
  /**
   * Returns the unique package name of the attached plugin
   */
  getPlugin: () => string
  /**
   * Sends a message (with or without a jsep to negotiate a PeerConnection) to the plugin;
   * @param parameters
   */
  send: (parameters) => void
  /**
   * Asks the janus.js to create a WebRTC compliant OFFER
   * @param callbacks
   */
  createOffer: (callbacks) => void
  /**
   * Asks the janus.js to create a WebRTC compliant ANSWER
   * @param callbacks
   */
  createAnswer: (callbacks) => void
  /**
   * Asks the janus.js to handle an incoming WebRTC compliant session description;
   * @param callbacks
   */
  handleRemoteJsep: (callbacks) => void
  /**
   * Sends a DTMF tone on the PeerConnection
   * @param parameters
   */
  dtmf: (parameters) => void
  /**
   * Sends data through the Data Channel, if available;
   * @param parameters
   */
  data: (parameters) => void
  /**
   * Gets a verbose description of the currently received stream bitrate
   */
  getBitrate: () => any
  /**
   * Tells the janus.js to close the PeerConnection; if the optional sendRequest argument is set to true,
   * then a hangup Janus API request is sent to Janus as well (disabled by default, Janus can usually figure this out via DTLS alerts and the like but it may be useful to enable it sometimes);
   * @param shouldSendRequest
   */
  hangup: (shouldSendRequest) => void
  /**
   * Detaches from the plugin and destroys the handle, tearing down the related PeerConnection if it exists
   * @param parameters
   */
  detach: (parameters) => void
  /**
   * Disables audio track
   */
  unmuteAudio: () => void
  /**
   * Enables audio track
   */
  muteAudio: () => void
  /**
   * Checks if audio input is muted
   */
  isAudioMuted: () => boolean
  /**
   * Enabled video track
   */
  unmuteVideo: () => void
  /**
   * Disables video track
   */
  muteVideo: () => void
  /**
   * Checks if video input is muted
   */
  isVideoMuted: () => boolean
}

/**
 * Params used when attaching the session to a plugin
 */
export type AttachParams = {
  /**
   * Unique package name of the plugin
   */
  plugin: PluginType
  /**
   * Opaque string meaningful to your application (e.g., to map all the handles of the same user);
   */
  opaqueId: string
  /**
   * Callback called when handle was successfully created and is ready to be used
   * @param pluginHandle
   */
  success: (pluginHandle: PluginHandle) => void
  /**
   * Callback called when handle was NOT successfully created;
   */
  error: (error: any) => void
  /**
   * Callback is triggered just before getUserMedia is called (parameter=true) and after it is completed (parameter=false);
   * this means it can be used to modify the UI accordingly, e.g., to prompt the user about the need to accept the device access consent requests;
   * @param isOpened
   */
  consentDialog: (isOpened: boolean) => void
  /**
   * Callback is triggered with a true value when the PeerConnection associated to a handle becomes active
   * (so ICE, DTLS and everything else succeeded) from the Janus perspective, while false is triggered when the PeerConnection goes down instead;
   * useful to figure out when WebRTC is actually up and running between you
   * and Janus (e.g., to notify a user they're actually now active in a conference);
   * notice that in case of false a reason string may be present as an optional parameter;
   * @param webrtcState
   */
  webrtcState: (webrtcState: boolean, reason: string) => void
  /**
   * Callback is triggered when the ICE state for the PeerConnection associated to the handle changes:
   * the argument of the callback is the new state (e.g., "connected" or "failed");
   * @param iceState
   */
  iceState: (iceState: RTCIceTransportState) => void
  /**
   * Callback is triggered when Janus starts or stops receiving your media:
   * for instance, a mediaState with type=audio and on=true means Janus started receiving your
   * audio stream (or started getting them again after a pause of more than a second);
   * a mediaState with type=video and on=false means Janus hasn't received any video from you in the last second,
   * after a start was detected before; useful to figure out when Janus actually started handling your media,
   * or to detect problems on the media path (e.g., media never started, or stopped at some time);
   * @param type
   * @param isOn
   */
  mediaState: (type: string, isOn: boolean) => void
  /**
   * Callback is triggered when Janus reports trouble either sending or receiving media on the specified PeerConnection,
   * typically as a consequence of too many NACKs received from/sent to the user in the last second:
   * for instance, a slowLink with uplink=true means you notified several missing packets from Janus,
   * while uplink=false means Janus is not receiving all your packets; useful to figure out when
   * there are problems on the media path (e.g., excessive loss), in order to possibly react
   * accordingly (e.g., decrease the bitrate if most of our packets are getting lost);
   * @param uplink
   * @param nacks
   */
  slowLink: (uplink: boolean, nacks: number) => void
  /**
   * Callback is triggered when message/event has been received from the plugin
   * @param msg
   * @param jsep
   */
  onmessage: (msg: any, jsep: any) => void
  /**
   * Callback is triggered when local MediaStream is available and ready to be displayed
   * @param localStream
   */
  onlocalstream: (localStream: MediaStream) => void
  /**
   * Callback is triggered when remote MediaStream is available and ready to be displayed
   * @param remoteStream
   */
  onremotestream: (remoteStream: MediaStream) => void
  /**
   * Callback is triggered when data Channel is available and ready to be used
   * @param dataChannelLabel
   */
  ondataopen: (dataChannelLabel: string) => void
  /**
   * Callback is triggered when data has been received through the Data Channel
   * @param data
   * @param dataChannelLabel
   */
  ondata: (data: any, dataChannelLabel: string) => void
  /**
   * Callback is triggered when the WebRTC PeerConnection with the plugin was closed
   */
  oncleanup: () => void
  /**
   * Callback is triggered when the plugin handle has been detached by the plugin itself, and so should not be used anymore.
   */
  detached: () => void
}

export type JanusSignallingConfig = {
  /**
   * Janus server url (in this case websocket server url, the proxy)
   */
  serverUrl: string
  /**
   * ICE servers array obtained from API
   */
  iceServers: any[]
  keepAlivePeriod: number
}

/**
 * Represents a state the connection can be in.
 */
export enum SignallingConnectionState {
  Initial = 'Initial',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Reclaiming = 'Reclaiming',
  Error = 'Error',
  Disconnected = 'Disconnected',
  Reallocating = 'Reallocating'
}

export type ConnectionStateData = {
  oldState: SignallingConnectionState
  currentState: SignallingConnectionState
  error: string | null | undefined
}
export type ConnectionStateObserver = (ConnectionStateObserverParams) => void

const isErrorSuitableForSessionReclaim = (error) => {
  // Reclaiming sessions is disabled because it was causing audio / video drops for some users
  return false
}

const hasExceededMaxReclaimAttemptsCount = (attempts) => {
  return attempts >= MAX_SESSION_RECLAIM_ATTEMPTS
}

/**
 * Represents the signalling layer of Janus WebRTC server connection.
 * Connects using websocket protocol to Janus server (via websocket-server proxy).
 * @link: https://janus.conf.meetecho.com/docs/JS.html
 */
export class JanusSignallingConnection {
  readonly serverUrl: string
  private readonly iceServers: any[]
  private readonly keepAlivePeriod: number
  private readonly connectionObserver: ConnectionStateObserver
  private _janusInstance: any | null
  private _connectionState: SignallingConnectionState = SignallingConnectionState.Initial
  private error: any | null
  private reclaimAttemptsCount = 0

  constructor(
    { serverUrl, iceServers, keepAlivePeriod }: JanusSignallingConfig,
    connectionObserver: ConnectionStateObserver
  ) {
    this.serverUrl = serverUrl
    this.iceServers = iceServers
    this.keepAlivePeriod = keepAlivePeriod
    this.connectionObserver = connectionObserver
  }

  set connectionState(newState: SignallingConnectionState) {
    logger.log(
      `[Signalling] Janus connection changed state from '${this._connectionState}' to '${newState}'.`
    )
    this.connectionObserver({
      oldState: this._connectionState,
      currentState: newState,
      error: this.error
    })
    this._connectionState = newState
  }

  get connectionState() {
    return this._connectionState
  }

  get janusInstance() {
    return this._janusInstance
  }

  /**
   * Connects to the Janus server, creates a session
   */
  connect = async () => {
    return new Promise((resolve) => {
      this.connectionState = SignallingConnectionState.Connecting

      if (!this.serverUrl) {
        return
      }
      this._janusInstance = new Janus({
        server: this.serverUrl,
        iceServers: this.iceServers,
        success: () => {
          this.handleConnectionSuccess()
          resolve(null)
        },
        error: this.handleConnectionError,
        keepAlivePeriod: this.keepAlivePeriod
      })

      this._janusInstance.error = logger.error
    })
  }

  /**
   * When connection is lost, session could be reclaimed in 120s window, after that
   * There will be no possibility to reclaim and full reconnection will need to be done
   */
  reclaimSession = async () =>
    new Promise((resolve) => {
      this.connectionState = SignallingConnectionState.Reclaiming

      this._janusInstance.reconnect({
        success: () => {
          this.handleConnectionSuccess()
          resolve(null)
        },
        error: this.handleConnectionError
      })
    })

  /**
   * Attaches the session to a plugin, creates a plugin handle
   * @param params
   */
  attach = (params: AttachParams) => {
    this._janusInstance.attach(params)
  }

  isConnected = () => this._janusInstance.isConnected()

  getSessionId = () => this._janusInstance.getSessionId()

  /**
   * Destroys the session with the server, and closes all the handles (and related PeerConnections) the session may have with any plugin as well.
   */
  disconnect = async () =>
    new Promise((resolve) => {
      this._janusInstance.destroy({
        cleanupHandles: true,
        success: () => {
          this._janusInstance = null
          this.connectionState = SignallingConnectionState.Disconnected

          logger.log('[Signalling] Janus connection successfully destroyed.')

          resolve(null)
        }
      })
    })

  disconnectSync = async () => {
    this._janusInstance.destroy({
      cleanupHandles: true,
      success: () => {
        this._janusInstance = null
        this.connectionState = SignallingConnectionState.Disconnected

        logger.log('[Signalling] Janus connection successfully destroyed.')
      }
    })
  }

  reallocate = async () => {
    this._janusInstance.destroy({
      cleanupHandles: true,
      success: () => {
        this._janusInstance = null
        this.connectionState = SignallingConnectionState.Reallocating

        logger.log('[Signalling] Room being reallocated. Janus connection successfully destroyed.')
        this.scheduleConnect()
      }
    })
  }

  private scheduleConnect = () => {
    setTimeout(() => {
      this.connect()
    }, RETRY_INTERVAL_MS)
  }

  private scheduleSessionReclaim = () => {
    setTimeout(() => {
      this.reclaimSession()
    }, RETRY_INTERVAL_MS)
  }

  private handleConnectionSuccess = () => {
    logger.log('[Signalling] Janus connected.')
    this.reclaimAttemptsCount = 0
    this.connectionState = SignallingConnectionState.Connected
  }

  /**
   * Handles session reclaim/reconnection when error conditions are met.
   * Session reclaim is performed when user was successfully connected but error happened.
   * In this case, Janus keeps user session for 120s (timeout value specified in Janus config) and it is
   * possible to 'reclaim' this session without actually performing full rejoin.
   * After a few {@link MAX_SESSION_RECLAIM_ATTEMPTS} reclaim attempts we should give up and perform full rejoin instead.
   * @param errorMessage
   */
  private handleConnectionError = (errorMessage) => {
    logger.error(`[Signalling] Janus error: ${errorMessage}`)
    this.error = errorMessage

    // This case is about different janus errors that can't be recovered via session reclaim mechanism
    if (!isErrorSuitableForSessionReclaim(errorMessage)) {
      logger.warn(`[Signalling] Skipping session reclaim, error: '${errorMessage}'.`)
      this.connectionState = SignallingConnectionState.Error
      this.reclaimAttemptsCount = 0
      this.scheduleConnect()
      return
    }

    // If we tried enough times, give up
    if (hasExceededMaxReclaimAttemptsCount(this.reclaimAttemptsCount)) {
      logger.warn(
        `[Signalling] Cancelling session reclaim attempt, tried: ${this.reclaimAttemptsCount} times, performing full reconnection.`
      )
      this.connectionState = SignallingConnectionState.Error
      this.reclaimAttemptsCount = 0
      this.scheduleConnect()
      return
    }

    // Only start session reclaim when we were connected previously and we're not, or we're already reclaiming
    if (
      this.connectionState === SignallingConnectionState.Connected ||
      this.connectionState === SignallingConnectionState.Reclaiming
    ) {
      logger.warn(
        `[Signalling] Connection failure, attempting to reclaim the session, attempt: ${this.reclaimAttemptsCount}`
      )
      this.reclaimAttemptsCount = this.reclaimAttemptsCount + 1
      this.scheduleSessionReclaim()
      return
    }

    // in this case we don't really know what happened except for the fact that connection is gone, connecting again
    this.connectionState = SignallingConnectionState.Error
    this.scheduleConnect()
  }
}
