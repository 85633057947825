import graphql from 'babel-plugin-relay/macro'

const ClearMessagesForRoomMutation = graphql`
  mutation ClearMessagesForRoomMutation($input: ClearMessagesForRoomInput!) {
    clearMessagesForRoom(input: $input) {
      clientMutationId
    }
  }
`

export default ClearMessagesForRoomMutation
