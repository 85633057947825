import graphql from 'babel-plugin-relay/macro'

const RecordingsViewQuery = graphql`
  query RecordingsViewQuery($roomId: UUID!) {
    room: liveroomById(id: $roomId) {
      ...RecordingsView_room
    }
    currentProfile {
      ...RecordingsView_currentProfile
    }
    iceServers: getIceServers {
      ...RecordingsView_iceServers
    }
  }
`

export default RecordingsViewQuery
