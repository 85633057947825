import React, { useCallback } from 'react'
import { MenuList } from '@material-ui/core'

import MenuItemWithSubMenu from '../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import FontFamily from './FontFamily/FontFamily'
import FontSize from './FontSize/FontSize'
import TypographyEmphasis from './TypographyEmphasis/TypographyEmphasis'
import {
  useDrawCanvasToolsContext,
  DRAW_TOOLS
} from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'
import { DrawToolIcon } from '../../../DrawToolIcon/DrawToolIcon'

const Text: React.FC = () => {
  const {
    setDrawTool,
    drawPreferences: { drawTool }
  } = useDrawCanvasToolsContext()

  const onClickTextHandler = useCallback(() => {
    setDrawTool(DRAW_TOOLS.TEXT)
  }, [setDrawTool])

  return (
    <MenuItemWithSubMenu
      icon={<DrawToolIcon drawTool={DRAW_TOOLS.TEXT} />}
      label="Text"
      onClick={onClickTextHandler}
      selected={drawTool === DRAW_TOOLS.TEXT}
      isOpen={drawTool === DRAW_TOOLS.TEXT}
    >
      <MenuList>
        <FontFamily />
        <FontSize />
        <TypographyEmphasis />
      </MenuList>
    </MenuItemWithSubMenu>
  )
}

export default React.memo(Text)
