import { useCallback, useState, useEffect } from 'react'

import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { useRemoveRoomFromDashboardMutation } from './useRemoveRoomFromDashboardMutation'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../../../../Common/log/Log'
import { Room } from '../../../../../../Models/apiEntities'

export enum RemoveRoomFromDashboardState {
  awaiting,
  submitting,
  success
}

const defaultErrorText = 'Error deleting room!'

export const useRemoveRoomFromDashboard = () => {
  const [selectedRoomToRemove, setSelectedRoomToRemove] = useState<Room | null>(null)
  const [state, setState] = useState(RemoveRoomFromDashboardState.awaiting)
  const [
    isRemoveFromDashboardConfirmationOpen,
    setIsRemoveFromDashboardConfirmationOpen
  ] = useState(false)
  const alert = useAlerts()

  const [
    executeDeleteLiveroomMutation,
    { error, graphQLErrors },
    cancel
  ] = useRemoveRoomFromDashboardMutation()

  // prevent error with unmounted component after room delete
  useEffect(() => () => cancel(), [cancel])

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
    onError: useCallback(() => {
      setState(RemoveRoomFromDashboardState.awaiting)
    }, [setState])
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
    setState(RemoveRoomFromDashboardState.awaiting)
  }, [error])

  const removefromDashboard = useCallback(async () => {
    if (!selectedRoomToRemove) return
    setState(RemoveRoomFromDashboardState.submitting)

    const variables = {
      input: {
        _roomId: selectedRoomToRemove?.id
      }
    }

    try {
      await executeDeleteLiveroomMutation(variables)
      setState(RemoveRoomFromDashboardState.success)
    } catch (e) {
      logger.error(e)
    }
  }, [executeDeleteLiveroomMutation, setState, selectedRoomToRemove])

  const showRemoveFromDashboardConfirmation = useCallback(async () => {
    setIsRemoveFromDashboardConfirmationOpen(true)
  }, [setIsRemoveFromDashboardConfirmationOpen])

  const closeRemoveFromDashboardConfirmation = useCallback(async () => {
    setIsRemoveFromDashboardConfirmationOpen(false)
  }, [setIsRemoveFromDashboardConfirmationOpen])

  const selectRoomToRemoveFromDashboard = useCallback(
    (room) => {
      setSelectedRoomToRemove(room)
      setState(RemoveRoomFromDashboardState.awaiting)
      showRemoveFromDashboardConfirmation()
    },
    [setSelectedRoomToRemove, showRemoveFromDashboardConfirmation, setState]
  )

  return {
    removeFromDashboardState: state,
    removefromDashboard,
    showRemoveFromDashboardConfirmation,
    closeRemoveFromDashboardConfirmation,
    isRemoveFromDashboardConfirmationOpen,
    selectRoomToRemoveFromDashboard,
    selectedRoomToRemove
  }
}
