import { useCallback, useEffect, useMemo, useState } from 'react'
import { omit } from 'lodash'
import { Observable } from 'rxjs'
import {
  LiveroomClient,
  Participant,
  ParticipantType,
} from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { Stream, StreamState } from '../../../../../../../Common/media/stream'
import { MediaSelection } from '../../hooks/useMediaSetup'
import { LocalDevicesState } from '../../hooks/useLocalDevicesState'
import { DamClient } from '../../../../../../../Common/dam/DamClient'
import { DamStream } from './DamStream'

export interface LiveroomParticipant {
  id: string
  displayName: string
  stream: Stream
  publishing: boolean
  type: ParticipantType
  onIceState: () => Observable<any>
  getInboundStats: () => Promise<RTCStatsReport | {}>
  getWebRTCStats: () => Promise<{[key: string]: any}>
  startReceivingVideo: () => Promise<any>
  stopReceivingVideo: () => Promise<any>
}

interface useLiveroomParticipantsParams {
  liveroomClient: LiveroomClient | null
  damClient: DamClient | null
  mediaSelection: MediaSelection
  localDevicesState: LocalDevicesState
  currentProfile: {
    id: string
    displayName: string
    email: string | null
  }
}

export const useLiveroomParticipants = ({
  liveroomClient,
  mediaSelection,
  localDevicesState,
  damClient,
  currentProfile,
}: useLiveroomParticipantsParams) => {
  const [participants, setParticipants] = useState<Participant[]>([])
  const [streams, setStreams] = useState<{ [key: string]: MediaStream | DamStream | null }>({})
  const [lastJoinedParticipant, setLastJoinedParticipant] = useState<Participant | null>(null)
  const [areLiveroomSubscriptionDone, setAreLiveroomSubscriptionDone] = useState<boolean>(false)
  const [areDamClientSubscriptionDone, setAreDamClientSubscriptionDone] = useState<boolean>(false)

  useEffect(() => {
    if (liveroomClient && !areLiveroomSubscriptionDone) {
      liveroomClient.onParticipantJoining().subscribe((participant) => {
        if (participant.type === ParticipantType.Dummy) {
          // Ignore dummy participants as they are meant to be hidden and do not publish
          return
        }

        setParticipants((participants) => [...participants, participant])
        setStreams((streams) => ({ ...streams, [participant.id]: null }))
        participant.onPublishing().subscribe((mediaStream) => {
          setParticipants((participants) => participants.map(p => {
            if (p.id === participant.id) {
              p.publishing = true
            }
            return p
          }))
          setStreams((streams) => ({ ...streams, [participant.id]: mediaStream }))
        })
        participant.onUnpublishing().subscribe(() => {
          setStreams((streams) => ({ ...streams, [participant.id]: null }))
        })
        setLastJoinedParticipant(participant)
      })
      liveroomClient.onParticipantLeaving().subscribe((participantId) => {
        setParticipants((participants) =>
          participants.filter((participant) => participant.id !== participantId)
        )
        setStreams((streams) => omit(streams, participantId))
      })
      liveroomClient.onParticipantPublishing().subscribe((participantId) => {
        setParticipants((participants) => participants.map(p => {
          if (p.id === participantId) {
            p.publishing = true
          }
          return p
        }))
      })
      setAreLiveroomSubscriptionDone(true)
    }

    if (damClient && !areDamClientSubscriptionDone) {
      damClient.onStreamStart().subscribe((serverStream) => {
        console.log(`Stream Started ${serverStream.id}`)
        const stream = new DamStream({
          src: serverStream.src,
          progress: serverStream.progress,
          isPaused: serverStream.isPaused,
          isBuffering: serverStream.isBuffering,
          id: serverStream.id,
          // preserve this on functions
          onStreamCanPlay: damClient.onStreamCanPlay.bind(damClient),
          onStreamWaiting: damClient.onStreamWaiting.bind(damClient),
          onStreamStalled: damClient.onStreamStalled.bind(damClient)
        })

        const owner = currentProfile.id === serverStream.ownerId ? currentProfile : participants?.find((p) => p.id.includes(serverStream.ownerId))

        const participant = {
          type: ParticipantType.DAMStream,
          id: serverStream.id,
          displayName: owner ? `DAM Stream | ${owner.displayName}` : '',
          ownerId: serverStream.ownerId,
          publishing: true,
        } as Participant

        setParticipants((participants) => [...participants, participant])
        setStreams((streams) => ({ ...streams, [participant.id]: stream }))
        setLastJoinedParticipant(participant)
      })

      damClient.onStreamStop().subscribe((stream) => {
        console.log(`Stream Stoped ${stream.id}`)
        setParticipants((participants) =>
          participants.filter((participant) => participant.id !== stream.id)
        )
        setStreams((streams) => omit(streams, stream.id))
      })

      damClient.onStreamProgress().subscribe((serverStream) => {
        const maxSyncTimeSeconds = 1

        setStreams((streams) => {
          const existingStream = streams[serverStream.id] as DamStream
          const serverProgressMilliseconds = serverStream.progress
          const serverProgressSeconds = serverProgressMilliseconds / 1000
          if (Math.abs(existingStream.progress - serverProgressSeconds) > maxSyncTimeSeconds) {
            existingStream.progress = serverProgressSeconds
          }
          if (serverStream.isPaused || serverStream.isBuffering) {
            existingStream.pause()
            if (existingStream.progress !== serverProgressSeconds) {
              existingStream.progress = serverProgressSeconds
            }
          } else {
            existingStream.play()
          }
          if (serverStream.volume !== existingStream.volume) {
            existingStream.updateVolume(serverStream.volume)
          }
          return streams
        })
      })
      setAreDamClientSubscriptionDone(true)
    }

    if (!liveroomClient && !damClient) {
      setParticipants([])
      setStreams({})
      setAreDamClientSubscriptionDone(false)
      setAreLiveroomSubscriptionDone(false)
    }
  }, [liveroomClient, damClient])

  // if user who's marked as last joined left, reset last joined participant
  useEffect(() => {
    if (!participants.find((participant) => participant.id === lastJoinedParticipant?.id)) {
      setLastJoinedParticipant(null)
    }
    // When the DAM Sidecar stream is added to the list of participants, the owner might no be in the list (probably because of a desync in the order of execution).
    // For this reason, everytime the list of participants change, we check if there is any DAM Sidecar stream without a proper display name and update it acordingly.
    // CAUTION: This useEffect updates a dependency which is not advisable. Becareful when updating the code below because it might introduce an infinite loop.
    if (participants.find((p) => p.type === ParticipantType.DAMStream && !p.displayName)) {
      setParticipants((participants) => participants.map((p) => {
        if (p.type === ParticipantType.DAMStream && !p.displayName) {
          const owenerId = p.ownerId || '';
          const owner = currentProfile.id === owenerId ? currentProfile : participants?.find((p) => p.id.includes(owenerId))
          p.displayName = `DAM Stream | ${owner?.displayName || ''}`
        }
        return p
      }))
    }
  }, [participants, lastJoinedParticipant])

  const toModel = useCallback(
    (participant: Participant) => {
      const mediaStream = streams[participant.id]
      let stream: Stream
      let startReceivingVideo: () => Promise<any>
      let stopReceivingVideo: () => Promise<any>
      if (mediaStream && participant.type === ParticipantType.DAMStream) {
        stream = mediaStream as DamStream
      } else if (mediaStream) {
        stream = {
          state: StreamState.available,
          current: mediaStream as MediaStream,
          hasAudioTrack: true,
          hasVideoTrack: true,
          speaker: localDevicesState.isAudioOutputEnabled ? mediaSelection.speaker : null,
        }
      } else {
        stream = {
          state: StreamState.unavailable,
        }
      }

      if (participant.type === ParticipantType.DAMStream) {
        startReceivingVideo = async () => { }
        stopReceivingVideo = async () => { }
      } else {
        startReceivingVideo = participant.startReceivingVideo.bind(participant)
        stopReceivingVideo = participant.stopReceivingVideo.bind(participant)
      }

      return {
        id: participant.id,
        displayName: participant.displayName,
        stream,
        publishing: participant.publishing,
        type: participant.type,
        onIceState: participant.onIceState,
        getInboundStats: participant.getInboundStats,
        getWebRTCStats: participant.getWebRTCStats,
        startReceivingVideo,
        stopReceivingVideo,
      } as LiveroomParticipant
    },
    [streams, mediaSelection.speaker, localDevicesState.isAudioOutputEnabled]
  )

  const participantsModel = useMemo(() => participants.map(toModel), [participants, toModel])
  const lastJoinedParticipantModel = useMemo(() => {
    return lastJoinedParticipant ? toModel(lastJoinedParticipant) : null
  }, [lastJoinedParticipant, toModel])

  const participantsManager = {
    kickParticipant(participantId) {
      liveroomClient?.kickParticipant(participantId)
    },

    muteParticipant(participantId) {
      liveroomClient?.muteParticipant(participantId)
    },

    muteAllParticipants() {
      liveroomClient?.muteAllParticipants()
    },
  }

  return {
    lastJoinedParticipantModel,
    participants,
    participantsModel,
    participantsManager,
  }
}
