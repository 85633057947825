import { Box, BoxProps } from '@material-ui/core'
import React from 'react'

const HorizontalLayout: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box display="flex" flexDirection="row" width="100%" minHeight={0} minWidth={0} {...boxProps}>
      {children}
    </Box>
  )
}

export default HorizontalLayout
