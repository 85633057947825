import { useMemo } from 'react'

export const useFormatTime = (seconds: number) =>
  useMemo(() => {
    const hours = Math.floor(seconds / 3600)
    const dividersForMinutes = seconds % 3600
    const minutes = Math.floor(dividersForMinutes / 60)
    const sec = Math.ceil(dividersForMinutes % 60)

    const hh = hours.toString()
    const mm = minutes.toString().padStart(2, '0')
    const ss = sec.toString().padStart(2, '0')
    return `${hh}:${mm}:${ss}`
  }, [seconds])
