/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TwoFactorVerifiedMutationVariables = {};
export type TwoFactorVerifiedMutationResponse = {
    readonly isTwoFactorVerified: boolean | null;
};
export type TwoFactorVerifiedMutation = {
    readonly response: TwoFactorVerifiedMutationResponse;
    readonly variables: TwoFactorVerifiedMutationVariables;
};



/*
mutation TwoFactorVerifiedMutation {
  isTwoFactorVerified
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isTwoFactorVerified",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TwoFactorVerifiedMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TwoFactorVerifiedMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4e0c4e3a98d59fe0b970da338e3d5195",
    "id": null,
    "metadata": {},
    "name": "TwoFactorVerifiedMutation",
    "operationKind": "mutation",
    "text": "mutation TwoFactorVerifiedMutation {\n  isTwoFactorVerified\n}\n"
  }
};
})();
(node as any).hash = 'a6393cd3ba76b95653f261a6c4582713';
export default node;
