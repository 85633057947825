import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../Views/MainView/AuthenticatedView/routes'

/**
 * Manage logic for common routes
 */
export const useNavigation = () => {
  const history = useHistory()

  const goToDashboard = useCallback(() => history.push(routes.dashboard.path), [history])
  const goToDashboardWithState = useCallback((kickRoomName, deniedDnd = false) => history.push(routes.dashboard.path, {kickRoomName, deniedDnd}), [history])
  const clearLocationState = (path) => history.push(path)

  return {
    goToDashboard,
    goToDashboardWithState,
    clearLocationState
  }
}
