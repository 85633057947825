import React from 'react'

import { BaseDialog } from '../../../../../Components/Dialogs/BaseDialog/BaseDialog'
import { DefaultDialogHeader } from '../../../../../Components/Dialogs/Headers/DefaultDialogHeader/DefaultDialogHeader'
import { useScreenShare } from './hooks/useScreenShare'
import { Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import videoQualityOptions, { VideoQuality } from '../../../../../Common/video/videoQualityOptions'
import Box from '@material-ui/core/Box'

interface ScreenShareDialogProps {
  isOpen: boolean
  handleClose: () => void
  handleStartScreenSharing: (videoQuality: VideoQuality) => void
}

/**
 * Screen share dialog
 * @param isOpen to show/hide dialog
 * @param handleClose on dialog close
 * @param handleStartScreenSharing handle start screen sharing action
 * @constructor
 */
export const ScreenShareDialog: React.FC<ScreenShareDialogProps> = ({
  isOpen,
  handleClose,
  handleStartScreenSharing
}) => {
  const { videoQualityId, handleQualityChange, handleScreenSharing } = useScreenShare(
    handleStartScreenSharing,
    handleClose
  )

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth={true}
      header={
        <DefaultDialogHeader text={'Screen share settings'} divider={true} onClose={handleClose} />
      }
    >
      <Box paddingX={5}>
        <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={3}>
          <Grid item>
            <ErrorOutlineIcon style={{ fontSize: 50 }} color="secondary" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary" className="browser-stream-header">
              {`Use EBS if you want high quality video streaming.`}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box marginTop={3}>
        <FormControl variant="filled" fullWidth>
          <InputLabel>Screen share quality</InputLabel>
          <Select value={videoQualityId} onChange={handleQualityChange}>
            {videoQualityOptions.getAll().map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box marginTop={4}>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          size="large"
          onClick={handleScreenSharing}
          data-testid="start-screen-share-button"
        >
          Start
        </Button>
      </Box>
    </BaseDialog>
  )
}

export default React.memo(ScreenShareDialog)
