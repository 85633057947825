/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Room_currentProfile = {
    readonly id: unknown;
    readonly email: string | null;
    readonly profilePreferences: {
        readonly nodes: ReadonlyArray<{
            readonly preferenceTypeName: string | null;
            readonly preferenceTypeId: unknown | null;
            readonly preferenceTypeDefault: string | null;
            readonly preferenceValue: string | null;
        } | null>;
    };
    readonly " $fragmentRefs": FragmentRefs<"LiveRoom_currentProfile">;
    readonly " $refType": "Room_currentProfile";
};
export type Room_currentProfile$data = Room_currentProfile;
export type Room_currentProfile$key = {
    readonly " $data"?: Room_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"Room_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Room_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PreferencesConnection",
      "kind": "LinkedField",
      "name": "profilePreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Preference",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceTypeName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceTypeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceTypeDefault",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferenceValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LiveRoom_currentProfile"
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '49fffec16cc93cb9febe9150cb0abaa8';
export default node;
