import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = ({ color, ...props }) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="11" r="8" fill={color} />
  </SvgIcon>
)

export default Icon
