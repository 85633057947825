import React from 'react'
import classnames from 'classnames'

import './ProgressBar.scss'
import { Box, BoxProps } from '@material-ui/core'

/**
 * ProgressBar component props
 */

/**
 * Custom Evercast progress bar
 * Source: https://codepen.io/katydecorah/pen/tbjqx
 * @constructor
 */
const ProgressBar: React.FC<BoxProps> = (boxProps) => {
  return <Box {...boxProps} className={classnames('progress-bar', boxProps.className)} />
}

export default React.memo(ProgressBar)
