import graphql from 'babel-plugin-relay/macro'

const CreateNoteMutation = graphql`
  mutation CreateNoteMutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      clientMutationId
      note {
        ...NoteItem_note
      }
    }
  }
`

export default CreateNoteMutation
