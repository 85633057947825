import React, { useCallback, useMemo } from 'react'
import { Edit, Delete, MoreVert } from '@material-ui/icons'
import Menu from '../../../../Menu/Menu'
import { IconButton } from '@material-ui/core'
import ConfirmationDialog from '../../../../Dialogs/ConfirmationDialog/ConfirmationDialog'
import { useDialog } from '../../../../RoomDialog/hooks/useDialog'

export interface NoteMenuProps {
  handleSelectEditMessage: () => void
  handleDeleteMessage: () => void
}

const NoteMenu: React.FC<NoteMenuProps> = ({ handleSelectEditMessage, handleDeleteMessage }) => {
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialog()

  const onDeleteMessage = useCallback(() => {
    closeConfirmationDialog()
    if (handleDeleteMessage) {
      handleDeleteMessage()
    }
  }, [closeConfirmationDialog, handleDeleteMessage])

  const onEditMessage = useCallback(() => {
    closeConfirmationDialog()
    if (handleSelectEditMessage) {
      handleSelectEditMessage()
    }
  }, [closeConfirmationDialog, handleSelectEditMessage])

  const menuOptions = useMemo(
    () => [
      {
        text: 'Edit',
        icon: <Edit />,
        onClick: onEditMessage
      },
      {
        text: 'Delete',
        icon: <Delete />,
        onClick: openConfirmationDialog
      }
    ],
    [onEditMessage, openConfirmationDialog]
  )

  return (
    <>
      <Menu
        button={(openMenu) => (
          <IconButton aria-label="chat-message-options" onClick={openMenu}>
            <MoreVert fontSize="small" />
          </IconButton>
        )}
        menuOptions={menuOptions}
      />
      <ConfirmationDialog
        text="Delete this note?"
        isOpen={isConfirmationDialogOpen}
        onConfirm={onDeleteMessage}
        onCancel={closeConfirmationDialog}
      />
    </>
  )
}

export default React.memo(NoteMenu)
