import React, { useCallback, memo } from 'react'
import { Tabs, Tab, Box, makeStyles } from '@material-ui/core'
interface TabPanelProps {
  index: number
  value: number
  padding?: number
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, padding = 3, ...other }) => (
  <Box
    p={padding}
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    height="100%"
    {...other}
  >
    {children}
  </Box>
)

interface Tab {
  label: string
  component: React.ReactNode
  icon?: React.ReactElement
  value?: string
}

interface TabsProps {
  tabs: Tab[]
  beforeChangeTab?: () => Promise<boolean>
  padding?: number
  variant?: 'primary' | 'secondary'
  onTabChange?: (tab) => void
}

const CustomTabs: React.FC<TabsProps> = ({ tabs, beforeChangeTab, padding, onTabChange }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = useCallback(
    async (event: React.ChangeEvent, newValue: number) => {
      if (beforeChangeTab) {
        const canChangeTab = await beforeChangeTab()
        if (!canChangeTab) return
      }

      setValue(newValue)
      if (onTabChange) onTabChange(tabs[newValue].value)
    },
    [beforeChangeTab]
  )

  const useStyles = makeStyles({
    notitlecase: {
      textTransform: "none",
    }
  })

  const classes = useStyles()

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth">
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            icon={tab.icon}
            className={classes.notitlecase}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={value} index={index} padding={padding}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  )
}

export default memo(CustomTabs)
