import React, { useCallback, useState } from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Box
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useDeleteRoomInvite from '../hooks/useDeleteRoomInvite'
import { RoomInvite, RoomInviteType } from '../../../Models/apiEntities'

import './RoomInviteList.scss'

interface RoomInviteListProps {
  room: {
    id: string
  }
  invites: RoomInvite[]
  onRemoveInvite: (id: string) => Promise<void>
}

const RoomInviteList: React.FC<RoomInviteListProps> = ({ room, invites, onRemoveInvite }) => {
  const { performDeleteRoomInvite } = useDeleteRoomInvite(room.id)
  const [removing, setRemoving] = useState<boolean>(false)

  const handleRemovedInvite = useCallback(
    async (inviteId: string) => {
      setRemoving(true)
      await onRemoveInvite(inviteId)
      setRemoving(false)
    },
    [performDeleteRoomInvite]
  )

  const inviteItem = (invite) => (
    <ListItem key={invite.id}>
      <ListItemText primary={invite.name} primaryTypographyProps={{ variant: 'body1' }} />
      <ListItemSecondaryAction>
        {(!removing && invite.type === RoomInviteType.USER) && 
          <IconButton
            edge="end"
            aria-label="delete invite"
            onClick={() => handleRemovedInvite(invite.id)}
          >
            <CloseIcon />
          </IconButton>
        }
      </ListItemSecondaryAction>
    </ListItem>
  )

  const inviteListItem = (invites) => <List dense={true}>{invites.map(inviteItem)}</List>

  const emptyInviteListItem = () => (
    <Box padding={2} display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
      <Typography variant="h6" color="textSecondary" align="center">
        No one has been invited yet.
      </Typography>
    </Box>
  )

  return (
    <Box className="invite-list">
      {invites.length > 0 ? inviteListItem(invites) : emptyInviteListItem()}
    </Box>
  )
}

export default React.memo(RoomInviteList)
