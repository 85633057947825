import React from 'react'
import { BaseDialog } from '../../../../../Components/Dialogs/BaseDialog/BaseDialog'
import { DefaultDialogHeader } from '../../../../../Components/Dialogs/Headers/DefaultDialogHeader/DefaultDialogHeader'
import VolumeSlider from '../../../../../Components/VolumeSlider/VolumeSlider'
import { useAudioMixer } from '../../../../../Providers/AudioMixerProvider'
import { Grid } from '@material-ui/core'

interface AudioMixerProps {
  isOpen: boolean
  handleClose: () => void
}

/**
 * Audio Mixer dialog
 * @param isOpen to show/hide dialog
 * @param handleClose on dialog close
 * @param handleStartScreenSharing handle start screen sharing action
 * @constructor
 */
export const AudioMixer = ({ isOpen, handleClose }: AudioMixerProps): JSX.Element => {
  const {
    setMasterVolume,
    masterVolume,
    isStreamVolumeEnabled,
    streamVolume,
    setStreamVolume
  } = useAudioMixer()

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xs"
      header={<DefaultDialogHeader text="Audio Mixer" divider={true} onClose={handleClose} />}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VolumeSlider
            initialValue={streamVolume}
            onVolumeChange={setStreamVolume}
            label="Stream audio"
            disabled={!isStreamVolumeEnabled}
          />
        </Grid>
        <Grid item xs={12}>
          <VolumeSlider
            initialValue={masterVolume}
            onVolumeChange={setMasterVolume}
            label="Participant audio"
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}

export default React.memo(AudioMixer)
