import { useMediaStream } from './useMediaStream'
import { useEffect, useMemo } from 'react'
import { Stream, StreamState } from '../stream'

export const useStreamFromConstraints = (
  constraints: MediaStreamConstraints | null,
  speaker: MediaDeviceInfo | null
) => {
  const [getMediaStream, { stream, isInProgress, error }, clearMediaStream] = useMediaStream()

  useEffect(() => {
    if (constraints) {
      getMediaStream(constraints)
    } else {
      clearMediaStream()
    }
  }, [getMediaStream, clearMediaStream, constraints])

  return useMemo(() => {
    if (error) {
      return {
        state: StreamState.error,
        error
      }
    }
    if (isInProgress) {
      return {
        state: StreamState.obtaining
      }
    }
    if (!stream || !constraints) {
      return {
        state: StreamState.unavailable
      }
    }
    return {
      state: StreamState.available,
      current: stream,
      hasAudioTrack: !!constraints.audio,
      hasVideoTrack: !!constraints.video,
      speaker
    }
  }, [stream, error, isInProgress, constraints, speaker]) as Stream
}
