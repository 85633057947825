import React from 'react'

export interface RoomErrorProps {
  error: Error
  retry: (() => void) | null
}

/**
 * Displays room error when unauthenticated room query fails
 * @param hash
 * @param retry
 * @constructor
 */
const RoomError: React.FC<RoomErrorProps> = ({ retry }) => {
  return <div>Error!</div>
}

export default React.memo(RoomError)
