import React from 'react'
import ConstructionIcon from './ConstructionIcon.svg'
import { Grid, Box } from '@material-ui/core'
import './UnderConstruction.scss'

const UnderConstruction = (): JSX.Element => (
  <Grid
    className="status-component"
    container
    direction="column"
    justify="center"
    alignItems="center"
  >
    <img src={ConstructionIcon} alt="Construction Icon" />
    <Box marginTop={2}>
      <h1 className="status-component-title">This is an upcoming feature.</h1>
    </Box>
  </Grid>
)

export default UnderConstruction
