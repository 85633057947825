import {ReplaySubject} from 'rxjs'

interface ServerStreamInfo {
  id: string
  ownerId: string
  src: string
  progress: number
  isPaused: boolean
  isBuffering: boolean
  contentType: string
  volume: number
}

export class DamClient {
  private streams: Map<string, ServerStreamInfo>
  private playbackStateConnection: WebSocket
  private onStreamStartSubject = new ReplaySubject<ServerStreamInfo>()
  private onStreamStopSubject = new ReplaySubject<ServerStreamInfo>()
  private onStreamProgressSubject = new ReplaySubject<ServerStreamInfo>()
  private streamUrl: string

  constructor(streamUrl, playbackStateConnection) {
    this.playbackStateConnection = playbackStateConnection
    this.playbackStateConnection.onmessage = this.handleMessage.bind(this)
    this.streams = new Map<string, ServerStreamInfo>()
    this.streamUrl = streamUrl
  }

  handleMessage = (event: MessageEvent) => {
    const serverStreams = this.serializeStatusMessage(event)
    this.updateStreams(serverStreams)
    serverStreams.forEach((stream) => {
      this.onStreamProgressSubject.next(stream)
    })
  }

  serializeStatusMessage(event: MessageEvent): Array<ServerStreamInfo> {
    const serverStatuses = JSON.parse(event.data)

    return serverStatuses.streams.map((serverStream) => {
      return {
        id: serverStream.id,
        ownerId: serverStream.ownerId,
        src: `${this.streamUrl}/streams/${serverStream.id}/videoplayer`,
        //src: serverStream.hackUrl,
        progress: serverStream.progressMilliseconds,
        isPaused: serverStream.isPaused,
        isBuffering: serverStream.isBuffering,
        contentType: serverStream.contentType,
        volume: serverStream.volume
      } as ServerStreamInfo
    })
  }

  updateStreams(serverStreams) {
    // New streams are coming from the server
    if (serverStreams.length > this.streams.size) {
      let newStreams = serverStreams.filter((stream) => !this.streams.has(stream.id))
      newStreams.forEach((stream) => {
        this.streams.set(stream.id, stream)
        this.onStreamStartSubject.next(stream)
      })
    }

    // Streams were deleted from the server
    if (serverStreams.length < this.streams.size) {
      let deletedStreamKeys = Array.from(this.streams.keys()).filter(
          (streamKey) => !serverStreams.some((stream) => stream.id === streamKey)
      )
      deletedStreamKeys.forEach((streamKey) => {
        const deletedStream = this.streams.get(streamKey)
        this.streams.delete(streamKey)
        this.onStreamStopSubject.next(deletedStream)
      })
    }
  }

  sendClientStatusMessage(streamId: string, isBuffering: boolean) {
    const message = {
      type: "shared-file-playback-client-status",
      streams: [
        {
          id: streamId,
          isBuffering
        }
      ]
    }
    this.playbackStateConnection.send(JSON.stringify(message))
  }

  onStreamCanPlay(streamId) {
    // TODO: disable spinner
    this.sendClientStatusMessage(streamId, false)
  }

  onStreamWaiting(streamId) {
    // TODO: enable spinner
    this.sendClientStatusMessage(streamId, true)
  }

  onStreamStalled(streamId) {
    // TODO: enable spinner
    this.sendClientStatusMessage(streamId, true)
  }

  onStreamProgress = () => this.onStreamProgressSubject.asObservable()
  onStreamStart = () => this.onStreamStartSubject.asObservable()
  onStreamStop = () => this.onStreamStopSubject.asObservable()
}
