import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  AppFeatureFlag,
  FeatureFlag,
  IOrganizationFeatures,
  IRoomFeatures,
  RoomFeatureFlag,
  UserFeatureFlag,
} from '../../Models/apiEntities'
import { useRoom } from '../../Providers/RoomProvider'
import { useAppFeatureFlags } from '../../Providers/AppFeatureFlagsProvider'
import { useUserFeatureFlags } from '../../Providers/UserFeatureFlagsProvider'
import useApi from '../../API/useApi'

export type FeatureFlagState = [boolean, string | null]

const useFeatureFlag = (key: string, featureFlags: { [key: string]: string }): FeatureFlagState => {
  return useMemo(() => {
    if (key in featureFlags && featureFlags[key] !== 'false') {
      return [true, featureFlags[key]]
    } else {
      return [false, null]
    }
  }, [key, featureFlags])
}

const convertArraytoObject = (featuresFlags: FeatureFlag<string>[]) =>
  featuresFlags.reduce((features, feature) => {
    features[feature.key] = feature.value
    return features
  }, {})

/**
 * Retrieves if given user feature flag is present and it's value
 *
 * @param userFeatureFlag user feature flag to retrieve
 * @returns [isPresent, value]
 */
export const useUserFeatureFlag = (userFeatureFlag: UserFeatureFlag) => {
  const userFeatureFlags = useUserFeatureFlags()
  return useFeatureFlag(userFeatureFlag, convertArraytoObject(userFeatureFlags))
}

/**
 * Retrieves if given app feature flag is present and it's value
 *
 * @param appFeatureFlag app feature flag to retrieve
 * @returns [isPresent, value]
 */
export const useAppFeatureFlag = (appFeatureFlag: AppFeatureFlag) => {
  const appFeatureFlags = useAppFeatureFlags()
  return useFeatureFlag(appFeatureFlag, convertArraytoObject(appFeatureFlags))
}

/**
 * Retrieves if given room feature flag is present and it's value
 *
 * @param roomFeatureFlag room feature flag to retrieve
 * @returns [isPresent, value]
 */
export const useRoomFeatureFlag = (roomFeatureFlag: RoomFeatureFlag) => {
  const room = useRoom()
  const { Organization } = useApi()
  const { data } = useQuery('organizations', () =>
    Organization.getFeatures(room?.organizationId as string)
  )
  return useFeatureFlag(roomFeatureFlag, (data ?? {}) as { [key: string]: string })
}

interface RoomFeatureFlagsRest {
  ADVANCED_VIDEO_MUTE: boolean
  ALLOW_PUBLIC_ROOMS: boolean
  ALLOW_REQUIRED_ROOM_MFA: boolean
  ALLOW_RECORDING: boolean
  ALLOW_RECORDING_DL: boolean
  ALLOW_ROOM_CHAT: boolean
  ALLOW_ROOM_CHAT_DL: boolean
  ALLOW_ROOM_NOTES: boolean
  ALLOW_ROOM_NOTES_DL: boolean
  ALLOW_SCREENSHARE_2K: boolean
  ALLOW_SCREENSHARE_4K: boolean
  CHATS: boolean
  DRAW_RECORDING: boolean
  MUTE_UPON_ENTRY: boolean
  PLAY_ENTRANCE_SOUND: boolean
  RECORDING: boolean
  ROOM_OWNER_CHAT_CONTROL: boolean
  VIDEO_WATERMARK: boolean
  ALLOW_WATERMARK: boolean
  ALLOW_ROOM_DRAW_TOOL: boolean
  ALLOW_ROOM_DO_NOT_DISTURB: boolean
  ALLOW_FORENSIC_WATERMARKING: boolean
}

const overrideRoomFeatureIfNeeded = (roomData: IRoomFeatures, orgData: IOrganizationFeatures) => {
  const roomFlags = {}
  if (roomData && orgData)
    for (let [key, props] of Object.entries(roomData ?? {})) {
      const isNotConfigurable = orgData[key]?.isConfigurable === false
      // use value from room props
      roomFlags[key] = props.value === 'true'
      // if not configurable, use value from org props
      if (isNotConfigurable) roomFlags[key] = orgData[key].value === 'true'
    }
  return roomFlags
}

export const useRoomFeatureFlagsRest = (): RoomFeatureFlagsRest => {
  const room = useRoom()
  const { Organization, Room } = useApi()
  const { data: roomData } = useQuery('room', () => Room.getFeatures(room.id))
  const { data: orgData } = useQuery('organization', () =>
    Organization.getFeatures(room.organizationId as string)
  )

  const flags = overrideRoomFeatureIfNeeded(
    roomData as IRoomFeatures,
    orgData as IOrganizationFeatures
  )

  return flags as RoomFeatureFlagsRest
}

const renderWithFeatureFlag = (
  children: React.ReactNode,
  [isPresent, value]: FeatureFlagState,
  predicate: (value: string) => boolean = () => true,
  renderNotMatching?: () => React.ReactNode,
  renderNotPresent?: () => React.ReactNode
) => {
  if (!isPresent) {
    return renderNotPresent ? renderNotPresent() : null
  }
  if (!predicate(value!)) {
    return renderNotMatching ? renderNotMatching() : null
  }
  return children
}

interface WithFeatureFlagProps<T extends string> {
  featureFlag: T
  predicate?: (value: string) => boolean
  renderNotMatching?: () => React.ReactNode
  renderNotPresent?: () => React.ReactNode
}

/**
 * Component for conditionally rendering based on user feature flag
 *
 * @param featureFlag         user feature flag
 * @param predicate           optional predicate to match with feature flag value
 * @param children            content to render if feature flag is present and matched
 * @param renderNotMatching   optional rendering if feature flag is present but not matching
 * @param renderNotPresent    optional rendering if feature flag is not present
 */
export const WithUserFeatureFlag: React.FC<WithFeatureFlagProps<UserFeatureFlag>> = ({
  featureFlag,
  predicate,
  renderNotMatching,
  renderNotPresent,
  children,
}) => {
  return (
    <>
      {renderWithFeatureFlag(
        children,
        useUserFeatureFlag(featureFlag),
        predicate,
        renderNotMatching,
        renderNotPresent
      )}
    </>
  )
}

/**
 * Component for conditionally rendering based on app feature flag
 *
 * @param featureFlag         user feature flag
 * @param predicate           optional predicate to match with feature flag value
 * @param children            content to render if feature flag is present and matched
 * @param renderNotMatching   optional rendering if feature flag is present but not matching
 * @param renderNotPresent    optional rendering if feature flag is not present
 */
export const WithAppFeatureFlag: React.FC<WithFeatureFlagProps<AppFeatureFlag>> = ({
  featureFlag,
  predicate,
  renderNotMatching,
  renderNotPresent,
  children,
}) => {
  return (
    <>
      {renderWithFeatureFlag(
        children,
        useAppFeatureFlag(featureFlag),
        predicate,
        renderNotMatching,
        renderNotPresent
      )}
    </>
  )
}

/**
 * Component for conditionally rendering based on room feature flag
 *
 * @param featureFlag         user feature flag
 * @param predicate           optional predicate to match with feature flag value
 * @param children            content to render if feature flag is present and matched
 * @param renderNotMatching   optional rendering if feature flag is present but not matching
 * @param renderNotPresent    optional rendering if feature flag is not present
 */
export const WithRoomFeatureFlag: React.FC<WithFeatureFlagProps<RoomFeatureFlag>> = ({
  featureFlag,
  predicate,
  renderNotMatching,
  renderNotPresent,
  children,
}) => {
  return (
    <>
      {renderWithFeatureFlag(
        children,
        useRoomFeatureFlag(featureFlag),
        predicate,
        renderNotMatching,
        renderNotPresent
      )}
    </>
  )
}
