import { EnvName, Environment } from '../environments/environments'
import testConfig from '../environments/test'
import localConfig from '../environments/local'
import devConfig from '../environments/dev'
import stageConfig from '../environments/stage'
import prodConfig from '../environments/prod'
import liteConfig from '../environments/lite'
import alphaConfig from '../environments/alpha'
import kiteConfig from '../environments/kite'
import qaConfig from '../environments/qa'
import betaConfig from '../environments/beta'

const environmentConfigs = {
  [EnvName.test]: testConfig,
  [EnvName.local]: localConfig,
  [EnvName.dev]: devConfig,
  [EnvName.stage]: stageConfig,
  [EnvName.prod]: prodConfig,
  [EnvName.lite]: liteConfig,
  [EnvName.alpha]: alphaConfig,
  [EnvName.beta]: betaConfig,
  [EnvName.kite]: kiteConfig,
  [EnvName.qa]: qaConfig
}

/**
 * Get environment Configuration
 *
 * @param {EnvName} envName environment name
 * @return {Environment} Environment configuration
 */
export const getEnvironmentConfig = (envName: EnvName): Environment => {
  const environmentConfig = environmentConfigs[envName]
  if (environmentConfig) {
    return environmentConfig()
  }
  throw new Error(`Environment name: ${envName} is not valid`)
}
