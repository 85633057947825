import React, { useEffect, useState } from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { isEmpty } from 'ramda'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Link from '@material-ui/core/Link'

import RoomCard from './RoomCard/RoomCard'
import { useHome } from './hooks/useHome'
import IconButton from '../../../../../Components/Buttons/IconButton/IconButton'

import './HomeView.scss'
import GeneralPadding from '../../../../../Components/GeneralPadding/GeneralPadding'
import RoomDialog from '../../../../../Components/RoomDialog/RoomDialog'
import { useDialog } from '../../../../../Components/RoomDialog/hooks/useDialog'
import DeleteRoomFromDashboardDialog
  from '../../../../../Components/DeleteRoomDialog/DeleteRoomFromDashboardDialog/DeleteRoomFromDashboardDialog'
import { RemoveRoomFromDashboardState, useRemoveRoomFromDashboard } from './hooks/useRemoveRoomFromDashboard'
import Banner from '../../../../../Components/Banner/Banner'
import RemovedFromRoomDialog from '../../../../../Components/RemovedFromRoomDialog/RemovedFromRoomDialog'
import { useLocation } from 'react-router-dom'
import { useNavigation } from '../../../../../Common/hooks/useNavigation'
import DeniedDndFromRoomDialog from '../../../../../Components/DeniedDndFromRoomDialog/DeniedDndFromRoomDialog'

interface HomeViewProps {
  canCreateRoom: boolean
  currentProfile: any
  refreshGraphQLQuery: () => void
}

/**
 * User's home view
 * @param canCreateRoom
 * @param currentProfile current user profile
 * @constructor
 */
export const HomeView: React.FC<HomeViewProps> = ({ canCreateRoom, currentProfile }) => {
  const { rooms, availableRooms, updateAvailableRoomsAfterDelete, refreshRooms } = useHome(
    currentProfile
  )
  const [isRoomDialogOpened, openRoomDialog, closeRoomDialog] = useDialog()

  const { pathname, state } = useLocation()
  const [kickRoomName, setKickRoomName] = useState('')
  const [deniedDnd, setDeniedDnd] = useState(false)
  const { clearLocationState } = useNavigation()

  useEffect(() => {
    if (state) {
      const { kickRoomName, deniedDnd } = state as any
      if (kickRoomName) {
        setKickRoomName(kickRoomName)
      } else {
        setKickRoomName('')
      }
      if (deniedDnd) {
        setDeniedDnd(deniedDnd)
      } else {
        setDeniedDnd(false)
      }
    } else {
      setKickRoomName('')
      setDeniedDnd(false)
    }
  }, [state])

  const {
    removeFromDashboardState,
    removefromDashboard,
    closeRemoveFromDashboardConfirmation,
    isRemoveFromDashboardConfirmationOpen,
    selectRoomToRemoveFromDashboard,
    selectedRoomToRemove,
  } = useRemoveRoomFromDashboard()

  return (
    <>
      <Banner id="download-app-banner" startTime={new Date('2021-11-17 16:00:00.000Z PST')}>
        <Grid container justify="center" alignItems="center">
          <a
            style={{ fontSize: '1rem', padding: '12px' }}
            href="https://www.evercast.us/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            Want to use the latest features for streaming and for color?{' '}
            <strong>Download the Evercast app</strong> for macOS and Windows
          </a>
        </Grid>
      </Banner>
      <GeneralPadding>
        <Grid container direction="column" spacing={5} className="home-view">
          <Grid item>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  Dashboard
                </Typography>
                <Typography variant="h5">Welcome back, {currentProfile.displayName}</Typography>
                {isEmpty(rooms) ? (
                  <Typography variant="body1" color="textSecondary">
                    You currently don&apos;t belong to any rooms
                    {canCreateRoom && (
                      <>
                        ,
                        <Link
                          variant="body1"
                          color="secondary"
                          onClick={openRoomDialog}
                          display="inline"
                        >
                          {' '}
                          create one now
                        </Link>
                      </>
                    )}
                    .
                  </Typography>
                ) : null}
              </Grid>
              {canCreateRoom && (
                <Grid item>
                  <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle2">
                        <strong>Room credits:</strong> {availableRooms}
                      </Typography>
                    </Grid>
                    {availableRooms >= 0 && (
                      <Grid item>
                        <IconButton
                          icon={AddIcon}
                          onClick={openRoomDialog}
                          className="new-room-btn"
                        />
                        <RoomDialog
                          isOpen={isRoomDialogOpened}
                          handleClose={closeRoomDialog}
                          onSuccess={closeRoomDialog}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!isEmpty(rooms) ? (
            <Grid item>
              <Grid container spacing={4}>
                {rooms.map((room: any) => (
                  <Grid key={room.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <RoomCard
                      room={room}
                      currentProfile={currentProfile}
                      selectRoomToRemoveFromDashboard={selectRoomToRemoveFromDashboard}
                      updateAvailableRoomsAfterDelete={updateAvailableRoomsAfterDelete}
                      refreshRooms={refreshRooms}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <DeleteRoomFromDashboardDialog
          isOpen={isRemoveFromDashboardConfirmationOpen}
          room={selectedRoomToRemove!}
          handleClose={closeRemoveFromDashboardConfirmation}
          onConfirm={removefromDashboard}
          isLoading={removeFromDashboardState === RemoveRoomFromDashboardState.submitting}
          removeDone={removeFromDashboardState === RemoveRoomFromDashboardState.success}
        />
      </GeneralPadding>
      <RemovedFromRoomDialog
        isOpen={kickRoomName?.length !== 0}
        roomName={kickRoomName}
        onClose={() => {
          clearLocationState(pathname)
        }}
      />
      <DeniedDndFromRoomDialog
        isOpen={deniedDnd}
        onClose={() => {
          clearLocationState(pathname)
        }}
      />
    </>
  )
}

export default createFragmentContainer(HomeView, {
  currentProfile: graphql`
    fragment HomeView_currentProfile on Profile {
      type
      id
      displayName
      availableCredits
      featureFlags {
        nodes {
          key
          value
        }
      }
    }
  `,
})
