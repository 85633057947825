import React, { memo } from 'react'

import { Box } from '@material-ui/core'
import HorizontalLayout from '../HorizontalLayout/HorizontalLayout'
import ExpandableBox from '../HorizontalLayout/ExpandableBox/ExpandableBox'
import './ControlBar.scss'

interface ControlBarProps {
  leftContent: React.ReactNode
  middleContent: React.ReactNode
  rightContent: React.ReactNode
  leftContentPercentage: number
  leftContentMinWidth?: number
  leftContentMaxWidth?: number
  rightContentPercentage: number
  rightContentMinWidth?: number
  rightContentMaxWidth?: number
}

const ControlBar: React.FC<ControlBarProps> = ({
  leftContent,
  middleContent,
  rightContent,
  leftContentPercentage,
  leftContentMinWidth,
  leftContentMaxWidth,
  rightContentPercentage,
  rightContentMinWidth,
  rightContentMaxWidth
}) => {
  return (
    <Box paddingY={2} paddingX={2} className="control-bar-root" data-testid="control-bar-root">
      <HorizontalLayout>
        <Box
          width={`${leftContentPercentage}%`}
          minWidth={leftContentMinWidth}
          maxWidth={leftContentMaxWidth}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {leftContent}
        </Box>
        <ExpandableBox display="flex" alignItems="center" justifyContent="center">
          {middleContent}
        </ExpandableBox>
        <Box
          width={`${rightContentPercentage}%`}
          minWidth={rightContentMinWidth}
          maxWidth={rightContentMaxWidth}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {rightContent}
        </Box>
      </HorizontalLayout>
    </Box>
  )
}

export default memo(ControlBar)
