import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { ReactComponent as Apple } from '../../../../../../../Static/images/apple.svg'
import { ReactComponent as Recordings } from '../../../../../../../Static/images/recordings.svg'
import { ReactComponent as EBS } from '../../../../../../../Static/images/evercast.svg'
import { ReactComponent as RoomAccess } from '../../../../../../../Static/images/room_access.svg'
import { ReactComponent as Stream } from '../../../../../../../Static/images/stream.svg'
import { ReactComponent as Microphone } from '../../../../../../../Static/images/microphone.svg'
import { ReactComponent as Screenshare } from '../../../../../../../Static/images/screenshare.svg'
import { ReactComponent as Settings } from '../../../../../../../Static/images/settings.svg'
import { ReactComponent as ThumbnailOn } from '../../../../../../../Static/images/thumbnail_on.svg'
import { ReactComponent as ThumbnailOff } from '../../../../../../../Static/images/thumbnail_off.svg'
import { ReactComponent as ShowToolbar } from '../../../../../../../Static/images/show_toolbar.svg'
import { ReactComponent as HideToolbar } from '../../../../../../../Static/images/hide_toolbar.svg'
import { ReactComponent as DnD } from '../../../../../../../Static/images/do_not_diturb.svg'
import ControlBar from '../../../../../../../Components/ControlBar/ControlBar'
import CameraToggleButton from '../../Buttons/CameraToggleButton/CameraToggleButton'
import MicrophoneToggleButton from '../../Buttons/MicrophoneToggleButton/MicrophoneToggleButton'
import AudioToggleButton from '../../../../../../../Components/ControlBar/Buttons/AudioToggleButton/AudioToggleButton'
import DrawToggleButton from '../../../../../../../Components/ControlBar/Buttons/DrawToggleButton/DrawToggleButton'
import LayoutButton from '../../../../../../../Components/ControlBar/Buttons/LayoutButton/LayoutButton'
import FullScreenToggleButton
  from '../../../../../../../Components/ControlBar/Buttons/FullScreenToggleButton/FullScreenToggleButton'
import LeaveRoomButton from '../../../../../../../Components/ControlBar/Buttons/LeaveRoomButton/LeaveRoomButton'
import RecordSessionButton
  from '../../../../../../../Components/ControlBar/Buttons/RecordSessionButton/RecordSessionButton'
import MoreButton from '../../../../../../../Components/ControlBar/Buttons/MoreButton/MoreButton'
import HorizontalLayout from '../../../../../../../Components/HorizontalLayout/HorizontalLayout'
import { useDisplayingFormat } from '../../../../../../../Common/hooks/useDisplayingFormat'
import InstantHelpButton from '../../../../../../../Components/ControlBar/Buttons/InstantHelpButton/InstantHelpButton'
import AudioMixerToggleButton
  from '../../../../../../../Components/ControlBar/Buttons/AudioMixerToggleButton/AudioMixerToggleButton'
import { WithCondition } from '../../../../../../../Components/WithCondition/WithCondition'
import { Option } from '../../../../../../../Components/Menu/Menu'
import {
  AppleTVLoginControl,
  AudioControl,
  AudioMixerControl,
  BrowserStreamControl,
  CameraControl,
  DeviceSelectionControl,
  DnDAccessControl,
  EbsKeysControl,
  FullScreenModeControl,
  MicrophoneControl,
  RecordingControl,
  RoomAccessControl,
  RoomSettingsControl,
  ScreenShareControl,
} from '../hooks/useLiveroom'
import { useNavigation } from '../../../../../../../Common/hooks/useNavigation'
import { BrowserType, getBrowserInformation } from '../../../../../../../Common/utils/browserCheck'
import { routes } from '../../../../routes'
import './LiveRoomControlBar.scss'
import ChatToggleButton from '../../../../../../../Components/Buttons/ChatToggleButton/ChatToggleButton'
import RoomSpecialInviteButton
  from '../../../../../../../Components/RoomAccess/RoomSpecialInviteInput/RoomSpecialInviteButton/RoomSpecialInviteButton'
import { useOpenSupportChat } from '../hooks/useOpenSupportChat'
import { ReactComponent as MuteRoom } from '../../../../../../../Static/images/microphone_disabled.svg'
import { useAlerts } from '../../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../../Components/Alert/Alert'
import { useRoomFeatureFlagsRest } from '../../../../../../../Common/utils/featureFlags'
import PeopleListButton from '../../../../../../../Components/Buttons/ParticipantListButton/ParticipantListButton'

interface LiveRoomControlBarProps {
  chat: {
    isAvailable: boolean
    isOpened: boolean
    hasNotification: boolean
    toggleVisibility: (boolean) => void
  }
  drawTool: {
    isAvailable: boolean
    isOpened: boolean
    toggleVisibility: (boolean) => void
  }
  thumbnails: {
    areVisible: boolean
    toggleVisibility: (boolean) => void
  }
  toolbar: {
    isShowAlways: boolean
    toggleVisibility: (boolean) => void
  }
  participantList: {
    isAvailable: boolean
    isOpened: boolean
    toggleVisibility: (boolean) => void
  }
  camera: CameraControl
  microphone: MicrophoneControl
  audio: AudioControl
  fullScreenMode: FullScreenModeControl
  deviceSelection: DeviceSelectionControl
  roomAccess: RoomAccessControl
  roomSettings: RoomSettingsControl
  ebsKeys: EbsKeysControl
  screenShare: ScreenShareControl
  browserStream: BrowserStreamControl
  recording: RecordingControl
  instantHelp: {
    isAvailable: boolean
  }
  appleTVLogin: AppleTVLoginControl
  audioMixer: AudioMixerControl
  roomId: string
  roomName: string
  hasInvitePermission: boolean
  isPublic: boolean
  roomHash: string
  currentProfile: {
    id: string
    displayName: string
    email: string | null
  }
  participantsManager
  mutePermissionInfo
  participants
  dndControl: DnDAccessControl
  showRecordingsClips: boolean
}

const LiveRoomControlBar: React.FC<LiveRoomControlBarProps> = ({
  chat,
  drawTool,
  thumbnails,
  toolbar,
  participantList,
  camera,
  microphone,
  audio,
  fullScreenMode,
  deviceSelection,
  roomSettings,
  roomAccess,
  ebsKeys,
  screenShare,
  browserStream,
  recording,
  instantHelp,
  appleTVLogin,
  audioMixer,
  roomId,
  roomName,
  hasInvitePermission,
  isPublic,
  roomHash,
  currentProfile,
  participantsManager,
  mutePermissionInfo,
  dndControl,
  showRecordingsClips,
  participants,
}) => {
  const { goToDashboard } = useNavigation()

  const {
    isDesktop: isDesktopLayout,
    isTablet: isTabletLayout,
    isPhone: isPhoneLayout,
  } = useDisplayingFormat()
  const { browserType } = getBrowserInformation()

  const isDesktopBrowser = useMemo(
    () => browserType === BrowserType.desktopChrome || browserType === BrowserType.desktopSafari,
    [browserType]
  )
  const openSupportChat = useOpenSupportChat(
    currentProfile.displayName,
    currentProfile.email,
    roomName
  )

  const alert = useAlerts()

  const moreButtonMenu = useMemo(() => {
    const menu: Array<Option> = [
      {
        text: dndControl.isActive ? 'Disable Do Not Disturb' : 'Enable Do Not Disturb',
        icon: <DnD />,
        onClick: dndControl.dialog.open,
        isAvailable: () => dndControl.isAvailable && dndControl.isAllowed,
      },
      {
        text: 'Mute Room',
        icon: <MuteRoom />,
        onClick: () => {
          participantsManager.muteAllParticipants()
          alert('Room muted', AlertType.success)
        },
        isAvailable: () => mutePermissionInfo.isAvailable && mutePermissionInfo.isAllowed,
      },
      {
        text: recording.isRecording ? 'Stop Recording' : 'Record',
        icon: <Microphone color={recording.isRecording ? 'secondary' : 'primary'} />,
        isAvailable: () => isTabletLayout && recording.isAvailable,
        onClick: recording.handleClick,
        disabled: !recording.isEnabled,
      },
      {
        text: 'Room access',
        icon: <RoomAccess />,
        onClick: roomAccess.dialog.open,
        isAvailable: () => roomAccess.isAvailable,
      },
      {
        text: 'Device settings',
        icon: <Settings />,
        onClick: deviceSelection.openDialog,
        isAvailable: () => deviceSelection.isAvailable,
      },
      {
        text: 'EBS stream keys',
        icon: <EBS />,
        onClick: ebsKeys.dialog.open,
        isAvailable: () => ebsKeys.isAvailable,
      },
      {
        text: 'Room settings',
        icon: <Settings />,
        onClick: roomSettings.dialog.open,
        isAvailable: () => roomSettings.isAvailable,
      },
      {
        text: 'Recordings & clips',
        icon: <Recordings />,
        onClick: () => {
          window.open(routes.recordings.path.replace(':roomId', roomId), '_blank')
        },
        isAvailable: () => showRecordingsClips,
      },
      {
        text: screenShare.isActive ? 'Stop screen share' : 'Screen share',
        icon: screenShare.isActive ? <Screenshare color="secondary" /> : <Screenshare />,
        onClick: screenShare.isActive ? screenShare.stopScreenShare : screenShare.dialog.open,
        isAvailable: () => screenShare.isAvailable && isDesktopBrowser,
      },
      {
        text: browserStream.isActive ? 'Stop browser stream' : 'Browser stream',
        icon: browserStream.isActive ? <Stream color="secondary" /> : <Stream />,
        onClick: browserStream.isActive
          ? browserStream.stopBrowserStream
          : browserStream.dialog.open,
        isAvailable: () => browserStream.isAvailable && isDesktopBrowser,
      },
      {
        text: 'Apple TV sign-in',
        icon: <Apple />,
        onClick: appleTVLogin.dialog.open,
        isAvailable: () => appleTVLogin.isAvailable,
      }
    ]
    return menu
  }, [isTabletLayout, isDesktopBrowser, roomId, dndControl, participantsManager, alert, mutePermissionInfo, recording, roomAccess, deviceSelection, ebsKeys, roomSettings, showRecordingsClips, screenShare, browserStream, appleTVLogin])

  const layoutButtonMenu = useMemo(() => {
    const menu: Array<Option> = [
      {
        text: thumbnails.areVisible ? 'Hide thumbnails' : 'Show thumbnails',
        icon: thumbnails.areVisible ? <ThumbnailOff /> : <ThumbnailOn />,
        onClick: thumbnails.toggleVisibility,
      },
      {
        text: toolbar.isShowAlways ? 'Auto-hide toolbar' : 'Always show toolbar',
        icon: toolbar.isShowAlways ? <HideToolbar /> : <ShowToolbar />,
        onClick: toolbar.toggleVisibility,
      },
    ]
    return menu
  }, [thumbnails, toolbar])

  const { ALLOW_REQUIRED_ROOM_MFA, ALLOW_FORENSIC_WATERMARKING } = useRoomFeatureFlagsRest()

  const toggleChat = (open) => {
    if (participantList.isOpened) {
      participantList.toggleVisibility(false)
    }
    chat.toggleVisibility(open)
  }

  const togglePeople = (open) => {
    if (chat.isOpened) {
      chat.toggleVisibility(false)
    }
    participantList.toggleVisibility(open)
  }

  return (
    <ControlBar
      leftContentPercentage={!isPhoneLayout ? 25 : 0}
      leftContentMinWidth={!isPhoneLayout ? 200 : 0}
      rightContentPercentage={isDesktopLayout ? 25 : isTabletLayout ? 10 : 0}
      rightContentMinWidth={isDesktopLayout ? 200 : isTabletLayout ? 55 : 0}
      leftContent={
        <WithCondition condition={!isPhoneLayout}>
          <Box display="flex" alignItems="flex-end">
            <LeaveRoomButton onClick={goToDashboard} />
            <Box display="flex" paddingLeft={{ lg: 4, xl: 12 }} flexDirection="column">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="button">{roomName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <WithCondition
                  condition={hasInvitePermission && !isPublic && !ALLOW_REQUIRED_ROOM_MFA && !ALLOW_FORENSIC_WATERMARKING}
                >
                  <RoomSpecialInviteButton roomHash={roomHash} roomId={roomId} />
                </WithCondition>
              </Box>
            </Box>
          </Box>
        </WithCondition>
      }
      middleContent={
        <HorizontalLayout
          alignItems="center"
          justifyContent="space-between"
          maxWidth={isPhoneLayout ? 180 : 'max-content'}
          className="horizontal-layout"
        >
          <WithCondition condition={microphone.isAvailable}>
            <MicrophoneToggleButton
              className="control-bar-button room-control-button"
              isActive={microphone.isEnabled}
              onToggle={microphone.setIsEnabled}
              noDevices={microphone.noDevices}
              microphoneSetToNone={microphone.microphoneSetToNone}
            />
          </WithCondition>
          <WithCondition condition={camera.isAvailable}>
            <CameraToggleButton
              className="control-bar-button room-control-button"
              isActive={camera.isEnabled}
              onToggle={camera.setIsEnabled}
              noDevices={camera.noDevices}
              cameraSetToNone={camera.cameraSetToNone}
            />
          </WithCondition>
          <AudioToggleButton
            className="control-bar-button room-control-button"
            isActive={audio.isEnabled}
            onToggle={audio.setIsEnabled}
          />
          <WithCondition condition={!isPhoneLayout}>
            <WithCondition condition={audioMixer.isAvailable}>
              <AudioMixerToggleButton
                className="control-bar-button room-control-button"
                isActive={audioMixer.dialog.isOpen}
                onToggle={audioMixer.dialog.open}
              />
            </WithCondition>
            <ChatToggleButton
              isActive={!chat.isOpened}
              onToggle={toggleChat}
              hasNotification={chat.hasNotification}
              className="control-bar-button room-control-button chat-toggle-button"
            />
            <PeopleListButton
              isActive={!participantList.isOpened}
              onToggle={togglePeople}
              className="control-bar-button room-control-button participant-list-toggle-button"
              participantCount={participants?.length}
            />
            <WithCondition condition={drawTool.isAvailable}>
              <DrawToggleButton
                className="control-bar-button room-control-button"
                isActive={!drawTool.isOpened}
                onToggle={drawTool.toggleVisibility}
              />
            </WithCondition>
            <LayoutButton layoutMenuOptions={layoutButtonMenu} className="room-control-button" />
            <WithCondition condition={isDesktopBrowser && fullScreenMode.isAvailable}>
              <FullScreenToggleButton
                className="control-bar-button room-control-button"
                isActive={fullScreenMode.isEnabled}
                onToggle={fullScreenMode.setIsEnabled}
              />
            </WithCondition>
          </WithCondition>
        </HorizontalLayout>
      }
      rightContent={
        <WithCondition condition={!isPhoneLayout}>
          <HorizontalLayout
            justifyContent="flex-end"
            alignItems="flex-start"
            maxWidth={320}
            paddingLeft={3}
            marginY={-4}
          >
            <WithCondition condition={isDesktopLayout}>
              <WithCondition condition={instantHelp.isAvailable}>
                <InstantHelpButton onClick={openSupportChat} />
              </WithCondition>
              <WithCondition condition={recording.isAvailable}>
                <RecordSessionButton
                  isDisabled={!recording.isEnabled}
                  onClick={recording.handleClick}
                  isRecording={recording.isRecording}
                />
              </WithCondition>
            </WithCondition>
            <MoreButton moreMenuOptions={moreButtonMenu} />
          </HorizontalLayout>
        </WithCondition>
      }
    />
  )
}

export default React.memo(LiveRoomControlBar)
