import React, { useCallback } from 'react'
import { Box } from '@material-ui/core'
import SizeSlider from '../../../SizeSlider/SizeSlider'
import MenuItemWithSubMenu from '../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import {
  useDrawCanvasToolsContext,
  DRAW_TOOLS
} from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'
import { DrawToolIcon } from '../../../DrawToolIcon/DrawToolIcon'

const Eraser: React.FC = () => {
  const {
    drawPreferences: { drawThickness, drawTool },
    setDrawThickness,
    setDrawTool
  } = useDrawCanvasToolsContext()

  const onChangeCommittedHandler = useCallback(
    (event, value) => {
      setDrawTool(DRAW_TOOLS.ERASER)
      setDrawThickness(value)
    },
    [setDrawThickness, setDrawTool]
  )

  const onClickEraserHandler = useCallback(() => {
    setDrawTool(DRAW_TOOLS.ERASER)
  }, [setDrawTool])

  return (
    <MenuItemWithSubMenu
      icon={<DrawToolIcon drawTool={DRAW_TOOLS.ERASER} />}
      onClick={onClickEraserHandler}
      selected={drawTool === DRAW_TOOLS.ERASER}
      isOpen={drawTool === DRAW_TOOLS.ERASER}
      label="Eraser"
    >
      <Box pt={1.5} pb={3} pr={1.5} pl={2.5}>
        <SizeSlider
          key={drawThickness}
          onChangeCommitted={onChangeCommittedHandler}
          initialValue={drawThickness}
        />
      </Box>
    </MenuItemWithSubMenu>
  )
}

export default React.memo(Eraser)
