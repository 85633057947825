import React from 'react'
import { Box, MenuItem as MUIMenuItem, Typography, ListItemIcon, Collapse } from '@material-ui/core'
import './MenuItemWithSubMenu.scss'
import palette from '../../../../../../../../Common/theme/colors/palette'

interface MenuItemProps {
  children?: React.ReactNode
  icon?: React.ReactElement
  label: string
  onClick?: (args?: any) => void
  dense?: boolean
  selected?: boolean
  style?: Record<string, any>
  isOpen?: boolean
}

/**
 * LiveRoomDrawer - wrapper for MUI MenuItem, adding the submenu functionality
 * @param currentProfile - the profile of the current user logged in
 * @param children - the component that will be rendered as submenu
 * @param selected - flag that indicates if the user has selected the menu item
 * @param dense - removes the padding of menu item
 * @param icon - icon that will be used to
 * @param label - label that will be used for menu item
 * @param onClick - action that will be triggered once the user clicks the menu item, if it has submenu, it will only display the submenu
 * @param onSubmenuEnter - action that will be triggered once the submenu is shown
 * @constructor
 */
const MenuItemWithSubMenu: React.FC<MenuItemProps> = ({
  children,
  selected,
  dense = false,
  icon,
  label,
  onClick = () => {},
  style,
  isOpen
}) => {
  return (
    <>
      <MUIMenuItem
        className="menu-item-with-submenu"
        onClick={onClick}
        dense={dense}
        selected={selected}
        style={style}
      >
        {icon && <ListItemIcon data-testid="menu-item-with-submenu-icon">{icon}</ListItemIcon>}
        <Typography variant="inherit">{label}</Typography>
      </MUIMenuItem>
      {children && (
        <Collapse in={isOpen} unmountOnExit>
          <Box
            bgcolor={palette.background.secondary}
            paddingX={1}
            className="menu-item-with-submenu-inner"
          >
            {children}
          </Box>
        </Collapse>
      )}
    </>
  )
}

export default MenuItemWithSubMenu
