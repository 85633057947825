import React from 'react'
import { Grid } from '@material-ui/core'
import AdaptiveTitle from '../AdaptiveTitle/AdaptiveTitle'

interface SuccessNotificationProps {
  title: string
  children: React.ReactNode
}

/**
 * Component for building common error notifications
 */
const ErrorNotification: React.FC<SuccessNotificationProps> = ({ children, title }) => {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <AdaptiveTitle>{title}</AdaptiveTitle>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <>
          Need help? Visit&nbsp;
          <a href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
            <strong>evercast.us</strong>
          </a>
        </>
      </Grid>
    </Grid>
  )
}

export default React.memo(ErrorNotification)
