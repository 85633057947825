import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../../Common/utils/formUtils'
import { AppleTVFormInput } from './useLoginWithAppleTVMutation'

export const useAppleTVLoginForm = ({ performLoginWithAppleTV }) => {
  const formMethods = useForm({ shouldFocusError: true })
  const { handleSubmit, formState, reset, clearErrors } = formMethods

  const { isSubmitted, isDirty, isValid } = formState

  const inputs = useFormValidation(formMethods, {
    code: {
      required: 'Please provide a code'
    }
  })

  useEffect(() => {
    if (isDirty) {
      clearErrors()
    }
  }, [clearErrors, isDirty])

  useEffect(() => {
    if (isSubmitted && isValid) {
      reset(inputs.code, { errors: true })
    }
  }, [reset, isSubmitted, isValid, inputs])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((formData) => {
        performLoginWithAppleTV({ ...formData } as AppleTVFormInput)
      }),
    [performLoginWithAppleTV, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs
  }
}
