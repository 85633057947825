import Bowser from 'bowser'
import { RTCConnectivityStats } from './useGetRTCConnectivity'
import { lowerCase } from 'lodash'

const bytesToString = (bytes: number | undefined) => {
  if (bytes === undefined) return undefined

  // TODO: these are in bits, but I think they should be in bytes. Making them bits to be the same as desktop.

  return `${((bytes * 8) / 1000000).toFixed(2)} Mbps`

  // if (bytes * 8 > 1000) return `${((bytes * 8) / 1000).toFixed(1)} Kbps`

  // return `${(bytes * 8).toFixed(0)} bps`
}

const inferPlatform = (operatingSystem?: string) => {
  if (!operatingSystem) {
    return undefined
  }
  const _operatingSystem = operatingSystem.toLowerCase()
  switch (_operatingSystem) {
    case 'windows':
    case 'apple':
    case 'linux':
      return 'Desktop'
    case 'tvos':
      return 'Apple TV'
    case 'ios':
      return 'iOS'
    case 'visionos':
      return 'Vision OS'
    default:
      return 'Unknown'
  }
}

const convertOSName = (operatingSystem?: string) => {
  if (!operatingSystem) {
    return undefined
  }
  // For macOS we want to display "Apple" to be consistent with desktop app
  return operatingSystem.toLowerCase() === 'macos' ? 'Apple' : operatingSystem
}

export const displayNetworkConnectivityStats = (stats?: Partial<RTCConnectivityStats>) => {
  if (!stats)
    return {
      uploadBitRate: undefined,
      downloadBitRate: undefined,
      rtt: undefined,
      jitter: undefined,
      platform: undefined,
      platformVersion: undefined,
      operatingSystem: undefined,
    }
  const {
    uploadByteRate: _uploadByteRate,
    downloadByteRate: _downloadByteRate,
    uploadBitRate: _uploadBitRate,
    downloadBitRate: _downloadBitRate,
    rtt: _rtt,
    jitter: _jitter,
    platform: _platform,
    platformVersion: _platformVersion,
    operatingSystem: _operatingSystem,
  } = stats

  const uploadBitRate: string | undefined = _uploadBitRate
    ? bytesToString(_uploadBitRate)
    : bytesToString(_uploadByteRate)
  const downloadBitRate: string | undefined = _downloadBitRate
    ? bytesToString(_downloadBitRate)
    : bytesToString(_downloadByteRate)
  const rtt: string | undefined = Number(_rtt) >= 0 ? `${Number(_rtt).toFixed(2)} ms` : undefined
  const jitter: string | undefined =
    Number(_jitter) >= 0 ? `${Number(_jitter).toFixed(2)} ms` : undefined
  const platform = _platform || inferPlatform(_operatingSystem)
  const platformVersion = _platformVersion ? ` ${_platformVersion}` : ''
  const operatingSystem = _operatingSystem
    ? `${convertOSName(_operatingSystem)} ${platformVersion}`
    : undefined

  return {
    uploadBitRate,
    downloadBitRate,
    rtt,
    jitter,
    platform,
    platformVersion,
    operatingSystem,
  }
}
