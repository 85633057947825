import { useMemo } from 'react'
import { useRelaySubscription } from '../../../Common/hooks/useRelaySubscription'
import ListenApproveDeviceLoginCodeRequestSubscription from '../../../GraphQL/subscriptions/ListenApproveDeviceLoginCodeRequestSubscription'

export const useAppleTVConnectingUpdatesSubscription = (
  roomId: string,
  updateDeviceCodeId: (code: string) => void,
  updateAppleTVName: (code: string) => void,
  onShow?: () => void
) => {
  const ListenApproveDeviceLoginCodeRequestConfig = useMemo(
    () => ({
      subscription: ListenApproveDeviceLoginCodeRequestSubscription,
      variables: {
        roomId
      },
      onNext: ({ listenApproveDeviceLoginCodeRequest }) => {
        if (onShow) {
          onShow()
        }

        if (listenApproveDeviceLoginCodeRequest && listenApproveDeviceLoginCodeRequest.approve) {
          if (listenApproveDeviceLoginCodeRequest.approve.deviceCodeId) {
            updateDeviceCodeId(listenApproveDeviceLoginCodeRequest.approve.deviceCodeId)
          }

          if (listenApproveDeviceLoginCodeRequest.approve.deviceCodeId) {
            updateAppleTVName(listenApproveDeviceLoginCodeRequest.approve.deviceName)
          }
        }
      }
    }),
    [roomId, onShow, updateDeviceCodeId, updateAppleTVName]
  )

  const context = useMemo(
    () => ({
      roomId,
      connectionName: 'ApproveAppleTV'
    }),
    [roomId]
  )

  useRelaySubscription(ListenApproveDeviceLoginCodeRequestConfig, context)
}
