import React from 'react'
import { useChangePassword, ChangePasswordState } from './hooks/useChangePassword'
import { useChangePasswordForm } from './hooks/useChangePasswordForm'
import { Grid, FormControl, TextField, Box, Typography } from '@material-ui/core'
import PasswordStrengthProgress from '../PasswordStrengthProgress/PasswordStrengthProgress'
import ProgressButton from '../ProgressButton/ProgressButton'
import { usePasswordToggle } from '../../Common/hooks/usePasswordToggle'

export interface ChangePasswordProps {
  backLink: {
    path: string
    text: string
  }
  successComponent: React.ReactNode
}

/**
 * Change password component
 * used by Auth/UnAuth ChangePassword Views
 * @constructor
 */
const ChangePassword: React.FC<ChangePasswordProps> = ({ backLink, successComponent }) => {
  const { changePassword, state } = useChangePassword()
  const { inputs, password, handleSubmit } = useChangePasswordForm(changePassword)
  const { toggle, setToggle, toggleTitle, Toggler, type } = usePasswordToggle()

  if (state === ChangePasswordState.success) {
    return <>{successComponent}</>
  }

  return (
    <Grid
      container
      spacing={3}
      onKeyDown={(key) => {
        if (key.keyCode === 13) {
          return handleSubmit()
        }
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Reset password</Typography>
        <Box className="login-navigation" mb={1} mt={1}>
          Enter your new password
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Toggler onClick={() => setToggle(!toggle)}>{toggleTitle}</Toggler>
          <TextField type={type ? type : 'password'} label="Password (8+ varied characters)" variant="filled" {...inputs.password} />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <PasswordStrengthProgress
          password={password.value}
          onStrengthChange={password.setStrength}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-start">
          <Box>
            <ProgressButton
              color="primary"
              isLoading={state === ChangePasswordState.submitting}
              onClick={handleSubmit}
              fullWidth
              className="continue"
              size="medium"
            >
              Reset password
            </ProgressButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(ChangePassword)
