import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { JanusParticipant } from './JanusParticipant'
import { JanusPublisher } from './JanusPublisher'
import { JanusSubscriber } from './JanusSubscriber'
import { RoomAccess, RoomFullInfo } from '../../../../Models/apiEntities'

export interface Recording {
  recordingId: string
  recordingStartTime: Date
  currentTime: Date
}

export interface Publisher {
  startRecording: () => Promise<void>
  stopRecording: (recordingId) => Promise<void>
  publish: (constraints: MediaStreamConstraints, bitrate: number) => Promise<void>
  unpublish: () => Promise<void>
  onLocalStream: () => Observable<MediaStream>
  onRecordingActivated: () => Observable<Recording>
  onRecordingDeactivated: () => Observable<string>
  onIceState: () => Observable<any>
  sendData: (data) => Promise<void>
  onDataChannelOpened: () => Observable<string>
  onDataChannelMessage: () => Observable<any>
  displayName: string
  id: string
  unmuteAudio: () => Promise<void>
  muteAudio: () => Promise<void>
  unmuteVideo: () => Promise<void>
  muteVideo: () => Promise<void>
  cleanup: () => void
  leave: () => Promise<void>
  getMediaStreamStats: () => Promise<RTCStatsReport | null | undefined>
  onJoined: () => Observable<{
    publishers: any[]
    participants: any[]
    privateId: string
  }>
  adjustStreamQuality: ({ height, bitrate }: { height?: number; bitrate?: number }) => Promise<void>
}

export enum ParticipantType {
  Local,
  Remote,
  ScreenShare,
  BrowserStream,
  Playback,
  Ebs,
  DAMStream,
  AppleTV,
  RemoteApp,
  Dummy,
}

export interface Participant {
  id: string
  displayName: string
  publishing: boolean
  ownerId?: string
  onUnpublishing: () => Observable<void>
  onPublishing: () => Observable<MediaStream>
  onDataChannelOpened: () => Observable<string>
  onDataChannelMessage: () => Observable<any>
  onIceState: () => Observable<any>
  type: ParticipantType
  setVideoState: (isVideoEnabled: boolean) => void
  getVolume: () => Promise<number>
  getInboundStats?: () => Promise<RTCStatsReport | {}>
  getWebRTCStats: () => Promise<{[key: string]: any}>
  startReceivingVideo: () => Promise<any>
  stopReceivingVideo: () => Promise<any>
}

export class LiveroomClient {
  private participants = new Map<string, JanusParticipant>()
  private publisher: JanusPublisher

  private onParticipantJoiningSubject = new ReplaySubject<Participant>()
  private onParticipantLeavingSubject = new ReplaySubject<string>()
  private onParticipantPublishingSubject = new ReplaySubject<string>()

  constructor({ janusSignallingConnection, roomId, roomHash, userId, displayName, roomInfo }) {
    this.publisher = new JanusPublisher({
      janusSignallingConnection,
      roomId,
      roomHash,
      userId,
      displayName,
    })

    const addParticipant = ({ id, display }, defaultPublishing = false) => {
      // We are defaulting to publishing true when view only is not enabled for the room
      const allowViewers = ((roomInfo as RoomAccess).room as RoomFullInfo).roomFeatures['ALLOW_ROOM_VIEW_ONLY'].value === 'true'
      const publishing = allowViewers ? defaultPublishing : true
      // ---

      const participant = new JanusParticipant({ id, displayName: display, publisherId: userId, publishing })
      this.participants.set(id, participant)
      this.onParticipantJoiningSubject.next(participant)
    }

    const removeParticipant = (id) => {
      const participant = this.participants.get(id)
      this.participants.delete(id)
      participant!.cleanup()
      this.onParticipantLeavingSubject.next(id)
    }

    const subscribeToParticipant = (id, privateId) => {
      const participant = this.participants.get(id)!
      const subscriber = new JanusSubscriber({
        id,
        roomId,
        roomHash,
        janusSignallingConnection,
        privateId,
        displayName: participant.displayName,
      })
      participant.handlePublishing(subscriber)
      this.onParticipantPublishingSubject.next(participant.id)
    }

    const unsubscribeToParticipant = (id) => {
      if (id !== 'ok') {
        this.participants.get(id)!.handleUnpublishing()
      }
    }

    this.publisher.onJoined().subscribe(({ publishers, participants, privateId }) => {
      participants.forEach((p) => {
        addParticipant({ id: p.id, display: p.display }, !!publishers.find(publisher => publisher.id === p.id))
      })
      publishers.forEach(({ id }) => subscribeToParticipant(id, privateId))
      this.publisher.onParticipantJoining().subscribe(addParticipant)
      this.publisher.onParticipantLeaving().subscribe(removeParticipant)
      this.publisher
        .onParticipantPublishing()
        .subscribe(({ id }) => subscribeToParticipant(id, privateId))
      this.publisher.onParticipantUnpublishing().subscribe(unsubscribeToParticipant)
    })
    this.publisher.createOrJoin()
  }

  onParticipantJoining = () => this.onParticipantJoiningSubject.asObservable()

  onParticipantLeaving = () => this.onParticipantLeavingSubject.asObservable()

  onParticipantPublishing = () => this.onParticipantPublishingSubject.asObservable()

  onJoined = () => this.publisher.onJoined().pipe(map(() => this.publisher as Publisher))

  onRoomReallocate = () => this.publisher.onRoomReallocate()

  onMute = () => this.publisher.onMute()

  onParticipantKick = () => this.publisher.onParticipantKick()

  kickParticipant = (participantId) => this.publisher.kickParticipant(participantId)

  muteParticipant = (participantId) => this.publisher.muteParticipant(participantId)

  muteAllParticipants = () => this.publisher.muteAllParticipants()

  leave = () => {
    this.onParticipantJoiningSubject.complete()
    this.onParticipantLeavingSubject.complete()
    this.publisher.leave()
    this.participants.forEach((participant) => participant.cleanup())
  }
}

// bits per second
export enum VideoBitrateSettings {
  highest = 6000 * 1024,
  high = 2048 * 1024,
  medium = 1536 * 1024,
  low = 768 * 1024,
  lowest = 128 * 1024,
}

// bits per second
export enum AudioBitrateSettings {
  highest = 510000,
  high = 255000,
  medium = 128000,
  low = 128000,
  lowest = 128000,
}
