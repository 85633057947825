import useApi from '../../../../API/useApi'
import { useCallback, useState } from 'react'

/**
 * Hook for validating a special invite
 *
 * @param invite special invite code
 * @param roomId roomId to be validated
 * @returns validation API response object
 */
export const useSpecialInviteValidation = (invite, roomId) => {
  const [_invite, setInvite] = useState({})
  const { InviteV1, AuthAccount } = useApi()

  const handleExistingUser = useCallback(
    () => async () => {
      await AuthAccount.logout()
      window.location.reload()
    },
    [AuthAccount]
  )

  if (invite) {
    InviteV1.validateSpecial(roomId, { specialInviteCode: invite }).then((result) => {
      setInvite(result)
    })
  }

  return { isInviteValid: _invite, handleExistingUser }
}
