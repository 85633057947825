import { useMutation } from '../../../../../../../Common/hooks/useMutation'
import DeleteLiveroomMutation from '../../../../../../../GraphQL/mutations/DeleteLiveroomMutation'

const getUpdatedCanCreateRoom = (store) =>
  store.getRootField('softDeleteLiveroomById').getLinkedRecord('query').getValue('canCreateRoom')

const getUpdatedNodes = (store) =>
  store
    .getRootField('softDeleteLiveroomById')
    .getLinkedRecord('query')
    .getLinkedRecord('currentProfile')
    .getLinkedRecord('featureFlags')
    .getLinkedRecords('nodes')

export const useDeleteLiveroomMutation = () => {
  return useMutation<any, any>(DeleteLiveroomMutation, {
    updater: (store, params) => {
      if (store.getRootField('softDeleteLiveroomById')) {
        const root = store.getRoot()
        root.setValue(getUpdatedCanCreateRoom(store), 'canCreateRoom')

        const currentProfile = root.getLinkedRecord('currentProfile')!
        const featureFlags = currentProfile.getLinkedRecord('featureFlags')!
        featureFlags.setLinkedRecords(getUpdatedNodes(store), 'nodes')
      }
    }
  })
}
