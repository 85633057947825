import { useCallback, useEffect, useState } from 'react'
import screenfull, { Screenfull } from 'screenfull'

export const FULLSCREEN_ELEMENT_ID = 'make-fullscreen'

export const useFullScreen = () => {
  const fullScreenHelper = screenfull as Screenfull
  const [isFullScreenMode, setIsFullScreenMode] = useState(() => fullScreenHelper.isFullscreen)

  useEffect(() => {
    const fullScreenModeListener = () => {
      setIsFullScreenMode(fullScreenHelper.isFullscreen)
    }

    fullScreenHelper.on('change', fullScreenModeListener)

    return () => {
      fullScreenHelper.off('change', fullScreenModeListener)
    }
  }, [fullScreenHelper])

  useEffect(() => {
    if (isFullScreenMode) {
      // Use specific element or default to <html> tag
      const fullScreenElement = document.getElementById(FULLSCREEN_ELEMENT_ID) || undefined
      fullScreenHelper.request(fullScreenElement)
    } else {
      fullScreenHelper.exit()
    }
  }, [fullScreenHelper, isFullScreenMode])

  return {
    isFullScreenMode,
    toggleFullScreenMode: useCallback((isEnabled: boolean) => {
      setIsFullScreenMode(isEnabled)
    }, [])
  }
}
