import React, { memo } from 'react'
import { BaseDialog } from '../../Dialogs/BaseDialog/BaseDialog'
import { DefaultDialogHeader } from '../../Dialogs/Headers/DefaultDialogHeader/DefaultDialogHeader'
import DeleteRoomFromDashboardDialogConfirm from './DeleteRoomFromDashboardDialogConfirm/DeleteRoomFromDashboardDialogConfirm'
import DeleteRoomFromDashboardDialogSuccess from './DeleteRoomFromDashboardDialogSuccess/DeleteRoomFromDashboardDialogSuccess'

interface RoomProps {
  id: string
  displayName: string
}

interface RoomFormProps {
  room?: RoomProps
  isOpen: boolean
  handleClose: () => void
  onConfirm: () => void
  isLoading: boolean
  removeDone: boolean
}

/**
 * Dialog to delete room owned by the user
 * @param isOpened - Flag that indicates if the dialog is visible or not
 * @param handleClose - Function that handles when the close action is triggered
 * @param onConfirm - Function that handles when the confirm action is triggered
 * @param room - Object that holds the room`s information
 * @param isLoading - Flag that indicates if the confirm action is taking place
 * @param removeDone - Flag that indicates if the was deleted
 * @constructor
 */
const DeleteRoomFromDashboardDialog: React.FC<RoomFormProps> = ({
  isOpen,
  handleClose,
  onConfirm,
  room,
  isLoading,
  removeDone
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      header={
        <DefaultDialogHeader text="Remove Room From Dashboard" onClose={handleClose} divider />
      }
    >
      {removeDone ? (
        <DeleteRoomFromDashboardDialogSuccess handleClose={handleClose} />
      ) : (
        <DeleteRoomFromDashboardDialogConfirm
          room={room}
          handleClose={handleClose}
          onConfirm={onConfirm}
          isLoading={isLoading}
        />
      )}
    </BaseDialog>
  )
}

export default memo(DeleteRoomFromDashboardDialog)
