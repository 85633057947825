import React from 'react'
import { useLocation, Redirect } from 'react-router-dom'

/**
 * Component that redirects to not found page if rendered
 */
const NotFoundRedirect: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <Redirect
      to={{
        pathname,
        state: {
          notFound: true
        }
      }}
    ></Redirect>
  )
}

export default NotFoundRedirect
