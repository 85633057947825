import React, { createContext, useContext, useEffect, useState } from 'react'
import useApi from '../API/useApi'
import { IRoomFeatures, Room } from '../Models/apiEntities'

const roomContext = createContext<Room>(null!)

interface RoomProviderProps {
  room: Room
}

export const RoomProvider: React.FC<RoomProviderProps> = ({ room, children }) => {
  const [featureFlagsRest, setFeatureFlagsRest] = useState<IRoomFeatures>(null!)
  const {
    Room: { getFeatures },
  } = useApi()
  useEffect(() => {
    getFeatures(room.id).then((features) => setFeatureFlagsRest(features))
  }, [room.id, getFeatures])
  return (
    <roomContext.Provider value={{ ...room, featureFlagsRest }}>{children}</roomContext.Provider>
  )
}

export const useRoom = () => {
  return useContext(roomContext)
}
