import { useEffect } from 'react'
import { GraphQLSubscriptionConfig, requestSubscription, OperationType } from 'relay-runtime'
import { useRelayEnvironment } from '../../Providers/RelayEnvironmentProvider'
import { logger } from '../log/Log'

export function useRelaySubscription<TSubscriptionPayload extends OperationType = OperationType>(
  config: GraphQLSubscriptionConfig<TSubscriptionPayload>,
  context?: any
): void {
  const relayEnvironment = useRelayEnvironment()

  useEffect(() => {
    const completeConfig = {
      ...config,
      onCompleted: () => {
        // a callback function executed when the subscription is closed by the peer without error
        logger.info('subscription closed by the peer without error', context)
      },
      onError: (error) => {
        // a callback function executed when Relay or the server encounters an error processing the subscription
        logger.error('Error on subscription ', context, error)
      }
    }
    const { dispose } = requestSubscription(relayEnvironment, completeConfig)
    return () => dispose()
  }, [relayEnvironment, config, context])
}
