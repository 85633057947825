import { useEffect, useState } from 'react'
import { useAudioStream } from '../../../../../../Common/media/hooks/useAudioStream'
import { useVideoStream } from '../../../../../../Common/media/hooks/useVideoStream'
import { MediaSelection } from '../../Room/hooks/useMediaSetup'

interface DeviceSelectionParams {
  currentMediaSelection: MediaSelection
}

export const useDeviceSelection = ({ currentMediaSelection }: DeviceSelectionParams) => {
  const [camera, setCamera] = useState(currentMediaSelection.camera)
  const [microphone, setMicrophone] = useState(currentMediaSelection.microphone)
  const [speaker, setSpeaker] = useState(currentMediaSelection.speaker)
  const [cameraQuality, setCameraQuality] = useState(currentMediaSelection.cameraQuality)

  const videoStream = useVideoStream({ camera, speaker: null })
  const audioStream = useAudioStream({ microphone, speaker: null })

  useEffect(() => {
    setCamera(currentMediaSelection.camera)
    setMicrophone(currentMediaSelection.microphone)
    setSpeaker(currentMediaSelection.speaker)
  }, [currentMediaSelection, setCamera, setMicrophone, setSpeaker])

  return {
    camera,
    setCamera,
    microphone,
    setMicrophone,
    speaker,
    setSpeaker,
    cameraQuality,
    setCameraQuality,
    videoStream,
    audioStream
  }
}
