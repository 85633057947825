import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.2866 7.50156C21.1075 5.50063 19.1025 3 17.0788 3C16.3478 3 15.6141 3.32625 15.0575 4.12031L9.87188 11.8753C8.46156 11.9659 7.18125 12.5031 6.455 14.4172C6.37219 14.6356 6.17375 14.7678 5.94219 14.7678C5.55188 14.7678 4.34406 13.795 4 13.56C4 16.4556 5.33344 19 8.5 19C12.9128 19 13.0591 15.3425 12.9659 14.4534L12.9666 14.4528L19.2866 7.50156V7.50156ZM16.2859 4.98125C16.5884 4.55 16.8831 4.5 17.0788 4.5C17.5913 4.5 18.1903 4.91156 18.4138 5.41719C18.5813 5.79625 18.5084 6.12781 18.1763 6.4925L12.3175 12.9369L11.45 12.2141L16.2859 4.98125ZM8.5 17.5C7.29375 17.5 6.56156 16.9728 6.12313 16.2603C6.90656 16.1934 7.57594 15.6934 7.85781 14.9494C8.17125 14.1231 8.42781 13.4716 10.4588 13.3406L11.4256 14.1466C11.4953 14.7694 11.8672 17.5 8.5 17.5Z" />
  </SvgIcon>
)

export default Icon
