import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '..'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { red, green } from '@material-ui/core/colors'

interface ConfirmationDialogProps {
  text: string
  isOpen?: boolean
  classNames?: string
  onConfirm?: () => void
  onCancel?: () => void
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

/**
 * Displays a ConfirmationDialog. A dialog with a text header and only two options (yes or no)
 * @constructor
 */
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  text,
  isOpen = false,
  classNames,
  onConfirm,
  onCancel,
  maxWidth,
  fullWidth
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      disableEscapeKeyDown={true}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      classNames={classNames}
      header={<DefaultDialogHeader text={text}></DefaultDialogHeader>}
      footer={
        <DefaultDialogFooter
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmButtonText="OK"
          cancelButtonText="Cancel"
          cancelButtonProps={{
            variant: 'text',
            startIcon: <CloseIcon style={{ color: red[500] }} />
          }}
          confirmButtonProps={{
            variant: 'text',
            startIcon: <CheckIcon style={{ color: green[500] }} />
          }}
        />
      }
    />
  )
}

export default React.memo(ConfirmationDialog)
