import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../../Common/utils/formUtils'
import { InviteFormInput } from './useCreateRoomInviteMutation'

/**
 * Manage logic for the room invite creation, form validation, handle api errors
 * @param performCreateRoomInvite - action to perform the mutation
 * @param invites - already existent invites, used to validate if is a duplicated item
 */
export const useRoomInviteForm = ({ performCreateRoomInvite, invites }) => {
  const formMethods = useForm({ shouldFocusError: true })
  const { handleSubmit, formState, reset, clearErrors } = formMethods

  const { isSubmitted, isDirty, isValid } = formState

  const inputs = useFormValidation(formMethods, {
    email: {
      required: 'Please enter an email address',
      validate: (value) =>
        invites.some((item) => item.email === value)
          ? 'An invite already exists for this email'
          : true
    }
  })

  useEffect(() => {
    if (isDirty) {
      clearErrors()
    }
  }, [clearErrors, isDirty])

  useEffect(() => {
    if (isSubmitted && isValid) {
      reset(inputs.email, { errors: true })
    }
  }, [reset, isSubmitted, isValid, inputs])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((formData) => {
        performCreateRoomInvite([{ ...formData } as InviteFormInput])
      }),
    [performCreateRoomInvite, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs
  }
}
