import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'

interface SlideAndPushProps {
  isOpen: boolean
  from: 'left' | 'right' | 'bottom' | 'top'
  position: 'fixed' | 'absolute' | 'relative'
  zIndex: number
  timeout: number
  width: any
  height: any
}

/**
 * Does an slide animation taking width/height and pushing other elements
 * @param isOpen    if content is hidden or not
 * @param from      direction from where the animation will take place
 * @param position  position of reference
 * @param zIndex    ensures content is displayed over other elements
 * @param timeout   duration of animation in milliseconds
 * @param width     content width
 * @param height    content height
 */
const SlideAndPush: React.FC<SlideAndPushProps> = ({
  children,
  from,
  position,
  zIndex,
  isOpen,
  timeout,
  width,
  height
}) => {
  const animation = { transition: `${timeout}ms` }
  const isHorizontal = from === 'left' || from === 'right'
  const outerProps = useMemo(() => {
    if (isHorizontal) {
      return {
        minWidth: isOpen ? width : 0,
        maxWidth: isOpen ? width : 0
      }
    } else {
      return {
        minHeight: isOpen ? height : 0,
        maxHeight: isOpen ? height : 0
      }
    }
  }, [isHorizontal, isOpen, width, height])

  return (
    <Box {...outerProps} style={animation}>
      <Box
        height={height}
        width={width}
        style={animation}
        position={position}
        zIndex={zIndex}
        {...{ [from]: isOpen ? 0 : isHorizontal ? -width : -height }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default SlideAndPush
