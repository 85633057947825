import React, { memo, useCallback, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import RoomSetup from './Tabs/RoomSetup/RoomSetup'
import Permissions from './Tabs/Permissions/Permissions'
import { useRoomAction } from './hooks/useRoomAction'
import { useRoomForm } from './hooks/useRoomForm'
import ProgressButton from '../ProgressButton/ProgressButton'
import { useRoomNavigation } from '../../Views/MainView/AuthenticatedView/DashboardView/HomeView/RoomCard/hooks/useRoomNavigation'
import { Box } from '@material-ui/core'
import Tabs from '../Tabs/Tabs'

enum FormTypes {
  create,
  update,
}

interface RoomProps {
  id: string
  displayName: string
  isPublic: boolean
}

interface RoomFormProps {
  room?: RoomProps
  isOpen: boolean
  handleClose: () => void
  onSuccess: () => void
}

/**
 * Dialog with the room setup form inside of it
 * @param isOpened - Flag that indicates if the dialog is visible or not
 * @param handleClose - function that handles when the close action is triggered
 * @param onSuccess - function that will be triggered once the form is submitted
 * @param room - object that holds the room`s information, it will be empty if the form is to create a new room
 * @constructor
 */
const RoomDialog: React.FC<RoomFormProps> = ({ isOpen, handleClose, onSuccess, room }) => {
  const { goToRoom } = useRoomNavigation()
  const isCreate /* as opposed to update */ = useMemo(() => !room?.id, [room])

  const formType: FormTypes = isCreate ? FormTypes.create : FormTypes.update

  const formProps = useMemo(
    () => ({
      [FormTypes.create]: {
        onSuccess: goToRoom,
        buttonText: 'Create room',
      },
      [FormTypes.update]: {
        onSuccess: onSuccess,
        buttonText: 'Save',
      },
    }),
    [goToRoom, onSuccess]
  )

  const { isInProgress, actions } = useRoomAction({ onSuccess: formProps[formType].onSuccess })

  const { handleSubmit, inputs, control, trigger } = useRoomForm({
    actions,
    room,
    isCreate,
  })

  const tabs = useMemo(
    () => [
      {
        label: 'Room setup',
        component: (
          <RoomSetup
            inputs={inputs}
            control={control}
            isCreate={isCreate}
            roomId={room?.id}
          />
        ),
      },
      {
        label: 'Permissions',
        component: <Permissions />,
      },
    ],
    [control, inputs, isCreate, room]
  )

  const beforeChangeTabHandler = useCallback(async (): Promise<boolean> => trigger(), [trigger])

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="dialog"
      fullWidth
      maxWidth="sm"
      onKeyDown={(key) => {
        if (key.keyCode === 13) {
          return handleSubmit()
        }
      }}
    >
      <Tabs tabs={tabs} beforeChangeTab={beforeChangeTabHandler} />
      <Box paddingX={2} paddingBottom={2} paddingTop={-1}>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ProgressButton
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            isLoading={isInProgress}
          >
            {formProps[formType].buttonText}
          </ProgressButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default memo(RoomDialog)
