import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react'
import { v4 as uuid } from 'uuid'
import Cookies from 'universal-cookie'
import addYears from 'date-fns/addYears'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import { logger } from '../../../Common/log/Log'
import { useRestApi } from '../../../Providers/RestApiProvider'
import useRedirectUser from './useRedirectUser'
import { LoginState } from '../LoginForm'
import {UserProfile} from "../../../Models/apiEntities";

/**
 * TrackingId cookie validity period
 */
const VALID_FOR_YEARS = 2

const NEEDS_TO_USE_COMPANY_SSO_ERR_CODE = '07112'
const EMAIL_VERIFICATION_ERR_CODE = '07150'
const TWO_FACTOR_TOKEN_ERR_CODE = '07141'
const MFA_TOKEN_EXPIRED_ERR_CODE = '07142'

export const ERROR_CODES = {
  [NEEDS_TO_USE_COMPANY_SSO_ERR_CODE]: 'You must sign in via SSO by selecting "Company SSO."',
  '07140': 'Authentication: Double-check your credentials and try again.', // this is legacy from graphql api
  [EMAIL_VERIFICATION_ERR_CODE]: 'Please check your email to verify your account.',
  '07190': 'Account temporarily locked. Please wait 60 seconds before trying again.', // this is legacy from graphql api
  [TWO_FACTOR_TOKEN_ERR_CODE]: 'Invalid MFA token. Please try again.',
  [MFA_TOKEN_EXPIRED_ERR_CODE]: 'MFA expired.  Please re-login',
  BAD_CREDENTIALS: 'Wrong email or password. Please try again.', // We could consider returning an error code in the api
}

const checkForKnownError = (response: any) => {
  let message = ''
  let errorCode: string | null = null
  const errorMessage = response?.data?.errors?.message
  for (const [code, fallBackMessage] of Object.entries(ERROR_CODES)) {
    if (errorMessage?.includes(code)) {
      try {
        errorCode = code
        message = response.data.errors.message.split('-')[1].trim()
      } catch {
        message = fallBackMessage
      }
      break
    }
  }
  return { message, errorCode }
}

interface Error {
  message: string
}

export interface useLoginActionProps {
  onLogin: (userProfile?: UserProfile) => void
  setState?: Dispatch<SetStateAction<LoginState>>
  redirectToSSO?: () => void
}

/**
 * Represents the data returned by {@link useLoginAction}
 */
interface UseLoginActionResult {
  isInProgress: boolean
  isVerificationRequired: boolean
  setVerificationRequired
  actions: {
    performAuthentication
  }
}

/**
 * Exposes the interface for login action.
 * @param onLogin - callback that has to be called when user logs in
 * @param setState - dispatch function to determine login view page
 * @param redirectToSSO - function to redirect to sso login page
 * @returns {@type UseLoginActionResult}
 */
export const useLoginAction = ({ onLogin, setState, redirectToSSO }: useLoginActionProps): UseLoginActionResult => {
  const { AuthAccount } = useRestApi()
  const alert = useAlerts()
  const [isVerificationRequired, setVerificationRequired] = useState(false)
  const [error, setError] = useState<null | Error>(null)
  const [isInProgress, setInProgress] = useState(false)

  /**
   * Device tracking id. If it's not set in cookies, creates new UUID and saves it.
   */
  const trackingId = useMemo(() => {
    const cookies = new Cookies()
    let trackingId = cookies.get('trackingId')
    if (!trackingId) {
      trackingId = uuid()

      cookies.set('trackingId', trackingId, {
        expires: addYears(new Date(), VALID_FOR_YEARS),
      })
    }

    return trackingId
  }, [])

  /**
   * Handle potential I/O errors
   */
  useEffect(() => {
    if (error) {
      alert(error.message, AlertType.error)
      setError(null)
    }
  }, [error, alert])

  const { redirectAfterLogin } = useRedirectUser()

  const performAuthentication = useCallback(
    async ({ email, password, mfaCode = '' }) => {
      setInProgress(true)
      try {
        await AuthAccount.authenticate({
          email,
          password,
          tracking_id: trackingId,
          two_factor_verification_code: mfaCode,
        })

        await onLogin()

        redirectAfterLogin()

        setError(null)
      } catch (e) {
        let message = 'Something went wrong!'

        const isBadCredentials = e.response?.status === 401
        if (isBadCredentials) message = ERROR_CODES['BAD_CREDENTIALS']

        const { message: knownMsg, errorCode } = checkForKnownError(e.response)
        if (knownMsg) message = knownMsg

        if (errorCode === TWO_FACTOR_TOKEN_ERR_CODE && !isVerificationRequired)
          return setVerificationRequired(true)

        if (errorCode === NEEDS_TO_USE_COMPANY_SSO_ERR_CODE) {
          message += ' Attempting to load your company SSO...'
          
          if (redirectToSSO) {
            redirectToSSO()
          }
          
        }
        
        if (errorCode === MFA_TOKEN_EXPIRED_ERR_CODE) {
          if (setState) {
            setState(LoginState.password)
          }
          
        }

        setError({ message })
        logger.error(e)
      } finally {
        setInProgress(false)
      }
    },
    [AuthAccount, trackingId, onLogin, redirectAfterLogin, isVerificationRequired, redirectToSSO, setState]
  )

  return {
    isInProgress,
    isVerificationRequired,
    setVerificationRequired,
    actions: {
      performAuthentication,
    },
  }
}
