import { graphql } from 'babel-plugin-relay/macro'

const CreateSessionMutation = graphql`
  mutation CreateSessionMutation($input: CreateSessionInput!, $roomId: UUID!) {
    createSession(input: $input) {
      clientMutationId
      session {
        id
        recordingId
      }
      query {
        liveroomById(id: $roomId) {
          sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {
            nodes {
              id
              displayName
              createdAt
              stoppedAt
              recordingId
            }
          }
        }
      }
    }
  }
`

export default CreateSessionMutation
