import React from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import ProgressButton from '../../../ProgressButton/ProgressButton'

interface DeleteRoomFromDashboardDialogSuccessProps {
  handleClose: () => void
}

const DeleteRoomFromDashboardDialogSuccess: React.FC<DeleteRoomFromDashboardDialogSuccessProps> = ({
  handleClose
}) => {
  return (
    <>
      <Box marginTop={1}>
        <Typography variant="h4" align="center" gutterBottom>
          Success!
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="body2" align="center" gutterBottom style={{ fontWeight: 'lighter' }}>
          You have removed yourself from the room.  You will need to be re-invited to regain access
          to the room.
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={2} alignItems="center" justify="flex-start">
          <Grid item xs={12}>
            <ProgressButton
              type="submit"
              color="secondary"
              variant="contained"
              onClick={handleClose}
              fullWidth
              isLoading={false}
            >
              Close
            </ProgressButton>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(DeleteRoomFromDashboardDialogSuccess)
