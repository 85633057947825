// object is what we want to fit, container is where we want to fit it
export default function objectFitContain({
  objectWidth,
  objectHeight,
  containerWidth,
  containerHeight
}) {
  const containerRatio = containerWidth / containerHeight
  const objectRatio = objectWidth / objectHeight
  let width
  let height
  let leftOffset
  let topOffset

  if (objectRatio < containerRatio) {
    width = Math.floor(containerHeight * objectRatio)
    height = containerHeight
    leftOffset = (containerWidth - width) / 2
    topOffset = 0
  } else {
    width = containerWidth
    height = Math.floor(containerWidth / objectRatio)
    leftOffset = 0
    topOffset = (containerHeight - height) / 2
  }
  const scale = objectWidth / width // could have been calculated by height as well

  return {
    width,
    height,
    leftOffset,
    topOffset,
    scale
  }
}
