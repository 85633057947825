import React, { useCallback } from 'react'
import { Box } from '@material-ui/core'
import { isEmpty } from 'ramda'

import CameraPreviewProgress from './Progress/Progress'
import UnexpectedError from './UnexpectedError/UnexpectedError'
import NoCamera from './NoCamera/NoCamera'
import NoDevices from './NoDevices/NoDevices'
import NoMicrophone from './NoMicrophone/NoMicrophone'
import StreamPlayer from '../../StreamPlayer/StreamPlayer'
import { Stream as StreamType } from '../../../../../../../Common/media/stream'
import { MediaSetupState } from '../../hooks/useMediaSetup'
import { MediaDevicesInfo } from '../../hooks/useMediaDevices'
import { BackgroundColor, BlurredBackground } from './BlurredBackground/BlurredBackground'
import { NoDevicesPermission } from './NoDevicesPermission/NoDevicesPermission'
import { BottomOverlay } from './BottomOverlay/BottomOverlay'

import './StreamPreview.scss'
import { WithCondition } from '../../../../../../../Components/WithCondition/WithCondition'

export const IconSize = 50

interface CameraPreviewProps {
  error?: Error | null
  stream: StreamType | null
  isCameraEnabled: boolean
  isMicrophoneEnabled: boolean
  mediaSetupState: MediaSetupState
  mediaDevices: MediaDevicesInfo | null
  isCameraSetToNone: boolean
  canPublish: boolean
}

const StreamPreview: React.FC<CameraPreviewProps> = ({
  error,
  stream,
  isCameraEnabled,
  isMicrophoneEnabled,
  mediaSetupState,
  mediaDevices,
  isCameraSetToNone,
  canPublish
}) => {
  const isInProgress =
    mediaSetupState === MediaSetupState.requestingPermission ||
    mediaSetupState === MediaSetupState.awaiting

  const renderDevicesStateOverlay = useCallback(() => {
    if (isInProgress) {
      return (
        <BlurredBackground backgroundColor={BackgroundColor.Dark}>
          <CameraPreviewProgress />
        </BlurredBackground>
      )
    }

    if (error) {
      return (
        <BlurredBackground backgroundColor={BackgroundColor.Dark}>
          <UnexpectedError />
        </BlurredBackground>
      )
    }

    if (!canPublish) {
      return (
        <BlurredBackground>
          <BottomOverlay>
            <NoDevices canPublish={canPublish} />
          </BottomOverlay>
        </BlurredBackground>
      )
    }

    const isCameraPermissionGranted = mediaDevices!.camera.hasPermission
    const isMicrophonePermissionGranted = mediaDevices!.microphone.hasPermission
    if (!isMicrophonePermissionGranted || !isCameraPermissionGranted) {
      // if there's a camera permission display the stream, otherwise use blurred background
      const permissionStates = (
        <BottomOverlay>
          <NoDevicesPermission
            isCameraPermissionGranted={isCameraPermissionGranted}
            isMicrophonePermissionGranted={isMicrophonePermissionGranted}
          />
        </BottomOverlay>
      )
      return (
        <WithCondition
          condition={isCameraPermissionGranted}
          fallback={
            <BlurredBackground backgroundColor={BackgroundColor.Light}>
              {permissionStates}
            </BlurredBackground>
          }
        >
          {permissionStates}
        </WithCondition>
      )
    }

    const hasMicrophoneDevices = !isEmpty(mediaDevices!.microphone.devices)
    const hasCameraDevices = !isEmpty(mediaDevices!.camera.devices)
    if (!hasCameraDevices && !hasMicrophoneDevices) {
      return (
        <BlurredBackground>
          <BottomOverlay>
            <NoDevices canPublish={canPublish} />
          </BottomOverlay>
        </BlurredBackground>
      )
    }

    if (!hasCameraDevices) {
      return (
        <BlurredBackground>
          <BottomOverlay>
            <NoCamera />
          </BottomOverlay>
        </BlurredBackground>
      )
    }

    if (!hasMicrophoneDevices) {
      return (
        <BlurredBackground>
          <BottomOverlay>
            <NoMicrophone />
          </BottomOverlay>
        </BlurredBackground>
      )
    }

    if (!isCameraEnabled && !isMicrophoneEnabled) {
      return <BlurredBackground />
    }

    if (!isCameraEnabled) {
      return <BlurredBackground />
    }

    if (isCameraSetToNone) {
      return <BlurredBackground backgroundColor={BackgroundColor.Dark} />
    }
  }, [isInProgress, error, mediaDevices, isCameraEnabled, isMicrophoneEnabled, isCameraSetToNone, canPublish])

  return (
    <div className="camera-preview">
      <Box position="absolute" top={0} bottom={0} right={0} left={0} zIndex={1}>
        {renderDevicesStateOverlay()}
      </Box>
      {!isInProgress && (
        <StreamPlayer className="camera-preview-stream" stream={stream!} isMuted={true} />
      )}
    </div>
  )
}

export default React.memo(StreamPreview)
