/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateNoteInput = {
    clientMutationId?: string | null;
    note: NoteInput;
};
export type NoteInput = {
    id?: unknown | null;
    profileId?: unknown | null;
    roomId: unknown;
    sessionId?: unknown | null;
    content: string;
    createdAt?: unknown | null;
    updatedAt?: unknown | null;
    sessionTime?: number | null;
    deletedAt?: unknown | null;
};
export type CreateNoteMutationVariables = {
    input: CreateNoteInput;
};
export type CreateNoteMutationResponse = {
    readonly createNote: {
        readonly clientMutationId: string | null;
        readonly note: {
            readonly " $fragmentRefs": FragmentRefs<"NoteItem_note">;
        } | null;
    } | null;
};
export type CreateNoteMutation = {
    readonly response: CreateNoteMutationResponse;
    readonly variables: CreateNoteMutationVariables;
};



/*
mutation CreateNoteMutation(
  $input: CreateNoteInput!
) {
  createNote(input: $input) {
    clientMutationId
    note {
      ...NoteItem_note
    }
  }
}

fragment NoteItem_note on Note {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotePayload",
        "kind": "LinkedField",
        "name": "createNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NoteItem_note"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotePayload",
        "kind": "LinkedField",
        "name": "createNote",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileDisplayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aaf78c81e27794f0c4effc3f8118238f",
    "id": null,
    "metadata": {},
    "name": "CreateNoteMutation",
    "operationKind": "mutation",
    "text": "mutation CreateNoteMutation(\n  $input: CreateNoteInput!\n) {\n  createNote(input: $input) {\n    clientMutationId\n    note {\n      ...NoteItem_note\n    }\n  }\n}\n\nfragment NoteItem_note on Note {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n"
  }
};
})();
(node as any).hash = 'a585b70ed61c70c93042a378cb2d34da';
export default node;
