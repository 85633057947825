import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../../Dialogs'
import RoomAccess from '../RoomAccess'

interface RoomAccessDialogProps {
  isOpen?: boolean
  classNames?: string
  handleClose?: () => void
  room: {
    id: string
    hash: string
    isPublic: boolean
  }
}

/**
 * Dialog wrapper to show RoomAccess as a BaseDialog
 * @param isOpened - Flag that indicates if the dialog is visible or not
 * @param handleClose - function that handles when the close action is triggered
 * @constructor
 */
const RoomAccessDialog: React.FC<RoomAccessDialogProps> = ({ isOpen, handleClose, room }) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="Room access" onClose={handleClose} divider />}
      footer={<DefaultDialogFooter />}
    >
      <RoomAccess room={room} />
    </BaseDialog>
  )
}

export default RoomAccessDialog
