import { graphql } from 'babel-plugin-relay/macro'

const CreateRoomByAssignmentMutation = graphql`
  mutation CreateRoomByAssignmentMutation($input: CreateRoomByAssignmentInput!) {
    createRoomByAssignment(input: $input) {
      liveroom {
        id
        hash
      }
      query {
        currentProfile {
          featureFlags {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
`

export default CreateRoomByAssignmentMutation
