import React from 'react'
import ErrorNotification from '../../../../../Components/ErrorNotification/ErrorNotification'

interface AcceptInviteErrorProps {
  error: string
}

/**
 *
 * @constructor
 */
const AcceptInviteError: React.FC<AcceptInviteErrorProps> = ({ error }) => {
  return <ErrorNotification title="Error">{error}</ErrorNotification>
}

export default AcceptInviteError
