import React from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import ChatMessageList from '../../../../../Components/Chat/ChatMessageList/ChatMessageList'
import { Message } from '../../../../../Models/apiEntities'
import ChatMessageItem from '../../../../../Components/Chat/ChatMessageList/ChatMessageItem/ChatMessageItem'
import { Box, Typography } from '@material-ui/core'
import { usePlaybackChatMessages } from './hooks/usePlaybackChatMessages'

const defaultMessagesNumber = 10
const noop = () => false
const noopPagination = { loadMore: noop, hasMore: noop, isLoading: false, defaultMessagesNumber }

interface PlaybackChatProps {
  session: {
    messages?: {
      edges: Message[]
    }
  }
}

export const PlaybackChat: React.FC<PlaybackChatProps> = ({ session }) => {
  const { messages } = usePlaybackChatMessages({ session })

  if (!messages.length) {
    return (
      <Box padding={2} alignItems="center" justifyContent="center">
        <Typography variant={'h6'} align="center">
          There are no comments on this recording.
        </Typography>
      </Box>
    )
  }

  return (
    <ChatMessageList pagination={noopPagination} isVisible={true}>
      {messages.map((message) => {
        return <ChatMessageItem key={message.id} message={message} />
      })}
    </ChatMessageList>
  )
}

export default createFragmentContainer(PlaybackChat, {
  session: graphql`
    fragment PlaybackChat_session on Session {
      id
      messages: messagesBySessionId(orderBy: [CREATED_AT_DESC]) {
        edges {
          node {
            ...ChatMessageItem_message
            id
            sessionId
            sessionTime
            content
            createdAt
            deletedAt
            profileId
            profileDisplayName
          }
        }
      }
    }
  `
})
