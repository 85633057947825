import React, { useState, useCallback } from 'react'
import {
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  Box,
  TextFieldProps
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDelayedEffect } from '../../Common/hooks/useDelayedEffect'

interface CopyToClipboardInputProps {
  content: string
  toolTipDuration?: number
  inputProps?: TextFieldProps
}

/**
 * An readonly input that shows a content that can be copied to the clipboard by the user.
 * @param content content to be shown in the input and copied to clipboard by the user
 * @param toolTipDuration Duration in miliseconds for the tooltip showing the content was copied
 */
const CopyToClipboardInput: React.FC<CopyToClipboardInputProps> = ({
  content,
  inputProps,
  toolTipDuration = 1000
}) => {
  const [isCopied, setIsCopied] = useState(false)

  useDelayedEffect(
    {
      delay: toolTipDuration,
      effect: () => {
        setIsCopied(false)
      },
      predicate: () => isCopied
    },
    [isCopied, setIsCopied]
  )

  const handleCopyToClipboard = useCallback(() => {
    setIsCopied(true)
  }, [setIsCopied])

  return (
    <TextField
      {...inputProps}
      value={content}
      variant="outlined"
      hiddenLabel
      fullWidth
      InputProps={{
        disabled: true,
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboard
              options={{
                format: 'text/plain'
              }}
              text={content}
              onCopy={handleCopyToClipboard}
            >
              <Tooltip
                id="copiedTooltip"
                open={isCopied}
                title="Copied"
                aria-label="copied"
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Box mr={1}>
                  <IconButton edge="end">
                    <LinkIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </CopyToClipboard>
          </InputAdornment>
        )
      }}
    />
  )
}

export default React.memo(CopyToClipboardInput)
