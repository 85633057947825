import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Box, FormControl, Select, TextField, InputLabel, InputAdornment } from '@material-ui/core'
import { InputProps } from '../../../../Common/utils/formUtils'
import { Control } from 'react-hook-form/dist/types/form'
import useRoomSetup from './hooks/useRoomSetup'

type InputObject<T> = {
  [P: string]: T
}

interface RoomSetupProps {
  inputs: InputObject<InputProps>
  control: Control
  isCreate: boolean
  roomId?: string
}

const RoomSetup: React.FC<RoomSetupProps> = ({
  inputs: { displayName, isPublic, organization },
  control,
  isCreate,
  roomId,
}) => {
  const [roomName, setRoomName] = useState('')
  const { orgOptions, PublicOptions, selectedOrg, setSelectedOrg } = useRoomSetup(roomId, isCreate)

  const organizationField = isCreate ? (
    <Box marginTop={3}>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="room-organization-label">Organization</InputLabel>
        <Controller
          control={control}
          {...organization}
          render={({ onBlur, onChange }) => (
            <Select
              id="room-organization-label"
              onBlur={onBlur}
              onChange={(e) => onChange(() => setSelectedOrg(e.target.value))} // This setState causes a warning, but I'm not sure how to fix it
              value={selectedOrg.customer_id}
            >
              {orgOptions}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  ) : (
    <input type="hidden" name="organization" value={selectedOrg.customer_id} />
  )

  return (
    <>
      {organizationField}
      <Box marginTop={3}>
        <TextField
          id="room-display-name-input"
          label="Room name"
          fullWidth
          variant="filled"
          onChange={(e) => setRoomName(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment className="MuiFormHelperText-root Mui-error" position="end">
                {roomName.length}/80
              </InputAdornment>
            ),
          }}
          {...displayName}
        />
      </Box>
      <Box marginTop={3}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="room-access-label">Room access</InputLabel>
          <Controller
            control={control}
            name={isPublic.name}
            defaultValue={false} // this means Private Access
            render={(o) => <PublicOptions {...o} />}
          />
        </FormControl>
      </Box>
    </>
  )
}

export default RoomSetup
