/* eslint-disable */

var Pan,
  Tool,
  createShape,
  extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key]
    }
    function ctor() {
      this.constructor = child
    }
    ctor.prototype = parent.prototype
    child.prototype = new ctor()
    child.__super__ = parent.prototype
    return child
  },
  hasProp = {}.hasOwnProperty

Tool = require('./base').Tool

createShape = require('../core/shapes').createShape

module.exports = Pan = (function (superClass) {
  extend(Pan, superClass)

  function Pan() {
    return Pan.__super__.constructor.apply(this, arguments)
  }

  Pan.prototype.name = 'Pan'

  Pan.prototype.iconName = 'pan'

  Pan.prototype.usesSimpleAPI = false

  Pan.prototype.didBecomeActive = function (lc) {
    var unsubscribeFuncs
    unsubscribeFuncs = []
    this.unsubscribe = (function (_this) {
      return function () {
        var func, i, len, results
        results = []
        for (i = 0, len = unsubscribeFuncs.length; i < len; i++) {
          func = unsubscribeFuncs[i]
          results.push(func())
        }
        return results
      }
    })(this)
    unsubscribeFuncs.push(
      lc.on(
        'lc-pointerdown',
        (function (_this) {
          return function (arg) {
            var rawX, rawY
            ;(rawX = arg.rawX), (rawY = arg.rawY)
            _this.oldPosition = lc.position
            return (_this.pointerStart = {
              x: rawX,
              y: rawY
            })
          }
        })(this)
      )
    )
    return unsubscribeFuncs.push(
      lc.on(
        'lc-pointerdrag',
        (function (_this) {
          return function (arg) {
            var dp, rawX, rawY
            ;(rawX = arg.rawX), (rawY = arg.rawY)
            dp = {
              x: (rawX - _this.pointerStart.x) * lc.backingScale,
              y: (rawY - _this.pointerStart.y) * lc.backingScale
            }
            return lc.setPan(_this.oldPosition.x + dp.x, _this.oldPosition.y + dp.y)
          }
        })(this)
      )
    )
  }

  Pan.prototype.willBecomeInactive = function (lc) {
    return this.unsubscribe()
  }

  return Pan
})(Tool)
