import { useCallback } from 'react'

interface useKeyDownHandlerProps {
  action: () => void
  predicate?: () => boolean
  deps?: any[]
}

const ENTER_KEY_CODE = 13
export const useKeyDownHandler = (
  { action, predicate = () => true, deps = [] }: useKeyDownHandlerProps,
  keyCode = ENTER_KEY_CODE
) => {
  const handleOnKeydown = useCallback(
    async (e) => {
      if (e.keyCode === keyCode && predicate()) {
        await action()
      }
    },
    // TODO: Inherited from clevertech, haven't investigated yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, keyCode, ...deps]
  )
  return { handleOnKeydown }
}
