import { graphql } from 'babel-plugin-relay/macro'

const SessionQuery = graphql`
  query SessionQuery {
    getCurrentFeatureFlags {
      nodes {
        key
        value
      }
    }
    currentProfile {
      type
      nodeId
      id
      displayName
      email
      organizationByOrganizationId {
        id
        displayName
      }
      profilePreferences {
        nodes {
          preferenceTypeName
          preferenceTypeId
          preferenceTypeDefault
          preferenceValue
        }
      }
      featureFlags {
        nodes {
          key
          value
        }
      }
    }
    getSSODetails {
      userPoolId
      userPoolClientId
      userPoolDomain
      userPoolRegion
    }
  }
`

export default SessionQuery
