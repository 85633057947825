import React from 'react'
import classnames from 'classnames'

import './CardText.scss'

interface CardTextProps {
  className?: string
  children: React.ReactNode
}

/**
 * Displays children styled as unauthenticated view card text
 * @param className - css classname which allows to customize styles, if needed
 * @param children
 * @constructor
 */
const CardText: React.FC<CardTextProps> = ({ className, children }) => {
  return (
    <span className={classnames('card-text', className)}>
      <span>{children}</span>
    </span>
  )
}

export default React.memo(CardText)
