/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EBSKeysDialog_room = {
    readonly id: unknown;
    readonly " $refType": "EBSKeysDialog_room";
};
export type EBSKeysDialog_room$data = EBSKeysDialog_room;
export type EBSKeysDialog_room$key = {
    readonly " $data"?: EBSKeysDialog_room$data;
    readonly " $fragmentRefs": FragmentRefs<"EBSKeysDialog_room">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EBSKeysDialog_room",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Liveroom",
  "abstractKey": null
};
(node as any).hash = 'f24cb027923af0de1088d9f984b7206f';
export default node;
