import React from 'react'
import { Card, Grid } from '@material-ui/core'

import CardNotification from '../../../../../Components/CardNotification/CardNotification'
import Container from '../../../../../Components/Container/Container'

import './RoomIssueInformation.scss'

interface RoomIssueInformationProps {
  title: React.ReactNode
  description: React.ReactNode
}

const RoomIssueInformation: React.FC<RoomIssueInformationProps> = ({
  title,
  description,
}: RoomIssueInformationProps) => {
  return (
    <Grid className="room-issue-information" container direction="row" justify="center">
      <Grid item>
        <Container component={Card}>
          <CardNotification
            title={title}
            description={description}
            footer={
              <>
                Need help? Visit&nbsp;
                <a
                  href={process.env.REACT_APP_SUPPORT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>evercast.us</strong>
                </a>
              </>
            }
          />
        </Container>
      </Grid>
    </Grid>
  )
}

export default React.memo(RoomIssueInformation)
