import React, { useCallback } from 'react'
import { Slider, Typography, Box } from '@material-ui/core'

interface VolumeSliderProps {
  onVolumeChange: (value: number) => void
  initialValue: number
  label: string
  disabled?: boolean
}

/**
 * VolumeSlider
 * @param initialValue - value that will be shown as default
 * @param label - slider helper label
 * @param disabled - flag that indicates if slider is disabled
 * @param onVolumeChange - action that will be triggered while is dragging the slider
 * @constructor
 */
const VolumeSlider: React.FC<VolumeSliderProps> = ({
  initialValue,
  label,
  onVolumeChange,
  disabled = false
}) => {
  const [value, setValue] = React.useState<number>(initialValue)

  const onChangeHandler = useCallback(
    (event: any, newValue: number) => {
      setValue(newValue)
      onVolumeChange(newValue)
    },
    [onVolumeChange]
  )

  return (
    <Box display="flex" flexDirection="column" onClick={(e) => e.stopPropagation()}>
      <Box paddingBottom={1}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Slider
          step={0.01}
          value={value}
          min={0}
          max={1}
          color="secondary"
          onChange={onChangeHandler}
          aria-labelledby="volume-slider"
          disabled={disabled}
        />
        <Box marginLeft={2} width={28}>
          <Typography variant="body2">{Math.round(value * 100)}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(VolumeSlider)
