import { useState, useCallback, useEffect, useMemo } from 'react'
import {
  SignallingConnectionState,
  JanusSignallingConnection
} from '../../../../../../../Common/janus/signalling/JanusSignallingConnection'
import { useEffectWithPredicate } from '../../../../../../../Common/hooks/useEffectWithPredicate'
import { BrowserStreamClient } from '../../../../../../../Common/janus/clients/sharing/BrowserStreamClient'
import { BrowserStreamMediaConstraints } from '../../../BrowserStream/hooks/useBrowserStreamMediaConstraints'
import { VideoBitrateSettings } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'

interface useBrowserStreamClientParams {
  signallingConnectionState: SignallingConnectionState
  janusSignallingConnection: JanusSignallingConnection
  room
  currentProfile
}

export const useBrowserStreamClient = ({
  signallingConnectionState,
  janusSignallingConnection,
  room,
  currentProfile
}: useBrowserStreamClientParams) => {
  const [
    currentBrowserStreamClient,
    setCurrentBrowserStreamClient
  ] = useState<BrowserStreamClient | null>(null)

  const [browserStreamConstraints, setBrowserStreamConstraints] = useState<
    BrowserStreamMediaConstraints | undefined
  >()

  const isBrowserStreamRequested = useMemo(() => !!browserStreamConstraints, [
    browserStreamConstraints
  ])

  const startBrowserStream = useCallback(
    (browserStreamConstraints: BrowserStreamMediaConstraints) => {
      setBrowserStreamConstraints(browserStreamConstraints)
    },
    [setBrowserStreamConstraints]
  )

  const leaveAndResetClient = useCallback(() => {
    if (currentBrowserStreamClient) currentBrowserStreamClient.leave()
    setCurrentBrowserStreamClient(null)
  }, [currentBrowserStreamClient, setCurrentBrowserStreamClient])

  const stopBrowserStream = useCallback(() => {
    setBrowserStreamConstraints(undefined)
    leaveAndResetClient()
  }, [setBrowserStreamConstraints, leaveAndResetClient])

  useEffectWithPredicate(
    {
      predicate: () =>
        isBrowserStreamRequested &&
        signallingConnectionState === SignallingConnectionState.Connected &&
        currentBrowserStreamClient === null,
      effect: () => {
        const browserStreamClient =
          currentBrowserStreamClient ||
          new BrowserStreamClient({
            roomId: room.id,
            roomHash: room.hash,
            displayName: currentProfile.displayName,
            userId: currentProfile.id,
            janusSignallingConnection
          })

        browserStreamClient.onJoined().subscribe((publisher) => {
          const { video, audio } = browserStreamConstraints!
          publisher.publish({ bitrate: video?.bitrate || VideoBitrateSettings.high, audio, video })
        })

        browserStreamClient.onPublishError().subscribe(() => {
          browserStreamClient.leave()
          stopBrowserStream()
        })

        setCurrentBrowserStreamClient(browserStreamClient)
      }
    },
    [
      signallingConnectionState,
      janusSignallingConnection,
      browserStreamConstraints,
      isBrowserStreamRequested,
      currentProfile,
      room,
      stopBrowserStream,
      currentBrowserStreamClient
    ]
  )

  useEffect(() => {
    return () => {
      if (currentBrowserStreamClient !== null) {
        leaveAndResetClient()
      }
    }
  }, [currentBrowserStreamClient, leaveAndResetClient])

  useEffect(() => {
    if (
      isBrowserStreamRequested &&
      signallingConnectionState === SignallingConnectionState.Error &&
      currentBrowserStreamClient !== null
    ) {
      leaveAndResetClient()
    }
  }, [
    stopBrowserStream,
    signallingConnectionState,
    isBrowserStreamRequested,
    leaveAndResetClient,
    currentBrowserStreamClient
  ])

  return {
    isBrowserStreamStarted: isBrowserStreamRequested && currentBrowserStreamClient !== null,
    startBrowserStream,
    stopBrowserStream
  }
}
