import React from 'react'

import { ReactComponent as More } from '../../../../Static/images/more.svg'

import SquareIconLabelButton from '../../../Buttons/SquareIconLabelButton/SquareIconLabelButton'
import Menu, { Option } from '../../../Menu/Menu'

import '../../../../../shared/theme/theme.scss'

interface MoreButtonProps {
  moreMenuOptions: Option[]
}

const MoreButton: React.FC<MoreButtonProps> = ({ moreMenuOptions }) => {
  return (
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      button={(openMenu) => (
        <SquareIconLabelButton icon={More} aria-label="settings" onClick={openMenu}>
          More
        </SquareIconLabelButton>
      )}
      menuOptions={moreMenuOptions}
    />
  )
}

export default React.memo(MoreButton)
