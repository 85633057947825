import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import ForgotPasswordView from './ForgotPasswordView/ForgotPasswordView'
import LoginView from './LoginView/LoginView'
import SignUpView from './SignUpView/SignUpView'
import RoomView from './RoomView/RoomView'
import { routes } from './routes'
import NotFoundRedirect from '../NotFoundView/NotFoundRedirect'
import VerifyView from './VerifyEmailView/VerifyEmailView'
import SingleSignOnView from './SingleSignOn/SingleSignOnView'
import { paths as authenticatedPaths } from '../AuthenticatedView/routes'
import UnauthenticatedChangePasswordView from './ChangePasswordView/UnauthenticatedChangePasswordView'
import UnauthenticatedAcceptInviteView from './AcceptInviteView/UnauthenticatedAcceptInviteView'
import JitSignUpView from './JitSignUpView/JitSignUpView'
import PostSSO from './PostSSO/PostSSO'
import { Grid } from '@material-ui/core'
import UnauthenticatedHeader from './Header/UnauthenticatedHeader'
import useRedirectUser from '../../../Components/LoginForm/hooks/useRedirectUser'
import { UnauthenticatedViewContext, UnauthenticatedContext } from './hooks/UnaunthentciatedViewContext'
import AcceptSpecialInviteView from './AcceptSpecialInviteView/AcceptSpecialInviteView'

interface UnauthenticatedViewProps {
  onLogin: () => void
}

const isAuthenticatedRoute = (pathname) => {
  return pathname !== '/' && authenticatedPaths.some((path) => path === pathname)
}

/**
 * Entry point component for all the views that do not require authentication
 * @param onLogin - callback called when user successfully authenticate
 * @constructor
 */
const UnauthenticatedView: React.FC<UnauthenticatedViewProps> = ({ onLogin }) => {
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('verify-2fa-activation')) {
      localStorage.setItem('twoFALink', location.pathname + location.search)
    }
  }, [location])

  const { makeRedirectQuery } = useRedirectUser()
  const urlQuery = isAuthenticatedRoute(location.pathname) ? '?' + makeRedirectQuery() : ''
  const { setSignUpRedirect, signUpRedirect } = UnauthenticatedContext()
  const value = { setSignUpRedirect, signUpRedirect }

  return (
    <UnauthenticatedViewContext.Provider value={value}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UnauthenticatedHeader />
        </Grid>
        <Grid item xs={12}>
          <Switch>
            <Route exact path={routes.login.path}>
              <LoginView onLogin={onLogin} />
            </Route>
            <Route exact path={routes.jitSignup.path}>
              <JitSignUpView />
            </Route>
            <Route exact path={routes.signUp.path}>
              <SignUpView onLogin={onLogin} />
            </Route>
            <Route exact path={routes.verify.path}>
              <VerifyView />
            </Route>
            <Route exact path={routes.forgotPassword.path}>
              <ForgotPasswordView />
            </Route>
            <Route exact path={routes.changePassword.path}>
              <UnauthenticatedChangePasswordView />
            </Route>
            <Route exact path={routes.room.path}>
              <RoomView onLogin={onLogin} />
            </Route>
            <Route exact path={routes.invite.path}>
              <UnauthenticatedAcceptInviteView />
            </Route>
            <Route exact path={routes.sso.path}>
              <SingleSignOnView />
            </Route>
            <Route exact path={routes.postSSO.path}>
              <PostSSO />
            </Route>
            <Route exact path={routes.acceptSpecialInvite.path}>
              <AcceptSpecialInviteView onLogin={onLogin}/>
            </Route>

            {/* if the path exists in authenticated paths, then it should redirect to login */}
            {authenticatedPaths.map((route) => {
              return (
                <Redirect
                  exact
                  key={route}
                  from={route}
                  to={{ pathname: routes.login.path, search: urlQuery }}
                />
              )
            })}

            <Route>
              <NotFoundRedirect />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </UnauthenticatedViewContext.Provider>
  )
}

export default React.memo(UnauthenticatedView)
