import { graphql } from 'babel-plugin-relay/macro'

const NotesSubscription = graphql`
  subscription NotesSubscription($input: NoteSubInput!) {
    notes(input: $input) {
      note {
        ...NoteItem_note
        id
        sessionId
        sessionTime
        content
        createdAt
        deletedAt
        profileId
        profileDisplayName
      }
      event
    }
  }
`

export default NotesSubscription
