import { useMutation } from '../../../../../Common/hooks/useMutation'
import verifyEmailMutation from '../../../../../GraphQL/mutations/VerifyEmailMutation'

interface VerifyEmailInput {
  input: {
    verificationToken: string
  }
}

export const useVerifyEmailMutation = () => {
  return useMutation<VerifyEmailInput, any>(verifyEmailMutation)
}
