import { EnvironmentSupplier, EnvName } from './environments'

const hostname = process.env.REACT_APP_BACKEND_HOSTNAME

const localConfig: EnvironmentSupplier = () => {
  /* Mimic lite environment by typing setToLite() in the console. Use setToLocal() to revert */
  const win = window as any
  win.setToLite = () => {
    localStorage.setItem('localEnv', 'lite')
    window.location.reload()
  }
  win.setToLocal = () => {
    localStorage.setItem('localEnv', 'local')
    window.location.reload()
  }
  const envName = localStorage.getItem('localEnv') === 'lite' ? EnvName.lite : EnvName.local

  return {
    envName,
    frontendUrl: `https://${hostname}`,
    apiUrl: `https://${hostname}/api`,
    authUrl: `https://${hostname}/auth`,
    moderationApiUrl: `https://${hostname}/moderation`,
    restApiUrl: `https://${hostname}/admin-api`,
    adminPortalUrl: `https://${hostname}/admin/`,
    webSocketUrl: `wss://${hostname}/websockets`,
    moderationWebSocketUrl: `wss://${hostname}/moderation/websocket`,
    esIngestorCSDUrl: `https://${hostname}/diagnostics/errorDetails`,
    captchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    devToolsSecurity: false,
    sso: {
      // it is important to use localhost here, because it is the only url registered to redirect in the aws.
      loginRedirect: `https://${hostname}/api/federatedlogin`,
      logoutRedirect: `https://${hostname}/api/federatedlogout`
    }
  }
}

export default localConfig
