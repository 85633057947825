import { useCallback, useState } from 'react'

import { SignUpForm } from './useSignUpForm'
import { AlertType } from '../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../Providers/AlertsProvider'
import { logger } from '../../../../../Common/log/Log'
import useApi from '../../../../../API/useApi'

export enum SignUpState {
  awaiting,
  submitting,
  success
}

export const ERROR_CODES = {
  '07210': 'Display name must be 1 to 79 characters in length.',
  '07220': 'A valid email is required.',
  '07230': 'Password is required.',
  '07240': 'Must verify you are human.'
}

export const useSignUp = () => {
  const alert = useAlerts()
  const [state, setState] = useState(SignUpState.awaiting)
  const { AuthAccount } = useApi()

  const registerUser = useCallback(
    async (formData: SignUpForm) => {
      setState(SignUpState.submitting)

      try {
        const resp = await AuthAccount.create(formData)
        if (resp.status === 'fail') {
          alert(resp.errors.message, AlertType.error)
          setState(SignUpState.awaiting)
        }
        if (resp.status === 'success') {
          setState(SignUpState.success)
        }
      } catch (e) {
        alert(e.response.data.errors.message, AlertType.error)
        setState(SignUpState.awaiting)
        logger.error(e)
      }
    },
    [AuthAccount, setState, alert]
  )

  return {
    registerUser,
    state
  }
}
