import { graphql } from 'babel-plugin-relay/macro'

const DeleteLiveroomMutation = graphql`
  mutation DeleteLiveroomMutation($input: SoftDeleteLiveroomByIdInput!) {
    softDeleteLiveroomById(input: $input) {
      liveroomEdge {
        node {
          id
          deletedAt
        }
      }
      query {
        canCreateRoom
        currentProfile {
          featureFlags {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
`

export default DeleteLiveroomMutation
