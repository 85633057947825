import React from 'react'
import MaterialCard from '@material-ui/core/Card'
import { Note } from '../../../../Models/apiEntities'
import { Box, Typography } from '@material-ui/core'
import NoteHeader from './NoteHeader/NoteHeader'
import graphql from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { useNoteItem } from './hooks/useNoteItem'

interface NoteProps {
  note: Note
  openRecordingLinksInNewTab?: boolean
  menu?: React.ReactNode | null
}

const NoteItem: React.FC<NoteProps> = ({ note, menu, openRecordingLinksInNewTab }) => {
  const {
    hasPlaybackLink,
    playbackLink,
    formattedRecordingTime,
    formattedMessageDate,
    messageAuthorName,
    parsedContent,
    isEdited
  } = useNoteItem(note)

  const editedIdentifier = isEdited
    ? `<span class="chat-message-item-edited">&nbsp;(edited)</span>`
    : ''

  return (
    <div className="chat-message-item-wrapper">
      <MaterialCard elevation={0} className={'chat-message-item'}>
        <Box className="chat-message-item-content" paddingX={2} paddingY={0.5}>
          <NoteHeader
            name={messageAuthorName}
            cardDate={formattedMessageDate}
            chatRecordingOffset={formattedRecordingTime}
            chatPlaybackLink={playbackLink}
            hasPlaybackLink={hasPlaybackLink}
            openRecordingLinksInNewTab={openRecordingLinksInNewTab}
            menuComponent={menu}
          />
          <Typography
            variant="body2"
            className="chat-message-item-description"
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: parsedContent + editedIdentifier
            }}
          />
        </Box>
      </MaterialCard>
    </div>
  )
}

export default createFragmentContainer(React.memo(NoteItem), {
  note: graphql`
    fragment NoteItem_note on Note {
      id
      sessionId
      sessionTime
      content
      createdAt
      updatedAt
      deletedAt
      profileId
      profileDisplayName
    }
  `
})
