/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AcceptInviteQueryVariables = {
    inviteToken: unknown;
};
export type AcceptInviteQueryResponse = {
    readonly invite: {
        readonly id: unknown;
        readonly token: unknown;
        readonly email: string | null;
        readonly expirationTime: unknown;
        readonly profileId: unknown | null;
        readonly roomId: unknown;
        readonly roomHash: unknown | null;
        readonly isNewUser: boolean | null;
        readonly isAccepted: boolean | null;
    } | null;
};
export type AcceptInviteQuery = {
    readonly response: AcceptInviteQueryResponse;
    readonly variables: AcceptInviteQueryVariables;
};



/*
query AcceptInviteQuery(
  $inviteToken: UUID!
) {
  invite: getInviteDetails(inviteToken: $inviteToken) {
    id
    token
    email
    expirationTime
    profileId
    roomId
    roomHash
    isNewUser
    isAccepted
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inviteToken"
  }
],
v1 = [
  {
    "alias": "invite",
    "args": [
      {
        "kind": "Variable",
        "name": "inviteToken",
        "variableName": "inviteToken"
      }
    ],
    "concreteType": "Invite",
    "kind": "LinkedField",
    "name": "getInviteDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expirationTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roomId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roomHash",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isNewUser",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAccepted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptInviteQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptInviteQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "163919898badfd29db4151b4d613cbe7",
    "id": null,
    "metadata": {},
    "name": "AcceptInviteQuery",
    "operationKind": "query",
    "text": "query AcceptInviteQuery(\n  $inviteToken: UUID!\n) {\n  invite: getInviteDetails(inviteToken: $inviteToken) {\n    id\n    token\n    email\n    expirationTime\n    profileId\n    roomId\n    roomHash\n    isNewUser\n    isAccepted\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a8a3a649cbfbe6dde9d0066d38fad6e';
export default node;
