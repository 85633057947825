import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from '../../../../routes'

/**
 * Manage logic for the room card
 */
export const useRoomNavigation = () => {
  const history = useHistory()

  const goToRoom = useCallback(
    (room) => {
      const url = routes.room.path.replace(':hash', room.hash)
      history.push(url)
    },
    [history]
  )

  const goToRecordings = useCallback(
    (room) => {
      const url = routes.recordings.path.replace(':roomId', room.id)
      history.push(url)
    },
    [history]
  )

  return {
    goToRoom,
    goToRecordings
  }
}
