import React, { useCallback, useRef } from 'react'
import { TextField, Box, IconButton, Tooltip } from '@material-ui/core'
import { useNoteForm } from './hooks/useNoteForm'
import { Note } from '../../../Models/apiEntities'
import { useEffectNonNull } from '../../../Common/hooks/useEffectNonNull'
import { Check, Clear } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'

const enterKeyCode = 13
const escKeyCode = 27

interface NotesFormProps {
  isInProgress: boolean
  performCreateOrUpdate: (content: string) => Promise<void>
  selectedMessage?: Note
  onDeselectMessage: () => void
}

const NotesForm: React.FC<NotesFormProps> = ({
  isInProgress,
  performCreateOrUpdate,
  selectedMessage,
  onDeselectMessage
}) => {
  const {
    handleSubmit,
    inputs: { content }
  } = useNoteForm({ performCreateOrUpdate, selectedMessage })
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>()

  const submitForm = useCallback(
    (event) => {
      event.preventDefault()
      if (inputRef.current && !inputRef.current.value.trim()) return
      formRef?.current?.dispatchEvent(new Event('submit'))
    },
    [formRef]
  )

  const deselectMessage = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    onDeselectMessage()
  }, [inputRef, onDeselectMessage])

  const handleKeyDown = useCallback(
    (event) => {
      if (!isInProgress) {
        if (event.nativeEvent.keyCode === enterKeyCode && event.nativeEvent.shiftKey === false) {
          submitForm(event)
        } else if (event.nativeEvent.keyCode === escKeyCode) {
          deselectMessage()
        }
      }
    },
    [isInProgress, deselectMessage, submitForm]
  )

  useEffectNonNull(() => {
    inputRef.current!.focus()
  }, [selectedMessage])

  const placeholder = selectedMessage
    ? `Editing note "${selectedMessage.content.slice(0, 15)}${selectedMessage.content.length > 14 ? '...' : ''
    }"`
    : 'Write a note here. Press Enter to submit.'

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Box className={'chat-form' + (selectedMessage ? ' editing-chat-or-note' : '')} padding={2}>
        <TextField
          {...content}
          inputRef={(ref) => {
            inputRef.current = ref
            content.inputRef(ref)
          }}
          hiddenLabel
          placeholder={placeholder}
          fullWidth
          className="chat-input"
          multiline
          onKeyDown={handleKeyDown}
          variant="filled"
          autoFocus
          rows={4}
        />
        <div className="chat-form-btns">
          <Tooltip title="Update note" enterDelay={1500}>
            <IconButton
              aria-label="update note"
              style={{ color: green[500] }}
              onClick={submitForm}
              size="small"
            >
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Edit" enterDelay={1500}>
            <IconButton
              color="secondary"
              aria-label="cancel edit"
              role="button"
              onClick={deselectMessage}
              size="small"
            >
              <Clear />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </form>
  )
}

export default React.memo(NotesForm)
