import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import CSMAlerts from '../../../../Components/CSMAlerts/CSMAlerts'
import Logo from '../../../../Components/Logo/Logo'

const useStyles = makeStyles({
    logo: {
        maxWidth: '250px',
        height: 'auto',
        display: 'block',
    },
    container: {
        paddingBottom: '40px'
    }
})

const UnauthenticatedHeader = () => {
    const classes = useStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={6}>
                <Logo className={classes.logo} />
            </Grid>
            <Grid item container xs={6} justify="flex-end">
                <CSMAlerts />
            </Grid>
        </Grid>
    )
}

export default UnauthenticatedHeader