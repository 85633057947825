export interface TransportStats {
  selectedCandidatePairId: string
  bytesReceived?: number
  bytesSent?: number
}

export class WebRTCStatsParser {
  static getRemoteVolume(stats: RTCStatsReport) {
    let volume = 0
    stats.forEach((res) => {
      if (!res || res.kind !== 'audio' || res.type !== 'inbound-rtp') return 0
      volume = res.audioLevel * 1000
    })

    return volume
  }
  
  static getTransportStats(stats: RTCStatsReport): TransportStats | {} {
    return Object.keys(stats).reduce((transportStat, statKey) => {
      const stat = stats[statKey]
      if (stat.type === 'transport') {
        transportStat = {
          selectedCandidatePairId: stat.selectedCandidatePairId,
          bytesReceived: stat.bytesReceived,
          bytesSent: stat.bytesSent
        }
      }
      return transportStat
    }, {})
  }
}
