/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, EffectCallback, DependencyList } from 'react'

/**
 * Runs effect only if all dependencies have non null values
 *
 * @param   {EffectCallback}  effect               effect to take place
 * @param   {any[]}           dependencies         dependencies list
 */
export const useEffectNonNull = (effect: EffectCallback, dependencies: DependencyList) => {
  useEffect(() => {
    if (dependencies.some((dependency) => dependency == null)) {
      return
    }
    return effect()
  }, dependencies)
}
