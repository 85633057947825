import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import './PulseLoader.scss'

interface ISymbol {
  percentage: number
  size?: number
  color?: string
  messages?: string[]
}

interface IStyledSVG {
  color?: string
  blurred?: boolean
}

const StyledSVG = styled.svg<IStyledSVG>`
  * {
    stroke-width: 0.5px;
    ${({ blurred }) => (blurred ? 'fill:' : 'fill:')} ${({ color }) => color};
  }
  ${({ blurred }) => blurred && `filter: blur(10px);`}
`

const StyledCenteredTextDiv = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
`

const StyledPathA = styled.path``
const StyledPathB = styled.path``

const loadingMessages = [
  `Welcome! We are starting up your room.`,
  `Thanks for waiting, your room is loading.`,
  `Almost there! We're getting your room ready.`,
  `Your room will be available in a moment.`,
]

export const PulseLoader: React.FC<ISymbol> = ({
  percentage,
  size = 50,
  color = 'rgb(255,255,255)',
  messages
}) => {
  const maxWidth = 540
  const leftPadding = -32

  const gradients = {
    0: 'rgba(255, 107, 0, 0.3)',
    50: 'rgba(255, 0, 214, 0.3)',
    100: 'rgba(0, 102, 255, 0.3)',
  }

  const [left, setLeft] = useState(leftPadding)
  const [gradient, setGradient] = useState(gradients[0])

  // Finds the closes number to the percentage given in the keys of the gradients array
  const findGradientColor = (percentage) => {
    return Object.keys(gradients)
      .map((key) => parseInt(key))
      .reduce(function (prev, curr) {
        return Math.abs(curr - percentage) < Math.abs(prev - percentage) ? curr : prev
      })
  }

  useEffect(() => {
    const offset = (maxWidth * percentage) / 100
    setLeft(Math.min(leftPadding + offset, maxWidth + leftPadding))

    const gradientColor = findGradientColor(percentage)
    setGradient(gradients[gradientColor])
  }, [percentage])

  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(0)

  useEffect(() => {
    let interval = setInterval(() => setCurrentLoadingMessage((i) => (i += 1)), 15000)
    return () => clearInterval(interval)
  }, [])

  return (
    <StyledCenteredTextDiv>
      <StyledSVG
        color={color}
        width={size}
        height={size}
        viewBox="0 0 23 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <StyledPathA d="M0 25H22.5026V20.002H5.00131V5.00131H22.5026V0H0V25Z" />
        <StyledPathB d="M22.4173 9.53174H9.21777V14.8436H22.4173V9.53174Z" />
      </StyledSVG>

      <div className="loader-container" style={{ width: maxWidth }}>
        <div className="loader-gradient" style={{ width: maxWidth }}></div>
        <div className="loader-blur" style={{ left, backgroundColor: gradient }}></div>
      </div>
      <div>{messages ? messages[currentLoadingMessage % messages.length] : loadingMessages[currentLoadingMessage % loadingMessages.length]}</div>
    </StyledCenteredTextDiv>
  )
}
