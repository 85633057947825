import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import palette from '../../../Common/theme/colors/palette'
import AdaptiveTitle from '../../../Components/AdaptiveTitle/AdaptiveTitle'

const NotFoundView: React.FC = () => {
  return (
    <Grid container spacing={3} justify="center">
      <Grid item container xs={12} justify="center">
        <Box fontSize={168} marginBottom={-6}>
          404
        </Box>
      </Grid>
      <Grid item container xs={12} justify="center">
        <AdaptiveTitle>Oops! Page not found.</AdaptiveTitle>
      </Grid>
      <Grid item container xs={12} justify="center">
        Please&nbsp;
        <Link to={'/'}>
          <Box color={palette.root.secondary}>click here</Box>
        </Link>
        &nbsp;to return to home.
      </Grid>
    </Grid>
  )
}

export default NotFoundView
