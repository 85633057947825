import React, { useCallback } from 'react'
import { UserProfile } from '../../../Models/apiEntities'
import { Switch, Route, Redirect } from 'react-router-dom'
import { routes } from './routes'
import { paths as unauthenticatedPaths } from '../UnauthenticatedView/routes'

import RoomView from './RoomView/RoomView'
import DashboardView from './DashboardView/DashboardView'
import Header from '../../../Components/Header/Header'
import AccountView, { tabsValues } from './AccountView/AccountView'
import { Box, Grow } from '@material-ui/core'
import { Animations } from '../../../Common/constants/animations'
import { ProgressProvider } from '../../../Providers/ProgressProvider'
import { UserFeatureFlagsProvider } from '../../../Providers/UserFeatureFlagsProvider'
import ProgressBar from '../../../Components/ProgressBar/ProgressBar'
import RecordingsView from './RecordingsView/RecordingsView'
import TwoFactorConfigurationView from './TwoFactorConfigurationView/TwoFactorConfigurationView'
import TwoFactorDeactiveConfigurationView from './TwoFactorDeactiveConfigurationView/TwoFactorDeactiveConfigurationView'
import NotFoundRedirect from '../NotFoundView/NotFoundRedirect'
import FadingRoute from '../../../Components/FadingRoute/FadingRoute'
import AuthenticatedChangePasswordView from './ChangePasswordView/AuthenticatedChangePasswordView'
import UnauthenticatedForgotPasswordView from '../UnauthenticatedView/ForgotPasswordView/ForgotPasswordView';
import AuthenticatedAcceptInviteView from './AcceptInviteView/AuthenticatedAcceptInviteView'
import LandingView from '../LandingView/LandingView'
import AcceptSpecialInviteView from './AcceptSpecialInviteView/AcceptSpecialInviteView'

interface AuthenticatedViewProps {
  userProfile: UserProfile
  onLogout: () => void
}

/**
 * Entry point component for all the views that require authentication
 * @param userProfile - currently logged in user profile
 * @param onLogout - function that allows logging out
 * @constructor
 */
const AuthenticatedView: React.FC<AuthenticatedViewProps> = ({
  userProfile,
  onLogout,
}: AuthenticatedViewProps) => {
  const renderContent = useCallback(
    (animateIn) => (
      <Box height="100%" width="100%" overflow="auto">
        <Switch>
          <FadingRoute exact path={routes.dashboard.path}>
            <DashboardView />
          </FadingRoute>
          <FadingRoute exact path={routes.room.path} fadeIn={animateIn}>
            <RoomView />
          </FadingRoute>
          <FadingRoute exact path={routes.account.path}>
            <AccountView />
          </FadingRoute>
          <FadingRoute exact path={routes.twoFactorConfiguration.path}>
            <LandingView>
              <TwoFactorConfigurationView />
            </LandingView>
          </FadingRoute>
          <FadingRoute exact path={routes.twoFactorDeactiveConfiguration.path}>
            <LandingView>
              <TwoFactorDeactiveConfigurationView />
            </LandingView>
          </FadingRoute>
          <FadingRoute path={routes.recordings.path} fadeIn={animateIn}>
            <RecordingsView />
          </FadingRoute>
          <FadingRoute exact path={routes.acceptSpecialInvite.path}>
            <AcceptSpecialInviteView />
          </FadingRoute>

          <Redirect
            from={routes.forgotPassword.path}
            to={routes.account.path.replace(':tab', tabsValues.settings)}
          />

          <Route exact path={routes.invite.path}>
            <AuthenticatedAcceptInviteView />
          </Route>

          {/* Previously AuthenticatedChangePasswordView; now use unAuthenticatedView and Okta */}
          <Route exact path={routes.changePassword.path}>
            <UnauthenticatedForgotPasswordView />
          </Route>

          {/* if the path exists in unauthenticated paths, then it should redirect to dashboard */}
          {unauthenticatedPaths.map((route) => (
            <Redirect key={route} exact from={route} to={routes.dashboard.path} />
          ))}

          <Route>
            <NotFoundRedirect />
          </Route>
        </Switch>
      </Box>
    ),
    []
  )
  const renderProgress = useCallback(
    () => (
      <Grow in={true} timeout={Animations.regular}>
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <ProgressBar className="progress-bar-default-size" />
        </Box>
      </Grow>
    ),
    []
  )
  return (
    <UserFeatureFlagsProvider featureFlags={userProfile.featureFlags.nodes}>
      <Header currentProfile={userProfile} onLogout={onLogout}>
        <ProgressProvider
          renderProgress={renderProgress}
          renderContent={renderContent}
          progressTimeout={Animations.fullProgressCycle}
        />
      </Header>
    </UserFeatureFlagsProvider>
  )
}

export default React.memo(AuthenticatedView)
