import { graphql } from 'babel-plugin-relay/macro'

const ListenApproveDeviceLoginCodeRequestSubscription = graphql`
  subscription ListenApproveDeviceLoginCodeRequestSubscription($roomId: String!) {
    listenApproveDeviceLoginCodeRequest(input: { _roomId: $roomId }) {
      approve {
        deviceCodeId
        deviceName
      }
      event
    }
  }
`

export default ListenApproveDeviceLoginCodeRequestSubscription
