import React from 'react'
import { Message } from '../../Models/apiEntities'
import { useChatMessageAction } from './hooks/useChatMessageAction'
import ChatForm from './ChatForm/ChatForm'
import ChatMessageList from './ChatMessageList/ChatMessageList'
import ChatHeader from './ChatHeader/ChatHeader'
import { useRecording } from '../../Providers/RecordingProvider'

import './Chat.scss'
import { Box, Divider } from '@material-ui/core'
import { useDialog } from '../RoomDialog/hooks/useDialog'
import ClearChatDialog from './ClearChatDialog/ClearChatDialog'
import { ChatDecisionInfo } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomPermissions'
import ChatMessageItem from './ChatMessageList/ChatMessageItem/ChatMessageItem'
import ChatMessageMenu from './ChatMessageList/ChatMessageItem/ChatMessageMenu/ChatMessageMenu'

interface ChatProps {
  defaultMessagesNumber?: number
  currentProfileId: string
  room: {
    id: string
    hash: string
  }
  messages: Message[]
  chatConnectionName: string
  relay: any
  isVisible: boolean
  chatPermissionInfo: ChatDecisionInfo
}

const Chat: React.FC<ChatProps> = ({
  room,
  currentProfileId,
  defaultMessagesNumber = 10,
  messages,
  chatConnectionName,
  relay,
  isVisible,
  chatPermissionInfo
}) => {
  const recordingInfo = useRecording()
  const [isClearChatDialogOpen, openClearChatDialog, closeClearChatDialog] = useDialog()
  const { isInProgress, actions, selectedMessage, selectMessageToEdit } = useChatMessageAction({
    roomId: room.id,
    connectionName: chatConnectionName,
    recordingInfo
  })
  return (
    <>
      <Box className="chats-content">
        <ChatHeader
          onClearChatClicked={openClearChatDialog}
          chatPermissionInfo={chatPermissionInfo}
          doMessagesExist={Boolean(messages.length)}
        />
        <Divider />
        <ChatMessageList pagination={{ ...relay, defaultMessagesNumber }} isVisible={isVisible}>
          {messages.map((message) => {
            const isAuthor = message.profileId === currentProfileId
            const handleSelectEditMessage = () => selectMessageToEdit(message)
            const handleDeleteMessage = () => actions.delete(message.id)

            return (
              <ChatMessageItem
                key={message.id}
                message={message}
                openRecordingLinksInNewTab
                menu={
                  isAuthor ? (
                    <ChatMessageMenu
                      handleSelectEditMessage={handleSelectEditMessage}
                      handleDeleteMessage={handleDeleteMessage}
                    />
                  ) : null
                }
              />
            )
          })}
        </ChatMessageList>
        <Divider />
        <ChatForm
          performCreateOrUpdate={actions.createOrUpdate}
          isInProgress={isInProgress}
          selectedMessage={selectedMessage}
          onDeselectMessage={() => selectMessageToEdit(undefined)}
        />
      </Box>
      {isClearChatDialogOpen && (
        <ClearChatDialog
          onClose={closeClearChatDialog}
          isInProgress={isInProgress}
          performClearAll={actions.clearAll}
        />
      )}
    </>
  )
}

export default React.memo(Chat)
