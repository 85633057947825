/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RemoveRoomFromDashboardInput = {
    clientMutationId?: string | null;
    _roomId?: unknown | null;
};
export type RemoveRoomFromDashboardMutationVariables = {
    input: RemoveRoomFromDashboardInput;
};
export type RemoveRoomFromDashboardMutationResponse = {
    readonly removeRoomFromDashboard: {
        readonly query: {
            readonly currentProfile: {
                readonly " $fragmentRefs": FragmentRefs<"HomeView_currentProfile">;
            } | null;
        } | null;
    } | null;
};
export type RemoveRoomFromDashboardMutation = {
    readonly response: RemoveRoomFromDashboardMutationResponse;
    readonly variables: RemoveRoomFromDashboardMutationVariables;
};



/*
mutation RemoveRoomFromDashboardMutation(
  $input: RemoveRoomFromDashboardInput!
) {
  removeRoomFromDashboard(input: $input) {
    query {
      currentProfile {
        ...HomeView_currentProfile
      }
    }
  }
}

fragment HomeView_currentProfile on Profile {
  type
  id
  displayName
  availableCredits
  featureFlags {
    nodes {
      key
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveRoomFromDashboardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveRoomFromDashboardPayload",
        "kind": "LinkedField",
        "name": "removeRoomFromDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "currentProfile",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "HomeView_currentProfile"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveRoomFromDashboardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveRoomFromDashboardPayload",
        "kind": "LinkedField",
        "name": "removeRoomFromDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "currentProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableCredits",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ColumnProfileFeatureFlagsConnection",
                    "kind": "LinkedField",
                    "name": "featureFlags",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ColumnProfileFeatureFlagsRecord",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "key",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98d4d6ce608659a4398976e1c626d1ba",
    "id": null,
    "metadata": {},
    "name": "RemoveRoomFromDashboardMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveRoomFromDashboardMutation(\n  $input: RemoveRoomFromDashboardInput!\n) {\n  removeRoomFromDashboard(input: $input) {\n    query {\n      currentProfile {\n        ...HomeView_currentProfile\n      }\n    }\n  }\n}\n\nfragment HomeView_currentProfile on Profile {\n  type\n  id\n  displayName\n  availableCredits\n  featureFlags {\n    nodes {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b39200f07c7c0195537d061a466f9a15';
export default node;
