import { Box, BoxProps } from '@material-ui/core'
import React from 'react'

const ExpandableBox: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box flexGrow={1} minWidth={0} minHeight={0} {...boxProps}>
      {children}
    </Box>
  )
}

export default React.memo(ExpandableBox)
