import React, { createContext, useContext } from 'react'
import { FeatureFlag, AppFeatureFlag } from '../Models/apiEntities'

const appFeatureFlagsContext = createContext<FeatureFlag<AppFeatureFlag>[]>([])

interface AppProviderFeatureFlagsProps {
  featureFlags: FeatureFlag<AppFeatureFlag>[]
}

export const AppFeatureFlagsProvider: React.FC<AppProviderFeatureFlagsProps> = ({
  featureFlags,
  children
}) => {
  return (
    <appFeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </appFeatureFlagsContext.Provider>
  )
}

export const useAppFeatureFlags = () => {
  return useContext(appFeatureFlagsContext)
}
