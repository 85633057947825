import { useEffect, useState } from 'react'

interface KeyShortcut {
  id: string
  keys: string[]
  onDown?: (event?: KeyShortcutParam) => any
  onUp?: (event?: KeyShortcutParam) => any
}

type KeysPressed = Set<string>

export interface KeyShortcutParam {
  event: KeyboardEvent
  keysPressed: KeysPressed
}

interface OnDown extends KeyShortcut {
  onDown: (event: KeyShortcutParam) => any
}

interface OnUp extends KeyShortcut {
  onUp: (event: KeyShortcutParam) => any
}

const areKeysPressed = (requiredKeys, keysPressed): boolean => {
  return (
    requiredKeys.length === keysPressed.size && requiredKeys.every((key) => keysPressed.has(key))
  )
}

const nonTypingKeys = ['Alt', 'Control', 'Meta']

const couldAffectTyping = (event: KeyboardEvent, requiredKeys) => {
  if (requiredKeys.length > 1) return false
  const tag = (event?.target as Element)?.tagName
  if (tag === 'TEXTAREA' || tag === 'INPUT') return !nonTypingKeys.includes(requiredKeys[0])
  else return false
}

const shouldDispatchCallback = (event, requiredKeys, keysPressed) =>
  areKeysPressed(requiredKeys, keysPressed) && !couldAffectTyping(event, requiredKeys)

export const useKeyboardShortcuts = (commands: { current: KeyShortcut[] }) => {
  const [keysPressed, setKeyPressed] = useState<KeysPressed>(new Set())

  useEffect(() => {
    const downCommands = commands.current.filter((c): c is OnDown => Boolean(c.onDown))
    const upCommands = commands.current.filter((c): c is OnUp => Boolean(c.onUp))

    const handleDown = (event: KeyboardEvent) => {
      const { key } = event
      keysPressed.add(key)
      setKeyPressed(keysPressed)
      const didCommandsRun = downCommands.map(({ keys, onDown }) => {
        if (shouldDispatchCallback(event, keys, keysPressed)) {
          onDown({ event, keysPressed })
          return true
        }
        return false
      })
      if (didCommandsRun.find(Boolean)) event.preventDefault()
    }

    const handleUp = (event: KeyboardEvent) => {
      const { key } = event
      const didCommandsRun = upCommands.map(({ keys, onUp }) => {
        if (shouldDispatchCallback(event, keys, keysPressed)) {
          onUp({ event, keysPressed })
          return true
        }
        return false
      })
      keysPressed.delete(key)
      setKeyPressed(keysPressed)
      if (didCommandsRun.find(Boolean)) event.preventDefault()
    }

    document.addEventListener('keydown', handleDown)
    document.addEventListener('keyup', handleUp)
    return () => {
      document.removeEventListener('keydown', handleDown)
      document.removeEventListener('keyup', handleUp)
    }
    // missing keysPressed to avoid re-running
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands])
}
