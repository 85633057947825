import { defaultDeviceId, emptyMediaDevices, MediaDevicesState, noneDeviceId, useMediaDevices } from './useMediaDevices'
import { useCallback, useMemo, useRef, useState } from 'react'
import { equals } from 'ramda'
import { useEffectWithPredicate } from '../../../../../../Common/hooks/useEffectWithPredicate'
import { logger } from '../../../../../../Common/log/Log'
import { ProfilePreferences } from '../../../../../../Common/preferences/usePreferences'
import { PreferenceType } from '../../../../../../Common/preferences/Preferences'
import { useSavePreferencesMutation } from '../../../../../../GraphQL/hooks/useSavePreferencesMutation'
import { useRoomFeatureFlag } from '../../../../../../Common/utils/featureFlags'
import { RoomFeatureFlag } from '../../../../../../Models/apiEntities'
import { usePrevious } from '../../../../../../Common/hooks/usePrevious'
import { RoomState } from './useRoomRoutingState'
import { PPTKey } from '../../DeviceSelectionDialog/hooks/usePTTShortcut'

// FIXME: Although 'high' and 'medium' should should have the same bitrate (per settings in the desktop app),
// the radio buttons in the selection dialog are selected by this integer. Therefore, both 'medium' and 'high'
// buttons are 'checked' in the dialog.
export const VideoBitrateSettings = {
  high: 2048 * 1024,
  medium: 1536 * 1024,
  low: 768 * 1024
}

export enum VideoQuality {
  low = VideoBitrateSettings.low,
  medium = VideoBitrateSettings.medium,
  high = VideoBitrateSettings.high
}

export interface MediaSelection {
  camera: MediaDeviceInfo | null
  microphone: MediaDeviceInfo | null
  speaker: MediaDeviceInfo | null
  cameraQuality: VideoQuality
  PPTShortcut: PPTKey
}

export enum MediaSetupState {
  timeout,
  requestingPermission,
  awaiting, // enumerating devices (progress?)
  success // we have the initial media selection
}

interface useMediaSetupParams {
  profilePreferences: ProfilePreferences
  roomState: RoomState
  canPublish: boolean
}

const mapCustomBitrateToVideoQuality = (customBitrateValue): VideoQuality => {
  logger.log(`Custom room bitrate is set: ${customBitrateValue}`)

  const possibleValues = ['low', 'medium', 'high']
  const roomBitrate = possibleValues.find((label) => label === customBitrateValue) ?? 'medium'

  return VideoQuality[roomBitrate]
}

const checkIfDifferentDefaultDevice = (
  device1: MediaDeviceInfo,
  device2?: MediaDeviceInfo | null
) => {
  if (!device2) return false
  return (
    device1.deviceId === defaultDeviceId &&
    device2.deviceId === defaultDeviceId &&
    device1.groupId !== device2.groupId
  )
}

export const useMediaSetup = ({ profilePreferences, roomState, canPublish }: useMediaSetupParams) => {
  const [mediaDevicesState, mediaDevices] = useMediaDevices()
  const previousMediaDevices = usePrevious(mediaDevices)
  const [mediaSelection, setMediaSelection] = useState<MediaSelection | null>(null)
  const [executeSavePreferencesMutation] = useSavePreferencesMutation()
  const [isCustomBitrateSet, customBitrateValue] = useRoomFeatureFlag(
    RoomFeatureFlag.customBaseBitrate
  )

  const cameraPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.VIDEO_INPUT_DEVICE_ID),
    [profilePreferences]
  )
  const microphonePreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.AUDIO_INPUT_DEVICE_ID),
    [profilePreferences]
  )
  const speakerPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.AUDIO_OUTPUT_DEVICE_ID),
    [profilePreferences]
  )
  const PPTPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.PUSH_TO_TALK_SHORTCUT),
    [profilePreferences]
  )

  const isInitialLoad = useRef(true)

  const updateMediaSelection = useCallback(
    async (newMediaSelection: MediaSelection) => {
      // update only if new media selection is different than previous media selection
      if (!equals(mediaSelection, newMediaSelection)) {
        setMediaSelection(newMediaSelection)
        logger.log(`New media selection: ${JSON.stringify(newMediaSelection, null, 2)}`)

        try {
          await executeSavePreferencesMutation({
            input: {
              preferencesToSet: [
                {
                  preferenceTypeId: cameraPreference.preferenceTypeId,
                  preferenceValue: newMediaSelection.camera?.deviceId || noneDeviceId
                },
                {
                  preferenceTypeId: microphonePreference.preferenceTypeId,
                  preferenceValue: newMediaSelection.microphone?.deviceId || noneDeviceId
                },
                {
                  preferenceTypeId: speakerPreference.preferenceTypeId,
                  preferenceValue: newMediaSelection.speaker?.deviceId || noneDeviceId
                },
                {
                  preferenceTypeId: PPTPreference.preferenceTypeId,
                  preferenceValue: newMediaSelection.PPTShortcut || noneDeviceId
                }
              ]
            }
          })
          logger.log('media selection updated on user profile')
        } catch (e) {
          logger.error(e)
        }
      }
    },
    [
      cameraPreference,
      microphonePreference,
      speakerPreference,
      PPTPreference,
      mediaSelection,
      setMediaSelection,
      executeSavePreferencesMutation
    ]
  )

  // load initial media selection based on available devices and profile preferences
  useEffectWithPredicate(
    {
      predicate: () => mediaDevices && !mediaSelection,
      effect: () => {
        const { camera, microphone, speaker } = mediaDevices!

        // preferred devices
        const preferredCamera =
          cameraPreference.getValue() === noneDeviceId
            ? null
            : camera.devicesById.get(cameraPreference.getValue()) || camera.default
        const preferredMicrophone =
          microphonePreference.getValue() === noneDeviceId
            ? null
            : microphone.devicesById.get(microphonePreference.getValue()) || microphone.default
        const preferredSpeaker =
          speakerPreference.getValue() === noneDeviceId
            ? null
            : speaker.devicesById.get(speakerPreference.getValue()) || speaker.default

        const newMediaSelection = {
          camera: preferredCamera,
          microphone: preferredMicrophone,
          speaker: preferredSpeaker,
          cameraQuality: isCustomBitrateSet
            ? mapCustomBitrateToVideoQuality(customBitrateValue)
            : VideoQuality.medium,
          PPTShortcut: PPTPreference.preferenceValue as PPTKey
        }
        setMediaSelection(newMediaSelection)
        isInitialLoad.current = true
      }
    },
    [mediaDevices]
  )

  // check if a device on current media selection was disconnected and if so:
  // if in lobby pick next available device
  // if in liveroom fallback to none for safety
  useEffectWithPredicate(
    {
      predicate: () => !!(mediaDevices && mediaSelection),
      effect: () => {
        const newMediaSelection = { ...mediaSelection! }
        const pickDevice = (devices: MediaDeviceInfo[]) => {
          if (roomState !== RoomState.Lobby || devices.length === 0) {
            return null
          }
          return devices[0]
        }
        if (
          newMediaSelection.camera &&
          !mediaDevices.camera.devicesById.get(newMediaSelection.camera?.deviceId as string)
        ) {
          newMediaSelection.camera = pickDevice(mediaDevices.camera.devices)
        }
        const selectedMic = mediaDevices.microphone.devicesById.get(
          newMediaSelection.microphone?.deviceId as string
        )
        if (newMediaSelection.microphone) {
          if (!selectedMic) {
            newMediaSelection.microphone = pickDevice(mediaDevices.microphone.devices)
          } else if (checkIfDifferentDefaultDevice(selectedMic!, mediaSelection?.microphone)) {
            newMediaSelection.microphone = selectedMic!
          }
        }
        if (
          newMediaSelection.speaker &&
          !mediaDevices.speaker.devicesById.get(newMediaSelection.speaker?.deviceId as string)
        ) {
          newMediaSelection.speaker = pickDevice(mediaDevices.speaker.devices)
        }
        if (!equals(mediaSelection, newMediaSelection)) {
          setMediaSelection(newMediaSelection)
        }
      }
    },
    [mediaDevices, roomState]
  )

  // check if new devices are connected and if in lobby and selection was none,
  // assume user wants to select newly connected devices
  useEffectWithPredicate(
    {
      predicate: () => !!(roomState === RoomState.Lobby && mediaSelection),
      effect: () => {
        if (isInitialLoad.current) {
          isInitialLoad.current = false
          return
        }

        const newMediaSelection = { ...mediaSelection! }
        const pickDevice = (newDevices: MediaDeviceInfo[], previousDevices: MediaDeviceInfo[]) => {
          if (newDevices.length === 0) {
            return null
          }
          const newDevice = newDevices.find(
            (newDevice) =>
              !previousDevices.some(
                (previousDevice) => newDevice.deviceId === previousDevice.deviceId
              )
          )
          return newDevice || null
        }
        if (!mediaSelection?.camera) {
          newMediaSelection.camera = pickDevice(
            mediaDevices.camera.devices,
            previousMediaDevices!.camera.devices
          )
        }
        if (!mediaSelection?.microphone) {
          newMediaSelection.microphone = pickDevice(
            mediaDevices.microphone.devices,
            previousMediaDevices!.microphone.devices
          )
        }
        if (!mediaSelection?.speaker) {
          newMediaSelection.speaker = pickDevice(
            mediaDevices.speaker.devices,
            previousMediaDevices!.speaker.devices
          )
        }
        if (!equals(mediaSelection, newMediaSelection)) {
          setMediaSelection(newMediaSelection)
        }
      }
    },
    [mediaDevices, roomState]
  )

  const mediaSetupState = useMemo(() => {
    if (mediaSelection && mediaDevicesState === MediaDevicesState.success ) {
      return MediaSetupState.success
    }
    if (mediaDevicesState === MediaDevicesState.requestingPermission) {
      return MediaSetupState.requestingPermission
    }
    if (mediaDevicesState === MediaDevicesState.awaiting) {
      return MediaSetupState.awaiting
    }
    return MediaSetupState.timeout
  }, [mediaSelection, mediaDevicesState])

  return {
    mediaSetupState,
    // if you don't have published permission, you can't select devices
    mediaDevices: canPublish ? mediaDevices! : (mediaDevices ? {
      ...mediaDevices,
      camera: { ...('camera' in (mediaDevices || {}) ? mediaDevices.camera : emptyMediaDevices.camera), devices: [] },
      microphone: { ...('microphone' in (mediaDevices || {}) ? mediaDevices.microphone : emptyMediaDevices.microphone), devices: [] },
    } : mediaDevices),
    // if you don't have published permission, set microphone and camera to none
    mediaSelection: canPublish ? mediaSelection! : (mediaSelection ? {
      ...mediaSelection,
      microphone: null,
      camera: null
    } : mediaSelection),
    actions: { handleMediaSelectionChange: updateMediaSelection }
  }
}
