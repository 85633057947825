import { useEffect, useState } from 'react'
import useApi from '../../../../../../../API/useApi'
import { IPostConnectivityLog } from '../../../../../../../Models/apiEntities'
import { useConnectivityContext } from '../../../../../../../Providers/ConnectivityProvider'
import { useRoom } from '../../../../../../../Providers/RoomProvider'
import { RTCConnectivityStats } from '../../../../../../../Components/NetworkConnectivity/hooks/useGetRTCConnectivity'

const useUploadConnectivityInfo = (currentProfile, streamId) => {
  const [, setIterations] = useState(0)
  const { currentRTCConnectivityStats } = useConnectivityContext()
  const { ConnectivityLogs } = useApi()
  const { id: roomId } = useRoom()
  const [isInitialUpload, setIsInitialUpload] = useState(true)

  const isValidLogData = (stats?: RTCConnectivityStats) => {
    return stats
      ? stats.uploadByteRate > 0 || stats.downloadByteRate > 0 || stats.rtt > 0 || stats.jitter > 0
      : false
  }

  useEffect(() => {
    setIterations((i) => {
      if (!isValidLogData(currentRTCConnectivityStats)) {
        return 0
      }
      const payload: IPostConnectivityLog = {
        uploadBitRate: Math.round(currentRTCConnectivityStats!.uploadByteRate),
        downloadBitRate: Math.round(currentRTCConnectivityStats!.downloadByteRate),
        rtt: Math.round(currentRTCConnectivityStats!.rtt),
        createdAt: new Date(),
        id: currentProfile.id,
        streamId: streamId,
        jitter: Math.round(currentRTCConnectivityStats!.jitter),
        platform: 'WebApp',
        platformVersion: currentRTCConnectivityStats!.platformVersion ?? '',
        operatingSystem: currentRTCConnectivityStats!.operatingSystem ?? '',
      }
      if (isInitialUpload || (i > 0 && i % 20 === 0)) {
        ConnectivityLogs.postLog(roomId, payload)
        setIsInitialUpload(false)
        return 0
      }
      return i + 1
    })
  }, [currentRTCConnectivityStats, ConnectivityLogs, currentProfile.id, roomId, streamId])
}

export default useUploadConnectivityInfo
