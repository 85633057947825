import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import ChatContainer from '../../../../../../../Components/Chat/ChatContainer'
import ChatIcon from '@material-ui/icons/Chat'
import NoteIcon from '@material-ui/icons/Note'
import NotesContainer from '../../../../../../../Components/Notes/NotesContainer'
import Tabs from '../../../../../../../Components/Tabs/Tabs'
import palette from '../../../../../../../Common/theme/colors/palette'
import { ChatDecisionInfo, NoteDecisionInfo } from '../hooks/useLiveroomPermissions'
import DisabledFeature from '../../../../../../../Components/DisabledFeature/DisabledFeature'

interface LiveRoomChatProps {
  room: {
    id: string
    hash: string
  }
  currentProfile: {
    id: string
    displayName: string
  }
  isVisible: boolean
  handleChatNotification: (notify: boolean) => void
  chatPermissionInfo: ChatDecisionInfo
  notePermissionInfo: NoteDecisionInfo
}

/**
 * Tabbed component that contains chat, notes (not implemented yet) and in the future shared files.
 */
const RightDrawer: React.FC<LiveRoomChatProps> = ({
  room,
  currentProfile,
  isVisible,
  handleChatNotification,
  chatPermissionInfo,
  notePermissionInfo
}) => {
  const isChatsEnabled = useMemo(
    () => chatPermissionInfo.isAllowed && chatPermissionInfo.isAvailable,
    [chatPermissionInfo]
  )
  const isNotesEnabled = useMemo(
    () => notePermissionInfo.isAllowed && notePermissionInfo.isAvailable,
    [notePermissionInfo]
  )

  const tabs = useMemo(
    () => [
      {
        label: 'Chat',
        component: isChatsEnabled ? (
          <ChatContainer
            room={room}
            currentProfile={currentProfile}
            isVisible={isVisible}
            handleChatNotification={handleChatNotification}
            chatPermissionInfo={chatPermissionInfo}
          />
        ) : (
          <DisabledFeature style={{ marginTop: '2rem' }} />
        ),
        icon: <ChatIcon />
      },
      {
        label: 'Notes',
        component: isNotesEnabled ? (
          <NotesContainer
            room={room}
            currentProfile={currentProfile}
            isVisible={isVisible}
            handleNotesNotification={handleChatNotification}
            notePermissionInfo={notePermissionInfo}
          />
        ) : (
          <DisabledFeature style={{ marginTop: '2rem' }} />
        ),
        icon: <NoteIcon />
      }
    ],
    [room, currentProfile, isVisible, handleChatNotification, notePermissionInfo, chatPermissionInfo, isChatsEnabled, isNotesEnabled]
  )

  return (
    <Box bgcolor={palette.background.primary} width="100%" height="100%">
      <Tabs tabs={tabs} padding={0} />
    </Box>
  )
}

export default React.memo(RightDrawer)
