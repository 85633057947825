/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateSessionByIdInput = {
    clientMutationId?: string | null;
    sessionPatch: SessionPatch;
    id: unknown;
};
export type SessionPatch = {
    id?: unknown | null;
    roomId?: unknown | null;
    displayName?: string | null;
    createdAt?: unknown | null;
    startedAt?: unknown | null;
    stoppedAt?: unknown | null;
    recordingId?: string | null;
    deletedAt?: unknown | null;
    isPublic?: boolean | null;
    profileId?: unknown | null;
};
export type UpdateSessionMutationVariables = {
    input: UpdateSessionByIdInput;
    roomId: unknown;
};
export type UpdateSessionMutationResponse = {
    readonly updateSessionById: {
        readonly clientMutationId: string | null;
        readonly query: {
            readonly liveroomById: {
                readonly sessionsByRoomId: {
                    readonly nodes: ReadonlyArray<{
                        readonly id: unknown;
                        readonly displayName: string;
                        readonly createdAt: unknown;
                        readonly stoppedAt: unknown | null;
                        readonly deletedAt: unknown | null;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type UpdateSessionMutation = {
    readonly response: UpdateSessionMutationResponse;
    readonly variables: UpdateSessionMutationVariables;
};



/*
mutation UpdateSessionMutation(
  $input: UpdateSessionByIdInput!
  $roomId: UUID!
) {
  updateSessionById(input: $input) {
    clientMutationId
    query {
      liveroomById(id: $roomId) {
        sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {
          nodes {
            id
            displayName
            createdAt
            stoppedAt
            deletedAt
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSessionPayload",
    "kind": "LinkedField",
    "name": "updateSessionById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "roomId"
              }
            ],
            "concreteType": "Liveroom",
            "kind": "LinkedField",
            "name": "liveroomById",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "CREATED_AT_DESC"
                    ]
                  }
                ],
                "concreteType": "SessionsConnection",
                "kind": "LinkedField",
                "name": "sessionsByRoomId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Session",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stoppedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "sessionsByRoomId(orderBy:[\"CREATED_AT_DESC\"])"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSessionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSessionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a079f7bd482e17cf8ed32969a3505415",
    "id": null,
    "metadata": {},
    "name": "UpdateSessionMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSessionMutation(\n  $input: UpdateSessionByIdInput!\n  $roomId: UUID!\n) {\n  updateSessionById(input: $input) {\n    clientMutationId\n    query {\n      liveroomById(id: $roomId) {\n        sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {\n          nodes {\n            id\n            displayName\n            createdAt\n            stoppedAt\n            deletedAt\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c64f8e474243b780a99ce39b017b8756';
export default node;
