/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, EffectCallback, DependencyList } from 'react'

interface UseEffectWithPredicateParams {
  effect: EffectCallback
  predicate: () => boolean
}

/**
 * Runs effect only when predicate evaluated to true
 * @param effect
 * @param predicate
 * @param dependencies
 */
export const useEffectWithPredicate = (
  { effect, predicate }: UseEffectWithPredicateParams,
  dependencies: DependencyList
) => {
  useEffect(() => {
    if (!predicate()) {
      return
    }

    return effect()
  }, dependencies)
}
