import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
import { Box, Grid, Typography } from '@material-ui/core'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { tabsValues } from '../../AccountView/AccountView'

/**
 * Evercast verify deactivation email error
 * @constructor
 */

const TwoFactorDeactivationEmailError: React.FC = () => {
  return (
    <>
      <Grid container className="two-factor-success-view">
        <Grid item xs={12}>
          <Box>
            <Box>
              <Typography gutterBottom variant="h4">
                Token Expired!
              </Typography>
            </Box>
            <Box pt={1} pb={3}>
              <Typography
                gutterBottom
                variant="body2"
                component="p"
                align="left"
                color="textSecondary"
              >
                To get a new token, click the multi-factor authentication link on the account
                settings page.
              </Typography>
            </Box>
            <Box display="flex">
              <Box mr={3}>
                <Link to={routes.dashboard.path} rel="noopener noreferrer">
                  <ProgressButton
                    color="primary"
                    variant="outlined"
                    isLoading={false}
                    size="medium"
                  >
                    Cancel
                  </ProgressButton>
                </Link>
              </Box>
              <Box>
                <Link to={routes.account.path.replace(':tab', tabsValues.settings)}>
                  <ProgressButton
                    color="primary"
                    isLoading={false}
                    size="medium"
                    className="continue"
                    autoFocus={true}
                  >
                    Back to settings
                  </ProgressButton>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(TwoFactorDeactivationEmailError)
