import React, { useCallback, useMemo } from 'react'
import { Box, MenuList } from '@material-ui/core'

import MenuItemWithSubMenu from '../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import ShapesIcon from '../../../assets/ShapesIcon'
import {
  useDrawCanvasToolsContext,
  DRAW_TOOLS
} from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'
import { DrawToolIcon } from '../../../DrawToolIcon/DrawToolIcon'
import SizeSlider from '../../../SizeSlider/SizeSlider'

const shapes = [
  {
    label: 'Line',
    shape: DRAW_TOOLS.LINE
  },
  {
    label: 'Arrow',
    shape: DRAW_TOOLS.ARROW
  },
  {
    label: 'Dash',
    shape: DRAW_TOOLS.DASH
  },
  {
    label: 'Circle',
    shape: DRAW_TOOLS.ELLIPSE
  },
  {
    label: 'Square',
    shape: DRAW_TOOLS.RECTANGLE
  }
]

const Shapes: React.FC = () => {
  const {
    drawPreferences: { drawTool, drawThickness },
    setDrawThickness,
    setDrawTool
  } = useDrawCanvasToolsContext()

  const onClickDrawToolHandler = useCallback(
    (shape) => () => {
      setDrawTool(shape)
    },
    [setDrawTool]
  )

  const onChangeCommittedHandler = useCallback(
    (event, value) => {
      setDrawThickness(value)
    },
    [setDrawThickness]
  )

  const selected = useMemo(() => shapes.some(({ shape }) => shape === drawTool), [drawTool])

  const handleMenuClick = useCallback(() => {
    if (selected) {
      return
    }
    setDrawTool(shapes[0].shape)
  }, [selected, setDrawTool])

  return (
    <MenuItemWithSubMenu
      icon={<ShapesIcon />}
      label="Shapes"
      selected={selected}
      isOpen={selected}
      onClick={handleMenuClick}
    >
      <Box pt={1.5} pb={3} pr={1.5} pl={2.5}>
        <SizeSlider
          key={drawThickness}
          onChangeCommitted={onChangeCommittedHandler}
          initialValue={drawThickness}
        />
      </Box>

      <MenuList>
        {shapes.map(({ label, shape }) => (
          <MenuItemWithSubMenu
            key={label}
            icon={<DrawToolIcon drawTool={shape} />}
            label={label}
            dense={true}
            selected={drawTool === shape}
            onClick={onClickDrawToolHandler(shape)}
          />
        ))}
      </MenuList>
    </MenuItemWithSubMenu>
  )
}

export default React.memo(Shapes)
