import React from 'react'

import { ReactComponent as AudioEnabled } from '../../../../Static/images/audio.svg'
import { ReactComponent as AudioDisabled } from '../../../../Static/images/audio_disabled.svg'

import OutlinedToggleIconButton from '../../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import ToggleIconButton, {
  ToggleIconButtonProps,
} from '../../../Buttons/ToggleIconButton/ToggleIconButton'

interface AudioToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
}

const AudioToggleButton: React.FC<AudioToggleButtonProps> = ({
  noOutline = false,
  inactiveIcon = AudioDisabled,
  ...otherProps
}) => {
  const Component = noOutline ? ToggleIconButton : OutlinedToggleIconButton

  return (
    <Component
      icon={AudioEnabled}
      inactiveIcon={AudioDisabled}
      activeTooltipTitle="Turn off audio"
      inactiveTooltipTitle="Turn on audio"
      label="Audio"
      {...otherProps}
    />
  )
}

export default React.memo(AudioToggleButton)
