import React, { useEffect, useState } from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { usePasswordStrength } from '../../Common/hooks/usePasswordStrength'
import './PasswordStrength.scss'

interface PasswordStrengthProgressProps {
  password: string
  onStrengthChange: (strength: number) => void
}

/**
 * Component for displaying password strength
 *
 * @param password - plain password to evaluate
 * @param onStrengthChange - callback to notify when password strength changes
 */
const PasswordStrengthProgress: React.FC<PasswordStrengthProgressProps> = ({
  password,
  onStrengthChange
}) => {
  const passwordStrength = usePasswordStrength(password)

  const [color, setColor] = useState<string>('password-strength-weak')

  useEffect(() => {
    onStrengthChange(passwordStrength)
    switch (passwordStrength) {
      case 0 || 1:
        setColor('password-strength-weak')
        break;
      case 2:
        setColor('password-strength-moderate')
        break;
      case 3:
        setColor('password-strength-strong')
        break;
      case 4:
        setColor('password-strength-strongest')
        break;
      default:
        setColor('password-strength-weak')
    }
  }, [passwordStrength, onStrengthChange])

  return (
    <Box display="flex" alignItems="center">
      <Box>Strength:</Box>
      <Box paddingLeft={3} width="100%">
        <Box className={color}>
          <LinearProgress variant="determinate" color="secondary" value={passwordStrength * 25} />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(PasswordStrengthProgress)
