import { useState, useCallback } from 'react'
import {
  JanusSignallingConnection,
  SignallingConnectionState
} from '../../../../../../../Common/janus/signalling/JanusSignallingConnection'
import { useEffectWithPredicate } from '../../../../../../../Common/hooks/useEffectWithPredicate'
import { ScreenshareClient } from '../../../../../../../Common/janus/clients/sharing/ScreenshareClient'
import VideoQualityOptions, {
  VideoQuality
} from '../../../../../../../Common/video/videoQualityOptions'

interface useScreenshareClientParams {
  signallingConnectionState: SignallingConnectionState
  janusSignallingConnection: JanusSignallingConnection
  room
  currentProfile
}

export const SCREENSHARE_FRAME_RATE = 30

export const useScreenshareClient = ({
  signallingConnectionState,
  janusSignallingConnection,
  room,
  currentProfile
}: useScreenshareClientParams) => {
  const [videoQuality, setVideoQuality] = useState(VideoQualityOptions.getDefault())
  const [isScreenShareStarted, setIsScreenShareStarted] = useState(false)
  const [shareStream, setShareStream] = useState<MediaStream | null>(null)

  const startScreenShare = useCallback(
    async (videoQuality: VideoQuality) => {
      setVideoQuality(videoQuality)
      await setScreenShareMediaStream(videoQuality)
      setIsScreenShareStarted(true)
    },
    [setIsScreenShareStarted, setVideoQuality]
  )


  const setScreenShareMediaStream = async (videoQuality: VideoQuality) => {
    let constraints = {
        video : {
          frameRate: SCREENSHARE_FRAME_RATE,
          height: videoQuality.height.ideal,
          width: videoQuality.width.ideal,
        } as MediaTrackConstraintSet
    }
    // @ts-ignore
    const mediaStream = await navigator.mediaDevices.getDisplayMedia(constraints)
    setShareStream(mediaStream)
  }

  const stopScreenShare = useCallback(() => {
    setVideoQuality(VideoQualityOptions.getDefault())
    setIsScreenShareStarted(false)
  }, [setIsScreenShareStarted, setVideoQuality])

  useEffectWithPredicate(
    {
      predicate: () =>
        isScreenShareStarted && signallingConnectionState === SignallingConnectionState.Connected,
      effect: () => {
        const screenshareClient = new ScreenshareClient({
          roomId: room.id,
          roomHash: room.hash,
          displayName: currentProfile.displayName,
          userId: currentProfile.id,
          janusSignallingConnection
        })
        screenshareClient.onJoined().subscribe((publisher) => {
          publisher.publish({ video: videoQuality, stream: shareStream })
        })

        screenshareClient.onPublishError().subscribe(() => {
          screenshareClient.leave()
          stopScreenShare()
        })

        screenshareClient.onLocalStreamEnded().subscribe(() => {
          stopScreenShare()
        })
        return () => screenshareClient.leave()
      }
    },
    [
      signallingConnectionState,
      janusSignallingConnection,
      isScreenShareStarted,
      videoQuality,
      currentProfile,
      room.id,
      room.hash,
      stopScreenShare
    ]
  )

  return {
    isScreenShareStarted,
    startScreenShare,
    stopScreenShare
  }
}
