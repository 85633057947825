import { useState, useCallback, useEffect, useMemo } from 'react'
import { RecordingInfo } from '../../../../../../../Common/recording/recordingInfo'

interface useRecordingNotificationDialogParams {
  recordingInfo: RecordingInfo
  currentProfile: any
}

export const useRecordingNotificationDialog = ({
  recordingInfo,
  currentProfile
}: useRecordingNotificationDialogParams) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const [isHidden, setHidden] = useState(false)
  const itemId = `recordingAccepted${currentProfile.id}`

  useEffect(() => {
    if (!recordingInfo.isRecording) return
    const isDisabled = localStorage.getItem(itemId) === String(true)

    setIsOpen(!isDisabled)
    setTimeout(() => setIsOpen(false), 3000)
  }, [recordingInfo, itemId])

  const toggleHide = useCallback(() => {
    setHidden(!isHidden)
    localStorage.setItem(itemId, String(!isHidden))
  }, [setHidden, itemId, isHidden])

  const recordingNotificationDialog = useMemo(
    () => ({
      isOpen,
      open,
      close,
      isHidden,
      toggleHide
    }),
    [isOpen, open, close, isHidden, toggleHide]
  )

  return {
    recordingNotificationDialog
  }
}
