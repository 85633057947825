import { useEffect, useRef } from 'react'

export const useIsMounted = () => {
  const isMountedRef = useRef(true)

  // runs when component using this hook unmounts
  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMountedRef
}
