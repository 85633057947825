import React from 'react'
import AcceptInviteErrorView from './AcceptInviteErrorView/AcceptInviteErrorView'
import AcceptInviteContainer from '../../../../Components/AcceptInvite/AcceptInviteContainer'

/**
 * Unauthenticated accept invite view
 */
const UnauthenticatedAcceptInviteView: React.FC = () => {
  return (
    <AcceptInviteContainer errorComponent={(error) => <AcceptInviteErrorView error={error} />} />
  )
}

export default React.memo(UnauthenticatedAcceptInviteView)
