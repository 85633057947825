import { useMutation } from '../../../../../../../Common/hooks/useMutation'
import StopCurrentRecordingMutation from '../../../../../../../GraphQL/mutations/StopCurrentRecordingMutation'

interface StopCurrentSessionInput {
  input: {
    _roomId: string
  }
}

export function useStopCurrentSessionMutation() {
  return useMutation<StopCurrentSessionInput, any>(StopCurrentRecordingMutation)
}
