import { useRef, useEffect } from 'react'

/**
 * Hook for getting the previous value - this is useful when you need to
 * perform an action only if the previous value is different from the current
 * obs. since this is using ref, it will not trigger and re-render such
 * as an setState.
 *
 * @param value
 * @returns previous value
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
