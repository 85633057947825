import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import UnauthenticatedRoomViewQuery from '../../../../GraphQL/queries/UnauthenticatedRoomViewQuery'
import RoomLogin from './RoomLogin/RoomLogin'
import RoomError from './RoomError/RoomError'
import QueryRenderer from '../../../../GraphQL/QueryRenderer'
import { ShowProgress } from '../../../../Providers/ProgressProvider'
import NotFoundRedirect from '../../NotFoundView/NotFoundRedirect'

export interface RoomViewProps {
  onLogin: () => void
}

/**
 * Room entry point for a case when user is not logged in.
 * After user logs in, main application view will rerender and authenticated room route will be displayed.
 */
const RoomView: React.FC<RoomViewProps> = ({ onLogin }) => {
  const { hash } = useParams()

  return (
    <QueryRenderer
      query={UnauthenticatedRoomViewQuery}
      variables={{ hash }}
      renderLoading={useCallback(
        () => (
          <ShowProgress />
        ),
        []
      )}
      renderError={useCallback((error, retry) => {
        return <RoomError error={error} retry={retry} />
      }, [])}
      renderResult={useCallback(
        (props) => {
          if (props.isPublic === null) {
            return <NotFoundRedirect />
          }
          return <RoomLogin onLogin={onLogin} isPublic={props.isPublic} hash={hash} />
        },
        [onLogin, hash]
      )}
    />
  )
}

export default React.memo(RoomView)
