import { useEffect, useState } from 'react'
import { logger } from '../../../../../../Common/log/Log'
import useApi from '../../../../../../API/useApi'


export const useQrImage = (): string => {
  const [barcodeUri, setBarcodeUri] = useState('')
  const { AuthAccount } = useApi()

  const generateQrUri = () => {
    try {
      AuthAccount.generateOtp().then(resp =>
        setBarcodeUri(resp.barcode_uri)
      )
    } catch (e) {
      logger.error(e)
    }
  }

  useEffect(() => {
    generateQrUri()
  }, [])

  return barcodeUri
}
