import React, { useCallback } from 'react'
import StreamPlayer from '../../../RoomView/Room/StreamPlayer/StreamPlayer'
import Thumbnail from '../../../../../../Components/Thumbnail/Thumbnail'
import { LiveroomParticipant } from '../../../RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'
import { useDrawCanvasDisplayContext } from '../../../../../../Providers/DrawCanvasDisplayProvider'
import { ThumbnailNodeType } from '../../../../../../Common/drawtools/types'

interface ParticipantThumbnailProps {
  participant: LiveroomParticipant
  isAudioActive: boolean
  onParticipantClicked: (participant: LiveroomParticipant) => void
}

const ParticipantThumbnail: React.FC<ParticipantThumbnailProps> = ({
  participant,
  isAudioActive,
  onParticipantClicked
}) => {
  const { setThumbnailNode } = useDrawCanvasDisplayContext()

  const setVideoNode = useCallback(
    (ref) => setThumbnailNode(participant.id, ref, ThumbnailNodeType.Video),
    [participant, setThumbnailNode]
  )

  const setCanvasRef = useCallback(
    (ref) => setThumbnailNode(participant.id, ref, ThumbnailNodeType.Canvas),
    [participant, setThumbnailNode]
  )

  const onParticipantClickedHandler = useCallback(() => onParticipantClicked(participant), [
    participant,
    onParticipantClicked
  ])

  return (
    <Thumbnail
      name={participant.displayName}
      id={participant.id}
      isNameAlwaysVisible
      onClick={onParticipantClickedHandler}
      canvas={<canvas key={participant.id} ref={setCanvasRef} />}
    >
      <StreamPlayer
        stream={participant.stream}
        isMuted={!isAudioActive}
        setVideoNode={setVideoNode}
        streamId={participant.id}
      />
    </Thumbnail>
  )
}

export default ParticipantThumbnail
