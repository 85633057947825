import React, { useCallback, useState } from 'react'

/**
 * Manage logic for the Popover
 */
export const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback((event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(
    (e?: any) => {
      const event = e as React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent
      event?.stopPropagation()
      setAnchorEl(null)
    },
    [setAnchorEl]
  )

  const isOpen = Boolean(anchorEl)

  return {
    isOpen,
    anchorEl,
    handleClick,
    handleClose
  }
}
