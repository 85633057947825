import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { useFormValidation } from '../../../../../../Common/utils/formUtils'

export interface UpdateProfileForm {
  displayName: string
}

export const useUpdateProfileForm = (onSubmit: (formData: UpdateProfileForm) => void) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const inputs = useFormValidation(formMethods, {
    displayName: {
      required: 'Please enter your display name'
    }
  })

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((formData: UpdateProfileForm) => {
        onSubmit(formData)
      }),
    [onSubmit, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs
  }
}
