import React, { useState } from 'react'
import { Box } from '@material-ui/core'

import { EvercastAudio } from '../../../../../../Common/audio/EvercastAudio'
import { useIsMounted } from '../../../../../../Common/hooks/useIsMounted'
import { AudioStream, AvailableStream, StreamState } from '../../../../../../Common/media/stream'
import { useEffectWithPredicate } from '../../../../../../Common/hooks/useEffectWithPredicate'

const MAX_VOLUME_THRESHOLD = 0.6 // volume value is in [0, 1] range

interface MicrophoneTesterProps {
  stream: AudioStream
  label: React.ReactNode
}

/**
 * Visualize current the volume of provided media stream audio track
 * @param stream
 * @constructor
 */
const MicrophoneTester: React.FC<MicrophoneTesterProps> = ({ stream, label }) => {
  const [volume, setVolume] = useState(0)
  const isMounted = useIsMounted()

  useEffectWithPredicate(
    {
      predicate: () => stream.state === StreamState.available,
      effect: () => {
        const evercastAudio = new EvercastAudio()
        evercastAudio.detect((stream as AvailableStream).current, null, (volumeMark) => {
          const volumePercentage = Math.round((volumeMark * 100) / MAX_VOLUME_THRESHOLD)
          if (isMounted.current) {
            setVolume(volumePercentage)
          }
        })
        return () => {
          evercastAudio.cleanup()
        }
      }
    },
    [stream, isMounted]
  )

  useEffectWithPredicate(
    {
      predicate: () => stream.state !== StreamState.available && volume !== 0,
      effect: () => setVolume(0)
    },
    [stream, isMounted, volume]
  )

  return (
    <Box display="flex" flexDirection="column">
      {label}
      <Box width={'100%'} marginTop={1} flexGrow={1} bgcolor={'rgba(255, 255, 255, 0.16)'}>
        <Box bgcolor={'#18E074'} style={{ width: `${volume}%`, height: 10 }} />
      </Box>
    </Box>
  )
}

export default React.memo(MicrophoneTester)
