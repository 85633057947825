import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.6 5H10.8C10.6939 5 10.5922 5.04214 10.5172 5.11716C10.4421 5.19217 10.4 5.29391 10.4 5.4V7.4C10.4 7.50609 10.4421 7.60783 10.5172 7.68284C10.5922 7.75786 10.6939 7.8 10.8 7.8H11.2C11.3061 7.8 11.4078 7.75786 11.4828 7.68284C11.5579 7.60783 11.6 7.50609 11.6 7.4V6.6H14.4V15H13.2C13.0939 15 12.9922 15.0421 12.9172 15.1172C12.8421 15.1922 12.8 15.2939 12.8 15.4V15.8C12.8 15.9061 12.8421 16.0078 12.9172 16.0828C12.9922 16.1579 13.0939 16.2 13.2 16.2H17.2C17.3061 16.2 17.4078 16.1579 17.4828 16.0828C17.5579 16.0078 17.6 15.9061 17.6 15.8V15.4C17.6 15.2939 17.5579 15.1922 17.4828 15.1172C17.4078 15.0421 17.3061 15 17.2 15H16V6.6H18.8V7.4C18.8 7.50609 18.8421 7.60783 18.9172 7.68284C18.9922 7.75786 19.0939 7.8 19.2 7.8H19.6C19.7061 7.8 19.8078 7.75786 19.8828 7.68284C19.9579 7.60783 20 7.50609 20 7.4V5.4C20 5.29391 19.9579 5.19217 19.8828 5.11716C19.8078 5.04214 19.7061 5 19.6 5ZM11.6 9.8H4.4C4.29391 9.8 4.19217 9.84214 4.11716 9.91716C4.04214 9.99217 4 10.0939 4 10.2V11.8C4 11.9061 4.04214 12.0078 4.11716 12.0828C4.19217 12.1579 4.29391 12.2 4.4 12.2H4.8C4.90609 12.2 5.00783 12.1579 5.08284 12.0828C5.15786 12.0078 5.2 11.9061 5.2 11.8V11H7.2V15H6.4C6.29391 15 6.19217 15.0421 6.11716 15.1172C6.04214 15.1922 6 15.2939 6 15.4V15.8C6 15.9061 6.04214 16.0078 6.11716 16.0828C6.19217 16.1579 6.29391 16.2 6.4 16.2H9.6C9.70609 16.2 9.80783 16.1579 9.88284 16.0828C9.95786 16.0078 10 15.9061 10 15.8V15.4C10 15.2939 9.95786 15.1922 9.88284 15.1172C9.80783 15.0421 9.70609 15 9.6 15H8.8V11H10.8V11.8C10.8 11.9061 10.8421 12.0078 10.9172 12.0828C10.9922 12.1579 11.0939 12.2 11.2 12.2H11.6C11.7061 12.2 11.8078 12.1579 11.8828 12.0828C11.9579 12.0078 12 11.9061 12 11.8V10.2C12 10.0939 11.9579 9.99217 11.8828 9.91716C11.8078 9.84214 11.7061 9.8 11.6 9.8Z" />
  </SvgIcon>
)

export default Icon
