import React from 'react'
import { Cursor, Note } from '../../Models/apiEntities'
import Notes from './Notes'

import { createPaginationContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useNoteOrdered } from './hooks/useNoteOrdered'
import { useNoteSubscription } from './hooks/useNoteSubscription'
import { useNotesUpdateNotification } from './hooks/useNoteUpdateNotification'
import { NoteDecisionInfo } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomPermissions'

interface ChatContainerProps {
  currentProfile: {
    id: string
    displayName: string
  }
  room: {
    id: string
    hash: string
    notes?: {
      edges: Cursor<Note>[]
    }
  }
  relay?: any
  isVisible: boolean
  handleNotesNotification: (notify: boolean) => void
  notePermissionInfo: NoteDecisionInfo
}

const notesConnectionName = 'NotesContainer_notes'

/**
 * Component responsible to wrap chat component and inject relay data.
 * @param currentProfile - object that holds the current profile information.
 * @param room - object that holds the room`s information.
 * @param relay - object containing pagination methods like hasMore, loadMore from relay. Injected by createPaginationContainer
 * @param isVisible - flag if the chat is visible or not.
 * @param handleChatNotification - called to notify parent if new messages arrive when chat is closed.
 * @param notePermissionInfo
 * @constructor
 */
const NotesContainer: React.FC<ChatContainerProps> = ({
  currentProfile,
  room,
  relay,
  isVisible,
  handleNotesNotification,
  notePermissionInfo
}) => {
  const { orderedMessages, lastMessage } = useNoteOrdered({ cursor: room.notes?.edges })
  useNotesUpdateNotification({ lastMessage, isVisible, handleNotesNotification })
  useNoteSubscription(notesConnectionName, room.id)
  return (
    <Notes
      room={room}
      currentProfileId={currentProfile.id}
      messages={orderedMessages || []}
      notesConnectionName={notesConnectionName}
      relay={relay!}
      isVisible={isVisible}
      notePermissionInfo={notePermissionInfo}
    />
  )
}

const connectionConfig = {
  getConnectionFromProps(props: ChatContainerProps) {
    return props.room && props.room.notes
  },
  // This is also the default implementation of `getFragmentVariables` if it isn't provided.
  getFragmentVariables(prevVars, totalCount) {
    return {
      ...prevVars,
      count: totalCount
    }
  },
  getVariables(props, { count, cursor }, fragmentVariables) {
    return {
      count,
      cursor,
      orderBy: fragmentVariables.orderBy,
      // hash isn't specified as an @argument for the fragment, but it should be a variable available for the fragment under the query root.
      hash: fragmentVariables.hash
    }
  },
  query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query NotesContainer_NotesPaginationQuery(
      $count: Int!
      $cursor: Cursor
      $orderBy: [MessagesOrderBy]!
      $hash: UUID!
    ) {
      room: liveroomByHash(hash: $hash) {
        ...NotesContainer_room @arguments(count: $count, cursor: $cursor, orderBy: $orderBy)
      }
    }
  `
}

export default createPaginationContainer(
  NotesContainer,
  {
    currentProfile: graphql`
      fragment NotesContainer_currentProfile on Profile {
        id
      }
    `,
    room: graphql`
      fragment NotesContainer_room on Liveroom
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 10 }
          cursor: { type: "Cursor" }
          orderBy: { type: "[MessagesOrderBy]", defaultValue: [CREATED_AT_DESC] }
        ) {
        displayName
        id
        hash
        creatorId

        notes: notesByRoomId(first: $count, after: $cursor, orderBy: [CREATED_AT_DESC])
          @connection(key: "NotesContainer_notes", filters: []) {
          edges {
            cursor
            node {
              ...NoteItem_note
              id
              content
              createdAt
              updatedAt
              roomId
              sessionId
              profileId
              nodeId
              deletedAt
              profileDisplayName
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `
  },
  connectionConfig
)
