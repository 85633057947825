import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { ErrorIcon } from '../Icons/Error'

const UnexpectedError: React.FC = () => {
  return (
    <Box width="100%" height="100%" pt={8} pb={16} pr={4} pl={4} margin={'auto'} display={'flex'}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <Box fontSize={80}>{ErrorIcon}</Box>
        </Grid>
        <Grid item>
          <Typography variant={'h5'} align={'center'}>
            It appears your devices are malfunctioning
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'} align={'center'}>
            Need help?&nbsp;
            <a
              href={`${process.env.REACT_APP_SUPPORT_URL}/hc/en-us/articles/4406287062931-Troubleshooting-Camera-and-or-microphone-won-t-work`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(UnexpectedError)
