import graphql from 'babel-plugin-relay/macro'

const GetSSODetailsQuery = graphql`
  query GetSSODetailsQuery($input: GetSSODetailsInput!) {
    getSSODetails(input: $input) {
      userPoolClientId
      userPoolId
      userPoolDomain
      userPoolRegion
    }
  }
`

export default GetSSODetailsQuery
