import { useCallback } from 'react'
import useApi from '../../../../API/useApi'
import { useEnvironment } from '../../../../Providers/EnvironmentProvider'
import { routes } from '../../../../Views/MainView/AuthenticatedView/routes'
/**
 * Hook for getting a public room url
 *
 * @param hash
 */
export const useSpecialInvite = (roomId, hash, setUrl, setSpecialInviteCode) => {
  const environment = useEnvironment()
  const { SpecialInvite } = useApi()

  const generateInvite = useCallback(async () => {
    const specialInviteCode = await SpecialInvite.generate(roomId)
    setUrl(`${environment.frontendUrl}${routes.acceptSpecialInvite.path.replace(':inviteToken', specialInviteCode)}?roomId=${roomId}`)
    setSpecialInviteCode(specialInviteCode)
  }, [SpecialInvite, hash, roomId, environment])

  

  return generateInvite
}
