export const toRGBA = ({ red, blue, green, alpha }) => `rgba(${red}, ${green}, ${blue}, ${alpha})`

// expects object with properties 'red', 'green', 'blue'
export const toRGBHex = (colorChannels) => {
  const order = ['red', 'green', 'blue']
  return order.reduce((str, channelName) => {
    const channelHex = colorChannels[channelName].toString(16)
    return `${str}${channelHex.length === 2 ? channelHex : `0${channelHex}`}`
  }, '#')
}

// expects hex string of 7 symbols with leading '#' (e.g. '#ffff00')
export const fromRGBHex = (hex) => ({
  red: parseInt(hex.substr(1, 2), 16),
  green: parseInt(hex.substr(3, 2), 16),
  blue: parseInt(hex.substr(5, 2), 16)
})
