import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
import { Box, Grid, Typography } from '@material-ui/core'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'

/**
 * Evercast verify deactivation email success view
 * @constructor
 */

const TwoFactorDeactivationSuccess: React.FC = () => {
  return (
    <Grid container className="two-factor-success-view">
      <Grid item xs={12}>
        <Box>
          <Box>
            <Typography gutterBottom variant="h4">
              Success!
            </Typography>
          </Box>
          <Box pt={1} pb={3}>
            <Typography
              gutterBottom
              variant="body2"
              component="p"
              align="left"
              color="textSecondary"
            >
              Multi-factor authentication is now deactivated.
            </Typography>
          </Box>
          <Box>
            <Link to={routes.dashboard.path}>
              <ProgressButton
                color="primary"
                isLoading={false}
                size="medium"
                className="continue"
                autoFocus={true}
              >
                Back to home
              </ProgressButton>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(TwoFactorDeactivationSuccess)
