import { useCallback, useMemo, useState } from 'react'
import { equals } from 'ramda'

import { ProfilePreferences } from '../../../../../../../../../Common/preferences/usePreferences'
import {
  BooleanConverter,
  PreferenceType
} from '../../../../../../../../../Common/preferences/Preferences'
import { logger } from '../../../../../../../../../Common/log/Log'
import { useSavePreferencesMutation } from '../../../../../../../../../GraphQL/hooks/useSavePreferencesMutation'
import { DRAW_TOOLS } from '../../../../../../../../../Providers/DrawCanvasToolsProvider'

class DrawPreferencesAlpha {
  static fromPreference(value: any): number {
    return Number(value / 100) || 1
  }

  static toPreference(value: number): string {
    return (value * 100).toFixed(0)
  }
}

export interface DrawPreferences {
  drawFontFamily: string
  drawTool: DRAW_TOOLS
  drawThickness: number
  drawFontSize: number
  drawStrokeColorRed: number
  drawStrokeColorGreen: number
  drawStrokeColorBlue: number
  drawStrokeColorAlpha: number
  drawFillColorRed: number
  drawFillColorGreen: number
  drawFillColorBlue: number
  drawFillColorAlpha: number
  drawModeEnabled: boolean
  drawFontIsBold: boolean
  drawFontIsItalic: boolean
  drawLineIsDashed: boolean
  drawLineHasEndArrow: boolean
}

export interface UseDrawPreferences {
  drawPreferences: DrawPreferences
  setDrawPreferences: (prefs: DrawPreferences) => void
}

export const useDrawPreferences = (profilePreferences: ProfilePreferences): UseDrawPreferences => {
  const [executeSavePreferencesMutation] = useSavePreferencesMutation()

  const drawModeEnabledPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_MODE_ENABLED),
    [profilePreferences]
  )

  const drawToolPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_TOOL),
    [profilePreferences]
  )

  const drawThicknessPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_THICKNESS),
    [profilePreferences]
  )

  const drawLineIsDashedPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_LINE_IS_DASHED),
    [profilePreferences]
  )

  const drawLineHasEndArrowPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_LINE_HAS_END_ARROW),
    [profilePreferences]
  )

  const drawFontFamilyPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FONT_FAMILY),
    [profilePreferences]
  )

  const drawFontSizePreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FONT_SIZE),
    [profilePreferences]
  )

  const drawFontIsBoldPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FONT_IS_BOLD),
    [profilePreferences]
  )

  const drawFontIsItalicPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FONT_IS_ITALIC),
    [profilePreferences]
  )

  const drawStrokeColorRedPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_STROKE_COLOR_RED),
    [profilePreferences]
  )

  const drawStrokeColorGreenPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_STROKE_COLOR_GREEN),
    [profilePreferences]
  )

  const drawStrokeColorBluePreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_STROKE_COLOR_BLUE),
    [profilePreferences]
  )

  const drawStrokeColorAlphaPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_STROKE_COLOR_ALPHA),
    [profilePreferences]
  )

  const drawFillColorRedPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FILL_COLOR_RED),
    [profilePreferences]
  )

  const drawFillColorGreenPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FILL_COLOR_GREEN),
    [profilePreferences]
  )

  const drawFillColorBluePreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FILL_COLOR_BLUE),
    [profilePreferences]
  )

  const drawFillColorAlphaPreference = useMemo(
    () => profilePreferences.getProfilePreference(PreferenceType.DRAW_FILL_COLOR_ALPHA),
    [profilePreferences]
  )

  const [drawPreferences, setDrawPreferences] = useState<DrawPreferences>({
    drawTool: drawToolPreference.getValue().toUpperCase(),
    drawFontFamily: drawFontFamilyPreference.getValue(),
    drawThickness: Number(drawThicknessPreference.getValue()),
    drawFontSize: Number(drawFontSizePreference.getValue()),
    drawStrokeColorRed: Number(drawStrokeColorRedPreference.getValue()),
    drawStrokeColorGreen: Number(drawStrokeColorGreenPreference.getValue()),
    drawStrokeColorBlue: Number(drawStrokeColorBluePreference.getValue()),
    drawStrokeColorAlpha: DrawPreferencesAlpha.fromPreference(
      drawStrokeColorAlphaPreference.getValue()
    ),
    drawFillColorRed: Number(drawFillColorRedPreference.getValue()),
    drawFillColorGreen: Number(drawFillColorGreenPreference.getValue()),
    drawFillColorBlue: Number(drawFillColorBluePreference.getValue()),
    drawFillColorAlpha: DrawPreferencesAlpha.fromPreference(
      drawFillColorAlphaPreference.getValue()
    ),
    drawModeEnabled: BooleanConverter(drawModeEnabledPreference.getValue()),
    drawFontIsBold: BooleanConverter(drawFontIsBoldPreference.getValue()),
    drawFontIsItalic: BooleanConverter(drawFontIsItalicPreference.getValue()),
    drawLineIsDashed: BooleanConverter(drawLineIsDashedPreference.getValue()),
    drawLineHasEndArrow: BooleanConverter(drawLineHasEndArrowPreference.getValue())
  })

  const updateDrawPreference = useCallback(
    async (newDrawPreferences: DrawPreferences) => {
      logger.log(`new preference: ${JSON.stringify(newDrawPreferences, null, 2)}`)

      try {
        const input = {
          input: {
            preferencesToSet: [
              {
                preferenceTypeId: drawModeEnabledPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawModeEnabled)
              },
              {
                preferenceTypeId: drawToolPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawTool)
              },
              {
                preferenceTypeId: drawThicknessPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawThickness)
              },
              {
                preferenceTypeId: drawLineIsDashedPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawLineIsDashed)
              },
              {
                preferenceTypeId: drawLineHasEndArrowPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawLineHasEndArrow)
              },
              {
                preferenceTypeId: drawFontFamilyPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFontFamily)
              },
              {
                preferenceTypeId: drawFontSizePreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFontSize)
              },
              {
                preferenceTypeId: drawFontIsBoldPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFontIsBold)
              },
              {
                preferenceTypeId: drawFontIsItalicPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFontIsItalic)
              },
              {
                preferenceTypeId: drawStrokeColorRedPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawStrokeColorRed)
              },
              {
                preferenceTypeId: drawStrokeColorGreenPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawStrokeColorGreen)
              },
              {
                preferenceTypeId: drawStrokeColorBluePreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawStrokeColorBlue)
              },
              {
                preferenceTypeId: drawStrokeColorAlphaPreference.preferenceTypeId,
                preferenceValue: DrawPreferencesAlpha.toPreference(
                  newDrawPreferences.drawStrokeColorAlpha
                )
              },
              {
                preferenceTypeId: drawFillColorRedPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFillColorRed)
              },
              {
                preferenceTypeId: drawFillColorGreenPreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFillColorGreen)
              },
              {
                preferenceTypeId: drawFillColorBluePreference.preferenceTypeId,
                preferenceValue: String(newDrawPreferences.drawFillColorBlue)
              },
              {
                preferenceTypeId: drawFillColorAlphaPreference.preferenceTypeId,
                preferenceValue: DrawPreferencesAlpha.toPreference(
                  newDrawPreferences.drawFillColorAlpha
                )
              }
            ]
          }
        }
        await executeSavePreferencesMutation(input)

        logger.log('draw preference updated on user profile')
      } catch (e) {
        logger.error(e)
      }
    },
    [
      drawFillColorAlphaPreference.preferenceTypeId,
      drawFillColorBluePreference.preferenceTypeId,
      drawFillColorGreenPreference.preferenceTypeId,
      drawFillColorRedPreference.preferenceTypeId,
      drawFontFamilyPreference.preferenceTypeId,
      drawFontIsBoldPreference.preferenceTypeId,
      drawFontIsItalicPreference.preferenceTypeId,
      drawFontSizePreference.preferenceTypeId,
      drawLineHasEndArrowPreference.preferenceTypeId,
      drawLineIsDashedPreference.preferenceTypeId,
      drawModeEnabledPreference.preferenceTypeId,
      drawStrokeColorAlphaPreference.preferenceTypeId,
      drawStrokeColorBluePreference.preferenceTypeId,
      drawStrokeColorGreenPreference.preferenceTypeId,
      drawStrokeColorRedPreference.preferenceTypeId,
      drawThicknessPreference.preferenceTypeId,
      drawToolPreference.preferenceTypeId,
      executeSavePreferencesMutation
    ]
  )

  const setDrawPreferencesHandler = (value) => {
    if (!equals(value, drawPreferences)) {
      setDrawPreferences(value)
      updateDrawPreference(value)
    }
  }

  return {
    drawPreferences,
    setDrawPreferences: setDrawPreferencesHandler
  }
}
