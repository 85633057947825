import { useEffect, useState } from 'react'
import { useQueryParams } from '../../../../../Common/hooks/useQueryParams'
import { SSOVerificationState } from './useSSOAction'
import { useCompanySsoRedirect } from '../../../../../Common/hooks/useCompanySsoRedirect'

const usePostSSOMessage = (state) => {
  const { email = '' } = useQueryParams()
  const { redirect } = useCompanySsoRedirect()

  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    switch (state) {
      case SSOVerificationState.companySSOError:
        setMessage(
          `The email address ${email} cannot be used to sign in through social SSO. Please choose company sso to sign in with this email address.`
        )
        setTimeout(() => {
          redirect(email)
        }, 5000)
        break
      case SSOVerificationState.appleIDPError:
        setMessage(
          `Your Apple account privacy settings don't allow us to validate your email address. Please use another sign in method from the sign in page.`
        )
        break
      case SSOVerificationState.notCompanySSO:
        setMessage(
          `The email address ${email} can't be used to sign in through SSO. Please choose another email address or another sign in method from the sign in page.`
        )
        break
      default:
        setMessage('We could not log you in at this time.')
        break
    }
  }, [state, email, redirect])

  return { message }
}

export default usePostSSOMessage
