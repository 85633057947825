import React from 'react'
import classnames from 'classnames'
import Button, { ButtonProps } from '@material-ui/core/Button'
import ProgressBar from '../../Components/ProgressBar/ProgressBar'

import './ProgressButton.scss'

/**
 * Progress button component props
 */
export interface ProgressButtonProps extends ButtonProps {
  className?: string
  isLoading: boolean
}

/**
 * Progress button to show some feedback when a process is loading
 * @constructor
 */
const ProgressButton: React.FC<ProgressButtonProps> = ({
  className,
  isLoading,
  children,
  size,
  ...buttonProps
}) => {
  return (
    <Button
      className={classnames('progress-button', className)}
      disabled={isLoading}
      variant="contained"
      size={size ? size : 'large'}
      {...buttonProps}
    >
      {isLoading ? <ProgressBar className="progress-button-loading-bar" /> : children}
    </Button>
  )
}

export default React.memo(ProgressButton)
