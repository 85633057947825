import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.0932 3C19.2414 3 19.3836 3.0602 19.4884 3.16737C19.5933 3.27453 19.6522 3.41988 19.6522 3.57143V6.42857C19.6522 6.58012 19.5933 6.72547 19.4884 6.83263C19.3836 6.9398 19.2414 7 19.0932 7H18.5342C18.3859 7 18.2437 6.9398 18.1389 6.83263C18.0341 6.72547 17.9752 6.58012 17.9752 6.42857V5.28571H12.9441V17.2857H14.6211C14.7694 17.2857 14.9116 17.3459 15.0164 17.4531C15.1212 17.5602 15.1801 17.7056 15.1801 17.8571V18.4286C15.1801 18.5801 15.1212 18.7255 15.0164 18.8326C14.9116 18.9398 14.7694 19 14.6211 19H9.03106C8.8828 19 8.74061 18.9398 8.63578 18.8326C8.53095 18.7255 8.47205 18.5801 8.47205 18.4286V17.8571C8.47205 17.7056 8.53095 17.5602 8.63578 17.4531C8.74061 17.3459 8.8828 17.2857 9.03106 17.2857H10.7081V5.28571H5.67702V6.42857C5.67702 6.58012 5.61812 6.72547 5.51329 6.83263C5.40846 6.9398 5.26627 7 5.11801 7H4.55901C4.41075 7 4.26856 6.9398 4.16373 6.83263C4.0589 6.72547 4 6.58012 4 6.42857V3.57143C4 3.41988 4.0589 3.27453 4.16373 3.16737C4.26856 3.0602 4.41075 3 4.55901 3H19.0932Z" />
  </SvgIcon>
)

export default Icon
