/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AttachDeviceLoginCodeMutationVariables = {
    code: string;
    roomId: unknown;
};
export type AttachDeviceLoginCodeMutationResponse = {
    readonly attachDeviceLoginCode: {
        readonly clientMutationId: string | null;
    } | null;
};
export type AttachDeviceLoginCodeMutation = {
    readonly response: AttachDeviceLoginCodeMutationResponse;
    readonly variables: AttachDeviceLoginCodeMutationVariables;
};



/*
mutation AttachDeviceLoginCodeMutation(
  $code: String!
  $roomId: UUID!
) {
  attachDeviceLoginCode(input: {_code: $code, _roomId: $roomId}) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_code",
            "variableName": "code"
          },
          {
            "kind": "Variable",
            "name": "_roomId",
            "variableName": "roomId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AttachDeviceLoginCodePayload",
    "kind": "LinkedField",
    "name": "attachDeviceLoginCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachDeviceLoginCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachDeviceLoginCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "26a376e8106499f8342873acc4f06918",
    "id": null,
    "metadata": {},
    "name": "AttachDeviceLoginCodeMutation",
    "operationKind": "mutation",
    "text": "mutation AttachDeviceLoginCodeMutation(\n  $code: String!\n  $roomId: UUID!\n) {\n  attachDeviceLoginCode(input: {_code: $code, _roomId: $roomId}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '1fae829cf72ef5fe50c6e0d1b9010199';
export default node;
