import { useForm } from 'react-hook-form'
import { useCallback, useMemo, useRef } from 'react'
import {
  joinInputErrors,
  useFormValidation,
  useCustomValidation,
  useTriggerValidation,
  emailAddressPattern
} from '../../../../../Common/utils/formUtils'
import { useEnvironment } from '../../../../../Providers/EnvironmentProvider'

export interface SignUpForm {
  display_name: string
  email: string
  password: string
  captcha?: string
}

export const useSignUpForm = (onSubmit: (formData: SignUpForm) => void) => {
  const formMethods = useForm()
  const { watch, handleSubmit } = formMethods
  const inputButtonRef = useRef<HTMLButtonElement | null>()

  const inputs = useFormValidation(formMethods, {
    display_name: {
      required: 'Please enter your display name'
    },
    email: {
      required: 'Please enter your email address',
      pattern: {
        value: emailAddressPattern,
        message: 'Please enter a valid email address'
      }
    },
    password: {
      required: 'Please enter your password'
    },
    confirmPassword: {
      required: 'Please confirm password',
      validate: (value) => value === watch('password') || 'Passwords don’t match'
    }
  })

  const [passwordStrengthError, setPasswordStrength] = useCustomValidation(formMethods, {
    name: 'passwordStrength',
    defaultValue: 0,
    rules: {
      validate: (value) => value >= 3 || 'Password not strong enough'
    }
  })

  const environment = useEnvironment()
  const captchaSiteKey = environment.captchaSiteKey
  const [captchaError, setCaptcha] = useCustomValidation(formMethods, {
    name: 'captcha',
    rules: {
      ...(!!captchaSiteKey && { required: 'captcha error' })
    }
  })

  const password = watch('password')
  useTriggerValidation(formMethods, 'confirmPassword', [password])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit(({ display_name, email, password, captcha }) => {
        // trim whitespace from beginning and end of email input
        email = email.trim()
        const formData = { display_name, email, password, captcha }
        onSubmit(formData)
      }),
    [onSubmit, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs: {
      ...inputs,
      password: joinInputErrors(inputs.password, passwordStrengthError)
    },
    password: {
      value: password,
      setStrength: setPasswordStrength
    },
    captcha: {
      isEnabled: !!environment.captchaSiteKey,
      setValue: setCaptcha,
      handleExpiration: useCallback(() => setCaptcha(null), [setCaptcha]),
      error: !!captchaError
    },
    inputButtonRef
  }
}
