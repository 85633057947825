import React, { useCallback } from 'react'
import CopyToClipboardInput from '../../../../../../Components/CopyClipboardInput/CopyToClipboardInput'
import { TextField, InputAdornment, Box, IconButton, CircularProgress } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

interface StreamKeyInputProps {
  isVisible: boolean
  isLoading: boolean
  streamKey?: string
  onEyeBallClicked: () => void
}

export const createStreamKeyPlaceholder = 'Stream key not created, click button below to create'
export const hiddenStreamKeyPlaceholder = '************************************'

/**
 * This component is responsible to display the 3 states of an ebs stream key can be shown. (Hidden, not created, the visible streamkey)
 * @param isHidden - if the stream key is hidden or not
 * @param streamKey- stream key to show
 * @param isLoading- if it still loading info
 * @constructor
 */
const StreamKeyInput: React.FC<StreamKeyInputProps> = ({
  isVisible,
  streamKey,
  isLoading,
  onEyeBallClicked
}) => {
  const getAlternativeInput = useCallback(
    (content, showIcon = true) => (
      <TextField
        value={content}
        variant="outlined"
        fullWidth
        InputProps={{
          disabled: true,
          endAdornment: (
            <InputAdornment position="end">
              <Box mr={1}>
                {isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  showIcon && (
                    <IconButton edge="end" onClick={onEyeBallClicked}>
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  )
                )}
              </Box>
            </InputAdornment>
          )
        }}
      />
    ),
    [isLoading, onEyeBallClicked]
  )

  return (
    <>
      {streamKey ? (
        isVisible ? (
          <CopyToClipboardInput content={streamKey!} />
        ) : (
          getAlternativeInput(hiddenStreamKeyPlaceholder)
        )
      ) : (
        getAlternativeInput(createStreamKeyPlaceholder, false)
      )}
    </>
  )
}

export default React.memo(StreamKeyInput)
