import React from 'react'
import { Cursor, Message } from '../../Models/apiEntities'
import Chat from './Chat'

import { createPaginationContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useChatOrderedMessage } from './hooks/useChatOrderedMessage'
import { useChatMessageSubscription } from './hooks/useChatMessageSubscription'
import { useChatUpdateNotification } from './hooks/useChatUpdateNotification'
import { ChatDecisionInfo } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomPermissions'

interface ChatContainerProps {
  currentProfile: {
    id: string
    displayName: string
  }
  room: {
    id: string
    hash: string
    messages?: {
      edges: Cursor<Message>[]
    }
  }
  relay?: any
  isVisible: boolean
  handleChatNotification: (notify: boolean) => void
  chatPermissionInfo: ChatDecisionInfo
}

const chatConnectionName = 'ChatContainer_messages'

/**
 * Component responsible to wrap chat component and inject relay data.
 * @param currentProfile - object that holds the current profile information.
 * @param room - object that holds the room`s information.
 * @param relay - object containing pagination methods like hasMore, loadMore from relay. Injected by createPaginationContainer
 * @param isVisible - flag if the chat is visible or not.
 * @param handleChatNotification - called to notify parent if new messages arrive when chat is closed.
 * @param chatPermissionInfo
 * @constructor
 */
const ChatContainer: React.FC<ChatContainerProps> = ({
  currentProfile,
  room,
  relay,
  isVisible,
  handleChatNotification,
  chatPermissionInfo
}) => {
  const { orderedMessages, lastMessage } = useChatOrderedMessage({ cursor: room.messages?.edges })
  useChatUpdateNotification({ lastMessage, isVisible, handleChatNotification })
  useChatMessageSubscription(chatConnectionName, room.id)

  return (
    <Chat
      room={room}
      currentProfileId={currentProfile.id}
      messages={orderedMessages || []}
      chatConnectionName={chatConnectionName}
      relay={relay!}
      isVisible={isVisible}
      chatPermissionInfo={chatPermissionInfo}
    />
  )
}

const connectionConfig = {
  getConnectionFromProps(props: ChatContainerProps) {
    return props.room && props.room.messages
  },
  // This is also the default implementation of `getFragmentVariables` if it isn't provided.
  getFragmentVariables(prevVars, totalCount) {
    return {
      ...prevVars,
      count: totalCount
    }
  },
  getVariables(props, { count, cursor }, fragmentVariables) {
    return {
      count,
      cursor,
      orderBy: fragmentVariables.orderBy,
      // hash isn't specified as an @argument for the fragment, but it should be a variable available for the fragment under the query root.
      hash: fragmentVariables.hash
    }
  },
  query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query ChatContainer_MessagesPaginationQuery(
      $count: Int!
      $cursor: Cursor
      $orderBy: [MessagesOrderBy]!
      $hash: UUID!
    ) {
      room: liveroomByHash(hash: $hash) {
        ...ChatContainer_room @arguments(count: $count, cursor: $cursor, orderBy: $orderBy)
      }
    }
  `
}

export default createPaginationContainer(
  ChatContainer,
  {
    currentProfile: graphql`
      fragment ChatContainer_currentProfile on Profile {
        id
      }
    `,
    room: graphql`
      fragment ChatContainer_room on Liveroom
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 10 }
          cursor: { type: "Cursor" }
          orderBy: { type: "[MessagesOrderBy]", defaultValue: [CREATED_AT_DESC] }
        ) {
        displayName
        id
        hash
        creatorId

        messages: messagesByRoomId(first: $count, after: $cursor, orderBy: [CREATED_AT_DESC])
          @connection(key: "ChatContainer_messages", filters: []) {
          edges {
            cursor
            node {
              ...ChatMessageItem_message
              id
              sessionId
              sessionTime
              content
              createdAt
              updatedAt
              deletedAt
              profileId
              profileDisplayName
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `
  },
  connectionConfig
)
