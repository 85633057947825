import { graphql } from 'babel-plugin-relay/macro'

const createStreamKeyMutation = graphql`
  mutation CreateStreamKeyMutation($input: CreateStreamKeyInput!) {
    createStreamKey(input: $input) {
      uuid
    }
  }
`
export default createStreamKeyMutation
