import React, { useCallback, useMemo, useState } from 'react'
import Thumbnail from '../../../../../../../Components/Thumbnail/Thumbnail'
import { useDrawCanvasToolsContext } from '../../../../../../../Providers/DrawCanvasToolsProvider'
import StreamPlayer from '../../StreamPlayer/StreamPlayer'
import { LiveroomPublisher } from '../hooks/useLiveroomPublisher'
import { ThumbnailNodeType } from '../../../../../../../Common/drawtools/types'
import NetworkConnectivityIndicator
  from '../../../../../../../Components/NetworkConnectivity/NetworkConnectivityIndicator'
import { useConnectivityContext } from '../../../../../../../Providers/ConnectivityProvider'
import {
  displayNetworkConnectivityStats,
} from '../../../../../../../Components/NetworkConnectivity/hooks/useDisplayNetworkConnectivityStats'
import { ThumbnailConnectivity } from '../../../../../../../Components/ThumbnailConnectivity/ThumbnailConnectivity'

interface Props {
  publisher: LiveroomPublisher
  onClick: () => void
  shouldShowMuteIndicator: boolean
}

const PublisherThumbnail: React.FC<Props> = ({ publisher, onClick, shouldShowMuteIndicator }) => {
  const { setThumbnailNode } = useDrawCanvasToolsContext()
  const setVideoNode = useCallback(
    (ref) => setThumbnailNode(publisher.id, ref, ThumbnailNodeType.Video),
    [publisher, setThumbnailNode]
  )
  const setCanvasRef = useCallback(
    (ref) => setThumbnailNode(publisher.id, ref, ThumbnailNodeType.Canvas),
    [publisher, setThumbnailNode]
  )

  const isConnectionLost = useMemo(() => {
    const _isConnectionLost = ['failed', 'disconnected'].includes(publisher.iceState)
    return _isConnectionLost
  }, [publisher.iceState])

  const [shouldShowConnectivityStats, setShouldShowConnectivityStats] = useState(false)

  const { currentRTCConnectivityStats } = useConnectivityContext()

  return (
    <Thumbnail
      name={publisher.displayName}
      id={publisher.id}
      shouldShowMuteIndicator={shouldShowMuteIndicator}
      isNameAlwaysVisible
      onClick={onClick}
      isConnectionLost={isConnectionLost}
      canvas={<canvas key={publisher.id} ref={setCanvasRef} />}
      networkConnectivityIndicator={
        <NetworkConnectivityIndicator
          onClick={() => setShouldShowConnectivityStats((bool) => !bool)}
        />
      }
      shouldShowConnectivityStats={shouldShowConnectivityStats}
      thumbnailConnectivityStats={
        <ThumbnailConnectivity
          {...displayNetworkConnectivityStats(currentRTCConnectivityStats)}
          close={() => setShouldShowConnectivityStats(false)}
        />
      }
    >
      <StreamPlayer stream={publisher.stream} isMuted={true} setVideoNode={setVideoNode} />
    </Thumbnail>
  )
}

export default React.memo(PublisherThumbnail)
