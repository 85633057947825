import { graphql } from 'babel-plugin-relay/macro'

const ChatroomMessagesSubscription = graphql`
  subscription ChatroomMessagesSubscription($input: ChatSubInput!) {
    chatroomMessages(input: $input) {
      message {
        ...ChatMessageItem_message
        id
        sessionId
        sessionTime
        content
        createdAt
        deletedAt
        profileId
        profileDisplayName
      }
      event
    }
  }
`

export default ChatroomMessagesSubscription
