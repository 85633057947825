/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateMessageByIdInput = {
    clientMutationId?: string | null;
    messagePatch: MessagePatch;
    id: unknown;
};
export type MessagePatch = {
    id?: unknown | null;
    profileId?: unknown | null;
    roomId?: unknown | null;
    sessionId?: unknown | null;
    content?: string | null;
    sessionTime?: number | null;
    createdAt?: unknown | null;
    updatedAt?: unknown | null;
    deletedAt?: unknown | null;
};
export type UpdateMessageMutationVariables = {
    input: UpdateMessageByIdInput;
};
export type UpdateMessageMutationResponse = {
    readonly updateMessageById: {
        readonly clientMutationId: string | null;
        readonly message: {
            readonly " $fragmentRefs": FragmentRefs<"ChatMessageItem_message">;
        } | null;
    } | null;
};
export type UpdateMessageMutation = {
    readonly response: UpdateMessageMutationResponse;
    readonly variables: UpdateMessageMutationVariables;
};



/*
mutation UpdateMessageMutation(
  $input: UpdateMessageByIdInput!
) {
  updateMessageById(input: $input) {
    clientMutationId
    message {
      ...ChatMessageItem_message
    }
  }
}

fragment ChatMessageItem_message on Message {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessagePayload",
        "kind": "LinkedField",
        "name": "updateMessageById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChatMessageItem_message"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMessageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateMessagePayload",
        "kind": "LinkedField",
        "name": "updateMessageById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileDisplayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b81fb2c7a928d60e8618a0ef472e4e37",
    "id": null,
    "metadata": {},
    "name": "UpdateMessageMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMessageMutation(\n  $input: UpdateMessageByIdInput!\n) {\n  updateMessageById(input: $input) {\n    clientMutationId\n    message {\n      ...ChatMessageItem_message\n    }\n  }\n}\n\nfragment ChatMessageItem_message on Message {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n"
  }
};
})();
(node as any).hash = '61c04f8707848f084576a43237037c91';
export default node;
