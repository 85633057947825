import { useCallback, useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useEnvironment } from '../../../Providers/EnvironmentProvider'
import { routes } from '../../../Views/MainView/AuthenticatedView/routes'

export enum HeaderMenuOptions {
  Dashboard = 'Dashboard',
  Account = 'Account',
  Room = 'Room',
  Logout = 'Sign out',
  AdminPortal = 'Admin Portal'
}

const optionsPathMapper = [
  {
    key: HeaderMenuOptions.Account,
    path: routes.account.path
  },
  {
    key: HeaderMenuOptions.Dashboard,
    path: routes.dashboard.path
  },
  {
    key: HeaderMenuOptions.Room,
    path: routes.room.path
  }
]

export const useHeaderNavigation = () => {
  const history = useHistory()
  const { adminPortalUrl } = useEnvironment()

  const accountRouteMatcher = useRouteMatch(routes.account.path)
  const dashboardRouteMatch = useRouteMatch(routes.dashboard.path)
  const roomRouteMatch = useRouteMatch(routes.room.path)

  const [selectedOption, setSelectedOption] = useState<HeaderMenuOptions | undefined>(
    HeaderMenuOptions.Dashboard
  )

  useEffect(() => {
    const routeMatchers = [accountRouteMatcher, dashboardRouteMatch, roomRouteMatch]
    routeMatchers.forEach((x) => {
      if (x?.isExact) {
        const selected = optionsPathMapper.find((option) => option.path === x.path)
        setSelectedOption(selected?.key || HeaderMenuOptions.Dashboard)
      }
    })
  }, [setSelectedOption, accountRouteMatcher, dashboardRouteMatch, roomRouteMatch])

  const goToClick = useCallback(
    (route, tab = '') => {
      const url = route.path.replace(':tab', tab)
      history.push(url)
    },
    [history]
  )

  const externalLinkClick = useCallback((route) => {
    window.location.href = route
  }, [])

  const goToAccountClick = useCallback((option) => goToClick(routes.account, option), [goToClick])

  const goToDashboardClick = useCallback(() => goToClick(routes.dashboard), [goToClick])

  const goToAdminPortalClick = useCallback(() => externalLinkClick(adminPortalUrl), [
    adminPortalUrl,
    externalLinkClick
  ])

  return {
    goToAccountClick,
    goToDashboardClick,
    goToAdminPortalClick,
    selectedOption
  }
}
