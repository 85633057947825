import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import ProgressBar from '../../../../../../../../Components/ProgressBar/ProgressBar'

const Progress: React.FC = () => {
  return (
    <Box width="100%" height="100%" pt={8} pb={16} pr={4} pl={4} margin={'auto'} display={'flex'}>
      <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
        <Grid item>
          <Box fontSize={80} m={4}>
            <ProgressBar />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant={'h5'} align={'center'}>
            Please enable your webcam & microphone
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body1'} align={'center'}>
            {`Your browser should prompt you to "Allow" or "Enable" your Webcam and Mic. Need help?`}{' '}
            <a
              href="https://guides.evercast.us/troubleshooting/evercast/no-webcam-mic-showing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(Progress)
