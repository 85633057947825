import { useCallback, useState } from 'react'
import videoQualityOptions from '../../../../../../Common/video/videoQualityOptions'

/**
 * Handle screen share dialog state and logic
 * @param handleStartScreenSharing handle method to start screen share
 */
export const useScreenShare = (handleStartScreenSharing, handleClose) => {
  const [videoQualityId, setvideoQualityId] = useState(videoQualityOptions.getDefault().id)

  const handleScreenSharing = useCallback(async () => {
    const selectedQuality = videoQualityOptions.get(videoQualityId)
    handleStartScreenSharing(selectedQuality)
    handleClose()
  }, [handleStartScreenSharing, videoQualityId, handleClose])

  const handleQualityChange = useCallback(
    async (event) => {
      setvideoQualityId(event.target.value)
    },
    [setvideoQualityId]
  )

  return {
    videoQualityId,
    handleQualityChange,
    handleScreenSharing
  }
}
