import React, { useCallback } from 'react'
import AcceptInvite from './AcceptInvite'
import { useParams } from 'react-router-dom'
import QueryRenderer from '../../GraphQL/QueryRenderer'
import AcceptInviteQuery from '../../GraphQL/queries/AcceptInviteQuery'

interface AcceptInviteContainerProps {
  errorComponent: (error: string) => React.ReactNode
}

const AcceptInviteContainer: React.FC<AcceptInviteContainerProps> = ({ errorComponent }) => {
  const { inviteToken } = useParams<{ inviteToken: string }>()
  return (
    <QueryRenderer
      query={AcceptInviteQuery}
      variables={{ inviteToken }}
      renderResult={useCallback((props) => {
        return (
          <AcceptInvite {...props} errorComponent={errorComponent} />
        )
      }, [errorComponent])}
    />
  )
}

export default React.memo(AcceptInviteContainer)
