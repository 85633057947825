import { useCallback, useState, useEffect } from 'react'
import { logger } from '../../../Common/log/Log'
import { RecordingInfo } from '../../../Common/recording/recordingInfo'
import {
  useCreateNoteMutation,
  mapToVariable as mapToCreateVariable
} from './useCreateNoteMutation'
import {
  useUpdateNoteMutation,
  mapToVariable as mapToUpdateVariable
} from './useUpdateNoteMutation'
import { Note } from '../../../Models/apiEntities'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { useDisplayGraphQLErrorsIfPresent } from '../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { AlertType } from '../../Alert/Alert'
import { useClearMessagesForRoomMutation } from './useClearNoteForRoomMutation'

export interface IChatMessageActions {
  createOrUpdate: (content: string) => Promise<void>
  delete: (messageId: string) => Promise<void>
  clearAll: () => Promise<any>
}

const defaultErrorText = 'Error updating notes!'

export const useNoteAction = ({
  roomId,
  connectionName,
  recordingInfo
}: {
  roomId: string
  connectionName: string
  recordingInfo: RecordingInfo
}) => {
  const alert = useAlerts()
  const [selectedNote, setSelectedNote] = useState<Note>()

  const [
    executeCreateMutation,
    { isInProgress: isCreateInProgress, error: creationError, graphQLErrors: creationGraphQLErrors }
  ] = useCreateNoteMutation(roomId, connectionName)
  const [
    executeUpdateMutation,
    { isInProgress: isUpdateInProgress, error: updateError, graphQLErrors: updateGraphQLErrors }
  ] = useUpdateNoteMutation()
  const [
    executeClearAllMutation,
    {
      isInProgress: isClearAllInProgress,
      error: clearAllError,
      graphQLErrors: clearAllGraphQLErrors
    }
  ] = useClearMessagesForRoomMutation()

  const performCreateOrUpdateChatMessage = useCallback(
    async (content: string) => {
      try {
        if (selectedNote) {
          await executeUpdateMutation(mapToUpdateVariable({ id: selectedNote.id, content }))
          setSelectedNote(undefined)
        } else {
          await executeCreateMutation(
            mapToCreateVariable({
              content: content,
              sessionTime: recordingInfo.getRecordingCurrentTime(),
              roomId
            })
          )
        }
      } catch (e) {
        logger.error(e)
      }
    },
    [
      roomId,
      selectedNote,
      setSelectedNote,
      executeUpdateMutation,
      executeCreateMutation,
      recordingInfo
    ]
  )

  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors: creationGraphQLErrors || updateGraphQLErrors || clearAllGraphQLErrors,
    defaultErrorText
  })

  useEffect(() => {
    if (creationError || updateError || clearAllError)
      alert('Something went wrong!', AlertType.error)
  }, [creationError, updateError, clearAllError, alert])

  const performDeleteChatMessage = useCallback(
    async (messageId: string) => {
      try {
        const now = new Date()
        await executeUpdateMutation(
          mapToUpdateVariable({ id: messageId, deletedAt: now.toISOString() })
        )
      } catch (e) {
        logger.error(e)
      }
    },
    [executeUpdateMutation]
  )

  const performClearAll = useCallback(
    async () => executeClearAllMutation({ input: { _roomId: roomId } }),
    [executeClearAllMutation, roomId]
  )

  const selectMessageToEdit = useCallback(
    (message?: Note) => {
      setSelectedNote(message)
    },
    [setSelectedNote]
  )

  return {
    isInProgress: isCreateInProgress || isUpdateInProgress || isClearAllInProgress,
    actions: {
      createOrUpdate: performCreateOrUpdateChatMessage,
      delete: performDeleteChatMessage,
      clearAll: performClearAll
    } as IChatMessageActions,
    selectedMessage: selectedNote,
    selectMessageToEdit
  }
}
