import React from 'react'
import { useEmailDeactivation } from './hooks/useEmailDeactivation'
import TwoFactorDeactivationEmailError from './TwoFactorDeactivationEmailError/TwoFactorDeactivationEmailError'
import TwoFactorDeactivationSuccess from './TwoFactorDeactivationSuccess/TwoFactorDeactivationSuccess'

/**
 * Evercast verify deativation two factor view
 * @constructor
 */

const TwoFactorDeactiveConfigurationView: React.FC = () => {
  const { isTokenInvalid } = useEmailDeactivation()

  if (isTokenInvalid) {
    return <TwoFactorDeactivationEmailError />
  }

  return <TwoFactorDeactivationSuccess />
}

export default React.memo(TwoFactorDeactiveConfigurationView)
