/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime'

export type Lobby_room = {
    readonly id: unknown;
    readonly hash: unknown;
    readonly displayName: string;
    readonly participantCount: number | null;
    readonly " $refType": "Lobby_room";
};
export type Lobby_room$data = Lobby_room;
export type Lobby_room$key = {
    readonly " $data"?: Lobby_room$data;
    readonly " $fragmentRefs": FragmentRefs<"Lobby_room">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Lobby_room",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "participantCount",
      "storageKey": null
    }
  ],
  "type": "Liveroom",
  "abstractKey": null
};
(node as any).hash = '8d4513805c4ff7602672a2daf7c5108f';
export default node;
