import React, { memo, useCallback, useRef, useEffect } from 'react'
import { Box, Slide, SlideProps } from '@material-ui/core'
import { useToggleStatus } from '../../Common/hooks/useToggleStatus'
import { useMenuTrigger } from '../../Providers/MenuTriggerProvider'
interface MouseOverSlide extends Omit<SlideProps, 'children'> {
  children: React.ReactNode
  keepOpen?: boolean
}

const HideDelayValueMs = 3000

const MouseOverSlide: React.FC<MouseOverSlide> = ({ children, keepOpen, ...otherProps }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>()
  const timeoutRef1 = useRef<NodeJS.Timeout | null>()
  const [isVisible, show, hide] = useToggleStatus()
  const { triggerHide, resetTriggerHide } = useMenuTrigger()

  useEffect(() => {
    if (triggerHide) {
      timeoutRef1.current = setTimeout(() => {
        hide()
        resetTriggerHide?.()
      }, HideDelayValueMs)
    }
  }, [triggerHide, hide, resetTriggerHide])

  return (
    <Box
      data-testid="mouse-over-slide"
      onMouseOver={useCallback(() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
        show()
      }, [show])}
      onMouseLeave={useCallback(() => {
        timeoutRef.current = setTimeout(() => {
          hide()
          resetTriggerHide?.()
        }, HideDelayValueMs)
      }, [hide, resetTriggerHide])}
    >
      <Slide direction="up" {...otherProps} in={keepOpen || isVisible}>
        <Box data-testid="mouse-over-slide-content">{children}</Box>
      </Slide>
    </Box>
  )
}

export default memo(MouseOverSlide)
