import React, {
  createContext,
  Dispatch,
  SetStateAction,
  // useCallback,
  useContext,
  // useEffect,
  useState,
} from 'react'
// import { useCSDLocalStorageQueue } from '../Common/hooks/useCSDLocalStorageQueue'
import { RoomPayloadParams } from '../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomDiagnosticsReporting'
interface CSDContextParams {
  setRoomPayloadParams: Dispatch<SetStateAction<RoomPayloadParams>> | (() => void)
  sendEntryToCSD: sendEntryToCSDFunction
}

const csdContext = createContext<CSDContextParams>({
  setRoomPayloadParams: () => {},
  sendEntryToCSD: () => {},
})

export enum CSDErrorCodes {
  serverConnectionLost = 'Server Connection Lost',
  videoFreeze = 'Video Pausing or Freezing',
  audioDigitalization = 'Audio Digitalization',
  audioLag = 'Audio Lag',
  dropFromRoom = 'Drop from Room',
  noAudioVideo = 'No Audio Video',
  grayThumbnail = 'Gray Thumbnail',
  noAudio = 'No Audio',
  oops = 'Oops',
  other = 'Other',
}

interface PayloadParams extends RoomPayloadParams {
  timestamp: null | string
  cpuCores: number
  memoryUtilization: Performance | null
  networkDownlink: number | null
  networkEffectiveType: string | null
  networkRTT: number | null
  chromeVersion: Function | number | boolean | null
}

export interface CSDEntry {
  errorCode: Partial<CSDErrorCodes>
  payload: PayloadParams
}

// function getChromeVersion(): number | boolean {
//   var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
//   return raw ? parseInt(raw[2], 10) : false
// }

type sendEntryToCSDFunction = (errorCode: Partial<CSDErrorCodes>) => void

export const CSDProvider = (props) => {
  const { children } = props
  const [, /* roomPayloadParams */ setRoomPayloadParams] = useState<RoomPayloadParams | null>(null)
  // const { addToCSDLocalStorageQueue, processCSDLocalStorageQueue } = useCSDLocalStorageQueue()
  // const [, setRecentErrors] = useState<CSDEntry[]>([])

  // const sendEntryToCSD = useCallback<sendEntryToCSDFunction>(
  //   (errorCode) => {
  //     const timestamp = new Date().toISOString()
  //     const cpuCores = navigator.hardwareConcurrency
  //     const memoryUtilization = window.performance
  //     const networkDownlink = navigator['connection']['downlink']
  //     const networkEffectiveType = navigator['connection']['effectiveType']
  //     const networkRTT = navigator['connection']['rtt']
  //     const chromeVersion = getChromeVersion()
  //     const payload = {
  //       ...roomPayloadParams,
  //       timestamp,
  //       cpuCores,
  //       memoryUtilization,
  //       networkDownlink,
  //       networkEffectiveType,
  //       networkRTT,
  //       chromeVersion
  //     } as PayloadParams

  //     // We only want the same error code to be sent once every minute
  //     setRecentErrors((errors) => {
  //       const codes = errors.map((e) => e.errorCode)
  //       if (!codes.includes(errorCode)) {
  //         addToCSDLocalStorageQueue({ errorCode, payload })
  //         return [...errors, { errorCode, payload }]
  //       }
  //       return errors
  //     })
  //   },
  //   [roomPayloadParams, addToCSDLocalStorageQueue]
  // )

  const sendEntryToCSD = () => {}

  // useEffect(() => {
  //   setInterval(() => setRecentErrors([]), 60000)
  // }, [])

  // useEffect(() => {
  //   processCSDLocalStorageQueue()
  // }, [processCSDLocalStorageQueue])

  return (
    <csdContext.Provider value={{ setRoomPayloadParams, sendEntryToCSD }}>
      {children}
    </csdContext.Provider>
  )
}

export const useCSDContext = () => useContext(csdContext)
