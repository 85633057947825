import { ConsoleTransport } from './transports/ConsoleTransport'
import { UserProfile } from '../../Models/apiEntities'

/**
 * Represents the log level that could be used in {@link LogTransport} implementation to prevent
 * unnecessary logging.
 */
export enum LogLevel {
  log,
  info,
  debug,
  warn,
  error
}

/**
 * General logging interface that allows to implement different loggers ie. Console
 */
export interface LogTransport {
  setUser?: (currentProfile: UserProfile) => void
  addBreadcrumb?: (any) => void
  log: (...args: any[]) => void
  info: (...args: any[]) => void
  debug: (...args: any[]) => void
  warn: (...args: any[]) => void
  error: (...args: any[]) => void
}

/**
 * Wraps all the log transport into the same interface allowing to have multiple logging transports at the same time.
 */
export class Log implements LogTransport {
  private transports: Array<LogTransport>

  constructor(transports: Array<LogTransport>) {
    this.transports = transports || []
  }

  addBreadcrumb(breadcrumb) {
    this.transports.forEach((transport) => {
      if (transport.addBreadcrumb) {
        transport.addBreadcrumb(breadcrumb)
      }
    })
  }

  log(...args) {
    this.transports.forEach((transport) => {
      transport.log(...args)
    })
  }

  info(...args) {
    this.transports.forEach((transport) => {
      transport.info(...args)
    })
  }

  debug(...args) {
    this.transports.forEach((transport) => {
      transport.debug(...args)
    })
  }

  warn(...args) {
    this.transports.forEach((transport) => {
      transport.warn(...args)
    })
  }

  error(...args) {
    this.transports.forEach((transport) => {
      transport.error(...args)
    })
  }

  setUser(currentProfile) {
    this.transports.forEach((transport) => {
      if (transport.setUser) {
        transport.setUser(currentProfile)
      }
    })
  }
}

const transports = Array<LogTransport>()

transports.push(new ConsoleTransport())

/**
 * Logging singleton, one thing that shouldn't be tight to React/Context and be accessible everywhere.
 */
export const logger = new Log(transports)
