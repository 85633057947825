import { useMutation } from '../../../../../../Common/hooks/useMutation'
import RemoveRoomFromDashboardMutation from '../../../../../../GraphQL/mutations/RemoveRoomFromDashboardMutation'

const getUpdatedNodes = (store) =>
  store
    .getRootField('removeRoomFromDashboard')
    .getLinkedRecord('query')
    .getLinkedRecord('currentProfile')

export const useRemoveRoomFromDashboardMutation = () => {
  return useMutation<any, any>(RemoveRoomFromDashboardMutation, {
    updater: (store, params) => {
      const root = store.getRoot()

      root.setLinkedRecord(getUpdatedNodes(store), 'currentProfile')
    }
  })
}
