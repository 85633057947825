import { useState, useMemo } from 'react'
import { useRelaySubscription } from '../../../Common/hooks/useRelaySubscription'
import ListenAttacherDeviceLoginCodeUpdateSubscription from '../../../GraphQL/subscriptions/ListenAttacherDeviceLoginCodeUpdateSubscription'

export const useNotifySubscription = (onShow?: () => void) => {
  const [isApproved, updateStatus] = useState(false)
  const [appleTVName, updateAppleTVName] = useState('')

  const ListenAttacherDeviceLoginCodeUpdateConfig = useMemo(
    () => ({
      subscription: ListenAttacherDeviceLoginCodeUpdateSubscription,
      variables: {},
      onNext: ({ listenAttacherDeviceLoginCodeUpdate }) => {
        if (onShow) {
          onShow()
        }

        if (listenAttacherDeviceLoginCodeUpdate) {
          if (listenAttacherDeviceLoginCodeUpdate.deviceCodeApproved) {
            updateStatus(
              listenAttacherDeviceLoginCodeUpdate.deviceCodeApproved.toString() === 'true'
            )
          }
          if (listenAttacherDeviceLoginCodeUpdate.deviceName) {
            updateAppleTVName(listenAttacherDeviceLoginCodeUpdate.deviceName)
          }
        }
      }
    }),
    [onShow, updateAppleTVName, updateStatus]
  )

  const context = useMemo(
    () => ({
      connectionName: 'NotifyAppleTV'
    }),
    []
  )
  useRelaySubscription(ListenAttacherDeviceLoginCodeUpdateConfig, context)

  return { isApproved, appleTVName }
}
