/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecordingsView_room = {
    readonly sessions: {
        readonly nodes: ReadonlyArray<{
            readonly id: unknown;
            readonly recordingId: string;
            readonly displayName: string;
            readonly startedAt: unknown;
            readonly stoppedAt: unknown | null;
            readonly createdAt: unknown;
            readonly deletedAt: unknown | null;
        } | null>;
    };
    readonly id: unknown;
    readonly creatorId: unknown | null;
    readonly organizationId: unknown | null;
    readonly hash: unknown;
    readonly featureFlags: {
        readonly nodes: ReadonlyArray<{
            readonly key: string | null;
            readonly value: string | null;
        } | null>;
    };
    readonly " $refType": "RecordingsView_room";
};
export type RecordingsView_room$data = RecordingsView_room;
export type RecordingsView_room$key = {
    readonly " $data"?: RecordingsView_room$data;
    readonly " $fragmentRefs": FragmentRefs<"RecordingsView_room">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecordingsView_room",
  "selections": [
    {
      "alias": "sessions",
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "deletedAt": null
          }
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "CREATED_AT_DESC"
          ]
        }
      ],
      "concreteType": "SessionsConnection",
      "kind": "LinkedField",
      "name": "sessionsByRoomId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Session",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recordingId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stoppedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "sessionsByRoomId(condition:{\"deletedAt\":null},orderBy:[\"CREATED_AT_DESC\"])"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ColumnRoomFeatureFlagsConnection",
      "kind": "LinkedField",
      "name": "featureFlags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ColumnRoomFeatureFlagsRecord",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Liveroom",
  "abstractKey": null
};
})();
(node as any).hash = 'ad3caf6455d21531e4bc1324fa53e075';
export default node;
