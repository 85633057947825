import { useEffect, useState } from 'react'
import { logger } from '../../../../../../../../Common/log/Log'
import { LocalDevicesActions, LocalDevicesState } from '../../../hooks/useLocalDevicesState'
import { TransitionState } from '../LiveRoomTransition'

interface Params {
  transitionState: TransitionState
  localDevicesState: LocalDevicesState
  localDevicesActions: LocalDevicesActions
}

/**
 * Enables/Disables Camera/Mic/AudioOutput accordingly to room entry animation state.
 * Initially, all devices are disabled and switch to user selected state when animation ends.
 * @param transitionState - room animation state
 * @param localDevicesActions - handlers for changing devices state
 * @param localDevicesState - current devices state, remembered at first render and used to restore devices state when animation ends
 */
export const useSyncLocalDevicesStateWithEntryAnimation = ({
  transitionState,
  localDevicesActions,
  localDevicesState
}: Params) => {
  /**
   * When going to the liveroom, remember what user has initially chosen
   */
  const [userLocalSelectedDevicesState] = useState<LocalDevicesState>(localDevicesState)

  useEffect(() => {
    if (transitionState === TransitionState.loading) {
      logger.log(
        'Muting local devices, room joining animation starts.',
        userLocalSelectedDevicesState
      )

      localDevicesActions.setIsAudioOutputEnabled(false)
      localDevicesActions.setIsCameraEnabled(false)
      localDevicesActions.setIsMicrophoneEnabled(false)
    }

    if (transitionState === TransitionState.liveroom) {
      logger.log(
        'Restoring user selected local devices state, room joining animation has ended.',
        userLocalSelectedDevicesState
      )

      localDevicesActions.setIsAudioOutputEnabled(
        userLocalSelectedDevicesState.isAudioOutputEnabled
      )
      localDevicesActions.setIsCameraEnabled(userLocalSelectedDevicesState.isCameraEnabled)
      localDevicesActions.setIsMicrophoneEnabled(userLocalSelectedDevicesState.isMicrophoneEnabled)
    }
    // TODO: Inherited from cleverTech, haven't investigated yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionState])
}
