import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { VideocamOffOutlined } from '@material-ui/icons'
import { IconSize } from '../StreamPreview'
import Outline from '../../../../../../../../Components/Outline/Outline'
import palette from '../../../../../../../../Common/theme/colors/palette'

const NoCamera: React.FC = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <Box display={'flex'} flexDirection={'row'} justifyContent="center" alignItems="center">
            <Box
              fontSize={IconSize}
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              m={2}
            >
              <Outline color={palette.root.secondary} thickness={2}>
                <VideocamOffOutlined
                  fontSize={'inherit'}
                  color="secondary"
                  style={{ padding: 8 }}
                />
              </Outline>
            </Box>

            <Typography variant={'h5'} align={'center'}>
              Webcam not detected
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={2}
          pr={2}
          maxWidth={800}
        >
          <Typography variant={'body1'} align={'center'}>
            You can still go live without a webcam. However, you will only be able to communicate.
            No one will be able to see you. To enable your webcam,{' '}
            <a
              href={`${process.env.REACT_APP_SUPPORT_URL}/hc/en-us/articles/4406287062931-Troubleshooting-Camera-and-or-microphone-won-t-work`}
              target="_blank"
              rel="noopener noreferrer"
            >
              click here.
            </a>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(NoCamera)
