import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { useSingleSignOnForm } from './hooks/useSingleSignOnForm'
import { Link } from 'react-router-dom'
import { useQueryParams } from '../../Common/hooks/useQueryParams'
import { TextField, Grid } from '@material-ui/core'
import ProgressButton from '../ProgressButton/ProgressButton'

import './SingleSignOnForm.scss'

interface SingleSignOnFormProps {
  loginRoutePath: string
}

const SingleSignOnForm: React.FC<SingleSignOnFormProps> = ({ loginRoutePath }) => {
  const errorMap = {
    invalid_client: 'Unable to complete SSO login. Please contact support.'
  }

  const { ssoError } = useQueryParams()
  const errorMessage =
    ssoError === 'string' || ssoError instanceof String
      ? errorMap[ssoError as string] || (ssoError as string)
      : undefined

  const {
    handleSubmit,
    isLoading,
    inputs: { email }
  } = useSingleSignOnForm(errorMessage)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Email address"
              variant="filled"
              autoComplete="email"
              id="email"
              {...email}
              autoFocus
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Link to={loginRoutePath}>Back to sign in</Link>
        </Grid>
        <Grid item xs={12}>
          <ProgressButton type="submit" color="primary" isLoading={isLoading} fullWidth>
            Continue
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default React.memo(SingleSignOnForm)
