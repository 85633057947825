import { useMutation } from '../../../Common/hooks/useMutation'
import CreateRoomByAssignmentMutation from '../../../GraphQL/mutations/CreateRoomByAssignmentMutation'

const storeUpdater = (store) => {
  const savePreferences = store.getRootField('createRoomByAssignment')
  const queryField = savePreferences.getLinkedRecord('query')
  const currentProfile = queryField.getLinkedRecord('currentProfile')
  const featureFlags = currentProfile.getLinkedRecord('featureFlags')
  const nodes = featureFlags.getLinkedRecords('nodes')
  const rootField = store.getRoot()
  const oldCurrentProfile = rootField.getLinkedRecord('currentProfile')
  const oldFeatureFlags = oldCurrentProfile.getLinkedRecord('featureFlags')
  oldFeatureFlags.setLinkedRecords(nodes, 'nodes')
}

export const useCreateRoomByAssignmentMutation = () =>
  useMutation<any, any>(CreateRoomByAssignmentMutation, {
    updater: storeUpdater
  })
