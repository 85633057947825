import { useMutation } from '../../../Common/hooks/useMutation'
import CreateMessageMutation from '../../../GraphQL/mutations/CreateMessageMutation'
import { ConnectionHandler } from 'relay-runtime'

export const createConfig = (rootFieldName, connectionName, roomId, position = 'before') => {
  return {
    updater: (store, eventData) => {
      // Handle checks for subscription updater
      if (eventData && eventData[rootFieldName]) {
        const {
          [rootFieldName]: { message: messageData, event }
        } = eventData
        if (event === 'clearChats') {
          // remove all edges
          const roomField = store.get(roomId)
          const messagesByRoomId = ConnectionHandler.getConnection(roomField, connectionName)
          if (messagesByRoomId) {
            const edges = messagesByRoomId.getLinkedRecords('edges')
            if (edges) {
              edges.forEach((edge) =>
                ConnectionHandler.deleteNode(
                  messagesByRoomId,
                  edge.getLinkedRecord('node')!.getValue('id') as string
                )
              )
            }
          }
          return
        } else if (event === 'messageUpdate' || !messageData) {
          // Note is already updated by subscription, no need to insert it in store
          return
        }
      }

      // Get message from query
      const queryRootField = store.getRootField(rootFieldName)
      const message = queryRootField.getLinkedRecord('message')

      // Add edge to messages collection
      const roomField = store.get(roomId)
      const messagesByRoomId = ConnectionHandler.getConnection(roomField, connectionName)
      if (messagesByRoomId) {
        const edge = ConnectionHandler.createEdge(store, messagesByRoomId, message, '')
        if (position === 'before') {
          ConnectionHandler.insertEdgeBefore(messagesByRoomId, edge)
        } else if (position === 'after') {
          ConnectionHandler.insertEdgeAfter(messagesByRoomId, edge)
        }
      }
    }
  }
}

export const mapToVariable = ({
  content,
  sessionTime,
  roomId
}: {
  content: string
  sessionTime: number
  roomId: string
}) => ({
  input: {
    message: {
      content,
      roomId,
      sessionTime
    }
  }
})

export const useCreateMessageMutation = (roomId: string, connectionName: string) =>
  useMutation<any, any>(
    CreateMessageMutation,
    createConfig('createMessage', connectionName, roomId)
  )
