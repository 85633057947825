import React from 'react'
import { Box, Grid } from '@material-ui/core'

export const BottomOverlay: React.FC = ({ children }) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      right={0}
      left={0}
      pt={5}
      pb={5}
      bgcolor="rgba(19, 19, 19, 0.7)"
      px={2}
    >
      <Grid container direction="column" justify="center" alignItems="center">
        {children}
      </Grid>
    </Box>
  )
}
