import { useCallback } from 'react'
import { useCreateRoomByAssignmentMutation } from './useCreateRoomByAssignmentMutation'
import { useUpdateRoomMutation } from './useUpdateRoomMutation'
import { logger } from '../../../Common/log/Log'

/**
 * Represents the data returned by {@link useRoomAction}
 */
interface UseRoomActionResult {
  isInProgress: boolean
  actions: {
    performCreateMutation
    performUpdateMutation
  }
}

type LiveRoomProp = {
  id: string
  hash: string
}

interface UseRoomActionProps {
  onSuccess: (arg: LiveRoomProp) => void
}

/**
 * Exposes the interface for create/update room action.
 * @param onSuccess - callback that has to be called when user logs in
 * @returns {@type UseRoomActionResult}
 */
export const useRoomAction = ({ onSuccess }: UseRoomActionProps): UseRoomActionResult => {
  const [
    executeCreateRoomByAssignmentMutation,
    { isInProgress: createByAssignmentInProgress }
  ] = useCreateRoomByAssignmentMutation()
  const [executeUpdateRoomMutation, { isInProgress: updateInProgress }] = useUpdateRoomMutation()

  const performCreateMutation = useCallback(
    async ({ displayName, isPublic }) => {
      try {
        const createRoom = executeCreateRoomByAssignmentMutation
        const queryLabel = 'createRoomByAssignment'
        const {
          [queryLabel]: { liveroom }
        } = await createRoom({ input: { displayName, isPublic } })
        onSuccess(liveroom)
      } catch (e) {
        logger.error(e)
      }
    },
    [onSuccess, executeCreateRoomByAssignmentMutation]
  )

  const performUpdateMutation = useCallback(
    async ({ id, displayName, isPublic }) => {
      try {
        const {
          updateLiveroomById: { liveroom }
        } = await executeUpdateRoomMutation({
          input: {
            id,
            liveroomPatch: {
              displayName,
              isPublic
            }
          }
        })
        onSuccess(liveroom)
      } catch (e) {
        logger.error(e)
      }
    },
    [executeUpdateRoomMutation, onSuccess]
  )

  return {
    isInProgress: updateInProgress || createByAssignmentInProgress,
    actions: {
      performCreateMutation,
      performUpdateMutation
    }
  }
}
