/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UnauthenticatedRoomViewQueryVariables = {
    hash: unknown;
};
export type UnauthenticatedRoomViewQueryResponse = {
    readonly isPublic: boolean | null;
};
export type UnauthenticatedRoomViewQuery = {
    readonly response: UnauthenticatedRoomViewQueryResponse;
    readonly variables: UnauthenticatedRoomViewQueryVariables;
};



/*
query UnauthenticatedRoomViewQuery(
  $hash: UUID!
) {
  isPublic: isHashPublic(isHash: $hash)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hash"
  }
],
v1 = [
  {
    "alias": "isPublic",
    "args": [
      {
        "kind": "Variable",
        "name": "isHash",
        "variableName": "hash"
      }
    ],
    "kind": "ScalarField",
    "name": "isHashPublic",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnauthenticatedRoomViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnauthenticatedRoomViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c896d297abcca3ec8f08d3888422155",
    "id": null,
    "metadata": {},
    "name": "UnauthenticatedRoomViewQuery",
    "operationKind": "query",
    "text": "query UnauthenticatedRoomViewQuery(\n  $hash: UUID!\n) {\n  isPublic: isHashPublic(isHash: $hash)\n}\n"
  }
};
})();
(node as any).hash = '79b4ddae4b9b5cddb029a83cf6863bda';
export default node;
