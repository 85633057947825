import { Box } from '@material-ui/core'
import React, { useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { animationVariants } from '../MainView.styles'
import './LandingView.scss'
import SVG from './LandingViewBackground.svg'
import { useWindowSize, Size } from '../../../Common/hooks/useWindowSize'

interface LandingViewProps {
  showNewUserActions?: boolean
}

const LandingView: React.FC<LandingViewProps> = ({ children }) => {
  const renderContent = useCallback(() => children, [children])
  const size: Size = useWindowSize()
  return (
    <Box className="landing-view-container">
      <AnimatePresence exitBeforeEnter>
        {(size.width !== undefined && size.width > 1000) && (
          <motion.div
            className="landing-view-square"
            variants={animationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <img src={SVG} alt="Evercast Login Screen" />
          </motion.div>
        )}
      </AnimatePresence>
      <Box className="landing-view-content">
        <Box key="main-area">
          {renderContent}
        </Box>
      </Box>
    </Box>
  )
}

export default LandingView
