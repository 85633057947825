import { useCallback, useState } from 'react'

/**
 * Manage logic for the menu
 */
export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (anchorEl) {
        setAnchorEl(null)
        return
      }

      setAnchorEl(event.currentTarget)
    },
    [anchorEl]
  )

  const handleClose = useCallback(
    (event, item) => {
      event.stopPropagation()
      setAnchorEl(null)
      if (item.onClick) {
        item.onClick()
      }
    },
    [setAnchorEl]
  )

  return {
    anchorEl,
    handleClick,
    handleClose
  }
}
