/* eslint-disable react-hooks/exhaustive-deps */
import { EffectCallback, DependencyList, useEffect } from 'react'

interface useDelayedEffectParams {
  delay: number
  effect: EffectCallback
  predicate?: () => boolean
}

/**
 * Runs effect after given delay
 *
 * @param   {number}  delay                        delay in milliseconds
 * @param   {EffectCallback}  effect               effect to take place after given delay
 * @param   {any[]}           dependencies         dependencies list
 */
export const useDelayedEffect = (
  { delay, effect, predicate = () => true }: useDelayedEffectParams,
  dependencies: DependencyList
) => {
  useEffect(() => {
    if (!predicate()) {
      return
    }
    let cleanupFn
    const timer = setTimeout(() => {
      cleanupFn = effect()
    }, delay)
    return () => {
      clearTimeout(timer)
      // if timer was executed run internal effect cleanup function as well
      if (cleanupFn) {
        cleanupFn()
      }
    }
  }, dependencies)
}
