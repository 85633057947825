import { useEffect, useState } from 'react'
import useAPI from '../../../API/useApi'
import styled from 'styled-components'
import { useMediaQuery } from '@material-ui/core'

export const StyledPill = styled.div`
  padding: 5px 10px;
  background: #121212;
  border-radius: 18px;
  min-width: 60px;
  text-align: center;
`

export type AccountMeProps = {
  email: string
  displayName: string
  didUserLogInWithSSO: boolean
  isUserAuthVerified: boolean
  deviceId: string
  isUserGuest: boolean
}

export const useHeaderInfo = (currentProfile) => {
  const [accountMe, setAccountMe] = useState<AccountMeProps>()
  const { AuthAccount } = useAPI()

  const tablet = useMediaQuery('(min-width:1015px)')
  const mobile = useMediaQuery('(min-width:800px)')
  const hideAll = useMediaQuery('(min-width:700px)')

  const fetchAccountMe = async () => {
    try {
      const response = await AuthAccount.me()
      setAccountMe(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAccountMe()
  }, [currentProfile])

  return {
    accountMe,
    tablet,
    mobile,
    hideAll,
  }
}
