import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useWatermarkProvider } from '../../Providers/WatermarkProvider'
import { useVisibleWatermark } from './hooks/useVisibleWatermark'
import { getVisibleWatermarkElementId, getWatermarkElementId, getWrapperElement, turnOffWatermark, turnOnWatermark } from './toggleWatermark'
import { useMutationObserver } from './hooks/useMutationObserver'

interface WatermarkProps {
  streamId?: string
}

export const Watermark = ({ streamId }: WatermarkProps) => {
  const [time, setTime] = useState(Date.now());
  const { forensicWatermarkError, forensicWatermark, shouldAllowVisibleWatermark } = useWatermarkProvider()
  const { info, dateString } = useVisibleWatermark()

  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    // Only attach to resize event for main stream (streamId === '')
    if (!streamId) {
      window.addEventListener('resize', updateSize);
    }
    return () => {
      if (!streamId) window.removeEventListener('resize', updateSize) 
    }
  }, []);


  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [streamId]);

  const checkManualDeletion = () => {
    const imageFound = document.querySelector(`#${getWatermarkElementId(streamId)}`);
    const textFound = document.querySelector(`#${getVisibleWatermarkElementId(streamId)}`);
    if (!imageFound || !textFound) {
      setTime(Date.now())
    }
  };

  const wrapperEl = getWrapperElement(streamId)
  useMutationObserver(wrapperEl, checkManualDeletion);

  useEffect(() => {
    if (!forensicWatermarkError) {
      turnOnWatermark(streamId, (shouldAllowVisibleWatermark && !streamId) ? info : null, (shouldAllowVisibleWatermark && !streamId) ? dateString : '', forensicWatermark ? `data:${forensicWatermark.contentType};base64,${forensicWatermark.content}` : null)
    }
    return () => {
      turnOffWatermark(streamId)
    }
  }, [forensicWatermarkError, forensicWatermark, size, time, streamId])

  return (
    <></>
  )
}
