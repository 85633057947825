import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { Grid, Box, Typography } from '@material-ui/core'
import { UserProfile } from '../../Models/apiEntities'
import { useLoginForm } from './hooks/useLoginForm'
import { routes } from '../../Views/MainView/UnauthenticatedView/routes'
import { useLoginAction } from './hooks/useLoginAction'
import ProgressButton from '../ProgressButton/ProgressButton'
import { useEffectWithPredicate } from '../../Common/hooks/useEffectWithPredicate'
import TwoFactorVerificationView from '../../Views/MainView/UnauthenticatedView/TwoFactorVerificationView/TwoFactorVerificationView'
import SSOLockup from '../../Views/MainView/UnauthenticatedView/SSOLockup/SSOLockup'
import { UnauthenticatedViewContext } from '../../Views/MainView/UnauthenticatedView/hooks/UnaunthentciatedViewContext'
import './LoginForm.scss'
import { useQueryParams } from '../../Common/hooks/useQueryParams'

/**
 * Login Form component props
 */
export interface LoginFormProps {
  onLogin: (userProfile: UserProfile) => void
  onTwoFactorStarted: (...args) => any
  onTwoFactorCanceled: (...args) => any
  loginState: any
}

export enum LoginState {
  login,
  password,
  twoFA,
  sso,
  privateRoom,
  privateRoomPassword,
}

/**
 * Login form component
 * @param onLogin - callback called when login is successful
 * @param handleTwoFactor - responsible to gather the  email and password then send to 2fa verification
 * @constructor
 */
const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  onTwoFactorStarted,
  onTwoFactorCanceled,
  loginState,
}) => {
  const {
    isInProgress,
    isVerificationRequired,
    setVerificationRequired,
    actions: { performAuthentication },
  } = useLoginAction({
    onLogin,
    redirectToSSO: () => {
      setState(LoginState.sso)
      handleSsoSubmit()
    },
  })
  const {
    handleSubmit: _handleSubmit,
    handleSsoSubmit,
    formMethods,
    inputs: { email, password, mfaCode },
  } = useLoginForm({ performAuthentication })

  const [state, setState] = loginState

  useEffectWithPredicate(
    {
      predicate: () => isVerificationRequired,
      effect: () => {
        setState(LoginState.twoFA)
        onTwoFactorStarted(true)
      },
    },
    [isVerificationRequired]
  )

  const { 'private-room': privateRoom } = useQueryParams()

  useEffectWithPredicate(
    {
      predicate: () => privateRoom === 'true',
      effect: () => {
        setState(LoginState.privateRoom)
      },
    },
    []
  )

  const handleTwoFactorCanceled = useCallback(() => {
    setVerificationRequired(false)
    setState(LoginState.login)
    onTwoFactorCanceled(false)
  }, [onTwoFactorCanceled, setVerificationRequired, setState])

  const handleContinueButton = useCallback(async () => {
    const { trigger } = formMethods
    const success = await trigger('email')

    if (success) {
      if (state === LoginState.login) {
        setState(LoginState.password)
      } else if (state === LoginState.privateRoom) {
        setState(LoginState.privateRoomPassword)
      }
    }
  }, [setState, formMethods, state])

  const handleTwoFieldSubmit = useCallback(async () => {
    const { trigger } = formMethods
    await trigger('password')
    return _handleSubmit()
  }, [_handleSubmit, formMethods])

  const handleEnterKey = useCallback(
    (e) => {
      if ((e.key === 'Enter' || e.keyCode === 13) && e.target.value) {
        if (state === LoginState.login || state === LoginState.privateRoom) handleContinueButton()
        if (state === LoginState.password) handleTwoFieldSubmit()
        if (state === LoginState.sso) handleSsoSubmit()
        if (state === LoginState.twoFA) handleTwoFieldSubmit()
      }
    },
    [handleContinueButton, handleSsoSubmit, handleTwoFieldSubmit, state]
  )

  const [emailValue, setEmailValue] = useState<string>('')
  const handleCancelButton = () => {
    setEmailValue('')
    setState(LoginState.login)
  }

  const { signUpRedirect } = useContext(UnauthenticatedViewContext)
  useEffect(() => {
    //if signUpRedirect returns true, then set state to sso in order to route user to the company sso login experience
    signUpRedirect && setState(LoginState.sso)
  }, [signUpRedirect, setState])

  return (
    <>
      <Grid
        container
        onKeyUp={handleEnterKey}
        style={{
          display: [LoginState.login, LoginState.privateRoom, LoginState.sso].includes(state)
            ? 'block'
            : 'none',
        }}
      >
        <Grid item xs={12}>
          <Box pb={1} mb={state === LoginState.sso && 5}>
            <Typography variant="h4">
              {(state === LoginState.privateRoom || state === LoginState.privateRoomPassword) &&
                'Private room sign in'}
              {state === LoginState.sso && 'Sign in with SSO'}
              {state === LoginState.login && 'Sign in'}
            </Typography>
          </Box>
          {state !== LoginState.sso && (
            <Box className="login-navigation" mb={2.5}>
              New to Evercast? <Link to={routes.signUp.path}>Create an account</Link>
            </Box>
          )}
          <FormControl fullWidth>
            <TextField
              label="Email Address"
              className="text-field"
              variant="filled"
              autoComplete="email"
              {...email}
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
            />
          </FormControl>
        </Grid>
        {state === LoginState.sso && (
          <Box ml={1.5} mt={1} mb={3}>
            Enter your company email address
          </Box>
        )}
        {state === LoginState.privateRoom && (
          <Box ml={1.5} mt={1} mb={3}>
            You must use the email address on your invitation
          </Box>
        )}
      </Grid>

      <Grid
        container
        spacing={3}
        onKeyUp={handleEnterKey}
        style={{
          display: [LoginState.password, LoginState.privateRoomPassword].includes(state)
            ? 'block'
            : 'none',
        }}
      >
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h4">
              {state === LoginState.privateRoomPassword ? 'Private room sign in' : 'Sign in'}
            </Typography>
          </Box>
          <Box className="login-navigation" mb={2.5}>
            New to Evercast? <Link to={routes.signUp.path}>Create an account</Link>
          </Box>
          <FormControl fullWidth className="login-form-password">
            <TextField
              className="text-field"
              label="Password"
              type="password"
              variant="filled"
              autoComplete="current-password"
              {...password}
            />
          </FormControl>
          <Box marginTop={2} mb={2} className="login-navigation">
            Forgot password? <Link to={routes.forgotPassword.path}>Reset your password</Link>
          </Box>
        </Grid>
      </Grid>

      {(state === LoginState.login || state === LoginState.privateRoom) && (
        <Grid container spacing={3} onKeyUp={handleEnterKey}>
          <Grid item xs={12}>
            <Box mt={3}>
              <ProgressButton
                color="primary"
                isLoading={isInProgress}
                onClick={() => handleContinueButton()}
                className="continue"
                size="medium"
              >
                Continue
              </ProgressButton>
            </Box>
          </Grid>
        </Grid>
      )}

      {(state === LoginState.password ||
        state === LoginState.sso ||
        state === LoginState.privateRoomPassword) && (
        <Grid container spacing={3} onKeyUp={handleEnterKey}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="flex-start">
              <Box mr={3}>
                <ProgressButton
                  isLoading={false}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => handleCancelButton()}
                  size="medium"
                >
                  Cancel
                </ProgressButton>
              </Box>
              <Box>
                <ProgressButton
                  isLoading={isInProgress}
                  onClick={state === LoginState.sso ? handleSsoSubmit : handleTwoFieldSubmit}
                  fullWidth
                  className="continue"
                  size="medium"
                >
                  {(state === LoginState.password || state === LoginState.privateRoomPassword) &&
                    'Sign in'}
                  {state === LoginState.sso && 'Continue'}
                </ProgressButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {state === LoginState.twoFA && (
        <TwoFactorVerificationView
          mfaCode={mfaCode}
          onTwoFactorCanceled={handleTwoFactorCanceled}
          handleTwoFieldSubmit={handleTwoFieldSubmit}
        />
      )}

      {(state === LoginState.login || state === LoginState.privateRoom) && (
        <SSOLockup onLogin={onLogin} setState={setState} LoginState={LoginState} />
      )}
    </>
  )
}

export default React.memo(LoginForm)
