const getAverageVolume = (audioBuffer) => {
  const sumOfSquares = audioBuffer.reduce((sum, x) => sum + x * x, 0)
  // We use RMS (Root Mean Square) instead of simple average value
  // because audio values are contained in [-1, 1] range and it is a sine-like signal.
  // And in that case we should use RMS: https://electronics.stackexchange.com/questions/40341/why-v-rms-instead-of-v-average
  return Math.sqrt(sumOfSquares / audioBuffer.length)
}

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}
/**
 * Evercast audio processing/detection.
 **/
export class EvercastAudio {
  private audioContext: AudioContext
  private source?: MediaStreamAudioSourceNode | null
  private processor?: ScriptProcessorNode | null

  constructor() {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    this.audioContext = new AudioContext()
  }

  detect(stream, options, callback) {
    this.source = this.audioContext.createMediaStreamSource(stream)
    this.processor = this.audioContext.createScriptProcessor(512)

    options = Object.assign(
      {
        minThreshold: 0, // minimum value that we want to count, if less than that then return 0
        onlyPeaks: false
      },
      options
    )

    let currentVolumePitch = 0
    let volumeMark = 0

    this.processor.onaudioprocess = function (event) {
      currentVolumePitch = getAverageVolume(event.inputBuffer.getChannelData(0))

      if (currentVolumePitch > volumeMark && currentVolumePitch > options.minThreshold) {
        // if we cross min threshold and our current volumeMark then update volumeMark
        volumeMark = currentVolumePitch
        callback(volumeMark, options)
      } else {
        // otherwise gradually decrease volumeMark
        volumeMark -= volumeMark * 0.05
        volumeMark = volumeMark > options.minThreshold ? volumeMark : 0
        if (!options.onlyPeaks) {
          callback(volumeMark, options)
        }
      }
    }

    this.processor.connect(this.audioContext.destination)
    this.source.connect(this.processor)
  }

  cleanup() {
    this.audioContext.close()
    this.source = null
    this.processor = null
  }
}
