import { useState } from 'react'
import { noneDeviceId } from '../../Room/hooks/useMediaDevices'

const macPPTKeyOptions = {
  None: noneDeviceId,
  Option: 'Alt',
  Command: 'Meta',
  Control: 'Control',
  Shift: 'Shift',
  Space: ' '
} as const

const winPPTKeyOptions = {
  None: noneDeviceId,
  Alt: 'Alt',
  Control: 'Control',
  Shift: 'Shift',
  Space: ' '
} as const

const PPTKeyOptions = { ...macPPTKeyOptions, ...winPPTKeyOptions }

type PPTOption = keyof typeof PPTKeyOptions
export type PPTKey = typeof PPTKeyOptions[PPTOption]

export const usePPTShortcut = ({ currentMediaSelection }) => {
  const [PPTShortcut, setPPTShortcut] = useState<PPTKey>(currentMediaSelection.PPTShortcut)

  const PPTKeyName = Object.entries(PPTKeyOptions).find(([key, value]) => value === PPTShortcut)?.[0] 
  const isMac = /Mac/i.test(navigator.platform)

  return {
    PPTKeyName,
    PPTShortcut,
    setPPTShortcut,
    PPTKeyOptions: isMac ? macPPTKeyOptions : winPPTKeyOptions
  }
}
