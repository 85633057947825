import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import AdaptiveTitle from '../../../../../Components/AdaptiveTitle/AdaptiveTitle'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import LandingView from '../../../LandingView/LandingView'
import { routes } from '../../routes'
import useApi from '../../../../../API/useApi'

const RoomInviteUsed = ({ isLoggingOut = true }): JSX.Element => {
  const { AuthAccount } = useApi()

  useEffect(() => {
    if (isLoggingOut) {
       AuthAccount.logout()
    }
  })

  return (
    <LandingView>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <AdaptiveTitle>Let's try this again.</AdaptiveTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            It looks like this link has already been used. Please get a new link from the room
            owner.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to={routes.dashboard.path}>
            <ProgressButton isLoading={false} color="primary" fullWidth>
              Back to Home
            </ProgressButton>
          </Link>
        </Grid>
      </Grid>
    </LandingView>
  )
}

export default React.memo(RoomInviteUsed)
