/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type VerifyEmailAddressInput = {
    clientMutationId?: string | null;
    verificationToken: unknown;
};
export type VerifyEmailMutationVariables = {
    input: VerifyEmailAddressInput;
};
export type VerifyEmailMutationResponse = {
    readonly verifyEmailAddress: {
        readonly emailVerificationResult: {
            readonly isVerified: boolean | null;
            readonly email: string | null;
        } | null;
    } | null;
};
export type VerifyEmailMutation = {
    readonly response: VerifyEmailMutationResponse;
    readonly variables: VerifyEmailMutationVariables;
};



/*
mutation VerifyEmailMutation(
  $input: VerifyEmailAddressInput!
) {
  verifyEmailAddress(input: $input) {
    emailVerificationResult {
      isVerified
      email
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VerifyEmailAddressPayload",
    "kind": "LinkedField",
    "name": "verifyEmailAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailVerificationResult",
        "kind": "LinkedField",
        "name": "emailVerificationResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isVerified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "caab9a3a51f86639220bbc73c9fb0b47",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailMutation(\n  $input: VerifyEmailAddressInput!\n) {\n  verifyEmailAddress(input: $input) {\n    emailVerificationResult {\n      isVerified\n      email\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3333dd06f071a84a08a2f0664f521268';
export default node;
