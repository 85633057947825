import { MutableRefObject } from 'react'
import { StreamState } from '../../../../../../../Common/media/stream'

interface DAMStreamProps {
  id: string
  src: string
  progress: number
  isPaused: boolean
  isBuffering: boolean
  onStreamWaiting: (id: string) => void
  onStreamCanPlay: (id: string) => void
  onStreamStalled: (id: string) => void
}

interface AddVideoReferenceOptions {
  useToTrackTimeUpdate?: boolean
}

export class DamStream {
  videoReferences: MutableRefObject<HTMLVideoElement>[]
  mainReference: MutableRefObject<HTMLVideoElement>
  id: string
  private _progress: number
  volume: number
  contentType: string
  isPaused: boolean
  isBuffering: boolean
  state = StreamState.available
  src: string
  type = 'dam-stream'
  speaker: MediaDeviceInfo | null
  hasAudioTrack = true
  hasVideoTrack = true
  current = new MediaStream()
  onStreamWaiting: (id: string) => void
  onStreamCanPlay: (id: string) => void
  onStreamStalled: (id: string) => void

  constructor({ id, src, progress, isPaused, isBuffering, onStreamCanPlay, onStreamWaiting, onStreamStalled }: DAMStreamProps) {
    this.id = id
    this.src = src
    this._progress = progress
    this.isPaused = isPaused
    this.isBuffering = isBuffering
    this.speaker = null
    this.videoReferences = []
    this.onStreamCanPlay = onStreamCanPlay
    this.onStreamWaiting = onStreamWaiting
    this.onStreamStalled = onStreamStalled
  }

  addVideoReference(
    ref: MutableRefObject<HTMLVideoElement>,
    options: AddVideoReferenceOptions = {}
  ) {
    const { useToTrackTimeUpdate } = options
    if (useToTrackTimeUpdate) {
      ref.current.muted = false /* Unmute the video tag because we don't want to sync this with an audio tag */
      ref.current.ontimeupdate = (event) => {
        this._progress = (event.target as HTMLVideoElement).currentTime
      }
      ref.current.oncanplay = (event: Event) => this.onStreamCanPlay(this.id)
      ref.current.onwaiting = (event: Event) => this.onStreamWaiting(this.id)
      ref.current.onstalled = (event: Event) => this.onStreamStalled(this.id)
      this.mainReference = ref
    } else ref.current.muted = true

    if (!this.videoReferences.some((r) => r === ref)) {
      this.videoReferences.push(ref)
    }

    this.videoReferences.forEach((r) => {
      if (r.current) {
        r.current.src = this.src
        r.current.currentTime = this._progress
        if (this.isPaused || this.isBuffering) {
          this.pause()
        }
      }
    })

    const observer = new MutationObserver((mutations) => {
      for (let m of mutations) {
        if (m.type === 'attributes' && m.attributeName === 'src') {
          if ((m.target as HTMLVideoElement).src !== this.src) {
            this.videoReferences = this.videoReferences.filter((r) => r !== ref)
          }
        }
      }
    })
    observer.observe(ref.current, { attributes: true })

    this.videoReferences = this.videoReferences.filter((r) => r.current)
  }

  play() {
    this.videoReferences.forEach((r) => {
      r?.current?.play()
    })
    this.isPaused = false
  }

  pause() {
    this.videoReferences.forEach((r) => {
      r?.current?.pause()
    })
    this.isPaused = true
  }

  set progress(newProgress: number) {
    this._progress = newProgress
    this.videoReferences.forEach((r) => {
      if (r.current) {
        r.current.currentTime = newProgress
      }
    })
  }

  get progress() {
    return this._progress
  }

  updateAudioStatus(isMuted: boolean) {
    this.videoReferences.forEach((r) => {
      if (r === this.mainReference && r.current) {
        r.current.muted = isMuted
      }
    })
  }

  updateVolume(volume: number) {
    this.volume = volume
    this.videoReferences.forEach((r) => {
      if (r.current) {
        r.current.volume = this.volume
      }
    })
  }
}
