import { useHistory, useLocation } from 'react-router'
import { useQueryParams } from '../../../Common/hooks/useQueryParams'

const useRedirectUser = () => {
  const { redirect, ...everythingElse } = useQueryParams()
  const { pathname } = useLocation()
  const history = useHistory()

  const makeRedirectQuery = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('redirect', pathname)
    return searchParams.toString()
  }

  const saveRedirect = () => {
    history.push({ pathname: '/', search: makeRedirectQuery() })
  }

  const redirectAfterLogin = () => {
    if (redirect) {
      const query = new URLSearchParams()
      for (const [key, value] of Object.entries(everythingElse))
        query.append(key, (value as string) || '')
      window.location.href =
        window.location.protocol + '//'
        + window.location.host
        // prepend slash if redirect does not start with one
        + (redirect.length && redirect[0] !== '/' ? '/' : '')
        + redirect + '?'
        + query.toString()
    }
  }
  return { makeRedirectQuery, saveRedirect, redirectAfterLogin }
}

export default useRedirectUser
