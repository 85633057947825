import React, { createContext, useContext, useCallback, useMemo } from 'react'
import { useEnvironment } from './EnvironmentProvider'
import { SSODetails } from '../Models/apiEntities'
import { EvercastAuthService } from '../Auth/EvercastAuthService'
import { EvercastAuthServiceFactory } from '../Auth/EvercastAuthServiceFactory'

const EvercastAuthContext = createContext<EvercastAuthService | null>(null!)

interface EvercastAuthProviderProps {
  ssoDetails: SSODetails
  children: React.ReactNode
}

export const EvercastAuthProvider: React.FC<EvercastAuthProviderProps> = ({
  ssoDetails,
  children
}) => {
  const enviroment = useEnvironment()

  const getAuthService = useCallback(() => {
    const factory = new EvercastAuthServiceFactory(enviroment.sso)
    return factory.createAuthServiceSafe(ssoDetails)
  }, [enviroment, ssoDetails])

  const authService = useMemo(() => getAuthService(), [getAuthService])
  return <EvercastAuthContext.Provider value={authService}>{children}</EvercastAuthContext.Provider>
}

export function useEvercastAuthService() {
  return useContext(EvercastAuthContext)
}
