import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { noWhitespacePattern, useFormValidation } from '../../../Common/utils/formUtils'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'

const organizationCannotBeEmpty = 'Organization cannot be empty'
/**
 * Manage logic for the room create/update, form validation, handle api errors
 * @param onSuccess - callback called when login is successful
 */
export const useRoomForm = ({
  actions: { performUpdateMutation, performCreateMutation },
  room,
  isCreate,
}) => {
  const formMethods = useForm({ shouldFocusError: true, defaultValues: room })
  const { handleSubmit, control, reset, trigger } = formMethods

  useEffect(() => reset(room), [reset, room])

  const alert = useAlerts()

  const inputs = useFormValidation(formMethods, {
    organization: {
      required: organizationCannotBeEmpty,
      minLength: {
        value: 1,
        message: organizationCannotBeEmpty,
      },
    },
    displayName: {
      required: 'Please enter the room name',
      maxLength: {
        value: 80,
        message: 'Display name cannot exceed 80 characters',
      },
      pattern: {
        value: noWhitespacePattern,
        message: 'Display name cannot have only whitespace',
      },
    },
    isPublic: {
      required: 'Room type can not be empty',
    },
  })

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((formData) => {
        try {
          if (!formData.organization && isCreate) throw new Error(organizationCannotBeEmpty) // Why isn't this being caught by react-hook-form validation?
          const onSubmit = room?.id ? performUpdateMutation : performCreateMutation
          onSubmit({
            ...room,
            ...formData,
          })
        } catch (err) {
          if (err.message === organizationCannotBeEmpty)
            alert(organizationCannotBeEmpty, AlertType.error)
          else alert('Something went wrong', AlertType.error)
        }
      }),
    [handleSubmit, performCreateMutation, performUpdateMutation, room, alert, isCreate]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs,
    control,
    trigger,
  }
}
