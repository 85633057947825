import React from 'react'

import './Container.scss'

interface ContainerProps {
  component: React.ComponentType<any>
  children: any
}

/**
 * Generic container that handles paddings/margins for mobile/desktop
 */
const Container: React.FC<ContainerProps> = ({ component, children }) => {
  const Component = component

  return <Component className="container">{children}</Component>
}

export default React.memo(Container)
