import { useCallback } from 'react'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { logger } from '../../../../../../Common/log/Log'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { useSendDeactivationEmailTwoFactorMutation } from './useSendDeactivationEmailTwoFactorMutation'

const defaultErrorText = 'Error during requesting two factor authentication deactivation!'

export const useDeactivateEmailTwoFactor = () => {
  const alert = useAlerts()

  const [
    SendDeactivationEmailTwoFactorMutation,
    { data, error, graphQLErrors },
  ] = useSendDeactivationEmailTwoFactorMutation()
  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  useEffectNonNull(() => {
    if (!data.sendDeactivationEmailTwoFactor) {
      return
    }

    alert(
      'Please check your email for instructions on how to deactivate multi-factor authentication.',
      AlertType.success
    )
  }, [data])

  const sendTwoFactorDeactivateEmail = useCallback(async () => {
    try {
      await SendDeactivationEmailTwoFactorMutation({})
    } catch (e) {
      logger.error(e)
    }
  }, [SendDeactivationEmailTwoFactorMutation])
  return {
    sendTwoFactorDeactivateEmail,
  }
}
