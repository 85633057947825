import React, { useCallback, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

import { EnvironmentProvider } from './Providers/EnvironmentProvider'
import { RelayEnvironmentProvider } from './Providers/RelayEnvironmentProvider'
import { AxiosProvider } from './Providers/AxiosProvider'
import { AlertsProvider } from './Providers/AlertsProvider'
import { QueryProvider } from './Providers/QueryProvider'
import { RestApiProvider } from './Providers/RestApiProvider'
import { MenuTriggerProvider } from './Providers/MenuTriggerProvider'
import AppError from './Components/AppError/AppError'
import { getEnvName } from './Common/environments/getEnvName'
import { logger } from './Common/log/Log'

import MainView from './Views/MainView/MainView'
import createTheme from './Common/theme/theme'

import './App.scss'

const App: React.FC = () => {
  const theme = useMemo(createTheme, [])
  const envName = useMemo(getEnvName, [])

  return (
    <BrowserRouter>
      <EnvironmentProvider envName={envName}>
        <AxiosProvider>
          <RestApiProvider>
            <QueryProvider>
              <RelayEnvironmentProvider>
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <AlertsProvider>
                    <ErrorBoundary
                      FallbackComponent={AppError}
                      onError={useCallback((error) => {
                        logger.error(error)
                      }, [])}
                    >
                      <MenuTriggerProvider>
                        <MainView />
                      </MenuTriggerProvider>
                    </ErrorBoundary>
                  </AlertsProvider>
                </MuiThemeProvider>
              </RelayEnvironmentProvider>
            </QueryProvider>
          </RestApiProvider>
        </AxiosProvider>
      </EnvironmentProvider>
    </BrowserRouter>
  )
}

export default React.memo(App)
