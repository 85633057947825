import React from 'react'
import { Grid } from '@material-ui/core'
import { routes } from '../routes'
import { AppFeatureFlag } from '../../../../Models/apiEntities'
import { WithAppFeatureFlag } from '../../../../Common/utils/featureFlags'
import SingleSignOnForm from '../../../../Components/SingleSignOnForm/SingleSignOnForm'
import NotFoundRedirect from '../../NotFoundView/NotFoundRedirect'
import AdaptiveTitle from '../../../../Components/AdaptiveTitle/AdaptiveTitle'

/**
 * SingleSignOn View
 * This component os only rendered if the 'sso' feature flag is enabled, otherwise we show a not found page
 */
const SingleSignOnView: React.FC = () => {
  return (
    <WithAppFeatureFlag
      featureFlag={AppFeatureFlag.sso}
      renderNotPresent={() => <NotFoundRedirect />}
    >
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <AdaptiveTitle>Single sign-on</AdaptiveTitle>
        </Grid>
        <Grid item xs={12}>
        This only works if your organization uses Evercast single sign-on.
        </Grid>
        <Grid item xs={12}>
          <SingleSignOnForm loginRoutePath={routes.login.path} />
        </Grid>
      </Grid>
    </WithAppFeatureFlag>
  )
}

export default React.memo(SingleSignOnView)
