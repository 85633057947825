import React from 'react'

import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined'
import { Badge } from '@material-ui/core'
import ToggleIconButton, { ToggleIconButtonProps } from '../ToggleIconButton/ToggleIconButton'
import OutlinedToggleIconButton from '../OutlinedToggleIconButton/OutlinedToggleIconButton'
import styled from 'styled-components'

interface PeopleListButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
  hasNotification?: boolean
  participantCount: number
}

const StyledBadge = styled(Badge)`
  > .MuiBadge-badge {
    border: 1px solid black;
  }
`

const PeopleListButton: React.FC<PeopleListButtonProps> = ({
  noOutline = false,
  hasNotification = false,
  participantCount,
  isActive,
  ...otherProps
}) => {
  const Component = noOutline ? ToggleIconButton : OutlinedToggleIconButton

  return (
    <Component
      icon={PeopleOutlinedIcon}
      isActive={isActive}
      customRender={(innerElement) => {
        const InnerElement = innerElement
        return (
          <StyledBadge
            color={'secondary'}
            badgeContent={participantCount + 1}
            overlap="circle"
            invisible={false}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {InnerElement}
          </StyledBadge>
        )
      }}
      label="People"
      {...otherProps}
    />
  )
}

export default React.memo(PeopleListButton)
