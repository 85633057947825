import { useMemo, useCallback } from 'react'
import { Room, RoomRest, Message, UserProfile } from '../../Models/apiEntities'
import { logger } from '../log/Log'

const getApiPermissionsAdapter = (profileApiBasedPermissions: string[]) => {
  return {
    canCreateInvite() {
      return profileApiBasedPermissions?.includes('invite:create')
    },
    canCreateSession() {
      return profileApiBasedPermissions?.includes('session:create')
    },
    canClearMessages() {
      return profileApiBasedPermissions?.includes('chat:delete')
    }
  }
}

const isRoom = (obj: Room | Message | RoomRest): obj is Room => (obj as Room).displayName !== undefined

interface usePermissionsProp {
  profileApiBasedPermissions?: string[]
  defaultProfile?: UserProfile
}
/**
 * Hook for getting
 *
 * @param
 * @returns
 */
export const usePermissions = ({
  profileApiBasedPermissions = [],
  defaultProfile
}: usePermissionsProp) => {
  const apiPermissionsAdapter = useMemo(
    () => getApiPermissionsAdapter(profileApiBasedPermissions),
    [profileApiBasedPermissions]
  )

  const permissionsMap = useMemo(() => {
    return {
      room: {
        edit: (profile, room) => room.creatorId === profile.id,
        delete: (profile, room) => room.creatorId === profile.id,
        invite: (profile, room) =>
          room.creatorId === profile.id || apiPermissionsAdapter.canCreateInvite(),
        record: (profile, room) =>
          room.creatorId === profile.id || apiPermissionsAdapter.canCreateSession(),
        clearMessages: (profile, room) =>
          room.creatorId === profile.id || apiPermissionsAdapter.canClearMessages()
      },
      message: {
        edit: (profile, message) => message.profileId === profile.id,
        delete: (profile, message) => message.profileId === profile.id
      }
    }
  }, [apiPermissionsAdapter])

  const getPermissionsForObject = useCallback(
    (obj: Room | Message | RoomRest) => {
      if (isRoom(obj)) return permissionsMap.room
      else return permissionsMap.message
    },
    [permissionsMap]
  )

  const checkPermission = useCallback(
    <T1 extends Room | Message | RoomRest>({ who, run, on }: { who?: UserProfile; run: string; on: T1 }) => {
      if (!who && !defaultProfile) {
        const message =
          'checkPermissions cannot be used without who parameter or a default profile parameter'
        logger.error(message)
        throw new Error(message)
      }
      const objPermissions = getPermissionsForObject(on)
      const permissionSpecification = objPermissions[run]

      if (typeof permissionSpecification !== 'function') {
        logger.error(`Permission for operation: ${run} on object ${on} not found or incorrect`)
        return false
      }

      return permissionSpecification(who || defaultProfile, on) as boolean
    },
    [getPermissionsForObject, defaultProfile]
  )

  const WithPermission = useCallback(
    <T1 extends Room | Message | RoomRest>({
      children,
      who,
      run,
      on,
      fallback
    }: {
      children: React.ReactNode
      who?: UserProfile
      run: string
      on: T1
      fallback?: () => React.ReactNode
    }) => {
      if (!checkPermission({ who, run, on })) {
        return fallback ? fallback() : null
      }
      return children
    },
    [checkPermission]
  )

  return {
    checkPermission,
    WithPermission
  }
}
