import { useMutation } from '../../../Common/hooks/useMutation'
import UpdateNoteMutation from '../../../GraphQL/mutations/UpdateNoteMutation'

export const mapToVariable = ({
  id,
  content,
  sessionTime,
  deletedAt
}: {
  id: string
  content?: string
  sessionTime?: number
  deletedAt?: string
}) => ({
  input: {
    id,
    notePatch: {
      content,
      sessionTime,
      deletedAt
    }
  }
})

export const useUpdateNoteMutation = () => useMutation<any, any>(UpdateNoteMutation)
