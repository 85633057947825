import React from 'react'
import { Note } from '../../Models/apiEntities'
import { useNoteAction } from './hooks/useNoteAction'
import NotesForm from './NotesForm/NotesForm'
import NoteList from './NoteList/NoteList'
import NotesHeader from './NotesHeader/NotesHeader'
import { useRecording } from '../../Providers/RecordingProvider'
import { Box, Divider } from '@material-ui/core'
//import { useDialog } from '../RoomDialog/hooks/useDialog'
//import ClearNotesDialog from './ClearNotesDialog/ClearNotesDialog'
import { NoteDecisionInfo } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomPermissions'
import ChatMessageItem from './NoteList/NoteItem/NoteItem'
import NoteMenu from './NoteList/NoteItem/NoteMenu/NoteMenu'

interface NotesProps {
  defaultMessagesNumber?: number
  currentProfileId: string
  room: {
    id: string
    hash: string
  }
  messages: Note[]
  notesConnectionName: string
  relay: any
  isVisible: boolean
  notePermissionInfo: NoteDecisionInfo
}

const Notes: React.FC<NotesProps> = ({
  room,
  currentProfileId,
  defaultMessagesNumber = 10,
  messages,
  notesConnectionName,
  relay,
  isVisible,
  notePermissionInfo
}) => {
  const recordingInfo = useRecording()
  // const [isClearChatDialogOpen, openClearChatDialog, closeClearChatDialog] = useDialog()
  const { isInProgress, actions, selectedMessage, selectMessageToEdit } = useNoteAction({
    roomId: room.id,
    connectionName: notesConnectionName,
    recordingInfo
  })
  return (
    <>
      <Box className="chats-content">
        <NotesHeader
          notePermissionInfo={notePermissionInfo}
          doMessagesExist={Boolean(messages.length)}
        />
        {/*<NotesHeader
          onClearChatClicked={openClearChatDialog}
          notePermissionInfo={notePermissionInfo}
        />*/}
        <Divider />
        <NoteList pagination={{ ...relay, defaultMessagesNumber }} isVisible={isVisible}>
          {messages.map((note) => {
            const isAuthor = note.profileId === currentProfileId
            const handleSelectEditMessage = () => selectMessageToEdit(note)
            const handleDeleteMessage = () => actions.delete(note.id)

            return (
              <ChatMessageItem
                key={note.id}
                note={note}
                openRecordingLinksInNewTab
                menu={
                  isAuthor ? (
                    <NoteMenu
                      handleSelectEditMessage={handleSelectEditMessage}
                      handleDeleteMessage={handleDeleteMessage}
                    />
                  ) : null
                }
              />
            )
          })}
        </NoteList>
        <Divider />
        <NotesForm
          performCreateOrUpdate={actions.createOrUpdate}
          isInProgress={isInProgress}
          selectedMessage={selectedMessage}
          onDeselectMessage={() => selectMessageToEdit(undefined)}
        />
      </Box>
      {/* {isClearChatDialogOpen && (
        <ClearNotesDialog
          onClose={closeClearChatDialog}
          isInProgress={isInProgress}
          performClearAll={actions.clearAll}
        />
      )} */}
    </>
  )
}

export default React.memo(Notes)
