import React from 'react'
import { Typography, Box } from '@material-ui/core'
import AppleIcon from '@material-ui/icons/Apple'
import ProgressButton from '../ProgressButton/ProgressButton'

interface AppleTVNotifyProps {
  isApproved: boolean
  appleTVName: string
  onClose: () => void
}

const AppleTVNotify: React.FC<AppleTVNotifyProps> = ({ isApproved, appleTVName, onClose }) => {
  return (
    <>
      <Box marginTop={1}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'lighter'
          }}
        >
          <AppleIcon style={{ fontSize: '3.6rem' }} />
          Apple TV sign-in
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="h5" align="center" style={{ fontWeight: 'lighter' }} gutterBottom>
          {isApproved ? 'SUCCESS!' : 'CONNECTION FAILED!'}
        </Typography>
      </Box>
      <Box marginTop={3}>
        <Typography variant="body1" align="center" style={{ fontWeight: 'lighter' }} gutterBottom>
          {appleTVName + (isApproved ? ' is now entering the room.' : ' has been denied access.')}
        </Typography>
      </Box>
      <Box marginTop={5}>
        <ProgressButton
          type="submit"
          color="secondary"
          variant="contained"
          isLoading={false}
          fullWidth={true}
          style={{ marginRight: '20px' }}
          onClick={onClose}
          autoFocus
        >
          Close
        </ProgressButton>
      </Box>
    </>
  )
}

export default React.memo(AppleTVNotify)
