import React from 'react'
import { BaseDialog, DefaultDialogFooter, DefaultDialogHeader } from '../Dialogs'

interface DeniedDndFromRoomDialogProps {
  isOpen?: boolean
  onClose?: () => void
}

/**
 * Displays a DeniedDndFromRoomDialog. A dialog with a text explaining you were denied access to a dnd room
 * @constructor
 */
const DeniedDndFromRoomDialog: React.FC<DeniedDndFromRoomDialogProps> = ({
  isOpen = false,
  onClose,
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      disableEscapeKeyDown={true}
      onClose={onClose}
      header={
        <DefaultDialogHeader
            text={'The host did not grant you access'}
            divider
            onClose={onClose}
        ></DefaultDialogHeader>
      }
      footer={
          <DefaultDialogFooter
              onConfirm={onClose}
              confirmButtonText="Ok"
              confirmButtonProps={{
                  variant: 'contained',
                  color: 'secondary',
              }}
          />
      }
    >
      If you were invited to this room, please contact the person who invited you.
    </BaseDialog>
  )
}

export default React.memo(DeniedDndFromRoomDialog)
