import { useEffect, useState } from 'react'
import useApi from '../../../../../../API/useApi'
import { RoomAccess } from '../../../../../../Models/apiEntities'

interface useRoomInfoAndAccessProps {
  hash: string
}
export const useRoomInfoAndAccess = (props: useRoomInfoAndAccessProps) => {
  const { hash } = props
  const [isLoading, setIsLoading] = useState(true)
  const [hasCalled, setHasCalled] = useState(false)
  const { Room } = useApi()
  const [data, setData] = useState<RoomAccess | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const getRoomAccess = () => {
    setHasCalled(true)
    if (!isLoading) setIsLoading(true)
    Room.getRoomAccess(hash)
      .then((response) => {
        setData(response)
        setIsLoading(false)
      })
      .catch((e) => {
        if (e?.response) {
          const isNotFoundError = 404 === e?.response?.status;
          if (isNotFoundError) setErrorMessage(e.response?.data?.errors?.message)
        }
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isLoading && !hasCalled) {
      getRoomAccess()
    }
  }, [])

  return {
    isLoading,
    data,
    errorMessage,
    getRoomAccess
  }
}