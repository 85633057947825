import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi, { SpecialInviteValidationResponse } from '../../API/useApi'
import { useQueryParams } from '../../Common/hooks/useQueryParams'
import { useRoomNavigation } from '../../Views/MainView/AuthenticatedView/DashboardView/HomeView/RoomCard/hooks/useRoomNavigation'
import RoomInvalidInvite from '../../Views/MainView/AuthenticatedView/RoomView/RoomInvalidInvite/RoomInvalidInvite'
import RoomInviteUsed from '../../Views/MainView/AuthenticatedView/RoomView/RoomInviteUsed/RoomInviteUsed'

const AcceptSpecialInviteContainer: React.FC = () => {
  const [validationResponse, setValidationResponse] = useState({ valid: true, isUsed: false })
  const { inviteToken } = useParams<{ inviteToken: string }>()
  const { goToRoom } = useRoomNavigation()
  const { SpecialInvite } = useApi()
  const { roomId } = useQueryParams()

  useEffect(() => {
    (async () => {
      let validationResponse: SpecialInviteValidationResponse;
      if (roomId && roomId.length > 0) {
          validationResponse = await SpecialInvite.validateSpecial(roomId as string, { specialInviteCode: inviteToken })
  
          if (validationResponse && validationResponse.valid && !validationResponse.isUsed) {
              const [acceptResponse] = await SpecialInvite.accept(roomId as string, { specialInviteCode: inviteToken })
  
              if (acceptResponse && acceptResponse.room_hash) {
                  goToRoom({ hash: acceptResponse.room_hash })
                  return
              }
          }
  
          setValidationResponse(validationResponse)
          return
      }
  
      setValidationResponse({ valid: false, isUsed: false})
    })()
  }, [inviteToken, SpecialInvite, roomId, goToRoom])

  const renderCallback = useCallback(() => {
    if (validationResponse.valid && !validationResponse.isUsed) {
        return <></>
    } else if (validationResponse.isUsed) {
        return <RoomInviteUsed isLoggingOut={false}></RoomInviteUsed>
    }

    return <RoomInvalidInvite isLoggingOut={false}></RoomInvalidInvite>
  }, [validationResponse])

  return renderCallback()
}

export default React.memo(AcceptSpecialInviteContainer)
