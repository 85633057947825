/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlaybackChat_session = {
    readonly id: unknown;
    readonly messages: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: unknown;
                readonly sessionId: unknown | null;
                readonly sessionTime: number | null;
                readonly content: string;
                readonly createdAt: unknown;
                readonly deletedAt: unknown | null;
                readonly profileId: unknown;
                readonly profileDisplayName: string | null;
                readonly " $fragmentRefs": FragmentRefs<"ChatMessageItem_message">;
            } | null;
        }>;
    };
    readonly " $refType": "PlaybackChat_session";
};
export type PlaybackChat_session$data = PlaybackChat_session;
export type PlaybackChat_session$key = {
    readonly " $data"?: PlaybackChat_session$data;
    readonly " $fragmentRefs": FragmentRefs<"PlaybackChat_session">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaybackChat_session",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "messages",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "CREATED_AT_DESC"
          ]
        }
      ],
      "concreteType": "MessagesConnection",
      "kind": "LinkedField",
      "name": "messagesBySessionId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Message",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sessionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sessionTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deletedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profileId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profileDisplayName",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChatMessageItem_message"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "messagesBySessionId(orderBy:[\"CREATED_AT_DESC\"])"
    }
  ],
  "type": "Session",
  "abstractKey": null
};
})();
(node as any).hash = '4b9ef59f5369af17b7ad53778902d8be';
export default node;
