import { useMutation } from '../../Common/hooks/useMutation'
import SavePreferencesMutation from '../mutations/SavePreferencesMutation'

export function preferenceStoreUpdater(store) {
  const savePreferences = store.getRootField('savePreferences')
  const queryField = savePreferences.getLinkedRecord('query')
  const currentProfile = queryField.getLinkedRecord('currentProfile')
  const profilePreferences = currentProfile.getLinkedRecord('profilePreferences')
  const preferences = profilePreferences.getLinkedRecords('nodes')

  const rootField = store.getRoot()
  const oldCurrentProfile = rootField.getLinkedRecord('currentProfile')
  const oldProfilePreferences = oldCurrentProfile.getLinkedRecord('profilePreferences')

  oldProfilePreferences.setLinkedRecords(preferences, 'nodes')
}

export interface ProfilePreferencePatch {
  preferenceTypeId: string
  preferenceValue: string | null
}

export interface SavePreferencesInput {
  input: {
    preferencesToSet: ProfilePreferencePatch[]
  }
}

export function useSavePreferencesMutation() {
  return useMutation<SavePreferencesInput, any>(SavePreferencesMutation, {
    updater: preferenceStoreUpdater
  })
}
