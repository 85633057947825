import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../../Dialogs'
import AppleTVLogin from '../AppleTVLogin'

interface AppleTVLoginDialogProps {
  isOpen?: boolean
  classNames?: string
  handleClose?: () => void
  onSuccess?: () => void
  room: {
    id: string
    hash: string
  }
  withCondition?: boolean
}

const AppleTVLoginDialog: React.FC<AppleTVLoginDialogProps> = ({
  isOpen,
  handleClose,
  onSuccess,
  room,
  withCondition = true
}) => {
  if (!withCondition) return <></>
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="Apple TV sign-in" onClose={handleClose} divider />}
      footer={<DefaultDialogFooter />}
    >
      <AppleTVLogin room={room} onSuccess={onSuccess} />
    </BaseDialog>
  )
}

export default React.memo(AppleTVLoginDialog)
