import React from 'react'

import OutlinedToggleIconButton from '../../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import { ToggleIconButtonProps } from '../../../Buttons/ToggleIconButton/ToggleIconButton'
import { ReactComponent as Draw } from '../../../../Static/images/draw.svg'
import './DrawToggleButton.scss'

interface DrawToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
}

const DrawToggleButton: React.FC<DrawToggleButtonProps> = ({ ...otherProps }) => {
  return (
    <OutlinedToggleIconButton
      className="draw-toggle-button"
      data-testid="draw-toggle-button"
      icon={Draw}
      activeTooltipTitle="Show draw tool"
      inactiveTooltipTitle="Hide draw tool"
      label="Draw"
      {...otherProps}
    />
  )
}

export default React.memo(DrawToggleButton)
