import React from 'react'
import classnames from 'classnames'
import { Dialog as MUIDialog, DialogContent } from '@material-ui/core'
import Box from '@material-ui/core/Box'

interface BaseDialogProps {
  children?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  isOpen?: boolean
  classNames?: string
  onClose?: () => void
  onEnter?: () => void
  disableEscapeKeyDown?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  dividers?: boolean
  testId?: string | null
  width?: any
}

/**
 * Displays an Custom Dialog
 * @param children
 * @constructor
 */
const BaseDialog: React.FC<BaseDialogProps> = ({
  header,
  footer,
  isOpen = false,
  classNames,
  onClose,
  onEnter,
  disableEscapeKeyDown = false,
  maxWidth,
  fullWidth,
  dividers,
  children,
  testId
}) => {
  return (
    <MUIDialog
      data-testid={testId}
      open={isOpen}
      className={classnames(classNames)}
      onClose={onClose}
      onEnter={onEnter}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {header}

      {children && <DialogContent dividers={dividers}>{children}</DialogContent>}

      {footer && (
        <Box pt={1} pr={2} pb={1} pl={2}>
          {footer}
        </Box>
      )}
    </MUIDialog>
  )
}

export interface BaseDialogComponentsProps {
  children: React.ReactNode
  divider?: boolean
}

const MemoizedBaseDialog = React.memo(BaseDialog)
export { MemoizedBaseDialog as BaseDialog }
