import { map } from 'rxjs/operators'
import { JanusSignallingConnection } from '../../signalling/JanusSignallingConnection'
import { BrowserStreamPublisher } from './BrowserStreamPublisher'
import { streamNameSeparator } from './common'

export const browserStreamIdPrefix = 'browserShare|'
export const browserStreamNamePrefix = 'Browser stream'

export interface BrowserStreamClientParams {
  janusSignallingConnection: JanusSignallingConnection
  roomId: string
  roomHash: string
  userId: string
  displayName: string
}

/**
 * Browser share is treated as another liveroom user, with the difference that it doesn’t care about seeing/hearing other participants
 * That user is created with a browser share prefix.
 */
export class BrowserStreamClient {
  private publisher: BrowserStreamPublisher

  constructor(params: BrowserStreamClientParams) {
    const { janusSignallingConnection, roomId, roomHash, userId, displayName } = params

    this.publisher = new BrowserStreamPublisher({
      janusSignallingConnection,
      roomId,
      roomHash,
      userId: `${browserStreamIdPrefix}${userId}`,
      displayName: `${browserStreamNamePrefix}${streamNameSeparator}${displayName}`
    })

    this.publisher.joinRoom()
  }

  onJoined = () =>
    this.publisher.onJoined().pipe(map(() => this.publisher as BrowserStreamPublisher))

  onPublishError = () => this.publisher.onPublishError()

  leave = () => {
    this.publisher.leave()
  }
}
