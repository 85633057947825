import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DefaultDialog from '../../../../../Components/Dialogs/DefaultDialog/DefaultDialog'
import { MediaDevicesInfo } from '../Room/hooks/useMediaDevices'
import { MediaSelection, VideoQuality } from '../Room/hooks/useMediaSetup'
import MicrophoneTester from '../Room/MicrophoneTester/MicrophoneTester'
import StreamPlayer from '../Room/StreamPlayer/StreamPlayer'
import SpeakerIcon from '@material-ui/icons/Speaker'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '../../../../../Components/Menu/Menu'
import testSound from '../../../../../Common/sounds/test.mp3'
import { useSoundPlayer } from '../../../../../Common/hooks/useSoundPlayer'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CardSubtitle from '../../../../../Components/CardSubtitle/CardSubtitle'
import { useDeviceSelection } from './hooks/useDeviceSelection'
import { usePPTShortcut } from './hooks/usePTTShortcut'
import './DeviceSelectionDialog.scss'
import { RtcStatSummary } from '../Room/LiveRoom/hooks/useClientsideDiagnostics'
import ClientsideDiagnosticsDialog from './ClientsideDiagnosticsDialog'
import { useDialog } from '../../../../../Components/RoomDialog/hooks/useDialog'
import { ReactComponent as CSDIcon } from '../../../../../Static/images/clientsidediagnostics.svg'
import palette from '../../../../../Common/theme/colors/palette'
import { useBrowserCapabilities } from '../../../hooks/useBrowserCapabilities'
import useApi from '../../../../../API/useApi'
import { RoomFeatureFlag } from '../../../../../Models/apiEntities'

interface DeviceSelectionDialogProps {
  isOpen?: boolean
  currentMediaSelection: MediaSelection
  mediaDevices: MediaDevicesInfo
  onUpdate: (mediaSelection: MediaSelection) => void
  onClose: () => void
  setCSD?: (value: boolean) => void
  isCSDEnabled?: boolean
  csdResults: RtcStatSummary | null
  roomId: string
}

const DeviceSelectionDialog: React.FC<DeviceSelectionDialogProps> = ({
  isOpen,
  currentMediaSelection,
  mediaDevices,
  onUpdate,
  onClose,
  setCSD,
  isCSDEnabled,
  csdResults,
  roomId,
}) => {
  const {
    browserCapabilities: { isSafari },
  } = useBrowserCapabilities() // Safari doesn't support 'audiooutput' devices as of yet, so mediaDevices.speaker.devices will always be empty on Safari

  const {
    camera,
    setCamera,
    microphone,
    setMicrophone,
    speaker,
    setSpeaker,
    cameraQuality,
    setCameraQuality,
    videoStream,
    audioStream,
  } = useDeviceSelection({ currentMediaSelection })
  const [isPlayingTestSound, playTestSound] = useSoundPlayer(testSound, speaker)
  const { PPTShortcut, setPPTShortcut, PPTKeyOptions } = usePPTShortcut({
    currentMediaSelection,
  })

  // build new media selection
  const handleSave = useCallback(() => {
    onClose()
    onUpdate({
      camera,
      microphone,
      speaker,
      cameraQuality,
      PPTShortcut,
    })
  }, [camera, microphone, speaker, cameraQuality, onUpdate, onClose, PPTShortcut])

  const cameraQualityOptions = useMemo(
    () => [
      { label: 'Low', value: VideoQuality.low },
      { label: 'Medium', value: VideoQuality.medium },
      { label: 'High (High bandwidth)', value: VideoQuality.high },
    ],
    []
  )

  // this checks if the room is in Theater mode
  const { Room } = useApi();
  const [isRoomViewOnlyEnabled, setIsRoomViewOnlyEnabled] = useState<boolean>(false);

  const updateEnabledFeatures = async () => {
    try {
      const features = await Room.getFeatures(roomId)
      setIsRoomViewOnlyEnabled(features[RoomFeatureFlag.allowRoomViewOnly].value === 'true')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    updateEnabledFeatures()
  }, [roomId])

  const safariMessage = `Audio is set to \'Default device\' by this browser. You can change it in your computer\'s system preferences.`;

  const renderDevicesSelect = (
    label: string,
    devices: MediaDeviceInfo[],
    setter: (mediaDeviceInfo) => void,
    current: MediaDeviceInfo | null,
    defaultDevice: MediaDeviceInfo | null,
    isRoomViewOnlyEnabled: boolean,
  ) => (
    <FormControl variant="filled" fullWidth disabled={devices.length === 0}>
      <InputLabel>{label}</InputLabel>
      <Tooltip title={
        devices.length <= 0 && label === 'Audio' && isSafari ? safariMessage : ''
      }>
        <Select value={!isSafari 
          ? current 
            ? current.deviceId 
            : 'none' 
          : current 
            ? devices.some((device) => device.deviceId === current.deviceId)
              ? current.deviceId
              : defaultDevice && !isRoomViewOnlyEnabled
                ? defaultDevice.deviceId 
                : 'default'
            : 'default'
        }>
          <MenuItem
            key={isSafari ? 'Default device' : 'none'}
            value={isSafari ? 'default' : 'none'}
            onClick={() => setter(undefined)}
          >
            {isSafari && label === 'Audio' ? 'Default device' : 'None'}  
          </MenuItem>
          {devices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId} onClick={() => setter(device)}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      {devices.length === 0 && (!isSafari || isSafari && label !== 'Audio') && !isRoomViewOnlyEnabled && <FormHelperText>No Device Detected</FormHelperText>}
      {devices.length === 0 && (!isSafari || isSafari && label !== 'Audio') && isRoomViewOnlyEnabled && <FormHelperText>Unavailable in theater mode</FormHelperText>}
    </FormControl>
  )

  const PPTShortcutSelect = (
    <FormControl variant="filled" fullWidth>
      <InputLabel>Push-to-talk shortcut</InputLabel>
      <Select value={PPTShortcut}>
        {Object.entries(PPTKeyOptions).map(([key, value]) => (
          <MenuItem key={key} value={value} onClick={() => setPPTShortcut(value)}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const menuOptions = useMemo(
    () => [
      {
        text: 'Performance Tracking On',
        onClick: () => setCSD && setCSD(true),
      },
      {
        text: 'Performance Tracking Off',
        onClick: () => setCSD && setCSD(false),
      },
    ],
    [setCSD]
  )

  const csdStyles = {
    status: {
      margin: '15px 0px 0px 3px',
      width: '25px',
      height: 'auto',
    },
    button: {
      marginTop: '8px',
    },
  }

  const useStyles = makeStyles(csdStyles)
  const classes = useStyles()

  const [isDialogOpen, openDialog, closeDialog] = useDialog()

  return (
    <DefaultDialog title="Device settings" isOpen={isOpen} onClose={onClose} maxWidth={'md'}>
      <Grid container spacing={3} style={{ maxWidth: 780 }}>
        <Grid item sm={7} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="device-selection-preview-section">
              {camera ? (
                <StreamPlayer
                  className="device-selection-preview"
                  stream={videoStream}
                  isMuted={true}
                />
              ) : (
                <Box className="device-selection-camera-not-selected">
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Box fontSize={65}>
                        <ErrorOutlineIcon fontSize="inherit" color="secondary" />
                      </Box>
                    </Grid>
                    <Grid item>
                      <CardSubtitle>
                        {mediaDevices.camera.hasPermission
                          ? 'No webcam selected'
                          : 'Camera is blocked'}
                      </CardSubtitle>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Camera quality</Typography>
              <RadioGroup row defaultValue="top" value={cameraQuality}>
                {cameraQualityOptions.map(({ label, value }) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio size="small" color="secondary" />}
                    label={<Typography variant="body2">{label}</Typography>}
                    onClick={() => setCameraQuality(value)}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <MicrophoneTester
                label={<Typography variant="body2">Microphone level</Typography>}
                stream={audioStream}
              />
            </Grid>
            {setCSD && (
              <Grid container>
                <Grid item xs={1}>
                  <Menu
                    menuOptions={menuOptions}
                    button={(openMenu) => (
                      <CSDIcon
                        color={isCSDEnabled ? 'secondary' : palette.background.default}
                        className={classes.status}
                        onClick={openMenu}
                      />
                    )}
                  />
                </Grid>
                {isCSDEnabled && (
                  <Grid item xs={10}>
                    <Button onClick={openDialog} className={classes.button}>
                      View Details
                    </Button>
                  </Grid>
                )}
                <ClientsideDiagnosticsDialog
                  csdResults={csdResults}
                  openDialog={isDialogOpen}
                  closeDialog={closeDialog}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sm={5} xs={12}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderDevicesSelect(
                    'Camera', 
                    mediaDevices.camera.devices, 
                    setCamera, 
                    camera,
                    mediaDevices.camera.default,
                    isRoomViewOnlyEnabled,
                  )}
                </Grid>
                <Grid item xs={12}>
                  {renderDevicesSelect(
                    'Microphone',
                    mediaDevices.microphone.devices,
                    setMicrophone,
                    microphone,
                    mediaDevices.microphone.default,
                    isRoomViewOnlyEnabled,
                  )}
                </Grid>
                <Grid container item xs={12}>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box marginLeft={-2.4}>
                      <IconButton
                        aria-label="close"
                        onClick={playTestSound}
                        color="secondary"
                        disabled={(!speaker && !isSafari) || isPlayingTestSound}
                        title="Test audio"
                      >
                        <SpeakerIcon fontSize="large" />
                      </IconButton>
                    </Box>
                    {renderDevicesSelect(
                      'Audio',
                      mediaDevices.speaker.devices,
                      setSpeaker,
                      speaker,
                      mediaDevices.speaker.default,
                      isRoomViewOnlyEnabled,
                    )}
                  </Box>
                </Grid>
                <Grid container item xs={12}>
                  <Box display="flex" flexDirection="row" width="100%">
                    {PPTShortcutSelect}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop="auto">
              <Box marginTop={3}>
                <Grid container alignItems="flex-end" justify="flex-end">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DefaultDialog>
  )
}

export default React.memo(DeviceSelectionDialog)
