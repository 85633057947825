import { useMemo, /* useCallback,*/ useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  /* useCustomValidation,*/ useFormValidation
} from '../../../../../../../Common/utils/formUtils'
// import { useEnvironment } from '../../../../../../../Providers/EnvironmentProvider'

export const useGuestLoginForm = ({ performGuestLogin }) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods
  const progressBtnRef = useRef<HTMLButtonElement | null>()

  const inputs = useFormValidation(formMethods, {
    displayName: {
      required: 'Please enter a display name'
    },
    email: {
      required: 'Please enter a valid email address',
      validate: (email) => {
        const emailValidationRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        if (!emailValidationRegex.test(email)) {
          return 'Please enter a valid email address'
        }
        return true
      }
    }
  })

  const handleLoginClick = useMemo(
    () =>
      handleSubmit(async ({ displayName, email, /* captcha */ }) => {
        performGuestLogin({ displayName, email, /* captcha */ })
      }),
    [performGuestLogin, handleSubmit]
  )

  return {
    inputs,
    actions: {
      handleLoginClick
    },

    progressBtnRef
  }
}
