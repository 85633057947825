import { useCallback, useState, Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../../../../../API/useApi'

interface UseParamsHash {
  hash: string
}

interface VerifyMFA {
  isVerified: boolean
  errorMessage: string
}

export type VerifyMFAFunction = (mfaCode: string) => Promise<VerifyMFA>
export type SetMFAFunction = (password: string) => Promise<any>
export type SetIsMFATokenSetFunction = Dispatch<SetStateAction<boolean>>

interface RoomLevelMFA {
  isMFAValid: boolean
  verifyMFA: VerifyMFAFunction
}

export const useRoomLevelMFA = (): RoomLevelMFA => {
  const { AuthAccount } = useApi()
  const { hash } = useParams<UseParamsHash>()
  const { roomId } = useParams<{ roomId: string }>()
  const [isMFAValid, setIsMFAValid] = useState(false)

  const verifyMFA = useCallback(
    async (mfaCode: string) => {
      let isVerified = false
      let errorMessage = ''
      try {
        if (!mfaCode) errorMessage = 'Enter an MFA code.'
        const { isVerified: _isVerified } = await AuthAccount.verifyRoomMfa(
          hash || roomId,
          mfaCode as string
        )
        setIsMFAValid(_isVerified)
        if (!_isVerified) errorMessage = 'Invalid MFA code. Please try again.'
      } catch (err) {
        setIsMFAValid(false)
        errorMessage = 'Invalid MFA code. Please try again.'
        if (err.isAxiosError) {
          errorMessage =
            err?.response?.data?.errors?.message?.split('-')?.[1]?.trim() || errorMessage
        }
      }

      return { isVerified, errorMessage }
    },
    [AuthAccount, hash, roomId]
  )

  return { isMFAValid, verifyMFA }
}
