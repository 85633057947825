import { useCallback, useState } from 'react'
import { useQueryParams } from '../../../Common/hooks/useQueryParams'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import useApi from '../../../API/useApi'

export const ERROR_CODES = {
  '07110': 'Reset password token is invalid',
  '07120': 'Reset password token has expired',
  '07130': 'Reset password token has already been used',
  '07160': 'Invalid credentials',
  '07170': 'Password cannot be empty',
  '07180': 'Passwords do not match',
}

const defaultErrorText = 'There was an error while changing the password! Try again later.'

export enum ChangePasswordState {
  awaiting,
  submitting,
  success,
}

export const useChangePassword = (onComplete?: (redirect: boolean) => void) => {
  const { token } = useQueryParams()
  const [state, setState] = useState(ChangePasswordState.awaiting)
  const alert = useAlerts()
  const { AuthAccount } = useApi()

  const changePassword = useCallback(
    async ({ password }) => {
      try {
        setState(ChangePasswordState.submitting)
        await AuthAccount.resetPassword({
          requestToken: token as string,
          newPassword: password,
        })
        setState(ChangePasswordState.success)
      } catch (e) {
        console.error(e)
        const msgFromServer = e?.response?.data?.errors?.message
        let message = defaultErrorText
        if (msgFromServer) {
          for (const [code] of Object.entries(ERROR_CODES)) {
            if (msgFromServer.includes(code)) message = msgFromServer.replace(`${code} - `, '')
          }
        }
        alert(message, AlertType.error)
        setState(ChangePasswordState.awaiting)
      }
    },
    [token, AuthAccount, alert]
  )

  return {
    state,
    changePassword,
  }
}
