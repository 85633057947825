import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16.09 10.385C16.7126 9.7963 17.1441 9.03416 17.3284 8.19736C17.5128 7.36055 17.4415 6.48767 17.1239 5.69183C16.8063 4.89599 16.257 4.21389 15.5472 3.73393C14.8374 3.25398 13.9997 2.99829 13.1429 3.00001H6.57143C6.41988 3.00001 6.27453 3.06021 6.16737 3.16738C6.0602 3.27454 6 3.41988 6 3.57144V4.14287C6 4.29442 6.0602 4.43976 6.16737 4.54693C6.27453 4.65409 6.41988 4.71429 6.57143 4.71429H7.14286V17.2857H6.57143C6.41988 17.2857 6.27453 17.3459 6.16737 17.4531C6.0602 17.5602 6 17.7056 6 17.8571V18.4286C6 18.5801 6.0602 18.7255 6.16737 18.8326C6.27453 18.9398 6.41988 19 6.57143 19H14C15.2124 19 16.3752 18.5184 17.2325 17.6611C18.0898 16.8038 18.5714 15.641 18.5714 14.4286C18.5714 12.6611 17.5578 11.1457 16.09 10.385ZM9.42857 4.71429H12.5714C13.2534 4.71429 13.9075 4.98521 14.3897 5.46745C14.8719 5.94968 15.1429 6.60374 15.1429 7.28572C15.1429 7.96771 14.8719 8.62176 14.3897 9.10399C13.9075 9.58623 13.2534 9.85715 12.5714 9.85715H9.42857V4.71429ZM13.4286 17.2857H9.42857V11.5714H13.4286C14.1863 11.5714 14.9131 11.8725 15.4489 12.4083C15.9847 12.9441 16.2857 13.6708 16.2857 14.4286C16.2857 15.1863 15.9847 15.9131 15.4489 16.4489C14.9131 16.9847 14.1863 17.2857 13.4286 17.2857Z" />
  </SvgIcon>
)

export default Icon
