import GetSSODetailsQuery from '../../../GraphQL/queries/GetSSODetailsQuery'
import { useQuery } from '../../../Common/hooks/useQuery'
import { useCallback, useMemo } from 'react'
import { useEnvironment } from '../../../Providers/EnvironmentProvider'
import { EvercastAuthServiceFactory } from '../../../Auth/EvercastAuthServiceFactory'

export class UnableToGetSSODetailException extends Error {
  innerException: Error
  constructor(email, innerException) {
    super(`Unable to obtain SSO details for given email: ${email}!`)
    this.name = this.constructor.name
    this.innerException = innerException
  }
}

/**
 * Abstraction layer for getSSODetails function returning already created evercast service or throw exception
 * @returns {{getEvercastAuthService: getEvercastAuthService}}
 */
export function useEvercastAuthService() {
  const query = useQuery()
  const { sso } = useEnvironment()

  const getSSODetails = useCallback(
    async (email: string) => {
      const variables = { input: { email } }
      try {
        const { getSSODetails } = await query<any>(GetSSODetailsQuery, variables)
        return getSSODetails
      } catch (err) {
        throw new UnableToGetSSODetailException(email, err)
      }
    },
    [query]
  )

  const getAuthServiceFactory = useMemo(() => {
    return new EvercastAuthServiceFactory(sso)
  }, [sso])

  async function getEvercastAuthService(email: string) {
    const ssoDetails = await getSSODetails(email)
    return getAuthServiceFactory.createAuthService(ssoDetails, email)
  }

  return {
    getEvercastAuthService
  }
}
