import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { History } from '@material-ui/icons'

import ProgressButton from '../../ProgressButton/ProgressButton'
import { useChatMessageList } from './hooks/useChatMessageList'

import './ChatMessageList.scss'

interface ChatMessageListProps {
  pagination: { hasMore; isLoading; loadMore; defaultMessagesNumber: number }
  isVisible: boolean
  children: ReactNode[]
}

const ChatMessageList: React.FC<ChatMessageListProps> = ({
  pagination: { hasMore, isLoading, loadMore, defaultMessagesNumber },
  isVisible,
  children
}) => {
  const {
    loadMoreMessages,
    isLoadingMoreMessages,
    canLoadMore,
    messageEndRef
  } = useChatMessageList({
    messagesCount: children.length,
    defaultMessagesNumber,
    isVisible,
    hasMore,
    isLoading,
    loadMore
  })

  return (
    <Box className="chat-message-list">
      {canLoadMore && (
        <div className="chat-load-more">
          <ProgressButton
            size="small"
            color="primary"
            aria-label="load-more"
            onClick={loadMoreMessages}
            isLoading={isLoadingMoreMessages}
          >
            <History />
            Load More...
          </ProgressButton>
        </div>
      )}
      {children}
      <div ref={messageEndRef} style={{ display: 'block' }} />
    </Box>
  )
}

export default React.memo(ChatMessageList)
