/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteSubInput = {
    roomId?: string | null;
};
export type NotesSubscriptionVariables = {
    input: NoteSubInput;
};
export type NotesSubscriptionResponse = {
    readonly notes: {
        readonly note: {
            readonly id: unknown;
            readonly sessionId: unknown | null;
            readonly sessionTime: number | null;
            readonly content: string;
            readonly createdAt: unknown;
            readonly deletedAt: unknown | null;
            readonly profileId: unknown;
            readonly profileDisplayName: string | null;
            readonly " $fragmentRefs": FragmentRefs<"NoteItem_note">;
        } | null;
        readonly event: string | null;
    } | null;
};
export type NotesSubscription = {
    readonly response: NotesSubscriptionResponse;
    readonly variables: NotesSubscriptionVariables;
};



/*
subscription NotesSubscription(
  $input: NoteSubInput!
) {
  notes(input: $input) {
    note {
      ...NoteItem_note
      id
      sessionId
      sessionTime
      content
      createdAt
      deletedAt
      profileId
      profileDisplayName
    }
    event
  }
}

fragment NoteItem_note on Note {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileDisplayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NoteSubPayload",
        "kind": "LinkedField",
        "name": "notes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NoteItem_note"
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NoteSubPayload",
        "kind": "LinkedField",
        "name": "notes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d64b8916331145ac6ffe1fabac422eb2",
    "id": null,
    "metadata": {},
    "name": "NotesSubscription",
    "operationKind": "subscription",
    "text": "subscription NotesSubscription(\n  $input: NoteSubInput!\n) {\n  notes(input: $input) {\n    note {\n      ...NoteItem_note\n      id\n      sessionId\n      sessionTime\n      content\n      createdAt\n      deletedAt\n      profileId\n      profileDisplayName\n    }\n    event\n  }\n}\n\nfragment NoteItem_note on Note {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n"
  }
};
})();
(node as any).hash = 'c4f0d4885dcc24c8fb7c3694a155dcfc';
export default node;
