import { graphql } from 'babel-plugin-relay/macro'

const UpdateRoomMutation = graphql`
  mutation UpdateRoomMutation($input: UpdateLiveroomByIdInput!) {
    updateLiveroomById(input: $input) {
      clientMutationId
      liveroom {
        id
        hash
        displayName
        isPublic
      }
      profileByCreatorId {
        id
        displayName
      }
    }
  }
`

export default UpdateRoomMutation
