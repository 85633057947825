import React, { createContext, useContext } from 'react'
import { UserFeatureFlag, FeatureFlag } from '../Models/apiEntities'

const userFeatureFlagsContext = createContext<FeatureFlag<UserFeatureFlag>[]>([])

interface UserProviderFeatureFlagsProps {
  featureFlags: FeatureFlag<UserFeatureFlag>[]
}

export const UserFeatureFlagsProvider: React.FC<UserProviderFeatureFlagsProps> = ({
  featureFlags,
  children
}) => {
  return (
    <userFeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </userFeatureFlagsContext.Provider>
  )
}

export const useUserFeatureFlags = () => {
  return useContext(userFeatureFlagsContext)
}
