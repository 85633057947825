import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useMemo } from 'react'

/**
 * return query params
 */
export const useQueryParams = () => {
  const { search } = useLocation()
  // return query params
  return useMemo(() => {
    return search ? qs.parse(search) : {}
  }, [search])
}
