import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../Dialogs'

interface RemovedFromRoomDialogProps {
  roomName: string
  isOpen?: boolean
  onClose?: () => void
}

/**
 * Displays a ConfirmationDialog. A dialog with a text header and only two options (yes or no)
 * @constructor
 */
const RemovedFromRoomDialog: React.FC<RemovedFromRoomDialogProps> = ({
  roomName,
  isOpen = false,
  onClose,
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      disableEscapeKeyDown={true}
      onClose={onClose}
      header={
        <DefaultDialogHeader
            text={'Remove from room'}
            divider
            onClose={onClose}
        ></DefaultDialogHeader>
      }
      footer={
          <DefaultDialogFooter
              onConfirm={onClose}
              confirmButtonText="Ok"
              confirmButtonProps={{
                  variant: 'contained',
                  color: 'secondary',
              }}
          />
      }
    >
      You've been removed from the room{roomName ? ' ' + roomName : ''}. Please contact the room owner before re-entering
      the room.
    </BaseDialog>
  )
}

export default React.memo(RemovedFromRoomDialog)
