import { Box, Fade } from '@material-ui/core'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Animations } from '../../Common/constants/animations'

interface FadingRouteProps extends RouteProps {
  path: string
  fadeIn?: boolean
}

const FadingRoute: React.FC<FadingRouteProps> = ({ children, fadeIn, ...routeProps }) => {
  return (
    <Route {...routeProps}>
      <Fade
        in={fadeIn !== undefined ? fadeIn : true}
        key={routeProps.path}
        timeout={Animations.fast}
      >
        <Box width="100%" height="100%">
          {children}
        </Box>
      </Fade>
    </Route>
  )
}

export default FadingRoute
