import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 3C7.58065 3 4 6.58065 4 11C4 15.4194 7.58065 19 12 19C16.4194 19 20 15.4194 20 11C20 6.58065 16.4194 3 12 3ZM12 17.4516C8.43548 17.4516 5.54839 14.5645 5.54839 11C5.54839 7.43548 8.43548 4.54839 12 4.54839C15.5645 4.54839 18.4516 7.43548 18.4516 11C18.4516 14.5645 15.5645 17.4516 12 17.4516Z" />
  </SvgIcon>
)

export default Icon
