import React, { createContext, useContext } from 'react'
import {
  RTCConnectivityStats,
  useGetRTCConnectivity,
} from '../Components/NetworkConnectivity/hooks/useGetRTCConnectivity'

interface ConnectivityContext {
  currentRTCConnectivityStats: RTCConnectivityStats | undefined
}

const connectivityContext = createContext<ConnectivityContext | null>(null)

export const ConnectivityProvider = (props) => {
  const { children, publisher, participants } = props
  const { currentRTCConnectivityStats } = useGetRTCConnectivity(publisher, participants, true)

  return (
    <connectivityContext.Provider value={{ currentRTCConnectivityStats }}>
      {children}
    </connectivityContext.Provider>
  )
}

export const useConnectivityContext = () => {
  const context = useContext(connectivityContext)

  if (context === null)
    throw new Error('useConnectivityContext must be used within a ConnectivityProvider')

  return context
}
