import React, { useCallback, useState } from 'react'

import { useQrImage } from './TwoFactorConfigurationQRCodeConfig/hooks/useQrImage'
import TwoFactorConfigurationMessage from './TwoFactorConfigurationMessage/TwoFactorConfigurationMessage'
import TwoFactorConfigurationQRCode from './TwoFactorConfigurationQRCodeConfig/TwoFactorConfigurationQRCodeConfig'
import TwoFactorConfigurationValidEmailSuccess
  from './TwoFactorConfigurationValidEmailSuccess/TwoFactorConfigurationValidEmailSuccess'
import { ConfigurationMessageState } from './TwoFactorConfigurationMessage/hooks/useTwoFactorConfigurationMessageProps'

export enum TwoFactorConfigurationVerificationState {
  awaiting,
  stepOne,
  stepTwo,
  success,
  error,
  submitting,
  verifying,
}

/**
 * Evercast verify two factor view
 * @constructor
 */
const TwoFactorConfigurationView: React.FC = () => {
  const [step, setStep] = useState(TwoFactorConfigurationVerificationState.stepOne)
  const barcodeUri = useQrImage()

  const updateStep = useCallback((verificationState: TwoFactorConfigurationVerificationState) => {
    setStep(verificationState)
  }, [])

  if (step === TwoFactorConfigurationVerificationState.stepTwo) {
    return (
      <TwoFactorConfigurationQRCode
        barcodeUri={barcodeUri}
        handleContinue={updateStep}
      />
    )
  }

  if (step === TwoFactorConfigurationVerificationState.success) {
    return <TwoFactorConfigurationMessage state={ConfigurationMessageState.CODE_SUCESS} />
  }

  return <TwoFactorConfigurationValidEmailSuccess handleContinue={updateStep} />
}

export default React.memo(TwoFactorConfigurationView)
