import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import QueryRenderer from '../../../../../GraphQL/QueryRenderer'
import PlaybackChat from './PlaybackChat'
import { Box, Typography } from '@material-ui/core'

export const PlaybackChatContainerQuery = graphql`
  query PlaybackChatContainerQuery($sessionId: UUID!) {
    session: sessionById(id: $sessionId) {
      ...PlaybackChat_session
    }
  }
`

interface PlaybackChatContainerProps {
  sessionId?: string
}

export const PlaybackChatContainer: React.FC<PlaybackChatContainerProps> = ({ sessionId }) => {
  if (!sessionId) {
    return (
      <Box padding={2} alignItems="center" justifyContent="center">
        <Typography variant={'h6'} align="center">
          No recording selected.
        </Typography>
      </Box>
    )
  }

  return (
    <QueryRenderer
      query={PlaybackChatContainerQuery}
      variables={{ sessionId }}
      renderResult={({ session }) => <PlaybackChat session={session} />}
    />
  )
}
