import { setStereoSDP } from '../../SDPHelper'

import { MessageType } from '../../plugin/JanusClient'
import { BaseSharePublisher } from './BaseSharePublisher'

/**
 * Browser share is treated as another liveroom user, with the difference that it doesn’t care about seeing/hearing other participants
 */
export class BrowserStreamPublisher extends BaseSharePublisher {
  constructor({ janusSignallingConnection, roomId, roomHash, userId, displayName }) {
    super({
      janusSignallingConnection,
      roomId,
      roomHash,
      userId,
      displayName,
      tag: 'Browser Stream'
    })
  }

  /**
   * Publish media for browser share 'user'.
    @param video - input video source choosen in BrowserStreamDialog
    @param audio - input audio source choosen in BrowserStreamDialog
    @param bitrate
   */
  innerPublish = async ({
    audio,
    video,
    bitrate
  }: {
    audio: MediaTrackConstraints | null
    video: MediaTrackConstraints | null
    bitrate: number
  }) => {
    const jsep = await this.janusClient.createOffer({
      customizeSdp: (jsepToCustomize) => {
        jsepToCustomize.sdp = setStereoSDP(jsepToCustomize.sdp)
        console.log(`[${this.tag}] Customizing SDP for Browser Stream`, jsepToCustomize.sdp)
      },
      media: {
        audioRecv: false,
        videoRecv: false,
        audioSend: !!audio,
        videoSend: !!video,
        video: video,
        audio: audio
      },
      simulcast: false
    })

    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        audio: !!audio,
        video: !!video,
        bitrate: bitrate
      },
      jsep
    })
  }

  protected sendStopMessage = async () => {
    const stopBrowserStream = {
      id: this.userId,
      request: MessageType.stopBrowserStream,
      room: this.roomId,
      roomHash: this.roomHash
    }
    await this.janusClient.sendMessage({
      message: stopBrowserStream
    })
  }
}
