import React, { ReactNode, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import './Formset.scss'

const InputComponent = ({ inputRef, ...props }) => <div {...props} />

interface FormsetProps {
  children: ReactNode
}

const Formset: React.FC<FormsetProps> = ({ children }) => {
  const [legend, setLegend] = useState('')

  useEffect(() => {
    const childrenArray = Array.isArray(children) ? children : [children]

    childrenArray.forEach((child) => {
      const c = child as any
      if (c.type === 'legend') {
        setLegend(c.props.children)
      }
    })
  }, [children])

  return (
    <TextField
      variant="outlined"
      label={legend}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{ inputComponent: InputComponent }}
      inputProps={{ children: children }}
      className="sso-login"
    />
  )
}

export default React.memo(Formset)
