import { useMutation } from '../../../Common/hooks/useMutation'
import AttachDeviceLoginCodeMutation from '../../../GraphQL/mutations/AttachDeviceLoginCodeMutation'

export interface AppleTVFormInput {
  code: string
}

const createConfig = (onSuccess?: () => void) => {
  return {
    onCompleted: (response, errors) => {
      if (onSuccess && errors === null) onSuccess()
    }
  }
}

export const useLoginWithAppleTVMutation = (onSuccess?: () => void) =>
  useMutation<any, any>(AttachDeviceLoginCodeMutation, createConfig(onSuccess))

export const mapToVariables = (roomId: string, code: string) => ({
  roomId,
  code
})
