import { useCallback } from 'react'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { useSendActivationEmailTwoFactorMutation } from './useSendActivationEmailTwoFactorMutation'
import { logger } from '../../../../../../Common/log/Log'

const defaultErrorText = 'Error during requesting two factor authentication!'

export const useTwoFactor = () => {
  const alert = useAlerts()

  const [
    SendActivationEmailTwoFactorMutation,
    { data, error, graphQLErrors },
  ] = useSendActivationEmailTwoFactorMutation()

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  useEffectNonNull(() => {
    console.log(data)
    if (!data.sendActivationEmailTwoFactor) {
      return
    }

    alert(
      'Please check your email for instructions on how to activate multi-factor authentication.',
      AlertType.success
    )
  }, [data])

  const sendTwoFactorEmail = useCallback(async () => {
    try {
      await SendActivationEmailTwoFactorMutation({})
    } catch (e) {
      logger.error(e)
    }
  }, [SendActivationEmailTwoFactorMutation])

  return {
    sendTwoFactorEmail,
  }
}
