import graphql from 'babel-plugin-relay/macro'

const MessagingAlertsQuery = graphql`
  query MessagingAlertsQuery {
    alerts: allAlerts(last: 10, orderBy: [CREATED_AT_ASC, EXPIRES_AT_ASC]) {
      nodes {
        message
        beginsAt
        id
        expiresAt
      }
    }
  }
`

export default MessagingAlertsQuery
