import React from 'react'

import { ReactComponent as Notes } from '../../../../Static/images/notes.svg'

import OutlinedToggleIconButton from '../../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import ToggleIconButton, {
  ToggleIconButtonProps,
} from '../../../Buttons/ToggleIconButton/ToggleIconButton'
import { Badge } from '@material-ui/core'

interface NotesToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
  hasNotification?: boolean
}

const NotesToggleButton: React.FC<NotesToggleButtonProps> = ({
  noOutline = false,
  hasNotification = false,
  ...otherProps
}) => {
  const Component = noOutline ? ToggleIconButton : OutlinedToggleIconButton

  return (
    <Component
      icon={Notes}
      activeTooltipTitle="Show chats and notes"
      inactiveTooltipTitle="Hide chats and notes"
      customRender={(innerElement) => {
        const InnerElement = innerElement
        return (
          <Badge
            color="secondary"
            badgeContent=" "
            overlap="circle"
            invisible={!hasNotification}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {InnerElement}
          </Badge>
        )
      }}
      {...otherProps}
    />
  )
}

export default React.memo(NotesToggleButton)
