/* eslint-disable */

var Rectangle,
  ToolWithStroke,
  createShape,
  extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key]
    }
    function ctor() {
      this.constructor = child
    }
    ctor.prototype = parent.prototype
    child.prototype = new ctor()
    child.__super__ = parent.prototype
    return child
  },
  hasProp = {}.hasOwnProperty

ToolWithStroke = require('./base').ToolWithStroke

createShape = require('../core/shapes').createShape

module.exports = Rectangle = (function (superClass) {
  extend(Rectangle, superClass)

  function Rectangle() {
    return Rectangle.__super__.constructor.apply(this, arguments)
  }

  Rectangle.prototype.name = 'Rectangle'

  Rectangle.prototype.iconName = 'rectangle'

  Rectangle.prototype.begin = function (x, y, lc) {
    return (this.currentShape = createShape('Rectangle', {
      x: x,
      y: y,
      strokeWidth: this.strokeWidth,
      strokeColor: lc.getColor('primary'),
      fillColor: lc.getColor('secondary')
    }))
  }

  Rectangle.prototype['continue'] = function (x, y, lc) {
    this.currentShape.width = x - this.currentShape.x
    this.currentShape.height = y - this.currentShape.y
    return lc.drawShapeInProgress(this.currentShape)
  }

  Rectangle.prototype.end = function (x, y, lc) {
    return lc.saveShape(this.currentShape)
  }

  return Rectangle
})(ToolWithStroke)
