import { Box } from '@material-ui/core'
import React from 'react'
import { StreamState } from '../../../../../../Common/media/stream'
import { LiveroomParticipant } from '../../../RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'
import ParticipantThumbnail from '../ParticipantThumbnail/ParticipantThumbnail'

interface Props {
  participants: LiveroomParticipant[]
  isAudioActive: boolean
  onParticipantClicked: (participant: LiveroomParticipant) => void
}

const ParticipantsList: React.FC<Props> = ({
  participants,
  isAudioActive,
  onParticipantClicked
}) => {
  return (
    <Box justifyContent="center" display="flex">
      <Box display="flex" overflow="auto">
        {participants
          .filter((participant) => participant.stream.state === StreamState.available)
          .map((participant) => (
            <Box key={participant.id}>
              <ParticipantThumbnail
                participant={participant}
                isAudioActive={isAudioActive}
                onParticipantClicked={onParticipantClicked}
              />
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default ParticipantsList
