import { useCallback, useEffect, useRef, useState } from 'react'
import { useEffectNonNull } from '../../../../../../../Common/hooks/useEffectNonNull'
import {
  Publisher,
  Recording
} from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { AlertType } from '../../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../../Providers/AlertsProvider'
import { useCreateSessionMutation } from './useCreateSessionMutation'
import { useStopCurrentSessionMutation } from './useStopCurrentSessionMutation'
import {
  RecordingInfo,
  defaultRecordingInfo
} from '../../../../../../../Common/recording/recordingInfo'
import { useRecordingTimer } from '../../../../../../../Common/recording/hooks/useRecordingTimer'
import { Room, UserProfile } from '../../../../../../../Models/apiEntities'

export enum RecordingState {
  awaiting,
  progress,
  recording
}

export interface useLiveroomRecordingParams {
  publisher: Publisher | null
  room: Room
  currentProfile: UserProfile
  isRecordingAllowed: boolean
}

export const useLiveroomRecording = ({
  publisher,
  room,
  currentProfile,
  isRecordingAllowed
}: useLiveroomRecordingParams) => {
  const {
    startRecordingTimer,
    stopRecordingTimer,
    getRecordingCurrentTime,
    onRecordingTimerUpdate
  } = useRecordingTimer()

  // state
  const recordingName = useRef<string | null>(null)
  const isNewSessionBeingCreated = useRef<boolean | null>(false)
  const isSessionBeingStopped = useRef<boolean | null>(false)

  const [recordingInfo, setRecordingInfo] = useState<RecordingInfo>(defaultRecordingInfo)
  const [state, setState] = useState<RecordingState>(RecordingState.awaiting)

  // mutations
  const [executeCreateSessionMutation, { error: createSessionError }] = useCreateSessionMutation({
    roomId: room.id
  })
  const [
    executeStopCurrentSessionMutation,
    { error: stopCurrentSessionError }
  ] = useStopCurrentSessionMutation()
  // alerts
  const alert = useAlerts()

  const handleRecordingStarted = useCallback(
    async (recording: Recording) => {
      if (isRecordingAllowed && isNewSessionBeingCreated.current!) {
        await executeCreateSessionMutation({
          input: {
            session: {
              displayName: recordingName.current!,
              roomId: room.id,
              profileId: currentProfile.id,
              isPublic: room.isPublic,
              startedAt: recording.recordingStartTime,
              recordingId: recording.recordingId
            }
          },
          roomId: room.id
        })
      }

      isNewSessionBeingCreated.current = false
      startRecordingTimer(recording)

      const recordingState = RecordingState.recording
      setState(recordingState)
      setRecordingInfo({
        id: recording.recordingId,
        isRecording: recordingState === RecordingState.recording,
        getRecordingCurrentTime: getRecordingCurrentTime,
        onRecordingTimerUpdate: onRecordingTimerUpdate
      })
    },
    [
      executeCreateSessionMutation,
      room.id,
      room.isPublic,
      currentProfile.id,
      getRecordingCurrentTime,
      isRecordingAllowed,
      onRecordingTimerUpdate,
      startRecordingTimer,
      isNewSessionBeingCreated
    ]
  )

  const handleRecordingStopped = useCallback(async () => {
    if (isRecordingAllowed && isSessionBeingStopped.current!) {
      await executeStopCurrentSessionMutation({
        input: {
          _roomId: room.id
        }
      })
    }

    isSessionBeingStopped.current = false
    stopRecordingTimer()

    setState(RecordingState.awaiting)
    setRecordingInfo(defaultRecordingInfo)
  }, [
    executeStopCurrentSessionMutation,
    room.id,
    isRecordingAllowed,
    stopRecordingTimer,
    isSessionBeingStopped
  ])

  useEffectNonNull(() => {
    alert('Error creating session!', AlertType.error)
  }, [createSessionError])

  useEffectNonNull(() => {
    alert('Error stopping session!', AlertType.error)
  }, [stopCurrentSessionError])

  useEffect(() => {
    if (publisher) {
      publisher.onRecordingActivated().subscribe(handleRecordingStarted)
      publisher.onRecordingDeactivated().subscribe(handleRecordingStopped)
    } else {
      setRecordingInfo(defaultRecordingInfo)
    }
  }, [publisher, handleRecordingStarted, handleRecordingStopped])

  const startRecording = useCallback(
    (_recordingName: string) => {
      if (publisher && isRecordingAllowed) {
        recordingName.current = _recordingName
        setState(RecordingState.progress)
        isNewSessionBeingCreated.current = true
        publisher.startRecording()
      }
    },
    [publisher, isRecordingAllowed]
  )

  const stopRecording = useCallback(() => {
    if (publisher && recordingInfo.isRecording && recordingInfo.id) {
      isNewSessionBeingCreated.current = false
      isSessionBeingStopped.current = true
      publisher.stopRecording(recordingInfo.id)
    }
  }, [publisher, recordingInfo])

  return {
    state,
    recordingInfo,
    startRecording,
    stopRecording
  }
}
