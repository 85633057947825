import React, { useCallback } from 'react'
import classnames from 'classnames'
import IconButton, { ExtendedIconButtonProps } from '../IconButton/IconButton'
import { Box } from '@material-ui/core'

import { SvgIconProps, PropTypes } from '@material-ui/core'

export interface ToggleIconButtonProps extends ExtendedIconButtonProps {
  isActive: boolean
  onToggle?: (isActive: boolean) => void
  inactiveIcon?: React.ComponentType<SvgIconProps> | React.FC
  activeColor?: PropTypes.Color
  inactiveColor?: PropTypes.Color
  activeClassNames?: string
  inactiveClassNames?: string
  activeTooltipTitle?: string
  inactiveTooltipTitle?: string
  noDevicesTooltipTitle?: string
  noDeviceStyles?: {}
  label?: string
}

interface BlurrableElement {
  blur: () => void
}

/**
 * Displays toggle button
 * @param icon - custom icon component for button
 * @param children
 * @constructor
 */
const ToggleIconButton: React.FC<ToggleIconButtonProps> = ({
  isActive,
  icon,
  inactiveIcon,
  activeClassNames,
  inactiveClassNames,
  activeColor = 'default',
  inactiveColor = 'default',
  onToggle,
  activeTooltipTitle = '',
  inactiveTooltipTitle = '',
  customRender,
  noDevices,
  noDeviceStyles,
  noDevicesTooltipTitle,
  label,
  ...otherProps
}) => {
  const Icon = icon
  const InactiveIcon = inactiveIcon ?? Icon
  const { className } = otherProps
  const tooltipTitle = isActive ? activeTooltipTitle : inactiveTooltipTitle

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (onToggle)
        onToggle(!isActive)
        // blurs the button and anything else that might make the space bar select a button the user isn't aware of
      ;((document?.activeElement as any) as BlurrableElement).blur()
    },
    [onToggle, isActive]
  )

  const toggleIconButton = (
    <Box>
      <IconButton
        tooltipTitle={tooltipTitle}
        style={{ paddingTop: label ? 17 : '' }}
        {...otherProps}
        color={isActive ? activeColor : inactiveColor}
        className={classnames(isActive ? activeClassNames : inactiveClassNames, className)}
        onClick={handleClick}
        icon={isActive ? Icon : InactiveIcon}
        label={label}
      />
    </Box>
  )

  return <>{customRender ? customRender(toggleIconButton) : toggleIconButton}</>
}

export default React.memo(ToggleIconButton)
