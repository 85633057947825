import { EnvironmentSupplier, EnvName } from './environments'

const hostname = window.location.hostname

const devConfig: EnvironmentSupplier = () => ({
  envName: EnvName.dev,
  frontendUrl: `https://${hostname}`,
  apiUrl: `https://${hostname}/api`,
  authUrl: `https://${hostname}/auth`,
  moderationApiUrl: `https://${hostname}/moderation`,
  webSocketUrl: `wss://${hostname}/websockets`,
  moderationWebSocketUrl: `wss://${hostname}/moderation/websocket`,
  restApiUrl: `https://${hostname}/admin-api`,
  adminPortalUrl: `https://${hostname}/admin/`,
  esIngestorCSDUrl: `https://${hostname}/diagnostics/errorDetails`,
  captchaSiteKey: '6LeZIboUAAAAACQ3cUESx8_0rNRQbYczIwpb2w6q',
  sso: {
    loginRedirect: `https://${hostname}/api/federatedlogin`,
    logoutRedirect: `https://${hostname}/api/federatedlogout`
  }
})

export default devConfig
