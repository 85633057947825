import { MutableRefObject } from 'react'
import { useEffect } from 'react'

interface UseIntersectionObserver {
  ref: MutableRefObject<HTMLElement | null> | null
  onVisible?: (entry?: IntersectionObserverEntry) => any
  onHidden?: (entry?: IntersectionObserverEntry) => any
  options?: IntersectionObserverInit
}

export function useIntersectionObserver(props: UseIntersectionObserver) {
  const { ref, onVisible = () => {}, onHidden = () => {}, options } = props
  useEffect(() => {
    const elem = ref?.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onVisible(entry)
        } else {
          onHidden(entry)
        }
      })
    }, options)

    if (elem) observer.observe(elem)

    return () => {
      if (elem) observer.unobserve(elem)
    }
  }, [ref, onVisible, onHidden, options])
}
