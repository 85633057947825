import React from 'react'
import { BaseDialog, DefaultDialogHeader } from '..'

interface BaseDialogProps {
  children?: React.ReactNode
  title: string
  isOpen?: boolean
  classNames?: string
  onClose?: () => void
  onEnter?: () => void
  disableEscapeKeyDown?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

/**
 * Displays default Dialog with closeable header
 * @param children
 * @constructor
 */
const DefaultDialog: React.FC<BaseDialogProps> = ({
  title,
  isOpen = false,
  classNames,
  onClose,
  onEnter,
  disableEscapeKeyDown = false,
  maxWidth,
  fullWidth,
  children
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={onClose}
      onEnter={onEnter}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      classNames={classNames}
      header={<DefaultDialogHeader text={title} onClose={onClose} divider></DefaultDialogHeader>}
    >
      {children}
    </BaseDialog>
  )
}

export interface BaseDialogComponentsProps {
  children: React.ReactNode
  divider?: boolean
}

export default React.memo(DefaultDialog)
