import React from 'react'
import classnames from 'classnames'

import './CardSubtitle.scss'

interface CardSubtitleProps {
  className?: string
  children: React.ReactNode
}

/**
 * Displays children styled as unauthenticated view card subtitle
 * @param className - css classname which allows to customize styles, if needed
 * @param children
 * @constructor
 */
const CardSubtitle: React.FC<CardSubtitleProps> = ({ className, children }) => {
  return (
    <span className={classnames('card-subtitle', className)}>
      <span>{children}</span>
    </span>
  )
}

export default React.memo(CardSubtitle)
