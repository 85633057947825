import React from 'react'
import { Link } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { useForgotPassword, ForgotPasswordState } from './hooks/useForgotPassword'
import { useEnvironment } from '../../../../Providers/EnvironmentProvider'
import { routes } from '../routes'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import Captcha from '../../../../Components/Captcha/Captcha'
import ProgressButton from '../../../../Components/ProgressButton/ProgressButton'
import { useForgotPasswordForm } from './hooks/useForgotPasswordForm'
import ForgotPasswordSuccess from './ForgotPasswordSuccess/ForgotPasswordSuccess'
import { useKeyDownHandler } from '../../../../Common/hooks/useKeyDownHandler'

const ForgotPasswordView: React.FC = () => {
  const history = useHistory()
  const { state, resetPassword } = useForgotPassword()
  const { inputs, captcha, handleSubmit, inputButtonRef } = useForgotPasswordForm(resetPassword)
  const environment = useEnvironment()

  const { handleOnKeydown } = useKeyDownHandler({
    action: handleSubmit,
    predicate: () => state === ForgotPasswordState.awaiting
  })

  if (state === ForgotPasswordState.success) {
    return <ForgotPasswordSuccess />
  }

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Box pb={1}><Typography variant="h4">Reset password</Typography></Box>
        <Box className="login-navigation" mb={2.5}>
          New to Evercast?{' '}
          <Link to={routes.signUp.path}>
            <strong>Create an account</strong>
          </Link>
        </Box>
        <FormControl fullWidth>
          <TextField label="Email address" autoFocus={true} variant="filled" {...inputs.email} />
        </FormControl>
      </Grid>
      {environment.captchaSiteKey && (
        <Grid item container spacing={1}>
          <Box display="flex" justifyContent="flex-start" ml={.3}>
            <Captcha
              onValue={captcha.setValue}
              onExpiration={captcha.handleExpiration}
              error={captcha.error}
              focusElementAfter={inputButtonRef}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" ml={0} mt={3}>
          <Box mr={3}>
            <ProgressButton
              isLoading={false}
              className="back-button"
              color="primary"
              size="medium"
              onClick={() => {
                history.push('/')
              }}
            >
              Cancel
            </ProgressButton>
          </Box>
          <Box mr={3}>
            <ProgressButton
              color="primary"
              id="formContainer"
              size="medium"
              onKeyDown={handleOnKeydown}
              isLoading={state === ForgotPasswordState.submitting}
              onClick={handleSubmit}
              buttonRef={inputButtonRef}
              className="continue"
            >
              Reset password
            </ProgressButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(ForgotPasswordView)
