/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Playback_iceServers = ReadonlyArray<{
    readonly urls: string;
    readonly username: string | null;
    readonly credential: string | null;
    readonly " $refType": "Playback_iceServers";
}>;
export type Playback_iceServers$data = Playback_iceServers;
export type Playback_iceServers$key = ReadonlyArray<{
    readonly " $data"?: Playback_iceServers$data;
    readonly " $fragmentRefs": FragmentRefs<"Playback_iceServers">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Playback_iceServers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urls",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "credential",
      "storageKey": null
    }
  ],
  "type": "IceServer",
  "abstractKey": null
};
(node as any).hash = 'cd36d66797b0a61ff7347a9c78021b19';
export default node;
