import { useEffect, useState } from 'react'
import axios from 'axios'
import { useEffectWithPredicate } from './useEffectWithPredicate'
import useApi from '../../API/useApi'
import { useRoom } from '../../Providers/RoomProvider'
import { getPKCECodes } from '../utils/pkce'
import { useEnvironment } from '../../Providers/EnvironmentProvider'


export const useWebSocketServerUrl = () => {
    const [webSocketServerUrl, setWebSocketServerUrl] = useState<string | null>(null)

    const room = useRoom()
    const { Room } = useApi()
    const restApiUrl = useEnvironment().restApiUrl

    const getWebSocketServerURL = async () => {
        try {
            let response = await Room.getRoomServerURL(room.id)

            if (!response.signalingUrl) {
                console.error('Unable to get the server URLs')
                return null
            }

            if (response.signalingAuthUrl) {
                if (response.accessToken) {
                    await axios.post(response.signalingAuthUrl, { accessToken: response.accessToken }, { withCredentials: true })
                } else {
                    const {
                        codeChallenge,
                        codeVerifier
                    } = await getPKCECodes()
                    const authResponse = await axios.post(`${restApiUrl}/api/rooms/${room.id}/authenticate/`, { codeChallenge })
                    const authorizationCode = authResponse.data?.data.authorizationCode
                    if (!authorizationCode) {
                        throw new Error('Something went wrong while doing the Auth flow');
                    }
                    await axios.post(response.signalingAuthUrl, { authorizationCode, codeVerifier }, { withCredentials: true })
                }
            }

            return response.signalingUrl
        } catch (e) {
            console.error(e.message)
            return null
        }
    }

    const getWebSocketServerURLWithRetry = async () => {
        let url: string | null = null
        while (!url) {
            url = await getWebSocketServerURL()
            if (!url) {
                await new Promise(r => setTimeout(r, 5000));
            }
        }
        return url
    }

    useEffect(() => {
        setWebSocketServerUrl(null)
    }, [room.id]
    )

    useEffectWithPredicate(
        {
            predicate: () => webSocketServerUrl === null,
            effect: () => {
                getWebSocketServerURLWithRetry().then((newUrl) => {
                    setWebSocketServerUrl(newUrl)
                })
            }
        },
        [webSocketServerUrl]
    )

    return webSocketServerUrl
}
