import React from 'react'

import { BaseDialog } from '../../../../../Components/Dialogs/BaseDialog/BaseDialog'
import { DefaultDialogHeader } from '../../../../../Components/Dialogs/Headers/DefaultDialogHeader/DefaultDialogHeader'
import { Typography, Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

interface RecordingNotficationDialogProps {
  isOpen: boolean
  handleClose: () => void
  isHidden: boolean
  toggleHide: () => void
}

/**
 * Recording notification dialog
 * @param isOpen to show/hide dialog
 * @param handleClose on dialog close
 * @constructor
 */
export const RecordingNotificationDialog: React.FC<RecordingNotficationDialogProps> = ({
  isOpen,
  handleClose,
  isHidden,
  toggleHide
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth={true}
      header={
        <DefaultDialogHeader
          text={'Secure recording started'}
          divider={true}
          onClose={handleClose}
        />
      }
    >
      <Box paddingX={7} paddingY={4}>
        <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={3}>
          <Grid item>
            <Typography variant="body2" align="center" color="textPrimary">
              Recording has started.
            </Typography>
            <Typography variant="body2" align="center" color="textPrimary">
              <Checkbox checked={isHidden} onChange={toggleHide} name="hide" />
              Don't show this again
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box paddingX={7} paddingY={4}>
        <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={3}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            onClick={handleClose}
            data-testid="start-screen-share-button"
          >
            Close
          </Button>
        </Grid>
      </Box>
    </BaseDialog>
  )
}

export default React.memo(RecordingNotificationDialog)
