import { Box, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { useValidateTwoFactorCode } from './hooks/useValidateTwoFactorCode'
import { useValidateTwoFactorCodeForm } from './hooks/useValidateTwoFactorCodeForm'
import { useKeyDownHandler } from '../../../../../Common/hooks/useKeyDownHandler'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'
import { tabsValues } from '../../AccountView/AccountView'
import QRCode from 'react-qr-code'

interface TwoFactorConfigurationQRCodeProps {
  barcodeUri: string
  handleContinue: (...args: any) => void
}

/**
 * Evercast verify two factor authentication success view
 * @constructor
 */

const TwoFactorConfigurationQRCode: React.FC<TwoFactorConfigurationQRCodeProps> = ({
  barcodeUri,
  handleContinue,
}) => {
  const { validateCode } = useValidateTwoFactorCode(handleContinue)
  const { input, handleSubmit } = useValidateTwoFactorCodeForm(validateCode)

  const { handleOnKeydown } = useKeyDownHandler({ action: handleSubmit })

  return (
    <Grid container className="two-factor-success-view">
      <Grid item xs={12}>
        <Box mb={5}>
          <Typography gutterBottom variant="h4">
            Set up multi-factor authentication
          </Typography>
        </Box>

        <Box>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" align="left">
                STEP 2
              </Typography>
              <Box paddingY={1}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="p"
                  align="left"
                  color="textSecondary"
                >
                  Scan the QR code, below, and then enter the verfication code provided by the
                  Google Authenticator App in the form field.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box paddingY={3}></Box>

          <Grid container spacing={2} alignItems="center" justify="center">
            {barcodeUri &&
              <div style={{ background: 'white', padding: '8px' }}>
                <QRCode
                  size={128}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={barcodeUri}
                  viewBox={`0 0 128 128`}
                />
              </div>}
          </Grid>

          <Box paddingY={3}></Box>

          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Enter verification code"
                  type="text"
                  variant="outlined"
                  autoFocus={true}
                  onKeyDown={handleOnKeydown}
                  {...input.code}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" ml={0}>
                <Box mr={3}>
                  <Link
                    to={routes.account.path.replace(':tab', tabsValues.settings)}
                    rel="noopener noreferrer"
                  >
                    <ProgressButton
                      color="primary"
                      variant="outlined"
                      isLoading={false}
                      size="medium"
                      fullWidth
                    >
                      Cancel
                    </ProgressButton>
                  </Link>
                </Box>
                <Box>
                  <ProgressButton
                    color="primary"
                    isLoading={false}
                    fullWidth
                    size="medium"
                    className="continue"
                    onClick={handleSubmit}
                  >
                    Verify
                  </ProgressButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(TwoFactorConfigurationQRCode)
