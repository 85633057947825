import React from 'react'

import { ReactComponent as FullScreen } from '../../../../Static/images/full_screen.svg'

import OutlinedToggleIconButton from '../../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import ToggleIconButton, {
  ToggleIconButtonProps,
} from '../../../Buttons/ToggleIconButton/ToggleIconButton'

interface FullScreenToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  onToggle: (boolean) => void
  noOutline?: boolean
}

const FullScreenToggleButton: React.FC<FullScreenToggleButtonProps> = ({
  onToggle,
  isActive,
  noOutline = false,
  ...otherProps
}) => {
  const Component = noOutline ? ToggleIconButton : OutlinedToggleIconButton

  return (
    <Component
      icon={FullScreen}
      activeTooltipTitle="Enter full screen"
      inactiveTooltipTitle="Exit full screen"
      inactiveIcon={FullScreen}
      isActive={!isActive}
      onToggle={() => {
        onToggle(!isActive)
      }}
      label="Full"
      {...otherProps}
    />
  )
}

export default React.memo(FullScreenToggleButton)
