import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProfilePreferences } from '../../../../../../Common/preferences/usePreferences'
import { useSavePreferencesMutation } from '../../../../../../GraphQL/hooks/useSavePreferencesMutation'
import { BooleanConverter, PreferenceType } from '../../../../../../Common/preferences/Preferences'
import { useKeyboardShortcuts } from './useKeyboardShortcuts'
import { PPTKey } from '../../DeviceSelectionDialog/hooks/usePTTShortcut'

export interface LocalDevicesState {
  isCameraEnabled: boolean
  isMicrophoneEnabled: boolean
  isAudioOutputEnabled: boolean
}

export interface LocalDevicesActions {
  setIsCameraEnabled: Dispatch<SetStateAction<boolean>>
  setIsMicrophoneEnabled: Dispatch<SetStateAction<boolean>>
  setIsAudioOutputEnabled: Dispatch<SetStateAction<boolean>>
}

export interface UseLocalDevicesStateResult {
  localDevicesState: LocalDevicesState
  localDevicesActions: LocalDevicesActions
}

export const useLocalDevicesState = (
  profilePreferences: ProfilePreferences
): UseLocalDevicesStateResult => {
  const cameraStatePreference = profilePreferences.getProfilePreference(
    PreferenceType.VIDEO_INPUT_ENABLED
  )
  const microphoneStatePreference = profilePreferences.getProfilePreference(
    PreferenceType.AUDIO_INPUT_ENABLED
  )
  const audioOutputStatePreference = profilePreferences.getProfilePreference(
    PreferenceType.AUDIO_OUTPUT_ENABLED
  )

  const [isCameraEnabled, setIsCameraEnabled] = useState(
    cameraStatePreference.getValue<boolean>(BooleanConverter)
  )
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(
    microphoneStatePreference.getValue<boolean>(BooleanConverter)
  )
  const [isAudioOutputEnabled, setIsAudioOutputEnabled] = useState(
    audioOutputStatePreference.getValue<boolean>(BooleanConverter)
  )

  const PPTPreference = profilePreferences.getProfilePreference(
    PreferenceType.PUSH_TO_TALK_SHORTCUT
  )
  const PPTValue = PPTPreference.getValue() as PPTKey

  const keyShortcuts = useRef([
    {
      id: 'PUSH_TO_TALK',
      keys: [PPTValue],
      onDown: () => setIsMicrophoneEnabled(true),
      onUp: () => setIsMicrophoneEnabled(false)
    },
    {
      id: 'MUTE',
      keys: ['Control', 'm'],
      onUp: () => setIsMicrophoneEnabled((bool) => !bool)
    }
  ])

  useEffect(() => {
    const idx = keyShortcuts.current.findIndex(({ id }) => id === 'PUSH_TO_TALK')
    keyShortcuts.current[idx].keys = [PPTValue]
  }, [PPTValue])

  useKeyboardShortcuts(keyShortcuts)

  const [executeSavePreferencesMutations] = useSavePreferencesMutation()
  const syncPreferences = useCallback(
    (typeId, state) => {
      executeSavePreferencesMutations({
        input: {
          preferencesToSet: [
            {
              preferenceTypeId: typeId,
              preferenceValue: state.toString()
            }
          ]
        }
      })
    },
    [executeSavePreferencesMutations]
  )

  return {
    localDevicesState: useMemo(
      () => ({
        isCameraEnabled,
        isMicrophoneEnabled,
        isAudioOutputEnabled
      }),
      [isCameraEnabled, isMicrophoneEnabled, isAudioOutputEnabled]
    ),
    localDevicesActions: {
      setIsCameraEnabled: useCallback(
        (isEnabled) => {
          setIsCameraEnabled(isEnabled)
          syncPreferences(cameraStatePreference.preferenceTypeId, isEnabled)
        },
        [cameraStatePreference.preferenceTypeId, syncPreferences]
      ),
      setIsMicrophoneEnabled: useCallback(
        (isEnabled) => {
          setIsMicrophoneEnabled(isEnabled)
          syncPreferences(microphoneStatePreference.preferenceTypeId, isEnabled)
        },
        [microphoneStatePreference.preferenceTypeId, syncPreferences]
      ),
      setIsAudioOutputEnabled: useCallback(
        (isEnabled) => {
          setIsAudioOutputEnabled(isEnabled)
          syncPreferences(audioOutputStatePreference.preferenceTypeId, isEnabled)
        },
        [audioOutputStatePreference.preferenceTypeId, syncPreferences]
      )
    }
  }
}
