import React from 'react'
import { Grid, Box } from '@material-ui/core'
import CardTitle from '../CardTitle/CardTitle'
import CardText from '../CardText/CardText'

import './CardNotification.scss'

interface CardNotificationProps {
  icon?: React.ReactNode
  title: React.ReactNode
  description: React.ReactNode
  footer: React.ReactNode
}

/**
 * Component for building common notification views with Logo at the top
 *
 * @param icon
 * @param title
 * @param description
 * @param footer
 */
const CardNotification: React.FC<CardNotificationProps> = ({
  icon,
  title,
  description,
  footer
}) => {
  return (
    <Grid container spacing={2} justify="center">
      {icon && (
        <Grid item>
          <Box margin={-1}>
            <span className="card-notification-icon">{icon}</span>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <CardTitle className='text-left'>{title}</CardTitle>
      </Grid>
      <Grid item xs={12}>
        <CardText className='text-left'>{description}</CardText>
      </Grid>
      <Grid item xs={12}>
        <CardText className='text-left'>{footer}</CardText>
      </Grid>
    </Grid>
  )
}

export default React.memo(CardNotification)
