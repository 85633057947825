import React, { useCallback } from 'react'
import { Box, MenuList, Popover } from '@material-ui/core'
import MenuItemWithSubMenu from '../../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import FontSizeIcon from '../../../../assets/FontSizeIcon'
import { usePopover } from '../../../hooks/usePopover'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../../Providers/DrawCanvasToolsProvider'

const fontSizes = [10, 12, 14, 18, 24, 32, 48, 64, 80, 120]

export const FontSize: React.FC = () => {
  const { isOpen, anchorEl, handleClick, handleClose } = usePopover()
  const {
    drawPreferences: { drawFontSize },
    setDrawFont
  } = useDrawCanvasToolsContext()

  const setDrawFontSizeHandler = useCallback(
    (drawFontSize) => () => {
      setDrawFont({ drawFontSize })
      handleClose()
    },
    [handleClose, setDrawFont]
  )

  return (
    <>
      <MenuItemWithSubMenu
        onClick={handleClick}
        icon={<FontSizeIcon />}
        label="Font size"
        dense={true}
        selected={isOpen}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box marginLeft={0.5}>
          <MenuList data-testid="font-size-selector">
            {fontSizes.map((size) => (
              <MenuItemWithSubMenu
                key={size}
                label={`${size}px`}
                onClick={setDrawFontSizeHandler(size)}
                selected={drawFontSize === size}
                dense={true}
              />
            ))}
          </MenuList>
        </Box>
      </Popover>
    </>
  )
}

export default React.memo(FontSize)
