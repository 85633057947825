// Generate a random string using unreserved characters
function generateRandomString(length) {
  var randomString = "";
  var unreservedCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~";

  for (var i = 0; i < length; i++) {
    randomString += unreservedCharacters.charAt(Math.floor(Math.random() * unreservedCharacters.length));
  }

  return randomString;
}

// Create a sha256, base64 encoded, digest of a code
async function generateCodeChallenge(codeVerifier) {
  var digest = await crypto.subtle.digest("SHA-256",
      new TextEncoder().encode(codeVerifier));
  return btoa(String.fromCharCode.apply(null, new Uint8Array(digest)));
}

// Get the pair codeVerifier-codeChallenge, required to fulfill a Authorization Code Flow with PKCE
// https://oauth.net/2/pkce/
export async function getPKCECodes() {
  const codeVerifier = generateRandomString(128)
  const codeChallenge = await generateCodeChallenge(codeVerifier)
  return {
    codeVerifier,
    codeChallenge
  }
}