import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { IconSize } from '../StreamPreview'
import { ErrorIcon } from '../Icons/Error'

type Props = {
  canPublish?: boolean
}
const NoDevices: React.FC<Props> = ({ canPublish }) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <Box display={'flex'} flexDirection={'row'} justifyContent="center" alignItems="center">
            <Box
              fontSize={IconSize}
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              m={2}
            >
              {ErrorIcon}
            </Box>

            <Typography variant={'h5'} align={'center'}>
              {canPublish ? 'No devices detected' : 'Theater mode room'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={2}
          pr={2}
          maxWidth={800}
        >
          <Typography variant={'body1'} align={'center'}>
            {canPublish ?
              <>
                You can still go live without a webcam or microphone. However, you will only be able to
                attend. To learn how to fix this,{' '}
                <a
                  href="https://guides.evercast.us/troubleshooting/evercast/no-webcam-mic-showing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here.
                </a>
              </> :
              <>You are joining this session as a viewer. Your mic and camera will be disabled. To enter as a contributor, contact the room owner.</>
            }
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(NoDevices)
