import { useCallback } from 'react'
import useApi from '../../../../API/useApi'

/**
 * Hook returns function that will activate a passed invite code
 *
 * @param roomId
 * @param specialInviteCode
 */
export const useSpecialInviteActivate = () => {
  const { SpecialInvite } = useApi()

  const activateInvite = useCallback(async (roomId, specialInviteCode) => {
    await SpecialInvite.activate(roomId, { specialInviteCode })
  }, [SpecialInvite])

  return activateInvite
}
