// based on https://www.cssfontstack.com/
export const SAFE_FONTS = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Rounded MT Bold',
  'Baskerville',
  'Book Antiqua',
  'Brush Script MT',
  'Calibri',
  'Calisto MT',
  'Candara',
  'Century Gothic',
  'Consolas',
  'Copperplate',
  'Courier New',
  'Garamond',
  'Georgia',
  'Gill Sans',
  'Goudy Old Style',
  'Helvetica',
  'Impact',
  'Lucida Bright',
  'Lucida Sans Typewriter',
  'Palatino',
  'Papyrus',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
]
