import { useCallback, useState } from 'react'

import { useAlerts } from '../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../Components/Alert/Alert'
import { ForgotPasswordForm } from './useForgotPasswordForm'
import { useEffectNonNull } from '../../../../../Common/hooks/useEffectNonNull'
import { useRequestPasswordResetMutation } from './useRequestPasswordResetMutation'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../../../Common/log/Log'

export enum ForgotPasswordState {
  awaiting,
  submitting,
  success
}

export const ERROR_CODES = {
  '07240': 'Must verify you are human.'
}

const defaultErrorText = 'Error during requesting password reset!'

export const useForgotPassword = () => {
  const [state, setState] = useState(ForgotPasswordState.awaiting)
  const alert = useAlerts()

  const [
    executeRequestPasswordMutation,
    { error, data, graphQLErrors }
  ] = useRequestPasswordResetMutation()

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: ERROR_CODES,
    graphQLErrors,
    defaultErrorText,
    onError: useCallback(() => {
      setState(ForgotPasswordState.awaiting)
    }, [setState])
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
    setState(ForgotPasswordState.awaiting)
  }, [error])

  useEffectNonNull(() => {
    if (!data.requestPasswordReset) {
      return
    }

    setState(ForgotPasswordState.success)
  }, [data])

  const resetPassword = useCallback(
    async (formData: ForgotPasswordForm) => {
      setState(ForgotPasswordState.submitting)

      try {
        await executeRequestPasswordMutation({ input: formData })
      } catch (e) {
        logger.error(e)
      }
    },
    [executeRequestPasswordMutation, setState]
  )

  return {
    state,
    resetPassword
  }
}
