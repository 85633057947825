import { logger } from '../../../../../../../Common/log/Log'
import { useEffect, useState } from 'react'
import { usePrevious } from '../../../../../../../Common/hooks/usePrevious'
import { ConditionalLoggerAdapter } from '../../../../../../../Common/log/ConditionalLoggerAdapter'
import { Participant, Publisher } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'

export const useAdaptiveStreaming = ({
  isEnabled,
  publisher,
  participants,
  activeParticipantId,
}: {
  isEnabled: boolean,
  publisher: Publisher | null
  participants: Participant[]
  activeParticipantId: string
}) => {
  const [subscribedParticipants, setSubscribedParticipants] = useState<string[]>([])
  const [streamObservers, setStreamObservers] = useState<string[]>([])
  const previousActiveParticipantId = usePrevious(activeParticipantId)

  // Update participant data channel subscriptions
  useEffect(() => {
    setSubscribedParticipants(subscribedParticipants =>
      subscribedParticipants.filter(subscribedParticipant =>
        participants.some(participant => subscribedParticipant === participant.id)))
  }, [participants])

  useEffect(() => {
    // Prevent subscribing if publisher doesn't exist yet (subscription will occur later, when publisher exists)
    if (!publisher) {
      return
    }

    participants.forEach(participant => {
      if (subscribedParticipants.includes(participant.id)) {
        return
      }

      ConditionalLoggerAdapter.info('[Adaptive streaming] Subscribing to participant data channel', participant.id)
      // TODO: DAMStreams don't use dataChannelMessage. Optional chaining is not ideal.
      participant.onDataChannelMessage?.().subscribe((event) => {
        ConditionalLoggerAdapter.info('[Adaptive streaming] Received participant data channel message', participant.id, event)
        if (!publisher) {
          return
        }

        try {
          const { streamId, type, message } = JSON.parse(event.data)

          if (type !== 'stream-active-speaker-broadcast') {
            return
          }

          if (!streamId || streamId.length === 0) {
            return
          }

          if (streamId === publisher.id) {
            return
          }

          // Add an observer if now actively viewing our stream or remove if no longer actively viewing
          setStreamObservers(observers => {
            if (message.current === publisher.id) {
              ConditionalLoggerAdapter.info('[Adaptive streaming] Adding stream observer', streamId)
              return observers.includes(streamId) ? observers : [...observers, streamId]
            } else {
              ConditionalLoggerAdapter.info('[Adaptive streaming] Removing stream observer', streamId)
              return observers.filter(observer => observer !== streamId)
            }
          })
        } catch (e) {
          ConditionalLoggerAdapter.error(e, { event })
        }
      })

      setSubscribedParticipants(subscribedParticipants => [...subscribedParticipants, participant.id])
    })
  }, [participants, subscribedParticipants, publisher])

  // Remove any observers no longer present in the room
  useEffect(() => {
    setStreamObservers(observers =>
      observers.filter(observer => participants.some(participant => observer === participant.id)))
  }, [participants])

  // Broadcast message when active stream changes
  useEffect(() => {
    if (activeParticipantId === previousActiveParticipantId) {
      return
    }

    ConditionalLoggerAdapter.info(
      `[Adaptive streaming] Active participant change from ${previousActiveParticipantId} to ${activeParticipantId}`)

    if (!publisher) {
      return
    }

    (async () => {
      try {
        const message = {
          type: 'stream-active-speaker-broadcast',
          streamId: publisher.id,
          message: {
            previous: previousActiveParticipantId ?? '',
            current: activeParticipantId,
          },
        }
        const text = JSON.stringify(message)
        logger.info('[Adaptive streaming] Sending message', text)
        await publisher.sendData({ text })
      } catch (e) {
        logger.error('[Adaptive streaming] Failed to send active stream data channel message', e)
      }
    })()
  }, [activeParticipantId, previousActiveParticipantId, publisher])

  // Check list of stream observers to adjust stream quality as needed
  useEffect(() => {
    if (!isEnabled) {
      return
    }

    if (!publisher) {
      return
    }

    (async () => {
      try {
        if (streamObservers.length > 0) {
          ConditionalLoggerAdapter.info('[Adaptive streaming] Using full stream quality')
          await publisher.adjustStreamQuality({})
        } else {
          ConditionalLoggerAdapter.info('[Adaptive streaming] Using reduced stream quality')
          await publisher.adjustStreamQuality({ height: 240, bitrate: 768 * 1024 })
        }
      } catch (e) {
        logger.warn('[Adaptive streaming] Failed to adjust camera stream quality', e)
      }
    })()
  }, [isEnabled, streamObservers, publisher])
}
