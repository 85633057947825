/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SendDeactivationEmailTwoFactorMutationVariables = {};
export type SendDeactivationEmailTwoFactorMutationResponse = {
    readonly sendDeactivationEmailTwoFactor: boolean | null;
};
export type SendDeactivationEmailTwoFactorMutation = {
    readonly response: SendDeactivationEmailTwoFactorMutationResponse;
    readonly variables: SendDeactivationEmailTwoFactorMutationVariables;
};



/*
mutation SendDeactivationEmailTwoFactorMutation {
  sendDeactivationEmailTwoFactor
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sendDeactivationEmailTwoFactor",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendDeactivationEmailTwoFactorMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SendDeactivationEmailTwoFactorMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fba82dfb078c5e9ad280f6d973708c01",
    "id": null,
    "metadata": {},
    "name": "SendDeactivationEmailTwoFactorMutation",
    "operationKind": "mutation",
    "text": "mutation SendDeactivationEmailTwoFactorMutation {\n  sendDeactivationEmailTwoFactor\n}\n"
  }
};
})();
(node as any).hash = '315d6a8b3d28b24163e14ea3372f2900';
export default node;
