import React, { useCallback, useState } from 'react'
import { Badge, Box, Button, Grid, IconButton, List, Typography } from '@material-ui/core'
import SingleAlert, {
  didUserAlreadyDismissCSMAlert,
  MessagingAlertProps,
  cleanupAlertsInLocalStorage
} from './SingleAlert/SingleAlert'
import MessagingAlertsQuery from '../../GraphQL/queries/MessagingAlertsQuery'
import QueryRenderer from '../../GraphQL/QueryRenderer'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import DefaultDialog from '../Dialogs/DefaultDialog/DefaultDialog'
import { useDialog } from '../RoomDialog/hooks/useDialog'
import { logger } from '../../Common/log/Log'
import './CSMAlerts.scss'

interface MessagingAlertsData {
  alerts: {
    nodes: [MessagingAlertProps]
  }
}

const CSMAlerts = () => {
  const [count, setCount] = useState(0)
  const [isOpen, open, close] = useDialog()

  const handleDataFromDB = useCallback((data: MessagingAlertsData) => {
    const { alerts } = data
    const now = new Date()
    const alertsWithinTimeRange = alerts.nodes.filter(
      ({ beginsAt, expiresAt }) =>
        new Date(beginsAt) < now && (!expiresAt || new Date(expiresAt) > now) // if startsAt is before today and (expiresAt is null or expiresAt is a later date), it's an alert we want to show
    )
    cleanupAlertsInLocalStorage(alertsWithinTimeRange)
    const content = alertsWithinTimeRange.length ? (
      alertsWithinTimeRange.map((props) => <SingleAlert {...props} key={props.id} />)
    ) : (
      <Typography align="center">There are no current alerts</Typography>
    )
    setCount(alertsWithinTimeRange.filter(({ id }) => !didUserAlreadyDismissCSMAlert(id)).length)
    return content
  }, [])

  const CSMAlertsModal = useCallback(
    (data: MessagingAlertsData) => {
      let dialogContent = <></>
      try {
        dialogContent = <List>{handleDataFromDB(data)}</List>
      } catch (err) {
        logger.error(err)
        dialogContent = <Typography align="center">Couldn't fetch new alerts</Typography>
      } finally {
        return (
          <DefaultDialog fullWidth title="Alerts" isOpen={isOpen} onClose={close} maxWidth={'sm'}>
            {dialogContent}
            <Box mt={3}>
              <Grid container spacing={3} justify="flex-end">
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={close}>
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DefaultDialog>
        )
      }
    },
    [isOpen, close, handleDataFromDB]
  )

  return (
    <>
      <IconButton aria-label={`show ${count} notifications`} color="inherit" onClick={open}>
        <Badge badgeContent={count} color="secondary" className="csm-alert-badge">
          <NotificationsIcon />
          <h5>Alerts</h5>
        </Badge>
      </IconButton>
      <QueryRenderer query={MessagingAlertsQuery} renderResult={CSMAlertsModal} />
    </>
  )
}

export default CSMAlerts
