import React, { createContext, useContext, useMemo } from 'react'
import { AxiosInstance } from 'axios'
import { createAxiosInstance } from '../Common/config/axiosConfig'

const axiosContext = createContext<AxiosInstance>(null!)

export const AxiosProvider: React.FC = ({ children }) => {
  const axios = useMemo(createAxiosInstance, [])
  return <axiosContext.Provider value={axios}>{children}</axiosContext.Provider>
}

export const useAxios = () => {
  return useContext(axiosContext)
}
