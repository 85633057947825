import React from 'react'
import { Typography, Box, Grid, Button } from '@material-ui/core'
import ProgressButton from '../../../ProgressButton/ProgressButton'

interface RoomProps {
  id: string
  displayName: string
}

interface DeleteRoomFromDashboardDialogConfirmProps {
  room?: RoomProps
  handleClose: () => void
  onConfirm: () => void
  isLoading: boolean
}

const DeleteRoomFromDashboardDialogConfirm: React.FC<DeleteRoomFromDashboardDialogConfirmProps> = ({
  room,
  handleClose,
  onConfirm,
  isLoading
}) => {
  return (
    <>
      <Box marginTop={1}>
        <Typography variant="h6" align="center" gutterBottom>
          Are you sure you want to proceed?
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="body2" align="center" gutterBottom style={{ fontWeight: 'lighter' }}>
          Removing this room ({room?.displayName}) from your dashboard means you will be uninvited and lose any roles you have for this room. Are you sure you wish to continue?
        </Typography>
      </Box>
      <Box marginTop={6}>
        <Grid container spacing={2} alignItems="center" justify="flex-start">
          <Grid item xs={12}>
            <ProgressButton
              type="submit"
              color="secondary"
              variant="contained"
              isLoading={isLoading}
              onClick={onConfirm}
              fullWidth
            >
              Remove room
            </ProgressButton>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleClose} fullWidth>
              Cancel - Go Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(DeleteRoomFromDashboardDialogConfirm)
