import { useCallback } from 'react'
import { getBrowserInformation } from '../../../../../../../Common/utils/browserCheck'

export const useOpenSupportChat = (displayName, email, roomName) => {
  const { browser } = getBrowserInformation()

  const openSupportChat = useCallback(() => {
    const info = {
      display_name: displayName,
      email: email || 'none',
      room_name: roomName,
      room_url: window.location.href,
      client: `web ${JSON.stringify(browser)}}`, // example: web {"_ua":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.55 Safari/537.36","parsedResult":{"browser":{"name":"Chrome","version":"96.0.4664.55"},"os":{"name":"macOS","version":"10.15.7","versionName":"Catalina"},"platform":{"type":"desktop","vendor":"Apple"},"engine":{"name":"Blink"}}}}
    }

    const url = new URL(process.env.REACT_APP_LIVE_CHAT_URL as string)
    for (let [key, value] of Object.entries(info)) url.searchParams.append(key, value)

    return window.open(
      url.toString(),
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=400,width=400,height=610'
    )
  }, [displayName, email, roomName, browser])
  return openSupportChat
}
