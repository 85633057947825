import React, { useState, useCallback, useMemo, useEffect } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { Box, TextField } from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DayWrapper from '@material-ui/pickers/views/Calendar/DayWrapper'
import DatePickerToolbar, { DatePickerToolbarProps } from './DatePickerToolbar'
import { InputProps } from '@material-ui/core/Input'
import './DatePicker.scss'

interface DatePickerProps {
  value: MaterialUiPickersDate
  onChange: (date: MaterialUiPickersDate, value?: string) => void
  onSelect?: (date: MaterialUiPickersDate[]) => void
  onSubmit?: (dates: MaterialUiPickersDate[]) => void
  inputValue?: string
  inputProps?: Partial<InputProps>
  anchorEl?: Element
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  inputValue,
  inputProps,
  onChange,
  onSelect,
  onSubmit,
  anchorEl
}) => {
  const [firstDate, setFirstDate] = useState<string | undefined>()
  const [secondDate, setSecondDate] = useState<string | undefined>()
  const [dateSelected, setDateSelected] = useState<MaterialUiPickersDate>(moment())

  const isBetweenSelectedDate = useCallback(
    (day) =>
      firstDate !== undefined && secondDate !== undefined
        ? day.isBetween(
            moment(firstDate)!.startOf('day').subtract(1, 'minute'),
            moment(secondDate)!.endOf('day')
          )
        : false,
    [firstDate, secondDate]
  )

  const dateRange = useMemo(
    () =>
      [
        firstDate !== undefined ? moment(firstDate!) : undefined,
        secondDate !== undefined ? moment(secondDate!) : undefined
      ].filter((d) => d !== undefined),
    [firstDate, secondDate]
  ) as MaterialUiPickersDate[]

  const handleDaySelect = useCallback(
    (selectedDate: any) => {
      const firstDateDiff = selectedDate.diff(firstDate!, 'days')
      const secondDateDiff = selectedDate.diff(secondDate!, 'days')
      setDateSelected(selectedDate)

      if (firstDate === undefined) {
        setFirstDate(selectedDate)
      } else {
        if (secondDate !== undefined) {
          if ([firstDateDiff, secondDateDiff].includes(0)) {
            setFirstDate(selectedDate.format('Y-MM-DD'))
            setSecondDate(undefined)
          } else if (secondDateDiff > 0) {
            setSecondDate(selectedDate.format('Y-MM-DD'))
          } else {
            setFirstDate(selectedDate.format('Y-MM-DD'))
          }
        } else if (secondDate === undefined) {
          if (firstDateDiff === 0) {
            setFirstDate(undefined)
            setSecondDate(undefined)
          } else if (firstDateDiff > 0) {
            setSecondDate(selectedDate.format('Y-MM-DD'))
          } else {
            setSecondDate(firstDate)
            setFirstDate(selectedDate.format('Y-MM-DD'))
          }
        }
      }

      onChange!(selectedDate)
    },
    [firstDate, secondDate, setFirstDate, setSecondDate, onChange]
  )

  const textFieldComponent = (otherProps) => {
    const { value, InputProps, onChange: change, ...props } = otherProps

    return (
      <TextField
        key={value}
        value={inputValue}
        onChange={() => {
          change!(dateSelected, value)
        }}
        InputProps={{ ...InputProps, ...(inputProps || {}) }}
        {...props}
      />
    )
  }

  const toolbarComponent: React.FC<DatePickerToolbarProps> = (props) => (
    <DatePickerToolbar
      onSubmit={onSubmit}
      dateRange={dateRange}
      doneStyle={{ color: dateRange.length > 0 ? '#73cd72' : '#fff' }}
      {...props}
    />
  )

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    isDayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ): JSX.Element => (
    <DayWrapper
      key={dateRange.toString() /* force render picker when dates are selected */}
      value={day}
      dayInCurrentMonth={isDayInCurrentMonth}
      onSelect={handleDaySelect}
    >
      <Box
        className={classnames('day-container', {
          selected:
            isBetweenSelectedDate(day) ||
            (firstDate !== undefined && day!.diff(firstDate!, 'days') === 0),
          'round-start':
            firstDate !== undefined &&
            secondDate !== undefined &&
            day!.diff(firstDate!, 'days') === 0,
          'round-end': secondDate !== undefined && day!.diff(secondDate!, 'days') === 0
        })}
      >
        {dayComponent}
      </Box>
    </DayWrapper>
  )

  useEffect(() => {
    if (onSelect) {
      onSelect(dateRange)
    }
  }, [onSelect, dateRange])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        className={'datepicker-input-wrapper'}
        ToolbarComponent={toolbarComponent}
        renderDay={renderDay}
        TextFieldComponent={textFieldComponent}
        format="M/D/YY"
        margin="normal"
        variant="inline"
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{ 'aria-label': 'change-date', className: 'date-picker-button' }}
        PopoverProps={{ className: 'datepicker-container', anchorEl: anchorEl }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default React.memo(DatePicker)
