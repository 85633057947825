import { SSODetails } from '../Models/apiEntities'
import { SSOConfig } from '../Common/environments/environments'
import { logger } from '../Common/log/Log'
import { EvercastAuthService } from './EvercastAuthService'

// These are arbitrary codes to help us detect which field is missing in case a support ticket is created
const ssoDetailsValidationMap = [
  { key: 'userPoolClientId', code: '103' },
  { key: 'userPoolId', code: '102' },
  { key: 'userPoolDomain', code: '101' },
  { key: 'userPoolRegion', code: '107' }
]

function verifySSODetails(ssoDetails: SSODetails) {
  const missingFields = ssoDetailsValidationMap.reduce((codes, validator) => {
    if (!ssoDetails[validator.key]) {
      codes.push(validator.code)
    }
    return codes
  }, [] as string[])
  return {
    missingFields,
    isDomainNotSet: missingFields.length >= ssoDetailsValidationMap.length
  }
}

export class EvercastAuthServiceFactory {
  private ssoConfig: SSOConfig

  /**
   * Creates new evercast auth service factory with given parameters
   * @param config - frontend config envs
   */
  constructor(config: SSOConfig) {
    this.ssoConfig = config
  }

  /**
   * Creates new evercast auth service or return null case backend configs are not set
   * @param ssoDetails - sso configs from backend
   * @param email - user email
   * @returns {EvercastAuthService | null}
   * @throws Error
   */
  createAuthServiceSafe(ssoDetails: SSODetails) {
    console.log(`Obtained SSO details: ${JSON.stringify(ssoDetails)}`)

    const { missingFields, isDomainNotSet } = verifySSODetails(ssoDetails)

    if (missingFields && missingFields.length > 0) {
      if (isDomainNotSet) {
        return null
      }

      const missingFieldsError = Error(`Missing fields in SSO Details ${missingFields.join(', ')}`)
      logger.error(missingFieldsError)
      throw missingFieldsError
    }

    return new EvercastAuthService(this.ssoConfig, ssoDetails)
  }

  /**
   * Creates new evercast auth service
   * @param ssoDetails - sso configs from backend
   * @param email - user email
   * @returns {EvercastAuthService}
   * @throws Error
   */
  createAuthService(ssoDetails: SSODetails, email: string) {
    console.log(`Obtained SSO details: ${JSON.stringify(ssoDetails)}`)

    const { missingFields, isDomainNotSet } = verifySSODetails(ssoDetails)

    if (missingFields && missingFields.length > 0) {
      if (isDomainNotSet) {
        const domainNoSetError = Error(`Domain not set up for given email ${email}`)
        logger.error(domainNoSetError)
        throw domainNoSetError
      }

      const missingFieldsError = Error(`Missing fields in SSO Details ${missingFields.join(', ')}`)
      logger.error(missingFieldsError)
      throw missingFieldsError
    }

    return new EvercastAuthService(this.ssoConfig, ssoDetails)
  }
}
