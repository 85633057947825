/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecordingsView_iceServers = ReadonlyArray<{
    readonly " $fragmentRefs": FragmentRefs<"Playback_iceServers">;
    readonly " $refType": "RecordingsView_iceServers";
}>;
export type RecordingsView_iceServers$data = RecordingsView_iceServers;
export type RecordingsView_iceServers$key = ReadonlyArray<{
    readonly " $data"?: RecordingsView_iceServers$data;
    readonly " $fragmentRefs": FragmentRefs<"RecordingsView_iceServers">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RecordingsView_iceServers",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Playback_iceServers"
    }
  ],
  "type": "IceServer",
  "abstractKey": null
};
(node as any).hash = 'd7677630d583b5cc7a522303e2459ead';
export default node;
