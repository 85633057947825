import React from 'react'
import { Box, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import CardTitle from '../../../../Components/CardTitle/CardTitle'
import ProgressButton from '../../../../Components/ProgressButton/ProgressButton'

import './TwoFactorVerificationView.scss'

interface TwoFactorVerificationProps {
  onTwoFactorCanceled: (...args: any) => void
  handleTwoFieldSubmit: (...args: any) => void
  mfaCode: any
}

const TwoFactorVerificationView: React.FC<TwoFactorVerificationProps> = ({
  onTwoFactorCanceled,
  handleTwoFieldSubmit,
  mfaCode,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <CardTitle className="two-factor-verification-view">
            Multi-factor authentication
          </CardTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="left">
            Enter the verification code generated in your Google Authenticator app.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField label="Enter verification code" variant="filled" {...mfaCode} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start">
                <Box mr={3}>
                  <ProgressButton
                    variant="outlined"
                    isLoading={false}
                    color="primary"
                    size="medium"
                    type="button"
                    onClick={onTwoFactorCanceled}
                    fullWidth
                  >
                    Cancel
                  </ProgressButton>
                </Box>
                <Box>
                  <ProgressButton
                    size="medium"
                    isLoading={false}
                    onClick={handleTwoFieldSubmit}
                    fullWidth
                    className="continue"
                  >
                    Verify
                  </ProgressButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(TwoFactorVerificationView)
