/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateRoomByAssignmentInput = {
    clientMutationId?: string | null;
    displayName: string;
    isPublic: boolean;
};
export type CreateRoomByAssignmentMutationVariables = {
    input: CreateRoomByAssignmentInput;
};
export type CreateRoomByAssignmentMutationResponse = {
    readonly createRoomByAssignment: {
        readonly liveroom: {
            readonly id: unknown;
            readonly hash: unknown;
        } | null;
        readonly query: {
            readonly currentProfile: {
                readonly featureFlags: {
                    readonly nodes: ReadonlyArray<{
                        readonly key: string | null;
                        readonly value: string | null;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type CreateRoomByAssignmentMutation = {
    readonly response: CreateRoomByAssignmentMutationResponse;
    readonly variables: CreateRoomByAssignmentMutationVariables;
};



/*
mutation CreateRoomByAssignmentMutation(
  $input: CreateRoomByAssignmentInput!
) {
  createRoomByAssignment(input: $input) {
    liveroom {
      id
      hash
    }
    query {
      currentProfile {
        featureFlags {
          nodes {
            key
            value
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRoomByAssignmentPayload",
    "kind": "LinkedField",
    "name": "createRoomByAssignment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Liveroom",
        "kind": "LinkedField",
        "name": "liveroom",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hash",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Profile",
            "kind": "LinkedField",
            "name": "currentProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ColumnProfileFeatureFlagsConnection",
                "kind": "LinkedField",
                "name": "featureFlags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ColumnProfileFeatureFlagsRecord",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRoomByAssignmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateRoomByAssignmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa529f9dfc5bac17e12dea93474694d6",
    "id": null,
    "metadata": {},
    "name": "CreateRoomByAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRoomByAssignmentMutation(\n  $input: CreateRoomByAssignmentInput!\n) {\n  createRoomByAssignment(input: $input) {\n    liveroom {\n      id\n      hash\n    }\n    query {\n      currentProfile {\n        featureFlags {\n          nodes {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '29c3abb7b030b1df55d50fabcbf526bf';
export default node;
