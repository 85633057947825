import React, { createContext, useMemo, useContext } from 'react'
import { Environment, EnvName } from '../Common/environments/environments'
import { getEnvironmentConfig } from '../Common/config/environmentConfig'

const environmentContext = createContext<Environment>(null!)

interface EnvironmentProviderProps {
  envName: EnvName
}

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ envName, children }) => {
  const environment = useMemo(() => getEnvironmentConfig(envName), [envName])
  return <environmentContext.Provider value={environment}>{children}</environmentContext.Provider>
}

export const useEnvironment = () => {
  return useContext(environmentContext)
}
