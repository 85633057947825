import { useEffect, useState } from 'react'
import useApi from '../../../../../../API/useApi'
import { RoomRest } from '../../../../../../Models/apiEntities'

export const useHome = (currentProfile) => {
  const [availableRooms, setAvailableRooms] = useState(currentProfile?.availableCredits || 0)
  const [rooms, setRooms] = useState<RoomRest[]>([])

  const { Dashboard } = useApi()

  // instead of refreshing the DashboardViewQuery after deleting a room, we can use state
  const updateAvailableRoomsAfterDelete = () => {
    setAvailableRooms((count) => count + 1)
    getRooms()
  }

  const refreshRooms = () => {
    getRooms()
  }

  const getRooms = async () => {
    const apiRooms = await Dashboard.getRooms()

    setRooms(
      apiRooms
        .map((r) => ({
          id: r.id,
          hash: r.hash,
          createdAt: r.created_at,
          deletedAt: r.deleted_at,
          displayName: r.display_name,
          creatorId: r.creator.id,
          organizationId: r.organization_id,
          sessions: r.recordings.map((recording) => ({
            id: recording.id,
            recordingId: recording.recording_id,
            displayName: recording.display_name,
            startedAt: recording.started_at,
            stoppedAt: recording.stopped_at,
            createdAt: recording.created_at,
            deletedAt: recording.deleted_at,
          })),
          sessionCount: r.recordings.length,
          noteCount: r.note_count,
          participantCount: r.participant_count,
          isPublic: r.is_public,
          joinedAt: r.joined_at,
          creator: {
            id: r.creator.id,
            displayName: r.creator.display_name,
          },
        }))
        .sort((a, b) => { return new Date(b.joinedAt || b.createdAt).getTime() - new Date(a.joinedAt || a.createdAt).getTime()} )
    )
  }

  useEffect(() => {
    let active = true

    if (active && currentProfile) {
      getRooms()
    }

    return () => {
      active = false
    }
  }, [currentProfile])

  return {
    rooms,
    availableRooms,
    updateAvailableRoomsAfterDelete,
    refreshRooms,
  }
}
