import { useCallback, useMemo, useRef, useState } from 'react'
import { useEffectWithPredicate } from '../../../../../../../Common/hooks/useEffectWithPredicate'
import { useIsMounted } from '../../../../../../../Common/hooks/useIsMounted'
import { useQueryParams } from '../../../../../../../Common/hooks/useQueryParams'
import { LiveroomClient } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import {
  JanusSignallingConnection,
  SignallingConnectionState
} from '../../../../../../../Common/janus/signalling/JanusSignallingConnection'
import { logger } from '../../../../../../../Common/log/Log'
import { RoomAccess } from '../../../../../../../Models/apiEntities'

export enum LiveroomState {
  disconnected,
  error,
  joining,
  joined,
  reallocating
}

interface useLiveroomClientParams {
  room
  roomInfo: RoomAccess
  currentProfile
  janusSignallingConnection: JanusSignallingConnection
  signallingConnectionState: SignallingConnectionState
}

/**
 * Number of initial connection retries before connection error will be shown
 */
export const MAX_INITIAL_CONNECTIONS_COUNT_BEFORE_ERROR = 10

export const useLiveroomClient = ({
  room,
  roomInfo,
  currentProfile,
  janusSignallingConnection,
  signallingConnectionState
}: useLiveroomClientParams) => {
  const [liveroomClient, setLiveroomClient] = useState<LiveroomClient | null>(null)
  const [hasJoined, setHasJoined] = useState(false)
  const isMounted = useIsMounted()
  const { invite } = useQueryParams()
  // to be able to compare prev and current state we keep it in ref
  const liveroomStateRef = useRef<LiveroomState>()
  // tracks if the signalling connection was ever connected
  const isConnectingForTheFirstTimeRef = useRef<boolean>(true)
  const signallingErrorCountRef = useRef<number>(0)

  const cleanup = useCallback(() => {
    if (isMounted.current) {
      logger.log('Cleaning up liveroom resources.')
      setHasJoined(false)
      setLiveroomClient(null)
    }
  }, [isMounted])

  // when signalling goes to error state, cleanup the resources, full rejoin upcoming
  useEffectWithPredicate(
    {
      predicate: () => signallingConnectionState === SignallingConnectionState.Error,
      effect: () => {
        logger.log('Signalling is gone.')
        signallingErrorCountRef.current = signallingErrorCountRef.current + 1
        cleanup()
      }
    },
    [signallingConnectionState, cleanup, signallingErrorCountRef]
  )

  function reallocate() {
    cleanup()
    janusSignallingConnection.reallocate()
  }

  // when signalling is connected and there's no liveroom client yet, create one
  // if there's a liveroom client it means session was reclaimed successfully and there's no need
  // to create a client
  useEffectWithPredicate(
    {
      predicate: () =>
        signallingConnectionState === SignallingConnectionState.Connected &&
        liveroomClient === null,
      effect: () => {
        logger.log('Creating new liveroom client.')
        
        const newLiveroomClient = new LiveroomClient({
          roomId: room.id,
          roomHash: room.hash,
          displayName: currentProfile.displayName,
          userId: currentProfile.id,
          janusSignallingConnection,
          roomInfo,
        })
        newLiveroomClient.onJoined().subscribe(() => {
          setHasJoined(true)
          isConnectingForTheFirstTimeRef.current = false
          signallingErrorCountRef.current = 0
        })
        newLiveroomClient.onRoomReallocate().subscribe(() => {
          reallocate()
        })
        setLiveroomClient(newLiveroomClient)
      }
    },
    [
      signallingConnectionState,
      janusSignallingConnection,
      currentProfile.id,
      currentProfile.displayName,
      room.id,
      room.hash
    ]
  )

  const { state, stateDescription } = useMemo(() => {
    if (hasJoined) {
      return { state: LiveroomState.joined, stateDescription: null }
    }

    if (signallingConnectionState === SignallingConnectionState.Disconnected) {
      return { state: LiveroomState.disconnected, stateDescription: 'You have left the room.' }
    }

    if (signallingConnectionState === SignallingConnectionState.Reallocating) {
      return { state: LiveroomState.reallocating, stateDescription: 'This room is being moved to a new server. Any recordings in progress will be stopped and automatically resumed on the new server.' }
    }

    const shouldShowError =
      !isConnectingForTheFirstTimeRef.current ||
      signallingErrorCountRef.current > MAX_INITIAL_CONNECTIONS_COUNT_BEFORE_ERROR
    const isSignallingInErrorState = signallingConnectionState === SignallingConnectionState.Error
    const isReconnecting =
      signallingConnectionState === SignallingConnectionState.Connecting &&
      liveroomStateRef.current === LiveroomState.error
    if (shouldShowError && (isSignallingInErrorState || isReconnecting)) {
      // when signalling is in error state or is reconnecting (prev state was error as well), show the right message
      return {
        state: LiveroomState.error,
        stateDescription: 'We are having troubles connecting to our servers. Trying to reconnect...'
      }
    }

    return {
      state: LiveroomState.joining,
      stateDescription: 'Joining the room...'
    }
  }, [
    hasJoined,
    signallingConnectionState,
    isConnectingForTheFirstTimeRef,
    signallingErrorCountRef
  ])
  liveroomStateRef.current = state

  return { liveroomClient, state, stateDescription }
}
