import React from 'react'
import { Box, Divider, Slide, SvgIconProps, Typography } from '@material-ui/core'
import { BrushOutlined, Close } from '@material-ui/icons'

import IconButton from '../Buttons/IconButton/IconButton'
import PushPin from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/LiveRoomDrawer/assets/PushPin'
import { useToggleStatus } from '../../Common/hooks/useToggleStatus'
import { Animations } from '../../Common/constants/animations'
import classnames from 'classnames'

import './SideDrawer.scss'
import palette from '../../Common/theme/colors/palette'

interface SideDrawerProps {
  title: string
  canBePinned?: boolean
  children: React.ReactNode
  pinnedIcon?: React.ComponentType<SvgIconProps>
  onClickCloseBtn: () => void
  direction?: 'left' | 'right'
}

/**
 * SideDrawer
 * @param title - title of the side drawer
 * @param children - component that will be rendered in the content area of side drawer
 * @param direction - direction that the the side drawer will appear from
 * @param pinnedIcon - icon that will be shown when the side drawer is pinned
 * @param canBePinned - flag that indicates if the side drawer can be pinned, it will be shown an icon instead of the side drawer
 * @param onClickCloseBtn - action that will be triggered when the user clicks in the close button on top right of side drawer
 * @constructor
 */
const SideDrawer: React.FC<SideDrawerProps> = ({
  title,
  children,
  direction = 'right',
  canBePinned = true,
  pinnedIcon = BrushOutlined,
  onClickCloseBtn
}) => {
  const [isSideBarShowing, showSideBar, hideSideBar] = useToggleStatus(true)
  const [isPinnedBtnShowing, showPinnedBtn, hidePinnedBtn] = useToggleStatus()
  const isRightDirection = direction === 'right'

  return (
    <Box height="100%" display="flex" flexDirection="column" className="side-drawer">
      <Slide
        in={isPinnedBtnShowing}
        direction={direction}
        timeout={Animations.fastest}
        onExited={showSideBar}
        unmountOnExit
      >
        <Box display="flex" justifyContent={isRightDirection ? 'flex-start' : 'flex-end'}>
          <IconButton
            icon={pinnedIcon}
            onClick={hidePinnedBtn}
            className={classnames('side-drawer-pinned-button', {
              'side-drawer-pinned-button-left': !isRightDirection,
              'side-drawer-pinned-button-right': isRightDirection
            })}
            data-testid="side-drawer-pinned-button"
          />
        </Box>
      </Slide>

      <Slide
        in={isSideBarShowing}
        direction={direction}
        timeout={Animations.fastest}
        onExited={showPinnedBtn}
        unmountOnExit
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={3.5}
            paddingY={2.5}
            bgcolor={palette.background.secondary}
          >
            <Typography variant="body1" className="side-drawer-typography">
              {title}
            </Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center">
              {canBePinned && (
                <Box mr={1}>
                  <IconButton
                    icon={PushPin}
                    onClick={hideSideBar}
                    data-testid="side-drawer-pin-button"
                    size="small"
                    className="side-drawer-icon"
                  />
                </Box>
              )}
              <IconButton
                icon={Close}
                onClick={onClickCloseBtn}
                size="small"
                className="side-drawer-icon"
                data-testid="side-drawer-close-button"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            height="100%"
            flex="1"
            bgcolor={palette.background.primary}
            className="side-drawer-content"
          >
            {children}
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}

export default React.memo(SideDrawer)
