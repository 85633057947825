/* eslint-disable */

let LiterallyCanvas
// TODO Perhaps create proper teardown method if needed
const createOrGetLCInstance = function () {
  if (LiterallyCanvas) {
    return LiterallyCanvas
  }

  LiterallyCanvas = require('./lc-core')
  require('./customizations')
  return LiterallyCanvas
}

export default createOrGetLCInstance
