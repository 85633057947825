import { useCallback } from 'react'
import { commitMutation } from 'react-relay'
import { MutationConfig, MutationParameters } from 'relay-runtime'
import { useRelayEnvironment } from '../../Providers/RelayEnvironmentProvider'

/**
 * Return function to perform mutations in current relay environment
 */
export const useCommitMutation = <TParameters extends MutationParameters = any>() => {
  const relayEnvironment = useRelayEnvironment()
  // set relay environment
  return useCallback(
    (config: MutationConfig<TParameters>) => {
      return commitMutation(relayEnvironment, config)
    },
    [relayEnvironment]
  )
}
