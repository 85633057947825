import { useMutation } from '../../../Common/hooks/useMutation'
import ApproveDeviceLoginCodeMutation from '../../../GraphQL/mutations/ApproveDeviceLoginCodeMutation'

const createConfig = (onSuccess?: () => void) => {
  return {
    onCompleted: (response, errors) => {
      if (onSuccess && errors === null) onSuccess()
    }
  }
}

export const useApproveConnectingAppleTVMutation = (onSuccess?: () => void) =>
  useMutation<any, any>(ApproveDeviceLoginCodeMutation, createConfig(onSuccess))

export const mapToVariables = (deviceCodeId: string, status: boolean) => ({
  deviceCodeId,
  status
})
