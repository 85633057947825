import { AudioStream } from '../stream'
import { AudioConstraintsParams, useAudioConstraints } from './useAudioConstraints'
import { useStreamFromConstraints } from './useStreamFromConstraints'

export const useAudioStream = ({
  speaker,
  ...audioConstraintsParams
}: AudioConstraintsParams & { speaker: MediaDeviceInfo | null }) => {
  const audioConstraints = useAudioConstraints(audioConstraintsParams)
  return useStreamFromConstraints(audioConstraints, speaker) as AudioStream
}
