/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, EffectCallback, DependencyList, useRef } from 'react'

interface UseOneTimeEffectParams<T> {
  effect: EffectCallback
  compare: (lastRunObject: T | null) => boolean
  predicate: () => boolean
  target: T | null
}

/**
 * Runs effect only once for target object. Compare function has to be provided to evaluate whether target object has changed.
 * @param effect
 * @param predicate
 * @param compare
 * @param target
 * @param dependencies
 */
export const useOneTimeEffect = <T>(
  { effect, predicate, compare, target }: UseOneTimeEffectParams<T>,
  dependencies: DependencyList
) => {
  const lastRunObjectRef = useRef<T | null>(null)

  useEffect(() => {
    if (!compare(lastRunObjectRef.current)) {
      return
    }

    if (!predicate()) {
      return
    }

    lastRunObjectRef.current = target

    return effect()
  }, dependencies)
}
