import React, { useCallback } from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import Lobby from './Lobby/Lobby'
import LiveRoom from './LiveRoom/LiveRoom'
import { Room as RoomModel, RoomFullInfo } from '../../../../../Models/apiEntities'
import { RoomErrorState, RoomState, useRoomRoutingState } from './hooks/useRoomRoutingState'
import RoomAccessDenied from '../RoomAccessDenied/RoomAccessDenied'
import RoomNotFound from '../RoomNotFound/RoomNotFound'
import RoomDeleted from '../RoomDeleted/RoomDeleted'
import { ShowProgress } from '../../../../../Providers/ProgressProvider'
import { MediaSetupState, useMediaSetup } from './hooks/useMediaSetup'
import { useProfilePreferences } from '../../../../../Common/preferences/usePreferences'
import { useJanusInit } from '../../../../../Common/janus/signalling/hooks/useJanusInit'
import { useLocalDevicesState } from './hooks/useLocalDevicesState'
import RoomInvalidInvite from '../RoomInvalidInvite/RoomInvalidInvite'
import RoomInviteUsed from '../RoomInviteUsed/RoomInviteUsed'
import PrivateRoomMFA from '../PrivateRoomMFA/PrivateRoomMFA'
import PrivateRoomMissingMFA from '../PrivateRoomMissingMFA/PrivateRoomMissingMFA'

const RoomErrorStateComponents = {
  [RoomErrorState.AccessDenied]: RoomAccessDenied,
  [RoomErrorState.NotFound]: RoomNotFound,
  [RoomErrorState.Deleted]: RoomDeleted,
  [RoomErrorState.InviteUsed]: RoomInviteUsed,
  [RoomErrorState.InviteInvalid]: RoomInvalidInvite,
  [RoomErrorState.PrivateRoomMissingMFA]: PrivateRoomMissingMFA,
}

interface RoomRouterProps {
  refreshGraphQLQuery: () => void
  room: RoomModel
  currentProfile
  iceServers: any
  profilePermissions: any
}

/**
 * Main room component.
 * 1. When user enters the room, it inits the Janus lib and displays the Lobby
 * 2. When user clicks 'Go Live' in Lobby it displays the Liveroom which connects to WebRTC server and starts publishing media.
 * @param retry
 * @param room
 * @param iceServers
 * @param currentProfile
 * @constructor
 */
const Room: React.FC<RoomRouterProps> = ({
  refreshGraphQLQuery,
  room,
  iceServers,
  currentProfile,
  profilePermissions: profileApiBasedPermissions,
}) => {
  useJanusInit()

  // user preferences, used to provide initial values for media selection, and all other user preferences
  const profilePreferences = useProfilePreferences(currentProfile.profilePreferences.nodes)
  // perform a bunch of checks (is user can join the room, if room exists) and executes the cache room permission mutation
  // to make sure joining the room is possible
  const { roomRoutingState, errorState, actions, verifyMFA, roomInfo, getRoomAccess } = useRoomRoutingState({
    retry: refreshGraphQLQuery,
    room,
  })
  // manages local devices state - camera state, mic state, audio state
  const { localDevicesState, localDevicesActions } = useLocalDevicesState(profilePreferences)
  // single source of truth for user media devices selection (selected camera device, selected microphone device, selected speaker)

  const {
    mediaSetupState,
    mediaDevices,
    mediaSelection,
    actions: { handleMediaSelectionChange },
  } = useMediaSetup({ profilePreferences, roomState: roomRoutingState, canPublish: !!roomInfo?.user?.canPublish })

  const renderRoomContent = useCallback(() => {
    if (errorState !== null) {
      const ErrorComponent = RoomErrorStateComponents[errorState]
      return <ErrorComponent />
    }

    if (
      roomRoutingState === RoomState.Fetching ||
      (mediaSetupState !== MediaSetupState.success && mediaSetupState !== MediaSetupState.timeout)
    ) {
      return <ShowProgress />
    }

    if (roomRoutingState === RoomState.MFAInput) {
      return <PrivateRoomMFA verifyMFA={verifyMFA} />
    }

    if ( (roomRoutingState === RoomState.Lobby || roomRoutingState === RoomState.DndMode ) &&
      (mediaSetupState === MediaSetupState.success || mediaSetupState === MediaSetupState.timeout)
    ) {

      const fullRoom = roomInfo?.room as RoomFullInfo
      const participantCount = roomRoutingState === RoomState.DndMode ? 0 : fullRoom?.participants?.length || 0
      const lobbyRoom = roomRoutingState === RoomState.DndMode ?
        {
          id: roomInfo?.room?.id as string,
          hash: roomInfo?.room?.hash as string,
          displayName: roomInfo?.room?.isPublic ? roomInfo?.room?.displayName as string : 'Private Locked Room',
          participantCount: 0
        } : room

      return (
        <Lobby
          room={lobbyRoom}
          canPublish={!!roomInfo?.user?.canPublish}
          participantCount={participantCount}
          dndMode={roomRoutingState === RoomState.DndMode}
          handleGoLiveClick={actions.handleGoLiveClick}
          localDevicesState={localDevicesState}
          localDevicesActions={localDevicesActions}
          mediaSetupState={mediaSetupState}
          mediaSelection={mediaSelection}
          mediaDevices={mediaDevices!}
          handleMediaSelectionChange={handleMediaSelectionChange}
          refreshGraphQLQuery={refreshGraphQLQuery}
          getRoomAccess={getRoomAccess}
        />
      )
    }

    return (
      <LiveRoom
        localDevicesState={localDevicesState}
        localDevicesActions={localDevicesActions}
        mediaDevices={mediaDevices}
        mediaSelection={mediaSelection!}
        room={room}
        roomInfo={roomInfo!}
        currentProfile={currentProfile}
        profilePreferences={profilePreferences}
        iceServers={iceServers}
        profileApiBasedPermissions={profileApiBasedPermissions.nodes || []}
        handleMediaSelectionChange={handleMediaSelectionChange}
      />
    )
  }, [errorState, mediaSetupState, verifyMFA, roomInfo, room, actions.handleGoLiveClick, localDevicesState, localDevicesActions, mediaSelection, mediaDevices, handleMediaSelectionChange, refreshGraphQLQuery, currentProfile, profilePreferences, iceServers, profileApiBasedPermissions.nodes, roomRoutingState, getRoomAccess])

  return <>{renderRoomContent()}</>
}

export default createFragmentContainer(React.memo(Room), {
  currentProfile: graphql`
    fragment Room_currentProfile on Profile {
      ...LiveRoom_currentProfile
      id
      email
      profilePreferences {
        nodes {
          preferenceTypeName
          preferenceTypeId
          preferenceTypeDefault
          preferenceValue
        }
      }
    }
  `,
  iceServers: graphql`
    fragment Room_iceServers on IceServer @relay(plural: true) {
      ...LiveRoom_iceServers
    }
  `,
  room: graphql`
    fragment Room_room on Liveroom {
      ...LiveRoom_room
      ...Lobby_room
      id
      displayName
    }
  `,
})
