import React from 'react'
import { Menu as MuiMenu, MenuItem, Box } from '@material-ui/core'
import { PopoverOrigin } from '@material-ui/core/Popover/Popover'
import { useMenuTrigger } from '../../Providers/MenuTriggerProvider'

import { useMenu } from './hooks/useMenu'

export interface Option {
  text: string
  icon?: React.ReactNode
  onClick?: any
  divider?: boolean
  isAvailable?: () => boolean
  disabled?: boolean
}

interface MenuProps {
  button: (openMenu: (event: any) => void) => React.ReactElement
  menuOptions: Option[]
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  itemStyles?: any
}

const menuTextStyle = { marginLeft: 15 }

/**
 * Menu
 * @param button the button that will trigger the menu
 * @param menuOptions array with the options to show inside the menu
 * @param anchorOrigin object with origin settings
 * @param transformOrigin object with transform origin settings
 * @constructor
 */
const Menu: React.FC<MenuProps> = ({
  button,
  menuOptions,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  itemStyles = { paddingTop: '1rem', paddingBottom: '1rem' },
}) => {
  const { anchorEl, handleClick, handleClose } = useMenu()
  const { setTriggerHide } = useMenuTrigger()

  return (
    <>
      {button(handleClick)}
      <MuiMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {menuOptions
          .filter((x) => x.isAvailable === undefined || x.isAvailable())
          .map((item, i) => {
            return (
              <MenuItem
                disabled={item.disabled}
                key={i}
                onClick={(e) => {
                  handleClose(e, item)
                  setTriggerHide?.()
                }}
                divider={item.divider}
                style={itemStyles}
              >
                <Box lineHeight={0.9} display="flex" alignItems="center">
                  {item.icon ? (
                    <>
                      <span>{item.icon}</span>
                      <span style={menuTextStyle}>{item.text}</span>
                    </>
                  ) : (
                    <>{item.text}</>
                  )}
                </Box>
              </MenuItem>
            )
          })}
      </MuiMenu>
    </>
  )
}

export default React.memo(Menu)
