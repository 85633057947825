import React from 'react'
import { Grid } from '@material-ui/core'
import { routes } from '../../routes'
import AdaptiveTitle from '../../../../../Components/AdaptiveTitle/AdaptiveTitle'
import { Link } from 'react-router-dom'
import LandingView from '../../../LandingView/LandingView'
import UnauthenticatedHeader from '../../../UnauthenticatedView/Header/UnauthenticatedHeader'
import styled from 'styled-components'

const Button = styled.div`
  background: #fff;
  display: inline-block;
  padding: 7px 15px;
  color: #000;
  font-weight: bold;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: #eee;
  }
`

const PrivateRoomMissingMFA: React.FC = () => {
  return (
    <LandingView>
      <Grid item xs={12}>
        <UnauthenticatedHeader />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <AdaptiveTitle>Private multi-factor authentication room</AdaptiveTitle>
        </Grid>
        <Grid item xs={12}>
          This room requires multi-factor authentication (MFA). Click below to set it up.
        </Grid>
        <Grid item xs={12}>
          <Link to={routes.twoFactorConfiguration.path}>
            <Button>Set up MFA</Button>
          </Link>
        </Grid>
      </Grid>
    </LandingView>
  )
}

export default React.memo(PrivateRoomMissingMFA)
