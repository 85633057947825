import React, { useCallback } from 'react'
import MenuItemWithSubMenu from '../../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import BoldIcon from '../../../../assets/BoldIcon'
import ItalicizeIcon from '../../../../assets/ItalicizeIcon'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../../Providers/DrawCanvasToolsProvider'

const TypographyEmphasis: React.FC = () => {
  const {
    drawPreferences: { drawFontIsBold, drawFontIsItalic },
    setDrawFont
  } = useDrawCanvasToolsContext()

  const setDrawFontIsBoldHandler = useCallback(() => {
    setDrawFont({ drawFontIsBold: !drawFontIsBold })
  }, [drawFontIsBold, setDrawFont])

  const setDrawFontIsItalicHandler = useCallback(() => {
    setDrawFont({ drawFontIsItalic: !drawFontIsItalic })
  }, [drawFontIsItalic, setDrawFont])

  return (
    <>
      <MenuItemWithSubMenu
        icon={<BoldIcon />}
        label="Bold"
        dense={true}
        selected={drawFontIsBold}
        onClick={setDrawFontIsBoldHandler}
      />
      <MenuItemWithSubMenu
        icon={<ItalicizeIcon />}
        label="Italicize"
        dense={true}
        selected={drawFontIsItalic}
        onClick={setDrawFontIsItalicHandler}
      />
    </>
  )
}

export default React.memo(TypographyEmphasis)
