export default {
  root: {
    primary: '#121212',
    secondary: '#FC2C03'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#A8A8A8'
  },
  background: {
    default: '#121212',
    primary: '#252525',
    secondary: '#2E2E2E'
  },
  input: {
    color: '#FFFFFF',
    background: 'rgba(255, 255, 255, 0.12)',
    underline: 'rgba(255, 255, 255, 0.16)',
    underlineHover: 'rgba(255, 255, 255, 0.2)'
  },
  dialog: {
    background: 'rgba(255, 255, 255, 0.1)'
  },
  info: {
    confirm: '#43A047',
    notice: '#EEAA22',
    warn: '#FF7700',
    deny: '#DD2C00'
  },
  divider: '#3F3F3F'
}
