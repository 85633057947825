import React, { useState, useCallback } from 'react'
import { ChromePicker } from 'react-color'
import { throttle } from 'lodash'

interface ColorPickerProps {
  onChangeComplete: (color) => void
  initialColor: string
}

/**
 * ColorPicker - chrome style rgba color picker
 * @param onChangeComplete - action that will be triggered once after the user finishes selecting color
 * @param initialColor - value that will be shown as default
 * @constructor
 */
const ColorPicker: React.FC<ColorPickerProps> = ({ onChangeComplete, initialColor }) => {
  const [color, setColor] = useState(initialColor)

  const onChange = useCallback(
    (color) => {
      return setColor(color.rgb)
    },
    [setColor]
  )

  const onChangeCompleteHandler = useCallback(
    throttle((color: { rgb: any }) => onChangeComplete(color.rgb), 150),
    [onChangeComplete]
  )

  return (
    <ChromePicker
      color={color}
      onChange={onChange}
      onChangeComplete={onChangeCompleteHandler}
      defaultView="rgb"
    />
  )
}

export default ColorPicker
