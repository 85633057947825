import React from 'react'
import { Box } from '@material-ui/core'
import UnderConstruction from '../../../UnderConstruction/UnderConstruction'

const Permissions: React.FC = () => (
  <Box paddingY={4} paddingX={0}>
    <UnderConstruction />
  </Box>
)

export default Permissions
