import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../routes'

export const useAccount = (initialTab) => {
  const [index, setIndex] = useState(initialTab)
  const history = useHistory()

  const handleTabChange = (event, newValue) => {
    setIndex(newValue)

    const url = routes.account.path.replace(':tab', newValue)
    history.push(url)
  }

  return {
    index,
    handleTabChange
  }
}
