import { useMutation } from '../../../../../../../Common/hooks/useMutation'
import UpdateSessionMutation from '../../../../../../../GraphQL/mutations/UpdateSessionMutation'
import { isNil } from 'ramda'

export const useUpdateRecordingMutation = (roomId) => {
  return useMutation<any, any>(UpdateSessionMutation, {
    updater: (store) => {
      const updateSession = store.getRootField('updateSessionById')
      const queryField = updateSession.getLinkedRecord('query')
      const liveroomById = queryField.getLinkedRecord('liveroomById', { id: roomId })
      const sessionsByRoomId = liveroomById.getLinkedRecord(
        'sessionsByRoomId(orderBy:["CREATED_AT_DESC"])'
      )
      const nodes = sessionsByRoomId.getLinkedRecords('nodes')

      const oldLiveroomById = store.get(roomId)!
      const oldSessionsByRoomId = oldLiveroomById.getLinkedRecord(
        'sessionsByRoomId(orderBy:["CREATED_AT_DESC"])'
      )!

      if (!isNil(oldSessionsByRoomId)) {
        oldSessionsByRoomId.setLinkedRecords(nodes, 'nodes')
      }
    }
  })
}
