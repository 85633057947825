import React from 'react'
import CardNotification from '../../../../../Components/CardNotification/CardNotification'
import ErrorIcon from '@material-ui/icons/Error'
import { Link } from 'react-router-dom'
import { routes } from '../../routes'

/**
 * Evercast verify email error view
 * @constructor
 */
const VerifyEmailError: React.FC = () => {
  return (
    <CardNotification
      icon={<ErrorIcon />}
      title={'Something went wrong!'}
      description={'Sorry, we were unable to verify your email'}
      footer={
        <>
          Back to&nbsp;
          <Link to={routes.login.path}>evercast.us</Link>
        </>
      }
    />
  )
}

export default React.memo(VerifyEmailError)
