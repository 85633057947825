import { useState, useEffect, useMemo } from 'react'
import { getBrowserInformation } from '../../../Common/utils/browserCheck'
import { useQueryParams } from '../../../Common/hooks/useQueryParams'

/**
 * Check if browser is supported
 */
export const useBrowserCapabilities = () => {
  const queryParams = useQueryParams()
  const [noBrowserCheck, setNoBrowserCheck] = useState(false)
  const browserCapabilities = useMemo(getBrowserInformation, [])

  useEffect(() => {
    if (queryParams.noBrowserCheck) {
      setNoBrowserCheck(true)
    }
  }, [queryParams])

  return {
    browserCapabilities: useMemo(() => {
      if (noBrowserCheck) {
        return { ...browserCapabilities, isSupported: true }
      }
      return browserCapabilities
    }, [noBrowserCheck, browserCapabilities])
  }
}
