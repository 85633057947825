import React from 'react'
import { Box, Grid } from '@material-ui/core'
import CardNotification from '../CardNotification/CardNotification'
import PacmanIcon from './PacmanIcon.svg'
import { BrowserCapabilities, BrowserType } from '../../Common/utils/browserCheck'
import Logo from '../../Components/Logo/Logo'
import AppStoreBadge from './AppStoreBadge.svg'
import './BrowserNotSupported.scss'

/**
 * Browser not supported
 */
const BrowserNotSupported: React.FC<Partial<BrowserCapabilities>> = ({ browserType }) => {
  let icon: JSX.Element | null = null
  let title: string
  let description: JSX.Element

  switch (browserType) {
    case BrowserType.desktopChrome:
      icon = <img src={PacmanIcon} alt="Update Icon" />
      title = 'Oops! Chrome needs to be updated'
      description = (
        <>
          Please&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
            rel="noopener noreferrer"
            target="blank"
          >
            click here
          </a>
          &nbsp;to update your Chrome browser
        </>
      )
      break
      case BrowserType.desktopSafari:
        icon = <img src={PacmanIcon} alt="Update Icon" />
        title = 'Oops! Safari needs to be updated'
        description = (
          <>
            Please&nbsp;
            <a
              href="https://support.apple.com/en-us/HT204416"
              rel="noopener noreferrer"
              target="blank"
            >
              click here
            </a>
            &nbsp;to learn how to update to a supported version of Safari
          </>
        )
        break
    case BrowserType.iPhoneSafari:
    case BrowserType.iPadSafari:
      title = 'Download Evercast'
      description = (
        <Grid
          className="status-component"
          item
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
        >
          Please download the Evercast app to enter your room
          <a href="https://apps.apple.com/us/app/evercast/id1534049229">
            <img
              src={AppStoreBadge}
              alt="Download from the App Store"
              style={{ marginTop: 20, height: 46, width: 138 /* aspect ratio 1:3 */ }}
            />
          </a>
        </Grid>
      )
      break
    default:
      title = 'Oops! Your browser isn\'t supported'
      description = (
        <>
          Please&nbsp;
          <a
            href="https://www.google.com/chrome/"
            rel="noopener noreferrer"
            target="blank"
            className="bold"
          >
            download
          </a>
          &nbsp;the Google Chrome browser to use Evercast. Need help?
        </>
      )
      break
  }

  return (
    <Grid className="app-error" container direction="row" justify="center">
      <Grid item>
        <Box mb={5}>
          <Logo className="logo" />
        </Box>
        <CardNotification
          icon={icon}
          title={title}
          description={description}
          footer={
            <>
              Check out our&nbsp;
              <a
                href={process.env.REACT_APP_SUPPORT_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="bold"
              >
                  <strong>help center</strong>
              </a>
              .
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

export default React.memo(BrowserNotSupported)
