import graphql from 'babel-plugin-relay/macro'

const DashboardViewQuery = graphql`
  query DashboardViewQuery {
    currentProfile {
      ...HomeView_currentProfile

      featureFlags {
        nodes {
          key
          value
        }
      }
    }
    canCreateRoom
  }
`

export default DashboardViewQuery
