import { useMemo } from 'react'
import { defaultDeviceId } from '../../../Views/MainView/AuthenticatedView/RoomView/Room/hooks/useMediaDevices'

export interface VideoConstraintsParams {
  camera?: MediaDeviceInfo | null
}

export const useVideoConstraints = ({ camera }: VideoConstraintsParams) => {
  return useMemo(() => {
    const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
    if (!camera) {
      return null
    }
    const video = {
      width: {
        min: 320, // low rest constraints to avoid OverconstrainedError
        ideal: 1280, // 720p cameras are used in most laptops
        max: 4096 // allow also 4k
      },
      height: {
        min: 320,
        ideal: 720,
        max: 2160
      }
    } as any

    if (camera.deviceId !== defaultDeviceId) {
      video.deviceId = {
        exact: camera.deviceId
      }
    }
    if (supportedConstraints.aspectRatio) {
      video.aspectRatio = 16 / 9
    }
    // useful for mobile devices - chooses front facing camera instead of the rear one
    if (supportedConstraints.facingMode) {
      video.facingMode = { ideal: 'user' }
    }
    if (supportedConstraints.frameRate) {
      video.frameRate = 30
    }
    return {
      audio: false,
      video
    }
  }, [camera])
}
