import React from 'react'
import AcceptSpecialInviteContainer from '../../../../Components/AcceptSpecialInvite/AcceptSpecialInviteContainer'

/**
 * Accept invite view for authenticated users. This view only provides a different layout for error page (because of the header and navigation when logged).
 * Besides that, it works exactly as v2, the accept invite only redirect a logged user to the room.
 */
const AcceptSpecialInviteView: React.FC = () => {
  return (
    <AcceptSpecialInviteContainer/>
  )
}

export default React.memo(AcceptSpecialInviteView)
