import React from 'react'

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import { Badge } from '@material-ui/core'
import ToggleIconButton, { ToggleIconButtonProps } from '../ToggleIconButton/ToggleIconButton'
import OutlinedToggleIconButton from '../OutlinedToggleIconButton/OutlinedToggleIconButton'

interface ChatToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
  hasNotification?: boolean
}

const ChatToggleButton: React.FC<ChatToggleButtonProps> = ({
  noOutline = false,
  hasNotification = false,
  ...otherProps
}) => {
  const Component = noOutline ? ToggleIconButton : OutlinedToggleIconButton

  return (
    <Component
      icon={ChatOutlinedIcon}
      customRender={(innerElement) => {
        const InnerElement = innerElement
        return (
          <Badge
            color="secondary"
            badgeContent=" "
            overlap="circle"
            invisible={!hasNotification}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {InnerElement}
          </Badge>
        )
      }}
      label="Chat"
      {...otherProps}
    />
  )
}

export default React.memo(ChatToggleButton)
