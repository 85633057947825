import React, { useMemo } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import HouseIcon from '@material-ui/icons/House'
import MeetingRoom from '@material-ui/icons/MeetingRoom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '../Menu/Menu'
import { useHeaderNavigation, HeaderMenuOptions } from './hooks/useHeaderNavigation'
import { Avatar, Box, Slide, Typography } from '@material-ui/core'
import { Animations } from '../../Common/constants/animations'
import { useIsHeaderOpen } from '../../Views/MainView/MainLayout/MainLayout'
import CSMAlerts from '../CSMAlerts/CSMAlerts'
import './Header.scss'
import usePermissionsRest from '../../Common/hooks/usePermissionsRest'
import { StyledPill, useHeaderInfo } from './hooks/useHeaderInfo'
export interface HeaderProps {
  onLogout: () => void
  currentProfile: {
    displayName: string
  }
  children?: React.ReactNode
}

/**
 * Header for the dashboard
 * @param onLogout To do logout of the user
 * @param currentProfile
 * @param children
 * @constructor
 */
const Header: React.FC<HeaderProps> = ({ onLogout, currentProfile, children }) => {
  const { canSeeAdminPortal } = usePermissionsRest()
  const {
    goToAccountClick,
    goToDashboardClick,
    goToAdminPortalClick,
    selectedOption,
  } = useHeaderNavigation()
  const isHeaderOpen = useIsHeaderOpen()

  const menuOptions = useMemo(
    () =>
      [
        {
          text: HeaderMenuOptions.Dashboard,
          icon: <HouseIcon />,
          onClick: () => goToDashboardClick(),
        },
        {
          text: HeaderMenuOptions.Account,
          icon: <AccountCircleIcon />,
          onClick: () => goToAccountClick('settings'),
        },
        {
          text: HeaderMenuOptions.AdminPortal,
          icon: <MeetingRoom />,
          onClick: () => goToAdminPortalClick(),
        },
        { text: HeaderMenuOptions.Logout, icon: <ExitToAppIcon />, onClick: onLogout },
      ].filter((option) => {
        if (option.text === HeaderMenuOptions.AdminPortal && !canSeeAdminPortal) return false
        return (
          (selectedOption !== HeaderMenuOptions.Room && option.text !== selectedOption) ||
          (selectedOption === HeaderMenuOptions.Room && option.text !== HeaderMenuOptions.Account)
        )
      }),
    [
      goToAccountClick,
      goToDashboardClick,
      goToAdminPortalClick,
      canSeeAdminPortal,
      onLogout,
      selectedOption,
    ]
  )

  const { accountMe, tablet, mobile, hideAll } = useHeaderInfo(currentProfile)

  return (
    <>
      <Slide direction="down" in={isHeaderOpen} mountOnEnter timeout={Animations.slow}>
        <AppBar position="absolute">
          <Toolbar className="header" style={{ padding: 0, minHeight: 80 }}>
            <Box width="100%" paddingX={3}>
              <Grid container justify="flex-end" alignItems="center" spacing={5}>
                {accountMe && (
                  <>
                    {hideAll && (
                      <>
                        <Grid item>
                          <Box>
                            {currentProfile.displayName} {tablet && <>| {accountMe.deviceId}</>}
                          </Box>
                          <Box>{accountMe.email}</Box>
                        </Grid>
                        {mobile && (
                          <>
                            {accountMe.isUserGuest && (
                              <Grid item>
                                <StyledPill>Guest</StyledPill>
                              </Grid>
                            )}
                            {accountMe.didUserLogInWithSSO && (
                              <Grid item>
                                <StyledPill>SSO</StyledPill>
                              </Grid>
                            )}
                            {accountMe.isUserAuthVerified && (
                              <Grid item>
                                <StyledPill>MFA</StyledPill>
                              </Grid>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Grid item>
                  <CSMAlerts />
                </Grid>
                <Grid item>
                  <Menu
                    button={(openMenu) => (
                      <Avatar className="header-avatar" variant="square" onClick={openMenu}>
                        <Typography variant={'body1'}>{currentProfile.displayName[0]}</Typography>
                      </Avatar>
                    )}
                    menuOptions={menuOptions}
                  />
                </Grid>
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box
        paddingTop={isHeaderOpen ? '80px' /* should be the height of the AppBar */ : 0}
        width="100%"
        height="100%"
      >
        {children}
      </Box>
    </>
  )
}

export default React.memo(Header)
