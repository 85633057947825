/* eslint-disable */

const objectFitContain = require('../../objectFitContain').default

var Text,
  Tool,
  createShape,
  getIsPointInBox,
  extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key]
    }
    function ctor() {
      this.constructor = child
    }
    ctor.prototype = parent.prototype
    child.prototype = new ctor()
    child.__super__ = parent.prototype
    return child
  },
  hasProp = {}.hasOwnProperty

Tool = require('./base').Tool

createShape = require('../core/shapes').createShape

getIsPointInBox = function (point, box) {
  if (point.x < box.x) {
    return false
  }
  if (point.y < box.y) {
    return false
  }
  if (point.x > box.x + box.width) {
    return false
  }
  if (point.y > box.y + box.height) {
    return false
  }
  return true
}

module.exports = Text = (function (superClass) {
  extend(Text, superClass)

  Text.prototype.name = 'Text'

  Text.prototype.iconName = 'text'

  function Text() {
    this.text = ''
    this.font = 'bold 18px sans-serif'
    this.currentShape = null
    this.currentShapeState = null
    this.initialShapeBoundingRect = null
    this.dragAction = null
    this.didDrag = false
  }

  Text.prototype.didBecomeActive = function (lc) {
    var switchAway, unsubscribeFuncs, updateInputEl
    unsubscribeFuncs = []
    this.unsubscribe = (function (_this) {
      return function () {
        var func, i, len, results
        results = []
        for (i = 0, len = unsubscribeFuncs.length; i < len; i++) {
          func = unsubscribeFuncs[i]
          results.push(func())
        }
        return results
      }
    })(this)
    switchAway = (function (_this) {
      return function () {
        _this._ensureNotEditing(lc)
        _this._clearCurrentShape(lc)
        return lc.repaintLayer('main')
      }
    })(this)
    updateInputEl = (function (_this) {
      return function () {
        return _this._updateInputEl(lc)
      }
    })(this)
    unsubscribeFuncs.push(lc.on('drawingChange', switchAway))
    unsubscribeFuncs.push(lc.on('zoom', updateInputEl))
    unsubscribeFuncs.push(lc.on('imageSizeChange', updateInputEl))
    unsubscribeFuncs.push(
      lc.on(
        'snapshotLoad',
        (function (_this) {
          return function () {
            _this._clearCurrentShape(lc)
            return lc.repaintLayer('main')
          }
        })(this)
      )
    )
    unsubscribeFuncs.push(
      lc.on(
        'primaryColorChange',
        (function (_this) {
          return function (newColor) {
            if (!_this.currentShape) {
              return
            }
            _this.currentShape.color = newColor
            _this._updateInputEl(lc)
            return lc.repaintLayer('main')
          }
        })(this)
      )
    )
    return unsubscribeFuncs.push(
      lc.on(
        'setFont',
        (function (_this) {
          return function (font) {
            if (!_this.currentShape) {
              return
            }
            _this.font = font
            _this.currentShape.setFont(font)
            _this._setShapesInProgress(lc)
            _this._updateInputEl(lc)
            return lc.repaintLayer('main')
          }
        })(this)
      )
    )
  }

  Text.prototype.willBecomeInactive = function (lc) {
    if (this.currentShape) {
      this._ensureNotEditing(lc)
      this.commit(lc)
    }
    return this.unsubscribe()
  }

  Text.prototype.setText = function (text) {
    return (this.text = text)
  }

  Text.prototype._ensureNotEditing = function (lc) {
    if (this.currentShapeState === 'editing') {
      return this._exitEditingState(lc)
    }
  }

  Text.prototype._clearCurrentShape = function (lc) {
    this.currentShape = null
    this.initialShapeBoundingRect = null
    this.currentShapeState = null
    return lc.setShapesInProgress([])
  }

  Text.prototype.commit = function (lc) {
    if (this.currentShape.text) {
      lc.saveShape(this.currentShape)
    }
    this._clearCurrentShape(lc)
    return lc.repaintLayer('main')
  }

  Text.prototype._getSelectionShape = function (ctx, backgroundColor) {
    if (backgroundColor == null) {
      backgroundColor = null
    }
    return createShape('SelectionBox', {
      shape: this.currentShape,
      ctx: ctx,
      backgroundColor: backgroundColor
    })
  }

  Text.prototype._setShapesInProgress = function (lc) {
    switch (this.currentShapeState) {
      case 'selected':
        return lc.setShapesInProgress([this._getSelectionShape(lc.ctx), this.currentShape])
      case 'editing':
        return lc.setShapesInProgress([this._getSelectionShape(lc.ctx, '#fff')])
      default:
        return lc.setShapesInProgress([this.currentShape])
    }
  }

  Text.prototype.begin = function (x, y, lc) {
    var br, point, selectionBox, selectionShape
    this.dragAction = 'none'
    this.didDrag = false
    if (this.currentShapeState === 'selected' || this.currentShapeState === 'editing') {
      br = this.currentShape.getBoundingRect(lc.ctx)
      selectionShape = this._getSelectionShape(lc.ctx)
      selectionBox = selectionShape.getBoundingRect()
      point = {
        x: x,
        y: y
      }
      if (getIsPointInBox(point, br)) {
        this.dragAction = 'move'
      }
      if (getIsPointInBox(point, selectionShape.getBottomRightHandleRect())) {
        this.dragAction = 'resizeBottomRight'
      }
      if (getIsPointInBox(point, selectionShape.getTopLeftHandleRect())) {
        this.dragAction = 'resizeTopLeft'
      }
      if (getIsPointInBox(point, selectionShape.getBottomLeftHandleRect())) {
        this.dragAction = 'resizeBottomLeft'
      }
      if (getIsPointInBox(point, selectionShape.getTopRightHandleRect())) {
        this.dragAction = 'resizeTopRight'
      }
      if (this.dragAction === 'none' && this.currentShapeState === 'editing') {
        this.dragAction = 'stop-editing'
        this._exitEditingState(lc)
      }
    } else {
      this.color = lc.getColor('primary')
      this.currentShape = createShape('Text', {
        x: x,
        y: y,
        text: this.text,
        color: this.color,
        font: this.font,
        v: 1
      })
      this.dragAction = 'place'
      this.currentShapeState = 'selected'
    }
    if (this.dragAction === 'none') {
      this.commit(lc)
      return
    }
    this.initialShapeBoundingRect = this.currentShape.getBoundingRect(lc.ctx)
    this.dragOffset = {
      x: x - this.initialShapeBoundingRect.x,
      y: y - this.initialShapeBoundingRect.y
    }
    this._setShapesInProgress(lc)
    return lc.repaintLayer('main')
  }

  Text.prototype['continue'] = function (x, y, lc) {
    var br, brBottom, brRight
    if (this.dragAction === 'none') {
      return
    }
    br = this.initialShapeBoundingRect
    brRight = br.x + br.width
    brBottom = br.y + br.height
    switch (this.dragAction) {
      case 'place':
        this.currentShape.x = x
        this.currentShape.y = y
        this.didDrag = true
        break
      case 'move':
        this.currentShape.x = x - this.dragOffset.x
        this.currentShape.y = y - this.dragOffset.y
        this.didDrag = true
        break
      case 'resizeBottomRight':
        this.currentShape.setSize(
          x - (this.dragOffset.x - this.initialShapeBoundingRect.width) - br.x,
          y - (this.dragOffset.y - this.initialShapeBoundingRect.height) - br.y
        )
        break
      case 'resizeTopLeft':
        this.currentShape.setSize(brRight - x + this.dragOffset.x, brBottom - y + this.dragOffset.y)
        this.currentShape.setPosition(x - this.dragOffset.x, y - this.dragOffset.y)
        break
      case 'resizeBottomLeft':
        this.currentShape.setSize(
          brRight - x + this.dragOffset.x,
          y - (this.dragOffset.y - this.initialShapeBoundingRect.height) - br.y
        )
        this.currentShape.setPosition(x - this.dragOffset.x, this.currentShape.y)
        break
      case 'resizeTopRight':
        this.currentShape.setSize(
          x - (this.dragOffset.x - this.initialShapeBoundingRect.width) - br.x,
          brBottom - y + this.dragOffset.y
        )
        this.currentShape.setPosition(this.currentShape.x, y - this.dragOffset.y)
    }
    this._setShapesInProgress(lc)
    lc.repaintLayer('main')
    return this._updateInputEl(lc)
  }

  Text.prototype.end = function (x, y, lc) {
    if (!this.currentShape) {
      return
    }
    this.currentShape.setSize(this.currentShape.forcedWidth, 0)
    if (this.currentShapeState === 'selected') {
      if (this.dragAction === 'place' || (this.dragAction === 'move' && !this.didDrag)) {
        this._enterEditingState(lc)
      }
    }
    this._setShapesInProgress(lc)
    lc.repaintLayer('main')
    return this._updateInputEl(lc)
  }

  Text.prototype._enterEditingState = function (lc) {
    var onChange
    this.currentShapeState = 'editing'
    if (this.inputEl) {
      throw 'State error'
    }
    this.inputEl = document.createElement('textarea')
    this.inputEl.className = 'text-tool-input'
    this.inputEl.style.position = 'absolute'
    this.inputEl.style.transformOrigin = '0px 0px'
    this.inputEl.style.backgroundColor = 'transparent'
    this.inputEl.style.border = 'none'
    this.inputEl.style.outline = 'none'
    this.inputEl.style.margin = '0'
    this.inputEl.style.padding = '4px'
    this.inputEl.style.zIndex = '1000'
    this.inputEl.style.overflow = 'hidden'
    this.inputEl.style.resize = 'none'
    this.inputEl.value = this.currentShape.text
    this.inputEl.addEventListener('mousedown', function (e) {
      return e.stopPropagation()
    })
    this.inputEl.addEventListener('touchstart', function (e) {
      return e.stopPropagation()
    })
    onChange = (function (_this) {
      return function (e) {
        const val = e.target.value.replace(/[\u00A0-\u9999<>\&]/g, (i) => (
          '&#'+i.charCodeAt(0)+';'
        ))
        _this.currentShape.setText(val)
        _this.currentShape.enforceMaxBoundingRect(lc)
        _this._setShapesInProgress(lc)
        lc.repaintLayer('main')
        _this._updateInputEl(lc)
        return e.stopPropagation()
      }
    })(this)
    this.inputEl.addEventListener(
      'keydown',
      (function (_this) {
        return function () {
          return _this._updateInputEl(lc, true)
        }
      })(this)
    )
    this.inputEl.addEventListener('keyup', onChange)
    this.inputEl.addEventListener('change', onChange)
    this._updateInputEl(lc)
    lc.containerEl.appendChild(this.inputEl)
    this.inputEl.focus()
    return this._setShapesInProgress(lc)
  }

  Text.prototype._exitEditingState = function (lc) {
    this.currentShapeState = 'selected'
    lc.containerEl.removeChild(this.inputEl)
    this.inputEl = null
    this._setShapesInProgress(lc)
    return lc.repaintLayer('main')
  }

  Text.prototype._updateInputEl = function (lc, withMargin) {
    var br, transformString
    if (withMargin == null) {
      withMargin = false
    }
    if (!this.inputEl) {
      return
    }
    br = this.currentShape.getBoundingRect(lc.ctx, true)
    const fontSizeMatchRegExp = /(\d+)px/
    const fontSize = parseInt(this.currentShape.font.match(fontSizeMatchRegExp)[1])
    const canvas = lc.containerEl.childNodes[1]
    const elementProps = canvas.getBoundingClientRect()
    const { scale, leftOffset, topOffset } = objectFitContain({
      objectWidth: canvas.width,
      objectHeight: canvas.height,
      containerWidth: elementProps.width,
      containerHeight: elementProps.height
    })
    this.inputEl.style.font = this.currentShape.font.replace(
      fontSizeMatchRegExp,
      fontSize / scale + 'px'
    )
    this.inputEl.style.color = this.currentShape.color
    this.inputEl.style.left =
      lc.position.x + leftOffset / lc.backingScale + (br.x / scale) * lc.scale - 4 / scale + 'px'
    this.inputEl.style.top =
      lc.position.y + topOffset / lc.backingScale + (br.y / scale) * lc.scale - 4 / scale + 'px'
    const inputWidth =
      withMargin && !this.currentShape.forcedWidth
        ? br.width + 10 + this.currentShape.renderer.emDashWidth
        : br.width + 1
    this.inputEl.style.width = inputWidth / scale + 'px'
    const inputHeight = withMargin
      ? br.height + 10 + this.currentShape.renderer.metrics.leading
      : br.height + 10
    this.inputEl.style.height = inputHeight / scale + 'px'
    transformString = 'scale(' + lc.scale + ')'
    this.inputEl.style.transform = transformString
    this.inputEl.style.webkitTransform = transformString
    this.inputEl.style.MozTransform = transformString
    this.inputEl.style.msTransform = transformString
    return (this.inputEl.style.OTransform = transformString)
  }

  Text.prototype.optionsStyle = 'font'

  return Text
})(Tool)
