import React from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'

const ClientsideDiagnosticsDialog = ({ csdResults, openDialog, closeDialog }) => {

    return (
        <Dialog
            open={openDialog}
            onClose={closeDialog}
        >
            <DialogTitle>Diagnostics Results</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} alignItems="flex-start" justify="flex-start">
                    {csdResults && (
                        <>
                            <Grid item sm={6}>Bytes Received: {csdResults.bytesReceived}</Grid>
                            <Grid item sm={6}>Bytes Sent: {csdResults.bytesSent}</Grid>
                            <Grid item sm={6}>Frames Decoded: {csdResults.framesDecoded}</Grid>
                            <Grid item sm={6}>Frames Encoded: {csdResults.framesEncoded}</Grid>
                            <Grid item sm={6}>Frames Received: {csdResults.framesReceived}</Grid>
                            <Grid item sm={6}>Frames Sent: {csdResults.framesSent}</Grid>
                            {/* <Grid item sm={6}>In-Bound FPS: {csdResults.inboundFps}</Grid> */}
                            <Grid item sm={6}>In-Bound FPS Average: {csdResults.inboundFpsAverage.toFixed(2)}</Grid>
                            {/* <Grid item sm={6}>Out-Bound FPS: {csdResults.outboundFps}</Grid> */}
                            <Grid item sm={6}>Out-Bound FPS Average: {csdResults.outboundFpsAverage.toFixed(2)}</Grid>
                            <Grid item sm={6}>Packets Lost: {csdResults.packetsLost}</Grid>
                            <Grid item sm={6}>Packets Received: {csdResults.packetsReceived}</Grid>
                            <Grid item sm={6}>Packets Sent: {csdResults.packetsSent}</Grid>
                            <Grid item sm={6}>Total Decode Time: {csdResults.totalDecodeTime.toFixed(2)}</Grid>
                            <Grid item sm={6}>Total Encode Time: {csdResults.totalEncodeTime.toFixed(2)}</Grid>
                            <Grid item sm={6}>Total Interframe Delay: {csdResults.totalInterFrameDelay.toFixed(2)}</Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} autoFocus>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ClientsideDiagnosticsDialog

