import React from 'react'
import { Box, Grid, TextField } from '@material-ui/core'
import AdaptiveTitle from '../../../../../Components/AdaptiveTitle/AdaptiveTitle'
import LandingView from '../../../LandingView/LandingView'
import UnauthenticatedHeader from '../../../UnauthenticatedView/Header/UnauthenticatedHeader'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { usePrivateRoomMFA } from './hooks/usePrivateRoomMFA'
import { VerifyMFAFunction } from '../Room/hooks/useRoomLevelMFA'
import { useNavigation } from '../../../../../Common/hooks/useNavigation'

interface PrivateRoomMFAProps {
  verifyMFA: VerifyMFAFunction
}

const PrivateMFARoom = (props: PrivateRoomMFAProps): JSX.Element => {
  const { verifyMFA } = props
  const { isLoading, submit } = usePrivateRoomMFA({ verifyMFA })
  const { goToDashboard } = useNavigation()

  return (
    <LandingView>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <UnauthenticatedHeader />
        </Grid>
        <Grid item xs={12}>
          <AdaptiveTitle>Private multi-factor authentication room</AdaptiveTitle>
        </Grid>
        <Grid item xs={12}>
          This room requires multi-factor authentication (MFA). Please enter your code from your
          authentication app.
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={submit}>
            <TextField
              variant="filled"
              type="number"
              name="mfa-code"
              inputMode="numeric"
              label="MFA Code"
              autoComplete="off"
              fullWidth
            />
            <Grid item xs={12}>
              <Box mt={3} display="flex" alignItems="flex-start">
                <Box mr={3}>
                  <ProgressButton
                    variant="outlined"
                    isLoading={isLoading}
                    color="primary"
                    size="medium"
                    type="button"
                    onClick={goToDashboard}
                    fullWidth
                  >
                    Cancel
                  </ProgressButton>
                </Box>
                <Box>
                  <ProgressButton
                    size="medium"
                    isLoading={isLoading}
                    fullWidth
                    className="continue"
                    type="submit"
                  >
                    Verify
                  </ProgressButton>
                </Box>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </LandingView>
  )
}

export default React.memo(PrivateMFARoom)
