/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NoteItem_note = {
    readonly id: unknown;
    readonly sessionId: unknown | null;
    readonly sessionTime: number | null;
    readonly content: string;
    readonly createdAt: unknown;
    readonly updatedAt: unknown;
    readonly deletedAt: unknown | null;
    readonly profileId: unknown;
    readonly profileDisplayName: string | null;
    readonly " $refType": "NoteItem_note";
};
export type NoteItem_note$data = NoteItem_note;
export type NoteItem_note$key = {
    readonly " $data"?: NoteItem_note$data;
    readonly " $fragmentRefs": FragmentRefs<"NoteItem_note">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteItem_note",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileDisplayName",
      "storageKey": null
    }
  ],
  "type": "Note",
  "abstractKey": null
};
(node as any).hash = 'dd00e1b3213f6bcced629307ee74f656';
export default node;
