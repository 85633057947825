import { Box } from '@material-ui/core'
import React, { useLayoutEffect, useRef } from 'react'
import ParticipantThumbnail from '../ParticipantThumbnail/ParticipantThumbnail'
import { LiveroomPublisher } from '../hooks/useLiveroomPublisher'
import { LiveroomParticipant } from '../hooks/useLiveroomParticipants'
import { StreamSelectionControl } from '../hooks/useLiveroom'
import PublisherThumbnail from '../PublisherThumbnail/PublisherThumbnail'
import { ebsIdPrefix } from '../../../../../../../Common/janus/clients/sharing/ebs'
import {
  browserStreamIdPrefix,
} from '../../../../../../../Common/janus/clients/sharing/BrowserStreamClient'
import {
  screenShareIdPrefix,
} from '../../../../../../../Common/janus/clients/sharing/ScreenshareClient'

interface Props {
  isVisible: boolean
  participants: LiveroomParticipant[]
  participantsManager
  publisher: LiveroomPublisher
  streamSelection: StreamSelectionControl
  liveroomAudioEnabled: boolean
  isDrawerOpen: boolean
  drawerWidth: number
  setIsThumbnailsScrollbarVisible: (value: boolean) => void
  isPublisherMuted: boolean
  kickPermissionInfo
  mutePermissionInfo
  canPublishInfo
}

const isPublishersOwnExternalStream = (
  publisher: LiveroomPublisher,
  participant: LiveroomParticipant
) => {
  const publisherId = publisher.id?.slice(0, 36)
  if (!publisherId) return false
  const hasPrefix =
    participant.id.startsWith(ebsIdPrefix) ||
    participant.id.startsWith(screenShareIdPrefix) ||
    participant.id.startsWith(browserStreamIdPrefix)
  const hasPublisherId = participant.id.includes(publisherId)
  return hasPrefix && hasPublisherId
}

const shouldInitiallyMuteAudio = (
  publisher: LiveroomPublisher,
  participant: LiveroomParticipant
) => {
  return isPublishersOwnExternalStream(publisher, participant)
}

const ParticipantsList: React.FC<Props> = ({
  isVisible,
  publisher,
  participants,
  participantsManager,
  streamSelection,
  liveroomAudioEnabled,
  isDrawerOpen,
  drawerWidth,
  setIsThumbnailsScrollbarVisible,
  isPublisherMuted,
  kickPermissionInfo,
  mutePermissionInfo,
  canPublishInfo,
}) => {
  const myRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const listElement = myRef.current
    if (listElement) {
      setIsThumbnailsScrollbarVisible(
        listElement.offsetWidth > window.innerWidth - (isDrawerOpen ? drawerWidth : 0)
      )
    } else {
      setIsThumbnailsScrollbarVisible(false)
    }
  }, [participants, setIsThumbnailsScrollbarVisible, myRef, isDrawerOpen, drawerWidth])

  return (
    <div ref={myRef} style={{ justifyContent: 'center', display: 'flex' }}>
      <Box display="flex" overflow={isVisible ? 'auto' : 'hidden'}>
        {canPublishInfo.isAllowed ? <Box>
          <PublisherThumbnail
            publisher={publisher}
            onClick={streamSelection.selectPublisher}
            shouldShowMuteIndicator={isPublisherMuted}
          />
        </Box>: <></>
        }
        {participants.filter((participant) => participant.publishing).map((participant) => (
          <Box key={participant.id}>
            <ParticipantThumbnail
              participantsManager={participantsManager}
              isParticipantSpeaking={streamSelection.selectedId === participant.id}
              participant={participant}
              isAudioInitiallyMuted={shouldInitiallyMuteAudio(publisher, participant)}
              liveroomAudioEnabled={liveroomAudioEnabled}
              onClick={() => {
                if (streamSelection.selectedId === participant.id) {
                  streamSelection.unselectAndUnlockParticipant()
                } else {
                  streamSelection.selectAndLockParticipant(participant)
                }
              }}
              kickPermissionInfo={kickPermissionInfo}
              mutePermissionInfo={mutePermissionInfo}
            />
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default React.memo(ParticipantsList)
