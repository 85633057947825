import React, { useCallback, useState } from 'react'
import DefaultDialog from '../../Dialogs/DefaultDialog/DefaultDialog'
import ClearChatWarning from './ClearChatWarning/ClearChatWarning'
import ClearChatConfirm from './ClearChatConfirm/ClearChatConfirm'
import ClearChatSuccess from './ClearChatSuccess/ClearChatSuccess'

interface ClearChatDialogProps {
  onClose: () => void
  isInProgress: boolean
  performClearAll: () => Promise<any>
}

enum ClearChatSteps {
  warning,
  confirm,
  success
}

const ClearChatDialog: React.FC<ClearChatDialogProps> = ({
  onClose,
  performClearAll,
  isInProgress
}) => {
  const [state, setState] = useState(ClearChatSteps.warning)

  const clearAll = useCallback(async () => {
    const response = await performClearAll()
    if (response.clearMessagesForRoom) {
      setState(ClearChatSteps.success)
    }
  }, [performClearAll])

  const renderContent = () => {
    if (state === ClearChatSteps.warning) {
      return <ClearChatWarning onContinue={() => setState(ClearChatSteps.confirm)} />
    }
    if (state === ClearChatSteps.confirm) {
      return <ClearChatConfirm onConfirm={clearAll} isLoading={isInProgress} />
    }
    return <ClearChatSuccess onClose={onClose} />
  }

  return (
    <DefaultDialog title="Clear chats" isOpen onClose={onClose} maxWidth="sm" fullWidth>
      {renderContent()}
    </DefaultDialog>
  )
}

export default React.memo(ClearChatDialog)
