import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { routes } from '../../Views/MainView/UnauthenticatedView/routes'
import AdaptiveTitle from '../AdaptiveTitle/AdaptiveTitle'
import ProgressButton from '../ProgressButton/ProgressButton'

interface SuccessNotificationProps {
  children: React.ReactNode
  title?: string | null
  button?: string | null
}

/**
 * Component for building common success notification for landing view
 */
const SuccessNotification: React.FC<SuccessNotificationProps> = ({ children, title, button }) => {
  if (!title) {
    title = 'Success'
  }
  if (!button) {
    button = 'Back to sign in'
  }

  return (
    <Grid container spacing={4}>
      <Grid item>
        <AdaptiveTitle>{title}</AdaptiveTitle>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <Link to={routes.login.path}>
          <ProgressButton color="primary" className="continue" size="medium" isLoading={false}>
            {button}
          </ProgressButton>
        </Link>
      </Grid>
    </Grid>
  )
}

export default React.memo(SuccessNotification)
