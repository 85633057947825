import { graphql } from 'babel-plugin-relay/macro'

const RemoveRoomFromDashboardMutation = graphql`
  mutation RemoveRoomFromDashboardMutation($input: RemoveRoomFromDashboardInput!) {
    removeRoomFromDashboard(input: $input) {
      query {
        currentProfile {
          ...HomeView_currentProfile
        }
      }
    }
  }
`

export default RemoveRoomFromDashboardMutation
