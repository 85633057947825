import graphql from 'babel-plugin-relay/macro'

const VerifyAuthDeactivationMutation = graphql`
  mutation VerifyAuthDeactivationMutation($input: VerifyAuthDeactivationInput!) {
    verifyAuthDeactivation(input: $input) {
      boolean
    }
  }
`
export default VerifyAuthDeactivationMutation
