import CreateSessionMutation from '../../../../../../../GraphQL/mutations/CreateSessionMutation'
import { useMutation } from '../../../../../../../Common/hooks/useMutation'

export const useCreateSessionMutation = ({ roomId }) => {
  return useMutation<any, any>(CreateSessionMutation, {
    updater: (store) => {
      const createSession = store.getRootField('createSession')
      const queryField = createSession.getLinkedRecord('query')
      const liveroomById = queryField.getLinkedRecord('liveroomById', { id: roomId })
      const sessionsByRoomId = liveroomById.getLinkedRecord(
        'sessionsByRoomId(orderBy:["CREATED_AT_DESC"])'
      )
      const nodes = sessionsByRoomId.getLinkedRecords('nodes')

      const oldLiveroomById = store.get(roomId)
      const oldSessionsByRoomId = oldLiveroomById?.getLinkedRecord(
        'sessionsByRoomId(orderBy:["CREATED_AT_DESC"])'
      )

      if (oldSessionsByRoomId) {
        oldSessionsByRoomId.setLinkedRecords(nodes, 'nodes')
      }
    }
  })
}
