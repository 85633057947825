import React, { useCallback, useState, memo, useEffect } from 'react'
import { Tooltip, Slider } from '@material-ui/core'
import { ValueLabelProps } from '@material-ui/core/Slider'
import { useFormatTime } from '../hooks/useFormatTime'
import './SeekBar.scss'

interface SeekBarProps {
  onSeekChange: (value) => void
  isSeeking: boolean
  seekingTarget: number
  currentTime: number
  length: number
}

const ValueLabelComponent: React.FC<ValueLabelProps> = ({ children, open, value }) => {
  const title = useFormatTime(value)
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={title}>
      {children}
    </Tooltip>
  )
}

/**
 * Component that handles the video seek bar
 * @param isSeeking
 * @param seekingTarget - current seeking target, used to synchronize dragging value to avoid jumping the seekbar handle to 0
 * @param currentTime current time
 * @param length length of the video
 * @param onSeekChange function that will be triggered slides the slider thumb
 * @constructor
 */
const SeekBar: React.FC<SeekBarProps> = ({
  isSeeking,
  seekingTarget,
  currentTime,
  length,
  onSeekChange
}) => {
  const [draggingValue, setDraggingValue] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    setDraggingValue(seekingTarget)
  }, [seekingTarget])

  const onChangeHandler = useCallback((event: any, newValue: number) => {
    const validValue = Math.floor(newValue / 10) * 10
    setDraggingValue(validValue)
    setIsDragging(true)
  }, [])

  const onChangeCommittedHandler = useCallback(
    (event: any, newValue: number) => {
      onSeekChange(newValue)
      setIsDragging(false)
    },
    [onSeekChange]
  )

  return (
    <Slider
      value={isDragging || isSeeking ? draggingValue : currentTime}
      color="secondary"
      step={10}
      marks={isDragging}
      min={0}
      max={length}
      ValueLabelComponent={ValueLabelComponent}
      onChange={onChangeHandler}
      onChangeCommitted={onChangeCommittedHandler}
      valueLabelDisplay="auto"
      aria-labelledby="video-seeker-slider"
      className="seek-bar"
      data-testid="seek-bar"
    />
  )
}

export default memo(SeekBar)
