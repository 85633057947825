import { useMemo } from 'react'
import { useSession } from './useSession'
import { useNotFound } from '../NotFoundView/useNotFound'
import { useBrowserCapabilities } from './useBrowserCapabilities'
import { useDesktopAppLaunchView } from '../DesktopAppLaunchView/useDesktopAppLaunchView'

export enum MainViewState {
  notFound,
  authenticating,
  unauthenticated,
  authenticated,
  unsupportedBrowser,
  error,
  waitingForNativeApp,
  nativeAppDetected
}

export const useMainView = () => {
  const { isLoading, userProfile, appFeatureFlags, ssoDetails, login, logout, error } = useSession()
  const notFound = useNotFound()
  const { browserCapabilities } = useBrowserCapabilities()
  const {
    showLaunchView,
    waitingForProtocol,
    dismissNativeAppLaunch,
    retryNativeAppLaunch
  } = useDesktopAppLaunchView({ authenticated: !!userProfile })

  const state = useMemo(() => {
    if (showLaunchView) {
      return MainViewState.nativeAppDetected
    }
    if (waitingForProtocol) {
      return MainViewState.waitingForNativeApp
    }
    if (!browserCapabilities.isSupported) {
      return MainViewState.unsupportedBrowser
    }
    if (notFound) {
      return MainViewState.notFound
    }
    if (isLoading) {
      return MainViewState.authenticating
    }
    if (error) {
      return MainViewState.error
    }
    if (userProfile) {
      return MainViewState.authenticated
    } else {
      return MainViewState.unauthenticated
    }
  }, [
    browserCapabilities,
    notFound,
    isLoading,
    error,
    userProfile,
    waitingForProtocol,
    showLaunchView
  ])

  return {
    state,
    userProfile,
    appFeatureFlags,
    ssoDetails,
    browserCapabilities,
    login,
    logout,
    dismissNativeAppLaunch,
    retryNativeAppLaunch
  }
}
