import { useMemo } from 'react'
import { AudioConstraintsParams, useAudioConstraints } from './useAudioConstraints'
import { useVideoConstraints, VideoConstraintsParams } from './useVideoConstraints'

export type AudioAndVideoConstraintsParams = AudioConstraintsParams & VideoConstraintsParams

export const useAudioAndVideoConstraints = (constraintsParams: AudioAndVideoConstraintsParams) => {
  const audioConstraints = useAudioConstraints(constraintsParams)
  const videoConstraints = useVideoConstraints(constraintsParams)
  return useMemo(() => {
    const audio = audioConstraints ? audioConstraints.audio : false
    const video = videoConstraints ? videoConstraints.video : false
    if (!audio && !video) {
      return null
    }
    return {
      audio,
      video
    }
  }, [audioConstraints, videoConstraints])
}
