import React from 'react'
import SuccessNotification from '../../../../../Components/SuccessNotification/SuccessNotification'

/**
 * Evercast forgot password success view
 * @constructor
 */
const ForgotPasswordSuccess: React.FC = () => {
  return (
    <SuccessNotification>
      Please check your email for a link to reset your password.
    </SuccessNotification>
  )
}

export default React.memo(ForgotPasswordSuccess)
