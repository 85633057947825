import { useState } from 'react'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { VerifyMFAFunction } from '../../Room/hooks/useRoomLevelMFA'

interface UsePrivateRoomMFAProps {
  verifyMFA: VerifyMFAFunction
}

export const usePrivateRoomMFA = (props: UsePrivateRoomMFAProps) => {
  const { verifyMFA } = props
  const [isLoading, setIsLoading] = useState(false)
  const showAlert = useAlerts()

  const submit = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      const form = e.target.closest('form')
      const formData = new FormData(form)
      const mfaCode = formData.get('mfa-code')
      const { errorMessage: _errorMessage } = await verifyMFA(mfaCode as string)
      setIsLoading(false)
      if (_errorMessage) showAlert(_errorMessage, AlertType.error)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    submit,
  }
}
