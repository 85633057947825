import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { useDelayedEffect } from '../Common/hooks/useDelayedEffect'

export interface ProgressFunctions {
  addProgress: () => void
  removeProgress: () => void
  showFiller: () => void
}

const progressContext = createContext<ProgressFunctions>(null!)

interface ProgressProviderProps {
  renderProgress: () => React.ReactNode
  renderContent: (displaying: boolean) => React.ReactNode
  renderFiller?: () => React.ReactNode
  progressTimeout: number
  onProgressChange?: (isProgress: boolean) => void
}

enum ProgressState {
  showingProgress,
  showingProgressCloseable,
  showingContent
}

/**
 * Root provider for displaying/hidding progress animation, to be used with <ShowProgress> and useProgress
 *
 * @param renderProgress - Function to be called for rendering progress animation
 * @param renderContent - Function to be called for rendering content
 * @param renderFiller - Function to be called for rendering filler, this will get renderer a frame before loading animation accours
 * @param progressTimeout - min time for displaying progress
 * @param onProgressChange -  Notifies on internal progress displaying/hiding
 */
export const ProgressProvider: React.FC<ProgressProviderProps> = ({
  renderFiller = () => null,
  renderProgress,
  renderContent,
  progressTimeout,
  onProgressChange = () => {}
}) => {
  const [showProgressCount, setShowProgressCount] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const fillerRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState(ProgressState.showingContent)
  const displayProgress = showProgressCount > 0

  const showFiller = useCallback(() => {
    if (contentRef.current && fillerRef.current) {
      contentRef.current.hidden = true
      fillerRef.current.hidden = false
    }
  }, [contentRef, fillerRef])

  const hideFiller = useCallback(() => {
    if (contentRef.current && fillerRef.current) {
      contentRef.current.hidden = false
      fillerRef.current.hidden = true
    }
  }, [contentRef, fillerRef])

  const progressFunctions = useMemo(
    () => ({
      addProgress: () => setShowProgressCount((count) => count + 1),
      removeProgress: () => setShowProgressCount((count) => count - 1),
      showFiller
    }),
    [setShowProgressCount, showFiller]
  )

  // animation needs to show for at least given timeout
  useDelayedEffect(
    {
      delay: progressTimeout,
      predicate: () => state === ProgressState.showingProgress,
      effect: () => setState(ProgressState.showingProgressCloseable)
    },
    [state, setState]
  )

  useEffect(() => {
    if (!displayProgress && state === ProgressState.showingProgressCloseable) {
      setState(ProgressState.showingContent)
      onProgressChange(false)
      hideFiller()
    } else if (displayProgress && state === ProgressState.showingContent) {
      setState(ProgressState.showingProgress)
      onProgressChange(true)
    }
  }, [displayProgress, state, hideFiller, onProgressChange])

  const showProgress = state !== ProgressState.showingContent

  return (
    <progressContext.Provider value={progressFunctions}>
      <div style={{ height: '100%' }} hidden={showProgress}>
        <div style={{ height: '100%' }} ref={contentRef}>
          {renderContent(!showProgress)}
        </div>
        <div ref={fillerRef} hidden>
          {renderFiller()}
        </div>
      </div>
      {showProgress && renderProgress()}
    </progressContext.Provider>
  )
}

/**
 * Hook for enabling/disabling progress inside a ProgressProvider's children
 *
 * @param isProgressDefault - if should start at progress state when mounting, false by default
 */
export const useProgress = (isProgressDefault = false) => {
  const { addProgress, removeProgress, showFiller } = useContext(progressContext)
  const isActive = useRef(false)
  const isFirstRender = useRef(true)

  useEffect(() => {
    return () => {
      if (isActive.current) {
        removeProgress()
      }
    }
  }, [isActive, removeProgress])

  const showProgress = useCallback(() => {
    if (!isActive.current) {
      addProgress()
      isActive.current = true
    }
  }, [isActive, addProgress])

  const hideProgress = useCallback(() => {
    if (isActive.current) {
      removeProgress()
      isActive.current = false
    }
  }, [isActive, removeProgress])

  useEffect(() => {
    isFirstRender.current = false
    if (isProgressDefault) {
      showProgress()
    }
  }, [isFirstRender, isProgressDefault, showProgress])

  // need to programatically show filler/hide view before first render or will get blinking otherwise
  if (isProgressDefault && isFirstRender.current) {
    showFiller!()
  }

  return useMemo(
    () => ({
      showProgress,
      hideProgress
    }),
    [showProgress, hideProgress]
  )
}

/**
 * Util component for interacting with ProgressProvider, will show progress when rendered
 */
export const ShowProgress: React.FC = ({ children }) => {
  try {
    useProgress(true)
  } catch {
    // ignore, sometimes the context is null, which breaks everything
  }
  return <>{children}</>
}
