/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileType = "BASIC" | "ENTERPRISE" | "%future added value";
export type SessionQueryVariables = {};
export type SessionQueryResponse = {
    readonly getCurrentFeatureFlags: {
        readonly nodes: ReadonlyArray<{
            readonly key: string | null;
            readonly value: string | null;
        } | null>;
    };
    readonly currentProfile: {
        readonly type: ProfileType;
        readonly nodeId: string;
        readonly id: unknown;
        readonly displayName: string;
        readonly email: string | null;
        readonly organizationByOrganizationId: {
            readonly id: unknown;
            readonly displayName: string | null;
        } | null;
        readonly profilePreferences: {
            readonly nodes: ReadonlyArray<{
                readonly preferenceTypeName: string | null;
                readonly preferenceTypeId: unknown | null;
                readonly preferenceTypeDefault: string | null;
                readonly preferenceValue: string | null;
            } | null>;
        };
        readonly featureFlags: {
            readonly nodes: ReadonlyArray<{
                readonly key: string | null;
                readonly value: string | null;
            } | null>;
        };
    } | null;
    readonly getSSODetails: {
        readonly userPoolId: string | null;
        readonly userPoolClientId: string | null;
        readonly userPoolDomain: string | null;
        readonly userPoolRegion: string | null;
    } | null;
};
export type SessionQuery = {
    readonly response: SessionQueryResponse;
    readonly variables: SessionQueryVariables;
};



/*
query SessionQuery {
  getCurrentFeatureFlags {
    nodes {
      key
      value
    }
  }
  currentProfile {
    type
    nodeId
    id
    displayName
    email
    organizationByOrganizationId {
      id
      displayName
    }
    profilePreferences {
      nodes {
        preferenceTypeName
        preferenceTypeId
        preferenceTypeDefault
        preferenceValue
      }
    }
    featureFlags {
      nodes {
        key
        value
      }
    }
  }
  getSSODetails {
    userPoolId
    userPoolClientId
    userPoolDomain
    userPoolRegion
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GetCurrentFeatureFlagsConnection",
    "kind": "LinkedField",
    "name": "getCurrentFeatureFlags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GetCurrentFeatureFlagsRecord",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Profile",
    "kind": "LinkedField",
    "name": "currentProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nodeId",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByOrganizationId",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PreferencesConnection",
        "kind": "LinkedField",
        "name": "profilePreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Preference",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceTypeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceTypeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceTypeDefault",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "preferenceValue",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ColumnProfileFeatureFlagsConnection",
        "kind": "LinkedField",
        "name": "featureFlags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ColumnProfileFeatureFlagsRecord",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v0/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SSODetailsType",
    "kind": "LinkedField",
    "name": "getSSODetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userPoolId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userPoolClientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userPoolDomain",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userPoolRegion",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b5e556ed98bfe8efb73a7c6edee63175",
    "id": null,
    "metadata": {},
    "name": "SessionQuery",
    "operationKind": "query",
    "text": "query SessionQuery {\n  getCurrentFeatureFlags {\n    nodes {\n      key\n      value\n    }\n  }\n  currentProfile {\n    type\n    nodeId\n    id\n    displayName\n    email\n    organizationByOrganizationId {\n      id\n      displayName\n    }\n    profilePreferences {\n      nodes {\n        preferenceTypeName\n        preferenceTypeId\n        preferenceTypeDefault\n        preferenceValue\n      }\n    }\n    featureFlags {\n      nodes {\n        key\n        value\n      }\n    }\n  }\n  getSSODetails {\n    userPoolId\n    userPoolClientId\n    userPoolDomain\n    userPoolRegion\n  }\n}\n"
  }
};
})();
(node as any).hash = '109cb44a5687fd2262de035554605bab';
export default node;
