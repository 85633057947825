import { Subject } from 'rxjs'
import { startsWith } from 'ramda'
import { JanusSubscriber } from './JanusSubscriber'
import { Participant, ParticipantType } from './LiveroomClient'
import { appleTVIdPrefix } from '../sharing/appleTV'
import { ebsIdPrefix } from '../sharing/ebs'
import { remoteAppIdPrefix, streamKeyDummyIdPrefix } from '../sharing/streamKey'
import { browserStreamIdPrefix } from '../sharing/BrowserStreamClient'
import { screenShareIdPrefix } from '../sharing/ScreenshareClient'
import { WebRTCStatsParser } from '../../stats/WebRTCStatsParser'
import { formatStats } from '../../../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useClientsideDiagnostics'

export class JanusParticipant implements Participant {
  public readonly id: string
  public readonly displayName: string
  public readonly publisherId: string
  public publishing: boolean

  private unpublishingSubject = new Subject<void>()
  private onStreamSubject = new Subject<MediaStream>()
  private onDataChannelOpenedSubject = new Subject<string>()
  private onDataChannelMessageSubject = new Subject<any>()
  private onIceStateSubject = new Subject<RTCIceTransportState>()
  private subscriber?: JanusSubscriber

  constructor({
    id,
    displayName,
    publisherId,
    publishing
  }: {
    id: string
    displayName: string
    publisherId: string
    publishing: boolean
  }) {
    this.id = id
    this.displayName = displayName
    this.publisherId = publisherId
    this.publishing = publishing
  }

  onUnpublishing = () => this.unpublishingSubject.asObservable()

  onPublishing = () => this.onStreamSubject.asObservable()

  onIceState = () => this.onIceStateSubject.asObservable()

  handlePublishing = (subscriber: JanusSubscriber) => {
    this.subscriber = subscriber
    this.subscriber.onDataChannelMessage().subscribe(this.onDataChannelMessageSubject)
    this.subscriber.onDataChannelOpened().subscribe(this.onDataChannelOpenedSubject)
    this.subscriber.onIceState().subscribe(this.onIceStateSubject)
    this.subscriber.onStream().subscribe((mediaStream) => this.onStreamSubject.next(mediaStream))
  }

  handleUnpublishing = () => {
    if (this.subscriber) {
      this.subscriber.cleanup()
      delete this.subscriber
      this.unpublishingSubject.next()
    }
  }

  setVideoState = (isVideoEnabled: boolean) => {
    if (this.subscriber) {
      this.subscriber.setVideoEnabled(isVideoEnabled)
    }
  }

  async configureParticipant(options: { receiveAudio?: boolean; receiveVideo?: boolean }) {
    if (this.subscriber) {
      return this.subscriber.configureSubscriber(options)
    }
  }

  async startReceivingVideo() {
    if (this.subscriber) {
      return this.subscriber.configureSubscriber({ receiveVideo: true })
    }
  }

  async stopReceivingVideo() {
    if (this.subscriber) {
      return this.subscriber.configureSubscriber({ receiveVideo: false })
    }
  }

  getVolume = async () => {
    if (!this.subscriber) {
      return 0
    }

    const webrtcStats = await this.subscriber.getWebRTCStats()
    if (!webrtcStats) {
      return 0
    }

    return WebRTCStatsParser.getRemoteVolume(webrtcStats)
  }

  getInboundStats = async () => {
    if (!this.subscriber) {
      return {}
    }
    const webrtcStats = await this.subscriber.getMediaStreamStats()
    return webrtcStats ? formatStats(webrtcStats) as RTCStatsReport : {}
  }

  getWebRTCStats = async (): Promise<{ [key: string]: any }> => {
    if (!this.subscriber) return {}

    const stats = (await this.subscriber.getWebRTCStats()) as RTCStatsReport
    const formattedStats = {}
    stats.forEach((report) => {
      const entries = Object.entries<any>(report)

      entries.reduce((prev, [key, value]) => {
        prev[key] = value
        return prev
      }, formattedStats)
    })
    
    return formattedStats
  }

  cleanup = () => {
    this.onDataChannelOpenedSubject.complete()
    this.onDataChannelMessageSubject.complete()
    this.unpublishingSubject.complete()
    this.onStreamSubject.complete()
    this.onIceStateSubject.complete()
    if (this.subscriber) {
      this.subscriber.cleanup()
    }
  }

  onDataChannelMessage = () => this.onDataChannelMessageSubject.asObservable()

  onDataChannelOpened = () => this.onDataChannelOpenedSubject.asObservable()

  get type(): ParticipantType {
    const isScreenShare = startsWith(`${screenShareIdPrefix}`, this.id)
    const isBrowserStream = startsWith(`${browserStreamIdPrefix}`, this.id)
    const isEBS = startsWith(`${ebsIdPrefix}`, this.id)
    const isAppleTV = startsWith(`${appleTVIdPrefix}`, this.id)
    const isRemoteApp = startsWith(`${remoteAppIdPrefix}`, this.id)
    const isDummy = startsWith(`${streamKeyDummyIdPrefix}`, this.id)

    if (isScreenShare) return ParticipantType.ScreenShare
    else if (isBrowserStream) return ParticipantType.BrowserStream
    else if (isEBS) return ParticipantType.Ebs
    else if (isAppleTV) return ParticipantType.AppleTV
    else if (isRemoteApp) return ParticipantType.RemoteApp
    else if (isDummy) return ParticipantType.Dummy
    else return ParticipantType.Remote
  }
}
