import React, { useState, useEffect, useCallback } from 'react'
import { useRoomNavigation } from '../../Views/MainView/AuthenticatedView/DashboardView/HomeView/RoomCard/hooks/useRoomNavigation'
import { logger } from '../../Common/log/Log'
import ProgressBar from '../ProgressBar/ProgressBar'

interface AcceptInviteProps {
  invite: {
    roomHash: string
    token: string
  }
  errorComponent: (error: string) => React.ReactNode
}

// There were more states in v2, but none of them were used, so we removed these states from v3 to keep it simple.
const ACCEPT_INVITE_STATE = {
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  NEW_USER: 'NEW_USER',
  ACCEPTED: 'ACCEPTED'
}

/**
 * AcceptInvite component copy the behavior of the v2. It basically checks if the invite has any error, if not redirect to the room got in the token
 */
const AcceptInvite: React.FC<AcceptInviteProps> = ({ invite, errorComponent }) => {
  const [acceptInviteState, setAcceptInviteState] = useState(ACCEPT_INVITE_STATE.IN_PROGRESS)
  const [error, setError] = useState('')
  const { goToRoom } = useRoomNavigation()

  console.log('accept invite')

  useEffect(() => {
    console.log('** AcceptInvite useEffect invite -> ', invite)
    if (!invite) {
      return changeState(ACCEPT_INVITE_STATE.ERROR, 'Invite token is invalid.')
    }

    try {
      goToRoom({ hash: invite.roomHash })
    } catch (error) {
      changeState(ACCEPT_INVITE_STATE.ERROR, error)
    }
    // TODO: Inherited from cleverTech, haven't investigated yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite, setAcceptInviteState, setError])

  const changeState = useCallback(
    (state, error) => {
      if (acceptInviteState === state) return

      if (error) logger.error(error)

      setAcceptInviteState(state)
      setError(error)
    },
    [acceptInviteState, setAcceptInviteState, setError]
  )

  return acceptInviteState === ACCEPT_INVITE_STATE.ERROR ? (
    <>{errorComponent(error)}</>
  ) : (
    <ProgressBar className="progress-bar-default-size" />
  )
}

export default React.memo(AcceptInvite)
