import {
  AttachParams,
  JanusSignallingConnection,
  PluginHandle,
  PluginType,
} from '../signalling/JanusSignallingConnection'
import { logger } from '../../log/Log'
import { ReplaySubject, Subject } from 'rxjs'
import { ConditionalLoggerAdapter } from '../../log/ConditionalLoggerAdapter'

const CLIENT_VERSION_FOR_WSS = "1.0"

export enum ParticipantType {
  Subscriber = 'subscriber',
  Publisher = 'publisher',
}

export enum MessageType {
  createRoom = 'create',
  queryIfRoomExists = 'exists',
  joinRoom = 'join',
  start = 'start',
  listParticipants = 'listparticipants',
  startRecording = 'recording_start',
  stopRecording = 'recording_stop',
  configure = 'configure',
  unpublish = 'unpublish',
  stopScreenshare = 'screenshare_stop',
  playbackSubscribe = 'playback_subscribe',
  playbackPrepare = 'playback_prep',
  playbackPause = 'playback_pause',
  playbackResume = 'playback_resume',
  playbackStart = 'playback_start',
  playbackSeek = 'playback_seek',
  playbackStop = 'playback_stop',
  playbackAck = 'playback_ack',
  stopBrowserStream = 'external_device_share_stop',
  kickParticipant = 'kick',
  mute = 'mute'
}

export enum JanusEvent {
  HANDLE_CREATED = 'handlecreated',
  JOINING = 'joining',
  JOINED = 'joined',
  DESTROYED = 'destroyed',
  PUBLISHERS = 'publishers',
  ATTENDEES = 'attendees',
  LEAVING = 'leaving',
  UNPUBLISHED = 'unpublished',
  ERROR = 'error',
  LOCAL_STREAM = 'localstream',
  REMOTE_STREAM = 'remotestream',
  ATTACHED = 'attached',
  DETACHED = 'detached',
  JSEP_AVAILABLE = 'jsepavailable',
  CLEANUP = 'cleanup',
  WEBRTC_STATE = 'webrtcstate',
  ICE_STATE = 'icestate',
  SLOW_LINK = 'slowlink',
  CONSENT_DIALOG = 'consentdialog',
  MEDIA_STATE = 'mediastate',
  RECORDING_STARTED = 'recording_started',
  RECORDING_STOPPED = 'recording_stopped',
  PLAYBACK_STARTED = 'playback_started',
  PLAYBACK_PAUSED = 'playback_paused',
  PLAYBACK_RESUMED = 'playback_resumed',
  PLAYBACK_PREPPING = 'playback_prepping',
  PLAYBACK_PREP_PROGRESS = 'playback_prep_progress',
  PLAYBACK_READY = 'playback_ready',
  PLAYBACK_STREAMS_READY = 'playback_streams_ready',
  PLAYBACK_ACTIVE_STREAMS = 'playback_active_streams',
  PLAYBACK_ENDED = 'playback_ended',
  PLAYBACK_SUBSCRIBING = 'playback_subscribing',
  PLAYBACK_SEEKING = 'playback_seeking',
  PLAYBACK_SEEKED = 'playback_seeked',
  DATA_CHANNEL_OPENED = 'data_channel_opened',
  DATA_CHANNEL_MESSAGE = 'data_channel_message',
  ROOM_REALLOCATED = 'room_reallocated',
  PARTICIPANT_MUTE = 'mute',
  PARTICIPANT_KICK = 'kick'
}

export class JanusClient {
  private static JANUS_NATIVE_EVENTS = new Set([JanusEvent.JOINED, JanusEvent.ATTACHED])
  private static JANUS_CUSTOM_EVENTS = new Set([
    JanusEvent.PLAYBACK_PREPPING,
    JanusEvent.PLAYBACK_PREP_PROGRESS,
    JanusEvent.PLAYBACK_READY,
    JanusEvent.PLAYBACK_ACTIVE_STREAMS,
    JanusEvent.PLAYBACK_ENDED,
    JanusEvent.RECORDING_STARTED,
    JanusEvent.RECORDING_STOPPED,
    JanusEvent.PLAYBACK_STARTED,
    JanusEvent.PLAYBACK_PAUSED,
    JanusEvent.PLAYBACK_RESUMED,
    JanusEvent.PLAYBACK_SEEKING,
    JanusEvent.PLAYBACK_SEEKED,
    JanusEvent.JOINING,
    JanusEvent.ROOM_REALLOCATED,
    JanusEvent.PARTICIPANT_MUTE,
    JanusEvent.PARTICIPANT_KICK,
  ])

  private pluginHandleSubject = new ReplaySubject<PluginHandle>()
  private errorSubject = new Subject<any>()
  private consentDialogSubject = new Subject<boolean>()
  private webrtcStateSubject = new Subject<{ webrtcState: boolean; reason: string }>()
  private iceStateSubject = new Subject<RTCIceTransportState>()
  private mediaStateSubject = new Subject<{ type: string; isOn: boolean }>()
  private slowLinkSubject = new Subject<{ uplink: boolean; nacks: number }>()
  private onLocalStreamSubject = new Subject<MediaStream>()
  private onRemoteStreamSubject = new Subject<MediaStream>()
  private onDataOpenSubject = new Subject<string>()
  private onDataSubject = new Subject<{ data: any; dataChannelLabel: string }>()
  private onCleanupSubject = new Subject<void>()
  private detachedSubject = new Subject<void>()
  private jsepSubject = new Subject<any>()
  private eventSubjects = new Map<JanusEvent, Subject<any>>(
    Object.values(JanusEvent).map((event) => [event, new Subject()])
  )

  constructor(private janusSignallingConnection: JanusSignallingConnection, private tag: string) {
    janusSignallingConnection.attach(this.createAttacher())
    this.registerLoggers()
  }

  private log = (...args) => {
    logger.log(`[${this.tag}]`, ...args)
  }

  private logError = (...args) => {
    logger.error(`[${this.tag}`, ...args)
  }

  private registerLoggers = () => {
    this.pluginHandleSubject.subscribe(async () =>
      this.log(
        'Received plugin handle: ',
        `session id: ${this.sessionId}, handle id: ${await this.getHandleId()}]`
      )
    )
    this.errorSubject.subscribe((error) => this.logError(error))
    this.consentDialogSubject.subscribe((isOpened) =>
      this.log('Received consent dialog: ', isOpened)
    )
    this.webrtcStateSubject.subscribe((webrtcState) =>
      this.log('Received webrtc state: ', webrtcState)
    )
    this.iceStateSubject.subscribe((iceState) => this.log('Received ice state: ', iceState))
    this.mediaStateSubject.subscribe((mediaState) => this.log('Received media state: ', mediaState))
    this.slowLinkSubject.subscribe((slowLink) => this.log('Received ice state: ', slowLink))
    this.onLocalStreamSubject.subscribe(() => this.log('Received local stream'))
    this.onRemoteStreamSubject.subscribe(() => this.log('Received remote stream'))
    this.onDataOpenSubject.subscribe((dataChannelLabel) =>
      this.log('Received data open: ', dataChannelLabel)
    )
    this.onDataSubject.subscribe((data) => this.log('Received data: ', data))
    this.onCleanupSubject.subscribe(() => this.log('Received cleanup'))
    this.detachedSubject.subscribe(() => this.log('Received detached'))
    this.jsepSubject.subscribe(() => this.log('Received jsep'))
    this.eventSubjects.forEach((eventSubject, eventType) =>
      eventSubject.subscribe((message) =>
        this.log(`Received message for event type [${eventType}]: `, message)
      )
    )
  }

  private createAttacher = (): AttachParams => {
    return {
      plugin: PluginType.EvercastVideoRoom,
      opaqueId: Math.random().toString(),
      success: this.handleSuccess,
      error: (error) => this.errorSubject.next(error),
      consentDialog: (isOpened) => this.consentDialogSubject.next(isOpened),
      webrtcState: (webrtcState: boolean, reason: string) =>
        this.webrtcStateSubject.next({ webrtcState, reason }),
      iceState: (iceState) => this.iceStateSubject.next(iceState),
      mediaState: (type: string, isOn: boolean) => this.mediaStateSubject.next({ type, isOn }),
      slowLink: (uplink: boolean, nacks: number) => this.slowLinkSubject.next({ uplink, nacks }),
      onmessage: this.handleMessage,
      onlocalstream: (stream) => this.onLocalStreamSubject.next(stream),
      onremotestream: (stream) => this.onRemoteStreamSubject.next(stream),
      ondataopen: (label: string) => this.onDataOpenSubject.next(label),
      ondata: (data: any, dataChannelLabel: string) =>
        this.onDataSubject.next({ data, dataChannelLabel }),
      oncleanup: () => this.onCleanupSubject.next(),
      detached: () => this.detachedSubject.next(),
    }
  }

  private handleMessage = (message: any, jsep: any) => {
    if (jsep) {
      this.jsepSubject.next(jsep)
    }
    const nativeEvent = message.videoroom
    if (!nativeEvent) {
      return
    }
    if (JanusClient.JANUS_NATIVE_EVENTS.has(nativeEvent)) {
      this.eventSubjects.get(nativeEvent)!.next(message)
      return
    }
    if (nativeEvent !== 'event') {
      return
    }
    const customEvent = message.event
    if (JanusClient.JANUS_CUSTOM_EVENTS.has(customEvent)) {
      this.eventSubjects.get(customEvent)!.next(message)
      return
    }
    if (message.publishers) {
      this.eventSubjects.get(JanusEvent.PUBLISHERS)!.next(message)
      return
    }

    if (message.leaving) {
      this.eventSubjects.get(JanusEvent.LEAVING)!.next(message)
      return
    }

    if (message.unpublished) {
      this.eventSubjects.get(JanusEvent.UNPUBLISHED)!.next(message)
      return
    }

    if (message.error) {
      this.eventSubjects.get(JanusEvent.ERROR)!.next(message)
    }
  }

  private handleSuccess = (pluginHandle: PluginHandle) => {
    this.pluginHandleSubject.next(pluginHandle)
    this.pluginHandleSubject.complete()
  }

  get sessionId() {
    return this.janusSignallingConnection.getSessionId()
  }

  get isConnected() {
    return this.janusSignallingConnection.isConnected()
  }

  onEvent = (eventType: JanusEvent) => this.eventSubjects.get(eventType)!.asObservable()

  onError = () => this.errorSubject.asObservable()

  onConsentDialog = () => this.consentDialogSubject.asObservable()

  onWebRtcStateChange = () => this.webrtcStateSubject.asObservable()

  onIceState = () => this.iceStateSubject.asObservable()

  onMediaState = () => this.mediaStateSubject.asObservable()

  onSlowLink = () => this.slowLinkSubject.asObservable()

  onLocalStream = () => this.onLocalStreamSubject.asObservable()

  onRemoteStream = () => this.onRemoteStreamSubject.asObservable()

  onDataChannelOpen = () => this.onDataOpenSubject.asObservable()

  onDataChannelMessage = () => this.onDataSubject.asObservable()

  onCleanup = () => this.onCleanupSubject.asObservable()

  onDetached = () => this.detachedSubject.asObservable()

  onJsep = () => this.jsepSubject.asObservable()

  getPluginHandle = () => this.pluginHandleSubject.toPromise()

  getHandleId = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.getId()
  }

  getBitrate = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.getBitrate()
  }

  getConnectionStats = async (mediaStream) => {
    if (!mediaStream) return null
    const connection = await this.getPeerConnection()
    const track = mediaStream.getVideoTracks().length ? mediaStream.getVideoTracks()[0] : mediaStream.getTracks()[0]
    return connection?.getStats(track)
  }

  getPeerConnection = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.webrtcStuff.pc
  }

  getMediaStream = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.webrtcStuff.myStream
  }

  handleRemoteJsep = async (jsep) => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.handleRemoteJsep({ jsep })
  }

  createOffer = (createOfferParams) =>
    new Promise<any>((resolve, reject) => {
      this.getPluginHandle().then((pluginHandle) => {
        this.log('Creating an offer', createOfferParams)
        pluginHandle.createOffer({
          ...createOfferParams,
          success: (jsep) => {
            resolve(jsep)
          },
          error: (error) => {
            reject(error)
          },
        })
      })
    })

  createAnswer = async (createAnswerParams) =>
    new Promise<any>((resolve, reject) => {
      this.getPluginHandle().then((pluginHandle) => {
        this.log('Creating an answer', createAnswerParams)
        pluginHandle.createAnswer({
          ...createAnswerParams,
          success: (jsep) => {
            resolve(jsep)
          },
          error: (error) => {
            reject(error)
          },
        })
      })
    })

  sendMessage = async ({ message, jsep }: { message: any; jsep?: any }) =>
    new Promise<any>((resolve, reject) => {
      this.getPluginHandle().then((pluginHandle) => {
        this.log('Sending message', message)
        pluginHandle.send({
          message: { ...message, client_version: CLIENT_VERSION_FOR_WSS },
          jsep,
          success: (data) => {
            resolve(data)
          },
          error: (error) => {
            reject(error)
          },
        })
      })
    })

  sendData = async (data: any) =>
    new Promise<any>((resolve, reject) => {
      this.getPluginHandle().then((pluginHandle) => {
        ConditionalLoggerAdapter.contextualizedInfo(() =>
          this.log('Sending data channel message', data)
        )

        pluginHandle.data({
          ...data,
          success: (data) => {
            resolve(data)
          },
          error: (error) => {
            reject(error)
          },
        })
      })
    })

  detach = async (options = {}) =>
    new Promise<void>((resolve, reject) => {
      this.getPluginHandle().then((pluginHandle) => {
        this.log(`Detaching plugin`)
        pluginHandle.detach({
          ...options,
          success: () => {
            resolve()
          },
          error: (message) => {
            reject(new Error(message))
          },
        })
      })
    })

  hangup = async (shouldSendRequest: boolean) => {
    const pluginHandle = await this.getPluginHandle()
    this.log(`Hangup plugin`)
    pluginHandle.hangup(shouldSendRequest)
  }

  muteAudio = async () => {
    this.log(`Muting audio`)

    const pluginHandle = await this.getPluginHandle()
    pluginHandle.muteAudio()
  }

  unmuteAudio = async () => {
    this.log(`Unmuting audio`)

    const pluginHandle = await this.getPluginHandle()
    pluginHandle.unmuteAudio()
  }

  isAudioMuted = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.isAudioMuted()
  }

  muteVideo = async () => {
    this.log(`Muting video`)

    const pluginHandle = await this.getPluginHandle()
    pluginHandle.muteVideo()
  }

  unmuteVideo = async () => {
    this.log(`Unmuting video`)

    const pluginHandle = await this.getPluginHandle()
    pluginHandle.unmuteVideo()
  }

  isVideoMuted = async () => {
    const pluginHandle = await this.getPluginHandle()
    return pluginHandle.isVideoMuted()
  }

  cleanup = () => {
    this.pluginHandleSubject.complete()
    this.errorSubject.complete()
    this.consentDialogSubject.complete()
    this.webrtcStateSubject.complete()
    this.iceStateSubject.complete()
    this.mediaStateSubject.complete()
    this.slowLinkSubject.complete()
    this.onLocalStreamSubject.complete()
    this.onRemoteStreamSubject.complete()
    this.onDataOpenSubject.complete()
    this.onDataSubject.complete()
    this.onCleanupSubject.complete()
    this.detachedSubject.complete()
    this.jsepSubject.complete()
    this.eventSubjects.forEach((eventSubject) => eventSubject.complete())
  }
}
