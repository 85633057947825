import { graphql } from 'babel-plugin-relay/macro'

const AttachDeviceLoginCodeMutation = graphql`
  mutation AttachDeviceLoginCodeMutation($code: String!, $roomId: UUID!) {
    attachDeviceLoginCode(input: { _code: $code, _roomId: $roomId }) {
      clientMutationId
    }
  }
`

export default AttachDeviceLoginCodeMutation
