import React, { createContext, useContext } from 'react'
import { RecordingInfo, defaultRecordingInfo } from '../Common/recording/recordingInfo'

const recordingContext = createContext<RecordingInfo>(defaultRecordingInfo)

interface RecordingProviderProps {
  recordingInfo: RecordingInfo
}

export const RecordingProvider: React.FC<RecordingProviderProps> = ({
  recordingInfo,
  children
}) => {
  return <recordingContext.Provider value={recordingInfo}>{children}</recordingContext.Provider>
}

export const useRecording = () => {
  return useContext(recordingContext)
}
