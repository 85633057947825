import { useCallback, useMemo } from 'react'
import { Message } from '../../../../../../Models/apiEntities'

export const usePlaybackChatMessages = ({ session }) => {
  const compareMessages = useCallback(({ node: a }, { node: b }) => {
    if (!a.createdAt || !b.createdAt) {
      return -1 // This ensures optimistic response stays in correct order
    }
    const timeA = new Date(a.createdAt).getTime()
    const timeB = new Date(b.createdAt).getTime()
    return timeA - timeB
  }, [])

  const orderedMessages = useMemo(() => {
    return session.messages.edges
      ?.filter(({ node }) => node?.deletedAt === null)
      .sort(compareMessages)
      .map((c) => c.node || ({} as Message))
  }, [session, compareMessages])

  return {
    messages: orderedMessages
  }
}
