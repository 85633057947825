import { useMutation } from '../../../Common/hooks/useMutation'
import UpdateMessageMutation from '../../../GraphQL/mutations/UpdateMessageMutation'

export const mapToVariable = ({
  id,
  content,
  sessionTime,
  deletedAt
}: {
  id: string
  content?: string
  sessionTime?: number
  deletedAt?: string
}) => ({
  input: {
    id,
    messagePatch: {
      content,
      sessionTime,
      deletedAt
    }
  }
})

export const useUpdateMessageMutation = () => useMutation<any, any>(UpdateMessageMutation)
