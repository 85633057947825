/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SoftDeleteLiveroomByIdInput = {
    clientMutationId?: string | null;
    roomId: unknown;
};
export type DeleteLiveroomMutationVariables = {
    input: SoftDeleteLiveroomByIdInput;
};
export type DeleteLiveroomMutationResponse = {
    readonly softDeleteLiveroomById: {
        readonly liveroomEdge: {
            readonly node: {
                readonly id: unknown;
                readonly deletedAt: unknown | null;
            } | null;
        } | null;
        readonly query: {
            readonly canCreateRoom: boolean | null;
            readonly currentProfile: {
                readonly featureFlags: {
                    readonly nodes: ReadonlyArray<{
                        readonly key: string | null;
                        readonly value: string | null;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type DeleteLiveroomMutation = {
    readonly response: DeleteLiveroomMutationResponse;
    readonly variables: DeleteLiveroomMutationVariables;
};



/*
mutation DeleteLiveroomMutation(
  $input: SoftDeleteLiveroomByIdInput!
) {
  softDeleteLiveroomById(input: $input) {
    liveroomEdge {
      node {
        id
        deletedAt
      }
    }
    query {
      canCreateRoom
      currentProfile {
        featureFlags {
          nodes {
            key
            value
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SoftDeleteLiveroomByIdPayload",
    "kind": "LinkedField",
    "name": "softDeleteLiveroomById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LiveroomsEdge",
        "kind": "LinkedField",
        "name": "liveroomEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Liveroom",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateRoom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Profile",
            "kind": "LinkedField",
            "name": "currentProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ColumnProfileFeatureFlagsConnection",
                "kind": "LinkedField",
                "name": "featureFlags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ColumnProfileFeatureFlagsRecord",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteLiveroomMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteLiveroomMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5db1fce4b4baa13855b52799bd68e95f",
    "id": null,
    "metadata": {},
    "name": "DeleteLiveroomMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteLiveroomMutation(\n  $input: SoftDeleteLiveroomByIdInput!\n) {\n  softDeleteLiveroomById(input: $input) {\n    liveroomEdge {\n      node {\n        id\n        deletedAt\n      }\n    }\n    query {\n      canCreateRoom\n      currentProfile {\n        featureFlags {\n          nodes {\n            key\n            value\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b241860e6d17fd4a0ae8621abf96225e';
export default node;
