import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.4286 17.2857H18.2386L13.4171 3.38429C13.3782 3.27199 13.3053 3.17463 13.2084 3.10575C13.1116 3.03687 12.9956 2.99991 12.8768 3H11.1232C11.0044 2.99998 10.8886 3.03698 10.7918 3.10586C10.695 3.17473 10.6221 3.27205 10.5832 3.38429L5.76143 17.2857H4.57143C4.41988 17.2857 4.27453 17.3459 4.16737 17.4531C4.0602 17.5602 4 17.7056 4 17.8571V18.4286C4 18.5801 4.0602 18.7255 4.16737 18.8326C4.27453 18.9398 4.41988 19 4.57143 19H9.14286C9.29441 19 9.43975 18.9398 9.54692 18.8326C9.65408 18.7255 9.71429 18.5801 9.71429 18.4286V17.8571C9.71429 17.7056 9.65408 17.5602 9.54692 17.4531C9.43975 17.3459 9.29441 17.2857 9.14286 17.2857H7.87714L9.06679 13.8571H14.9332L16.1229 17.2857H14.8571C14.7056 17.2857 14.5602 17.3459 14.4531 17.4531C14.3459 17.5602 14.2857 17.7056 14.2857 17.8571V18.4286C14.2857 18.5801 14.3459 18.7255 14.4531 18.8326C14.5602 18.9398 14.7056 19 14.8571 19H19.4286C19.5801 19 19.7255 18.9398 19.8326 18.8326C19.9398 18.7255 20 18.5801 20 18.4286V17.8571C20 17.7056 19.9398 17.5602 19.8326 17.4531C19.7255 17.3459 19.5801 17.2857 19.4286 17.2857ZM9.66179 12.1429L12 5.40393L14.3382 12.1429H9.66179Z" />
  </SvgIcon>
)

export default Icon
