export const routes = {
  login: {
    path: '/',
  },
  signUp: {
    path: '/signup',
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  changePassword: {
    path: '/reset-password',
  },
  room: {
    path: `/rooms/:hash`,
  },
  invite: {
    path: `/accept_invite/:inviteToken`,
  },
  acceptSpecialInvite: {
    path: `/accept_special_invite/:inviteToken`,
  },
  verify: {
    path: '/verify',
  },
  sso: {
    path: '/sso',
  },
  jitSignup: {
    path: '/jit-signup',
  },
  postSSO: {
    path: '/post-sso',
  },
}

export const paths = [
  routes.login.path,
  routes.signUp.path,
  routes.forgotPassword.path,
  routes.changePassword.path,
  routes.room.path,
  routes.invite.path,
  routes.verify.path,
  routes.sso.path,
  routes.jitSignup.path,
]
