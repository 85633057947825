import { useCallback, useState } from 'react'

import { useAlerts } from '../../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../../Components/Alert/Alert'
import { useEffectNonNull } from '../../../../../../../Common/hooks/useEffectNonNull'
import { useUpdateRecordingMutation } from './useUpdateRecordingMutation'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../../../../../Common/log/Log'
import { RoomSession, Room } from '../../../../../../../Models/apiEntities'

export enum DeleteRecordingState {
  awaiting,
  submitting,
  success
}

const defaultErrorText = 'Error deleting recording!'

export const useDeleteRecording = (recording: RoomSession, room: Room) => {
  const [state, setState] = useState(DeleteRecordingState.awaiting)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const alert = useAlerts()

  const [executeDeleteRecordingMutation, { error, graphQLErrors }] = useUpdateRecordingMutation(
    room.id
  )

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
    onError: useCallback(() => {
      setState(DeleteRecordingState.awaiting)
    }, [setState])
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
    setState(DeleteRecordingState.awaiting)
  }, [error])

  const deleteRecording = useCallback(async () => {
    setState(DeleteRecordingState.submitting)

    const deletedAt = new Date().toISOString()
    const variables = {
      input: {
        id: recording.id,
        sessionPatch: {
          deletedAt
        }
      },
      roomId: room.id
    }

    try {
      await executeDeleteRecordingMutation(variables)
    } catch (e) {
      logger.error(e)
    }
  }, [executeDeleteRecordingMutation, setState, recording, room])

  const showDeleteConfirmation = useCallback(async () => {
    setIsDeleteConfirmationOpen(true)
  }, [setIsDeleteConfirmationOpen])

  const closeDeleteConfirmation = useCallback(async () => {
    setIsDeleteConfirmationOpen(false)
  }, [setIsDeleteConfirmationOpen])

  return {
    deleteRecordingState: state,
    deleteRecording,
    showDeleteConfirmation,
    closeDeleteConfirmation,
    isDeleteConfirmationOpen
  }
}
