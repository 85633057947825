import { graphql } from 'babel-plugin-relay/macro'

const getStreamKeyMutation = graphql`
  mutation GetStreamKeyMutation($input: GetStreamKeyInput!) {
    getStreamKey(input: $input) {
      uuid
    }
  }
`
export default getStreamKeyMutation
