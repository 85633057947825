import React from 'react'
import { useVerifyEmailAction, EmailVerificationState } from './hooks/useVerifyEmailAction'
import VerifyEmailSuccess from './VerifyEmailSuccess/VerifyEmailSuccess'
import VerifyEmailError from './VerifyEmailError/VerifyEmailError'

/**
 * Evercast verify email view
 * @constructor
 */
const VerifyView: React.FC = () => {
  const { state } = useVerifyEmailAction()

  if (state === EmailVerificationState.success) {
    return <VerifyEmailSuccess />
  }

  if (state === EmailVerificationState.error) {
    return <VerifyEmailError />
  }

  return null
}

export default React.memo(VerifyView)
