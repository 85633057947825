import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
    root: {
        fontSize: '12px',
        '& > a': {
            fontSize: '12px'
        },
        '& > a:hover': {
            textDecoration: 'underline',
        }
    }
})

const TermsPrivacyView = () => {
  const classes = useStyles()
  return (
    <Box mb={1} className={classes.root}>
      By creating an account, you agree to our{' '}
      <a href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
        <strong>Terms of Service</strong>
      </a>{' '}
      and{' '}
      <a href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
        <strong>Privacy Policy</strong>
      </a>
      .
    </Box>
  )
}

export default TermsPrivacyView
