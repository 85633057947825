import React from 'react'
import { ReactComponent as VideocamOutlined } from '../../../../../../../Static/images/camera.svg'
import { ReactComponent as VideocamOffOutlined } from '../../../../../../../Static/images/camera_disabled.svg'
import { ReactComponent as VideocamOutlined2x } from '../../../../../../../Static/images/camera2x.svg'
import { ReactComponent as VideocamOffOutlined2x } from '../../../../../../../Static/images/camera_disabled2x.svg'
import { ReactComponent as VideocamNone } from '../../../../../../../Static/images/camera_none.svg'
import { ReactComponent as VideocamNone2x } from '../../../../../../../Static/images/camera_none2x.svg'
import OutlinedToggleIconButton from '../../../../../../../Components/Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import { ToggleIconButtonProps } from '../../../../../../../Components/Buttons/ToggleIconButton/ToggleIconButton'
interface CameraToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
  medium?: boolean
  thickness?: number
  cameraSetToNone?: boolean
  noneSetTooltipTitle?: string
}

const CameraToggleButton: React.FC<CameraToggleButtonProps> = ({ ...otherProps }) => {
  const { medium, cameraSetToNone, ...props } = otherProps

  const setIcon = () => {
    if (cameraSetToNone) {
      return medium ? VideocamNone2x : VideocamNone
    } else {
      return medium ? VideocamOutlined2x : VideocamOutlined
    }
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <OutlinedToggleIconButton
        activeTooltipTitle="Turn off camera"
        inactiveTooltipTitle="Turn on camera"
        noDevicesTooltipTitle="No camera detected"
        noneSetTooltipTitle="No camera selected"
        icon={setIcon()}
        cameraSetToNone={cameraSetToNone}
        inactiveIcon={medium ? VideocamOffOutlined2x : VideocamOffOutlined}
        label="Camera"
        {...props}
      />
    </div>
  )
}
export default React.memo(CameraToggleButton)
