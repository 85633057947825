import React from 'react'

interface Props {
  condition: boolean
  fallback?: React.ReactNode
}

/**
 * Conditionally renders children
 * @param condition
 * @param children
 * @param fallback
 * @constructor
 */
export const WithCondition: React.FC<Props> = ({ condition, children, fallback }) => {
  if (!condition) {
    return fallback ? <>{fallback}</> : null
  }

  return <>{children}</>
}
