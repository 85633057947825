import React, { useState, useCallback } from 'react'
import Recaptcha from 'react-recaptcha'
import { useEnvironment } from '../../Providers/EnvironmentProvider'
import { FormHelperText, Box, Grid } from '@material-ui/core'
import ProgressBar from '../ProgressBar/ProgressBar'
import { useDelayedEffect } from '../../Common/hooks/useDelayedEffect'

import './Captcha.scss'

interface CaptchaProps {
  onValue: (string) => void
  onExpiration: () => void
  error: boolean
  focusElementAfter?: React.RefObject<HTMLElement | undefined | null>
}

const Captcha: React.FC<CaptchaProps> = ({ onValue, onExpiration, error, focusElementAfter }) => {
  const [displayLoading, setDisplayLoading] = useState(true)
  const environment = useEnvironment()

  // do short loading animation to prevent blinking
  useDelayedEffect(
    {
      delay: 600,
      effect: () => {
        setDisplayLoading(false)
      }
    },
    [setDisplayLoading]
  )

  const handleOnValue = useCallback(
    (value) => {
      onValue(value)
      const elementToFocus = focusElementAfter?.current
      if (elementToFocus) {
        setTimeout(() => {
          elementToFocus.focus()
        }, 100)
      }
    },
    [focusElementAfter, onValue]
  )

  return (
    <Grid container justify="center">
      <Grid item>
        {displayLoading && (
          <Box className="captcha-loading">
            <ProgressBar className="captcha-loading-progress" />
          </Box>
        )}
        <Box hidden={displayLoading}>
          <Recaptcha
            sitekey={environment.captchaSiteKey}
            render="explicit"
            verifyCallback={handleOnValue}
            expiredCallback={onExpiration}
          />
        </Box>
        {error && <FormHelperText error={error}>Please verify that you're human</FormHelperText>}
      </Grid>
    </Grid>
  )
}

export default React.memo(Captcha)
