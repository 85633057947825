import blackBgImage from './room-card-bg-black.jpg'
import goldBgImage from './room-card-bg-gold.jpg'
import greenBgImage from './room-card-bg-green.jpg'
import purpleBgImage from './room-card-bg-purple.jpg'
import { getRandomBackgroundIndex } from './randomBackground'

const imagesArray = [greenBgImage, goldBgImage, blackBgImage, purpleBgImage]
const ROOM_CARD_BACKGROUNDS_KEY = 'ROOM_CARD_BACKGROUNDS'

/**
 * Returns card background based on the room id
 * New room will have a random background, existing one
 * will get the bg index from local storage
 * @param roomId
 */
export const getCardBackgroundByRoom = (roomId: string) => {
  const roomsBackgrounds = JSON.parse(localStorage.getItem(ROOM_CARD_BACKGROUNDS_KEY) || '{}')
  if (isNaN(roomsBackgrounds[roomId])) {
    // We need to assign a background to this room
    roomsBackgrounds[roomId] = getRandomBackgroundIndex(imagesArray.length)
    localStorage.setItem(ROOM_CARD_BACKGROUNDS_KEY, JSON.stringify(roomsBackgrounds))
  }
  return `url(${imagesArray[roomsBackgrounds[roomId]]})`
}
