import { useForm } from 'react-hook-form'
import { useCallback, useMemo, useRef } from 'react'
import {
  useFormValidation,
  useCustomValidation,
  emailAddressPattern
} from '../../../../../Common/utils/formUtils'
import { useEnvironment } from '../../../../../Providers/EnvironmentProvider'

export interface ForgotPasswordForm {
  email: string
  captcha: string
}

export const useForgotPasswordForm = (onSubmit: (formData: ForgotPasswordForm) => void) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods
  const inputButtonRef = useRef<HTMLButtonElement | null>()

  const inputs = useFormValidation(formMethods, {
    email: {
      required: 'Please enter your email address',
      pattern: {
        value: emailAddressPattern,
        message: 'Please enter a valid email address'
      }
    }
  })

  const environment = useEnvironment()
  const captchaSiteKey = environment.captchaSiteKey
  const [captchaError, setCaptcha] = useCustomValidation(formMethods, {
    name: 'captcha',
    rules: {
      ...(!!captchaSiteKey && { required: 'captcha error' })
    }
  })

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((formData: ForgotPasswordForm) => {
        onSubmit(formData)
      }),
    [onSubmit, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs,
    captcha: {
      isEnabled: !!environment.captchaSiteKey,
      setValue: setCaptcha,
      handleExpiration: useCallback(() => setCaptcha(null), [setCaptcha]),
      error: !!captchaError
    },
    inputButtonRef
  }
}
