import React from 'react'

import './GeneralPadding.scss'

interface GeneralPaddingProps {
  children: React.ReactNode
}

/**
 * Component to manage the padding depending on screen size
 * @constructor
 */
const GeneralPadding: React.FC<GeneralPaddingProps> = ({ children }) => {
  return <div className="general-padding">{children}</div>
}

export default React.memo(GeneralPadding)
