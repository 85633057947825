import { useMutation } from '../../../Common/hooks/useMutation'
import DeleteRoomInviteMutation from '../../../GraphQL/mutations/DeleteRoomInviteMutation'

function createConfig(roomId) {
  return {
    updater: (store, params) => {
      const deleteInviteById = store.getRootField('deleteInviteById')
      const queryField = deleteInviteById.getLinkedRecord('query')
      const liveroomById = queryField.getLinkedRecord('liveroomById', { id: roomId })
      const invitesByRoomId = liveroomById.getLinkedRecord('invitesByRoomId')
      const nodes = invitesByRoomId.getLinkedRecords('nodes')

      const oldLiveroomById = store.get(roomId)
      const oldInvitesByRoomId = oldLiveroomById.getLinkedRecord('invitesByRoomId')

      oldInvitesByRoomId.setLinkedRecords(nodes, 'nodes')
    }
  }
}

export const useDeleteRoomInviteMutation = (roomId) =>
  useMutation<any, any>(DeleteRoomInviteMutation, createConfig(roomId))

export const mapToVariables = (roomId: string, inviteId: string) => ({
  input: {
    id: inviteId
  },
  id: roomId
})
