/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ListenApproveDeviceLoginCodeRequestSubscriptionVariables = {
    roomId: string;
};
export type ListenApproveDeviceLoginCodeRequestSubscriptionResponse = {
    readonly listenApproveDeviceLoginCodeRequest: {
        readonly approve: {
            readonly deviceCodeId: string | null;
            readonly deviceName: string | null;
        } | null;
        readonly event: string | null;
    } | null;
};
export type ListenApproveDeviceLoginCodeRequestSubscription = {
    readonly response: ListenApproveDeviceLoginCodeRequestSubscriptionResponse;
    readonly variables: ListenApproveDeviceLoginCodeRequestSubscriptionVariables;
};



/*
subscription ListenApproveDeviceLoginCodeRequestSubscription(
  $roomId: String!
) {
  listenApproveDeviceLoginCodeRequest(input: {_roomId: $roomId}) {
    approve {
      deviceCodeId
      deviceName
    }
    event
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_roomId",
            "variableName": "roomId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ApproveDeviceLoginCodeRequestPayload",
    "kind": "LinkedField",
    "name": "listenApproveDeviceLoginCodeRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApproveDeviceLoginCodeRequest",
        "kind": "LinkedField",
        "name": "approve",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceCodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "event",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListenApproveDeviceLoginCodeRequestSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListenApproveDeviceLoginCodeRequestSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e639940674e7b5418f37cdd610f5dbc2",
    "id": null,
    "metadata": {},
    "name": "ListenApproveDeviceLoginCodeRequestSubscription",
    "operationKind": "subscription",
    "text": "subscription ListenApproveDeviceLoginCodeRequestSubscription(\n  $roomId: String!\n) {\n  listenApproveDeviceLoginCodeRequest(input: {_roomId: $roomId}) {\n    approve {\n      deviceCodeId\n      deviceName\n    }\n    event\n  }\n}\n"
  }
};
})();
(node as any).hash = '61ad25e07d12b9666c89998b5896577e';
export default node;
