import { useRelaySubscription } from '../../../Common/hooks/useRelaySubscription'
import { useMemo } from 'react'
import NoteSubscription from '../../../GraphQL/subscriptions/NotesSubscription'
import { createConfig } from './useCreateNoteMutation'

export const useNoteSubscription = (connectionName: string, roomId: string) => {
  const noteConfig = useMemo(() => {
    return createConfig('notes', connectionName, roomId)
  }, [roomId, connectionName])

  const noteSubscriptionConfig = useMemo(
    () => ({
      subscription: NoteSubscription,
      variables: {
        input: {
          roomId
        }
      },
      updater: noteConfig.updater
    }),
    [noteConfig, roomId]
  )

  const context = useMemo(
    () => ({
      roomId,
      connectionName
    }),
    [roomId, connectionName]
  )

  useRelaySubscription(noteSubscriptionConfig, context)
}
