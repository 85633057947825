import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useParams } from 'react-router-dom'
import { useAccount } from './hooks/useAccount'
import SettingsView from './SettingsView/SettingsView'
import AccountViewQuery from '../../../../GraphQL/queries/AccountViewQuery'
import QueryRenderer from '../../../../GraphQL/QueryRenderer'
import { Box } from '@material-ui/core'
import NotFoundRedirect from '../../NotFoundView/NotFoundRedirect'

export const tabsValues = {
  settings: 'settings'
}

interface WithPaddingProps {
  paddingY?: number
  paddingX?: number
}

const WithPadding: React.FC<WithPaddingProps> = ({ children, paddingY = 4, paddingX = 4 }) => {
  return (
    <Box paddingY={paddingY} paddingX={paddingX}>
      {children}
    </Box>
  )
}

const ComponentMap = {
  [tabsValues.settings]: SettingsView
}

/**
 * Account view
 * @constructor
 */
const AccountView: React.FC = () => {
  const { tab } = useParams<{ tab: string }>()
  const { index, handleTabChange } = useAccount(tab)
  const Component = ComponentMap[index]

  if (!Component) {
    return <NotFoundRedirect />
  }

  return (
    <QueryRenderer
      query={AccountViewQuery}
      renderResult={(props) => (
        <>
          <Tabs
            value={index}
            onChange={handleTabChange}
            aria-label="account-tabs"
            textColor="secondary"
            centered
          >
            <Tab label="Settings" value={tabsValues.settings} />
          </Tabs>
          <WithPadding>{Component ? <Component {...props} /> : <NotFoundRedirect />}</WithPadding>
        </>
      )}
    />
  )
}

export default React.memo(AccountView)
