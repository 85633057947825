import React from 'react'
import SuccessNotification from '../../../../../Components/SuccessNotification/SuccessNotification'

/**
 * Evercast sign up success view
 * @constructor
 */
const SignUpSuccess: React.FC = () => {
  return (
    <SuccessNotification>
      Please check your email for a link to finish the Evercast account creation.
    </SuccessNotification>
  )
}

export default React.memo(SignUpSuccess)
