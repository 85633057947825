import { useCallback, useState, useLayoutEffect, useRef } from 'react'

export const useChatMessageList = ({
  messagesCount,
  defaultMessagesNumber,
  isVisible,
  hasMore,
  isLoading,
  loadMore
}) => {
  const [isRelayPaginationLoading, setIsRelayPaginationLoading] = useState(false)
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false)
  const messageEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = useCallback(() => {
    return messageEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [])

  useLayoutEffect(() => {
    if (isVisible) scrollToBottom()
  }, [isVisible, scrollToBottom])

  useLayoutEffect(() => {
    if (!isHistoryLoaded) scrollToBottom()
    setIsHistoryLoaded(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesCount, setIsHistoryLoaded, scrollToBottom])

  const loadMoreMessages = useCallback(() => {
    if (!hasMore()) {
      // Nothing more to load
      return
    } else if (isLoading()) {
      // Request is already pending
      return
    }

    setIsRelayPaginationLoading(true)
    setIsHistoryLoaded(true)

    loadMore(defaultMessagesNumber, () => {
      setIsRelayPaginationLoading(false)
    })
  }, [setIsRelayPaginationLoading, hasMore, isLoading, loadMore, defaultMessagesNumber])

  return {
    loadMoreMessages,
    messageEndRef,
    isLoadingMoreMessages: isRelayPaginationLoading,
    canLoadMore: hasMore()
  }
}
