import { useRef } from 'react'

/**
 * Returns objects that is initialized using provided function and won't be recreated during rerenders
 * @param init
 */
export const useInitOnce = <T>(init: () => T): T => {
  const ref = useRef<T>()
  if (!ref.current) {
    ref.current = init()
  }

  return ref.current as T
}
