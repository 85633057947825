import React, { createContext, useContext, useEffect, useState } from 'react'
import useApi from '../API/useApi'
import { Room, Watermark } from '../Models/apiEntities'
import { noop } from 'rxjs'
import { useRoomFeatureFlagsRest } from '../Common/utils/featureFlags'

const watermarkContext = createContext<WatermarkResult>({ forensicWatermark: null, forensicWatermarkError: false, setStartForensicWatermark: noop, shouldAllowForensicWatermark: false, shouldAllowVisibleWatermark: false, watermarkLoading: false })

interface WatermarkResult {
  forensicWatermark: Watermark | null
  forensicWatermarkError: boolean
  setStartForensicWatermark: (start: boolean) => void
  shouldAllowForensicWatermark: boolean
  shouldAllowVisibleWatermark: boolean
  watermarkLoading: boolean
}

interface WatermarkProviderProps {
  room: Room
}

export const WatermarkProvider: React.FC<WatermarkProviderProps> = ({ room, children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [startWatermark, setStartWatermark] = useState<boolean>(false)
  const [streamWatermark, setStreamWatermark] = useState<Watermark | null>(null);
  const [watermarkError, setWatermarkError] = useState<boolean>(false)
  const { ALLOW_FORENSIC_WATERMARKING: shouldAllowForensicWatermark, ALLOW_WATERMARK: shouldAllowVisibleWatermark } = useRoomFeatureFlagsRest()

  const { Room } = useApi()

  const getWatermarking = async () => {
    try {
      setLoading(true)
      const response = await Room.getWatermarks(room.id);
      setStreamWatermark(response?.watermarks[0]);
      setWatermarkError(false)
      setLoading(false)
    } catch (e) {
      console.error(e.message)
      setStreamWatermark(null);
      setWatermarkError(true)
      setLoading(false)
      return null
    }
  }

  useEffect(() => {
    if (shouldAllowForensicWatermark && startWatermark && (!streamWatermark || new Date().getTime() > new Date(streamWatermark.expiresAt).getTime())) {
      getWatermarking()
    }
  }, [startWatermark, shouldAllowForensicWatermark, streamWatermark])

  return (
    <watermarkContext.Provider value={{ forensicWatermark: streamWatermark, forensicWatermarkError: watermarkError, setStartForensicWatermark: setStartWatermark, shouldAllowForensicWatermark, shouldAllowVisibleWatermark, watermarkLoading: loading}}>{children}</watermarkContext.Provider>
  )
}

export const useWatermarkProvider = () => {
  return useContext(watermarkContext)
}
