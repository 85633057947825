import { useCallback } from 'react'
import { fetchQuery, GraphQLTaggedNode, Variables } from 'react-relay'
import { CacheConfig } from 'relay-runtime'
import { useRelayEnvironment } from '../../Providers/RelayEnvironmentProvider'

/**
 * return function to perform queries in current relay environment
 */
export const useQuery = () => {
  const relayEnvironment = useRelayEnvironment()
  // set relay environment
  return useCallback(
    <T>(taggedNode: GraphQLTaggedNode, variables: Variables = {}, cacheConfig?: CacheConfig) => {
      return fetchQuery(relayEnvironment, taggedNode, variables, cacheConfig) as Promise<T>
    },
    [relayEnvironment]
  )
}
