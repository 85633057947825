import React, { useMemo } from 'react';
import { IconButton } from '@material-ui/core'
import KickFromRoomConfirmationDialog from '../../../KickFromRoomConfirmationDialog/KickFromRoomConfirmationDialog';
import Menu from '../../../Menu/Menu';
import { ReactComponent as DropDownIcon } from '../../../../Static/images/thumbnail_indicators_small.svg';
import { ReactComponent as KickParticipant } from '../../../../Static/images/kick_from_room_red.svg';
import { ReactComponent as MuteParticipant } from '../../../../Static/images/microphone_crossed_red.svg';
import { useDialog } from '../../../RoomDialog/hooks/useDialog';

interface PeopleListMenuProps {
  name: string;
  personId: string;
  canPublish: boolean;
  participantsManager;
  kickPermissionInfo;
  mutePermissionInfo;
}

const PeopleListMenu = ({ name, participantsManager, personId, canPublish, kickPermissionInfo, mutePermissionInfo }: PeopleListMenuProps) => {
  const [isKickDialogOpen, openKickDialog, closeKickDialog] = useDialog();
  const iconStyles = { style: { height: '16px', width: 'auto' } };

  const menuOptions = useMemo(
    () => [
      {
        text: 'Remove from room',
        icon: <KickParticipant {...iconStyles} />,
        onClick: openKickDialog,
        isAvailable: () => 
          kickPermissionInfo.isAvailable && kickPermissionInfo.isAllowed,
      },
      {
        text: 'Mute for all',
        icon: <MuteParticipant {...iconStyles} />,
        onClick: () => participantsManager.muteParticipant(personId),
        isAvailable: () => 
          mutePermissionInfo.isAvailable && mutePermissionInfo.isAllowed && canPublish,
      },
    ], 
    [openKickDialog]
  )

  return (
    <>
      <Menu 
        button={(openMenu) => (
          <IconButton aria-label="Kick-user-from-room" onClick={openMenu}>
            <DropDownIcon {...iconStyles}  />
          </IconButton>
        )}
        menuOptions={menuOptions}
      />
      <KickFromRoomConfirmationDialog 
        name={name}
        onConfirm={async () => {
          await participantsManager.kickParticipant(personId)
          closeKickDialog()
        }}
        onCancel={closeKickDialog}
        isOpen={isKickDialogOpen}
      />
    </>
  )
}

export default React.memo(PeopleListMenu);

