import { useCallback } from 'react'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import { useDisplayGraphQLErrorsIfPresent } from '../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../Common/log/Log'
import {
  useLoginWithAppleTVMutation,
  mapToVariables,
  AppleTVFormInput
} from './useLoginWithAppleTVMutation'
import { useEffectNonNull } from '../../../Common/hooks/useEffectNonNull'

/**
 * Possible error codes that could be returned by attach code to room mutation
 */
export const ERROR_CODES = {
  '07350': 'Trying to access a deactivated room.',
  '09162': 'Please enter a valid sign-in code.',
  '09164': 'No user is present in room to approve connecting device.',
  '09167': 'Error in attaching room to code.',
  '09163': "You are not invited or don't have invite/disinvite permissions"
}

const defaultErrorText = 'Error login with Apple TV'

export const useLoginWithAppleTV = (roomId: string, onSuccess?: () => void) => {
  const alert = useAlerts()

  const [executeMutation, { isInProgress, error, graphQLErrors }] = useLoginWithAppleTVMutation(
    onSuccess
  )

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: ERROR_CODES,
    graphQLErrors,
    defaultErrorText
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  const performLoginWithAppleTV = useCallback(
    async (input: AppleTVFormInput) => {
      try {
        await executeMutation(mapToVariables(roomId, input.code))
        alert('Success! Waiting for approval.', AlertType.success)
      } catch (e) {
        logger.error(e)
      }
    },
    [executeMutation, alert, roomId]
  )

  return {
    isInProgress,
    performLoginWithAppleTV
  }
}
