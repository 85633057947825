import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { InputProps } from '../../../Common/utils/formUtils'
import ProgressButton from '../../ProgressButton/ProgressButton'

type AppleTVLoginInputObject<T> = {
  [P: string]: T
}

interface AppleTVLoginFormProps {
  onSubmit: () => void
  isInProgress: boolean
  inputs: AppleTVLoginInputObject<InputProps>
}

const AppleTVLoginForm: React.FC<AppleTVLoginFormProps> = ({
  onSubmit,
  isInProgress,
  inputs: { code }
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2} alignItems="center" justify="flex-start">
        <Grid item xs={12}>
          <TextField
            autoFocus
            {...code}
            label="Apple TV sign-in code"
            type="text"
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <ProgressButton
            type="submit"
            color="secondary"
            variant="contained"
            isLoading={isInProgress}
            fullWidth
          >
            Sign in
          </ProgressButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default React.memo(AppleTVLoginForm)
