import { map } from 'rxjs/operators'
import { JanusClient, JanusEvent, MessageType } from '../../plugin/JanusClient'

export class JanusPlaybackController {
  private janusClient: JanusClient
  private readonly recordingId: string
  private playbackId: string
  private tag = 'Playback'

  constructor({ janusSignallingConnection, recordingId }) {
    this.recordingId = recordingId

    // janus client setup
    this.janusClient = new JanusClient(janusSignallingConnection, this.tag)
    this.janusClient.onEvent(JanusEvent.PLAYBACK_READY).subscribe(this.handlePlaybackReady)
  }

  preparePlayback = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackPrepare,
        recording_id: this.recordingId
      }
    })

  startPlayback = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackStart,
        playback_id: this.playbackId
      }
    })

  stopPlayback = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackStop,
        playback_id: this.playbackId
      }
    })

  pausePlayback = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackPause,
        playback_id: this.playbackId
      }
    })

  resumePlayback = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackResume,
        playback_id: this.playbackId
      }
    })

  seekPlayback = async (offset: number) =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackSeek,
        playback_id: this.playbackId,
        offset
      }
    })

  onPlaybackReady = () =>
    this.janusClient
      .onEvent(JanusEvent.PLAYBACK_READY)
      .pipe(map(({ playback_id: playbackId, participants }) => ({ playbackId, participants })))

  onActiveStreams = () =>
    this.janusClient.onEvent(JanusEvent.PLAYBACK_ACTIVE_STREAMS).pipe(
      map(({ streams }) => {
        if (Object.keys(streams).length === 0) {
          return []
        }
        return streams as string[]
      })
    )

  onPlaybackStarted = () => this.janusClient.onEvent(JanusEvent.PLAYBACK_STARTED)

  onPlaybackEnded = () => this.janusClient.onEvent(JanusEvent.PLAYBACK_ENDED)

  onPlaybackSeeked = () =>
    this.janusClient.onEvent(JanusEvent.PLAYBACK_SEEKED).pipe(map(({ offset }) => offset))

  onPlaybackPaused = () => this.janusClient.onEvent(JanusEvent.PLAYBACK_PAUSED)

  onPlaybackResumed = () => this.janusClient.onEvent(JanusEvent.PLAYBACK_RESUMED)

  onPlaybackError = () => this.janusClient.onEvent(JanusEvent.ERROR)

  private handlePlaybackReady = ({ playback_id: playbackId }) => {
    this.playbackId = playbackId
  }

  leave = async () => {
    await this.janusClient.detach({ noRequest: false })
    this.janusClient.cleanup()
  }
}
