import React, { useState, useEffect, useRef } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { GraphQLTaggedNode } from 'relay-runtime'
import { useLazyLoadQuery } from 'react-relay/lib/relay-experimental'
import { ExportType } from './DownloaderContainer'
import { Message } from '../../../../Models/apiEntities'
import moment from 'moment'

const chatsQuery = graphql`
  query ChatMessagesQuery($roomHash: UUID!, $condition: ExportMessagesCondition) {
    getChatMessageByRoomHash(
      roomHash: $roomHash
      condition: $condition
      orderBy: "created_at desc"
    ) {
      nodes {
        profileDisplayName
        createdAt
        content
        sessionTime
      }
    }
  }
`

export interface ChatMessagesProps {
  roomHash: string
  exportType: ExportType
  startDate: string
  fetchKey?: number
  endDate?: string
  onQueryLoad?: (messages: Message[]) => void
  fileName?: string
  fileContents?: string
}

const ChatMessages: React.FC<ChatMessagesProps> = (props) => {
  const downloadChatEl = useRef<HTMLAnchorElement | null>(null)
  const [contents, setContents] = useState<string>('')
  const [name, setName] = useState('')
  const { roomHash, onQueryLoad, fileName, fetchKey, fileContents, ...condition } = props

  const endDate = condition.endDate || condition.startDate
  const startDate = moment(condition.startDate + 'T00:00:00')
    .subtract({ seconds: fetchKey! % 2 })
    .format('Y-MM-DDTHH:mm:ss')
  const variables = {
    roomHash,
    condition: {
      duration: {
        start: { value: startDate, inclusive: true },
        end: { value: endDate + 'T23:59:59', inclusive: true }
      }
    }
  }
  const data = useLazyLoadQuery<GraphQLTaggedNode>(chatsQuery, variables, {
    fetchKey //need to specify unique key to rerender query
  })

  useEffect(() => {
    if (data.getChatMessageByRoomHash !== null) {
      const ext = condition.exportType === ExportType.CSV ? '.csv' : '.txt'
      const _fileName =
        fileName || condition.exportType + '-ChatDownload-' + moment().format('MMDDYHHmmss') + ext
      setName(_fileName)
      if (onQueryLoad !== undefined) {
        onQueryLoad(data.getChatMessageByRoomHash.nodes)
      }
    } else if (onQueryLoad !== undefined) {
      onQueryLoad([])
    }
  }, [data, fileContents, fileName, condition, onQueryLoad])

  useEffect(() => {
    if (fileContents) {
      setContents(fileContents)
    }
  }, [fileContents, setContents])

  useEffect(() => {
    if (downloadChatEl !== null && contents) {
      downloadChatEl!.current!.click()
      setContents('')
    }
  }, [downloadChatEl, contents])

  return (
    <a ref={downloadChatEl} download={name} href={contents} style={{ height: 1, width: 1 }}>
      {' '}
    </a>
  )
}

export default React.memo(ChatMessages)
