import { useMutation } from '../../../../../../Common/hooks/useMutation'
import UpdateProfileMutation from '../../../../../../GraphQL/mutations/UpdateProfileMutation'

export const useUpdateProfileMutation = () => {
  return useMutation<any, any>(UpdateProfileMutation, {
    updater: (store, params) => {
      const root = store.getRootField('updateProfileById')
      const queryField = root.getLinkedRecord('query')
      const currentProfile = queryField.getLinkedRecord('currentProfile')
      const rootField = store.getRoot()
      rootField.setLinkedRecord(currentProfile, 'currentProfile')
    }
  })
}
