/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountViewQueryVariables = {};
export type AccountViewQueryResponse = {
    readonly currentProfile: {
        readonly " $fragmentRefs": FragmentRefs<"SettingsView_currentProfile">;
    } | null;
};
export type AccountViewQuery = {
    readonly response: AccountViewQueryResponse;
    readonly variables: AccountViewQueryVariables;
};



/*
query AccountViewQuery {
  currentProfile {
    ...SettingsView_currentProfile
  }
}

fragment SettingsView_currentProfile on Profile {
  id
  displayName
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SettingsView_currentProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4fac5406dd5e80ff4fa10365ecaafe03",
    "id": null,
    "metadata": {},
    "name": "AccountViewQuery",
    "operationKind": "query",
    "text": "query AccountViewQuery {\n  currentProfile {\n    ...SettingsView_currentProfile\n  }\n}\n\nfragment SettingsView_currentProfile on Profile {\n  id\n  displayName\n}\n"
  }
};
(node as any).hash = '7be2665730e68cc49ac970c5e4c8f72f';
export default node;
