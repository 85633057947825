import { ProfilePreference as ProfilePreferenceModel } from '../../Models/apiEntities'

export interface ProfilePreference extends ProfilePreferenceModel {
  getValue: <T>(converter?: (value) => T) => T | null | any
}

/**
 * Enum for all the supported preference types.
 * All of the following types have to be present in preference.preference_type table.
 */
export enum PreferenceType {
  VIDEO_INPUT_DEVICE_ID = 'VIDEO_INPUT_DEVICE_ID',
  AUDIO_INPUT_DEVICE_ID = 'AUDIO_INPUT_DEVICE_ID',
  AUDIO_OUTPUT_DEVICE_ID = 'AUDIO_OUTPUT_DEVICE_ID',
  VIDEO_INPUT_ENABLED = 'VIDEO_INPUT_ENABLED',
  AUDIO_INPUT_ENABLED = 'AUDIO_INPUT_ENABLED',
  AUDIO_OUTPUT_ENABLED = 'AUDIO_OUTPUT_ENABLED',
  SCREEN_SHARE_QUALITY = 'SCREEN_SHARE_QUALITY',
  SCREEN_SHARE_AUDIO_ENABLED = 'SCREEN_SHARE_AUDIO_ENABLED',
  SCREEN_SHARE_AUDIO_DEVICE_ID = 'SCREEN_SHARE_AUDIO_DEVICE_ID',
  LAST_USED_EXTERNAL_DEVICE_VIDEO = 'LAST_USED_EXTERNAL_DEVICE_VIDEO',
  LAST_USED_EXTERNAL_DEVICE_AUDIO = 'LAST_USED_EXTERNAL_DEVICE_AUDIO',
  LAST_USED_EXTERNAL_DEVICE_QUALITY = 'LAST_USED_EXTERNAL_DEVICE_QUALITY',
  IS_EXTERNAL_DEVICE_ACTIVE = 'IS_EXTERNAL_DEVICE_ACTIVE',
  PUSH_TO_TALK_SHORTCUT = 'PUSH_TO_TALK_SHORTCUT',
  THUMBNAIL_DRAWER_OPENED = 'THUMBNAIL_DRAWER_OPENED',
  THEME = 'THEME',
  DRAW_MODE_ENABLED = 'DRAW_MODE_ENABLED',
  DRAW_TOOL = 'DRAW_TOOL',
  DRAW_THICKNESS = 'DRAW_THICKNESS',
  DRAW_LINE_IS_DASHED = 'DRAW_LINE_IS_DASHED',
  DRAW_LINE_HAS_END_ARROW = 'DRAW_LINE_HAS_END_ARROW',
  DRAW_FONT_FAMILY = 'DRAW_FONT_FAMILY',
  DRAW_FONT_SIZE = 'DRAW_FONT_SIZE',
  DRAW_FONT_IS_BOLD = 'DRAW_FONT_IS_BOLD',
  DRAW_FONT_IS_ITALIC = 'DRAW_FONT_IS_ITALIC',
  DRAW_STROKE_COLOR_RED = 'DRAW_STROKE_COLOR_RED',
  DRAW_STROKE_COLOR_GREEN = 'DRAW_STROKE_COLOR_GREEN',
  DRAW_STROKE_COLOR_BLUE = 'DRAW_STROKE_COLOR_BLUE',
  DRAW_STROKE_COLOR_ALPHA = 'DRAW_STROKE_COLOR_ALPHA',
  DRAW_FILL_COLOR_RED = 'DRAW_FILL_COLOR_RED',
  DRAW_FILL_COLOR_GREEN = 'DRAW_FILL_COLOR_GREEN',
  DRAW_FILL_COLOR_BLUE = 'DRAW_FILL_COLOR_BLUE',
  DRAW_FILL_COLOR_ALPHA = 'DRAW_FILL_COLOR_ALPHA',
  CHAT_DRAWER_OPENED = 'CHAT_DRAWER_OPENED'
}

/**
 * Converts 'true'/'false' strings to real boolean.
 * @param value
 * @constructor
 */
export const BooleanConverter = (value: string): boolean => {
  return value.toLowerCase() === 'true'
}

/**
 * Returns the preference for a given preference type.
 * @param preferences
 * @param preferenceType
 */
export const getProfilePreference = (
  preferences: ProfilePreferenceModel[],
  preferenceType: PreferenceType
): ProfilePreference => {
  if (!preferences) {
    throw new Error(`No preferences provided!`)
  }

  const preference = preferences.find((profilePreference) => {
    return profilePreference.preferenceTypeName === preferenceType
  })

  if (!preference) {
    throw new Error(`Database preference: ${preferenceType} missing!`)
  }

  return {
    ...preference,
    getValue: <T>(converter?: (value) => T): T | any =>
      getPreferenceValue<T>(preferences, preferenceType, converter)
  }
}

/**
 * Returns the preference value for a given preference type.
 * Optionally, can be strongly typed:
 * @example
 * getProfilePreference<boolean>(PreferenceType.VIDEO_INPUT_ENABLED)
 * @param preferences
 * @param preferenceType
 * @param converter
 */
export const getPreferenceValue = <T>(
  preferences: ProfilePreferenceModel[],
  preferenceType: PreferenceType,
  converter?: (value) => T
): T | any => {
  if (!preferences) {
    throw new Error(`No preferences provided!`)
  }

  const preference = preferences.find((profilePreference) => {
    return profilePreference.preferenceTypeName === preferenceType
  })

  if (!preference) {
    throw new Error(`Database preference: ${preferenceType} missing!`)
  }

  const value = preference.preferenceValue
    ? preference.preferenceValue
    : preference.preferenceTypeDefault

  return converter ? converter(value) : value
}
