import React from 'react'
import SquareIconLabelButton from '../../../Buttons/SquareIconLabelButton/SquareIconLabelButton'
import { ReactComponent as Support } from '../../../../Static/images/support.svg'

interface Props {
  onClick?: () => void
}

const InstantHelpButton: React.FC<Props> = ({ onClick }) => {
  return (
    <SquareIconLabelButton icon={Support} onClick={onClick}>
      Help
    </SquareIconLabelButton>
  )
}

export default React.memo(InstantHelpButton)
