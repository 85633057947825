import { map, mergeMap, take } from 'rxjs/operators'
import { setStereoSDP, setBaselineProfileForH264 } from '../../SDPHelper'
import { equals } from 'ramda'

import { JanusClient, JanusEvent, MessageType } from '../../plugin/JanusClient'
import { Publisher } from './LiveroomClient'
import { logger } from '../../../log/Log'

export const VIDEO_CODECS = 'vp8,vp9,h264'
export const AUDIO_CODECS = 'multiopus,opus'
export const MAX_PARTICIPANTS = 10
export const FIR_FREQUENCY = 10 // this

const makeStreamIdHash = () => Math.random().toString().slice(2, 12)

export class JanusPublisher implements Publisher {
  private janusClient: JanusClient
  private readonly roomId: string
  private readonly roomHash: string
  private lastSetAudio
  private lastSetVideo
  private lastSetBitrate
  private lastIceState: RTCIceTransportState
  private isPublishing: boolean
  public displayName: string
  public id: string

  constructor({ janusSignallingConnection, roomId, roomHash, userId, displayName }) {
    this.roomId = roomId
    this.roomHash = roomHash
    this.id = `${userId}-${makeStreamIdHash()}`
    this.displayName = displayName

    // janus client setup
    this.janusClient = new JanusClient(janusSignallingConnection, 'Publisher')
    this.janusClient.onJsep().subscribe(this.janusClient.handleRemoteJsep)
    this.janusClient.onEvent(JanusEvent.ERROR).subscribe(this.handleErrorEventReceived)
    this.janusClient.onIceState().subscribe(this.handleICEStateChangedEventReceived)
  }

  cleanup = () => {
    this.janusClient.cleanup()
  }

  /**
   * Leaves the room, clears the subscriptions
   */
  leave = async () => {
    await this.janusClient.detach()
    this.cleanup()
  }

  startRecording = async () => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.startRecording,
        room: this.roomId,
      },
    })
  }

  stopRecording = async (recordingId: string) =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.stopRecording,
        room: this.roomId,
        recording_id: recordingId,
      },
    })

  getMediaStreamStats = async () => {
    const myStream = await this.janusClient.getMediaStream()
    const connectionStats = await this.janusClient.getConnectionStats(myStream!)
    return connectionStats
  }

  getConnectionStats = async () => {
    const stats = await this.janusClient.getConnectionStats(null)
    return stats
  }

  publish = async ({ audio, video }: MediaStreamConstraints, bitrate: number) => {
    if (!this.isPublishing) {
      await this.startPublishing({ audio, video }, bitrate)
    } else {
      // reconfigure doesn't work in this case, need to stop and publish again
      if (!!audio !== !!this.lastSetAudio || !!video !== !!this.lastSetVideo) {
        logger.log(`[Publisher] Republishing feed...`)
        this.janusClient
          .onCleanup()
          .pipe(take(1))
          .subscribe(() => this.startPublishing({ audio, video }, bitrate))
        await this.unpublish()
      } else {
        await this.reconfigure({ audio, video })
        await this.changeBitrate(bitrate)
      }
    }
  }

  private startPublishing = async ({ audio, video }: MediaStreamConstraints, bitrate: number) => {
    const jsep = await this.janusClient.createOffer({
      customizeSdp: (jsepToCustomize) => {
        jsepToCustomize.sdp = setStereoSDP(jsepToCustomize.sdp)
        jsepToCustomize.sdp = setBaselineProfileForH264(jsepToCustomize.sdp)
      },
      media: {
        audioRecv: false,
        videoRecv: false,
        audioSend: !!audio,
        videoSend: !!video,
        video: video,
        audio: audio,
        data: true,
      },
    })
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        audio: !!audio,
        video: !!video,
        bitrate,
      },
      jsep,
    })
    this.lastSetAudio = audio
    this.lastSetVideo = video
    this.lastSetBitrate = bitrate
    this.isPublishing = true
  }

  /**
   * Reconfigures the connection (ie. when user changed device like camera/mic)
   * @param ownStream
   */
  private reconfigure = async ({ audio, video }: MediaStreamConstraints) => {
    const isAudioMuted = await this.janusClient.isAudioMuted()
    const isVideoMuted = await this.janusClient.isVideoMuted()
    const replaceAudio = !equals(audio, this.lastSetAudio)
    const replaceVideo = !equals(video, this.lastSetVideo)

    if (!replaceAudio && !replaceVideo) {
      return
    }
    const jsep = await this.janusClient.createOffer({
      simulcast: false,
      media: {
        audioRecv: false,
        videoRecv: false,
        replaceAudio,
        replaceVideo,
        audioSend: !!audio,
        videoSend: !!video,
        video: video,
        audio: audio,
      },
    })
    if (isAudioMuted) this.muteAudio()
    if (isVideoMuted) this.muteVideo()
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        update: true,
      },
      jsep,
    })
    this.lastSetAudio = audio
    this.lastSetVideo = video
  }

  /**
   * Force ICE to restart
   */
  private forceICERestart = async () => {
    if (!this.janusClient.isConnected) {
      logger.log(`[Publisher] Skipping ICE restart, not connected to a server.`)
      return
    }

    logger.log('[Publisher] Forcing ICE restart')

    const jsep = await this.janusClient.createOffer({
      iceRestart: true,
      media: {
        audioRecv: false,
        videoRecv: false,
        audioSend: !!this.lastSetAudio,
        videoSend: !!this.lastSetVideo,
      },
    })

    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        update: true,
      },
      jsep,
    })
  }

  /**
   * Changes own bitrate, ie quality of the stream
   * @param bitrate
   */
  private changeBitrate = async (bitrate: number) => {
    if (bitrate === this.lastSetBitrate) {
      return
    }
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        bitrate,
      },
    })
    this.lastSetBitrate = bitrate
  }

  /**
   * Method that unmute the session on the janus side
   */
  private janusUnmute = async () => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        audio: true,
      },
    })
  }

  unpublish = async () => {
    if (!this.isPublishing) {
      return
    }
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.unpublish,
      },
    })
    this.isPublishing = false
  }

  sendData = (data) => this.janusClient.sendData(data)

  onLocalStream = () => this.janusClient.onLocalStream()

  onDataChannelOpened = () => this.janusClient.onDataChannelOpen()

  onDataChannelMessage = () => this.janusClient.onDataChannelMessage()

  onIceState = () => this.janusClient.onIceState()

  onRoomReallocate = () => this.janusClient.onEvent(JanusEvent.ROOM_REALLOCATED)

  onJoined = () =>
    this.janusClient.onEvent(JanusEvent.JOINED).pipe(
      // eslint-disable-next-line camelcase
      map(({ publishers, attendees, private_id }) => ({
        publishers,
        participants: attendees,
        privateId: private_id,
      }))
    )

  onMute = () => this.janusClient.onEvent(JanusEvent.PARTICIPANT_MUTE)

  onParticipantKick = () => this.janusClient.onEvent(JanusEvent.PARTICIPANT_KICK)

  kickParticipant = async (partipantId) => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.kickParticipant,
        room: this.roomId,
        participant: partipantId,
      },
    })
  }

  muteParticipant = async (participantId) => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.mute,
        room: this.roomId,
        participant: participantId,
      },
    })
  }

  muteAllParticipants = async () => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.mute,
        room: this.roomId,
      },
    })
  }

  onParticipantJoining = () =>
    this.janusClient.onEvent(JanusEvent.JOINING).pipe(map(({ id, display }) => ({ id, display })))

  onParticipantLeaving = () =>
    this.janusClient
      .onEvent(JanusEvent.LEAVING)
      .pipe(map(({ leaving }: { leaving: string }) => leaving))

  onParticipantPublishing = () =>
    this.janusClient.onEvent(JanusEvent.PUBLISHERS).pipe(mergeMap(({ publishers }) => publishers))

  onParticipantUnpublishing = () =>
    this.janusClient
      .onEvent(JanusEvent.UNPUBLISHED)
      .pipe(map(({ unpublished }: { unpublished: string }) => unpublished))

  onRecordingActivated = () =>
    this.janusClient.onEvent(JanusEvent.RECORDING_STARTED).pipe(
      // eslint-disable-next-line camelcase
      map(({ recording_id, recordingStartTime, currentTime }) => ({
        recordingId: recording_id,
        recordingStartTime: new Date(recordingStartTime * 1000),
        currentTime: new Date(currentTime * 1000),
      }))
    )

  onRecordingDeactivated = () =>
    this.janusClient
      .onEvent(JanusEvent.RECORDING_STOPPED)
      // eslint-disable-next-line camelcase
      .pipe(map(({ recording_id }) => recording_id))

  createOrJoin = async () => {
    if (!(await this.checkIfRoomExists())) {
      await this.createRoom()
    }
    await this.joinRoom()
  }

  muteAudio = async () => {
    return this.janusClient.muteAudio()
  }

  unmuteAudio = async () => {
    // We should also unmute ourselves on the server side, just in case we were muted by the room manager
    await this.janusUnmute()
    return this.janusClient.unmuteAudio()
  }

  muteVideo = async () => {
    return this.janusClient.muteVideo()
  }

  unmuteVideo = async () => {
    return this.janusClient.unmuteVideo()
  }

  adjustStreamQuality = async ({ height, bitrate }: { height?: number; bitrate?: number }) => {
    const pc = await this.janusClient.getPeerConnection()
    if (!pc) {
      throw new Error('no peer connection')
    }

    const sender = pc.getSenders().find((sender) => sender.track?.kind === 'video')
    if (!sender) {
      throw new Error('no video sender')
    }

    const params = sender.getParameters()

    let ratio: number
    if (height) {
      const videoHeight = sender.track?.getSettings().height
      if (!videoHeight) {
        throw new Error('no video height')
      }
      ratio = videoHeight / height
    } else {
      ratio = 1
    }
    params.encodings[0].scaleResolutionDownBy = Math.max(ratio, 1)

    params.encodings[0].maxBitrate = bitrate || this.lastSetBitrate

    await sender.setParameters(params)
  }

  /**
   * Joins the room. Before actually joining, checks if user id was already taken.
   * It can happen when user was previously in the room but his connection was interrupted and
   * his session is still active (waiting for time out)
   * @private
   */
  private joinRoom = async () => {
    const { participants, attendees } = await this.listRoomParticipants()

    const isUserOnParticipantList = participants.some((participant) => participant.id === this.id)
    const isUserOnAttendeesList = attendees.some((attendee) => attendee.id === this.id)
    const isIdAlreadyTaken = isUserOnAttendeesList || isUserOnParticipantList
    if (isIdAlreadyTaken) {
      this.id = `${this.id}-${await this.janusClient.getHandleId()}`
    }

    await this.janusClient.sendMessage({
      message: {
        id: this.id,
        request: MessageType.joinRoom,
        room: this.roomId,
        roomHash: this.roomHash,
        ptype: 'publisher',
        display: this.displayName,
      },
    })
  }

  private createRoom = async () => {
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.createRoom,
        audiocodec: AUDIO_CODECS,
        videocodec: VIDEO_CODECS,
        publishers: MAX_PARTICIPANTS,
        room: this.roomId,
        fir_freq: FIR_FREQUENCY,
        require_pvtid: true,
        notify_joining: true,
      },
    })
  }

  private checkIfRoomExists = async () => {
    const response = await this.janusClient.sendMessage({
      message: {
        request: MessageType.queryIfRoomExists,
        room: this.roomId,
      },
    })
    return response.exists
  }

  private listRoomParticipants = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.listParticipants,
        room: this.roomId,
      },
    })

  private handleErrorEventReceived = ({ error }) => {
    console.error(error)
    // TODO: handle me
  }

  private handleICEStateChangedEventReceived = async (iceState: RTCIceTransportState) => {
    const wasConnected = ['connected', 'completed'].includes(this.lastIceState)
    const isDisconnected = wasConnected && iceState === 'disconnected'
    const isFailed = iceState === 'failed'

    if (isDisconnected || isFailed) {
      logger.log(
        `[Publisher] ICE restart will be forced. Reason: isDisconnected ${isDisconnected}, isFailed ${isFailed}`
      )
      await this.forceICERestart()
    }

    this.lastIceState = iceState
  }
}
