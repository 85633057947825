/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExportMessagesCondition = {
    duration?: DatetimeRangeInput | null;
};
export type DatetimeRangeInput = {
    start?: DatetimeRangeBoundInput | null;
    end?: DatetimeRangeBoundInput | null;
};
export type DatetimeRangeBoundInput = {
    value: unknown;
    inclusive: boolean;
};
export type ChatMessagesQueryVariables = {
    roomHash: unknown;
    condition?: ExportMessagesCondition | null;
};
export type ChatMessagesQueryResponse = {
    readonly getChatMessageByRoomHash: {
        readonly nodes: ReadonlyArray<{
            readonly profileDisplayName: string | null;
            readonly createdAt: unknown | null;
            readonly content: string | null;
            readonly sessionTime: number | null;
        } | null>;
    } | null;
};
export type ChatMessagesQuery = {
    readonly response: ChatMessagesQueryResponse;
    readonly variables: ChatMessagesQueryVariables;
};



/*
query ChatMessagesQuery(
  $roomHash: UUID!
  $condition: ExportMessagesCondition
) {
  getChatMessageByRoomHash(roomHash: $roomHash, condition: $condition, orderBy: "created_at desc") {
    nodes {
      profileDisplayName
      createdAt
      content
      sessionTime
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roomHash"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "condition",
        "variableName": "condition"
      },
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "created_at desc"
      },
      {
        "kind": "Variable",
        "name": "roomHash",
        "variableName": "roomHash"
      }
    ],
    "concreteType": "ExportMessagesConnection",
    "kind": "LinkedField",
    "name": "getChatMessageByRoomHash",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExportMessage",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileDisplayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sessionTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatMessagesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatMessagesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c1cb702d2f51f25e980e2b0381e7da09",
    "id": null,
    "metadata": {},
    "name": "ChatMessagesQuery",
    "operationKind": "query",
    "text": "query ChatMessagesQuery(\n  $roomHash: UUID!\n  $condition: ExportMessagesCondition\n) {\n  getChatMessageByRoomHash(roomHash: $roomHash, condition: $condition, orderBy: \"created_at desc\") {\n    nodes {\n      profileDisplayName\n      createdAt\n      content\n      sessionTime\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8c83e9489b762abbb6f546026e496344';
export default node;
