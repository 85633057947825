import { useCallback, useMemo, useState } from 'react'

import { ProfilePreferences } from '../../../../../../../Common/preferences/usePreferences'
import {
  BooleanConverter,
  PreferenceType
} from '../../../../../../../Common/preferences/Preferences'
import { useSavePreferencesMutation } from '../../../../../../../GraphQL/hooks/useSavePreferencesMutation'
import { ThumbnailsControl } from './useLiveroom'

export const useThumbnailsControl = (profilePreferences: ProfilePreferences): ThumbnailsControl => {
  const thumbnailDrawerOpenedPreference = profilePreferences.getProfilePreference(
    PreferenceType.THUMBNAIL_DRAWER_OPENED
  )
  const [areThumbnailsOpened, setAreThumbnailsOpened] = useState(
    thumbnailDrawerOpenedPreference.getValue<boolean>(BooleanConverter)
  )
  const [executeSavePreferencesMutations] = useSavePreferencesMutation()
  const syncPreferences = useCallback(
    (typeId, state) => {
      executeSavePreferencesMutations({
        input: {
          preferencesToSet: [
            {
              preferenceTypeId: typeId,
              preferenceValue: state.toString()
            }
          ]
        }
      })
    },
    [executeSavePreferencesMutations]
  )
  const handleThumbnailToggleClick = useCallback(
    () =>
      setAreThumbnailsOpened((areVisible) => {
        const newState = !areVisible
        syncPreferences(thumbnailDrawerOpenedPreference.preferenceTypeId, newState)
        return newState
      }),
    [syncPreferences, thumbnailDrawerOpenedPreference.preferenceTypeId]
  )
  return useMemo(() => {
    return {
      isAvailable: true,
      areVisible: areThumbnailsOpened,
      toggleVisibility: handleThumbnailToggleClick
    }
  }, [areThumbnailsOpened, handleThumbnailToggleClick])
}
