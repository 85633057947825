import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Room, RoomSession } from '../../../../../Models/apiEntities'
import { routes } from '../../routes'

const playbackFullRoute = `${routes.recordings.path}${routes.recordings.playback.path}`

export const useRecordings = ({ room }: { room: Room }) => {
  const [isNotesOpen, setIsNotesOpen] = useState(true)
  const [selectedTab, setSelectedTab] = useState('recordings')
  const [isAudioActive, setIsAudioActive] = useState(true)
  const match = useRouteMatch<{ sessionId: string }>(playbackFullRoute)
  const history = useHistory()
  const sessionId = useMemo(() => {
    if (match) {
      return match.params.sessionId
    }
    return null
  }, [match])

  const selectSession = useCallback(
    (newSession: RoomSession) => {
      history.push(
        playbackFullRoute.replace(':roomId', room.id).replace(':sessionId', newSession.id)
      )
    },
    [room, history]
  )

  const session = useMemo(() => {
    const session = room.sessions!.nodes.find(
      (session) => !session.deletedAt && session.id === sessionId
    )
    return session || null
  }, [sessionId, room])

  useEffect(() => {
    if (!session && sessionId) {
      history.push(routes.recordings.path.replace(':roomId', room.id))
    }
  }, [room, session, sessionId, history])

  const handleNotesClick = useCallback(() => {
    setIsNotesOpen((isOpen) => !isOpen)
  }, [])

  const handleAudioClick = useCallback(() => {
    setIsAudioActive((isActive) => !isActive)
  }, [])

  return {
    isNotesOpen,
    session,
    selectedTab,
    isAudioActive,
    actions: {
      selectSession,
      selectTab: setSelectedTab,
      handleNotesClick,
      handleAudioClick
    }
  }
}
