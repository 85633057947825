import { setBaselineProfileForH264, setMultiChannelSDP } from '../../SDPHelper'
import { JanusClient, JanusEvent, MessageType } from '../../plugin/JanusClient'
import { JanusSignallingConnection } from '../../signalling/JanusSignallingConnection'
import { logger } from '../../../log/Log'

export class JanusSubscriber {
  private janusClient: JanusClient
  readonly id: string
  private roomId: string
  private roomHash: string
  private privateId: string
  private lastIceState: RTCIceTransportState

  constructor({
    id,
    roomId,
    roomHash,
    privateId,
    janusSignallingConnection,
    displayName,
  }: {
    id: string
    roomId: string
    roomHash: string
    privateId: string
    janusSignallingConnection: JanusSignallingConnection
    displayName: string
  }) {
    this.id = id
    this.roomId = roomId
    this.roomHash = roomHash
    this.privateId = privateId

    // janus client setup
    this.janusClient = new JanusClient(janusSignallingConnection, `Subscriber-${displayName}`)
    this.janusClient.onJsep().subscribe(this.handleJsepAvailable)
    this.janusClient.onEvent(JanusEvent.ERROR).subscribe(this.handleErrorEventReceived)
    this.janusClient.onIceState().subscribe(this.handleICEStateChangedEventReceived)
    this.join()
  }

  onStream = () => {
    return this.janusClient.onRemoteStream()
  }

  onDataChannelOpened = () => this.janusClient.onDataChannelOpen()

  onDataChannelMessage = () => this.janusClient.onDataChannelMessage()

  onIceState = () => this.janusClient.onIceState()

  setVideoEnabled = (isVideoEnabled) => {
    return this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        video: isVideoEnabled,
        audio: true,
        data: true,
      },
    })
  }

  configureSubscriber = (options: { receiveAudio?: boolean; receiveVideo?: boolean }) => {
    const { receiveAudio = true, receiveVideo = true } = options
    return this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        video: receiveVideo,
        audio: receiveAudio,
        data: true,
      },
    })
  }

  getWebRTCStats = async () => {
    const peerConnection = await this.janusClient.getPeerConnection()
    if (!peerConnection) {
      return null
    }

    return peerConnection.getStats()
  }

  getMediaStreamStats = async () => {
    const myStream = await this.janusClient.getMediaStream()
    const connectionStats = await this.janusClient.getConnectionStats(myStream!)
    return connectionStats
  }

  cleanup = () => {
    this.janusClient.detach()
    this.janusClient.cleanup()
  }

  private join = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.joinRoom,
        room: this.roomId,
        ptype: 'subscriber',
        roomHash: this.roomHash,
        feed: this.id,
        private_id: this.privateId,
      },
    })

  /**
   * Force ICE to restart
   */
  private forceICERestart = async () => {
    if (!this.janusClient.isConnected) {
      logger.log(`[Subscriber ${this.id}] Skipping ICE restart, not connected to a server.`)
      return
    }

    logger.log(`[Subscriber ${this.id}] Forcing ICE restart`)

    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        restart: true,
      },
    })
  }

  private handleJsepAvailable = async (jsep) => {
    const answerJsep = await this.answer(jsep)
    await this.start(answerJsep)
  }

  private answer = async (jsep) => {
    // We need to set the sdp to baseline _before_ we create an answer
    jsep.sdp = setBaselineProfileForH264(jsep.sdp)

    return this.janusClient.createAnswer({
      jsep,
      media: {
        audioSend: false,
        videoSend: false,
        data: true,
      },
      customizeSdp: (jsepToCustomize) => {
        jsepToCustomize.sdp = setMultiChannelSDP(jsepToCustomize.sdp, jsep.sdp)
      },
    })
  }

  private start = async (jsep) =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.start,
        room: this.roomId,
      },
      jsep,
    })

  private handleErrorEventReceived = ({ error }) => {
    logger.error(error)
  }

  private handleICEStateChangedEventReceived = async (iceState: RTCIceTransportState) => {
    const wasConnected = ['connected', 'completed'].includes(this.lastIceState)
    const isDisconnected = wasConnected && iceState === 'disconnected'
    const isFailed = iceState === 'failed'

    if (isDisconnected || isFailed) {
      logger.log(
        `[Subscriber ${this.id}] ICE restart will be forced. Reason: isDisconnected ${isDisconnected}, isFailed ${isFailed}`
      )
      await this.forceICERestart()
    }

    this.lastIceState = iceState
  }
}
