import React from 'react'

import { PlayArrow, Pause } from '@material-ui/icons'

import ToggleIconButton from '../../../Buttons/ToggleIconButton/ToggleIconButton'
import { ToggleIconButtonProps } from '../../../Buttons/ToggleIconButton/ToggleIconButton'
import './PlayToggleButton.scss'

interface Props extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
}

const PlayToggleButton: React.FC<Props> = ({ ...otherProps }) => {
  return (
    <ToggleIconButton
      className="play-toggle-button"
      activeClassNames="play-toggle-button-active"
      inactiveClassNames="play-toggle-button-inactive"
      icon={PlayArrow}
      inactiveIcon={Pause}
      activeTooltipTitle="Play"
      inactiveTooltipTitle="Pause"
      {...otherProps}
    />
  )
}

export default React.memo(PlayToggleButton)
