import React from 'react'

import { ReactComponent as Toggle } from '../../../../Static/images/toggle.svg'

import SquareIconLabelButton from '../../../Buttons/SquareIconLabelButton/SquareIconLabelButton'
import Menu, { Option } from '../../../Menu/Menu'

import '../../../../../shared/theme/theme.scss'

interface LayoutButtonProps {
  layoutMenuOptions: Option[]
  className?: string | undefined
}

const LayoutButton: React.FC<LayoutButtonProps> = ({ layoutMenuOptions, ...otherProps }) => {
  return (
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      button={(openMenu) => (
        <>
          <SquareIconLabelButton
            icon={Toggle}
            aria-label="settings"
            onClick={openMenu}
            {...otherProps}
          >
            Layout
          </SquareIconLabelButton>
        </>
      )}
      menuOptions={layoutMenuOptions}
    />
  )
}

export default React.memo(LayoutButton)
