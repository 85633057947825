import { graphql } from 'babel-plugin-relay/macro'

const StopCurrentRecordingMutation = graphql`
  mutation StopCurrentRecordingMutation($input: StopCurrentRecordingInput!) {
    stopCurrentRecording(input: $input) {
      clientMutationId
    }
  }
`

export default StopCurrentRecordingMutation
