import { useForm } from 'react-hook-form'
import { useQueryParams } from '../../../Common/hooks/useQueryParams'
import { useFormValidation, emailAddressPattern } from '../../../Common/utils/formUtils'
import { useEnvironment } from '../../../Providers/EnvironmentProvider'
import { useCompanySsoRedirect } from '../../../Common/hooks/useCompanySsoRedirect'

declare global {
  interface Window {
    Location: string
  }
}

/**
 * Manage logic for the login, form validation, handle api errors
 * @param onLogin - callback called when login is successful
 */
export const useLoginForm = ({ performAuthentication }) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods
  const { redirect: redirectSSO } = useCompanySsoRedirect()

  const authURL = useEnvironment().authUrl

  const { redirect, ...everythingElse } = useQueryParams()

  const query = new URLSearchParams()
  for (const [key, value] of Object.entries(everythingElse)) {
    query.append(key, (value as string) || '')
  }

  const redirectQuery = redirect ? `?redirect=${redirect}?${query}` : ''

  const inputs = useFormValidation(formMethods, {
    email: {
      required: 'Email can not be empty',
      pattern: {
        value: emailAddressPattern,
        message: 'Invalid email address'
      }
    },
    password: {},
    mfaCode: {}
  })

  return {
    handleSubmit: handleSubmit((formData) => performAuthentication(formData)),
    handleAppleSubmit: () =>
      (window.location.href = authURL + '/sso/login/strategy/apple' + redirectQuery),
    handleGoogleSubmit: () =>
      (window.location.href = authURL + '/sso/login/strategy/google' + redirectQuery),
    handleMicrosoftSubmit: () =>
      (window.location.href = authURL + '/sso/login/strategy/microsoft' + redirectQuery),
    handleSsoSubmit: handleSubmit(async ({ email }) => {
      redirectSSO(email)
    }),
    inputs,
    formMethods,
    inputValues: formMethods.getValues()
  }
}
