import React from 'react'

import RoomIssueInformation from '../RoomIssueInformation/RoomIssueInformation'

const RoomDeleted: React.FC = () => {
  return (
    <RoomIssueInformation
      title={'Room was deleted'}
      description={'This room was already deleted and can not be accessed.'}
    />
  )
}

export default React.memo(RoomDeleted)
