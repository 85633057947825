import React from 'react'
import DoNotDisturbIcon from './DoNotDisturbIcon.svg'
import { Grid, Box } from '@material-ui/core'

const DisabledFeature = ({ style = {} }): JSX.Element => (
  <Grid
    className="status-component"
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={style}
  >
    <img src={DoNotDisturbIcon} alt="Disabled Icon" />
    <Box marginTop={2}>
      <h1 className="status-component-title">This feature is disabled.</h1>
    </Box>
  </Grid>
)

export default DisabledFeature
