import { useCallback, useMemo, useState } from 'react'
import { useDisplayingFormat } from '../../../../../../../../Common/hooks/useDisplayingFormat'
import { ThumbnailsControl } from '../../hooks/useLiveroom'

export const useLiveRoomDefaultLayout = ({ thumbnails }: { thumbnails: ThumbnailsControl }) => {
  const { isLargeDesktop, isDesktop, isTablet, isPhone } = useDisplayingFormat()

  const [isChatOpenDesktop, setIsChatOpenDesktop] = useState(false)
  const [hasChatNotification, setHasChatNotification] = useState(false)
  const [isParticipantListOpen, setIsParticipantListOpen] = useState(false)

  const [isDrawerOpenDesktop, setIsDrawerOpenDesktop] = useState(false)
  const [lastOpenPanelTablet, setLastOpenPanelTablet] = useState<'drawer' | 'chat' | null>(null)
  const [isThumbnailsScrollbarVisible, setIsThumbnailsScrollbarVisible] = useState(false)
  const [isToolbarShowAlways, setIsToolbarShowAlways] = useState(false)

  const isChatOpen = useMemo(
    () =>
      isDesktop
        ? isChatOpenDesktop
        : isTablet
        ? isChatOpenDesktop && lastOpenPanelTablet === 'chat'
        : false,
    [isDesktop, isTablet, isChatOpenDesktop, lastOpenPanelTablet]
  )
  const isDrawerOpen = useMemo(
    () =>
      isDesktop
        ? isDrawerOpenDesktop
        : isTablet
        ? isDrawerOpenDesktop && lastOpenPanelTablet === 'drawer'
        : false,
    [isDesktop, isTablet, isDrawerOpenDesktop, lastOpenPanelTablet]
  )

  const handleChatClick = useCallback(() => {
    if (isChatOpen) {
      setIsChatOpenDesktop(false)
      setLastOpenPanelTablet(null)
    } else {
      setIsChatOpenDesktop(true)
      setLastOpenPanelTablet('chat')
      if (isTablet) {
        setIsDrawerOpenDesktop(false)
      }
    }
  }, [isChatOpen, isTablet])

  const handleChatNotification = useCallback(
    (notify: boolean) => {
      setHasChatNotification(notify)
    },
    [setHasChatNotification]
  )

  const handleDrawerClick = useCallback(() => {
    if (isDrawerOpen) {
      setIsDrawerOpenDesktop(false)
      setLastOpenPanelTablet(null)
    } else {
      setIsDrawerOpenDesktop(true)
      setLastOpenPanelTablet('drawer')
      if (isTablet) {
        setIsChatOpenDesktop(false)
      }
    }
  }, [isDrawerOpen, isTablet])

  const handleParticipantListClick = useCallback(() => {
    if (isParticipantListOpen) {
      setIsParticipantListOpen(false)
    } else {
      setIsParticipantListOpen(true)
    }
  }, [isParticipantListOpen])

  return {
    displayFormat: { isLargeDesktop, isDesktop, isTablet, isPhone },
    actions: {
      chat: {
        handleChatClick,
        handleChatNotification,
      },
      handleDrawerClick,
      handleThumbnailClick: thumbnails.toggleVisibility,
      setIsThumbnailsScrollbarVisible,
      setIsToolbarShowAlways,
      setIsParticipantListOpen,
      setIsChatOpenDesktop,
      handleParticipantListClick,
    },
    state: {
      chat: {
        isChatOpen,
        hasChatNotification,
      },
      isDrawerOpen,
      isThumbnailOpen: thumbnails.areVisible && !isPhone,
      isThumbnailsScrollbarVisible,
      isToolbarShowAlways,
      isParticipantListOpen,
    },
  }
}
