import React from 'react'
import { Card, Box } from '@material-ui/core'
import Container from '../../../../Components/Container/Container'
import AcceptInviteErrorView from './AcceptInviteErrorView/AcceptInviteErrorView'
import AcceptInviteContainer from '../../../../Components/AcceptInvite/AcceptInviteContainer'

/**
 * Accept invite view for authenticated users. This view only provides a different layout for error page (because of the header and navigation when logged).
 * Besides that, it works exactly as v2, the accept invite only redirect a logged user to the room.
 */
const AuthenticatedAcceptInviteView: React.FC = () => {
  return (
    <Box display="flex" paddingTop={4} flexDirection="column" alignItems="center">
      <Container component={Card}>
        <AcceptInviteContainer errorComponent={(error) => <AcceptInviteErrorView error={error} />} />
      </Container>
    </Box >
  )
}

export default React.memo(AuthenticatedAcceptInviteView)
