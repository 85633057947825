import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as LogoImage } from './logo-white.svg'

interface LogoProps {
  href?: string
  className?: string
  width?: any
  forceReload?: boolean
}

const Logo: React.FC<LogoProps> = ({ href = '/', className, width, forceReload = false }) => {
  const onClick = forceReload ? () => setTimeout(() => window.location.reload) : () => {}
  return (
    <Link to={href} onClick={onClick}>
      <div className={className}>
        <LogoImage width={width} />
      </div>
    </Link>
  )
}

export default React.memo(Logo)
