import { Observable, ReplaySubject } from 'rxjs'
import { ParticipantType } from '../liveroom/LiveroomClient'
import { JanusPlaybackController } from './JanusPlaybackController'
import { JanusPlaybackParticipant } from './JanusPlaybackParticipant'

export interface PlaybackController {
  startPlayback: () => Promise<void>
  stopPlayback: () => Promise<void>
  pausePlayback: () => Promise<void>
  resumePlayback: () => Promise<void>
  seekPlayback: (offset: number) => Promise<void>
  onActiveStreams: () => Observable<string[]>
  onPlaybackStarted: () => Observable<void>
  onPlaybackEnded: () => Observable<void>
  onPlaybackPaused: () => Observable<void>
  onPlaybackResumed: () => Observable<void>
  onPlaybackSeeked: () => Observable<number>
}

export interface PlaybackParticipant {
  id: string
  displayName: string
  onRemoteStream: () => Observable<MediaStream>
  type: ParticipantType
}

export class PlaybackClient {
  private controller: JanusPlaybackController
  private participants = new Map<string, JanusPlaybackParticipant>()
  private playbackReadySubject = new ReplaySubject<{
    controller: PlaybackController
    participants: PlaybackParticipant[]
  }>()

  constructor({ janusSignallingConnection, recordingId }) {
    const addParticipant = (playbackId, { participant_id: id, display: displayName }) => {
      const playbackParticipant = new JanusPlaybackParticipant({
        janusSignallingConnection,
        playbackId,
        id,
        displayName
      })
      this.participants.set(id, playbackParticipant)
    }

    this.controller = new JanusPlaybackController({ janusSignallingConnection, recordingId })
    this.controller.onPlaybackReady().subscribe(({ participants, playbackId }) => {
      participants.forEach((participant) => addParticipant(playbackId, participant))
      this.playbackReadySubject.next({
        controller: this.controller,
        participants: Array.from(this.participants.values())
      })
    })
  }

  preparePlayback = () => this.controller.preparePlayback()

  onPlaybackError = () => this.controller.onPlaybackError()

  onPlaybackReady = () => this.playbackReadySubject.asObservable()

  leave = () => {
    this.playbackReadySubject.complete()
    this.controller.leave()
    this.participants.forEach((participant) => participant.leave())
  }
}
