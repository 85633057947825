export enum EnvName {
  test = 'test',
  local = 'local',
  alpha = 'alpha',
  beta = 'beta',
  kite = 'kite',
  qa = 'qa',
  dev = 'dev',
  stage = 'stage',
  prod = 'prod',
  lite = 'lite'
}

export interface SSOConfig {
  loginRedirect: string
  logoutRedirect: string
}

export interface Environment {
  authUrl?: string
  envName: EnvName
  frontendUrl: string
  apiUrl: string
  restApiUrl: string
  adminPortalUrl: string
  moderationApiUrl: string
  esIngestorCSDUrl: string
  webSocketUrl: string
  moderationWebSocketUrl: string
  captchaSiteKey?: string
  devToolsSecurity?: boolean
  sso: SSOConfig
}

export type EnvironmentSupplier = () => Environment
