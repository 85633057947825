import { useEffect, useState } from 'react'
import { useEffectWithPredicate } from './useEffectWithPredicate'
import useApi from '../../API/useApi'
import { useRoom } from '../../Providers/RoomProvider'
import { EBSConfig } from '../../Models/apiEntities'


export const useEBSConfig = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [ebsConfig, setEBSConfig] = useState<EBSConfig | null>(null)

    const room = useRoom()
    const { Account, Room } = useApi()

    const getEBSConfig = async () => {
        try {
            setIsLoading(true)
            const result = await Room.getEBSConfig(room.id)
            setIsLoading(false)
            return result;
        } catch (e) {
            console.error(e.message)
            return null
        }
    }

    const createOrRefresh = async () => {
        try {
            setIsLoading(true)
            await Account.createOrRefreshStreamKey()
            setIsLoading(false)
            setEBSConfig(null);
        } catch (e) {
            console.error(e.message)
        }
    }

    useEffect(() => {
        setEBSConfig(null)
    }, [room.id]
    )

    useEffectWithPredicate(
        {
            predicate: () => ebsConfig === null,
            effect: () => {
                getEBSConfig().then((newEBSConfig) => {
                    setEBSConfig(newEBSConfig)
                })
            }
        },
        [ebsConfig]
    )

    return { ebsConfig, createOrRefresh, isLoading }
}
