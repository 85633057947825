/* eslint-disable */

var Tool,
  ToolWithStroke,
  tools,
  extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key]
    }
    function ctor() {
      this.constructor = child
    }
    ctor.prototype = parent.prototype
    child.prototype = new ctor()
    child.__super__ = parent.prototype
    return child
  },
  hasProp = {}.hasOwnProperty

tools = {}

tools.Tool = Tool = (function () {
  function Tool() {}

  Tool.prototype.name = null

  Tool.prototype.iconName = null

  Tool.prototype.usesSimpleAPI = true

  Tool.prototype.begin = function (x, y, lc) {}

  Tool.prototype['continue'] = function (x, y, lc) {}

  Tool.prototype.end = function (x, y, lc) {}

  Tool.prototype.optionsStyle = null

  Tool.prototype.didBecomeActive = function (lc) {}

  Tool.prototype.willBecomeInactive = function (lc) {}

  return Tool
})()

tools.ToolWithStroke = ToolWithStroke = (function (superClass) {
  extend(ToolWithStroke, superClass)

  function ToolWithStroke(lc) {
    this.strokeWidth = lc.opts.defaultStrokeWidth
  }

  ToolWithStroke.prototype.optionsStyle = 'stroke-width'

  ToolWithStroke.prototype.didBecomeActive = function (lc) {
    var unsubscribeFuncs
    unsubscribeFuncs = []
    this.unsubscribe = (function (_this) {
      return function () {
        var func, i, len, results
        results = []
        for (i = 0, len = unsubscribeFuncs.length; i < len; i++) {
          func = unsubscribeFuncs[i]
          results.push(func())
        }
        return results
      }
    })(this)
    return unsubscribeFuncs.push(
      lc.on(
        'setStrokeWidth',
        (function (_this) {
          return function (strokeWidth) {
            _this.strokeWidth = strokeWidth
            return lc.trigger('toolDidUpdateOptions')
          }
        })(this)
      )
    )
  }

  ToolWithStroke.prototype.willBecomeInactive = function (lc) {
    return this.unsubscribe()
  }

  return ToolWithStroke
})(Tool)

module.exports = tools
