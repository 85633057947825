import React, { createContext, useContext, useState, useEffect } from 'react'
import { Box, Grow } from '@material-ui/core'
import { MainViewState } from '../hooks/useMainView'
import Logo from '../../../Components/Logo/Logo'
import { Animations } from '../../../Common/constants/animations'

interface MainLayoutProps {
  children: React.ReactNode
  mainViewState: MainViewState
}

const headerContext = createContext<[boolean, (isHeaderOpen: boolean) => void]>([true, () => {}])

/**
 * Main App layout to be used over main view
 * @constructor
 */
const MainLayout: React.FC<MainLayoutProps> = ({ children, mainViewState }) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(true)
  return (
    <Box width="100%" height="100%" position="relative" overflow="hidden">
      {mainViewState === MainViewState.authenticated && isHeaderOpen && (
        <Box position="absolute" zIndex={9999} display="flex" padding={3}>
          <Grow in={isHeaderOpen} timeout={Animations.regular}>
            <Box>
              <Logo width={152} />
            </Box>
          </Grow>
        </Box>
      )}
      <headerContext.Provider value={[isHeaderOpen, setIsHeaderOpen]}>
        {children}
      </headerContext.Provider>
    </Box>
  )
}

export const useIsHeaderOpen = () => {
  const [isHeaderOpen] = useContext(headerContext)
  return isHeaderOpen
}

export const WithoutHeader: React.FC = ({ children }) => {
  const [, setIsHeaderOpen] = useContext(headerContext)
  useEffect(() => {
    setIsHeaderOpen(false)
    return () => setIsHeaderOpen(true)
  }, [setIsHeaderOpen])
  return <>{children}</>
}

export default React.memo(MainLayout)
