import { useMemo, useEffect, useState, useCallback } from 'react'
import { logger } from '../log/Log'

export const useSoundPlayer = (src: string, speaker: MediaDeviceInfo | null) => {
  const [isPlayingSound, setIsPlayingSound] = useState(false)
  const player = useMemo(() => new Audio(), [])

  useEffect(() => {
    const listener = () => setIsPlayingSound(false)
    player.addEventListener('ended', listener)
    return () => {
      player.pause()
      player.removeEventListener('ended', listener)
    }
  }, [player])

  const playSound = useCallback(async () => {
    if (isPlayingSound) {
      return
    }
    try {
      setIsPlayingSound(true)
      player.src = src
      if (speaker && player.setSinkId) {
        await player.setSinkId(speaker.deviceId)
      }
      await player.play()
    } catch (e) {
      logger.error(e)
      setIsPlayingSound(false)
    }
  }, [player, src, speaker, isPlayingSound, setIsPlayingSound])

  return [isPlayingSound, playSound] as [boolean, () => Promise<void>]
}
