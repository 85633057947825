import React from 'react'
import { Popover, Box } from '@material-ui/core'

import MenuItemWithSubMenu from '../MenuItemWithSubMenu/MenuItemWithSubMenu'
import ColorPicker from '../ColorPicker/ColorPicker'
import CircleIcon from '../assets/CircleIcon'
import './ColorPickerPopover.scss'
import { usePopover } from '../Menu/hooks/usePopover'

interface RGB {
  r: number
  g: number
  b: number
  a: number
}

interface ColorPickerMenuItemPopover {
  label: string
  onChangeComplete: (color) => void
  color: RGB
}

const rgbaToString = (value: RGB) => `rgba(${value.r},${value.g},${value.b},${value.a})`

/**
 * ColorPickerMenuItemPopover - wrapper for MenuItemWithSubMenu that triggers a popOver when clicked
 * @param onChangeComplete - action that will be triggered once after the user finishes selecting color
 * @param color - color that will be used to fill the circle and also as initial value for color picker
 * @param label - label that will be used for menu item
 * @constructor
 */
const ColorPickerMenuItemPopover: React.FC<ColorPickerMenuItemPopover> = ({
  label,
  color,
  onChangeComplete
}) => {
  const { isOpen, anchorEl, handleClick, handleClose } = usePopover()

  return (
    <>
      <MenuItemWithSubMenu
        icon={<CircleIcon color={rgbaToString(color)} />}
        label={label}
        onClick={handleClick}
        selected={isOpen}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          root: 'color-picker-popover'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box marginLeft={0.5}>
          <ColorPicker onChangeComplete={onChangeComplete} initialColor={rgbaToString(color)} />
        </Box>
      </Popover>
    </>
  )
}

export default React.memo(ColorPickerMenuItemPopover)
