import { useCallback, useMemo } from 'react'
import { Cursor, Note } from '../../../Models/apiEntities'
import { last } from 'ramda'

interface useChatOrderedMessageProps {
  cursor?: Cursor<Note>[]
}

export const useNoteOrdered = (props: useChatOrderedMessageProps) => {
  const { cursor } = props

  const compareMessages = useCallback(({ node: a }, { node: b }) => {
    if (!a.createdAt || !b.createdAt) {
      return -1 // This ensures optimistic response stays in correct order
    }
    const timeA = new Date(a.createdAt).getTime()
    const timeB = new Date(b.createdAt).getTime()
    return timeA - timeB
  }, [])

  // TODO: Old implementation filters in the frontend. We have to ask backend to implement this feature.
  const orderedMessages = useMemo(() => {
    return cursor
      ?.filter(({ node }) => node?.deletedAt === null)
      .sort(compareMessages)
      .map((c) => c.node || ({} as Note))
  }, [cursor, compareMessages])

  const lastMessage = useMemo(() => {
    return orderedMessages ? last(orderedMessages) : undefined
  }, [orderedMessages])

  return {
    orderedMessages,
    lastMessage
  }
}
