import React from 'react'
import {
  Remove,
  TrendingFlat,
  CheckBoxOutlineBlank,
  RadioButtonUnchecked
} from '@material-ui/icons'

import { DRAW_TOOLS } from '../../../../../../../../Providers/DrawCanvasToolsProvider'
import DashIcon from '../assets/DashIcon'
import TextIcon from '../assets/TextIcon'
import EraserOutlinedIcon from '../assets/EraserOutlinedIcon'
import BrushOutlinedIcon from '../assets/BrushOutlinedIcon'

export const Icons = {
  [DRAW_TOOLS.ARROW]: TrendingFlat,
  [DRAW_TOOLS.DASH]: DashIcon,
  [DRAW_TOOLS.PENCIL]: BrushOutlinedIcon,
  [DRAW_TOOLS.LINE]: Remove,
  [DRAW_TOOLS.TEXT]: TextIcon,
  [DRAW_TOOLS.ERASER]: EraserOutlinedIcon,
  [DRAW_TOOLS.ELLIPSE]: RadioButtonUnchecked,
  [DRAW_TOOLS.RECTANGLE]: CheckBoxOutlineBlank
}

export const DrawToolIcon = ({ drawTool }: { drawTool: DRAW_TOOLS }): React.ReactElement => {
  const Icon = Icons[drawTool]

  return <Icon data-testid={`drawtool-icon-${drawTool.toLowerCase()}`} />
}
