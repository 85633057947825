import React from 'react'

import RoomIssueInformation from '../RoomIssueInformation/RoomIssueInformation'

const RoomNotFound: React.FC = () => {
  return (
    <RoomIssueInformation
      title={'Room not found'}
      description={'Seems like there is no such room.'}
    />
  )
}

export default React.memo(RoomNotFound)
