/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UpdateNoteByIdInput = {
    clientMutationId?: string | null;
    notePatch: NotePatch;
    id: unknown;
};
export type NotePatch = {
    id?: unknown | null;
    profileId?: unknown | null;
    roomId?: unknown | null;
    sessionId?: unknown | null;
    content?: string | null;
    createdAt?: unknown | null;
    updatedAt?: unknown | null;
    sessionTime?: number | null;
    deletedAt?: unknown | null;
};
export type UpdateNoteMutationVariables = {
    input: UpdateNoteByIdInput;
};
export type UpdateNoteMutationResponse = {
    readonly updateNoteById: {
        readonly clientMutationId: string | null;
        readonly note: {
            readonly " $fragmentRefs": FragmentRefs<"NoteItem_note">;
        } | null;
    } | null;
};
export type UpdateNoteMutation = {
    readonly response: UpdateNoteMutationResponse;
    readonly variables: UpdateNoteMutationVariables;
};



/*
mutation UpdateNoteMutation(
  $input: UpdateNoteByIdInput!
) {
  updateNoteById(input: $input) {
    clientMutationId
    note {
      ...NoteItem_note
    }
  }
}

fragment NoteItem_note on Note {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotePayload",
        "kind": "LinkedField",
        "name": "updateNoteById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NoteItem_note"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateNotePayload",
        "kind": "LinkedField",
        "name": "updateNoteById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Note",
            "kind": "LinkedField",
            "name": "note",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sessionTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileDisplayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1de01d03bf7c3c19345ca599f05e868d",
    "id": null,
    "metadata": {},
    "name": "UpdateNoteMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateNoteMutation(\n  $input: UpdateNoteByIdInput!\n) {\n  updateNoteById(input: $input) {\n    clientMutationId\n    note {\n      ...NoteItem_note\n    }\n  }\n}\n\nfragment NoteItem_note on Note {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n"
  }
};
})();
(node as any).hash = '0bc41a3b70d6c28f002f6c74e93f5f29';
export default node;
