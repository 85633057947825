import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryParams } from '../../../../../../Common/hooks/useQueryParams'
import {
  PlaybackClient,
  PlaybackController
} from '../../../../../../Common/janus/clients/playback/PlaybackClient'
import { logger } from '../../../../../../Common/log/Log'
import { FIR_FREQUENCY } from '../../../../../../Common/janus/clients/liveroom/JanusPublisher'

export enum PlaybackState {
  connecting,
  startReady,
  ended,
  paused,
  playing,
  error
}

interface usePlaybackControllerParams {
  playbackClient: PlaybackClient | null
}

const calculateNearestPossibleSeekTargetTime = (targetTime) => {
  return targetTime ? Math.round(targetTime / FIR_FREQUENCY) * FIR_FREQUENCY : 0
}

export const usePlaybackController = ({ playbackClient }: usePlaybackControllerParams) => {
  const { startTime } = useQueryParams()
  const startTimeInSecondsRef = useRef<number>(
    calculateNearestPossibleSeekTargetTime(Number(startTime))
  ) // we can only seek to a nearest time frame
  const [playbackState, setPlaybackState] = useState<PlaybackState>(PlaybackState.connecting)
  const [controller, setController] = useState<PlaybackController | null>(null)
  const [isSeeking, setIsSeeking] = useState<boolean>(false)
  const [seekingTarget, setSeekingTarget] = useState<number>(0)
  const [offset, setOffset] = useState<number>(0)
  const [pausedTime, setPausedTime] = useState<Date | null>(null)
  const [resumedTime, setResumedTime] = useState<Date | null>(null)
  const [startTimeOffsetInMilliseconds, setStartTimeOffsetInMilliseconds] = useState(
    startTimeInSecondsRef.current * 1000
  )

  useEffect(() => {
    if (playbackClient) {
      playbackClient.preparePlayback()
      playbackClient.onPlaybackReady().subscribe(({ controller }) => {
        setController(controller)
        setPlaybackState((state) => {
          if (state === PlaybackState.ended) {
            controller.startPlayback()
          }
          return PlaybackState.startReady
        })
      })
      playbackClient.onPlaybackError().subscribe(() => setPlaybackState(PlaybackState.error))
    } else {
      setController(null)
      setPlaybackState(PlaybackState.connecting)
    }
  }, [playbackClient])

  useEffect(() => {
    if (controller) {
      controller.onPlaybackStarted().subscribe(() => {
        setOffset(0)
        setResumedTime(new Date())
        setPlaybackState(PlaybackState.playing)
      })
      controller.onPlaybackResumed().subscribe(() => {
        setResumedTime(new Date())
        setPlaybackState(PlaybackState.playing)
      })
      controller.onPlaybackEnded().subscribe(() => setPlaybackState(PlaybackState.ended))
      controller.onPlaybackSeeked().subscribe((offset) => {
        setResumedTime(new Date())
        setOffset(offset * 1000)
        setIsSeeking(false)
        setSeekingTarget(0)
      })
    }
  }, [controller])

  // need to run independenly as it has a dependency on resumedTime
  useEffect(() => {
    if (controller) {
      const onPauseSuscription = controller.onPlaybackPaused().subscribe(() => {
        const newPausedTime = new Date()
        if (playbackState !== PlaybackState.ended) {
          setPlaybackState(PlaybackState.paused)
        }
        setPausedTime(newPausedTime)
        setOffset((offset) => offset + newPausedTime.getTime() - resumedTime!.getTime())
      })
      return () => onPauseSuscription.unsubscribe()
    }
  }, [controller, resumedTime, playbackState])

  const playAction = useCallback(async () => {
    if (!controller) {
      return
    }
    switch (playbackState) {
      case PlaybackState.startReady:
        await controller.startPlayback()
        if (startTimeOffsetInMilliseconds > 0) {
          await controller.seekPlayback(startTimeOffsetInMilliseconds / 1000)
        }
        break
      case PlaybackState.playing:
        await controller.pausePlayback()
        break
      case PlaybackState.paused:
        await controller.resumePlayback()
        break
      case PlaybackState.ended:
        await controller.seekPlayback(0)
        await controller.resumePlayback()
        break
    }
  }, [controller, playbackState, startTimeOffsetInMilliseconds])

  const seek = useCallback(
    async (offset) => {
      if (!controller) {
        return
      }
      setSeekingTarget(offset)
      if (playbackState === PlaybackState.startReady) {
        setStartTimeOffsetInMilliseconds(offset * 1000)
      } else if (playbackState === PlaybackState.ended) {
        setIsSeeking(true)
        await controller.seekPlayback(offset)
        await controller.resumePlayback()
      } else {
        setIsSeeking(true)
        await controller.seekPlayback(offset)
      }
    },
    [controller, playbackState]
  )

  // listens to location change, might happen when user clicks session link in chat message
  useEffect(() => {
    const newStartTimeInSeconds = calculateNearestPossibleSeekTargetTime(startTime)
    if (newStartTimeInSeconds === startTimeInSecondsRef.current) {
      logger.log(
        'Start time has not changed, not seeking',
        newStartTimeInSeconds,
        startTimeInSecondsRef.current
      )
      return
    }

    logger.log(
      'Start time has changed, seeking',
      newStartTimeInSeconds,
      startTimeInSecondsRef.current
    )

    startTimeInSecondsRef.current = newStartTimeInSeconds
    seek(newStartTimeInSeconds).then(() =>
      logger.log(`Seeking to ${newStartTimeInSeconds} completed.`)
    )
  }, [seek, startTime])

  const stop = useCallback(async () => {
    if (!controller) {
      return
    }
    setPlaybackState(PlaybackState.ended)
    setOffset(0)
    await controller.pausePlayback()
  }, [controller])

  return {
    state: {
      startTimeOffset: startTimeOffsetInMilliseconds,
      playbackState,
      isSeeking,
      seekingTarget,
      offset,
      pausedTime,
      resumedTime
    },
    actions: {
      playAction,
      seek,
      stop
    }
  }
}
