import React from 'react'

export const ErrorIcon = (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8555 3.22581C37.8132 3.22581 47.6297 12.9108 47.6297 25C47.6297 37.0253 37.8907 46.7742 25.8555 46.7742C13.8349 46.7742 4.08127 37.0401 4.08127 25C4.08127 12.9836 13.8194 3.22581 25.8555 3.22581ZM25.8555 0C12.0493 0 0.855469 11.1979 0.855469 25C0.855469 38.8102 12.0493 50 25.8555 50C39.6616 50 50.8555 38.8102 50.8555 25C50.8555 11.1979 39.6616 0 25.8555 0ZM24.6972 12.0968H27.0136C27.7014 12.0968 28.2509 12.6696 28.2223 13.3569L27.5167 30.2923C27.4896 30.9403 26.9566 31.4516 26.308 31.4516H25.4028C24.7544 31.4516 24.2212 30.9402 24.1942 30.2923L23.4885 13.3569C23.46 12.6696 24.0094 12.0968 24.6972 12.0968ZM25.8555 33.4677C24.2966 33.4677 23.0329 34.7315 23.0329 36.2903C23.0329 37.8492 24.2966 39.1129 25.8555 39.1129C27.4143 39.1129 28.678 37.8492 28.678 36.2903C28.678 34.7315 27.4143 33.4677 25.8555 33.4677Z"
      fill="#FC2C03"
    />
  </svg>
)
