import React from 'react'

import './BlurredBackground.scss'

export enum BackgroundColor {
  Light,
  Dark
}

type Props = {
  backgroundColor?: BackgroundColor
}

export const BlurredBackground: React.FC<Props> = ({ children, backgroundColor }) => {
  return (
    <div className="blurred-background">
      <div
        className="blurred-background-content"
        style={{
          backgroundColor:
            backgroundColor === BackgroundColor.Dark
              ? 'rgba(18, 18, 18, 0.6)'
              : 'rgba(255, 255, 255, 0.05)'
        }}
      >
        {children}
      </div>
    </div>
  )
}
