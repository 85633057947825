import React from 'react'
import { Button, Grid } from '@material-ui/core'

import CardTitle from '../../../Components/CardTitle/CardTitle'
import CardText from '../../../Components/CardText/CardText'

const ErrorView: React.FC = () => {
  return (
    <Grid container spacing={4} justify="center">
      <Grid item xs={12}>
        <CardTitle>Oops.</CardTitle>
      </Grid>
      <Grid item xs={12}>
        <CardText>Something went wrong.</CardText>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            // this is non recoverable error so what else can we do?
            window.location.reload()
          }}
        >
          Try again
        </Button>
      </Grid>
    </Grid>
  )
}

export default ErrorView
