/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ApproveDeviceLoginCodeMutationVariables = {
    deviceCodeId: unknown;
    status: boolean;
};
export type ApproveDeviceLoginCodeMutationResponse = {
    readonly approveDeviceLoginCode: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ApproveDeviceLoginCodeMutation = {
    readonly response: ApproveDeviceLoginCodeMutationResponse;
    readonly variables: ApproveDeviceLoginCodeMutationVariables;
};



/*
mutation ApproveDeviceLoginCodeMutation(
  $deviceCodeId: UUID!
  $status: Boolean!
) {
  approveDeviceLoginCode(input: {_deviceCodeId: $deviceCodeId, _approved: $status}) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceCodeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_approved",
            "variableName": "status"
          },
          {
            "kind": "Variable",
            "name": "_deviceCodeId",
            "variableName": "deviceCodeId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ApproveDeviceLoginCodePayload",
    "kind": "LinkedField",
    "name": "approveDeviceLoginCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApproveDeviceLoginCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApproveDeviceLoginCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ab3abfee3a2afccdeae96c2755098505",
    "id": null,
    "metadata": {},
    "name": "ApproveDeviceLoginCodeMutation",
    "operationKind": "mutation",
    "text": "mutation ApproveDeviceLoginCodeMutation(\n  $deviceCodeId: UUID!\n  $status: Boolean!\n) {\n  approveDeviceLoginCode(input: {_deviceCodeId: $deviceCodeId, _approved: $status}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4730161782ac1d85b1b356b50dc8801';
export default node;
