import { map } from 'rxjs/operators'
import { ScreensharePublisher } from './ScreensharePublisher'
import { JanusSignallingConnection } from '../../signalling/JanusSignallingConnection'
import { Subject } from 'rxjs'
import { head, isEmpty } from 'ramda'
import { streamNameSeparator } from './common'

export const screenShareIdPrefix = 'screenshare|'
export const screenShareNamePrefix = 'Screenshare'

export interface ScreenshareClientParams {
  janusSignallingConnection: JanusSignallingConnection
  roomId: string
  roomHash: string
  userId: string
  displayName: string
}

/**
 * Screenshare is treated as another liveroom user, with the difference that it doesn’t care about seeing/hearing other participants
 * That user is created with a sharescreen prefix.
 * Screenshare client needs to subscribe videoTrack onEnd event because the user can stop the screenshare using chrome interrupt bar.
 */
export class ScreenshareClient {
  private publisher: ScreensharePublisher

  private onStreamEndedSubject = new Subject()

  constructor(params: ScreenshareClientParams) {
    const { janusSignallingConnection, roomId, roomHash, userId, displayName } = params

    this.publisher = new ScreensharePublisher({
      janusSignallingConnection,
      roomId,
      roomHash,
      userId: `${screenShareIdPrefix}${userId}`,
      displayName: `${screenShareNamePrefix}${streamNameSeparator}${displayName}`
    })

    this.publisher.onLocalStream().subscribe((stream) => {
      const videoTracks = stream.getVideoTracks()
      if (isEmpty(videoTracks)) {
        return
      }

      head(videoTracks).onended = () => {
        this.onStreamEndedSubject.next()
        this.leave()
      }
    })

    this.publisher.joinRoom()
  }

  onLocalStreamEnded = () => this.onStreamEndedSubject.asObservable()

  onJoined = () => this.publisher.onJoined().pipe(map(() => this.publisher as ScreensharePublisher))
  onPublishError = () => this.publisher.onPublishError()

  leave = () => {
    this.onStreamEndedSubject.complete()
    this.publisher.leave()
  }
}
