import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'

import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import LockIcon from '@material-ui/icons/Lock'
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import { useRequestPassword } from './hooks/useRequestPassword'
import { useUpdateProfileForm } from './hooks/useUpdateProfileForm'
import { useTwoFactor } from './hooks/useTwoFactor'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { useUpdateProfile, UpdateProfileState } from './hooks/useUpdateProfile'

import './SettingsView.scss'
import { useTwoFactorIsVerified } from './hooks/useTwoFactorIsVerified'
import { useDeactivateEmailTwoFactor } from './hooks/useDeactivateTwoFactor'

export interface CurrentProfile {
  id: string
  displayName: string
}

interface SettingsViewProps {
  currentProfile: CurrentProfile
}

/**
 * Settings view
 * @param currentProfile - currently logged in user profile
 * @constructor
 */
export const SettingsView: React.FC<SettingsViewProps> = ({ currentProfile }) => {
  const { sendTwoFactorEmail } = useTwoFactor()
  const { sendTwoFactorDeactivateEmail } = useDeactivateEmailTwoFactor()
  const isVerified = useTwoFactorIsVerified()
  const { resetPassword } = useRequestPassword()
  const { updateProfileState, updateProfile } = useUpdateProfile(currentProfile)
  const { inputs, handleSubmit } = useUpdateProfileForm(updateProfile)

  return (
    <>
      <Grid container alignItems="center" justify="center" className="settings-view">
        <Grid item xs={10} sm={9} md={7} lg={5}>
          <Card>
            <CardContent className="settings-view-content">
              <div className="settings-view-content-title settings-view-content-padding">
                Account settings
              </div>
              <Divider />
              <Box p={3}>
                <Grid container direction="column" spacing={5}>
                  <Grid item>
                    <FormControl fullWidth>
                      <TextField
                        label="Display name"
                        variant="filled"
                        defaultValue={currentProfile.displayName}
                        {...inputs.displayName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid container justify="flex-start" alignItems="center" spacing={1}>
                          <Grid item>
                            <LockIcon />
                          </Grid>
                          <Grid item>
                            <Link color="secondary" onClick={resetPassword}>
                              Request password reset
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Grid container justify="flex-start" alignItems="center" spacing={1}>
                          <Grid item>
                            <EnhancedEncryptionIcon />
                          </Grid>
                          <Grid item>
                            <Link
                              color="secondary"
                              onClick={
                                isVerified?.isTwoFactorVerified
                                  ? sendTwoFactorDeactivateEmail
                                  : sendTwoFactorEmail
                              }
                            >
                              {isVerified?.isTwoFactorVerified ? 'Deactivate' : 'Activate'}{' '}
                              Multi-factor authentication
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-end">
                      <ProgressButton
                        color="secondary"
                        isLoading={updateProfileState === UpdateProfileState.submitting}
                        onClick={handleSubmit}
                      >
                        Save
                      </ProgressButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default createFragmentContainer(SettingsView, {
  currentProfile: graphql`
    fragment SettingsView_currentProfile on Profile {
      id
      displayName
    }
  `,
})
