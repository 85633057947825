import { UserProfile, FeatureFlag, AppFeatureFlag, SSODetails } from '../../../Models/apiEntities'
import { useState, useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import SessionQuery from '../../../GraphQL/queries/SessionQuery'
import { useQuery } from '../../../Common/hooks/useQuery'
import { logger } from '../../../Common/log/Log'
import { useEvercastAuthService } from '../../../Providers/EvercastAuthProvider'
import { useNavigation } from '../../../Common/hooks/useNavigation'
import useApi from '../../../API/useApi'

/**
 * Manage initial authentication and login/logout interactions
 */
export const useSession = () => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
  const [appFeatureFlags, setAppFeatureFlags] = useState<FeatureFlag<AppFeatureFlag>[]>([])
  const [ssoDetails, setSSODetails] = useState<SSODetails>({} as SSODetails)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const query = useQuery()
  const evercastAuthService = useEvercastAuthService()
  const { goToDashboard } = useNavigation()
  const { AuthAccount } = useApi()
  const { mutate: logoutMutate } = useMutation(
    async () => await AuthAccount.logout(),
    {
      onSuccess: () => {
        setUserProfile(null)
        setIsLoading(false)
        goToDashboard()
      }
    }
  )

  const login = useCallback(async () => {
    setIsLoading(true)

    try {
      const result = await query<any>(SessionQuery)
      setUserProfile(result.currentProfile)
      setAppFeatureFlags(result.getCurrentFeatureFlags?.nodes)
      setSSODetails(result.getSSODetails)
      setIsLoading(false)
      logger.setUser(result.currentProfile)
    } catch (e) {
      logger.error(e)
      setError(e)
      setIsLoading(false)
    }
  }, [query])

  const logout = useCallback(async () => {
    setIsLoading(true)

    //AuthAccount
    if (evercastAuthService) await evercastAuthService.logOutFederated()
    logoutMutate()
  }, [evercastAuthService, logoutMutate, setIsLoading])

  // load initial session
  useEffect(() => {
    login()
  }, [login])

  return {
    error,
    isLoading,
    userProfile,
    appFeatureFlags,
    ssoDetails,
    login,
    logout
  }
}