export const formVariants = {
    initial: {
        opacity: 0,
        x: 50,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.75,
        },
    },
    exit: {
        opacity: 0,
        x: 50,
        transition: {
            duration: 0.75,
        },
    },
};
  
export const animationVariants = {
    initial: {
        opacity: 0,
        x: -25,
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1,
        },
    },
    exit: {
        opacity: 0,
        x: -25,
        transition: {
            duration: .5,
        },
    },
};
  