import React, { useCallback } from 'react'
import DashboardViewQuery from '../../../../GraphQL/queries/DashboardViewQuery'
import QueryRenderer from '../../../../GraphQL/QueryRenderer'
import HomeView from './HomeView/HomeView'
import { UserFeatureFlagsProvider } from '../../../../Providers/UserFeatureFlagsProvider'

/**
 * User's dashboard
 * @constructor
 */
const DashboardView: React.FC = () => {
  return (
    <QueryRenderer
      query={DashboardViewQuery}
      variables={{}}
      renderResult={useCallback((props, refreshGraphQLQuery) => {
        return (
          <UserFeatureFlagsProvider featureFlags={props.currentProfile.featureFlags.nodes}>
            <HomeView refreshGraphQLQuery={refreshGraphQLQuery} {...props} />
          </UserFeatureFlagsProvider>
        )
      }, [])}
    />
  )
}

export default React.memo(DashboardView)
