import React from 'react'
import { FormControl, TextField, Grid, Box, Typography } from '@material-ui/core'
import { useSignUp, SignUpState } from './hooks/useSignUp'
import PasswordStrengthProgress from '../../../../Components/PasswordStrengthProgress/PasswordStrengthProgress'
import Captcha from '../../../../Components/Captcha/Captcha'
import SignUpSuccess from './SignUpSuccess/SignUpSuccess'
import ProgressButton from '../../../../Components/ProgressButton/ProgressButton'
import { useSignUpForm } from './hooks/useSignUpForm'
import { useKeyDownHandler } from '../../../../Common/hooks/useKeyDownHandler'
import { Link } from 'react-router-dom'
import { routes } from '../routes'
import './SignUpView.scss'
import TermsPrivacyView from '../TermsPrivacyView/TermsPrivacyView'
import { usePasswordToggle } from '../../../../Common/hooks/usePasswordToggle'
import SSOLockup from '../SSOLockup/SSOLockup'
import { UserProfile } from '../../../../Models/apiEntities'
import styled from 'styled-components'

export interface LoginViewProps {
  onLogin: (userProfile: UserProfile) => void
}

/**
 * Evercast sign up view
 * @constructor
 */
const SignUpView: React.FC<LoginViewProps> = (onLogin) => {
  const { registerUser, state } = useSignUp()
  const { handleSubmit, inputs, password, captcha, inputButtonRef } = useSignUpForm(registerUser)
  const { toggle, setToggle, toggleTitle, Toggler, type } = usePasswordToggle()

  const { handleOnKeydown } = useKeyDownHandler({
    action: handleSubmit,
    predicate: () => state === SignUpState.awaiting,
    deps: [state]
  })

  if (state === SignUpState.success) {
    return <SignUpSuccess />
  }

  return (
    <>
      <Grid item container spacing={3} onKeyDown={handleOnKeydown}>
        <Grid item xs={12}>
          <Box pb={1}>
            <Typography variant="h4">Create an account</Typography>
          </Box>
          <Box className="login-navigation" mb={2.5}>
            Already have an account? <Link to={routes.login.path}>Sign in</Link>
          </Box>
          <FormControl fullWidth>
            <TextField label="Email address" variant="filled" {...inputs.email} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Display name"
              autoFocus={true}
              variant="filled"
              {...inputs.display_name}
            />
          </FormControl>
          <StyledLabel ml={1.5} mt={1}>
            Enter a name that will be shown to others on Evercast.
          </StyledLabel>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Toggler onClick={() => setToggle(!toggle)}>{toggleTitle}</Toggler>
            <TextField
              type={type}
              label="Password (8+ varied characters)"
              variant="filled"
              {...inputs.password}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <PasswordStrengthProgress
              password={password.value}
              onStrengthChange={password.setStrength}
            />
          </Box>
        </Grid>
        {captcha.isEnabled && (
          <Grid item container spacing={1}>
            <Box display="flex" justifyContent="flex-start" ml={0.5}>
              <Captcha
                onValue={captcha.setValue}
                onExpiration={captcha.handleExpiration}
                error={captcha.error}
                focusElementAfter={inputButtonRef}
              />
            </Box>
          </Grid>
        )}
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <TermsPrivacyView />
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <ProgressButton
              color="primary"
              onClick={handleSubmit}
              isLoading={state === SignUpState.submitting}
              buttonRef={inputButtonRef}
              size="medium"
              className="continue"
            >
              Continue
            </ProgressButton>
          </Grid>
        </Grid>
      </Grid>
      <SSOLockup header="Or use one of these" onLogin={onLogin} redirectToLogin />
    </>
  )
}

const StyledLabel = styled(Box)`
  font-size: 12px;
  opacity: .7;
`

export default React.memo(SignUpView)
