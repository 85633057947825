import graphql from 'babel-plugin-relay/macro'

const UpdateNoteMutation = graphql`
  mutation UpdateNoteMutation($input: UpdateNoteByIdInput!) {
    updateNoteById(input: $input) {
      clientMutationId
      note {
        ...NoteItem_note
      }
    }
  }
`

export default UpdateNoteMutation
