/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveRoom_room = {
    readonly id: unknown;
    readonly hash: unknown;
    readonly displayName: string;
    readonly isPublic: boolean;
    readonly creatorId: unknown | null;
    readonly organizationId: unknown | null;
    readonly roomLock: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"EBSKeysDialog_room" | "ChatContainer_room" | "NotesContainer_room">;
    readonly " $refType": "LiveRoom_room";
};
export type LiveRoom_room$data = LiveRoom_room;
export type LiveRoom_room$key = {
    readonly " $data"?: LiveRoom_room$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveRoom_room">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveRoom_room",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creatorId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roomLock",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EBSKeysDialog_room"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatContainer_room"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotesContainer_room"
    }
  ],
  "type": "Liveroom",
  "abstractKey": null
};
(node as any).hash = '2b4d68fbb203638a4449e4a45cf255fb';
export default node;
