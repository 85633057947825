import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { Box, IconButton, Tooltip, Popover, Typography, Button } from '@material-ui/core'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { InputProps } from '@material-ui/core/Input'
import moment from 'moment'

import DatePicker from './DatePicker/DatePicker'
import Menu from '../../../Menu/Menu'
import useDownload from './hooks/useDownload'
import { MessageType, ExportType } from './DownloaderContainer'
import DownloaderContainer from './DownloaderContainer'
import { ucwords } from '../../../../Common/utils/textUtils'

interface DownloadIconProps {
  messageType: MessageType
}

const DownloadIcon: React.FC<DownloadIconProps> = ({ messageType }) => {
  const downloadBtnEl = useRef<HTMLButtonElement | null>(null)
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(moment())
  const [inputValue, setInputValue] = useState<string>('Date Range')
  const [inputProps, setInputProps] = useState<Partial<InputProps>>({
    readOnly: true,
    style: { width: '66%' }
  })
  // @ts-ignore
  const { hash } = useParams()
  const { goToDowloadOption, setDateRange, onDownload, downloader, popover } = useDownload(
    messageType,
    hash
  )

  const handleDateChange = (date: MaterialUiPickersDate, value?: string) => { }

  const handleSelect = (dates: MaterialUiPickersDate[]) => {
    const currentDate = [...dates].pop() || moment()
    const dateRangeText =
      dates.length > 0 ? dates.map((d) => d!.format('M/D/YY')).join(' - ') : 'Date Range'

    if (!currentDate.isSame(selectedDate!, 'day')) {
      setSelectedDate(currentDate)
    }

    if (dateRangeText !== inputValue) {
      let width = '66%'
      if (!!dates.length) {
        width = dates.length === 1 ? '57%' : '100%'
      }
      setInputProps({ readOnly: true, style: { width } })
      setDateRange(dates)
      setInputValue(dateRangeText)
    }
  }

  const handleSubmit = (dateRange) => {
    if (dateRange.length > 0) {
      onDownload()
    }
  }

  const menuOptions = useMemo(
    () => [
      {
        text: 'CSV',
        onClick: () => goToDowloadOption(ExportType.CSV)
      },
      {
        text: 'Text File',
        onClick: () => goToDowloadOption(ExportType.TextFile)
      }
    ],
    [goToDowloadOption]
  )

  useEffect(() => {
    if (downloadBtnEl !== null && downloadBtnEl.current && popover.anchorEl === null) {
      popover.setAnchorEl(downloadBtnEl.current)
    }
  }, [downloadBtnEl, popover])

  return (
    <>
      <Menu
        button={(openMenu) => (
          <Tooltip title="Download and export">
            <IconButton
              ref={downloadBtnEl}
              size="small"
              color="inherit"
              data-testid="download-button"
              onClick={openMenu}
            >
              <CloudDownloadOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        menuOptions={menuOptions}
      />
      {popover.anchorEl ? (
        <Popover
          open={popover.menu.open}
          anchorEl={popover.anchorEl}
          onClose={popover.menu.close}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 30 }}
        >
          <Box p={2} style={{ width: 348 }} className="download-dialog-container">
            <Box p={0}>
              <Typography variant="body2" data-testid="download-dialog-title">
                This will download all {messageType}s currently in the room. To download for a specific date range, select a range below.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding={0}>
              <DatePicker
                value={selectedDate}
                inputValue={inputValue}
                inputProps={inputProps}
                onChange={handleDateChange}
                onSelect={handleSelect}
                onSubmit={handleSubmit}
                anchorEl={popover.anchorEl}
              />
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => {
                  popover.menu.close()
                  onDownload()
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Popover>
      ) : null}

      {popover.anchorEl ? (
        <Popover
          open={popover.error.open}
          anchorEl={popover.anchorEl}
          onClose={popover.error.close}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 30 }}
        >
          <Box p={2}>
            <Typography variant="h6">Export {ucwords(messageType)} Error</Typography>
            <Box px={0} py={2}>
              <Typography variant="body2">
                There is no {messageType} history to download.
              </Typography>
            </Box>
            <Box padding={0}>
              <Button variant="text" size="small" onClick={popover.error.close}>
                CLOSE
              </Button>
            </Box>
          </Box>
        </Popover>
      ) : null}
      <DownloaderContainer {...downloader} />
    </>
  )
}

export default React.memo(DownloadIcon)
