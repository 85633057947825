import { createMuiTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import FKGroteskRegular from '../../Static/fonts/FKGrotesk-Regular.woff2'
import FKGroteskMonoRegular from '../../Static/fonts/FKGroteskMono-Regular.woff2'
import FKDisplayRegular from '../../Static/fonts/FKDisplay-Regular.woff2'

import palette from './colors/palette'

const FKGroteskRegularFont = {
  fontFamily: 'Grotesk',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Grotesk'),
    local('Grotesk-Regular'),
    url(${FKGroteskRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
} as any

const FKGroteskMonoRegularFont = {
  fontFamily: 'GroteskMono',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('GroteskMono'),
    local('GroteskMono-Regular'),
    url(${FKGroteskMonoRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
} as any

const FKDisplayRegularFont = {
  fontFamily: 'Display',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Display'),
    local('Display-Regular'),
    url(${FKDisplayRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
} as any

const headerFontFamily = ['Display', 'Helvetica Neue', 'Roboto'].join(',')

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    status: any
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {}
}

const createTheme = (): Theme => {
  return createMuiTheme({
    palette: {
      background: {
        default: palette.background.default,
        paper: palette.background.primary
      },
      primary: {
        main: palette.root.primary
      },
      secondary: {
        main: palette.root.secondary
      },
      text: {
        primary: palette.text.primary,
        secondary: palette.text.secondary
      },
      type: 'dark'
    },
    typography: {
      fontWeightRegular: 400,
      fontSize: 14,
      fontFamily: ['Grotesk', 'Helvetica Neue', 'Roboto'].join(','),
      h1: {
        fontFamily: headerFontFamily,
        fontSize: 60
      },
      h2: {
        fontFamily: headerFontFamily
      },
      h3: {
        fontFamily: headerFontFamily
      },
      h4: {
        fontFamily: headerFontFamily,
        fontSize: 30,
        marginTop: -4,
        marginBottom: -4
      },
      h5: {
        fontFamily: headerFontFamily
      },
      h6: {
        fontFamily: headerFontFamily
      },
      caption: {
        fontSize: 16,
        letterSpacing: '0.03em',
        fontFamily: FKGroteskMonoRegularFont.fontFamily
      },
      subtitle1: {
        fontFamily: headerFontFamily,
        fontSize: 20
      },
      button: {
        fontWeight: 500,
        textTransform: 'none',
        fontSize: 12
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [FKGroteskRegularFont, FKDisplayRegularFont, FKGroteskMonoRegularFont]
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: palette.divider,
          color: palette.input.color
        }
      },
      MuiInputLabel: {
        root: {
          color: palette.text.secondary,
          '&$focused': {
            color: palette.text.secondary
          }
        }
      },
      MuiFormLabel: {
        root: {
          color: palette.text.secondary,
          '&$focused': {
            color: palette.text.secondary
          }
        }
      },
      MuiFilledInput: {
        root: {
          color: palette.text.primary,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderRadius: 0,
          opacity: 0.9,
          backgroundColor: palette.input.background
        },
        underline: {
          '&:before': {
            borderBottom: `1px solid ${palette.input.underline}`
          },
          '&:after': {
            borderBottom: `2px solid ${palette.input.underline}`
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ${palette.input.underlineHover}`
          }
        }
      },
      MuiFormHelperText: {
        root: {
          fontFamily: FKGroteskMonoRegularFont,
          fontSize: 14,
        }
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          letterSpacing: 1,
          boxShadow: 'none',
          textTransform: 'none'
        },
        sizeLarge: {
          padding: 'auto 30px',
          height: 56
        },
        label: {
          fontSize: 14
        },
        contained: {
          boxShadow: 'none'
        },
        textPrimary: {
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          }
        },
        outlinedPrimary: {
          color: palette.text.primary,
          borderColor: palette.text.primary,
          '&:hover': {
            borderColor: palette.text.primary,
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
          }
        }
      },
      MuiPopover: {
        paper: {
          maxWidth: 'initial'
        }
      },
      MuiLinearProgress: {
        root: {
          height: 6,
          borderRadius: 0
        },
        colorPrimary: {
          backgroundColor: palette.background.primary
        },
        colorSecondary: {
          backgroundColor: palette.background.secondary
        },
        bar: {
          borderRadius: 0
        }
      },
      MuiMenu: {
        paper: {
          boxShadow: `0px 0px 14px 0px rgba(0,0,0,0.4)`
        }
      },
      MuiMenuItem: {
        root: {
          padding: 30,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none'
        }
      },
      MuiToolbar: {
        root: {
          backgroundColor: palette.background.primary
        }
      },
      MuiTabs: {
        indicator: {
          height: '4px'
        }
      },
      MuiDialog: {
        paper: {
          backdropFilter: 'blur(100px)',
          backgroundColor: palette.dialog.background
        }
      },
      MuiDialogContent: {
        root: {
          padding: '24px'
        }
      },
      MuiTab: {
        root: {
          textTransform: 'capitalize',
          fontSize: 16,
          fontWeight: 400,
          padding: '24px 12px',
          borderBottom: palette.input.underline,
          '&:hover': {
            opacity: 1
          },
          '@media (min-width: 600px) and (max-width: 760px)': {
            minWidth: '100px'
          }
        },
        labelIcon: {
          fontSize: 12,
          padding: '0px 12px'
        }
      },
      MuiSlider: {
        rail: {
          backgroundColor: palette.root.primary
        }
      },
      MuiIconButton: {
        root: {
          color: palette.text.primary
        }
      }
    },
    props: {
      MuiLink: {
        color: 'textPrimary',
        underline: 'none'
      }
    }
  })
}

export default createTheme
