import { useRoom } from '../../../Providers/RoomProvider'
import { useSession } from '../../../Views/MainView/hooks/useSession'

// These options are just Jake's best attempt at keeping the date short,
// but also making sure the month/day don't get confused, the AM/PM doesn't
// get confused and the timezone is always visible
const dateOptions = {
  hourCycle: 'h12',
  timeZoneName: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

interface UseWatermarkResult {
  info?: (string | null | undefined)[]
  dateString?: string
}

export const useVisibleWatermark = () : UseWatermarkResult => {
  const { userProfile } = useSession()
  const room = useRoom()

  const date = new Date()
  const dateString = new Intl.DateTimeFormat(
    'en-US',
    dateOptions as Intl.DateTimeFormatOptions
  ).format(date)

  const email = userProfile?.email
  // once we have the email, we have the most important part of data. The rest is helping identify if there's not an email for whatever reason
  const displayName = userProfile?.displayName === email ? null : userProfile?.displayName
  const roomName = email ? null : room.displayName

  const info = [email, displayName, roomName].filter(Boolean)
  return { info, dateString }
}
