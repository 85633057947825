import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, DialogTitle, Box } from '@material-ui/core'
import './DefaultDialogHeader.scss'

export interface DefaultDialogHeaderProps {
  text: string
  divider?: boolean
  onClose?: () => void
}

const DefaultDialogHeader: React.FC<DefaultDialogHeaderProps> = ({ text, onClose }) => {
  return (
    <DialogTitle className="default-dialog-header">
      <Box className="default-dialog-header-title">
        {text}
        {onClose && (
          <Box className="closeButton">
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              data-testid="default-dialog-header-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </DialogTitle>
  )
}

const MemoizedDefaultDialogHeader = React.memo(DefaultDialogHeader)
export { MemoizedDefaultDialogHeader as DefaultDialogHeader }
