import React, { useCallback, useState } from 'react'
import ColorPickerPopover from '../../../ColorPickerPopover/ColorPickerPopover'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'

const Color: React.FC = () => {
  const {
    drawPreferences: {
      drawFillColorRed,
      drawFillColorGreen,
      drawFillColorBlue,
      drawFillColorAlpha
    },
    setDrawFillColor
  } = useDrawCanvasToolsContext()

  const [color, setColor] = useState({
    r: drawFillColorRed,
    g: drawFillColorGreen,
    b: drawFillColorBlue,
    a: drawFillColorAlpha
  })

  const setColorHandler = useCallback(
    (color) => {
      setDrawFillColor({
        red: color.r,
        green: color.g,
        blue: color.b,
        alpha: color.a
      })
      setColor(color)
    },
    [setDrawFillColor]
  )

  return <ColorPickerPopover label="Fill color" color={color} onChangeComplete={setColorHandler} />
}

export default React.memo(Color)
