import React, { memo, useEffect } from 'react'
import ControlBar from '../../../../../../Components/ControlBar/ControlBar'
import PlayToggleButton from '../../../../../../Components/ControlBar/Buttons/PlayToggleButton/PlayToggleButton'
import FullScreenToggleButton from '../../../../../../Components/ControlBar/Buttons/FullScreenToggleButton/FullScreenToggleButton'
import AudioToggleButton from '../../../../../../Components/ControlBar/Buttons/AudioToggleButton/AudioToggleButton'
import NotesToggleButton from '../../../../../../Components/ControlBar/Buttons/NotesToggleButton/NotesToggleButton'
import RoomName from '../../../../../../Components/ControlBar/RoomName/RoomName'
import SeekBar from './SeekBar/SeekBar'
import HorizontalLayout from '../../../../../../Components/HorizontalLayout/HorizontalLayout'
import { PlaybackClient } from '../../../../../../Common/janus/clients/playback/PlaybackClient'
import { PlaybackState, usePlaybackController } from '../hooks/usePlaybackController'
import { Box, Typography } from '@material-ui/core'
import { RoomSession } from '../../../../../../Models/apiEntities'
import { usePlaybackTiming } from '../hooks/usePlaybackTiming'
import { useFormatTime } from './hooks/useFormatTime'
import { useFullScreen } from '../../../../../../Common/hooks/useFullScreen'
import './PlaybackControlBar.scss'
import MouseOverSlide from '../../../../../../Components/MouseOverSlide/MouseOverSlide'
import { useDrawCanvasDisplayContext } from '../../../../../../Providers/DrawCanvasDisplayProvider'

interface PlaybackControlBarProps {
  session: RoomSession
  playbackClient: PlaybackClient | null
  isNotesOpen: boolean
  onNotesClick: () => void
  isAudioActive: boolean
  onAudioClicked: () => void
}

const PlaybackControlBar: React.FC<PlaybackControlBarProps> = ({
  session,
  playbackClient,
  isNotesOpen,
  onNotesClick,
  isAudioActive,
  onAudioClicked,
}) => {
  const {
    state: { playbackState, isSeeking, seekingTarget, startTimeOffset, offset, resumedTime },
    actions: { seek, playAction, stop },
  } = usePlaybackController({ playbackClient })
  const { duration, currentTime } = usePlaybackTiming({
    playbackState,
    session,
    startTimeOffset,
    offset,
    resumedTime,
    stop,
  })
  const { isFullScreenMode, toggleFullScreenMode } = useFullScreen()
  const { cleanUpCanvases } = useDrawCanvasDisplayContext()

  useEffect(() => {
    if (isSeeking || playbackState === PlaybackState.ended) {
      cleanUpCanvases()
    }
  }, [cleanUpCanvases, isSeeking, playbackState])

  const currentTimeFormatted = useFormatTime(currentTime)
  const lengthTime = useFormatTime(duration)

  if (playbackState === PlaybackState.connecting) {
    return null
  }

  return (
    <MouseOverSlide
      keepOpen={[PlaybackState.startReady, PlaybackState.ended].includes(playbackState)}
    >
      <Box width="100%">
        <Box marginBottom={-1.25}>
          <SeekBar
            seekingTarget={seekingTarget}
            isSeeking={isSeeking}
            currentTime={currentTime}
            onSeekChange={seek}
            length={duration}
          />
        </Box>
        <ControlBar
          leftContentPercentage={25}
          leftContentMaxWidth={380}
          leftContentMinWidth={200}
          rightContentPercentage={25}
          rightContentMaxWidth={380}
          rightContentMinWidth={200}
          leftContent={
            <HorizontalLayout alignItems="center" justifyContent="space-between">
              <RoomName isNameOnly={true} name={session.displayName} />
            </HorizontalLayout>
          }
          middleContent={
            <PlayToggleButton
              isActive={playbackState !== PlaybackState.playing}
              onToggle={playAction}
            />
          }
          rightContent={
            <HorizontalLayout
              justifyContent={'space-between'}
              alignItems="flex-start"
              maxWidth={300}
            >
              <FullScreenToggleButton
                onToggle={toggleFullScreenMode}
                isActive={isFullScreenMode}
                className="playback-control-bar-button"
              />
              <AudioToggleButton
                onToggle={onAudioClicked}
                isActive={isAudioActive}
                className="playback-control-bar-button"
              />
              <NotesToggleButton
                isActive={isNotesOpen}
                onToggle={onNotesClick}
                className="playback-control-bar-button"
                activeTooltipTitle="Show side panel"
                inactiveTooltipTitle="Hide side panel"
                label="Panel"
              />
              <Box paddingTop={1.25} minWidth={100} height={24} alignItems="center">
                <Typography variant="caption" className="playback-control-bar-seek">
                  {currentTimeFormatted} / {lengthTime}
                </Typography>
              </Box>
            </HorizontalLayout>
          }
        />
      </Box>
    </MouseOverSlide>
  )
}

export default memo(PlaybackControlBar)
