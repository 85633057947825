import { useForm } from 'react-hook-form'
import { useMemo } from 'react'
import { useFormValidation } from '../../../../../../Common/utils/formUtils'

export interface ValidateTwoFactorCodeForm {
  code: string
}

export const useValidateTwoFactorCodeForm = (
  onSubmit: (formData: ValidateTwoFactorCodeForm) => void
) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const input = useFormValidation(formMethods, {
    code: {
      required: 'Code cannot be empty'
    }
  })

  const _handleSubmit = useMemo(
    () =>
      handleSubmit(({ code }) => {
        const formData = { code }
        onSubmit(formData)
      }),
    [onSubmit, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    input
  }
}
