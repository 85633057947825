import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { logger } from '../log/Log'

const includeNonceInterceptor = (request: AxiosRequestConfig) => {
  const nonce = localStorage.getItem('nonce')
  request.headers['X-Double-Submit'] = nonce
  return request
}

const extractNonceInterceptor = (response: AxiosResponse) => {
  const nonce = response.headers['x-double-submit']
  if (nonce) {
    localStorage.setItem('nonce', nonce)
  }
  return response
}

const logoutInterceptor = (error) => {
  if (error.response && error.response.status === 403) {
    logger.warn('Session expired, logging out.')
    window.location.reload()
  }
}

/**
 * Create axios http instance with predefined middlewares
 *
 * @return  {AxiosInstance}  axios instance
 */
export const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  axiosInstance.interceptors.response.use(undefined, logoutInterceptor)
  return axiosInstance
}

/**
 * Create axios http instance for relay use with predefined middlewares
 *
 * @return  {AxiosInstance}  axios relay instance
 */
export const createAxiosRelayInstance = () => {
  const axiosInstance = axios.create({
    method: 'POST',
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  axiosInstance.interceptors.request.use(includeNonceInterceptor)
  axiosInstance.interceptors.response.use(extractNonceInterceptor, logoutInterceptor)
  return axiosInstance
}
