import React from 'react'
import { BaseDialog, DefaultDialogHeader } from '../Dialogs'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Button, Typography } from '@material-ui/core'
import ProgressButton from '../ProgressButton/ProgressButton'
import { BaseDialogFooter } from '../Dialogs/Footers/BaseDialogFooter/BaseDialogFooter'

interface DoNotDisturbDialogProps {
  isOpen: boolean
  handleClose: () => void
  onConfirm: (roomId: string, roomLock: boolean) => Promise<void>
  isLoading: boolean
  isActive: boolean
  room: {
    id: string
    displayName: string
    isPublic: boolean
    hash: string
    roomLock: boolean
  }
}

const DoNotDisturbDialog: React.FC<DoNotDisturbDialogProps> = ({
  isOpen,
  handleClose,
  onConfirm,
  isLoading,
  isActive,
  room,
}) => {

  const handleClick = () => {
    onConfirm(room.id, isActive).then(() => {
      handleClose()
    });
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="Do not Disturb" onClose={handleClose} divider />}
      footer={<BaseDialogFooter>
        <Grid container justify="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Button onClick={handleClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <ProgressButton color="secondary" isLoading={isLoading} onClick={() => handleClick()}>
              {isActive ? 'Disable Do Not Disturb' : 'Enable Do Not Disturb'}
            </ProgressButton>
          </Grid>
        </Grid>
      </BaseDialogFooter>}
    >
      <Box p={1} >
        <Grid container direction="column" justify="center" alignItems="center" wrap="nowrap" spacing={3}>
          <Grid item>
            <Typography variant="h5" align="center">Notice</Typography>
          </Grid>
          <Grid item>
            {isActive ? <Typography variant="body2" align="center" color="textPrimary">
              Turning off <strong>Do not disturb</strong> will unlock your room and allow other users to join.
            </Typography>
            : <Typography variant="body2" align="center" color="textPrimary">
                Turning on <strong>Do not disturb</strong> will lock your room from anyone else joining.
                New participants trying to enter will need to be admitted by a room owner or admin.
            </Typography>}
          </Grid>
        </Grid>
      </Box>
    </BaseDialog>
  )
}

export default React.memo(DoNotDisturbDialog)
