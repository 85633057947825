import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ConnectionStateData,
  ConnectionStateObserver,
  JanusSignallingConnection,
  KEEP_ALIVE_PERIOD,
  SignallingConnectionState
} from '../JanusSignallingConnection'
import { useIsMounted } from '../../../hooks/useIsMounted'
import { useInitOnce } from '../../../hooks/useInitOnce'
import { useEffectWithPredicate } from '../../../hooks/useEffectWithPredicate'
import { useAlerts } from '../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../Components/Alert/Alert'
import { logger } from '../../../log/Log'
import { useEventListener } from '../../../hooks/useEventListener'
import { BrowserType, getBrowserInformation } from '../../../utils/browserCheck'
import { CSDErrorCodes, useCSDContext } from '../../../../Providers/CSDProvider'

/**
 * Connects to Janus using given parameters when component is mounted.
 * Disconnects automatically when it's unmounted.
 * @param iceServers
 * @param serverUrl
 */
export const useJanusSignallingConnection = ({ iceServers, serverUrl }) => {
  const { sendEntryToCSD } = useCSDContext()
  const showAlert = useAlerts()
  const janusSignallingConfig = useMemo(
    () => ({
      serverUrl,
      iceServers: iceServers,
      keepAlivePeriod: KEEP_ALIVE_PERIOD
    }),
    [iceServers, serverUrl]
  )
  const isMountedRef = useIsMounted()
  const [connectionStateData, setConnectionStateData] = useState<ConnectionStateData>({
    oldState: SignallingConnectionState.Initial,
    currentState: SignallingConnectionState.Initial,
    error: null
  })
  const connectionEventsObserver = useCallback<ConnectionStateObserver>(
    ({ oldState, currentState, error }: ConnectionStateData) => {
      // if room is disconected abnormally //
      if (
        oldState === SignallingConnectionState.Reclaiming &&
        currentState === SignallingConnectionState.Error
      ) {
        sendEntryToCSD(CSDErrorCodes.serverConnectionLost)
      }

      if (!isMountedRef.current) {
        return
      }

      setConnectionStateData({ oldState, currentState, error })
    },
    [isMountedRef, sendEntryToCSD]
  )

  const [janusSignallingConnection, setJanusSignallingConnection] = useState<JanusSignallingConnection>(new JanusSignallingConnection(janusSignallingConfig, connectionEventsObserver));
  useEffect(() => {
    if (serverUrl && janusSignallingConnection.serverUrl !== serverUrl) {
      if (janusSignallingConnection.serverUrl) janusSignallingConnection.disconnect()
      setJanusSignallingConnection(
        new JanusSignallingConnection(janusSignallingConfig, connectionEventsObserver)
      )
    }
  }, [serverUrl]);

  useEffectWithPredicate(
    {
      predicate: () => connectionStateData.currentState === SignallingConnectionState.Reclaiming,
      effect: () => {
        showAlert(
          'Server connection issue, attempting to reconnect automatically...',
          AlertType.warning
        )
        sendEntryToCSD(CSDErrorCodes.serverConnectionLost)
      }
    },
    [connectionStateData.currentState]
  )

  useEffect(() => {
    if (!janusSignallingConnection) {
      return;
    }
    // perform initial connection
    janusSignallingConnection.connect()

    return () => {
      // disconnect when unmounting
      logger.log('Unmounting liveroom, cleaning up resources.')
      if (janusSignallingConnection.serverUrl) janusSignallingConnection.disconnect()
    }
  }, [janusSignallingConnection])

  const eventName = useMemo(() => {
    const { browserType } = getBrowserInformation()
    if (browserType === BrowserType.iPadSafari || browserType === BrowserType.iPhoneSafari) {
      return 'pagehide'
    } else return 'beforeunload'
  }, [])

  useEventListener({
    eventName,
    handler: () => {
      janusSignallingConnection?.disconnectSync()
    }
  })

  return {
    janusSignallingConnection,
    signallingConnectionState: connectionStateData.currentState
  }
}
