import { useMemo } from 'react'

export interface AudioConstraintsParams {
  microphone?: MediaDeviceInfo | null
}

export const useAudioConstraints = ({ microphone }: AudioConstraintsParams) => {
  return useMemo(() => {
    const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
    if (!microphone) {
      return null
    }
    return {
      video: false,
      audio: {
        deviceId: {
          exact: microphone.deviceId,
          groupId: microphone.groupId
        },
        ...(supportedConstraints.echoCancellation && { echoCancellation: true })
      }
    }
  }, [microphone])
}
