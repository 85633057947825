import { useEffect, useState } from 'react'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'

import { useTwoFactorVerifiedMutation } from './useTwoFactorVerifiedMutation'

const defaultErrorText = 'Error during requesting two factor verified!'

export const useTwoFactorIsVerified = () => {
  const alert = useAlerts()

  const [isVerified, setIsVerified] = useState({ isTwoFactorVerified: false })

  const [TwoFactorVerifiedMutation, { error, graphQLErrors }] = useTwoFactorVerifiedMutation()
  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  useEffect(() => {
    const isVerified = async () => {
      setIsVerified(await TwoFactorVerifiedMutation())
    }

    isVerified()
  }, [TwoFactorVerifiedMutation])

  return isVerified
}
