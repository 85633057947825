import React from 'react'
import ToggleIconButton, { ToggleIconButtonProps } from '../ToggleIconButton/ToggleIconButton'
import HoverOutline from '../../Outline/HoverOutline'
import { Box, makeStyles, withStyles } from '@material-ui/core'
import palette from '../../../Common/theme/colors/palette'

interface OutlinedToggleIconButtonProps extends ToggleIconButtonProps {
  thickness?: number
  noDevices?: boolean
  noDevicesTooltipTitle?: string
  noneSetTooltipTitle?: string
  cameraSetToNone?: boolean
  microphoneSetToNone?: boolean
  label?: string
}

/**
 * Displays toggle button
 * @param icon - custom icon component for button
 * @constructor
 */
const OutlinedToggleIconButton: React.FC<OutlinedToggleIconButtonProps> = ({
  thickness,
  cameraSetToNone,
  noneSetTooltipTitle,
  microphoneSetToNone,
  label,
  ...otherProps
}) => {
  const { color, inactiveClassNames, inactiveColor, noDevices, noDevicesTooltipTitle } = otherProps
  const defaultStyles = {
    inactive: {
      backgroundColor: palette.root.secondary,
      color: palette.text.primary,
      '&:hover': {
        backgroundColor: palette.root.secondary,
        color: palette.text.primary,
        '& svg': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
      '& svg': {
        '& path': {
          fillOpacity: 1,
        },
      },
    },
  }

  const noDevicesStyles = {
    inactive: {
      color: palette.text.primary,
      '&:hover': {
        '& svg': {
          '& path': {
            fillOpacity: 0.38,
          },
        },
      },
      '& svg': {
        '& path': {
          fillOpacity: 0.38,
        },
      },
    },
  }

  let styles: { inactive } = defaultStyles

  if (noDevices) {
    styles = noDevicesStyles
    otherProps.onToggle = () => {}
    otherProps.tooltipTitle = noDevicesTooltipTitle
    otherProps.isActive = false // mainly to set the active icon how the designers want it
  }

  if (cameraSetToNone || microphoneSetToNone) {
    styles = noDevicesStyles //shares same style as noDevices button
    otherProps.onToggle = () => {}
    otherProps.tooltipTitle = noneSetTooltipTitle
    otherProps.isActive = true //set to true to assume none icon
  }
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <Box className="inactive" clone>
      <HoverOutline thickness={thickness} color={color} disabled={!otherProps.isActive}>
        <ToggleIconButton
          className="inactive"
          {...otherProps}
          inactiveColor={inactiveColor || 'default'}
          inactiveClassNames={
            inactiveClassNames !== undefined
              ? classes[inactiveClassNames] || inactiveClassNames
              : classes.inactive
          }
          label={label}
        />
      </HoverOutline>
    </Box>
  )
}

export default React.memo(
  withStyles({
    colorPrimary: {
      color: palette.background.primary,
    },
  })(OutlinedToggleIconButton)
)
