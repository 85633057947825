import React, { createContext, useContext } from 'react'
import useApi, { restAPIContext } from '../API/useApi'

const restApiContext = createContext({} as restAPIContext)

export const RestApiProvider = ({ children }) => {
  const api = useApi()

  return <restApiContext.Provider value={api}>{children}</restApiContext.Provider>
}

export const useRestApi = () => useContext(restApiContext)
