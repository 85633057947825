import React, { useMemo } from 'react'
import { Box, Grid, IconButton } from '@material-ui/core'
import Alert, { AlertType } from '../../Alert/Alert'
import { ReactComponent as Apply } from '../../../Static/images/check_apply.svg'
import { ReactComponent as Close } from '../../../Static/images/close.svg'

import './DoNotDisturbParticipant.scss'

const DoNotDisturbParticipant = ({ participant, approve, deny, roomId }) => {
  const authenticationMethod = useMemo(() => {
    switch(participant.authenticationMethod) {
      case 'sso':
        return <Box className="access-container-info">SSO</Box>
      case 'guest':
        return <Box className="access-container-info red">Unauthenticated guest</Box>
      case 'okta':
        return <Box className="access-container-info">Authenticated by Okta</Box>
      default:
        return <></>
    }
  }, [participant.authenticationMethod])

  const handleApprove = () => {
    approve(participant.profileId, roomId)
  }

  const handleDeny = () => {
    deny(participant.profileId, roomId)
  }

  return (
    <Alert alertType={AlertType.info}>
      <Grid container>
        <Grid item xs={6}>
          <Box className="access-container-header">Access Request</Box>
          <Box className="access-container-name">{participant.name}</Box>
          <Box className="access-container-info">{participant.email}</Box>
          {authenticationMethod}
        </Grid>
        
        <Grid container item xs={6} justify="flex-end">
          <IconButton onClick={() => { handleApprove() }}>
            <Apply />
          </IconButton>
          <IconButton onClick={() => { handleDeny() }}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Alert>
  )
}

export default DoNotDisturbParticipant
