import React, { useEffect, useState } from 'react'
import { PulseLoader } from '../LiveRoomTransition/PulseLoader'
import { Box, Button, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { ArrowBack } from '@material-ui/icons'

interface NoContributorsProps {
  setIsToolbarShowAlways: (value: boolean) => void
  setIsParticipantListOpen: (value: boolean) => void
  setIsChatOpenDesktop: (value: boolean) => void
}

const NoContributors: React.FC<NoContributorsProps> = ({ setIsToolbarShowAlways, setIsParticipantListOpen, setIsChatOpenDesktop, }) => {
  const [percentage, setPercentage] = useState(0)
  useEffect(() => {
    setPercentage(100)
    const intervalId = setInterval(() => {
      setPercentage(prevValue => {
        if (prevValue >= 100) {
          return 0
        }
        return 100
      })
    }, 2000)

    return () => clearInterval(intervalId)
  }, [])

  const BackButton = ({ }) => {
    const history = useHistory()

    return (
      <Button
        onClick={() => { history.push(routes.dashboard.path) }}
        startIcon={< ArrowBack />}
        style={{ marginTop: 35, marginLeft: 35, border: '1px solid rgba(177, 177, 177, 0.5)' }}
      >
        <Typography variant="button">
          Back
        </Typography>
      </Button >
    )
  }

  useEffect(() => {
    setIsToolbarShowAlways(false)
    setIsParticipantListOpen(false)
    setIsChatOpenDesktop(false)
  }, [])

  return (
    <>
      <Box width="100%" height="100%" position="fixed" left={0} top={0} zIndex={6}>
        <BackButton />
      </Box>
      <Box marginTop="-10%">
        <PulseLoader percentage={percentage} messages={["Waiting for contributors to join"]} />
      </Box>
    </>
  )
}

export default NoContributors
