/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type NotesContainer_currentProfile = {
    readonly id: unknown;
    readonly " $refType": "NotesContainer_currentProfile";
};
export type NotesContainer_currentProfile$data = NotesContainer_currentProfile;
export type NotesContainer_currentProfile$key = {
    readonly " $data"?: NotesContainer_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"NotesContainer_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotesContainer_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '9dc24db9c87acb0d8e2549bfdb1c049f';
export default node;
