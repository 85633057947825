import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { MediaSelection } from '../hooks/useMediaSetup'
import LiveRoomDefaultLayout from './LiveRoomDefaultLayout/LiveRoomDefaultLayout'
import { useLiveroom } from './hooks/useLiveroom'
import ProgressBar from '../../../../../../Components/ProgressBar/ProgressBar'
import ScreenShareDialog from '../../ScreenShareDialog/ScreenShareDialog'
import BrowserStreamDialog from '../../BrowserStream/BrowserStreamDialog'
import { MediaDevicesInfo } from '../hooks/useMediaDevices'
import RoomAccessDialog from '../../../../../../Components/RoomAccess/RoomAccessDialog/RoomAccessDialog'
import EBSKeysDialog from '../../EBSKeys/EBSKeysDialog/EBSKeysDialog'
import RoomDialog from '../../../../../../Components/RoomDialog/RoomDialog'
import { ProfilePreferences } from '../../../../../../Common/preferences/usePreferences'
import { LocalDevicesActions, LocalDevicesState } from '../hooks/useLocalDevicesState'
import { StartRecordingDialog } from '../../StartRecordingDialog/StartRecordingDialog'
import { LiveroomState } from './hooks/useLiveroomClient'
import AppleTVLoginDialog from '../../../../../../Components/AppleTVLogin/AppleTVLoginDialog/AppleTVLoginDialog'
import AppleTVApproveDialog from '../../../../../../Components/AppleTVLogin/AppleTVApproveDialog/AppleTVApproveDialog'
import { RecordingNotificationDialog } from '../../RecordingNotificationDialog/RecordingNotificationDialog'
import { MutedDialog } from './MutedDialog/MutedDialog'
import { DrawCanvasToolsContextProvider } from '../../../../../../Providers/DrawCanvasToolsProvider'
import { RecordingProvider } from '../../../../../../Providers/RecordingProvider'
import { AudioMixer } from '../../AudioMixer/AudioMixer'
import { AudioMixerProvider } from '../../../../../../Providers/AudioMixerProvider'
import { WithoutHeader } from '../../../../MainLayout/MainLayout'
import LiveRoomTransition from './LiveRoomTransition/LiveRoomTransition'
import { useDialog } from '../../../../../../Components/RoomDialog/hooks/useDialog'
import DeviceSelectionDialog from '../../../RoomView/DeviceSelectionDialog/DeviceSelectionDialog'
import { ConnectivityProvider } from '../../../../../../Providers/ConnectivityProvider'
import DoNotDisturbDialog from '../../../../../../Components/DoNotDisturb/DoNotDisturbDialog'
import DoNotDisturbAccessRoom
  from '../../../../../../Components/DoNotDisturb/DoNotDisturbAccessRoom/DoNotDisturbAccessRoom'
import { RoomAccess } from '../../../../../../Models/apiEntities'

interface LiveRoomProps {
  localDevicesActions: LocalDevicesActions
  localDevicesState: LocalDevicesState
  currentProfile: {
    id: string
    displayName: string
    email: string | null
  }
  mediaDevices: MediaDevicesInfo
  mediaSelection: MediaSelection
  iceServers: any
  room: {
    id: string
    displayName: string
    isPublic: boolean
    hash: string
    roomLock: boolean
  }
  roomInfo: RoomAccess
  profilePreferences: ProfilePreferences
  profileApiBasedPermissions: string[]
  handleMediaSelectionChange: (newMediaSelection: MediaSelection) => Promise<void>
}

/**
 * Main liveroom component responsible for connecting to Janus, managing the signalling connection, error handling and providing data
 * to liveroom layouts
 * @param localDevicesActions
 * @param localDevicesState
 * @param mediaDevices
 * @param mediaSelection
 * @param iceServers
 * @param room
 * @param roomInfo
 * @param currentProfile
 * @param profilePreferences
 * @param profileApiBasedPermissions
 * @param handleMediaSelectionChange
 * @constructor
 */
const LiveRoom: React.FC<LiveRoomProps> = ({
  localDevicesActions,
  localDevicesState,
  iceServers,
  room,
  roomInfo,
  mediaDevices,
  mediaSelection,
  currentProfile,
  profilePreferences,
  profileApiBasedPermissions,
  handleMediaSelectionChange,
}) => {
  const [
    isDeviceSelectionDialogOpened,
    openDeviceSelectionDialog,
    closeDeviceSelectionDialog,
  ] = useDialog()
  const {
    state,
    stateDescription,
    recordingInfo,
    publisher,
    drawCanvas,
    participants,
    participantsManager,
    streamSelection,
    chatPermissionInfo,
    notePermissionInfo,
    camera,
    microphone,
    audio,
    fullScreenMode,
    deviceSelection,
    recording,
    roomAccess,
    screenShare,
    browserStream,
    ebsKeys,
    roomSettings,
    instantHelp,
    thumbnails,
    appleTVLogin,
    audioMixer,
    recordingNotification,
    userMuted,
    setCSD,
    isCSDEnabled,
    csdResults,
    kickPermissionInfo,
    mutePermissionInfo,
    dndControl,
    _publisher,
    canPublishInfo,
  } = useLiveroom({
    mediaSelection,
    iceServers,
    room,
    roomInfo,
    currentProfile,
    openDeviceSelectionDialog,
    localDevicesActions,
    localDevicesState,
    profilePreferences,
    profileApiBasedPermissions,
    mediaDevices,
  })

  const renderRoomContent = () => {
    if (state !== LiveroomState.joined) {
      return (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ProgressBar fontSize={150} />
          <Box margin={4}>
            <Typography variant={'h5'} color="textPrimary">
              {stateDescription}
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <DrawCanvasToolsContextProvider
        profilePreferences={profilePreferences}
        activeStreamId={drawCanvas.activeStreamId}
        participants={drawCanvas.participants}
        publisher={drawCanvas.publisher}
      >
        <RecordingProvider recordingInfo={recordingInfo}>
          <LiveRoomDefaultLayout
            currentProfile={currentProfile}
            room={room}
            participants={participants}
            participantsManager={participantsManager}
            publisher={publisher}
            streamSelection={streamSelection}
            camera={camera}
            microphone={microphone}
            audio={audio}
            fullScreenMode={fullScreenMode}
            deviceSelection={deviceSelection}
            roomAccess={roomAccess}
            screenShare={screenShare}
            browserStream={browserStream}
            ebsKeys={ebsKeys}
            roomSettings={roomSettings}
            recording={recording}
            instantHelp={instantHelp}
            thumbnails={thumbnails}
            chatPermissionInfo={chatPermissionInfo}
            notePermissionInfo={notePermissionInfo}
            appleTVLogin={appleTVLogin}
            audioMixer={audioMixer}
            kickPermissionInfo={kickPermissionInfo}
            mutePermissionInfo={mutePermissionInfo}
            dndControl={dndControl}
            canPublishInfo={canPublishInfo}
          />
        </RecordingProvider>
      </DrawCanvasToolsContextProvider>
    )
  }

  return (
    <WithoutHeader>
      <ConnectivityProvider publisher={_publisher} participants={participants}>
        <AudioMixerProvider participants={participants} isLiveroomAudioEnabled={audio.isEnabled}>
          <LiveRoomTransition
            liveroomState={state}
            localDevicesState={localDevicesState}
            localDevicesActions={localDevicesActions}
          >
            <Box width="100%" height="100%" overflow="hidden" position="relative">
              {renderRoomContent()}

              <RoomDialog
                isOpen={roomSettings.dialog.isOpen}
                handleClose={roomSettings.dialog.close}
                onSuccess={roomSettings.dialog.close}
                room={room}
              />
              <RoomAccessDialog
                isOpen={roomAccess.dialog.isOpen}
                handleClose={roomAccess.dialog.close}
                room={room}
              />
              <ScreenShareDialog
                isOpen={screenShare.dialog.isOpen}
                handleClose={screenShare.dialog.close}
                handleStartScreenSharing={screenShare.startScreenShare}
              />
              <BrowserStreamDialog
                isOpen={browserStream.dialog.isOpen}
                handleClose={browserStream.dialog.close}
                handleStartBrowserStream={browserStream.startBrowserStream}
                defaultMediaSelection={mediaSelection}
                mediaDevices={mediaDevices}
              />
              <EBSKeysDialog
                isOpen={ebsKeys.dialog.isOpen}
                handleClose={ebsKeys.dialog.close}
                room={room}
              />
              <StartRecordingDialog
                isOpen={recording.dialog.isOpen}
                handleClose={recording.dialog.close}
                handleStartRecording={recording.startRecording}
              />
              <AppleTVLoginDialog
                isOpen={appleTVLogin.dialog.isOpen}
                room={room}
                handleClose={appleTVLogin.dialog.close}
                onSuccess={appleTVLogin.dialog.close}
              />
              <AppleTVApproveDialog room={room} />
              <AudioMixer isOpen={audioMixer.dialog.isOpen} handleClose={audioMixer.dialog.close} />
              <RecordingNotificationDialog
                isOpen={recordingNotification.dialog.isOpen}
                isHidden={recordingNotification.dialog.isHidden}
                handleClose={recordingNotification.dialog.close}
                toggleHide={recordingNotification.dialog.toggleHide}
              />
              <MutedDialog
                isOpen={userMuted.dialog.isOpen}
                handleClose={userMuted.dialog.close}
                microphoneIsEnabled={microphone.isEnabled}
              />
              <DoNotDisturbDialog
                isOpen={dndControl.dialog.isOpen}
                room={room}
                isActive={dndControl.isActive}
                handleClose={dndControl.dialog.close}
                onConfirm={dndControl.onConfirm}
                isLoading={dndControl.isLoading}
              />
              <DeviceSelectionDialog
                isOpen={isDeviceSelectionDialogOpened}
                mediaDevices={mediaDevices}
                currentMediaSelection={mediaSelection}
                onUpdate={handleMediaSelectionChange}
                onClose={closeDeviceSelectionDialog}
                setCSD={setCSD}
                isCSDEnabled={isCSDEnabled}
                csdResults={csdResults}
                roomId={room.id}
              />
              <DoNotDisturbAccessRoom
                approve={dndControl.approveAccess}
                deny={dndControl.denyAccess}
                participants={dndControl.dndParticipants}
                room={room} />
            </Box>
          </LiveRoomTransition>
        </AudioMixerProvider>
      </ConnectivityProvider>
    </WithoutHeader>
  )
}
export default createFragmentContainer(React.memo(LiveRoom), {
  currentProfile: graphql`
    fragment LiveRoom_currentProfile on Profile {
      ...ChatContainer_currentProfile
      ...NotesContainer_currentProfile
      id
      displayName
      email
    }
  `,
  iceServers: graphql`
    fragment LiveRoom_iceServers on IceServer @relay(plural: true) {
      urls
      username
      credential
    }
  `,
  room: graphql`
    fragment LiveRoom_room on Liveroom {
      ...EBSKeysDialog_room
      ...ChatContainer_room
      ...NotesContainer_room
      id
      hash
      displayName
      isPublic
      creatorId
      organizationId
      roomLock
    }
  `,
})
