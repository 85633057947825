/**
 * Stops the provided stream by stopping each stream track.
 * @param {MediaStream} stream
 */
export function stopStream(stream?: MediaStream): void {
  if (!stream) {
    return
  }

  const tracks = stream.getTracks()
  tracks.forEach((track) => {
    track.stop()
  })
}
