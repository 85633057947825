/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SavePreferencesInput = {
    clientMutationId?: string | null;
    preferencesToSet: Array<PreferenceInput | null>;
};
export type PreferenceInput = {
    preferenceTypeId?: unknown | null;
    preferenceTypeName?: string | null;
    preferenceTypeDefault?: string | null;
    preferenceValue?: string | null;
};
export type SavePreferencesMutationVariables = {
    input: SavePreferencesInput;
};
export type SavePreferencesMutationResponse = {
    readonly savePreferences: {
        readonly preferences: ReadonlyArray<{
            readonly preferenceTypeId: unknown | null;
            readonly preferenceTypeName: string | null;
            readonly preferenceValue: string | null;
            readonly preferenceTypeDefault: string | null;
        } | null> | null;
        readonly query: {
            readonly currentProfile: {
                readonly profilePreferences: {
                    readonly nodes: ReadonlyArray<{
                        readonly preferenceTypeDefault: string | null;
                        readonly preferenceTypeName: string | null;
                        readonly preferenceValue: string | null;
                        readonly preferenceTypeId: unknown | null;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type SavePreferencesMutation = {
    readonly response: SavePreferencesMutationResponse;
    readonly variables: SavePreferencesMutationVariables;
};



/*
mutation SavePreferencesMutation(
  $input: SavePreferencesInput!
) {
  savePreferences(input: $input) {
    preferences {
      preferenceTypeId
      preferenceTypeName
      preferenceValue
      preferenceTypeDefault
    }
    query {
      currentProfile {
        profilePreferences {
          nodes {
            preferenceTypeDefault
            preferenceTypeName
            preferenceValue
            preferenceTypeId
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferenceTypeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferenceTypeName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferenceValue",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferenceTypeDefault",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SavePreferencesPayload",
    "kind": "LinkedField",
    "name": "savePreferences",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Preference",
        "kind": "LinkedField",
        "name": "preferences",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Profile",
            "kind": "LinkedField",
            "name": "currentProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PreferencesConnection",
                "kind": "LinkedField",
                "name": "profilePreferences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Preference",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SavePreferencesMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SavePreferencesMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "47fec54fd43649e8eb26f29d4db67005",
    "id": null,
    "metadata": {},
    "name": "SavePreferencesMutation",
    "operationKind": "mutation",
    "text": "mutation SavePreferencesMutation(\n  $input: SavePreferencesInput!\n) {\n  savePreferences(input: $input) {\n    preferences {\n      preferenceTypeId\n      preferenceTypeName\n      preferenceValue\n      preferenceTypeDefault\n    }\n    query {\n      currentProfile {\n        profilePreferences {\n          nodes {\n            preferenceTypeDefault\n            preferenceTypeName\n            preferenceValue\n            preferenceTypeId\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7842f3711ed526f7fdca7620c0d6754b';
export default node;
