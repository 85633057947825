import React from 'react'
import { BaseDialog, DefaultDialogHeader } from '../../../../../Components/Dialogs'
import {
  Typography,
  Button,
  Select,
  MenuItem,
  Box,
  FormControl,
  InputLabel,
  Grid
} from '@material-ui/core'
import videoQualityOptions from '../../../../../Common/video/videoQualityOptions'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import MediaDeviceDropdown from './BrowserStreamDropDown/BrowserStreamDropDown'
import useBrowserStream, { BrowserStreamDefaultSelection } from './hooks/useBrowserStream'
import { MediaDevicesInfo } from '../Room/hooks/useMediaDevices'
import { BrowserStreamMediaConstraints } from './hooks/useBrowserStreamMediaConstraints'

interface BrowserStreamDialogProps {
  handleClose: () => void
  handleStartBrowserStream: (selection: BrowserStreamMediaConstraints) => void
  isOpen?: boolean
  defaultMediaSelection: BrowserStreamDefaultSelection
  mediaDevices: MediaDevicesInfo
}

const BrowserStreamDialog: React.FC<BrowserStreamDialogProps> = ({
  handleClose,
  handleStartBrowserStream,
  isOpen,
  defaultMediaSelection,
  mediaDevices
}) => {
  const {
    videoSource,
    audioSource,
    resolution,
    handleOnStartClick,
    isSaveEnabled
  } = useBrowserStream({
    mediaDevices,
    defaultMediaSelection,
    handleClose,
    handleStartBrowserStream
  })

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth
      header={<DefaultDialogHeader text="Browser stream" divider={true} onClose={handleClose} />}
    >
      <Box paddingX={5}>
        <Grid container justify="center" alignItems="center" wrap="nowrap" spacing={3}>
          <Grid item>
            <ErrorOutlineIcon style={{ fontSize: 50 }} color="secondary" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {`Use EBS if you want high quality video streaming.`}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box marginTop={3}>
        <MediaDeviceDropdown
          label="Video source"
          value={videoSource?.selectedId || ''}
          options={mediaDevices.camera.devices}
          updateState={videoSource.setter}
        />
      </Box>

      <Box marginTop={3}>
        <MediaDeviceDropdown
          label="Audio source"
          value={audioSource?.selectedId || ''}
          options={mediaDevices.microphone.devices}
          updateState={audioSource.setter}
        />
      </Box>

      <Box marginTop={3}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="browser-stream-resolution">Resolution</InputLabel>
          <Select
            labelId="resolution-label"
            id="resolution-select"
            value={resolution.selectedId}
            label="Resolution"
          >
            {videoQualityOptions.getAll().map((option) => (
              <MenuItem key={option.id} value={option.id} onClick={() => resolution.setter(option)}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box marginTop={4}>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          size="large"
          onClick={handleOnStartClick}
          data-testid="start-browser-stream-button"
          disabled={isSaveEnabled}
        >
          Start
        </Button>
      </Box>
    </BaseDialog>
  )
}

export default React.memo(BrowserStreamDialog)
