import { format, parseJSON, setSeconds, differenceInSeconds } from 'date-fns'
import { useMemo } from 'react'
import { RoomSession } from '../../../../../../../Models/apiEntities'
import { logger } from '../../../../../../../Common/log/Log'

const todayWithoutHour = new Date()
todayWithoutHour.setHours(0, 0, 0, 0)
const invalidStoppedAt = '00:00:00'

const getDuration = (session: RoomSession) => {
  const diffInSeconds = differenceInSeconds(
    parseJSON(session.stoppedAt),
    parseJSON(session.startedAt)
  )
  if (!session.stoppedAt) {
    logger.info(`Session ${session.id} has a invalid stoppedAt date`)
    return invalidStoppedAt
  }

  const dateWithSeconds = setSeconds(todayWithoutHour, diffInSeconds)
  return format(dateWithSeconds, 'HH:mm:ss')
}

export const useRecordingCard = (session: RoomSession) => {
  const recordingDuration = useMemo(() => getDuration(session), [session])

  return {
    recordingDuration
  }
}
