/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PlaybackChatContainerQueryVariables = {
    sessionId: unknown;
};
export type PlaybackChatContainerQueryResponse = {
    readonly session: {
        readonly " $fragmentRefs": FragmentRefs<"PlaybackChat_session">;
    } | null;
};
export type PlaybackChatContainerQuery = {
    readonly response: PlaybackChatContainerQueryResponse;
    readonly variables: PlaybackChatContainerQueryVariables;
};



/*
query PlaybackChatContainerQuery(
  $sessionId: UUID!
) {
  session: sessionById(id: $sessionId) {
    ...PlaybackChat_session
  }
}

fragment ChatMessageItem_message on Message {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}

fragment PlaybackChat_session on Session {
  id
  messages: messagesBySessionId(orderBy: [CREATED_AT_DESC]) {
    edges {
      node {
        ...ChatMessageItem_message
        id
        sessionId
        sessionTime
        content
        createdAt
        deletedAt
        profileId
        profileDisplayName
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sessionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "sessionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaybackChatContainerQuery",
    "selections": [
      {
        "alias": "session",
        "args": (v1/*: any*/),
        "concreteType": "Session",
        "kind": "LinkedField",
        "name": "sessionById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlaybackChat_session"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlaybackChatContainerQuery",
    "selections": [
      {
        "alias": "session",
        "args": (v1/*: any*/),
        "concreteType": "Session",
        "kind": "LinkedField",
        "name": "sessionById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "messages",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": [
                  "CREATED_AT_DESC"
                ]
              }
            ],
            "concreteType": "MessagesConnection",
            "kind": "LinkedField",
            "name": "messagesBySessionId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sessionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sessionTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileDisplayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "messagesBySessionId(orderBy:[\"CREATED_AT_DESC\"])"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f2b304a17fc128d3c8041c52296b581",
    "id": null,
    "metadata": {},
    "name": "PlaybackChatContainerQuery",
    "operationKind": "query",
    "text": "query PlaybackChatContainerQuery(\n  $sessionId: UUID!\n) {\n  session: sessionById(id: $sessionId) {\n    ...PlaybackChat_session\n  }\n}\n\nfragment ChatMessageItem_message on Message {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n\nfragment PlaybackChat_session on Session {\n  id\n  messages: messagesBySessionId(orderBy: [CREATED_AT_DESC]) {\n    edges {\n      node {\n        ...ChatMessageItem_message\n        id\n        sessionId\n        sessionTime\n        content\n        createdAt\n        deletedAt\n        profileId\n        profileDisplayName\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '779fa218f2324d09b1fe22dd1f1f2a80';
export default node;
