import React from 'react'
import { Grid, Box /*IconButton, Tooltip*/ } from '@material-ui/core'
import OutlinedToggleIconButton from '../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
//import { DeleteOutlined } from '@material-ui/icons'
import { WithCondition } from '../../WithCondition/WithCondition'
import { NoteDecisionInfo } from '../../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomPermissions'
import DownloadIcon from '../../Chat/ChatHeader/Download/DownloadIcon'
import { MessageType } from '../../Chat/ChatHeader/Download/DownloaderContainer'

interface ChatHeaderProps {
  //onClearChatClicked: () => void
  notePermissionInfo: NoteDecisionInfo
  doMessagesExist: boolean
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  /*onClearChatClicked,*/ notePermissionInfo,
  doMessagesExist
}) => {
  return (
    <>
      <Box className="chat-header">
        <Grid container justify="space-between" alignItems="center">
          <Grid item className="chat-header-slots">
            <OutlinedToggleIconButton icon={ChatBubbleOutlineIcon} isActive={true} />

            {/* Just samples for chats in next version */}
            {/* <OutlinedToggleIconButton disabled icon={AccountBox} />
            <OutlinedToggleIconButton disabled icon={SportsEsports} /> */}
          </Grid>

          <Grid item className="chat-header-actions">
            <Grid container spacing={1}>
              <Grid item>
                {/*<WithCondition condition={notePermissionInfo.canClear}>
                  <Tooltip title="Clear chats">
                    <IconButton size="small" color="inherit" onClick={onClearChatClicked}>
                      <DeleteOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </WithCondition>*/}
                <WithCondition condition={notePermissionInfo.canDownload && doMessagesExist}>
                  <DownloadIcon messageType={MessageType.Note} />
                </WithCondition>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(ChatHeader)
