import React, { useMemo } from 'react'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'

interface BrowserStreamDropDownProps {
  label: string
  value: string
  options?: MediaDeviceInfo[]
  updateState: (option: MediaDeviceInfo) => void
}

const MediaDeviceDropdown: React.FC<BrowserStreamDropDownProps> = (props) => {
  const { label, value, updateState, options = [] } = props
  const id = useMemo(() => `use-dropdown-${label.replace(' ', '').toLowerCase()}`, [label])
  const hasValidOptions = useMemo(() => options?.length > 0, [options])
  return (
    <FormControl variant="filled" fullWidth disabled={!hasValidOptions}>
      <InputLabel>{label}</InputLabel>
      <Select id={id} value={value}>
        {hasValidOptions &&
          options.map((device) => (
            <MenuItem
              key={`${device.deviceId}-${device.kind}`}
              value={device.deviceId}
              onClick={() => updateState(device)}
            >
              {device.label}
            </MenuItem>
          ))}
      </Select>
      {!hasValidOptions && (
        <FormHelperText data-testid={`no-device-msg`}>No Device Detected</FormHelperText>
      )}
    </FormControl>
  )
}

export default React.memo(MediaDeviceDropdown)
