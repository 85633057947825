import graphql from 'babel-plugin-relay/macro'

const UpdateMessageMutation = graphql`
  mutation UpdateMessageMutation($input: UpdateMessageByIdInput!) {
    updateMessageById(input: $input) {
      clientMutationId
      message {
        ...ChatMessageItem_message
      }
    }
  }
`

export default UpdateMessageMutation
