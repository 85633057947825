import { head } from 'ramda'

import { useEffectNonNull } from './useEffectNonNull'
import { AlertType } from '../../Components/Alert/Alert'
import { useAlerts } from '../../Providers/AlertsProvider'
import { GraphQLError } from './useMutation'

interface UseDisplayGraphQLErrorsParams {
  errorCodesToHandle: any
  graphQLErrors: ReadonlyArray<GraphQLError> | null | undefined
  defaultErrorText: string
  onError?: ((errorMessage: string | null | undefined) => void) | null | undefined
  excludedCodes?: Array<string>
}

export const useDisplayGraphQLErrorsIfPresent = ({
  errorCodesToHandle,
  graphQLErrors,
  defaultErrorText,
  onError,
  excludedCodes
}: UseDisplayGraphQLErrorsParams) => {
  const alert = useAlerts()

  /**
   * Handle potential mutation errors
   */
  useEffectNonNull(() => {
    const error = head(graphQLErrors) || {}
    if (excludedCodes?.some((excludedCode) => excludedCode === error.errcode)) {
      return
    }

    const apiError = errorCodesToHandle[error.errcode]
    const errorToDisplay = apiError || defaultErrorText

    alert(errorToDisplay, AlertType.error)

    if (onError) {
      onError(errorToDisplay)
    }
  }, [errorCodesToHandle, graphQLErrors, defaultErrorText])
}
