import { startsWith } from 'lodash'
import { appleTVIdPrefix } from '../sharing/appleTV'
import { browserStreamIdPrefix } from '../sharing/BrowserStreamClient'
import { ebsIdPrefix } from '../sharing/ebs'
import { remoteAppIdPrefix, streamKeyDummyIdPrefix } from '../sharing/streamKey'
import { screenShareIdPrefix } from '../sharing/ScreenshareClient'
import { logger } from '../../../log/Log'
import { JanusClient, JanusEvent, MessageType } from '../../plugin/JanusClient'
import { ParticipantType } from '../liveroom/LiveroomClient'

export class JanusPlaybackParticipant {
  public readonly id: string
  public readonly displayName: string
  public readonly publisherId: string

  private janusClient: JanusClient
  private readonly playbackId: string

  private tag = 'Playback Participant'

  constructor({ janusSignallingConnection, playbackId, id, displayName }) {
    this.playbackId = playbackId
    this.displayName = displayName
    this.id = id

    // janus client setup
    this.janusClient = new JanusClient(janusSignallingConnection, this.tag)
    this.janusClient.onEvent(JanusEvent.ERROR).subscribe(this.handleErrorEventReceived)
    this.janusClient.onJsep().subscribe(this.answerAndAck)
    this.subscribe()
  }

  private subscribe = async () => {
    this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackSubscribe,
        playback_id: this.playbackId,
        participant_id: this.id
      }
    })
  }

  private answerAndAck = async (jsep) => {
    const newJsep = await this.janusClient.createAnswer({
      jsep,
      media: {
        audioSend: false,
        videoSend: false,
        data: true
      }
    })
    await this.janusClient.sendMessage({
      message: {
        request: MessageType.playbackAck
      },
      jsep: newJsep
    })
  }

  private handleErrorEventReceived = ({ error }) => {
    logger.error(error)
  }

  private handleICEStateChangedEventReceived = (iceState) => {}

  onRemoteStream = () => this.janusClient.onRemoteStream()

  onDataChannelOpened = () => this.janusClient.onDataChannelOpen()

  onDataChannelMessage = () => this.janusClient.onDataChannelMessage()

  leave = async () => {
    await this.janusClient.detach({ noRequest: false })
    this.janusClient.cleanup()
  }

  get type(): ParticipantType {
    const isScreenShare = startsWith(`${screenShareIdPrefix}`, this.id)
    const isBrowserStream = startsWith(`${browserStreamIdPrefix}`, this.id)
    const isEBS = startsWith(`${ebsIdPrefix}`, this.id)
    const isAppleTV = startsWith(`${appleTVIdPrefix}`, this.id)
    const isRemoteApp = startsWith(`${remoteAppIdPrefix}`, this.id)
    const isDummy = startsWith(`${streamKeyDummyIdPrefix}`, this.id)

    if (isScreenShare) return ParticipantType.ScreenShare
    else if (isBrowserStream) return ParticipantType.BrowserStream
    else if (isEBS) return ParticipantType.Ebs
    else if (isAppleTV) return ParticipantType.AppleTV
    else if (isRemoteApp) return ParticipantType.RemoteApp
    else if (isDummy) return ParticipantType.Dummy
    else return ParticipantType.Remote
  }
}
