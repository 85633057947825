/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ListenAttacherDeviceLoginCodeUpdateSubscriptionVariables = {};
export type ListenAttacherDeviceLoginCodeUpdateSubscriptionResponse = {
    readonly listenAttacherDeviceLoginCodeUpdate: {
        readonly deviceCodeApproved: string | null;
        readonly deviceName: string | null;
        readonly event: string | null;
    } | null;
};
export type ListenAttacherDeviceLoginCodeUpdateSubscription = {
    readonly response: ListenAttacherDeviceLoginCodeUpdateSubscriptionResponse;
    readonly variables: ListenAttacherDeviceLoginCodeUpdateSubscriptionVariables;
};



/*
subscription ListenAttacherDeviceLoginCodeUpdateSubscription {
  listenAttacherDeviceLoginCodeUpdate {
    deviceCodeApproved
    deviceName
    event
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AttacherDeviceLoginCodeUpdatePayload",
    "kind": "LinkedField",
    "name": "listenAttacherDeviceLoginCodeUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceCodeApproved",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "event",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListenAttacherDeviceLoginCodeUpdateSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ListenAttacherDeviceLoginCodeUpdateSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "14609e2cc2087a4f8ed7d3da5dd83cef",
    "id": null,
    "metadata": {},
    "name": "ListenAttacherDeviceLoginCodeUpdateSubscription",
    "operationKind": "subscription",
    "text": "subscription ListenAttacherDeviceLoginCodeUpdateSubscription {\n  listenAttacherDeviceLoginCodeUpdate {\n    deviceCodeApproved\n    deviceName\n    event\n  }\n}\n"
  }
};
})();
(node as any).hash = '4de4fb0eb84758c1f84da6122ae53d5c';
export default node;
