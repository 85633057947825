import Bowser from 'bowser'

export enum BrowserType {
  desktopChrome,
  desktopSafari,
  iPadSafari,
  iPhoneSafari,
  notSupported,
}

export interface BrowserCapabilities {
  browserType: BrowserType
  isSupported: boolean
  browser: Bowser.Parser.Parser
  isSafari: boolean
}

export const getBrowserInformation = (): BrowserCapabilities => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  let browserType = BrowserType.notSupported
  let isSupported = false

  if (isSupportedDesktop(browser)) {
    isSupported = true
    if (isChrome(browser)) {
      browserType = BrowserType.desktopChrome
    }
    if (isSafari(browser)) {
      browserType = BrowserType.desktopSafari
    }
  } else if (isIPad(browser)) {
    browserType = BrowserType.iPadSafari
    isSupported = true
  } else if (isIPhone(browser)) {
    browserType = BrowserType.iPhoneSafari
    isSupported = false
  }

  return {
    browser,
    browserType,
    isSupported,
    isSafari:
      browserType === BrowserType.desktopSafari ||
      browserType === BrowserType.iPadSafari ||
      browserType === BrowserType.iPhoneSafari,
  }
}

export const isIPhone = (browser) => {
  return browser.satisfies({
    mobile: {
      safari: '>=13',
    },
  })
}

export const isIPad = (browser) => {
  return (
    browser.satisfies({
      desktop: {
        safari: '>=13', // iPadOS is detected as desktop as well
      },
      tablet: {
        safari: '>=13', // older iPads with iOS
      },
    }) && window.navigator.maxTouchPoints > 1 // Make sure we don't identify macOS as iPadOS
  )
}

export const isChrome = (browser) => {
  return browser.getBrowserName() === 'Chrome'
}

export const isSafari = (browser) => {
  return browser.getBrowserName() === 'Safari'
}

export const isSupportedDesktop = (browser) => {
  return browser.satisfies({
    desktop: {
      chrome: '>83',
      safari: '>=16',
    },
    'Chrome OS': {
      chrome: '>83',
    },
  })
}
