import React, { useCallback, useMemo } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { useEffect, useState } from 'react'
import useApi from '../../../../../API/useApi'
import {OrganizationFeatureFlag, RoomFeatureFlag} from '../../../../../Models/apiEntities'

interface Org {
  customer_id: string
  company_name: string
  allowPublicRooms: boolean
  allowRoomMFA: boolean
  allowForensicWatermarking: boolean
}

const blankOrg = {
  customer_id: '',
  company_name: '',
  allowPublicRooms: false,
  allowRoomMFA: false,
  allowForensicWatermarking: false,
}

const useRoomSetup = (roomId, isCreate) => {
  const { Account, Organization, Room } = useApi()
  const [organizations, setOrganizations] = useState<Org[]>([blankOrg])
  const [selectedOrg, _setSelectedOrg] = useState<Org>(organizations[0])

  const populateOrgs = async () => {
    try {
      const orgs = await Account.getUnusedCredits()
      const features: Org[] = orgs.map(async ({ customer_id, customer: { company_name } }) => {
        const flags = await Organization.getFeatures(customer_id)
        const allowPublicRooms = flags[OrganizationFeatureFlag.allowPublicRooms].value === 'true'
        const allowRoomMFA = flags[OrganizationFeatureFlag.allowRoomMFA].value === 'true'
        const allowForensicWatermarking = flags[OrganizationFeatureFlag.allowForensicWatermarking].value === 'true'
        return { customer_id, company_name, allowPublicRooms, allowRoomMFA, allowForensicWatermarking }
      })
      const orgsWithFeatures = await Promise.all(features)

      setOrganizations([blankOrg, ...orgsWithFeatures])
    } catch {
      setOrganizations([blankOrg])
    }
  }

  const populateOrg = async () => {
    try {
      const room = await Room.getRoom(roomId)
      const features = await Room.getFeatures(roomId)
      const allowPublicRooms = features[RoomFeatureFlag.allowPublicRooms].value === 'true'
      const allowRoomMFA = features[RoomFeatureFlag.allowRoomMFA].value === 'true'
      const allowForensicWatermarking = features[RoomFeatureFlag.allowForensicWatermarking].value === 'true'
      setOrganizations([
        {
          customer_id: room.organization_id,
          company_name: room.display_name,
          allowPublicRooms,
          allowRoomMFA,
          allowForensicWatermarking,
        },
      ])
      _setSelectedOrg({
        customer_id: room.organization_id,
        company_name: '', // doesn't matter here
        allowPublicRooms,
        allowRoomMFA,
        allowForensicWatermarking,
      })
    } catch {
      setOrganizations([])
    }
  }

  useEffect(() => {
    isCreate ? populateOrgs() : populateOrg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate])

  const orgOptions = useMemo(() => {
    return organizations.map(({ customer_id, company_name }) => (
      <MenuItem key={customer_id} value={customer_id}>
        {company_name}
      </MenuItem>
    ))
  }, [organizations])

  const PublicOptions = useCallback(
    ({ onChange, onBlur, value }) => {
      return (
        <Select
          id="room-is-public-select"
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value === 'public')}
          value={value ? 'public' : 'private'}
        >
          {selectedOrg.customer_id ? (
            <MenuItem value="private">
              {selectedOrg.allowRoomMFA ? 'Private Multi-factor authentication room' : 'Private'}
            </MenuItem>
          ) : null}
          {selectedOrg.allowPublicRooms && selectedOrg.allowRoomMFA === false && selectedOrg.allowForensicWatermarking === false ? (
            <MenuItem value="public">Open</MenuItem>
          ) : null}
        </Select>
      )
    },
    [selectedOrg.customer_id, selectedOrg.allowPublicRooms, selectedOrg.allowRoomMFA]
  )

  const setSelectedOrg = (customer_id) => {
    const org = organizations.find((org) => org.customer_id === customer_id)
    _setSelectedOrg(org || blankOrg)
  }

  return { orgOptions, PublicOptions, selectedOrg, setSelectedOrg }
}

export default useRoomSetup
