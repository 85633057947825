import React, { useCallback, useState } from 'react'
import ColorPickerPopover from '../../../ColorPickerPopover/ColorPickerPopover'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'

const Color: React.FC = () => {
  const { drawPreferences, setDrawStrokeColor } = useDrawCanvasToolsContext()

  const [color, setColor] = useState({
    r: drawPreferences.drawStrokeColorRed,
    g: drawPreferences.drawStrokeColorGreen,
    b: drawPreferences.drawStrokeColorBlue,
    a: drawPreferences.drawStrokeColorAlpha
  })

  const setColorHandler = useCallback(
    (color) => {
      setDrawStrokeColor({
        red: color.r,
        green: color.g,
        blue: color.b,
        alpha: color.a
      })
      setColor(color)
    },
    [setDrawStrokeColor]
  )

  return (
    <ColorPickerPopover label="Stroke color" color={color} onChangeComplete={setColorHandler} />
  )
}

export default React.memo(Color)
