/* eslint-disable */

var Pencil,
  ToolWithStroke,
  createShape,
  extend = function (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key]
    }
    function ctor() {
      this.constructor = child
    }
    ctor.prototype = parent.prototype
    child.prototype = new ctor()
    child.__super__ = parent.prototype
    return child
  },
  hasProp = {}.hasOwnProperty

ToolWithStroke = require('./base').ToolWithStroke

createShape = require('../core/shapes').createShape

module.exports = Pencil = (function (superClass) {
  extend(Pencil, superClass)

  function Pencil() {
    return Pencil.__super__.constructor.apply(this, arguments)
  }

  Pencil.prototype.name = 'Pencil'

  Pencil.prototype.iconName = 'pencil'

  Pencil.prototype.eventTimeThreshold = 10

  Pencil.prototype.begin = function (x, y, lc) {
    this.color = lc.getColor('primary')
    this.currentShape = this.makeShape()
    this.currentShape.addPoint(this.makePoint(x, y, lc))
    return (this.lastEventTime = Date.now())
  }

  Pencil.prototype['continue'] = function (x, y, lc) {
    var timeDiff
    timeDiff = Date.now() - this.lastEventTime
    if (timeDiff > this.eventTimeThreshold) {
      this.lastEventTime += timeDiff
      this.currentShape.addPoint(this.makePoint(x, y, lc))
      return lc.drawShapeInProgress(this.currentShape)
    }
  }

  Pencil.prototype.end = function (x, y, lc) {
    lc.saveShape(this.currentShape)
    return (this.currentShape = void 0)
  }

  Pencil.prototype.makePoint = function (x, y, lc) {
    return createShape('Point', {
      x: x,
      y: y,
      size: this.strokeWidth,
      color: this.color
    })
  }

  Pencil.prototype.makeShape = function () {
    return createShape('LinePath')
  }

  return Pencil
})(ToolWithStroke)
