import { Subject, interval, Subscription, SchedulerLike } from 'rxjs'
import { inc } from 'ramda'

export const EVENT = {
  RECORDING_TIMER_STARTED: 'RECORDING_TIMER_STARTED',
  RECORDING_TIMER_STOPPED: 'RECORDING_TIMER_STOPPED',
  UPDATE_TIMER: 'UPDATE_TIMER'
}

export const RECORDING_TIMER_INTERVAL = 1000

export interface RecordTimerEvent {
  type: string
  currentTime?: number
}

/**
 * This class is responsible for emit sequential events to update a timer used by the recording components
 * such 'record time display', 'chat form', 'notes form' (in future).
 */
export class RecordingTimer {
  private onTimerSubject = new Subject<RecordTimerEvent>()
  private currentTime = 0
  private currentSubscription: Subscription

  /**
   * Return an observable allowing the client code to subscribe and react to each timer update.
   */
  onTimerUpdate = () => this.onTimerSubject.asObservable()

  /**
   * Get exact time passed since the start of the timer in seconds.
   */
  getCurrentTime = () => this.currentTime

  /**
   * Starts a new subscription for the timer. Only one subscription can exists at the time,
   * so if one is already created/open this method will not create a new one.
   * The subscription will update the timer by 1 each second. And it will emit an EVENT.UPDATE_TIMER event.
   */
  startTimer = ({
    currentTime = 0,
    scheduler
  }: {
    currentTime?: number
    scheduler?: SchedulerLike
  }) => {
    if (!this.isNotStartedOrIsClosed()) return

    this.onTimerSubject.next({
      type: EVENT.RECORDING_TIMER_STARTED
    })

    if (currentTime) this.setCurrentTime(currentTime)

    this.currentSubscription = interval(RECORDING_TIMER_INTERVAL, scheduler).subscribe(() => {
      this.currentTime = inc(this.currentTime)
      this.onTimerSubject.next({
        type: EVENT.UPDATE_TIMER,
        currentTime: this.currentTime
      })
    })
  }

  /**
   * Stop the timer, unsubscribe and return currentTime to 0.
   */
  stopTimer = () => {
    if (this.isNotStartedOrIsClosed()) return

    this.onTimerSubject.next({
      type: EVENT.RECORDING_TIMER_STOPPED
    })
    this.currentSubscription.unsubscribe()
    this.currentTime = 0
  }

  private isNotStartedOrIsClosed = () =>
    !this.currentSubscription || this.currentSubscription.closed

  private setCurrentTime = (seconds) => {
    this.currentTime = seconds
    this.onTimerSubject.next({
      type: EVENT.UPDATE_TIMER,
      currentTime: this.currentTime
    })
  }
}
