import React, { useMemo, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { useHistory } from 'react-router-dom'
import { Grid, Box, TextField, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../../../Common/utils/formUtils'
import { routes } from '../routes'
import ProgressButton from '../../../../Components/ProgressButton/ProgressButton'
import { useEnvironment } from '../../../../Providers/EnvironmentProvider'
import { useRestApi } from '../../../../Providers/RestApiProvider'
import TermsPrivacyView from '../TermsPrivacyView/TermsPrivacyView'
import './JitSignUpView.scss'

const helperTextStyles = makeStyles({
  root: {
    fontSize: 12,
    fontFamily: 'Grotesk, sans-serif'
  }
})

declare global {
  interface Window {
    Location: string
  }
}

/**
 * JitSignup View
 * Just In Time provisining registration form
 */
const JitSignUpView: React.FC = () => {
  const helperTextClass = helperTextStyles()
  const { AuthAccount } = useRestApi()
  const { data, status } = useQuery('jitUser', AuthAccount.jitUser)
  const { authUrl } = useEnvironment()
  const history = useHistory()
  const formMethods = useForm({ shouldFocusError: true })
  const { handleSubmit: submitting, reset } = formMethods
  const inputs = useFormValidation(formMethods, {
    displayName: {
      required: 'Display Name can not be empty'
    }
  })
  const handleSubmit = useMemo(
    () =>
      submitting(async ({ displayName }) => {
        window.location.href = authUrl + '/sso/jit/' + displayName
      }),
    [submitting, authUrl]
  )

  useEffect(() => {
    if (status === 'error') {
      history.push('/')
    }

    if (data) {
      reset({ displayName: data.display_name })
    }
  }, [data, status, reset, history])

  return (
    <>
      {status === 'loading' && <h1>Loading...</h1>}
      {status === 'success' && (
        <>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Create account for:</Typography>
              <Typography variant="h4" className="email-name">{data!.email}</Typography>
              <Box mt={2} mb={1} className="login-navigation">
                Already have an account? <Link to={routes.login.path}>Sign in</Link>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Display name"
                    variant="filled"
                    id="display_name"
                    {...inputs.displayName}
                    FormHelperTextProps={{ classes: helperTextClass }}
                    helperText="Enter a name that will be shown to others on Evercast."
                    autoFocus
                  />
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={12}>
              <TermsPrivacyView />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start">
                <Box mr={3}>
                  <ProgressButton
                    isLoading={false}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      history.push('/')
                    }}
                    size="medium"
                  >
                    Cancel
                </ProgressButton>
                </Box>
                <Box>
                  <ProgressButton
                    isLoading={false}
                    onClick={handleSubmit}
                    fullWidth
                    className="continue"
                    size="medium"
                  >
                    <strong>Continue</strong>
                  </ProgressButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default React.memo(JitSignUpView)

