import React, { useState, createContext, useContext } from 'react'

interface MenuTriggerContextProps {
  triggerHide: boolean
  setTriggerHide: () => void
  resetTriggerHide: () => void
}

const MenuTriggerContext = createContext<MenuTriggerContextProps>(null!)
interface MenuTriggerProviderProps {
  children: JSX.Element
}

export const MenuTriggerProvider = ({ children }: MenuTriggerProviderProps): JSX.Element => {
  const [triggerHide, setHide] = useState(false)

  const setTriggerHide = () => setHide(true)
  const resetTriggerHide = () => setHide(false)

  const value = { triggerHide, setTriggerHide, resetTriggerHide }

  return <MenuTriggerContext.Provider value={value}>{children}</MenuTriggerContext.Provider>
}

export const useMenuTrigger = () => {
  return useContext(MenuTriggerContext)
}
