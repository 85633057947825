import { useCallback, useState } from 'react'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import { useDisplayGraphQLErrorsIfPresent } from '../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../Common/log/Log'
import {
  useApproveConnectingAppleTVMutation,
  mapToVariables
} from './useApproveConnectingAppleTVMutation'
import { useApprovingState } from './useUtil'
import { useEffectNonNull } from '../../../Common/hooks/useEffectNonNull'

export const ERROR_CODES = {
  '09162': 'Please enter a valid sign-in code.',
  '09165': 'User must be room owner or have invite permissions for approving.',
  '09166': 'Error in approving device.'
}

const defaultErrorText = 'Error in approving'

export const useApproveConnectingAppleTV = (onSuccess?: () => void) => {
  const alert = useAlerts()

  const [isApproving, approving, denying] = useApprovingState()
  const [deviceCodeId, updateDeviceCodeId] = useState('')

  const [
    executeMutation,
    { isInProgress, error, graphQLErrors }
  ] = useApproveConnectingAppleTVMutation(onSuccess)

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: ERROR_CODES,
    graphQLErrors,
    defaultErrorText
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  const approveAppleTV = useCallback(async () => {
    try {
      approving()
      await executeMutation(mapToVariables(deviceCodeId, true))
      alert('Success!', AlertType.success)
    } catch (e) {
      logger.error(e)
    }
  }, [executeMutation, alert, approving, deviceCodeId])

  const denyAppleTV = useCallback(async () => {
    try {
      denying()
      await executeMutation(mapToVariables(deviceCodeId, false))
      alert('Success!', AlertType.success)
    } catch (e) {
      logger.error(e)
    }
  }, [executeMutation, alert, denying, deviceCodeId])

  return {
    isInProgress,
    isApproving,
    updateDeviceCodeId,
    approveAppleTV,
    denyAppleTV
  }
}
