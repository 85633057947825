export const routes = {
  dashboard: {
    path: `/`,
  },
  room: {
    path: `/rooms/:hash`,
  },
  invite: {
    path: `/accept_invite/:inviteToken`,
  },
  acceptSpecialInvite: {
    path: `/accept_special_invite/:inviteToken`,
  },
  account: {
    path: `/account/:tab`,
  },
  twoFactorConfiguration: {
    path: `/verify-2fa-activation/`,
  },
  twoFactorDeactiveConfiguration: {
    path: `/verify-2fa-deactivation/`,
  },
  recordings: {
    path: `/recordings/:roomId`,
    playback: {
      path: `/session/:sessionId`,
    },
  },
  forgotPassword: {
    path: '/forgot-password',
  },
  // For Okta, use same flow as unauthenticated with forgot-password
  changePassword: {
    path: '/forgot-password',
  },
  privateRoomMFA: {
    path: '/private-room-mfa',
  },
}

export const paths = [
  routes.dashboard.path,
  routes.room.path,
  routes.account.path,
  routes.twoFactorConfiguration.path,
  routes.twoFactorDeactiveConfiguration.path,
  routes.recordings.path,
  routes.recordings.playback.path,
  routes.forgotPassword.path,
  routes.changePassword.path,
  routes.acceptSpecialInvite.path,
  routes.privateRoomMFA.path,
]
