import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M17.4286 3.57143V4.14286C17.4286 4.29441 17.3684 4.43975 17.2612 4.54692C17.154 4.65408 17.0087 4.71429 16.8571 4.71429H14.4643L11.3214 17.2857H13.4286C13.5801 17.2857 13.7255 17.3459 13.8326 17.4531C13.9398 17.5602 14 17.7056 14 17.8571V18.4286C14 18.5801 13.9398 18.7255 13.8326 18.8326C13.7255 18.9398 13.5801 19 13.4286 19H6.57143C6.41988 19 6.27453 18.9398 6.16737 18.8326C6.0602 18.7255 6 18.5801 6 18.4286V17.8571C6 17.7056 6.0602 17.5602 6.16737 17.4531C6.27453 17.3459 6.41988 17.2857 6.57143 17.2857H8.96429L12.1071 4.71429H10C9.84845 4.71429 9.7031 4.65408 9.59594 4.54692C9.48878 4.43975 9.42857 4.29441 9.42857 4.14286V3.57143C9.42857 3.41988 9.48878 3.27453 9.59594 3.16737C9.7031 3.0602 9.84845 3 10 3H16.8571C17.0087 3 17.154 3.0602 17.2612 3.16737C17.3684 3.27453 17.4286 3.41988 17.4286 3.57143Z" />
  </SvgIcon>
)

export default Icon
