import { useState, useCallback, useEffect } from 'react'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { MessageType, ExportType } from '../DownloaderContainer'
import { Message } from '../../../../../Models/apiEntities'
import { ucwords } from  '../../../../../Common/utils/textUtils'

const filterCsvCol = (col) => col.replace(/"/g, '""').replace(/\x85|\f|\n|\r/g, "\r\n")
export const csvConvert = (rows: any[]) => (
  rows.reduce((csvContent, row, i) => {
    if (i === 0) { // setting up header
      csvContent += Object.keys(row).map(filterCsvCol).join('","') + "\"\r\n"
    }
    csvContent += '"' + Object.values(row).map(filterCsvCol).join('","') + "\"\r\n"
    return csvContent
  }, "data:text/csv;charset=utf-8,\"")
)

export const textConvert = (rows: any[]) =>
  rows.reduce((textContent, row) => {
    textContent += Object.values(row).join('\r\n') + '\r\n\r\n'
    return textContent
  }, 'data:text/plain;charset=utf-8,')

/**
 * Handles functionality for notes/chats download button
 * @messageType
 * @roomHash
 * @constructor
 */
const useDownload = (messageType: MessageType, roomHash: string) => {
  const [exportType, setExportType] = useState<ExportType>(ExportType.CSV)
  const [openMenu, isOpenMenu] = useState<boolean>(false)
  const [openError, isOpenError] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<MaterialUiPickersDate[]>([])
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [fileName, setFileName] = useState<string>('')
  const [fileContents, setFileContents] = useState<string>('')
  const [fetchKey, setFetchKey] = useState<number>(0)

  //resetting download
  useEffect(() => {
    if (fileContents && exportType) {
      setStartDate(undefined)
      setEndDate(undefined)
      setFileContents('')
      setDateRange([])
    }
  }, [fileContents, exportType])

  return {
    setDateRange: useCallback(
      (range) => {
        setDateRange(range)
      },
      [setDateRange]
    ),
    downloader: {
      roomHash,
      messageType,
      exportType,
      fetchKey,
      startDate,
      endDate,
      onQueryLoad: useCallback((messages: Message[]) => {
        //doing the download
        if (messages.length > 0 && startDate !== undefined && fileContents === '') {
          //reformatting exported file contents
          const formattedData = messages.map((node) => ({
            'Display Name': node.profileDisplayName,
            'Create Date': moment(node.createdAt).format("MMM D, YYYY [at] hh:mm A"),
            [ucwords(messageType)]: node.content
            
          }))
          const contents =
            exportType === ExportType.CSV ? csvConvert(formattedData) : textConvert(formattedData)

          setFileContents(contents)
        } else if (messages.length === 0 && startDate !== undefined && fileContents === '') {
          isOpenError(true)
        }
      },
      [setFileContents, startDate, isOpenError, fileContents, exportType, messageType]),
      fileName,
      fileContents
    },
    popover: {
      anchorEl,
      setAnchorEl: (el: Element | null) => {
        setAnchorEl(el)
      },
      menu: {
        open: openMenu,
        close: () => {
          setStartDate(undefined)
          setEndDate(undefined)
          isOpenMenu(false)
        }
      },
      error: {
        open: openError,
        close: () => {
          isOpenError(false)
        }
      }
    },
    goToDowloadOption: useCallback(
      (downloadOption: ExportType) => {
        setExportType(downloadOption)
        isOpenMenu(true)
      },
      [setExportType, isOpenMenu]
    ),
    onDownload: useCallback(() => {
      let rangeStart, rangeEnd
      if (dateRange.length === 0) {
        rangeStart = moment('1999-12-31')
        rangeEnd = moment()
      } else {
        ;[rangeStart, rangeEnd] = dateRange
      }
      //preparing download
      const name = [exportType, messageType + 'Download', moment().format('MMDDYHHmmss')].join('-')
      const ext = exportType === ExportType.CSV ? '.csv' : '.txt'
      setFileName(name + ext)
      if (rangeEnd !== undefined) {
        setEndDate(rangeEnd.format('Y-MM-DD'))
      }
      setStartDate(rangeStart.format('Y-MM-DD'))
      setFetchKey((prev) => prev + 1) //force render download
    }, [dateRange, exportType, messageType, setStartDate, setEndDate, setFileName, setFetchKey])
  }
}

export default useDownload
