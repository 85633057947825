import { graphql } from 'babel-plugin-relay/macro'

const ApproveDeviceLoginCodeMutation = graphql`
  mutation ApproveDeviceLoginCodeMutation($deviceCodeId: UUID!, $status: Boolean!) {
    approveDeviceLoginCode(input: { _deviceCodeId: $deviceCodeId, _approved: $status }) {
      clientMutationId
    }
  }
`

export default ApproveDeviceLoginCodeMutation
