import React from 'react'
import { DialogActions, Divider } from '@material-ui/core'
import { BaseDialogComponentsProps } from '../../BaseDialog/BaseDialog'

const BaseDialogFooter: React.FC<BaseDialogComponentsProps> = ({ children, divider }) => {
  return (
    <>
      {divider && <Divider variant="fullWidth" />}
      <DialogActions>{children}</DialogActions>
    </>
  )
}

const MemoizedBaseDialogFooter = React.memo(BaseDialogFooter)
export { MemoizedBaseDialogFooter as BaseDialogFooter }
