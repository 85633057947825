/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DashboardViewQueryVariables = {};
export type DashboardViewQueryResponse = {
    readonly currentProfile: {
        readonly featureFlags: {
            readonly nodes: ReadonlyArray<{
                readonly key: string | null;
                readonly value: string | null;
            } | null>;
        };
        readonly " $fragmentRefs": FragmentRefs<"HomeView_currentProfile">;
    } | null;
    readonly canCreateRoom: boolean | null;
};
export type DashboardViewQuery = {
    readonly response: DashboardViewQueryResponse;
    readonly variables: DashboardViewQueryVariables;
};



/*
query DashboardViewQuery {
  currentProfile {
    ...HomeView_currentProfile
    featureFlags {
      nodes {
        key
        value
      }
    }
  }
  canCreateRoom
}

fragment HomeView_currentProfile on Profile {
  type
  id
  displayName
  availableCredits
  featureFlags {
    nodes {
      key
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ColumnProfileFeatureFlagsConnection",
  "kind": "LinkedField",
  "name": "featureFlags",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ColumnProfileFeatureFlagsRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canCreateRoom",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HomeView_currentProfile"
          }
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardViewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableCredits",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ]
  },
  "params": {
    "cacheID": "efc9cec46584f919fce7ab4e8e75faa1",
    "id": null,
    "metadata": {},
    "name": "DashboardViewQuery",
    "operationKind": "query",
    "text": "query DashboardViewQuery {\n  currentProfile {\n    ...HomeView_currentProfile\n    featureFlags {\n      nodes {\n        key\n        value\n      }\n    }\n  }\n  canCreateRoom\n}\n\nfragment HomeView_currentProfile on Profile {\n  type\n  id\n  displayName\n  availableCredits\n  featureFlags {\n    nodes {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bf4f996dcc2286df2d38dd8ec77bb882';
export default node;
