import { useLocation } from 'react-router-dom'

/**
 * Determines if should display not found page based on current location options, true if <NotFoundRedirect> was used
 */
export const useNotFound = () => {
  const { state } = useLocation<{ notFound?: boolean }>()
  const notFound = state?.notFound
  return notFound == null ? false : notFound
}
