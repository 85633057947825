import React, { memo } from 'react'
import './RoomName.scss'
import { Box, Typography } from '@material-ui/core'

interface RoomNameProps {
  name: string
  isNameOnly?: boolean
}

const RoomName: React.FC<RoomNameProps> = ({ name, isNameOnly = false }) => (
  <div className="room-name">
    {!isNameOnly && (
      <Box marginBottom={1}>
        <Typography className="room-name-title" variant="caption">
          Room Name:
        </Typography>
      </Box>
    )}

    <Typography className="room-name-name" variant="body1">
      {name}
    </Typography>
  </div>
)

export default memo(RoomName)
