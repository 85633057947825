import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import AdaptiveTitle from '../../../../../Components/AdaptiveTitle/AdaptiveTitle'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import LandingView from '../../../LandingView/LandingView'
import { routes } from '../../routes'

const RoomAccessDenied = (): JSX.Element => {
  return (
    <LandingView>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <AdaptiveTitle>Private Room</AdaptiveTitle>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Oops, you don't have access to this private room. Please contact the room owner and ask
            for an invite.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link to={routes.dashboard.path}>
            <ProgressButton isLoading={false} color="primary" fullWidth>
              Back to Home
            </ProgressButton>
          </Link>
        </Grid>
      </Grid>
    </LandingView>
  )
}

export default React.memo(RoomAccessDenied)
