import React from 'react';
import { Snackbar } from '@material-ui/core'
import DoNotDisturbParticipant from "../DoNotDisturbParticipant/DoNotDisturbParticipant";
import './DoNotDisturbAccessRoom.scss'


const DoNotDisturbAccessRoom = ({
  approve,
  deny,
  participants,
  room: { id: roomId }
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      className="access-container"
      open={true}
    >
      <>
        {participants && participants.map(participant => (
          <DoNotDisturbParticipant
            participant={participant}
            approve={approve}
            deny={deny}
            roomId={roomId}
            key={participant.profileId}
          />
        ))}
        <span />
      </>
    </Snackbar>
  )
}

export default DoNotDisturbAccessRoom