import { useState, useCallback, useEffect } from 'react'
import videoQualityOptions, {
  VideoQuality
} from '../../../../../../Common/video/videoQualityOptions'
import { MediaDevicesInfo } from '../../Room/hooks/useMediaDevices'
import useBrowserStreamMediaConstraints, {
  BrowserStreamMediaConstraints
} from './useBrowserStreamMediaConstraints'

export interface BrowserStreamDefaultSelection {
  camera: MediaDeviceInfo | null
  microphone: MediaDeviceInfo | null
}

export interface BrowserStreamSelection {
  audioSource: MediaDeviceInfo
  videoSource: MediaDeviceInfo
  resolution: VideoQuality
}

const useBrowserStream = ({
  mediaDevices,
  defaultMediaSelection,
  handleStartBrowserStream,
  handleClose
}: {
  mediaDevices: MediaDevicesInfo
  defaultMediaSelection: BrowserStreamDefaultSelection
  handleStartBrowserStream: (constraints: BrowserStreamMediaConstraints) => void
  handleClose: () => void
}) => {
  const [videoSource, setVideoSource] = useState<MediaDeviceInfo | null>(
    defaultMediaSelection ? defaultMediaSelection.camera : null
  )
  const [audioSource, setAudioSource] = useState<MediaDeviceInfo | null>(
    defaultMediaSelection ? defaultMediaSelection.microphone : null
  )
  const [videoResolution, setVideoResolution] = useState<VideoQuality>(
    videoQualityOptions.getDefault()
  )

  const getBrowserStreamConstraints = useBrowserStreamMediaConstraints()

  const handleOnStartClick = useCallback(() => {
    if (videoSource && audioSource) {
      const constraints = getBrowserStreamConstraints({
        videoSource,
        audioSource,
        resolution: videoResolution
      })
      handleStartBrowserStream(constraints)
    }
    handleClose()
  }, [
    handleStartBrowserStream,
    handleClose,
    videoResolution,
    audioSource,
    videoSource,
    getBrowserStreamConstraints
  ])

  useEffect(() => {
    if (videoSource && !mediaDevices.camera.devicesById.get(videoSource.deviceId)) {
      setVideoSource(mediaDevices.camera.default)
    }
    if (audioSource && !mediaDevices.microphone.devicesById.get(audioSource.deviceId)) {
      setAudioSource(mediaDevices.microphone.default)
    }
  }, [mediaDevices, setAudioSource, setVideoSource, videoSource, audioSource])

  return {
    videoSource: { selectedId: videoSource?.deviceId, setter: setVideoSource },
    audioSource: { selectedId: audioSource?.deviceId, setter: setAudioSource },
    resolution: { selectedId: videoResolution.id, setter: setVideoResolution },
    handleOnStartClick,
    isSaveEnabled:
      mediaDevices.camera.devices?.length === 0 || mediaDevices.microphone.devices?.length === 0
  }
}

export default useBrowserStream
