import React from 'react'
import { Box, IconButton, Slide } from '@material-ui/core'
import palette from '../../Common/theme/colors/palette'
import CloseIcon from '@material-ui/icons/Close'
import useBanner from './hooks/useBanner'
import { Animations } from '../../Common/constants/animations'

interface BannerProps {
  children: React.ReactNode
  id: string
  startTime?: Date
  endTime?: Date
}

const Banner = (props: BannerProps) => {
  const { children, id, startTime, endTime } = props
  const { dismissBanner, isDismissed } = useBanner(id, startTime, endTime)

  if (isDismissed) return null

  return (
    <Slide direction="down" in={true} mountOnEnter timeout={Animations.slow}>
      <Box style={{ background: palette.root.secondary, position: 'relative' }}>
        {children}
        <IconButton
          onClick={dismissBanner}
          style={{ position: 'absolute', right: '24px', top: '0' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Slide>
  )
}

export default Banner
