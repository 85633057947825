import graphql from 'babel-plugin-relay/macro'

const AcceptInviteQuery = graphql`
  query AcceptInviteQuery($inviteToken: UUID!) {
    invite: getInviteDetails(inviteToken: $inviteToken) {
      id
      token
      email
      expirationTime
      profileId
      roomId
      roomHash
      isNewUser
      isAccepted
    }
  }
`

export default AcceptInviteQuery
