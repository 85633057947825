/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileType = "BASIC" | "ENTERPRISE" | "%future added value";
export type HomeView_currentProfile = {
    readonly type: ProfileType;
    readonly id: unknown;
    readonly displayName: string;
    readonly availableCredits: number | null;
    readonly featureFlags: {
        readonly nodes: ReadonlyArray<{
            readonly key: string | null;
            readonly value: string | null;
        } | null>;
    };
    readonly " $refType": "HomeView_currentProfile";
};
export type HomeView_currentProfile$data = HomeView_currentProfile;
export type HomeView_currentProfile$key = {
    readonly " $data"?: HomeView_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"HomeView_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeView_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableCredits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ColumnProfileFeatureFlagsConnection",
      "kind": "LinkedField",
      "name": "featureFlags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ColumnProfileFeatureFlagsRecord",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '25e3ba789a7ff6230ff42cae5bca6be6';
export default node;
