/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ChatSubInput = {
    roomId?: string | null;
};
export type ChatroomMessagesSubscriptionVariables = {
    input: ChatSubInput;
};
export type ChatroomMessagesSubscriptionResponse = {
    readonly chatroomMessages: {
        readonly message: {
            readonly id: unknown;
            readonly sessionId: unknown | null;
            readonly sessionTime: number | null;
            readonly content: string;
            readonly createdAt: unknown;
            readonly deletedAt: unknown | null;
            readonly profileId: unknown;
            readonly profileDisplayName: string | null;
            readonly " $fragmentRefs": FragmentRefs<"ChatMessageItem_message">;
        } | null;
        readonly event: string | null;
    } | null;
};
export type ChatroomMessagesSubscription = {
    readonly response: ChatroomMessagesSubscriptionResponse;
    readonly variables: ChatroomMessagesSubscriptionVariables;
};



/*
subscription ChatroomMessagesSubscription(
  $input: ChatSubInput!
) {
  chatroomMessages(input: $input) {
    message {
      ...ChatMessageItem_message
      id
      sessionId
      sessionTime
      content
      createdAt
      deletedAt
      profileId
      profileDisplayName
    }
    event
  }
}

fragment ChatMessageItem_message on Message {
  id
  sessionId
  sessionTime
  content
  createdAt
  updatedAt
  deletedAt
  profileId
  profileDisplayName
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sessionTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileDisplayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "event",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatroomMessagesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatSubPayload",
        "kind": "LinkedField",
        "name": "chatroomMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChatMessageItem_message"
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatroomMessagesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatSubPayload",
        "kind": "LinkedField",
        "name": "chatroomMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34d90accd24867052229116ac836a0bd",
    "id": null,
    "metadata": {},
    "name": "ChatroomMessagesSubscription",
    "operationKind": "subscription",
    "text": "subscription ChatroomMessagesSubscription(\n  $input: ChatSubInput!\n) {\n  chatroomMessages(input: $input) {\n    message {\n      ...ChatMessageItem_message\n      id\n      sessionId\n      sessionTime\n      content\n      createdAt\n      deletedAt\n      profileId\n      profileDisplayName\n    }\n    event\n  }\n}\n\nfragment ChatMessageItem_message on Message {\n  id\n  sessionId\n  sessionTime\n  content\n  createdAt\n  updatedAt\n  deletedAt\n  profileId\n  profileDisplayName\n}\n"
  }
};
})();
(node as any).hash = 'd63d5be9f495dc16be728d22d4f2b278';
export default node;
