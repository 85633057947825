import React from 'react'

import CustomBox from './CustomBox'
import palette from '../../Common/theme/colors/palette'
import { makeStyles, withStyles } from '@material-ui/core'

export interface OutlineProps {
  children: React.ReactNode
  color?: string
  thickness?: number
  disabled?: boolean
  borderRadius?: string
}

/**
 * Displays an icon button with a border on hover.
 * @param icon - custom icon component for button
 * @param children
 * @constructor
 */
const HoverOutline: React.FC<OutlineProps> = ({
  color,
  thickness,
  children,
  borderRadius = '0%',
  disabled = false
}) => {
  const defaultValues = {
    color: color || palette.text.primary,
    thickness: thickness || 1
  }

  const useStyles = makeStyles({
    outline: {
      border: `${defaultValues.thickness}px solid transparent`,
      '&:hover': {
        borderColor: disabled ? 'transparent' : defaultValues.color
      }
    }
  })

  const classes = useStyles()

  return (
    <CustomBox clone className={classes.outline} borderRadius={borderRadius}>
      {children}
    </CustomBox>
  )
}

export default React.memo(
  withStyles({
    colorPrimary: {
      color: palette.background.primary
    }
  })(HoverOutline)
)
