import { graphql } from 'babel-plugin-relay/macro'

const UpdateProfileMutation = graphql`
  mutation UpdateProfileMutation($input: UpdateProfileByIdInput!) {
    updateProfileById(input: $input) {
      query {
        currentProfile {
          id
          displayName
        }
      }
    }
  }
`

export default UpdateProfileMutation
