import { useState } from 'react'

const dismissedKey = 'dismissed'

const useBanner = (id, startTime, endTime) => {
  const dismissBanner = () => {
    window.localStorage.setItem(id, dismissedKey)
    setDismissed(true)
  }

  const wasDismissed = () => {
    const now = new Date()
    const isDismissedInLocalStorage = window.localStorage.getItem(id) === dismissedKey
    const isBetweenStartAndEnd = (!startTime || now >= startTime) && (!endTime || now <= endTime)
    return isDismissedInLocalStorage || !isBetweenStartAndEnd
  }

  const [isDismissed, setDismissed] = useState(wasDismissed())

  return { dismissBanner, isDismissed }
}

export default useBanner
