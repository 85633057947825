import { graphql } from 'babel-plugin-relay/macro'

const UpdateSessionMutation = graphql`
  mutation UpdateSessionMutation($input: UpdateSessionByIdInput!, $roomId: UUID!) {
    updateSessionById(input: $input) {
      clientMutationId
      query {
        liveroomById(id: $roomId) {
          sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {
            nodes {
              id
              displayName
              createdAt
              stoppedAt
              deletedAt
            }
          }
        }
      }
    }
  }
`

export default UpdateSessionMutation
