import React, { useCallback, useMemo } from 'react'
import { Slider, Typography, Grid, Box } from '@material-ui/core'

import './SizeSlider.scss'

interface SizeSlider {
  onChangeCommitted: (event: React.ChangeEvent, value: number | number[]) => void
  initialValue: number
  circleColor?: string
}

const MIN_VALUE = 1
const MAX_VALUE = 30

/**
 * SideDrawerContent
 * @param onChangeCommitted - action that will be triggered once after the user finishes dragging slider
 * @param initialValue - value that will be shown as default
 * @constructor
 */
const SizeSlider: React.FC<SizeSlider> = ({ onChangeCommitted, initialValue, circleColor }) => {
  const [value, setValue] = React.useState<number>(initialValue)

  const handleChange = useCallback(
    (event: any, newValue: number | number[]) => setValue(newValue as number),
    []
  )

  const valueInPixel = useMemo(() => `${value}px`, [value])

  return (
    <Box className="size-slider">
      <Typography variant="body2" gutterBottom>
        Size
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Box
            paddingY={0.25}
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Slider
              min={MIN_VALUE}
              max={MAX_VALUE}
              color="secondary"
              value={value}
              onChange={handleChange}
              onChangeCommitted={onChangeCommitted}
              aria-labelledby="continuous-slider"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box
              className="size-slider-filled-circle"
              height={valueInPixel}
              width={valueInPixel}
              borderRadius="50%"
              bgcolor={circleColor || 'rgba(225,225,225,.87)'}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(SizeSlider)
