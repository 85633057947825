import { Subject } from 'rxjs'
import { logger } from '../../../log/Log'
import { JanusClient, JanusEvent, MessageType } from '../../plugin/JanusClient'

/**
 * Base class with common behavior for screenshare and browserstream publishers
 */
export abstract class BaseSharePublisher {
  protected readonly roomId: string
  protected readonly roomHash: string
  protected readonly displayName: string
  protected userId: string
  protected tag: string
  protected janusClient: JanusClient

  private publishErrorSubject = new Subject()

  constructor({ janusSignallingConnection, roomId, roomHash, userId, displayName, tag }) {
    this.roomId = roomId
    this.roomHash = roomHash
    this.userId = userId
    this.displayName = displayName
    this.tag = tag

    // janus client setup
    this.janusClient = new JanusClient(janusSignallingConnection, this.tag)
    this.janusClient.onJsep().subscribe(this.janusClient.handleRemoteJsep)
  }

  /**
   * Leaves the room: send share stop message, detach and clears the subscriptions,
   * It is important to pass noRequest: false otherwise detach is done only locally by janus.js
   */
  leave = async () => {
    this.publishErrorSubject.complete()

    await this.sendStopMessage()
    await this.janusClient.detach({ noRequest: false })

    this.janusClient.cleanup()
  }

  /**
   * Safely call innerPublish. Log and notify subscribers in case of errors.
   */
  publish = async (publishOptions) => {
    try {
      await this.innerPublish(publishOptions)
    } catch (error) {
      logger.error(
        `[${this.tag}] BaseSharePublisher failed. UserId: ${this.userId} in RoomId: ${this.roomId}`,
        error
      )
      this.publishErrorSubject.next()
    }
  }

  /**
   * Template method to be implemented by specialization classes to Publish media for share content 'user'.
    @param video - video source choosen
    @param audio - audio source choosen
    @param bitrate
   */
  protected abstract innerPublish: ({ audio, video, bitrate, stream }) => void

  unpublish = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.unpublish
      }
    })

  onLocalStream = () => this.janusClient.onLocalStream()

  onJoined = () => this.janusClient.onEvent(JanusEvent.JOINED)

  onPublishError = () => this.publishErrorSubject.asObservable()

  /**
   * Joins the room. Before actually joining, checks if user id was already taken.
   * It can happen when user was previously in the room but his connection was interrupted and
   * his session is still active (waiting for time out)
   */
  joinRoom = async () => {
    const { participants = [], attendees = [] } = await this.listRoomParticipants()

    const isUserOnParticipantList = participants.some(
      (participant) => participant.id === this.userId
    )
    const isUserOnAttendeesList = attendees.some((attendee) => attendee.id === this.userId)
    const isIdAlreadyTaken = isUserOnAttendeesList || isUserOnParticipantList
    if (isIdAlreadyTaken) {
      this.userId = `${this.userId}-${await this.janusClient.getHandleId()}`
    }

    await this.janusClient.sendMessage({
      message: {
        id: this.userId,
        request: MessageType.joinRoom,
        room: this.roomId,
        roomHash: this.roomHash,
        ptype: 'publisher',
        display: this.displayName
      }
    })
  }

  private listRoomParticipants = async () =>
    this.janusClient.sendMessage({
      message: {
        request: MessageType.listParticipants,
        room: this.roomId
      }
    })

  protected abstract sendStopMessage: () => void
}
