import { useEffect } from 'react'
import { useEnvironment } from '../../Providers/EnvironmentProvider'

const devtoolsCheck = () => {
  return setInterval(() => {
    // Eval is important here as it just throws the debug in a nondescript page on devtools
    // Using just debugger would stop at the source code which is not good

    // eslint-disable-next-line no-eval
    eval('debugger')
  }, 500)
}

/**
 * hook for code integrity monitor
 */
export const useCodeIntegrityMonitor = () => {
  const environment = useEnvironment()

  useEffect(() => {
    const checkInterval = (environment.devToolsSecurity && devtoolsCheck()) || null
    return () => {
      if (checkInterval) {
        clearInterval(checkInterval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {}
}
