import React from 'react'

import { ReactComponent as SettingsOutlined } from '../../../../../../../Static/images/settings.svg'
import OutlinedIconButton from '../../../../../../../Components/Buttons/OutlinedIconButton/OutlinedIconButton'
import './DeviceSelectionButton.scss'

interface DeviceSelectionButtonProps {
  onClick?: () => void
  className?: string
  medium?: boolean
}

/**
 * Displays device selection button
 * @param onClick - function handles when button is clicked
 */
const DeviceSelectionButton: React.FC<DeviceSelectionButtonProps> = ({
  onClick,
  ...otherProps
}) => {
  const { medium, ...props } = otherProps
  return (
    <>
      <OutlinedIconButton
        icon={medium ? SettingsOutlined : SettingsOutlined}
        onClick={onClick}
        tooltipTitle="Open device selection"
        thickness={2}
        label="Settings"
        {...props}
      />
    </>
  )
}

export default React.memo(DeviceSelectionButton)
