import React from 'react'

import { ReactComponent as AudioMixer } from '../../../../Static/images/audio_mixer.svg'

import OutlinedToggleIconButton from '../../../Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import { ToggleIconButtonProps } from '../../../Buttons/ToggleIconButton/ToggleIconButton'

interface ThumbnailToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
}

const AudioMixerToggleButton: React.FC<ThumbnailToggleButtonProps> = ({
  isActive,
  ...otherProps
}) => {
  return (
    <OutlinedToggleIconButton
      icon={AudioMixer}
      isActive={!isActive}
      tooltipTitle="Show audio mixer"
      label="Mixer"
      {...otherProps}
    />
  )
}

export default React.memo(AudioMixerToggleButton)
