/* eslint-disable */

const objectFitContain = require('../../objectFitContain').default

var bindEvents, buttonIsDown, getCoordinatesPosition, getCoordinatesPositionForTouch

getCoordinatesPositionForTouch = function (el, e) {
  const canvas = this.containerEl.childNodes[1]
  const elementProps = canvas.getBoundingClientRect()
  const { scale, leftOffset, topOffset } = objectFitContain({
    objectWidth: canvas.width,
    objectHeight: canvas.height,
    containerWidth: elementProps.width,
    containerHeight: elementProps.height
  })

  const tx = e.changedTouches[0].clientX
  const ty = e.changedTouches[0].clientY

  const coords = {
    left: (tx - (elementProps.left + leftOffset)) * scale,
    top: (ty - (elementProps.top + topOffset)) * scale
  }
  return [coords.left, coords.top]
}

getCoordinatesPosition = function (e) {
  // elementProps contains html <canvas> element position/size props,
  // when canvas.width and canvas.height attributes determine internal canvas area
  const canvas = this.containerEl.childNodes[1]
  const elementProps = canvas.getBoundingClientRect()
  const { scale, leftOffset, topOffset } = objectFitContain({
    objectWidth: canvas.width,
    objectHeight: canvas.height,
    containerWidth: elementProps.width,
    containerHeight: elementProps.height
  })

  return {
    left: (e.clientX - (elementProps.left + leftOffset)) * scale,
    top: (e.clientY - (elementProps.top + topOffset)) * scale
  }
}

buttonIsDown = function (e) {
  if (e.buttons != null) {
    return e.buttons === 1
  } else {
    return e.which > 0
  }
}

module.exports = bindEvents = function (lc, canvas, panWithKeyboard) {
  var listener, mouseMoveListener, mouseUpListener, touchEndListener, touchMoveListener, unsubs
  if (panWithKeyboard == null) {
    panWithKeyboard = false
  }
  unsubs = []
  const position = getCoordinatesPosition.bind(lc)
  mouseMoveListener = (function (_this) {
    return function (e) {
      e.preventDefault()
      const p = position(e)
      return lc.pointerMove(p.left, p.top)
    }
  })(this)
  mouseUpListener = (function (_this) {
    return function (e) {
      e.preventDefault()
      const p = position(e)
      lc.pointerUp(p.left, p.top)
      canvas.removeEventListener('mousemove', mouseMoveListener)
      canvas.removeEventListener('mouseup', mouseUpListener)
    }
  })(this)
  canvas.addEventListener(
    'mousedown',
    (function (_this) {
      return function (e) {
        if (e.target.tagName.toLowerCase() !== 'canvas') {
          return
        }
        e.preventDefault()
        const p = position(e)
        lc.pointerDown(p.left, p.top)
        canvas.addEventListener('mousemove', mouseMoveListener)
        canvas.addEventListener('mouseup', mouseUpListener)
      }
    })(this)
  )

  const touchPosition = getCoordinatesPositionForTouch.bind(lc)
  touchMoveListener = function (e) {
    e.preventDefault()
    return lc.pointerMove.apply(lc, touchPosition(canvas, e))
  }
  touchEndListener = function (e) {
    e.preventDefault()
    lc.pointerUp.apply(lc, touchPosition(canvas, e))
    canvas.removeEventListener('touchmove', touchMoveListener)
    canvas.removeEventListener('touchend', touchEndListener)
    canvas.removeEventListener('touchcancel', touchEndListener)
  }
  canvas.addEventListener('touchstart', function (e) {
    if (e.target.tagName.toLowerCase() !== 'canvas') {
      return
    }
    e.preventDefault()
    if (e.touches.length === 1) {
      lc.pointerDown.apply(lc, touchPosition(canvas, e))
      canvas.addEventListener('touchmove', touchMoveListener)
      canvas.addEventListener('touchend', touchEndListener)
      canvas.addEventListener('touchcancel', touchEndListener)
    } else {
      return lc.pointerMove.apply(lc, touchPosition(canvas, e))
    }
  })
  if (panWithKeyboard) {
    console.warn('Keyboard panning is deprecated.')
    listener = function (e) {
      switch (e.keyCode) {
        case 37:
          lc.pan(-10, 0)
          break
        case 38:
          lc.pan(0, -10)
          break
        case 39:
          lc.pan(10, 0)
          break
        case 40:
          lc.pan(0, 10)
      }
      return lc.repaintAllLayers()
    }
    canvas.addEventListener('keydown', listener)
    unsubs.push(function () {
      return canvas.removeEventListener(listener)
    })
  }
  return function () {
    var f, i, len, results
    results = []
    for (i = 0, len = unsubs.length; i < len; i++) {
      f = unsubs[i]
      results.push(f())
    }
    return results
  }
}
