import React from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import './NoteHeader.scss'
import { CheckCircleOutline } from '@material-ui/icons'

interface NoteHeaderProps {
  name: string
  chatRecordingOffset: string | null
  chatPlaybackLink: string | null
  hasPlaybackLink: boolean
  cardDate: string | null
  menuComponent: React.ReactNode | null
  openRecordingLinksInNewTab?: boolean
}

const NoteHeader: React.FC<NoteHeaderProps> = ({
  name,
  chatRecordingOffset,
  chatPlaybackLink,
  hasPlaybackLink,
  cardDate,
  openRecordingLinksInNewTab,
  menuComponent
}) => {
  return (
    <div
      className={classnames({
        'message-header-title': true,
        'force-nowrap': !hasPlaybackLink
      })}
    >
      <CheckCircleOutline className="note-circle-outline" fontSize="small" />
      <div className="message-header-title-name">
        <Typography variant="body1">{name}</Typography>
      </div>
      {hasPlaybackLink && (
        <>
          <Link
            target={openRecordingLinksInNewTab ? '_blank' : '_self'}
            className="message-header-title-record-time"
            to={chatPlaybackLink!}
          >
            {chatRecordingOffset}
          </Link>
        </>
      )}

      <div
        className={classnames({
          'message-header-title-date': true,
          'with-recording-time': hasPlaybackLink
        })}
      >
        {cardDate}
      </div>

      <div className="message-header-title-actions">{menuComponent}</div>
      <div className={classnames({ break: hasPlaybackLink })} />
    </div>
  )
}

export default React.memo(NoteHeader)
