import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import CopyToClipboardInput from '../../../../../Components/CopyClipboardInput/CopyToClipboardInput'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import StreamKeyInput from './StreamKeyInput/StreamKeyInput'
import { useEBSConfig } from '../../../../../Common/hooks/useEBSConfig'

interface EBSKeysDialogProps {
  roomId: string
  isOpen?: boolean
  onClose?: () => void
}

/**
 * Dialog wrapper to show EBS keys info
 * @param roomId - the room which is going to be used to stream EBS
 * @constructor
 */
const EBSKeys: React.FC<EBSKeysDialogProps> = ({ roomId }) => {
  const { ebsConfig, createOrRefresh, isLoading } = useEBSConfig()
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Box
        paddingX={{ xs: 8 }}
        mb={2}
        justifyContent="center"
        alignContent="center"
        textAlign="center"
      >
        <Typography variant="body2" color="textSecondary">
          Use these keys to tell EBS which room to stream to.
          <br />
          <br />
          <strong>Step 1: </strong>{' '}
          <a
            href={process.env.REACT_APP_EBS_DOWNLOAD_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Download</strong>
          </a>{' '}
          and install EBS.
          <br />
          <br />
          <strong>Step 2: </strong> Copy and paste these keys into the Settings window in EBS. To
          learn how, &nbsp;
          <a
            href={`${process.env.REACT_APP_SUPPORT_URL}/hc/en-us/articles/4860905006355`}
            target="_blank"
            rel="noopener noreferrer"
          >
            click here
          </a>
          .
        </Typography>
      </Box>

      <Box marginTop={3}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Server name
        </Typography>
        <CopyToClipboardInput content={ebsConfig?.signalingUrl || ''} />
      </Box>

      <Box marginTop={3}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Server room
        </Typography>
        <CopyToClipboardInput content={roomId} />
      </Box>

      <Box marginTop={3}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Stream key
        </Typography>
        <StreamKeyInput
          isVisible={isVisible}
          streamKey={ebsConfig?.streamKey || ''}
          isLoading={isLoading}
          onEyeBallClicked={() => setIsVisible((curr) => !curr)}
        />
      </Box>

      <Box marginTop={3}>
        <ProgressButton
          type="submit"
          color="secondary"
          variant="contained"
          isLoading={isLoading}
          fullWidth={false}
          onClick={() => isVisible ? createOrRefresh() : setIsVisible(true)}
        >
          {ebsConfig?.streamKey ?
            isVisible
              ? 'Refresh stream key'
              : 'Show stream key'
            : 'Create stream key'}
        </ProgressButton>
      </Box>
    </>
  )
}

export default EBSKeys
