import React from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../../Dialogs'
import AppleTVNotify from '../AppleTVNotify'
import { useDialog } from '../../../Components/RoomDialog/hooks/useDialog'
import { useNotifySubscription } from '../hooks/useNotifyAppleTV'

interface AppleTVNotifyDialogProps {
  classNames?: string
}

const AppleTVNotifyDialog: React.FC<AppleTVNotifyDialogProps> = () => {
  const [isOpen, openDialog, closeDialog] = useDialog()

  const { isApproved, appleTVName } = useNotifySubscription(openDialog)

  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="Apple TV sign-in" onClose={closeDialog} divider />}
      footer={<DefaultDialogFooter />}
    >
      <AppleTVNotify isApproved={isApproved} appleTVName={appleTVName} onClose={closeDialog} />
    </BaseDialog>
  )
}

export default React.memo(AppleTVNotifyDialog)
