import React from 'react'
import OpenRoomLogin from '../RoomView/RoomLogin/OpenRoomLogin/OpenRoomLogin'

export interface RoomViewProps {
  onLogin: () => void
}

/**
 * Room entry point for a case when user is not logged in.
 * After user logs in, main application view will rerender and authenticated room route will be displayed.
 */
const RoomView: React.FC<RoomViewProps> = ({ onLogin }) => {
  return <OpenRoomLogin onLogin={onLogin} />
}

export default React.memo(RoomView)





