import { Environment } from '../environments/environments'
import {
  RecordSource,
  Store,
  Environment as RelayEnvironment,
  Network,
  Observable
} from 'relay-runtime'
import { WebSocketLink } from 'apollo-link-ws'
import { createAxiosRelayInstance } from './axiosConfig'
import { execute } from 'apollo-link'
import { SubscriptionClient } from 'subscriptions-transport-ws'

/**
 * Creates relay environment for queries and network subscriptions
 *
 * @param   {Environment}  environment  an environment configuration
 *
 * @return  {RelayEnvironment}          relay environment
 */
export const createRelayEnvironment = (environment: Environment) => {
  const axios = createAxiosRelayInstance()
  const graphQlUrl = `${environment.apiUrl}/graphql`
  const websocketURL = graphQlUrl.replace('https', 'wss')

  const fetchQuery = async (operation, variables) => {
    const response = await axios.post(graphQlUrl, {
      query: operation.text,
      variables
    })
    return response?.data
  }

  const networkSubscriptions = (operation, variables) => {
    const subscriptionClient = new SubscriptionClient(websocketURL, {
      reconnect: true,
      inactivityTimeout: 5000,
      connectionParams: () => {
        const nonce = localStorage.getItem('nonce')

        return {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Double-Submit': nonce
          }
        }
      }
    })

    const subscriptionLink = new WebSocketLink(subscriptionClient)

    return Observable.create<any>((subscriber) => {
      return execute(subscriptionLink, {
        query: operation.text,
        variables
      }).subscribe((data) => subscriber.next(data))
    })
  }

  const source = new RecordSource()
  const store = new Store(source)

  return new RelayEnvironment({
    network: Network.create(fetchQuery, networkSubscriptions),
    store: store
  })
}
