/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ChatContainer_currentProfile = {
    readonly id: unknown;
    readonly " $refType": "ChatContainer_currentProfile";
};
export type ChatContainer_currentProfile$data = ChatContainer_currentProfile;
export type ChatContainer_currentProfile$key = {
    readonly " $data"?: ChatContainer_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"ChatContainer_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatContainer_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '467f767e2c83012377bb489132ac1a14';
export default node;
