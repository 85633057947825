import { useMemo, useEffect, useRef } from 'react'
import { Note } from '../../../Models/apiEntities'
import { usePrevious } from '../../../Common/hooks/usePrevious'

export interface useNotesUpdateNotificationProps {
  lastMessage?: Note
  isVisible: boolean
  handleNotesNotification: (notify: boolean) => void
}

export const useNotesUpdateNotification = (props: useNotesUpdateNotificationProps) => {
  const { lastMessage, isVisible, handleNotesNotification } = props

  const initialized = useRef(false)

  const lastMessageDate = useMemo(() => {
    return lastMessage ? new Date(lastMessage.createdAt).getTime() : undefined
  }, [lastMessage])

  const lastSeenDate = usePrevious(lastMessageDate)

  useEffect(() => {
    let notify = false

    if (!initialized.current) {
      initialized.current = true
      return
    }

    // only notify if chat is closed
    if (!isVisible && lastMessageDate && initialized.current) {
      if (!lastSeenDate) {
        // there is a lastMessage, but not lastSeenDate, so we received our first message
        notify = true
      }

      if (lastSeenDate! < lastMessageDate!) notify = true
    }

    handleNotesNotification(notify)
  }, [lastSeenDate, lastMessageDate, isVisible, handleNotesNotification])
}
