import React, { useEffect, useState, useMemo } from 'react'
import OutlinedToggleIconButton from '../../../../../../../../Components/Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import { TuneOutlined, VideocamOffOutlined, VolumeOffOutlined } from '@material-ui/icons'

interface UseStatusIconsProps {
  isAudioEnabled: boolean
  isVideoEnabled: boolean
  toggleAudioState: (boolean: boolean) => void
  toggleVideoState: (boolean: boolean) => void
  volume: string | number
  setIsVolumeSliderOpened: (boolean: boolean) => void
}

const useStatusIcons = ({
  isAudioEnabled,
  isVideoEnabled,
  toggleAudioState,
  toggleVideoState,
  volume,
  setIsVolumeSliderOpened,
}: UseStatusIconsProps) => {
  const icons = useMemo(
    () => ({
      audio: () => (
        <OutlinedToggleIconButton
          data-testid="audio-toggle-button"
          isActive
          onToggle={toggleAudioState}
          icon={VolumeOffOutlined}
          size="small"
          className="thumbnail-toggle-button"
        />
      ),
      video: () => (
        <OutlinedToggleIconButton
          data-testid="video-toggle-button"
          isActive
          onToggle={toggleVideoState}
          icon={VideocamOffOutlined}
          size="small"
          className="thumbnail-toggle-button"
        />
      ),
      mixer: () => (
        <OutlinedToggleIconButton
          data-testid="video-toggle-button"
          isActive
          onToggle={() => setIsVolumeSliderOpened(true)}
          icon={TuneOutlined}
          size="small"
          className="thumbnail-toggle-button"
        />
      ),
    }),
    [toggleAudioState, toggleVideoState, setIsVolumeSliderOpened]
  )

  const [iconNames, setIconNames] = useState<Array<string>>([])

  useEffect(() => {
    setIconNames(() => {
      const arr: string[] = []
      if (!isAudioEnabled) arr.push('audio')
      if (!isVideoEnabled) arr.push('video')
      if (Number(volume) !== 1) arr.push('mixer')

      return arr
    })
  }, [volume, isAudioEnabled, isVideoEnabled])

  const statusIcons = Array.from(iconNames).map((status, i) => (
    <React.Fragment key={i}>{icons[status]()}</React.Fragment>
  ))

  return {
    statusIcons,
  }
}

export default useStatusIcons
