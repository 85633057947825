import { useCallback } from 'react'
import { getProfilePreference, PreferenceType, ProfilePreference } from './Preferences'
import { ProfilePreference as ProfilePreferenceModel } from '../../Models/apiEntities'

export interface ProfilePreferences {
  getProfilePreference: (preferenceType: PreferenceType) => ProfilePreference
}

export const useProfilePreferences = (
  profilePreferences: ProfilePreferenceModel[]
): ProfilePreferences => {
  return {
    getProfilePreference: useCallback(
      (preferenceType: PreferenceType) => getProfilePreference(profilePreferences, preferenceType),
      [profilePreferences]
    )
  }
}
