import { logger } from './Log'

/**
 * Helper class to supress logs in production env
 */
export class ConditionalLoggerAdapter {
  static info(...params) {
    // const shouldLog = process.env.NODE_ENV !== 'production'
    // if (shouldLog) {
    //   logger.log(...params)
    // }
  }

  static contextualizedInfo(action: () => void) {
    const shouldLog = process.env.NODE_ENV !== 'production'
    if (shouldLog) {
      action()
    }
  }

  static error(error, extra) {
    logger.error(error, extra)
  }
}
