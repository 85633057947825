import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { History } from '@material-ui/icons'

import ProgressButton from '../../ProgressButton/ProgressButton'
import { useNoteList } from './hooks/useNoteList'

interface NoteListProps {
  pagination: { hasMore; isLoading; loadMore; defaultMessagesNumber: number }
  isVisible: boolean
  children: ReactNode[]
}

const NoteList: React.FC<NoteListProps> = ({
  pagination: { hasMore, isLoading, loadMore, defaultMessagesNumber },
  isVisible,
  children
}) => {
  const { loadMoreMessages, isLoadingMoreMessages, canLoadMore, messageEndRef } = useNoteList({
    messagesCount: children.length,
    defaultMessagesNumber,
    isVisible,
    hasMore,
    isLoading,
    loadMore
  })

  return (
    <Box className="chat-message-list">
      {canLoadMore && (
        <div className="chat-load-more">
          <ProgressButton
            size="small"
            color="primary"
            aria-label="load-more"
            onClick={loadMoreMessages}
            isLoading={isLoadingMoreMessages}
          >
            <History />
            Load More...
          </ProgressButton>
        </div>
      )}
      {children}
      <div ref={messageEndRef} style={{ display: 'block' }} />
    </Box>
  )
}

export default React.memo(NoteList)
