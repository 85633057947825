/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileType = "BASIC" | "ENTERPRISE" | "%future added value";
export type UpdateProfileByIdInput = {
    clientMutationId?: string | null;
    profilePatch: ProfilePatch;
    id: unknown;
};
export type ProfilePatch = {
    id?: unknown | null;
    displayName?: string | null;
    createdAt?: unknown | null;
    updatedAt?: unknown | null;
    image?: string | null;
    organizationId?: unknown | null;
    type?: ProfileType | null;
    deletedAt?: unknown | null;
    guestEmail?: string | null;
};
export type UpdateProfileMutationVariables = {
    input: UpdateProfileByIdInput;
};
export type UpdateProfileMutationResponse = {
    readonly updateProfileById: {
        readonly query: {
            readonly currentProfile: {
                readonly id: unknown;
                readonly displayName: string;
            } | null;
        } | null;
    } | null;
};
export type UpdateProfileMutation = {
    readonly response: UpdateProfileMutationResponse;
    readonly variables: UpdateProfileMutationVariables;
};



/*
mutation UpdateProfileMutation(
  $input: UpdateProfileByIdInput!
) {
  updateProfileById(input: $input) {
    query {
      currentProfile {
        id
        displayName
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateProfilePayload",
    "kind": "LinkedField",
    "name": "updateProfileById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Profile",
            "kind": "LinkedField",
            "name": "currentProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4c73c6f1269155538c887685c3940ea",
    "id": null,
    "metadata": {},
    "name": "UpdateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProfileMutation(\n  $input: UpdateProfileByIdInput!\n) {\n  updateProfileById(input: $input) {\n    query {\n      currentProfile {\n        id\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '67645c923d17f8626a3e9fe0625ab9b4';
export default node;
