import { useState, useCallback, useEffect } from 'react'
import { useEffectWithPredicate } from '../../../../../../Common/hooks/useEffectWithPredicate'
import { logger } from '../../../../../../Common/log/Log'

export enum MediaDevicesState {
  timeout,
  requestingPermission,
  awaiting,
  success
}

export const defaultDeviceId = 'default'
export const noneDeviceId = 'none'

export const emptyMediaDevices = {
  camera: {
    devices: [],
    devicesById: new Map(),
    default: null,
    hasPermission: true
  },
  microphone: {
    devices: [],
    devicesById: new Map(),
    default: null,
    hasPermission: true
  },
  speaker: {
    devices: [],
    devicesById: new Map(),
    default: null
  }
}

export interface MediaDevicesInfo {
  camera: {
    devices: MediaDeviceInfo[]
    devicesById: Map<string, MediaDeviceInfo>
    default: MediaDeviceInfo | null
    hasPermission: boolean
  }
  microphone: {
    devices: MediaDeviceInfo[]
    devicesById: Map<string, MediaDeviceInfo>
    default: MediaDeviceInfo | null
    hasPermission: boolean
  }
  speaker: {
    devices: MediaDeviceInfo[]
    devicesById: Map<string, MediaDeviceInfo>
    default: MediaDeviceInfo | null
  }
}

const getPermissions = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices()
  return {
    camera: !devices.find((device) => device.kind === 'videoinput' && device.deviceId === ''),
    microphone: !devices.find((device) => device.kind === 'audioinput' && device.deviceId === '')
  }
}

const requestPermissions = async () => {
  const constraints = {
    audio: {},
    video: {},
    sinkId: null
  }
  try {
    await navigator.mediaDevices.getUserMedia(constraints)
    return true
  } catch (err) {
    return false
  }
}

const getDevices = async (): Promise<MediaDevicesInfo> => {
  const permissions = await getPermissions()
  const devices = await navigator.mediaDevices.enumerateDevices()

  const filterAndMap = (deviceKind) => {
    const filtered = devices
      .filter((device) => device.kind === deviceKind)
      .filter((device) => device.deviceId !== '')
    const mapped = new Map(filtered.map((device) => [device.deviceId, device]))
    return [filtered, mapped] as [MediaDeviceInfo[], Map<string, MediaDeviceInfo>]
  }

  const [cameras, camerasMap] = filterAndMap('videoinput')
  const [microphones, microphonesMap] = filterAndMap('audioinput')
  const [speakers, speakersMap] = filterAndMap('audiooutput')

  logger.log(`Detected devices: ${JSON.stringify({ cameras, microphones, speakers }, null, 2)}`)

  return {
    camera: {
      devices: cameras,
      devicesById: camerasMap,
      default: camerasMap.get(defaultDeviceId) || cameras[0] || null,
      hasPermission: permissions.camera
    },
    microphone: {
      devices: microphones,
      devicesById: microphonesMap,
      default: microphonesMap.get(defaultDeviceId) || microphones[0] || null,
      hasPermission: permissions.microphone
    },
    speaker: {
      devices: speakers,
      devicesById: speakersMap,
      default: speakersMap.get(defaultDeviceId) || speakers[0] || null
    }
  }
}

export const useMediaDevices = () => {
  const [state, setState] = useState(MediaDevicesState.awaiting)
  const [mediaDevices, setMediaDevices] = useState<MediaDevicesInfo | null>(null)

  // load initial devices, ask for permissions if needed
  const loadPermissions = useCallback(async () => {
    const permissions = await getPermissions()
    if (!permissions.camera || !permissions.microphone) {
      setState(MediaDevicesState.requestingPermission)
      await requestPermissions()
      setState(MediaDevicesState.awaiting)
    }
  }, [setState] )

  const loadDevices = useCallback(async () => {
    const mediaTimeout = setTimeout(() => {
      setMediaDevices(emptyMediaDevices)
      setState(MediaDevicesState.timeout)
    }, 10000)
    const mediaDevices = await getDevices()
    setMediaDevices(mediaDevices)
    setState(MediaDevicesState.success)
    clearTimeout(mediaTimeout)
  }, [setState, setMediaDevices])

  useEffect(() => {
    (async () => {
      await loadPermissions()
      loadDevices()
    })()
  }, [loadDevices, loadPermissions])

  // subscribe to devices changes
  useEffectWithPredicate(
    {
      predicate: () => state === MediaDevicesState.success,
      effect: () => {
        const devicesChangeEventSupported = !!navigator.mediaDevices.addEventListener
        const listener = async () => {
          setMediaDevices(await getDevices())
        }
        if (devicesChangeEventSupported) {
          navigator.mediaDevices.addEventListener('devicechange', listener)
        }
        return () => {
          navigator.mediaDevices.removeEventListener('devicechange', listener)
        }
      }
    },
    [state]
  )

  return [state, mediaDevices] as [MediaDevicesState, MediaDevicesInfo]
}
