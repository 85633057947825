import { EvercastAuth } from '@evercast/auth-client'
import { SSODetails } from '../Models/apiEntities'
import { SSOConfig } from '../Common/environments/environments'

export const OAUTH_SCOPES = ['openid', 'profile', 'email', 'phone', 'aws.cognito.signin.user.admin']

/*
 * Responsible to wrap client implementation with our abstraction.
 */
export class EvercastAuthService {
  evercastAuthClient: EvercastAuth

  constructor(config: SSOConfig, ssoDetails: SSODetails) {
    const { userPoolClientId, userPoolId, userPoolDomain, userPoolRegion } = ssoDetails
    this.evercastAuthClient = new EvercastAuth({
      region: userPoolRegion,
      userPoolId,
      userPoolClientId,
      oauth: {
        domain: userPoolDomain,
        scope: OAUTH_SCOPES,
        redirectSignIn: config.loginRedirect,
        redirectSignOut: config.logoutRedirect
      }
    })
  }

  public logInFederated() {
    this.evercastAuthClient.logInFederated(undefined, false)
  }

  public logOutFederated() {
    this.evercastAuthClient.logOutFederated()
  }
}
