import { logger } from '../../../App/Common/log/Log'

export enum VideoQualityOptionsEnum {
  VGA = 1,
  HD = 2,
  FULLHD = 3,
  _4K = 4
}

class VideoQualityAspect {
  min: number
  ideal: number
  max: number
  constructor({ min, ideal, max }: { min: number; ideal: number; max: number }) {
    this.min = min
    this.ideal = ideal
    this.max = max
  }
}

export class VideoQuality {
  id: VideoQualityOptionsEnum
  label: string
  width: VideoQualityAspect
  height: VideoQualityAspect
  bitrate: number
  constructor({
    id,
    label,
    width,
    height,
    bitrate
  }: {
    id: VideoQualityOptionsEnum
    label: string
    width: VideoQualityAspect
    height: VideoQualityAspect
    bitrate: number
  }) {
    this.label = label
    this.width = width
    this.height = height
    this.id = id
    this.bitrate = bitrate
  }
}

const optionsArray = [
  new VideoQuality({
    id: VideoQualityOptionsEnum._4K,
    label: '4K',
    width: new VideoQualityAspect({ min: 320, ideal: 3840, max: 3840 }),
    height: new VideoQualityAspect({ min: 240, ideal: 2160, max: 2160 }),
    bitrate: 6000 * 1024
  }),
  new VideoQuality({
    id: VideoQualityOptionsEnum.FULLHD,
    label: '1080p',
    width: new VideoQualityAspect({ min: 320, ideal: 1920, max: 1920 }),
    height: new VideoQualityAspect({ min: 240, ideal: 1080, max: 1080 }),
    bitrate: 3096 * 1024
  }),
  new VideoQuality({
    id: VideoQualityOptionsEnum.HD,
    label: '720p',
    width: new VideoQualityAspect({ min: 320, ideal: 1280, max: 1280 }),
    height: new VideoQualityAspect({ min: 240, ideal: 720, max: 720 }),
    bitrate: 3096 * 1024
  }),
  new VideoQuality({
    id: VideoQualityOptionsEnum.VGA,
    label: '480p',
    width: new VideoQualityAspect({ min: 320, ideal: 640, max: 640 }),
    height: new VideoQualityAspect({ min: 240, ideal: 480, max: 480 }),
    bitrate: 256 * 1024
  })
]

class VideoQualityOptions {
  options: Map<VideoQualityOptionsEnum, VideoQuality>
  constructor() {
    this.options = new Map(
      optionsArray.map((x) => [x.id, x] as [VideoQualityOptionsEnum, VideoQuality])
    )
  }

  getDefault(): VideoQuality {
    return this.options.get(VideoQualityOptionsEnum.HD)!
  }

  get(option: VideoQualityOptionsEnum): VideoQuality {
    if (!this.options.has(option)) {
      logger.error(`Not supported option: ${option}`)
      return this.getDefault()
    }

    return this.options.get(option)!
  }

  getAll() {
    return Array.from(this.options.values())
  }
}

const singleton = new VideoQualityOptions()
export default singleton
