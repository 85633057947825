import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../../../Common/utils/formUtils'
import { Message } from '../../../../Models/apiEntities'

export const useChatForm = ({
  performCreateOrUpdate,
  selectedMessage
}: {
  performCreateOrUpdate: (content: string) => Promise<void>
  selectedMessage?: Message
}) => {
  const formMethods = useForm({ shouldFocusError: true })
  const { handleSubmit, formState, reset } = formMethods
  const { isSubmitted } = formState

  const inputs = useFormValidation(formMethods, {
    content: {
      required: true
    }
  })

  useEffect(() => {
    if (selectedMessage) {
      const { content } = selectedMessage
      reset({ content })
    }
  }, [reset, selectedMessage])

  useEffect(() => {
    if (isSubmitted) {
      reset(inputs.content)
    }
  }, [reset, isSubmitted, inputs])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        performCreateOrUpdate(data.content)
      }),
    [performCreateOrUpdate, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs
  }
}
