/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveRoom_iceServers = ReadonlyArray<{
    readonly urls: string;
    readonly username: string | null;
    readonly credential: string | null;
    readonly " $refType": "LiveRoom_iceServers";
}>;
export type LiveRoom_iceServers$data = LiveRoom_iceServers;
export type LiveRoom_iceServers$key = ReadonlyArray<{
    readonly " $data"?: LiveRoom_iceServers$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveRoom_iceServers">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LiveRoom_iceServers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urls",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "credential",
      "storageKey": null
    }
  ],
  "type": "IceServer",
  "abstractKey": null
};
(node as any).hash = '5b31d0b295a68727423c2f389b319c0b';
export default node;
