import React, { useState } from 'react'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
  Box
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core'
import DOMPurify from "dompurify";

export interface MessagingAlertProps {
  id: string
  message: string
  beginsAt: string // example 2021-05-19T19:45:21.343633+00:00
  expiresAt: string | null
  number: number
}

const localStorageKey = `dismissed-CSM-alerts`

const getAlerts = (): string[] => {
  const _alerts = localStorage.getItem(localStorageKey)
  if (!_alerts) return []
  const alerts = JSON.parse(_alerts)
  return alerts
}

export const cleanupAlertsInLocalStorage = (alertsFromDB: MessagingAlertProps[]) => {
  const alertsList = getAlerts()
  const ids = alertsFromDB.map(({ id }) => id)
  // If an id in local storage is no longer in the DB, we can remove it from local storage
  const currentAlertsThatHaveBeenDismissed = alertsList.filter((id) => {
    return ids.includes(id)
  })
  saveAlerts(currentAlertsThatHaveBeenDismissed)
}

const saveAlerts = (arr: string[]) => {
  localStorage.setItem(localStorageKey, JSON.stringify(arr))
}

const dismissCSMAlert = (id: string) => {
  const arr = getAlerts()
  arr.push(id)
  saveAlerts(arr)
}

const keepCSMAlert = (id: string) => {
  const arr = getAlerts()
  const newArr = arr.filter((_id) => id !== _id)
  saveAlerts(newArr)
}

export const didUserAlreadyDismissCSMAlert = (id: string) => {
  const arr = getAlerts()
  const item = arr.find((_id) => id === _id)
  return Boolean(item)
}

const SingleAlert = (props: MessagingAlertProps): JSX.Element => {
  const { message, id } = props

  const [isChecked, _setIsChecked] = useState(didUserAlreadyDismissCSMAlert(id))
  const setIsChecked = () =>
    _setIsChecked((bool) => {
      const newBool = !bool
      if (newBool) dismissCSMAlert(id)
      else keepCSMAlert(id)
      return newBool
    })

  const useStyles = makeStyles({
    messages: {
      '& a': {
        textDecoration: 'underline',
        fontSize: '1rem',
      }
    }
  })
  const classes = useStyles()

  const primary = (
    <Typography
      variant="body1"
      style={{ fontWeight: isChecked ? undefined : 'bold' }}
      className={classes.messages}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
    />
  )

  return (
    <ListItem button className="cms-alert" onClick={setIsChecked}>
      <Box pr={5}>
        <ListItemText id={id} primary={primary} />
      </Box>
      <ListItemSecondaryAction>
        <Checkbox
          edge="end"
          onChange={setIsChecked}
          checked={isChecked}
          icon={
            <span
              style={{
                backgroundColor: '#FC2A02',
                color: '#FFFFFF',
                fontSize: '1rem',
                padding: '0.5rem 0.7rem'
              }}
            >
              New
            </span>
          }
          checkedIcon={<Check fontSize="large" style={{ color: green[500] }} />}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default SingleAlert
