import graphql from 'babel-plugin-relay/macro'

const SavePreferencesMutation = graphql`
  mutation SavePreferencesMutation($input: SavePreferencesInput!) {
    savePreferences(input: $input) {
      preferences {
        preferenceTypeId
        preferenceTypeName
        preferenceValue
        preferenceTypeDefault
      }

      query {
        currentProfile {
          profilePreferences {
            nodes {
              preferenceTypeDefault
              preferenceTypeName
              preferenceValue
              preferenceTypeId
            }
          }
        }
      }
    }
  }
`

export default SavePreferencesMutation
