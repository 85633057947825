/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ChatMessageItem_message = {
    readonly id: unknown;
    readonly sessionId: unknown | null;
    readonly sessionTime: number | null;
    readonly content: string;
    readonly createdAt: unknown;
    readonly updatedAt: unknown;
    readonly deletedAt: unknown | null;
    readonly profileId: unknown;
    readonly profileDisplayName: string | null;
    readonly " $refType": "ChatMessageItem_message";
};
export type ChatMessageItem_message$data = ChatMessageItem_message;
export type ChatMessageItem_message$key = {
    readonly " $data"?: ChatMessageItem_message$data;
    readonly " $fragmentRefs": FragmentRefs<"ChatMessageItem_message">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatMessageItem_message",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sessionTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileDisplayName",
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};
(node as any).hash = '1c7b5de00c96486b520294f6a6910324';
export default node;
