import React from 'react'

import CustomBox from './CustomBox'
import palette from '../../Common/theme/colors/palette'

export interface OutlineProps {
  children: React.ReactNode
  color?: string
  thickness?: number
  disabled?: boolean
  borderRadius?: string
}

/**
 * Displays an icon button with a border.
 * @param icon - custom icon component for button
 * @param children
 * @constructor
 */
const Outline: React.FC<OutlineProps> = ({ color, thickness, children, borderRadius = '50%' }) => {
  const defaultValues = {
    color: color || palette.text.primary,
    thickness: thickness || 1
  }

  return (
    <CustomBox
      clone
      border={defaultValues.thickness}
      borderColor={defaultValues.color}
      borderRadius={borderRadius}
    >
      {children}
    </CustomBox>
  )
}

export default React.memo(Outline)
