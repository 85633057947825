import { graphql } from 'babel-plugin-relay/macro'

const AuthenticatedRoomViewQuery = graphql`
  query AuthenticatedRoomViewQuery($hash: UUID!) {
    profilePermissions: getProfilePermissionsByRoomHash(roomHash: $hash) {
      nodes
    }
    room: liveroomByHash(hash: $hash) {
      ...Room_room
      id
      displayName
      organizationId
      featureFlags {
        nodes {
          key
          value
        }
      }
    }
    iceServers: getIceServers {
      ...Room_iceServers
    }
    currentProfile {
      ...Room_currentProfile
    }
  }
`

export default AuthenticatedRoomViewQuery
