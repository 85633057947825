import { useMediaQuery } from '@material-ui/core'
import { useMemo } from 'react'

export enum DisplayingFormat {
  desktop,
  tablet,
  phone
}

export const useDisplayingFormat = () => {
  const isLargeDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('xl'), { noSsr: true })
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('lg'), { noSsr: true })
  const isTablet =
    useMediaQuery((theme: any) => theme.breakpoints.up('md'), { noSsr: true }) && !isDesktop
  return useMemo(() => {
    const isPhone = !isDesktop && !isTablet
    const format = isDesktop
      ? DisplayingFormat.desktop
      : isTablet
      ? DisplayingFormat.tablet
      : DisplayingFormat.phone

    return {
      format,
      isDesktop,
      isTablet,
      isPhone,
      isLargeDesktop
    }
  }, [isDesktop, isTablet, isLargeDesktop])
}
