import React from 'react'

import { ReactComponent as Microphone } from '../../../../../../../Static/images/microphone.svg'
import { ReactComponent as MicrophoneInactive } from '../../../../../../../Static/images/microphone_disabled.svg'
import { ReactComponent as Microphone2x } from '../../../../../../../Static/images/microphone2x.svg'
import { ReactComponent as MicrophoneInactive2x } from '../../../../../../../Static/images/microphone_disabled2x.svg'
import { ReactComponent as MicrophoneNone } from '../../../../../../../Static/images/microphone_none.svg'
import { ReactComponent as MicrophoneNone2x } from '../../../../../../../Static/images/microphone_none2x.svg'
import OutlinedToggleIconButton from '../../../../../../../Components/Buttons/OutlinedToggleIconButton/OutlinedToggleIconButton'
import { ToggleIconButtonProps } from '../../../../../../../Components/Buttons/ToggleIconButton/ToggleIconButton'

interface MicrophoneToggleButtonProps extends Omit<ToggleIconButtonProps, 'icon'> {
  noOutline?: boolean
  medium?: boolean
  noDevices?: boolean
  microphoneSetToNone?: boolean
  noneSetTooltipTitle?: string
}

/**
 * Displays toggle microphone button
 */
const MicrophoneToggleButton: React.FC<MicrophoneToggleButtonProps> = ({ ...otherProps }) => {
  const { medium, microphoneSetToNone, ...props } = otherProps

  const setIcon = () => {
    if (microphoneSetToNone) {
      return medium ? MicrophoneNone2x : MicrophoneNone
    } else {
      return medium ? Microphone2x : Microphone
    }
  }
  return (
    <OutlinedToggleIconButton
      activeTooltipTitle="Mute"
      inactiveTooltipTitle="Unmute"
      noDevicesTooltipTitle="No Microphone Detected"
      noneSetTooltipTitle="No Microphone Selected"
      icon={setIcon()}
      microphoneSetToNone={microphoneSetToNone}
      inactiveIcon={medium ? MicrophoneInactive2x : MicrophoneInactive}
      label="Mic"
      {...props}
    />
  )
}

export default React.memo(MicrophoneToggleButton)
