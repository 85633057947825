import React, { useCallback } from 'react'
import { Typography } from '@material-ui/core'

import TrashOutlinedIcon from '../../../assets/TrashOutlinedIcon'
import MenuItemWithSubMenu from '../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import { BaseDialog, DefaultDialogFooter } from '../../../../../../../../../../Components/Dialogs'
import { useToggleStatus } from '../../../../../../../../../../Common/hooks/useToggleStatus'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'

const ClearAllEveryone: React.FC = () => {
  const { clearDrawCanvasStream } = useDrawCanvasToolsContext()
  const [isDialogOpen, openDialog, closeDialog] = useToggleStatus()

  const onClickConfirmHandler = useCallback(() => {
    clearDrawCanvasStream()
    closeDialog()
  }, [closeDialog, clearDrawCanvasStream])

  return (
    <>
      <MenuItemWithSubMenu icon={<TrashOutlinedIcon />} onClick={openDialog} label="Clear stream" />
      <BaseDialog
        isOpen={isDialogOpen}
        maxWidth="xs"
        fullWidth
        testId="clear-all-dialog"
        footer={
          <DefaultDialogFooter
            onCancel={closeDialog}
            onConfirm={onClickConfirmHandler}
            confirmButtonText="Delete"
            confirmButtonProps={{
              variant: 'text',
              color: 'secondary'
            }}
          />
        }
      >
        <Typography variant="body2">
          Delete all drawings on this stream? You can't undo this action.
        </Typography>
      </BaseDialog>
    </>
  )
}

export default React.memo(ClearAllEveryone)
