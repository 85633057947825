import { graphql } from 'babel-plugin-relay/macro'

const ListenAttacherDeviceLoginCodeUpdateSubscription = graphql`
  subscription ListenAttacherDeviceLoginCodeUpdateSubscription {
    listenAttacherDeviceLoginCodeUpdate {
      deviceCodeApproved
      deviceName
      event
    }
  }
`

export default ListenAttacherDeviceLoginCodeUpdateSubscription
