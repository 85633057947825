import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'

import './SideDrawerContent.scss'

interface SideDrawerContentProps {
  title?: string
  children: React.ReactNode
}

/**
 * SideDrawerContent
 * @param title - title of the side drawer content
 * @param children - component that will be rendered
 * @constructor
 */
const SideDrawerContent: React.FC<SideDrawerContentProps> = ({ title = '', children }) => {
  const titleTag = useMemo(
    () =>
      title && (
        <Box paddingX={2} pt={3}>
          <Typography variant="body2" className="side-drawer-content-subtitle">
            {title.toUpperCase()}
          </Typography>
        </Box>
      ),
    [title]
  )
  return (
    <>
      {titleTag}
      <Box>{children}</Box>
    </>
  )
}

export default React.memo(SideDrawerContent)
