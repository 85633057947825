/* eslint-disable */

var defineSVGRenderer, lineEndCapShapes, renderShapeToSVG, renderers

lineEndCapShapes = require('./lineEndCapShapes')

renderers = {}

defineSVGRenderer = function (shapeName, shapeToSVGFunc) {
  return (renderers[shapeName] = shapeToSVGFunc)
}

renderShapeToSVG = function (shape, opts) {
  if (opts == null) {
    opts = {}
  }
  if (opts.shouldIgnoreUnsupportedShapes == null) {
    opts.shouldIgnoreUnsupportedShapes = false
  }
  if (renderers[shape.className]) {
    return renderers[shape.className](shape)
  } else if (opts.shouldIgnoreUnsupportedShapes) {
    console.warn("Can't render shape of type " + shape.className + ' to SVG')
    return ''
  } else {
    throw "Can't render shape of type " + shape.className + ' to SVG'
  }
}

defineSVGRenderer('Rectangle', function (shape) {
  var height, width, x, x1, x2, y, y1, y2
  x1 = shape.x
  y1 = shape.y
  x2 = shape.x + shape.width
  y2 = shape.y + shape.height
  x = Math.min(x1, x2)
  y = Math.min(y1, y2)
  width = Math.max(x1, x2) - x
  height = Math.max(y1, y2) - y
  if (shape.strokeWidth % 2 !== 0) {
    x += 0.5
    y += 0.5
  }
  return (
    "<rect x='" +
    x +
    "' y='" +
    y +
    "' width='" +
    width +
    "' height='" +
    height +
    "' stroke='" +
    shape.strokeColor +
    "' fill='" +
    shape.fillColor +
    "' stroke-width='" +
    shape.strokeWidth +
    "' />"
  )
})

defineSVGRenderer('SelectionBox', function (shape) {
  return ''
})

defineSVGRenderer('Ellipse', function (shape) {
  var centerX, centerY, halfHeight, halfWidth
  halfWidth = Math.floor(shape.width / 2)
  halfHeight = Math.floor(shape.height / 2)
  centerX = shape.x + halfWidth
  centerY = shape.y + halfHeight
  return (
    "<ellipse cx='" +
    centerX +
    "' cy='" +
    centerY +
    "' rx='" +
    Math.abs(halfWidth) +
    "' ry='" +
    Math.abs(halfHeight) +
    "' stroke='" +
    shape.strokeColor +
    "' fill='" +
    shape.fillColor +
    "' stroke-width='" +
    shape.strokeWidth +
    "' />"
  )
})

defineSVGRenderer('Image', function (shape) {
  return (
    "<image x='" +
    shape.x +
    "' y='" +
    shape.y +
    "' width='" +
    shape.image.naturalWidth * shape.scale +
    "' height='" +
    shape.image.naturalHeight * shape.scale +
    "' xlink:href='" +
    shape.image.src +
    "' />"
  )
})

defineSVGRenderer('Line', function (shape) {
  var arrowWidth, capString, dashString, x1, x2, y1, y2
  dashString = shape.dash ? "stroke-dasharray='" + shape.dash.join(', ') + "'" : ''
  capString = ''
  arrowWidth = Math.max(shape.strokeWidth * 2.2, 5)
  x1 = shape.x1
  x2 = shape.x2
  y1 = shape.y1
  y2 = shape.y2
  if (shape.strokeWidth % 2 !== 0) {
    x1 += 0.5
    x2 += 0.5
    y1 += 0.5
    y2 += 0.5
  }
  if (shape.endCapShapes[0]) {
    capString += lineEndCapShapes[shape.endCapShapes[0]].svg(
      x1,
      y1,
      Math.atan2(y1 - y2, x1 - x2),
      arrowWidth,
      shape.color
    )
  }
  if (shape.endCapShapes[1]) {
    capString += lineEndCapShapes[shape.endCapShapes[1]].svg(
      x2,
      y2,
      Math.atan2(y2 - y1, x2 - x1),
      arrowWidth,
      shape.color
    )
  }
  return (
    "<g> <line x1='" +
    x1 +
    "' y1='" +
    y1 +
    "' x2='" +
    x2 +
    "' y2='" +
    y2 +
    "' " +
    dashString +
    " stroke-linecap='" +
    shape.capStyle +
    "' stroke='" +
    shape.color +
    " 'stroke-width='" +
    shape.strokeWidth +
    "' /> " +
    capString +
    ' </g>'
  )
})

defineSVGRenderer('LinePath', function (shape) {
  return (
    "<polyline fill='none' points='" +
    shape.smoothedPoints
      .map(function (p) {
        var offset
        offset = p.strokeWidth % 2 === 0 ? 0.0 : 0.5
        return p.x + offset + ',' + (p.y + offset)
      })
      .join(' ') +
    "' stroke='" +
    shape.points[0].color +
    "' stroke-linecap='round' stroke-width='" +
    shape.points[0].size +
    "' />"
  )
})

defineSVGRenderer('ErasedLinePath', function (shape) {
  return ''
})

defineSVGRenderer('Polygon', function (shape) {
  if (shape.isClosed) {
    return (
      "<polygon fill='" +
      shape.fillColor +
      "' points='" +
      shape.points
        .map(function (p) {
          var offset
          offset = p.strokeWidth % 2 === 0 ? 0.0 : 0.5
          return p.x + offset + ',' + (p.y + offset)
        })
        .join(' ') +
      "' stroke='" +
      shape.strokeColor +
      "' stroke-width='" +
      shape.strokeWidth +
      "' />"
    )
  } else {
    return (
      "<polyline fill='" +
      shape.fillColor +
      "' points='" +
      shape.points
        .map(function (p) {
          var offset
          offset = p.strokeWidth % 2 === 0 ? 0.0 : 0.5
          return p.x + offset + ',' + (p.y + offset)
        })
        .join(' ') +
      "' stroke='none' /> <polyline fill='none' points='" +
      shape.points
        .map(function (p) {
          var offset
          offset = p.strokeWidth % 2 === 0 ? 0.0 : 0.5
          return p.x + offset + ',' + (p.y + offset)
        })
        .join(' ') +
      "' stroke='" +
      shape.strokeColor +
      "' stroke-width='" +
      shape.strokeWidth +
      "' />"
    )
  }
})

defineSVGRenderer('Text', function (shape) {
  var heightString, textSplitOnLines, widthString
  widthString = shape.forcedWidth ? "width='" + shape.forcedWidth + "px'" : ''
  heightString = shape.forcedHeight ? "height='" + shape.forcedHeight + "px'" : ''
  textSplitOnLines = shape.text.split(/\r\n|\r|\n/g)
  if (shape.renderer) {
    textSplitOnLines = shape.renderer.lines
  }
  return (
    "<text x='" +
    shape.x +
    "' y='" +
    shape.y +
    "' " +
    widthString +
    ' ' +
    heightString +
    " fill='" +
    shape.color +
    "' style='font: " +
    shape.font +
    ";'> " +
    textSplitOnLines
      .map(
        (function (_this) {
          return function (line, i) {
            var dy
            dy = i === 0 ? 0 : '1.2em'
            return (
              "<tspan x='" +
              shape.x +
              "' dy='" +
              dy +
              "' alignment-baseline='text-before-edge'> " +
              line +
              ' </tspan>'
            )
          }
        })(this)
      )
      .join('') +
    ' </text>'
  )
})

module.exports = {
  defineSVGRenderer: defineSVGRenderer,
  renderShapeToSVG: renderShapeToSVG,
}
