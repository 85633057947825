import React from 'react'
import { BaseDialogFooter } from '../BaseDialogFooter/BaseDialogFooter'
import { Button, ButtonProps } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

interface DefaultDialogFooterProps {
  divider?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
}

const DefaultDialogFooter: React.FC<DefaultDialogFooterProps> = ({
  divider,
  onConfirm,
  onCancel,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Save',
  cancelButtonProps,
  confirmButtonProps
}) => {
  return (
    <BaseDialogFooter divider={divider}>
      <Grid container justify="flex-end" alignItems="center" spacing={2}>
        <Grid item>
          {onCancel && (
            <Button
              color="primary"
              {...cancelButtonProps}
              onClick={onCancel}
              data-testid="default-dialog-footer-cancel-button"
            >
              {cancelButtonText}
            </Button>
          )}
        </Grid>
        <Grid item>
          {onConfirm && (
            <Button
              variant="contained"
              {...confirmButtonProps}
              onClick={onConfirm}
              data-testid="default-dialog-footer-confirm-button"
            >
              {confirmButtonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </BaseDialogFooter>
  )
}

const MemoizedDefaultDialogFooter = React.memo(DefaultDialogFooter)
export { MemoizedDefaultDialogFooter as DefaultDialogFooter }
