import React, { useState } from 'react'
export const UnauthenticatedViewContext = React.createContext<any>('')

export const UnauthenticatedContext = () => {
  const [signUpRedirect, setSignUpRedirect] = useState<boolean>(false)
  return {
    signUpRedirect,
    setSignUpRedirect
  }
}
