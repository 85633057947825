import { useCallback } from 'react'
import { logger } from '../../../../../../Common/log/Log'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { TwoFactorConfigurationVerificationState } from '../../TwoFactorConfigurationView'
import { ValidateTwoFactorCodeForm } from './useValidateTwoFactorCodeForm'
import useApi from '../../../../../../API/useApi'
import { useQueryParams } from '../../../../../../Common/hooks/useQueryParams'

export const useValidateTwoFactorCode = (handleContinue) => {
  const alert = useAlerts()
  const { token } = useQueryParams()
  const { AuthAccount } = useApi()

  const validateCode = useCallback(
    async (formData: ValidateTwoFactorCodeForm) => {
      try {
        await AuthAccount.confirmMfa(formData.code, token as string)
        handleContinue(TwoFactorConfigurationVerificationState.success)
      } catch (e) {
        let msg = e.response.data.errors.message
        if (msg) {
          alert(msg?.split('-')?.[1]?.trim() || msg, AlertType.error)
        } else {
          alert('Invalid MFA token. Please try again.', AlertType.error)
          logger.error(e)
        }
      }
    },
    [alert, AuthAccount, handleContinue, token]
  )

  return {
    validateCode,
  }
}
