import { useState, useEffect } from 'react'
import styled from 'styled-components'

export const Toggler = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 5;
  transform: translate(0%, -50%);
  text-decoration: underline;
  cursor: pointer;
`

/* 
  Extends the TextField to house a show/hide toggler
*/

export const usePasswordToggle = () => {
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleTitle, setToggleTitle] = useState<string>('Show')
  const [type, setType] = useState<string>('password')

  useEffect(() => {
    if (toggle) {
      setToggleTitle('Hide')
      setType('text')
    } else {
      setToggleTitle('Show')
      setType('password')
    }
  }, [toggle])

  return {
    toggle,
    setToggle,
    toggleTitle,
    Toggler,
    type
  }
}
