import React from 'react'
import { ReactComponent as connectionStrength3 } from '../../Static/images/connection-strength-3.svg'
import IconButton from '../Buttons/IconButton/IconButton'

const NetworkConnectivityIndicator = ({
  onClick = (_event: React.SyntheticEvent) => {},
}): JSX.Element => {
  return (
    <IconButton
      icon={connectionStrength3}
      tooltipTitle={'Network Connectivity'}
      onClick={onClick}
    />
  )
}

export default NetworkConnectivityIndicator
