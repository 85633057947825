import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import ProgressButton from '../../../../../../Components/ProgressButton/ProgressButton'
import { useGuestLoginForm } from './hooks/useGuestLoginForm'
import { routes } from '../../../routes'
import { Box, Typography } from '@material-ui/core'
import useRedirectUser from '../../../../../../Components/LoginForm/hooks/useRedirectUser'
import { WithCondition } from '../../../../../../Components/WithCondition/WithCondition'
import { useQueryParams } from '../../../../../../Common/hooks/useQueryParams'
import { logger } from '../../../../../../Common/log/Log'
import useApi from '../../../../../../API/useApi'

export interface OpenRoomLoginProps {
  onLogin: () => void
}

/**
 * Displays open room login form when user isn't logged in and room is open
 * @param onLogin
 * @constructor
 */
const OpenRoomLogin: React.FC<OpenRoomLoginProps> = ({ onLogin }) => {

  const [isInProgress, setIsInProgress] = useState(false);

  const { AuthAccount } = useApi()

  const performGuestLogin = async ({ displayName, email }) => {
    setIsInProgress(true);
    try {
      await AuthAccount.guestLogin(displayName, email)
      onLogin()
    } catch (e) {
      logger.error(e)
    }
    setIsInProgress(false);
  }

  const {
    inputs: { displayName, email },
    actions: { handleLoginClick },
    progressBtnRef,
  } = useGuestLoginForm({ performGuestLogin })

  const { makeRedirectQuery } = useRedirectUser()
  const { invite } = useQueryParams()

  return (
    <Grid
      container
      spacing={3}
      onKeyDown={(key) => {
        if (key.keyCode === 13) {
          return handleLoginClick()
        }
      }}
    >
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="h4">Join as a guest</Typography>
        </Box>
        <WithCondition condition={!invite}>
          <Box className="login-navigation" mb={2}>
            Have an account?{' '}
            <Link to={{ pathname: routes.login.path, search: makeRedirectQuery() }}>
              <strong>Sign in</strong>
            </Link>
          </Box>
        </WithCondition>
        <FormControl fullWidth>
          <TextField label="Display name" variant="filled"  {...displayName} />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <TextField label="Email address" variant="filled" {...email} />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="flex-start">
          <Box>
            <ProgressButton
              color="primary"
              isLoading={isInProgress}
              onClick={handleLoginClick}
              fullWidth
              className="continue"
              size="medium"
              buttonRef={progressBtnRef}
            >
              Continue
            </ProgressButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(OpenRoomLogin)
