import { useMemo, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { useFormValidation } from '../../../../../../Common/utils/formUtils'

type Inputs = {
  recordingName?: string
}

const RECORDING_NAME_MAX_LENGTH = 80

/**
 * Manage logic for the record session, form validation, handle api errors
 * @param performStartRecordingMutation action that will be triggered when button is clicked
 */
export const useRecordSessionForm = ({ performStartRecording }) => {
  const todaysDate = format(new Date(), 'M-dd-yy | h:mma')
  const [recordingNameLength, setRecordingNameLength] = useState(todaysDate.length)

  const formMethods = useForm<Inputs>({
    shouldFocusError: true,
    defaultValues: {
      recordingName: todaysDate
    }
  })

  const { handleSubmit } = formMethods

  const inputs = useFormValidation(formMethods, {
    recordingName: {
      required: 'Recording name cannot be empty'
    }
  })

  const _handleSubmit = useMemo(
    () =>
      handleSubmit(({ recordingName }) => {
        performStartRecording(recordingName)
      }),
    [handleSubmit, performStartRecording]
  )

  const recordingNameChange = useCallback(
    (recordingNameText) => {
      setRecordingNameLength(recordingNameText.length)
    },
    [setRecordingNameLength]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs,
    RECORDING_NAME_MAX_LENGTH,
    recordingNameLength,
    recordingNameChange
  }
}
