import React from 'react'
import classnames from 'classnames'
import {
  IconButton as MUIIconButton,
  SvgIconProps,
  IconButtonProps,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
  iconButton: {
    '& .MuiIconButton-label': {
      flexDirection: 'column',
    },
  },
})
export interface ExtendedIconButtonProps extends IconButtonProps {
  icon: React.ComponentType<SvgIconProps> | React.FC
  className?: string
  tooltipTitle?: string
  noDevices?: boolean
  customRender?: (icon: JSX.Element) => JSX.Element
  label?: string
}

/**
 * Displays an Icon Button
 * @param icon - custom icon component for button
 * @param children
 * @constructor
 */
const IconButton: React.FC<ExtendedIconButtonProps> = ({
  icon,
  className,
  tooltipTitle = '',
  customRender,
  label,
  ...otherProps
}) => {
  const classes = useStyles()
  const Icon = icon

  return (
    <Tooltip title={tooltipTitle}>
      <MUIIconButton
        {...otherProps}
        disableTouchRipple
        className={classnames(className, classes.iconButton)}
      >
        {customRender ? customRender(<Icon />) : <Icon />}
        {label && (
          <Typography variant="button" style={{ display: 'block' }}>
            {label}
          </Typography>
        )}
      </MUIIconButton>
    </Tooltip>
  )
}

export default React.memo(IconButton)
