import { useEffect, useMemo, useState } from 'react'
import {
  LiveroomClient,
  Publisher
} from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { useAudioAndVideoConstraints } from '../../../../../../../Common/media/hooks/useAudioAndVideoConstraints'
import { Stream, StreamState } from '../../../../../../../Common/media/stream'
import { MediaSelection } from '../../hooks/useMediaSetup'
import {LocalDevicesActions, LocalDevicesState} from '../../hooks/useLocalDevicesState'
import { logger } from '../../../../../../../Common/log/Log'
import {useNavigation} from "../../../../../../../Common/hooks/useNavigation";
import {useAlerts} from "../../../../../../../Providers/AlertsProvider";
import {AlertType} from "../../../../../../../Components/Alert/Alert";

export interface LiveroomPublisher {
  id: string
  displayName: string
  stream: Stream
  iceState: RTCIceTransportState
}

interface useLiveroomPublisherParams {
  liveroomClient: LiveroomClient | null
  mediaSelection: MediaSelection
  localDevicesState: LocalDevicesState
  localDevicesActions: LocalDevicesActions,
  room,
  canPublish,
}

export const useLiveroomPublisher = ({
  liveroomClient,
  mediaSelection,
  localDevicesState,
  localDevicesActions,
  room,
  canPublish,
}: useLiveroomPublisherParams) => {
  const [publisher, setPublisher] = useState<Publisher | null>(null)
  const [iceState, setIceState] = useState<RTCIceTransportState>('new')
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const constraints = useAudioAndVideoConstraints(mediaSelection)
  const { goToDashboardWithState } = useNavigation()
  const alert = useAlerts()

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [mediaStream])

  useEffect(() => {
    if (liveroomClient) {
      liveroomClient.onMute().subscribe(() => {
        localDevicesActions.setIsMicrophoneEnabled(false)
        alert('The room owner muted you', AlertType.error)
      })
      liveroomClient.onParticipantKick().subscribe(() => {
        liveroomClient.leave()
        goToDashboardWithState(room.displayName)
      })
      liveroomClient.onJoined().subscribe((publisher) => {
        setPublisher(publisher!)
        setMediaStream(null)
        publisher.onLocalStream().subscribe(setMediaStream)
        publisher.onIceState().subscribe(setIceState)
      })
    } else {
      logger.log('Clearing up publisher and the local stream.')
      if (publisher) {
        publisher.cleanup()
      }
      setPublisher(null)
      setMediaStream(null)
    }
    // TODO: Inherited from cleverTech, haven't investigated yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveroomClient])

  useEffect(() => {
    if (!publisher) {
      return
    }
    if (!canPublish) {
      return
    }
    const publishContraints = constraints ? constraints : { audio:false, video:false }
    publisher.publish(publishContraints, mediaSelection.cameraQuality)
    if (!constraints) {
      setMediaStream(null)
    }
  }, [publisher, constraints, mediaSelection.cameraQuality])

  useEffect(() => {
    if (!publisher || !mediaStream) {
      return
    }

    if (localDevicesState.isMicrophoneEnabled) {
      publisher.unmuteAudio()
    } else {
      publisher.muteAudio()
    }
  }, [publisher, localDevicesState.isMicrophoneEnabled, mediaStream])

  useEffect(() => {
    if (!publisher || !mediaStream) {
      return
    }

    if (localDevicesState.isCameraEnabled) {
      publisher.unmuteVideo()
    } else {
      publisher.muteVideo()
    }
  }, [publisher, localDevicesState.isCameraEnabled, mediaStream])

  const stream: Stream = useMemo(() => {
    if (!publisher || !mediaStream) {
      return {
        state: StreamState.unavailable
      }
    }
    return {
      state: StreamState.available,
      current: mediaStream,
      hasAudioTrack: true,
      hasVideoTrack: true,
      speaker: mediaSelection.speaker
    }
  }, [publisher, mediaStream, mediaSelection.speaker])

  const publisherModel: LiveroomPublisher = useMemo(
    () => ({
      displayName: publisher?.displayName as string,
      id: publisher?.id as string,
      stream,
      iceState
    }),
    [stream, publisher, iceState]
  )

  return {
    publisher,
    publisherModel
  }
}
