import React from 'react'
import SuccessNotification from '../../../../../Components/SuccessNotification/SuccessNotification'

/**
 * Evercast change password success view for unauthenticated users
 * @constructor
 */
const ChangePasswordSuccess: React.FC = () => {
  return (
    <SuccessNotification title="Reset password success" button="Go to sign in">
      Your password is updated
    </SuccessNotification>
  )
}

export default ChangePasswordSuccess
