import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/* 
  There are no cases for success since that will be handled by the SSO third-party platform.
  Users will only be routed to this page if they do not have an account via SSO, or encounter an error.
*/

export enum SSOVerificationState {
  loading,
  noAccount,
  companySSOError,
  appleIDPError,
  notCompanySSO,
  error
}

export const useSSOAction = () => {
  const [state, setState] = useState(SSOVerificationState.loading)
  const href = useLocation().search

  useEffect(() => {
    const findQueryString = (string) => {
      if (href.includes(string)) return href
    }
    switch (href) {
      case findQueryString('no-account'):
        setState(SSOVerificationState.noAccount)
        break
      case findQueryString('not-company-sso'):
        setState(SSOVerificationState.notCompanySSO)
        break
      case findQueryString('company-sso-error'):
        setState(SSOVerificationState.companySSOError)
        break
      case findQueryString('apple-idp-error'):
        setState(SSOVerificationState.appleIDPError)
        break
      default:
        setState(SSOVerificationState.error)
        break
    }
  }, [href])
  return {
    state
  }
}
