import {
  borders,
  compose,
  css,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography
} from '@material-ui/system'
import { styled } from '@material-ui/styles'

const innerStyleFunction = css(
  compose(borders, display, flexbox, positions, palette, shadows, sizing, spacing, typography)
)

const styleFunction = (props) => ({
  '&&': innerStyleFunction(props)
})

styleFunction.filterProps = (innerStyleFunction as any).filterProps

/**
 * Increases specificity of CSS generated by Box.
 * This is needed because a bug in material-ui causing Box->Clone to works diferently in prod from dev.
 * https://github.com/mui-org/material-ui/issues/18496
 * https://github.com/mui-org/material-ui/issues/21038
 * https://github.com/mui-org/material-ui/issues/15993 (this workaround)
 * In PROD cloned box does not override the inner element style, so we added more specificity to make it override.
 */
const CustomBox = styled('div')(styleFunction, {
  name: 'MuiBox'
})

export default CustomBox
