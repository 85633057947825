import React, { FunctionComponent } from 'react'
import { Box, Typography, IconButton, Tooltip } from '@material-ui/core'
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DoneIcon from '@material-ui/icons/Done'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles(
  () =>
    createStyles({
      container: {
        position: 'absolute',
        width: 210,
        display: 'flex',
        margin: '8px 20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        zIndex: 1
      },
      ArrowDownButtonContainer: {
        marginLeft: 125,
        marginTop: 10
      },
      doneButtonContainer: {
        marginBottom: 20
      },
      iconButton: {
        flex: '0 0 auto',
        padding: 12,
        overflow: 'visible',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }
    }),
  { name: 'MuiPickersCalendarHeader' }
)

export interface DatePickerToolbarProps extends ToolbarComponentProps {
  onSubmit?: (range: MaterialUiPickersDate[]) => void
  doneStyle?: any
  dateRange?: MaterialUiPickersDate[]
}

const DatePickerToolbar: FunctionComponent<DatePickerToolbarProps> = ({
  setOpenView,
  openView,
  onSubmit,
  doneStyle,
  dateRange
}) => {
  const classes = useStyles()
  const submit = onSubmit ? onSubmit : () => {}
  const ariaText = dateRange?.length ? `Download` : `Select a valid date range`

  return (
    <Box className={classes.container}>
      {openView !== 'year' && (
        <>
          <Box className={classes.ArrowDownButtonContainer}>
            <Typography variant="body2">
              <IconButton
                size="small"
                onClick={() => {
                  setOpenView('year')
                }}
              >
                <ArrowDropDownIcon fontSize="small" />
              </IconButton>
            </Typography>
          </Box>

          <Box className={classes.doneButtonContainer}>
            <Tooltip title={ariaText} placement="top" enterDelay={500}>
              <IconButton
                aria-label={ariaText}
                size="medium"
                className={classes.iconButton}
                style={{ right: 0 }}
                onClick={() => submit(dateRange || [])}
              >
                <DoneIcon fontSize="default" style={doneStyle || {}} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  )
}

export default React.memo(DatePickerToolbar)
