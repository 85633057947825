import React from 'react'
import './DesktopAppLaunchView.scss'
import rightArrow from '../../../Static/images/right_arrow.svg'

interface DesktopAppLaunchViewButtonProps {
  onClick: () => void
}

const DesktopAppLaunchViewButton: React.FC<DesktopAppLaunchViewButtonProps> = ({
  children,
  onClick
}) => {
  return (
    <div className={'desktop-app-launch-button'} onClick={onClick}>
      <div className={'desktop-app-launch-button-content'}>{children}</div>
      <img className={'desktop-app-launch-button-arrow'} src={rightArrow} alt={''} />
    </div>
  )
}

interface DesktopAppLaunchViewProps {
  continueToWebAppCallback: () => void
  retryNativeAppLaunch: () => void
}

const DesktopAppLaunchView: React.FC<DesktopAppLaunchViewProps> = ({
  continueToWebAppCallback,
  retryNativeAppLaunch
}) => {
  return (
    <div className={'desktop-app-launch-wrapper'}>
      <div className={'desktop-app-launch-description'}>
        Welcome!
        <br />
        Please accept the browser's pop-up window prompt to open the app.
      </div>
      <div className={'desktop-app-launch-content'}>
        <div className={'desktop-app-launch-description-alternative'}>
          If you don't have the pop-up, click the button below.
        </div>
        <DesktopAppLaunchViewButton onClick={retryNativeAppLaunch}>
          Launch the Evercast app
        </DesktopAppLaunchViewButton>
        <div className={'desktop-app-launch-alternatives'}>
          Or,{' '}
          <div className={'desktop-app-launch-button-link'} onClick={continueToWebAppCallback}>
            click here
          </div>{' '}
          to join via web browser.
        </div>
      </div>
    </div>
  )
}

export default DesktopAppLaunchView
