import graphql from 'babel-plugin-relay/macro'

const CreateMessageMutation = graphql`
  mutation CreateMessageMutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      clientMutationId
      message {
        ...ChatMessageItem_message
      }
    }
  }
`

export default CreateMessageMutation
