import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { routes } from '../../routes'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import './PostSSOError.scss'

const PostSSOError = ({ message }): JSX.Element => {
  return (
    <Grid container spacing={3} className="post-sso">
      <Grid item xs={12}>
        <h1>Ooops! There's a problem!</h1>
        <p>{message}</p>
      </Grid>
      <Grid item xs={12}>
        <Link to={routes.login.path} className="button">
          <ProgressButton isLoading={false} color="primary" className="continue">
            <strong>Back to sign in</strong>
          </ProgressButton>
        </Link>
      </Grid>
    </Grid>
  )
}

export default React.memo(PostSSOError)
