import React, { useState } from 'react'
import { Grid, Box, TextField } from '@material-ui/core'
import palette from '../../../../Common/theme/colors/palette'
import { useRoom } from '../../../../Providers/RoomProvider'
import ProgressButton from '../../../ProgressButton/ProgressButton'

interface ClearChatConfirmProps {
  onConfirm: () => void
  isLoading: boolean
}

const ClearChatConfirm: React.FC<ClearChatConfirmProps> = ({ onConfirm, isLoading }) => {
  const [confirmName, setConfirmName] = useState('')
  const room = useRoom()
  const roomName = room.displayName.toUpperCase()
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        Clearing the chats in this room will remove the information from our database.{' '}
        <b>This CANNOT be undone.</b>
      </Grid>
      <Grid item xs={12} container>
        <Box color={palette.text.secondary} width="100%" paddingBottom={2}>
          To confirm this action, please type “{roomName}”.
        </Box>
        <TextField
          label="Room Name"
          value={confirmName}
          variant="filled"
          onChange={(e) => setConfirmName(e.target.value.toUpperCase())}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ProgressButton
          isLoading={isLoading}
          color="secondary"
          variant="contained"
          fullWidth
          disabled={roomName !== confirmName}
          onClick={onConfirm}
        >
          I’M SURE - CLEAR CHAT
        </ProgressButton>
      </Grid>
    </Grid>
  )
}

export default React.memo(ClearChatConfirm)
