/* eslint-disable */

var INFINITE, JSONToShape, renderWatermark, util

util = require('./util').default

JSONToShape = require('./shapes').JSONToShape

INFINITE = 'infinite'

renderWatermark = function (ctx, image, scale) {
  if (!image.width) {
    return
  }
  ctx.save()
  ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
  ctx.scale(scale, scale)
  ctx.drawImage(image, -image.width / 2, -image.height / 2)
  return ctx.restore()
}

module.exports = function (snapshot, opts) {
  var allShapes, backgroundShapes, colors, imageSize, s, shapes, watermarkCanvas, watermarkCtx
  if (opts == null) {
    opts = {}
  }
  if (opts.scale == null) {
    opts.scale = 1
  }
  shapes = (function () {
    var i, len, ref, results
    ref = snapshot.shapes
    results = []
    for (i = 0, len = ref.length; i < len; i++) {
      s = ref[i]
      results.push(JSONToShape(s))
    }
    return results
  })()
  backgroundShapes = []
  if (snapshot.backgroundShapes) {
    backgroundShapes = (function () {
      var i, len, ref, results
      ref = snapshot.backgroundShapes
      results = []
      for (i = 0, len = ref.length; i < len; i++) {
        s = ref[i]
        results.push(JSONToShape(s))
      }
      return results
    })()
  }
  if (opts.margin == null) {
    opts.margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  }
  imageSize = snapshot.imageSize || {
    width: INFINITE,
    height: INFINITE
  }
  colors = snapshot.colors || {
    background: 'transparent'
  }
  allShapes = shapes.concat(backgroundShapes)
  watermarkCanvas = document.createElement('canvas')
  watermarkCtx = watermarkCanvas.getContext('2d')
  if (opts.rect) {
    opts.rect.x -= opts.margin.left
    opts.rect.y -= opts.margin.top
    opts.rect.width += opts.margin.left + opts.margin.right
    opts.rect.height += opts.margin.top + opts.margin.bottom
  } else {
    opts.rect = util.getDefaultImageRect(
      (function () {
        var i, len, results
        results = []
        for (i = 0, len = allShapes.length; i < len; i++) {
          s = allShapes[i]
          results.push(s.getBoundingRect(watermarkCtx))
        }
        return results
      })(),
      imageSize,
      opts.margin
    )
  }
  watermarkCanvas.width = opts.rect.width * opts.scale
  watermarkCanvas.height = opts.rect.height * opts.scale
  watermarkCtx.fillStyle = colors.background
  watermarkCtx.fillRect(0, 0, watermarkCanvas.width, watermarkCanvas.height)
  if (!(opts.rect.width && opts.rect.height)) {
    return null
  }
  if (opts.watermarkImage) {
    renderWatermark(watermarkCtx, opts.watermarkImage, opts.watermarkScale)
  }
  return util.combineCanvases(
    watermarkCanvas,
    util.renderShapes(backgroundShapes, opts.rect, opts.scale),
    util.renderShapes(shapes, opts.rect, opts.scale)
  )
}
