import { useCallback } from 'react'
import { BrowserStreamSelection } from './useBrowserStream'
import { VideoQuality } from '../../../../../../Common/video/videoQualityOptions'

export interface BrowserStreamMediaConstraints {
  video: (MediaTrackConstraints & { bitrate: number }) | null
  audio: MediaTrackConstraints | null
}

const useBrowserStreamMediaConstraints = () => {
  const getVideoConstraint = useCallback(
    (
      videoSource: MediaDeviceInfo,
      resolution: VideoQuality,
      supportedConstraints: MediaTrackSupportedConstraints
    ) => {
      if (!videoSource) return null

      const videoConstraint: MediaTrackConstraints & { bitrate: number } = resolution
      if (videoSource.deviceId !== 'default') {
        videoConstraint.deviceId = {
          exact: videoSource.deviceId
        }
      }

      if (supportedConstraints.aspectRatio) videoConstraint.aspectRatio = 16 / 9
      if (supportedConstraints.frameRate) videoConstraint.frameRate = 30

      return videoConstraint
    },
    []
  )

  const getAudioConstraint = useCallback((audioSource: MediaDeviceInfo) => {
    if (!audioSource) return null
    return {
      deviceId: {
        exact: audioSource.deviceId
      },
      echoCancellation: false
    }
  }, [])

  const getBrowserStreamConstraints = useCallback(
    (browserStreamSelection: BrowserStreamSelection): BrowserStreamMediaConstraints => {
      if (!browserStreamSelection) return { audio: null, video: null }

      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints()
      const { audioSource, videoSource, resolution } = browserStreamSelection

      return {
        video: getVideoConstraint(videoSource, resolution, supportedConstraints),
        audio: getAudioConstraint(audioSource)
      }
    },
    [getVideoConstraint, getAudioConstraint]
  )

  return getBrowserStreamConstraints
}

export default useBrowserStreamMediaConstraints
