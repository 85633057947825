import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.5606 11.7286C19.8419 11.441 19.9999 11.0511 19.9999 10.6444C19.9999 10.2378 19.8419 9.84776 19.5606 9.5602L14.5606 4.44907C14.2793 4.16153 13.8978 4 13.5 4C13.1022 4 12.7206 4.16153 12.4393 4.44907L4.43931 12.6269C4.15802 12.9144 4 13.3044 4 13.7111C4 14.1177 4.15802 14.5077 4.43931 14.7953L7.43932 17.862C7.72061 18.1495 8.10213 18.3111 8.49995 18.3111H19.625C19.7245 18.3111 19.8198 18.2707 19.8902 18.1988C19.9605 18.1269 20 18.0294 20 17.9278V17.1611C20 17.0594 19.9605 16.9619 19.8902 16.89C19.8198 16.8181 19.7245 16.7778 19.625 16.7778H14.6212L19.5606 11.7286ZM13.5 5.53327L18.5 10.6444L15.2812 13.9347L10.2812 8.82356L13.5 5.53327ZM8.49995 16.7778L5.49994 13.7111L9.2187 9.90968L14.2187 15.0208L12.5 16.7778H8.49995Z" />
  </SvgIcon>
)

export default Icon
