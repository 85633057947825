import graphql from 'babel-plugin-relay/macro'

const AccountViewQuery = graphql`
  query AccountViewQuery {
    currentProfile {
      ...SettingsView_currentProfile
    }
  }
`

export default AccountViewQuery
