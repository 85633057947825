import './ThumbnailData.scss'
import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from '@material-ui/core'
import { checkOverflow } from '../../Common/utils/textUtils'

interface ThumbnailDataProps {
  data?: string
}

export const ThumbnailData = ({ data }: ThumbnailDataProps) => {
  const [overflowActive, setOverflowActive] = useState<boolean>(false)
  const overflowingText = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true)
      return
    }
    setOverflowActive(false)
  }, [overflowActive, data])

  return (
    <Tooltip disableHoverListener={!overflowActive} title={data ?? ''}>
      <div ref={overflowingText} className="tcs-data">
        {data ?? '--'}
      </div>
    </Tooltip>
  )
}
