import React from 'react'
import HoverOutline from '../../Outline/HoverOutline'
import IconButton, { ExtendedIconButtonProps } from '../IconButton/IconButton'

export interface OutlinedIconButtonProps extends ExtendedIconButtonProps {
  thickness?: number
}

/**
 * Displays an icon button with a border.
 * OutlinedIconButton is just a composition with Outline and IconButton
 * @param icon - custom icon component for button
 * @param children
 * @constructor
 */
const OutlinedIconButton: React.FC<OutlinedIconButtonProps> = ({ thickness, ...buttonProps }) => {
  const { color } = buttonProps

  return (
    <HoverOutline thickness={thickness} color={color}>
      <IconButton {...buttonProps} />
    </HoverOutline>
  )
}

export default React.memo(OutlinedIconButton)
