import { MutableRefObject, useEffect, useState } from 'react'
import { useIntersectionObserver } from '../../../../../../../../Common/hooks/useIntersectionObserver'
import { LiveroomParticipant } from '../../hooks/useLiveroomParticipants'

interface UseToggleFeedProps {
  thumbnailRef: null | MutableRefObject<HTMLElement | null>
  participant: LiveroomParticipant
  isVideoEnabled: boolean
  isParticipantSpeaking?: boolean
}

export function useToggleVideoFeed({
  thumbnailRef,
  participant,
  isVideoEnabled,
  isParticipantSpeaking,
}: UseToggleFeedProps) {
  const [isReceivingVideo, setIsReceivingVideo] = useState(false)

  const onVisible = async () => {
    if (isVideoEnabled) {
      await participant.startReceivingVideo()
      setIsReceivingVideo(true)
    }
  }

  const onHidden = async (entry: IntersectionObserverEntry) => {
    if (!isParticipantSpeaking && entry.intersectionRatio <= 0) {
      participant.stopReceivingVideo()
      setIsReceivingVideo(false)
    }
  }

  useEffect(() => {
    if (isParticipantSpeaking) {
      participant.startReceivingVideo()
      setIsReceivingVideo(true)
    }
  }, [isParticipantSpeaking, participant])

  useIntersectionObserver({
    ref: thumbnailRef,
    onVisible,
    onHidden,
  })

  return { isReceivingVideo }
}
