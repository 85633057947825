import React from 'react'
import { Typography, Box } from '@material-ui/core'
import AppleIcon from '@material-ui/icons/Apple'
import ProgressButton from '../ProgressButton/ProgressButton'

interface AppleTVApproveProps {
  approve: () => void
  deny: () => void
  isInProgress: boolean
  isApproving: boolean
  appleTVName: string
}

const AppleTVApprove: React.FC<AppleTVApproveProps> = ({
  approve,
  deny,
  isInProgress,
  isApproving,
  appleTVName
}) => {
  return (
    <>
      <Box marginTop={1}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'lighter'
          }}
        >
          <AppleIcon style={{ fontSize: '3.6rem' }} />
          Apple TV
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="body1" align="center" gutterBottom>
          {appleTVName} is requesting access.
        </Typography>
      </Box>
      <Box marginTop={5} display="flex" justifyContent="center">
        <ProgressButton
          type="submit"
          color="secondary"
          variant="contained"
          isLoading={isInProgress && isApproving}
          fullWidth={false}
          style={{ marginRight: '20px' }}
          onClick={approve}
          autoFocus
        >
          Accept
        </ProgressButton>
        <ProgressButton
          type="submit"
          color="default"
          variant="contained"
          isLoading={isInProgress && !isApproving}
          fullWidth={false}
          style={{ marginLeft: '20px' }}
          onClick={deny}
        >
          Deny
        </ProgressButton>
      </Box>
    </>
  )
}

export default React.memo(AppleTVApprove)
