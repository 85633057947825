import { graphql } from 'babel-plugin-relay/macro'

const VerifyEmailMutation = graphql`
  mutation VerifyEmailMutation($input: VerifyEmailAddressInput!) {
    verifyEmailAddress(input: $input) {
      emailVerificationResult {
        isVerified
        email
      }
    }
  }
`
export default VerifyEmailMutation
