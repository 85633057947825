import React, { useCallback, useMemo } from 'react'
import { Box } from '@material-ui/core'
import SizeSlider from '../../../SizeSlider/SizeSlider'
import MenuItemWithSubMenu from '../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import {
  useDrawCanvasToolsContext,
  DRAW_TOOLS
} from '../../../../../../../../../../Providers/DrawCanvasToolsProvider'
import { DrawToolIcon } from '../../../DrawToolIcon/DrawToolIcon'

const Draw: React.FC = () => {
  const {
    drawPreferences: {
      drawThickness,
      drawTool,
      drawStrokeColorRed,
      drawStrokeColorGreen,
      drawStrokeColorBlue,
      drawStrokeColorAlpha
    },
    setDrawThickness,
    setDrawTool
  } = useDrawCanvasToolsContext()

  const rgba = useMemo(
    () =>
      `rgba(${drawStrokeColorRed},${drawStrokeColorGreen},${drawStrokeColorBlue},${drawStrokeColorAlpha})`,
    [drawStrokeColorRed, drawStrokeColorGreen, drawStrokeColorBlue, drawStrokeColorAlpha]
  )

  const onChangeCommittedHandler = useCallback(
    (event, value) => {
      setDrawThickness(value)
      setDrawTool(DRAW_TOOLS.PENCIL)
    },
    [setDrawThickness, setDrawTool]
  )

  const onClickDrawHandler = useCallback(() => {
    setDrawTool(DRAW_TOOLS.PENCIL)
  }, [setDrawTool])

  return (
    <MenuItemWithSubMenu
      icon={<DrawToolIcon drawTool={DRAW_TOOLS.PENCIL} />}
      onClick={onClickDrawHandler}
      selected={drawTool === DRAW_TOOLS.PENCIL}
      isOpen={drawTool === DRAW_TOOLS.PENCIL}
      label="Draw"
    >
      <Box pt={1.5} pb={3} pr={1.5} pl={2.5}>
        <SizeSlider
          key={drawThickness}
          onChangeCommitted={onChangeCommittedHandler}
          initialValue={drawThickness}
          circleColor={rgba}
        />
      </Box>
    </MenuItemWithSubMenu>
  )
}

export default React.memo(Draw)
