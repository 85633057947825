import { useCallback } from 'react'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { useDisplayGraphQLErrorsIfPresent } from '../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { logger } from '../../../Common/log/Log'
import { useDeleteRoomInviteMutation, mapToVariables } from './useDeleteRoomInviteMutation'
import { AlertType } from '../../Alert/Alert'
import { useEffectNonNull } from '../../../Common/hooks/useEffectNonNull'

const defaultErrorText = 'Error when deleting invites!'

const useDeleteRoomInvite = (roomId: string) => {
  const alert = useAlerts()

  const [executeMutation, { isInProgress, error, graphQLErrors }] = useDeleteRoomInviteMutation(
    roomId
  )

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText
  })

  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  const performDeleteRoomInvite = useCallback(
    async (inviteId: string) => {
      try {
        await executeMutation(mapToVariables(roomId, inviteId))
        alert('Invite deleted successfully.', AlertType.success)
      } catch (e) {
        logger.error(e)
      }
    },
    [executeMutation, alert, roomId]
  )

  return {
    isInProgress,
    performDeleteRoomInvite
  }
}

export default useDeleteRoomInvite
