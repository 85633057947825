import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useGetStreamKeyMutation } from '../../../EBSKeys/hooks/useStreamKeyMutation'
import { Publisher } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { Room } from '../../../../../../../Models/apiEntities'
import useInterval from '@use-it/interval'

export const formatStats = (stats) => {
  const results = {}
  stats.forEach((value, key) => {
    results[key] = value
  })
  return results
}

declare var process: {
  env: {
    REACT_APP_CSD_DELAY: number
  }
}

const DELAY_TIME = process.env.REACT_APP_CSD_DELAY || 5000

interface useClientsideDiagnosticsParams {
  publisher: Publisher
  room: Room
}

export const useClientsideDiagnostics = ({ publisher, room }: useClientsideDiagnosticsParams) => {
  const [isCSDEnabled, setIsCSDEnabled] = useState<boolean>(false)
  const [csdResults, setCSDResults] = useState(statSummary)
  const setCSD = (bool) => {
    setIsCSDEnabled(bool)
  }
  const [executeGetMutation] = useGetStreamKeyMutation()
  const getStreamKey = useCallback(async () => {
    const result = await executeGetMutation({ input: {} })
    return result?.getStreamKey?.uuid || ''
  }, [executeGetMutation])

  //server connection issue, attempting to reconnect automatically
  async function fetchCSD() {
    const stats = await publisher.getMediaStreamStats()
    if (!stats) return

    const payload = formatStats(stats)
    const streamKey = await getStreamKey()
    const options = {
      withCredentials: true,
    }

    //AuthenticatedRoomViewQuery
    //GetAndCacheRoomPermissionsMutation
    axios.defaults.withCredentials = true // will inherit JWT in cookie and pass it to post
    axios
      .post(
        '/diagnostics',
        {
          roomId: room.id,
          opaqueId: room.id,
          streamKey,
          organizationId: room.organizationId,
          payload,
        },
        options
      )
      .then(() => {
        // console.log('corn:: axios stuff')
        // axios.get('/diagnostics', options).then((data) => {
        //   console.log('corn::', data)
        // })
      })
    setCSDResults(getStatsForDisplay(payload as RTCStatsReport))
  }

  useEffect(() => {
    if (isCSDEnabled && publisher) {
      fetchCSD()
    }
    // eslint-disable-next-line
  }, [isCSDEnabled, publisher])

  useInterval(() => {
    if (isCSDEnabled) {
      fetchCSD()
    }
  }, DELAY_TIME)

  return { setCSD, isCSDEnabled, csdResults }
}

export interface RtcStatSummary {
  packetsLost: number
  packetsSent: number
  packetsReceived: number
  bytesSent: number
  bytesReceived: number
  inboundFps: number[]
  outboundFps: number[]
  framesEncoded: number
  framesSent: number
  framesReceived: number
  framesDecoded: number
  totalEncodeTime: number
  totalDecodeTime: number
  totalInterFrameDelay: number
  inboundFpsAverage: number
  outboundFpsAverage: number
  totalRoundTripTime: number
  timestamp: number
  responsesReceived: number
  jitter: number
}

let statSummary: RtcStatSummary = {
  packetsLost: 0,
  packetsSent: 0,
  packetsReceived: 0,
  bytesSent: 0,
  bytesReceived: 0,
  inboundFps: [],
  outboundFps: [],
  framesEncoded: 0,
  framesSent: 0,
  framesReceived: 0,
  framesDecoded: 0,
  totalEncodeTime: 0,
  totalDecodeTime: 0,
  totalInterFrameDelay: 0,
  inboundFpsAverage: 0,
  outboundFpsAverage: 0,
  totalRoundTripTime: 0,
  timestamp: new Date().getTime(),
  responsesReceived: 0,
  jitter: 0,
}

export function getStatsForDisplay(statReport: RTCStatsReport): RtcStatSummary {
  Object.entries(statReport).forEach(([, stat]) => {
    switch (stat.type) {
      case 'candidate-pair': {
        statSummary.totalRoundTripTime = stat.totalRoundTripTime
        statSummary.responsesReceived = stat.responsesReceived
        break
      }

      case 'inbound-rtp': {
        statSummary.packetsLost += stat.packetsLost

        if (stat.kind === 'video') {
          statSummary.framesDecoded += stat.framesDecoded
          statSummary.framesReceived += stat.framesReceived
          statSummary.totalInterFrameDelay += stat.totalInterFrameDelay
          statSummary.totalDecodeTime += stat.totalDecodeTime
        }

        break
      }

      case 'outbound-rtp': {
        if (stat.kind === 'video') {
          statSummary.outboundFps.push(stat.framesPerSecond)
          statSummary.framesEncoded += stat.framesEncoded
          statSummary.framesSent += stat.framesSent
          statSummary.totalEncodeTime += stat.totalEncodeTime
        }

        break
      }

      case 'transport': {
        statSummary.bytesSent = stat.bytesSent
        statSummary.bytesReceived = stat.bytesReceived
        statSummary.packetsReceived = stat.packetsReceived
        statSummary.packetsSent = stat.packetsSent
        statSummary.timestamp = stat.timestamp
        break
      }

      case 'remote-inbound-rtp': {
        statSummary.packetsLost += stat.packetsLost ?? 0
        statSummary.jitter = stat.jitter
        break
      }

      case 'media-source': {
        if (stat.kind === 'video') {
          statSummary.inboundFps.push(stat.framesPerSecond)
        }
        break
      }

      default:
        break
    }
  })

  statSummary.inboundFpsAverage = statSummary.inboundFps.length
    ? statSummary.inboundFps.reduce((prev, curr) => prev + curr, 0) / statSummary.inboundFps.length
    : 0
  statSummary.outboundFpsAverage = statSummary.outboundFps.length
    ? statSummary.outboundFps.reduce((prev, curr) => prev + curr, 0) /
      statSummary.outboundFps.length
    : 0

  return statSummary
}
