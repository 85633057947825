import { useMemo, useState } from 'react'
import { useEffectWithPredicate } from '../../../../../../Common/hooks/useEffectWithPredicate'
import { useIsMounted } from '../../../../../../Common/hooks/useIsMounted'
import { PlaybackClient } from '../../../../../../Common/janus/clients/playback/PlaybackClient'
import { useJanusInit } from '../../../../../../Common/janus/signalling/hooks/useJanusInit'
import { useJanusSignallingConnection } from '../../../../../../Common/janus/signalling/hooks/useJanusSignallingConnection'
import { SignallingConnectionState } from '../../../../../../Common/janus/signalling/JanusSignallingConnection'
import { RoomSession } from '../../../../../../Models/apiEntities'
import { useEnvironment } from '../../../../../../Providers/EnvironmentProvider'

interface usePlaybackParams {
  session: RoomSession
  iceServers
}

export const usePlaybackClient = ({ session, iceServers }: usePlaybackParams) => {
  useJanusInit()
  const [playbackClient, setPlaybackClient] = useState<PlaybackClient | null>(null)
  const isMounted = useIsMounted()
  const environment = useEnvironment()
  const serverUrl = useMemo(() => `${environment.webSocketUrl}?sessionId=${session.id}`, [
    environment,
    session
  ])
  const { janusSignallingConnection, signallingConnectionState } = useJanusSignallingConnection({
    iceServers,
    serverUrl
  })

  useEffectWithPredicate(
    {
      predicate: () => signallingConnectionState === SignallingConnectionState.Connected,
      effect: () => {
        const playbackClient = new PlaybackClient({
          recordingId: session.recordingId,
          janusSignallingConnection
        })
        setPlaybackClient(playbackClient)
        return () => {
          playbackClient.leave()
          if (isMounted.current) {
            setPlaybackClient(null)
          }
        }
      }
    },
    [signallingConnectionState, janusSignallingConnection]
  )

  return playbackClient
}
