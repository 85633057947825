import { useEffect } from 'react'
import { Room } from '../../../../../../../Models/apiEntities'
import { LocalDevicesState } from '../../hooks/useLocalDevicesState'
import { MediaSelection, VideoQuality } from '../../hooks/useMediaSetup'
import { SignallingConnectionState } from '../../../../../../../Common/janus/signalling/JanusSignallingConnection'
import { LiveroomClient } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { useCSDContext } from '../../../../../../../Providers/CSDProvider'

interface ReportingPrarams {
  room: Room
  currentProfile: any
  localDevicesState: LocalDevicesState
  mediaSelection: MediaSelection
  signallingConnectionState: SignallingConnectionState
  liveroomClient: LiveroomClient | null
  microphone: any
  isFullScreenMode: boolean | null
}

export interface RoomPayloadParams {
  roomInformation?: Room | null
  currentProfile?: null
  speaker: MediaDeviceInfo | null
  camera: MediaDeviceInfo | string | null
  cameraQuality: VideoQuality | null
  isCameraEnabled: LocalDevicesState['isCameraEnabled'] | null
  isAudioEnabled: LocalDevicesState['isAudioOutputEnabled'] | null
  isMicrophoneEnabled: LocalDevicesState['isMicrophoneEnabled'] | null
  connectionState: SignallingConnectionState | null
  isPPTEnabled: boolean | null
  isFullScreenMode: boolean | null
  participantCount: number | null
  isRoomPublic: boolean | null
}

export const useLiveroomDiagnosticsReporting = ({
  room,
  currentProfile,
  localDevicesState,
  mediaSelection,
  signallingConnectionState,
  liveroomClient,
  microphone,
  isFullScreenMode
}: ReportingPrarams) => {
  const { setRoomPayloadParams } = useCSDContext()

  useEffect(() => {
    setRoomPayloadParams({
      roomInformation: room,
      currentProfile: currentProfile.id,
      speaker: mediaSelection.speaker,
      camera: mediaSelection.camera ? mediaSelection.camera : 'No camera detected',
      cameraQuality: mediaSelection.cameraQuality,
      isCameraEnabled: localDevicesState.isCameraEnabled,
      isAudioEnabled: localDevicesState.isAudioOutputEnabled,
      isMicrophoneEnabled: localDevicesState.isMicrophoneEnabled,
      connectionState: signallingConnectionState,
      isPPTEnabled: microphone.PPTShortcut,
      isFullScreenMode: isFullScreenMode,
      participantCount: room.participantCount,
      isRoomPublic: room.isPublic
    })
  }, [
    room,
    currentProfile.id,
    isFullScreenMode,
    localDevicesState.isCameraEnabled,
    localDevicesState.isAudioOutputEnabled,
    localDevicesState.isMicrophoneEnabled,
    mediaSelection.camera,
    mediaSelection.cameraQuality,
    microphone.PPTShortcut,
    mediaSelection.speaker,
    signallingConnectionState,
    setRoomPayloadParams
  ])
}
