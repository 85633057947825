import { useMemo } from 'react'
import { useEnvironment } from '../../../../Providers/EnvironmentProvider'
import { routes } from '../../../../Views/MainView/AuthenticatedView/routes'

/**
 * Hook for getting a public room url
 *
 * @param hash
 * @returns Full public URL of a specific room. ex. http://host/rooms/xxxx-xxxx-xxxxx-xxxx
 */
export const useRoomAddress = (hash) => {
  const environment = useEnvironment()

  return useMemo(() => {
    const roomRoute = routes.room.path.replace(':hash', hash)
    return `${environment.frontendUrl}${roomRoute}`
  }, [environment, hash])
}
