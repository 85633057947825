import { VideoStream } from '../stream'
import { useStreamFromConstraints } from './useStreamFromConstraints'
import { useVideoConstraints, VideoConstraintsParams } from './useVideoConstraints'

export const useVideoStream = ({
  speaker,
  ...videoConstraintsParams
}: VideoConstraintsParams & { speaker: MediaDeviceInfo | null }) => {
  const videoConstraints = useVideoConstraints(videoConstraintsParams)
  return useStreamFromConstraints(videoConstraints, speaker) as VideoStream
}
