import React, { memo, useMemo } from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import VideocamIcon from '@material-ui/icons/Videocam'
import classnames from 'classnames'
import format from 'date-fns/format'
import { RoomSession, Room } from '../../../../../../Models/apiEntities'
import { useRecordingCard } from './hooks/useRecordingCard'
import { useDeleteRecording, DeleteRecordingState } from './hooks/useDeleteRecording'
import Menu from '../../../../../../Components/Menu/Menu'
import { BaseDialog, DefaultDialogHeader } from '../../../../../../Components/Dialogs'
import { BaseDialogFooter } from '../../../../../../Components/Dialogs/Footers/BaseDialogFooter/BaseDialogFooter'
import ProgressButton from '../../../../../../Components/ProgressButton/ProgressButton'

import './RecordingCard.scss'

interface RecordingCardProps {
  isActive: boolean
  session: RoomSession
  room: Room
  isOwner: boolean
  onSelected: () => void
}

/**
 * Recording card details
 * @param recording recording information
 * @param room room information
 * @param isOwner boolean to know if the user is the owner of the room
 * @param onSelected handler for RecordingCard click to load playback
 * @constructor
 */
const RecordingCard: React.FC<RecordingCardProps> = ({
  session,
  room,
  isOwner,
  onSelected,
  isActive
}) => {
  const { recordingDuration } = useRecordingCard(session)
  const {
    deleteRecordingState,
    deleteRecording,
    showDeleteConfirmation,
    closeDeleteConfirmation,
    isDeleteConfirmationOpen
  } = useDeleteRecording(session, room)

  const menuOptions = useMemo(() => [{ text: 'Delete', onClick: showDeleteConfirmation }], [
    showDeleteConfirmation
  ])

  const actionButton = useMemo(() => {
    return (
      <Menu
        button={(openMenu) => (
          <IconButton
            aria-label="settings"
            size="small"
            onClick={openMenu}
            data-testid="menu-button"
          >
            <MoreVertIcon />
          </IconButton>
        )}
        menuOptions={menuOptions}
      />
    )
  }, [menuOptions])

  return (
    <div
      className={classnames(isActive ? 'active-recording' : 'inactive-recording', 'recording-card')}
      onClick={onSelected}
      data-testid="recording-card"
    >
      <Box alignItems="center" justifyContent="center" display="flex" pt={2} pb={2}>
        <VideocamIcon color={isActive ? 'secondary' : 'inherit'} className="recording-card-icon" />
      </Box>
      <Box padding={1} className="recording-card-information">
        <Grid container>
          <Grid item xs={10}>
            {session.displayName}
          </Grid>
          {isOwner && (
            <Grid item xs={2} className="recording-card-information-menu">
              {actionButton}
            </Grid>
          )}
        </Grid>
        <Box pt={1} className="recording-card-information-date">
          {`${recordingDuration} | ${format(new Date(session.createdAt), 'MMM dd, yyyy')}`}
        </Box>
      </Box>
      <BaseDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        fullWidth={true}
        header={
          <DefaultDialogHeader
            text={'Delete recording'}
            divider={true}
            onClose={closeDeleteConfirmation}
          />
        }
        footer={
          <BaseDialogFooter>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Button color="primary" onClick={closeDeleteConfirmation}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <ProgressButton
                  color="secondary"
                  isLoading={deleteRecordingState === DeleteRecordingState.submitting}
                  onClick={deleteRecording}
                >
                  OK
                </ProgressButton>
              </Grid>
            </Grid>
          </BaseDialogFooter>
        }
      >
        <Typography variant="body1">
          This recording will be permanently deleted: {session.displayName}
        </Typography>
      </BaseDialog>
    </div>
  )
}

export default memo(RecordingCard)
