import { useCallback, useEffect, useState } from 'react'
import { stopStream } from '../stopStream'
import { logger } from '../../log/Log'
import { useIsMounted } from '../../hooks/useIsMounted'

export enum GetUserMediaError {
  NotFoundError,
  NotAllowedError
}

/**
 * Represents the result returned by {@link useMediaStream}
 */
interface MediaStreamResult {
  isInProgress: boolean
  error: Error | null
  stream: MediaStream | null
}

/**
 * Return type of the {@link useMediaStream}.
 */
export type UseMediaStreamResult = [
  (constrains: MediaStreamConstraints) => Promise<MediaStream | null>,
  MediaStreamResult,
  () => void
]

/**
 * Creates a media stream using provided constraints.
 * Stream will be automatically stopped when component is unmounted.
 */
export const useMediaStream = (): UseMediaStreamResult => {
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [isInProgress, setIsInProgress] = useState(false)
  const isMounted = useIsMounted()

  // cleanup created stream
  useEffect(() => {
    return () => {
      if (stream) {
        logger.log('Cleaning up stream.', stream)

        stopStream(stream)
      }
    }
  }, [stream])

  const clearMediaStream = useCallback(() => {
    setStream(null)
  }, [setStream])

  const executeGetMediaStream = useCallback(
    async (constraints: MediaStreamConstraints): Promise<MediaStream | null> => {
      try {
        setIsInProgress(true)

        const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
        if (!isMounted.current) {
          return null
        }

        setStream(mediaStream)
        setIsInProgress(false)

        logger.log(`Created media stream for constraints ${JSON.stringify(constraints, null, 2)}`)

        return mediaStream
      } catch (error) {
        logger.error(error)
        setStream(null)
        setIsInProgress(false)
        setError(error)

        return null
      }
    },
    [setStream, setError, isMounted]
  )

  return [executeGetMediaStream, { stream, error, isInProgress }, clearMediaStream]
}
