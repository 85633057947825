import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  joinInputErrors,
  useFormValidation,
  useCustomValidation,
  useTriggerValidation
} from '../../../Common/utils/formUtils'

export interface ChangePasswordFormData {
  password: string
  confirmPassword: string
}

export const useChangePasswordForm = (onSubmit: (formData: ChangePasswordFormData) => void) => {
  const formMethods = useForm()
  const { watch, handleSubmit } = formMethods

  const inputs = useFormValidation(formMethods, {
    password: {
      required: 'Password can not be empty'
    },
    confirmPassword: {
      required: 'Please confirm password',
      validate: (value) => value === watch('password') || 'Passwords do not match!'
    }
  })

  const [passwordStrengthError, setPasswordStrength] = useCustomValidation(formMethods, {
    name: 'passwordStrength',
    defaultValue: 0,
    rules: {
      validate: (value) => value >= 3 || 'Password not strong enough'
    }
  })

  const password = watch('password')
  useTriggerValidation(formMethods, 'confirmPassword', [password])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit(({ password, confirmPassword }) => {
        const formData = { password, confirmPassword }
        onSubmit(formData)
      }),
    [onSubmit, handleSubmit]
  )

  return {
    handleSubmit: _handleSubmit,
    inputs: {
      ...inputs,
      password: joinInputErrors(inputs.password, passwordStrengthError)
    },
    password: {
      value: password,
      setStrength: setPasswordStrength
    }
  }
}
