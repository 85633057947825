import { Grid, Button, Box } from '@material-ui/core'
import React from 'react'
import { useRoom } from '../../../../Providers/RoomProvider'
import CardText from '../../../CardText/CardText'
import CardTitle from '../../../CardTitle/CardTitle'

interface ClearSuccessProps {
  onClose: () => void
}

const ClearChatSuccess: React.FC<ClearSuccessProps> = ({ onClose }) => {
  const room = useRoom()
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardTitle>Success!</CardTitle>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Box maxWidth={380}>
          <CardText>You have successfully cleared the chat for room {room.displayName}.</CardText>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button color="secondary" variant="contained" fullWidth onClick={onClose}>
          Close
        </Button>
      </Grid>
    </Grid>
  )
}

export default React.memo(ClearChatSuccess)
