/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Room_room = {
    readonly id: unknown;
    readonly displayName: string;
    readonly " $fragmentRefs": FragmentRefs<"LiveRoom_room" | "Lobby_room">;
    readonly " $refType": "Room_room";
};
export type Room_room$data = Room_room;
export type Room_room$key = {
    readonly " $data"?: Room_room$data;
    readonly " $fragmentRefs": FragmentRefs<"Room_room">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Room_room",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LiveRoom_room"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Lobby_room"
    }
  ],
  "type": "Liveroom",
  "abstractKey": null
};
(node as any).hash = '5b8443184bcafdada252ba442510fac5';
export default node;
