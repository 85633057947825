import React, { useState } from 'react'
import { BaseDialog, DefaultDialogHeader, DefaultDialogFooter } from '../../Dialogs'
import AppleTVApprove from '../AppleTVApprove'
import { useDialog } from '../../../Components/RoomDialog/hooks/useDialog'
import { useApproveConnectingAppleTV } from '../hooks/useApproveConnectingAppleTV'
import { useAppleTVConnectingUpdatesSubscription } from '../hooks/useAppleTVConnectingUpdatesSubscription'
interface AppleTVApproveDialogProps {
  classNames?: string
  room: {
    id: string
    hash: string
  }
}

const AppleTVApproveDialog: React.FC<AppleTVApproveDialogProps> = ({ room }) => {
  const [isOpen, openDialog, closeDialog] = useDialog()
  const [appleTVName, updateAppleTvName] = useState('')
  const {
    isInProgress,
    isApproving,
    updateDeviceCodeId,
    approveAppleTV,
    denyAppleTV
  } = useApproveConnectingAppleTV(closeDialog)

  useAppleTVConnectingUpdatesSubscription(
    room.id,
    updateDeviceCodeId,
    updateAppleTvName,
    openDialog
  )

  return (
    <BaseDialog
      isOpen={isOpen}
      fullWidth
      maxWidth="sm"
      header={<DefaultDialogHeader text="Apple TV Request Access" divider />}
      footer={<DefaultDialogFooter />}
    >
      <AppleTVApprove
        approve={approveAppleTV}
        deny={denyAppleTV}
        isInProgress={isInProgress}
        isApproving={isApproving}
        appleTVName={appleTVName}
      />
    </BaseDialog>
  )
}

export default React.memo(AppleTVApproveDialog)
