import { Observable } from 'rxjs'
import { RecordTimerEvent } from './RecordingTimer'

export interface RecordingInfo {
  id?: string
  getRecordingCurrentTime: () => number
  isRecording: boolean
  onRecordingTimerUpdate?: () => Observable<RecordTimerEvent>
}

export const defaultRecordingInfo = {
  getRecordingCurrentTime: () => 0,
  isRecording: false
}
