/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecordingsViewQueryVariables = {
    roomId: unknown;
};
export type RecordingsViewQueryResponse = {
    readonly room: {
        readonly " $fragmentRefs": FragmentRefs<"RecordingsView_room">;
    } | null;
    readonly currentProfile: {
        readonly " $fragmentRefs": FragmentRefs<"RecordingsView_currentProfile">;
    } | null;
    readonly iceServers: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"RecordingsView_iceServers">;
    } | null> | null;
};
export type RecordingsViewQuery = {
    readonly response: RecordingsViewQueryResponse;
    readonly variables: RecordingsViewQueryVariables;
};



/*
query RecordingsViewQuery(
  $roomId: UUID!
) {
  room: liveroomById(id: $roomId) {
    ...RecordingsView_room
  }
  currentProfile {
    ...RecordingsView_currentProfile
  }
  iceServers: getIceServers {
    ...RecordingsView_iceServers
  }
}

fragment Playback_iceServers on IceServer {
  urls
  username
  credential
}

fragment RecordingsView_currentProfile on Profile {
  id
}

fragment RecordingsView_iceServers on IceServer {
  ...Playback_iceServers
}

fragment RecordingsView_room on Liveroom {
  sessions: sessionsByRoomId(orderBy: [CREATED_AT_DESC], condition: {deletedAt: null}) {
    nodes {
      id
      recordingId
      displayName
      startedAt
      stoppedAt
      createdAt
      deletedAt
    }
  }
  id
  creatorId
  organizationId
  hash
  featureFlags {
    nodes {
      key
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "roomId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecordingsViewQuery",
    "selections": [
      {
        "alias": "room",
        "args": (v1/*: any*/),
        "concreteType": "Liveroom",
        "kind": "LinkedField",
        "name": "liveroomById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecordingsView_room"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecordingsView_currentProfile"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "iceServers",
        "args": null,
        "concreteType": "IceServer",
        "kind": "LinkedField",
        "name": "getIceServers",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RecordingsView_iceServers"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecordingsViewQuery",
    "selections": [
      {
        "alias": "room",
        "args": (v1/*: any*/),
        "concreteType": "Liveroom",
        "kind": "LinkedField",
        "name": "liveroomById",
        "plural": false,
        "selections": [
          {
            "alias": "sessions",
            "args": [
              {
                "kind": "Literal",
                "name": "condition",
                "value": {
                  "deletedAt": null
                }
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": [
                  "CREATED_AT_DESC"
                ]
              }
            ],
            "concreteType": "SessionsConnection",
            "kind": "LinkedField",
            "name": "sessionsByRoomId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Session",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recordingId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stoppedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "sessionsByRoomId(condition:{\"deletedAt\":null},orderBy:[\"CREATED_AT_DESC\"])"
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creatorId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hash",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ColumnRoomFeatureFlagsConnection",
            "kind": "LinkedField",
            "name": "featureFlags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ColumnRoomFeatureFlagsRecord",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "currentProfile",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "iceServers",
        "args": null,
        "concreteType": "IceServer",
        "kind": "LinkedField",
        "name": "getIceServers",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "urls",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "credential",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d12c6241043455a34ec650c349f8547",
    "id": null,
    "metadata": {},
    "name": "RecordingsViewQuery",
    "operationKind": "query",
    "text": "query RecordingsViewQuery(\n  $roomId: UUID!\n) {\n  room: liveroomById(id: $roomId) {\n    ...RecordingsView_room\n  }\n  currentProfile {\n    ...RecordingsView_currentProfile\n  }\n  iceServers: getIceServers {\n    ...RecordingsView_iceServers\n  }\n}\n\nfragment Playback_iceServers on IceServer {\n  urls\n  username\n  credential\n}\n\nfragment RecordingsView_currentProfile on Profile {\n  id\n}\n\nfragment RecordingsView_iceServers on IceServer {\n  ...Playback_iceServers\n}\n\nfragment RecordingsView_room on Liveroom {\n  sessions: sessionsByRoomId(orderBy: [CREATED_AT_DESC], condition: {deletedAt: null}) {\n    nodes {\n      id\n      recordingId\n      displayName\n      startedAt\n      stoppedAt\n      createdAt\n      deletedAt\n    }\n  }\n  id\n  creatorId\n  organizationId\n  hash\n  featureFlags {\n    nodes {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b182e42e3794f90a086e3382ef92fe16';
export default node;
