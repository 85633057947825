import { useEffect, useRef } from 'react'

interface Params {
  eventName: string
  handler: (event: Event) => any
  element?: any
}

export const useEventListener = ({ eventName, handler, element = window }: Params) => {
  const savedHandler = useRef(handler)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isAddEventListenerSupported = element && element.addEventListener
    if (!isAddEventListenerSupported) return

    const eventListener = (event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
