import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Box, Typography } from '@material-ui/core'

import './TwoFactorConfigurationMessage.scss'
import {
  ConfigurationMessageState,
  useTwoFactorConfigurationMessageProps,
} from './hooks/useTwoFactorConfigurationMessageProps'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { routes } from '../../routes'

interface TwoFactorConfigurationMessageProps {
  state: ConfigurationMessageState
}

/**
 * Evercast two factor configuration messages
 * @constructor
 */
const TwoFactorConfigurationMessage: React.FC<TwoFactorConfigurationMessageProps> = ({ state }) => {
  const { configurationProps } = useTwoFactorConfigurationMessageProps(state)

  return (
    <>
      <Grid container className="two-factor-message-view">
        <Grid item xs={12}>
          <Box>
            {configurationProps && (
              <>
                <Box>
                  <Typography gutterBottom variant="h4">
                    {configurationProps.title}
                  </Typography>
                </Box>
                <Box pt={1} pb={3}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="p"
                    align="left"
                    color="textSecondary"
                  >
                    {configurationProps.description}
                  </Typography>
                </Box>
                <Box display="flex">
                  {state === ConfigurationMessageState.TOKEN_EXPIRED && (
                    <Box mr={3}>
                      <Link to={routes.dashboard.path} rel="noopener noreferrer">
                        <ProgressButton
                          color="primary"
                          variant="outlined"
                          isLoading={false}
                          size="medium"
                        >
                          Cancel
                        </ProgressButton>
                      </Link>
                    </Box>
                  )}
                  <Box>
                    <Link to={configurationProps.routeTo}>
                      <ProgressButton
                        color="primary"
                        isLoading={false}
                        size="medium"
                        className="continue"
                        autoFocus={true}
                      >
                        {configurationProps.buttonText}
                      </ProgressButton>
                    </Link>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(TwoFactorConfigurationMessage)
