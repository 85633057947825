import React from 'react'
import ChangePassword from '../../../../Components/ChangePassword/ChangePassword'
import { routes } from '../routes'
import ChangePasswordSuccess from './ChangePasswordSuccess/ChangePasswordSuccess'

/**
 * Change password unAuthenticated view acessible at {@link routes.changePassword.path}
 */
const UnauthenticatedChangePasswordView: React.FC = () => {
  return (
    <ChangePassword backLink={{ path: routes.login.path, text: 'Return to sign in' }} successComponent={<ChangePasswordSuccess />} />
  )
}

export default React.memo(UnauthenticatedChangePasswordView)
