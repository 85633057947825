import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import './TwoFactorConfigurationValidEmailSuccess.scss'
import ProgressButton from '../../../../../Components/ProgressButton/ProgressButton'
import { routes } from '../../routes'
import { TwoFactorConfigurationVerificationState } from '../TwoFactorConfigurationView'
import { tabsValues } from '../../AccountView/AccountView'
import { Link } from 'react-router-dom'

interface TwoFactorConfigurationProps {
  handleContinue: (step: TwoFactorConfigurationVerificationState) => void
}

/**
 * Evercast verify two factor authentication success view
 * @constructor
 */
const TwoFactorConfigurationValidEmailSuccess: React.FC<TwoFactorConfigurationProps> = ({
  handleContinue,
}) => {
  return (
    <Grid container className="two-factor-success-view">
      <Grid item xs={12}>
        <Box mb={5}>
          <Typography gutterBottom variant="h4">
            Set up multi-factor authentication
          </Typography>
        </Box>

        <Box>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" align="left">
                STEP 1
              </Typography>
              <Box paddingY={1}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="p"
                  align="left"
                  color="textSecondary"
                >
                  Download and install the Google Authenticator app from the Apple App Store or
                  Google Play App Store.
                </Typography>
              </Box>
            </Grid>
          </Grid>
  
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" align="left">
                STEP 2
              </Typography>
              <Box paddingY={1}>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="p"
                  align="left"
                  color="textSecondary"
                >
                  Open the authentication app and scan the QR code shown on the next page. For
                  security reasons, don't show this QR code to anyone.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box marginTop={6}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" ml={0}>
                  <Box mr={3}>
                    <Link
                      to={routes.account.path.replace(':tab', tabsValues.settings)}
                      rel="noopener noreferrer"
                    >
                      <ProgressButton
                        color="primary"
                        variant="outlined"
                        isLoading={false}
                        size="medium"
                        fullWidth
                      >
                        Cancel
                      </ProgressButton>
                    </Link>
                  </Box>
                  <Box>
                    <ProgressButton
                      color="primary"
                      isLoading={false}
                      size="medium"
                      onClick={() =>
                        handleContinue(TwoFactorConfigurationVerificationState.stepTwo)
                      }
                      autoFocus={true}
                      fullWidth
                      className="continue"
                    >
                      Next
                    </ProgressButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(TwoFactorConfigurationValidEmailSuccess)
