import { useMemo } from 'react'
import { useAudioMixer } from '../../../../../../../Providers/AudioMixerProvider'
import { ParticipantType } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'

const useVolume = (participant) => {
  const { getParticipantVolume, masterVolume, streamVolume } = useAudioMixer()
  return useMemo(
    () =>
      participant.type === ParticipantType.Ebs
        ? streamVolume
        : getParticipantVolume(participant.id) * masterVolume,
    [getParticipantVolume, participant.id, participant.type, streamVolume, masterVolume]
  )
}

export default useVolume
