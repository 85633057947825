import React, { useEffect } from 'react'
import { Button, Box } from '@material-ui/core'
import './MutedDialog.scss'

interface Props {
  isOpen: boolean
  handleClose: () => void
  microphoneIsEnabled: boolean
}
export const MutedDialog: React.FC<Props> = ({
  isOpen,
  handleClose,
  microphoneIsEnabled
  }) => {

  useEffect(() => {
    if (microphoneIsEnabled && isOpen)  {
      handleClose()
    }  
  }, [microphoneIsEnabled, isOpen, handleClose])

  return (
    <Box className={isOpen ? 'muted-dialog' : ''}>
      Are you talking? Your mic is off. Click the mic to turn it back on.
      <Box display="flex" justifyContent="flex-end" width="100" paddingTop={1}>
        <Button
          variant="text"
          size="medium"
          color="secondary"
          onClick={handleClose}
          data-testid="muted-dialog-close-button">
          CLOSE
        </Button>
      </Box>
    </Box>
  )
}