import { useCallback } from 'react'
import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { useRequestPasswordResetMutation } from '../../../../UnauthenticatedView/ForgotPasswordView/hooks/useRequestPasswordResetMutation'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { logger } from '../../../../../../Common/log/Log'

const defaultErrorText = 'Error during requesting password reset!'

export const useRequestPassword = () => {
  const alert = useAlerts()

  const [
    executeRequestPasswordMutation,
    { error, data, graphQLErrors }
  ] = useRequestPasswordResetMutation()

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
    onError: useCallback(() => {
      alert('Something went wrong!', AlertType.error)
    }, [alert])
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
  }, [error])

  useEffectNonNull(() => {
    if (!data.requestPasswordReset) {
      return
    }

    alert(
      'Please check your email for instructions on how to reset your password',
      AlertType.success
    )
  }, [data])

  const resetPassword = useCallback(async () => {
    try {
      await executeRequestPasswordMutation({ input: { email: '' } })
    } catch (e) {
      logger.error(e)
    }
  }, [executeRequestPasswordMutation])

  return {
    resetPassword
  }
}
