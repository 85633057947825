import { useCallback, useState } from 'react'

import { useAlerts } from '../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../Components/Alert/Alert'
import { UpdateProfileForm } from './useUpdateProfileForm'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { useUpdateProfileMutation } from './useUpdateProfileMutation'
import { useDisplayGraphQLErrorsIfPresent } from '../../../../../../Common/hooks/useDisplayGraphQLErrorsIfPresent'
import { CurrentProfile } from '../SettingsView'
import { logger } from '../../../../../../Common/log/Log'

export enum UpdateProfileState {
  awaiting,
  submitting,
  success
}

const defaultErrorText = 'Error during updating profile name!'

export const useUpdateProfile = (userProfile: CurrentProfile) => {
  const [state, setState] = useState(UpdateProfileState.awaiting)
  const alert = useAlerts()

  const [executeUpdateProfileMutation, { error, data, graphQLErrors }] = useUpdateProfileMutation()

  /**
   * Handle potential mutation errors
   */
  useDisplayGraphQLErrorsIfPresent({
    errorCodesToHandle: [],
    graphQLErrors,
    defaultErrorText,
    onError: useCallback(() => {
      setState(UpdateProfileState.awaiting)
    }, [setState])
  })

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
    setState(UpdateProfileState.awaiting)
  }, [error])

  useEffectNonNull(() => {
    if (!data.updateProfileById) {
      return
    }

    alert('Display name updated successfully', AlertType.success)
    setState(UpdateProfileState.success)
  }, [data])

  const updateProfile = useCallback(
    async (formData: UpdateProfileForm) => {
      setState(UpdateProfileState.submitting)

      const variables = {
        input: {
          id: userProfile.id,
          profilePatch: {
            displayName: formData.displayName
          }
        }
      }

      try {
        await executeUpdateProfileMutation(variables)
      } catch (e) {
        logger.error(e)
      }
    },
    [executeUpdateProfileMutation, setState, userProfile]
  )

  return {
    updateProfileState: state,
    updateProfile
  }
}
