/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MessagingAlertsQueryVariables = {};
export type MessagingAlertsQueryResponse = {
    readonly alerts: {
        readonly nodes: ReadonlyArray<{
            readonly message: string;
            readonly beginsAt: unknown;
            readonly id: unknown;
            readonly expiresAt: unknown | null;
        } | null>;
    } | null;
};
export type MessagingAlertsQuery = {
    readonly response: MessagingAlertsQueryResponse;
    readonly variables: MessagingAlertsQueryVariables;
};



/*
query MessagingAlertsQuery {
  alerts: allAlerts(last: 10, orderBy: [CREATED_AT_ASC, EXPIRES_AT_ASC]) {
    nodes {
      message
      beginsAt
      id
      expiresAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "alerts",
    "args": [
      {
        "kind": "Literal",
        "name": "last",
        "value": 10
      },
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": [
          "CREATED_AT_ASC",
          "EXPIRES_AT_ASC"
        ]
      }
    ],
    "concreteType": "AlertsConnection",
    "kind": "LinkedField",
    "name": "allAlerts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "beginsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allAlerts(last:10,orderBy:[\"CREATED_AT_ASC\",\"EXPIRES_AT_ASC\"])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessagingAlertsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MessagingAlertsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b32206235df67df2b1c6499d131be3c0",
    "id": null,
    "metadata": {},
    "name": "MessagingAlertsQuery",
    "operationKind": "query",
    "text": "query MessagingAlertsQuery {\n  alerts: allAlerts(last: 10, orderBy: [CREATED_AT_ASC, EXPIRES_AT_ASC]) {\n    nodes {\n      message\n      beginsAt\n      id\n      expiresAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6517670e4318789f6b9d226cff07fbc8';
export default node;
