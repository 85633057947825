import React, { memo } from 'react'
import { Button, Grid } from '@material-ui/core'
import { BaseDialog } from '../../Dialogs/BaseDialog/BaseDialog'
import { DefaultDialogHeader } from '../../Dialogs/Headers/DefaultDialogHeader/DefaultDialogHeader'
import { BaseDialogFooter } from '../../Dialogs/Footers/BaseDialogFooter/BaseDialogFooter'
import ProgressButton from '../../ProgressButton/ProgressButton'

interface RoomProps {
  id: string
  displayName: string
}

interface RoomFormProps {
  room: RoomProps
  isOpen: boolean
  handleClose: () => void
  onConfirm: () => void
  isLoading: boolean
}

/**
 * Dialog to delete room owned by the user
 * @param isOpened - Flag that indicates if the dialog is visible or not
 * @param handleClose - Function that handles when the close action is triggered
 * @param onConfirm - Function that handles when the confirm action is triggered
 * @param room - Object that holds the room`s information
 * @param isLoading - Flag that indicates if the confirm action is taking place
 * @constructor
 */
const DeleteOwnRoomDialog: React.FC<RoomFormProps> = ({
  isOpen,
  handleClose,
  onConfirm,
  room,
  isLoading
}) => {
  return (
    <BaseDialog
      isOpen={isOpen}
      onClose={handleClose}
      fullWidth={true}
      header={<DefaultDialogHeader text={'Delete room'} divider={true} onClose={handleClose} />}
      footer={
        <BaseDialogFooter>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <ProgressButton color="secondary" isLoading={isLoading} onClick={onConfirm}>
                Delete
              </ProgressButton>
            </Grid>
          </Grid>
        </BaseDialogFooter>
      }
    >
      This will permanently delete the room: {room.displayName}
    </BaseDialog>
  )
}

export default memo(DeleteOwnRoomDialog)
