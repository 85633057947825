import React from 'react'
import { useRoomAddress } from './hooks/useRoomAddress'
import CopyToClipboardInput from '../../CopyClipboardInput/CopyToClipboardInput'

interface ShareRoomInputProps {
  roomHash: string
  toolTipDuration?: number
}

/**
 * An readonly input that shows the public URL of a room. Allows the user to click in the Icon and copy the content to the clipboard.
 * @param roomHash Shareable content to be shown in the input and copied by the user
 * @param toolTipDuration Duration in miliseconds for the tooltip showing the content was copied
 */
const ShareRoomInput: React.FC<ShareRoomInputProps> = ({ roomHash }) => {
  const roomAddress = useRoomAddress(roomHash)
  return <CopyToClipboardInput content={roomAddress} />
}

export default React.memo(ShareRoomInput)
