import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { MicOffOutlined } from '@material-ui/icons'

import Outline from '../../../../../../../../Components/Outline/Outline'
import { IconSize } from '../StreamPreview'
import palette from '../../../../../../../../Common/theme/colors/palette'

const NoMicrophone = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <Box display={'flex'} flexDirection={'row'} justifyContent="center" alignItems="center">
            <Box
              fontSize={IconSize}
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              m={2}
            >
              <Outline color={palette.root.secondary} thickness={2}>
                <MicOffOutlined fontSize={'inherit'} color="secondary" style={{ padding: 8 }} />
              </Outline>
            </Box>

            <Typography variant={'h5'} align={'center'}>
              Microphone not detected
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={2}
          pr={2}
          maxWidth={800}
        >
          <Typography variant={'body1'} align={'center'}>
            You can still go live without a microphone. However, you won't be able to communicate.
            To enable your microphone,{' '}
            <a href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
              <strong>click here</strong>.
            </a>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default React.memo(NoMicrophone)
