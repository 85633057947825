import { useQueryParams } from '../../../../../Common/hooks/useQueryParams'
import { useVerifyEmailMutation } from './useVerifyEmailMutation'
import { useEffect, useState } from 'react'
import { useEffectNonNull } from '../../../../../Common/hooks/useEffectNonNull'

export enum EmailVerificationState {
  verifying,
  error,
  success
}

export const useVerifyEmailAction = () => {
  const [state, setState] = useState(EmailVerificationState.verifying)
  const { emailConfirmationToken } = useQueryParams()
  const [executeVerifyEmailMutation, { error, data, graphQLErrors }] = useVerifyEmailMutation()

  useEffect(() => {
    executeVerifyEmailMutation({ input: { verificationToken: emailConfirmationToken as string } })
  }, [executeVerifyEmailMutation, emailConfirmationToken])

  useEffectNonNull(() => {
    const { isVerified } = data.verifyEmailAddress?.emailVerificationResult
    setState(isVerified ? EmailVerificationState.success : EmailVerificationState.error)
  }, [setState, data])

  useEffectNonNull(() => {
    setState(EmailVerificationState.error)
  }, [setState, error])

  useEffectNonNull(() => {
    setState(EmailVerificationState.error)
  }, [setState, graphQLErrors])

  return {
    state
  }
}
