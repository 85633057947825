import { Button } from '@material-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSpecialInvite } from '../hooks/useSpecialInvite'
import { useSpecialInviteActivate } from '../hooks/useSpecialInviteActivate'

interface RoomSpecialInviteButtonProps {
  roomHash: string
  roomId: string
  toolTipDuration?: number
}

/**
 * An readonly input that generates a special invite code and appends it to the room url.
 * Allows the user to click the generate text and copy a link to their clipboard.
 * @param roomHash Shareable content to be shown in the input and copied by the user
 * @param toolTipDuration Duration in miliseconds for the tooltip showing the content was copied
 */
const RoomSpecialInviteButton: React.FC<RoomSpecialInviteButtonProps> = ({ roomId, roomHash }) => {
  const [roomAddress, setRoomAddress] = useState('')
  const [specialInviteCode, setSpecialInviteCode] = useState('')

  const generateInvite = useSpecialInvite(roomId, roomHash, setRoomAddress, setSpecialInviteCode)
  const activateSpecialInvite = useSpecialInviteActivate()

  const handleClicked = useCallback(async () => {
    if (specialInviteCode) {
      activateSpecialInvite(roomId, specialInviteCode)
    }
  
    generateInvite()
    }, [generateInvite, activateSpecialInvite, specialInviteCode, roomId])

  useEffect(() => {
		let active = true

		if (active && roomHash) {
			handleClicked()
		}

		return () => {
			active = false
		}
	}, [roomHash])

  return <CopyToClipboard 
            options={{
                format: 'text/plain'
            }}
            text={roomAddress}
            onCopy={handleClicked}
            >
                <Button variant="text" size="small">Generate single-use link</Button>
        </CopyToClipboard>
}

export default React.memo(RoomSpecialInviteButton)
