import React from 'react'
import { Button, Card, Box, Grid } from '@material-ui/core'
import Container from '../Container/Container'
import Logo from '../Logo/Logo'

import './AppError.scss'
import CardTitle from '../CardTitle/CardTitle'
import CardText from '../CardText/CardText'

/**
 * Top level error handler. Displayed when one of the components throws uncaught error.
 */
const AppError: React.FC = () => {
  return (
    <Box className="app-error">
      <Box className="app-error-logo-container">
        <Logo className="app-error-logo" forceReload={true} />
      </Box>
      <Box>
        <Container component={Card}>
          <Grid container spacing={4} justify="center">
            <Grid item xs={12}>
              <CardTitle>Oops.</CardTitle>
            </Grid>
            <Grid item xs={12}>
              <CardText>Something went wrong.</CardText>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  // this is non recoverable error so what else can we do?
                  window.location.reload()
                }}
              >
                Try again
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default React.memo(AppError)
