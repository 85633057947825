import { useMemo } from 'react'
import zxcvbn from 'zxcvbn'

/**
 * Hook for getting strength value of a given password
 *
 * @param password
 * @returns password strength [0-4]
 */
export const usePasswordStrength = (password) => {
  return useMemo(() => {
    return zxcvbn(password != null ? password : '').score
  }, [password])
}
