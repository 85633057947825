import React from 'react'
import { ReactComponent as RecordingEnabled } from '../../../../Static/images/recording_enabled.svg'
import { ReactComponent as RecordingDisabled } from '../../../../Static/images/recording_disabled.svg'
import classnames from 'classnames'

import SquareIconLabelButton from '../../../Buttons/SquareIconLabelButton/SquareIconLabelButton'

import './RecordSessionButton.scss'

interface RecordSessionButtonProps {
  isRecording: boolean
  isDisabled: boolean
  onClick: () => void
}

const RecordSessionButton: React.FC<RecordSessionButtonProps> = ({
  isDisabled,
  isRecording,
  onClick,
}) => {
  const label =
    isRecording && isDisabled ? 'Recording in Progress' : isRecording ? 'Stop recording' : 'Record'
  return (
    <SquareIconLabelButton
      icon={isRecording ? RecordingEnabled : RecordingDisabled}
      activeTooltipTitle="Stop recording"
      inactiveTooltipTitle="Start recording"
      testId="record-session-button"
      onClick={onClick}
      isDisabled={isDisabled}
      className={classnames({
        'record-session-button-recording': isRecording,
      })}
    >
      {label}
    </SquareIconLabelButton>
  )
}

export default React.memo(RecordSessionButton)
