/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RecordingsView_currentProfile = {
    readonly id: unknown;
    readonly " $refType": "RecordingsView_currentProfile";
};
export type RecordingsView_currentProfile$data = RecordingsView_currentProfile;
export type RecordingsView_currentProfile$key = {
    readonly " $data"?: RecordingsView_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"RecordingsView_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecordingsView_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '91a4af1b4d50515a46401686c8db0986';
export default node;
