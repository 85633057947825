import React, { useCallback } from 'react'
import { Button, SvgIconProps, Typography, Tooltip } from '@material-ui/core'
import classnames from 'classnames'
import './SquareIconLabelButton.scss'

interface SquareIconLabelButtonProps {
  isActive?: boolean
  onClick?: (...args: any) => void
  onToggle?: (isActive: boolean) => void
  icon: React.ComponentType<SvgIconProps> | React.FC
  className?: string
  children: React.ReactNode
  testId?: string | null
  isDisabled?: boolean
  activeTooltipTitle?: string
  inactiveTooltipTitle?: string
}

/**
 * This component is basically the Button from Material UI
 * but it adds support to use an icon above the label
 * instead of only on the left-right side
 * @param icon custom icon component for button
 * @param children is the label of the button
 * @param onClick action that will be triggered when the button is clicked
 * @param className custom classNames for the button
 * @param testId test id
 * @param isEnabled
 * @constructor
 */
const SquareIconLabelButton: React.FC<SquareIconLabelButtonProps> = ({
  isActive,
  icon,
  children,
  className,
  testId,
  onToggle,
  onClick,
  isDisabled,
  activeTooltipTitle = '',
  inactiveTooltipTitle = '',
}) => {
  const Icon = icon
  const toolTipTitle = isActive ? activeTooltipTitle : inactiveTooltipTitle

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (onToggle) onToggle(!isActive)
      if (onClick) onClick(event)
    },
    [onToggle, isActive, onClick]
  )

  return (
    <Tooltip title={toolTipTitle}>
      <Button
        data-testid={testId}
        className={classnames('square-icon-label-button', className)}
        onClick={handleClick}
        disabled={isDisabled}
        startIcon={<Icon />}
      >
        <Typography variant="button" style={{ marginTop: 4 }}>
          {children}
        </Typography>
      </Button>
    </Tooltip>
  )
}

export default React.memo(SquareIconLabelButton)
