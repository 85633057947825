import { LogTransport } from '../Log'

/**
 * Binds transport to console
 */
export class ConsoleTransport implements LogTransport {
  log = console.log.bind(console)
  info = console.info.bind(console)
  debug = console.debug.bind(console)
  warn = console.warn.bind(console)
  error = console.error.bind(console)
}
