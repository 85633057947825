import { useEffect } from 'react'
import JanusInit from '../lib/janusInit'

/**
 * Initializes Janus library, should be called before using it ie. before joining the room or using playback
 */
export function useJanusInit(): void {
  // initializes Janus
  useEffect(() => {
    JanusInit()
  }, [])
}
