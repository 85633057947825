import React from 'react'
import { ReactComponent as Exit } from '../../../../Static/images/exit.svg'
import { Popover, Box, Button, Typography } from '@material-ui/core'

import './LeaveRoomButton.scss'
import SquareIconLabelButton from '../../../Buttons/SquareIconLabelButton/SquareIconLabelButton'

interface LeaveRoomButtonProps {
  onClick: () => void
}

const LeaveRoomButton: React.FC<LeaveRoomButtonProps> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <SquareIconLabelButton icon={Exit} onClick={handleClick}>
        Exit
      </SquareIconLabelButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          root: 'leave-room-popover'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Box p={2}>
          <Box pt={1} pb={1.5} pl={4} pr={4}>
            <Typography variant="body1" align="center" data-testid="leave-room-dialog-title">
              Exit the room?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" paddingY={1}>
            <Button variant="text" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="text" color="secondary" onClick={onClick}>
              OK
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default React.memo(LeaveRoomButton)
