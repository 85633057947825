import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import Outline from '../../../../../../../../Components/Outline/Outline'
import palette from '../../../../../../../../Common/theme/colors/palette'
import { MicOffOutlined, VideocamOffOutlined } from '@material-ui/icons'
import { IconSize } from '../StreamPreview'

type Props = {
  isCameraPermissionGranted?: boolean
  isMicrophonePermissionGranted?: boolean
}

export const NoDevicesPermission: React.FC<Props> = ({
  isCameraPermissionGranted,
  isMicrophonePermissionGranted,
}) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
      <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
        {!isCameraPermissionGranted && (
          <Grid item>
            <Box display={'flex'} flexDirection={'row'} justifyContent="center" alignItems="center">
              <Box
                fontSize={IconSize}
                display="inline-flex"
                justifyContent="center"
                alignItems="center"
                m={2}
              >
                <Outline color={palette.root.secondary} thickness={2}>
                  <VideocamOffOutlined
                    fontSize={'inherit'}
                    color="secondary"
                    style={{ padding: 8 }}
                  />
                </Outline>
              </Box>

              <Typography variant={'h5'} align={'center'}>
                Camera is off
              </Typography>
            </Box>
          </Grid>
        )}

        {!isMicrophonePermissionGranted && (
          <Grid item>
            <Box display={'flex'} flexDirection={'row'} justifyContent="center" alignItems="center">
              <Box
                fontSize={IconSize}
                display="inline-flex"
                justifyContent="center"
                alignItems="center"
                m={2}
              >
                <Outline color={palette.root.secondary} thickness={2}>
                  <MicOffOutlined fontSize={'inherit'} color="secondary" style={{ padding: 8 }} />
                </Outline>
              </Box>

              <Typography variant={'h5'} align={'center'}>
                Microphone is off
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={2}
          pr={2}
          maxWidth={800}
        >
          <Typography variant={'body1'} align={'center'}>
            To turn on your camera or microphone, click on the corresponding buttons above the live
            button.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
