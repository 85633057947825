import React, { useState, useEffect, Suspense } from 'react'
import NoteMessages, { NoteMessagesProps } from './NoteMessages'
import ChatMessages, { ChatMessagesProps } from './ChatMessages'
import { Message } from '../../../../Models/apiEntities'

export enum ExportType {
  Shotgun = "Shotgun",
  CSV = "CSV",
  TextFile = "TextFile"
}

export enum MessageType {
  Chat = "chat",
  Note = "note"
}

export interface DownloaderContainerProps {
  roomHash: string
  messageType: MessageType
  exportType?: ExportType
  startDate?: string
  endDate?: string
  fetchKey?: number
  onQueryLoad?: (messages: Message[]) => void
  fileName?: string
  fileContents?: string
}

const MessagesDownloader = (type, props) => {
  if (type === MessageType.Chat) {
    return <ChatMessages {...props as ChatMessagesProps} />
  } else if (type === MessageType.Note) {
    return <NoteMessages {...props as NoteMessagesProps} />
  } else {
    return <span></span>
  }
}

const DownloaderContainer: React.FC<DownloaderContainerProps> = (props) => {
  const [messageType, setMessageType] = useState('')
  const [messagesProps, setMessagesProps] = useState<ChatMessagesProps|NoteMessagesProps|undefined>()

  useEffect(() => {
    if (props.exportType !== undefined && props.startDate !== undefined) {
      setMessageType(props.messageType)
      setMessagesProps({
        roomHash: props.roomHash,
        exportType: props.exportType,
        startDate: props.startDate,
        endDate: props.endDate,
        fetchKey: props.fetchKey,
        onQueryLoad: props.onQueryLoad,
        fileName: props.fileName,
        fileContents: props.fileContents
      })
    }
  }, [props])

  return <Suspense fallback={<span></span>}>
    { MessagesDownloader(messageType, messagesProps) }
  </Suspense>
}

export default React.memo(DownloaderContainer)