import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useEffectNonNull } from '../../../../../../Common/hooks/useEffectNonNull'
import { logger } from '../../../../../../Common/log/Log'
import { useQueryParams } from '../../../../../../Common/hooks/useQueryParams'
import {
  useSpecialInviteValidation,
} from '../../../../../../Components/RoomAccess/RoomSpecialInviteInput/hooks/useSpecialInviteValidation'
import { useSession } from '../../../../hooks/useSession'
import { SpecialInviteValidationResponse } from '../../../../../../API/useApi'
import { useRoomLevelMFA } from './useRoomLevelMFA'
import { useRoomInfoAndAccess } from './useRoomInfoAndAccess'

export enum RoomState {
  Fetching,
  Lobby,
  LiveRoom,
  MFAInput,
  DndMode,
}

export enum RoomErrorState {
  AccessDenied,
  NotFound,
  Deleted,
  InviteUsed,
  InviteInvalid,
  PrivateRoomMissingMFA,
}

// TODO: react-router-dom types should have this type, but it's not working
interface UseParams {
  hash: string
}

/**
 * Gets room information from API via roomAccess and calculates the proper room state.
 * Redirects to one of the `error` states [deleted, error, access-denied, not-found] if needed.
 * @param retry
 * @param room
 */
export const useRoomRoutingState = ({ retry, room }) => {
  let { hash } = useParams<UseParams>()
  if (room && room.hash) {
    hash = room.hash
  }
  const { invite } = useQueryParams()
  const { userProfile } = useSession()

  const [roomRoutingState, setRoomRoutingState] = useState(RoomState.Fetching)
  const [errorState, setErrorState] = useState<RoomErrorState | null>(null)

  const { isLoading, data: roomInfo, errorMessage, getRoomAccess } = useRoomInfoAndAccess({ hash })
  const { isMFAValid, verifyMFA } = useRoomLevelMFA()
  const { isInviteValid, handleExistingUser } = useSpecialInviteValidation(invite, room?.roomId)

  useEffect(() => {
    if (!isLoading) getRoomAccess()
  }, [hash, isMFAValid,])
  /**
   * Check user access to the room, see if it can join
   * 1. If we can't, set correct error/input needed state
   * 2. If we can, move on to the lobby
   */
  useEffectNonNull(() => {
    if (isLoading) return

    // check for room errors
    if (errorMessage) {
      if (errorMessage?.includes('Error code 40400')) {
        logger.log('Room not found.')
        setErrorState(RoomErrorState.NotFound)
        return
      }

      if (errorMessage?.includes('Error code 40401')) {
        logger.log('Room was deleted.')
        setErrorState(RoomErrorState.Deleted)
        return
      }
    }

    // should always have room info here
    if (!roomInfo) {
      logger.log('Retrying the top level query to get the room details...')
      retry()
      return
    }

    // let's check causes for not being able to join the room
    if (!roomInfo?.user?.canJoinRoom) {

      // user does not have access to room -> Standard access denied
      if (!roomInfo?.user?.hasAccess) {
        setErrorState(RoomErrorState.AccessDenied)
        return
      }
      // just validated MFA, check again
      if (isMFAValid && !roomInfo?.room.roomState.isDndActive) return

      // have an invite
      if (invite) {
        if ( userProfile !== null) {
          logger.log('Logging out existing user accepting invite...')
          handleExistingUser()
          return
        }

        // Invite is invalid
        if ((isInviteValid as SpecialInviteValidationResponse)?.valid !== true) {
          if ((isInviteValid as SpecialInviteValidationResponse)?.isUsed) {
            // Invite was valid but already used by another user
            setErrorState(RoomErrorState.InviteUsed)
            return
          } else {
            // Invite has either expired, or entirely invalid
            setErrorState(RoomErrorState.InviteInvalid)
            return
          }
        }
      }

      // room is MFA protected and user not exempt by using org sso
      if (roomInfo?.room.roomState.isMFAProtected && !(roomInfo?.user?.didUserSignInWithSSO || roomInfo?.user?.isMFAValid)) {
        if (!roomInfo?.user?.hasMFASetUp ) {
          setErrorState(RoomErrorState.PrivateRoomMissingMFA)
          return
        } else if ( !isMFAValid) {
          setRoomRoutingState(RoomState.MFAInput)
          return
        }
      }

      // room is do not disturb mode
      if (roomInfo?.user?.hasAccess && !!roomInfo?.room.roomState.isDndActive) {
        setRoomRoutingState(RoomState.DndMode)
        return
      }

      // if neither of the previous cases
      logger.warn('Can not join the room!')
      setErrorState(RoomErrorState.AccessDenied)
      return
    }

    logger.log('Joined the room, showing the lobby.')
    setRoomRoutingState(RoomState.Lobby)
  }, [
    hash, isLoading, isMFAValid,
  ])

  return {
    roomRoutingState,
    errorState,
    verifyMFA,
    getRoomAccess,
    roomInfo,
    actions: {
      handleGoLiveClick: useCallback(() => {
        logger.log('Go live clicked, showing the liveroom.')
        setRoomRoutingState(RoomState.LiveRoom)
      }, [setRoomRoutingState]),
    },
  }
}
