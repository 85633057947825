import React, { createContext, useCallback, useContext, useRef } from 'react'

import { DrawCanvas, DrawCanvasMode } from '../Common/drawtools/DrawCanvas'
import { useEffectNonNull } from '../Common/hooks/useEffectNonNull'
import { useEffectWithPredicate } from '../Common/hooks/useEffectWithPredicate'
import { useRoomFeatureFlagsRest } from '../Common/utils/featureFlags'
import { PlaybackParticipant } from '../Common/janus/clients/playback/PlaybackClient'
import { IDrawCanvas } from '../Common/drawtools/types'

interface IDrawCanvasDisplayProvier extends IDrawCanvas {
  cleanUpCanvases: () => void
}

export const DrawCanvasDisplayProvider = createContext<IDrawCanvasDisplayProvier>(null!)

interface DrawCanvasDisplayContextProviderProps {
  children: React.ReactNode
  activeStreamId: string | null
  participants: PlaybackParticipant[]
}

//@note: This provider is used for the playback recordings
export const DrawCanvasToolsContextProvider = ({
  children,
  participants,
  activeStreamId
}: DrawCanvasDisplayContextProviderProps) => {
  const drawCanvasRef = useRef<DrawCanvas>()
  const RoomFeatureFlags =  useRoomFeatureFlagsRest()

  // Make sure draw canvas is created only once also wait until room feature api is complete
  useEffectWithPredicate({
    predicate: () => !!Object.keys(RoomFeatureFlags).length && !drawCanvasRef.current,
    effect: () => {
      drawCanvasRef.current = new DrawCanvas(
        {},
        {
          drawCanvasMode: DrawCanvasMode.Playback,
          isRoomDrawingEnabled: RoomFeatureFlags['ALLOW_ROOM_DRAW_TOOL'] ?? false
        }
      )
    }
  }, [RoomFeatureFlags])
  
  useEffectNonNull(() => {
    drawCanvasRef.current!.lc.on = () => {}
  }, [drawCanvasRef.current])
  
  useEffectNonNull(() => {
    drawCanvasRef.current!.setActiveStream(activeStreamId)
  }, [activeStreamId, drawCanvasRef.current])

  useEffectNonNull(() => {
    drawCanvasRef.current!.removeCanvasParticipants(participants)
    drawCanvasRef.current!.remotePublishersEventCallback(participants)
  }, [participants, drawCanvasRef.current])

  
  // No warning needed here because if drawCanvasRef.current is not set, cleanup is likely already done or initialization hasn't happened yet.
  const cleanUpCanvases = useCallback(() => {
    if (drawCanvasRef.current) {
      drawCanvasRef.current.clearAllCanvases()
      participants.forEach((participant) => drawCanvasRef.current!.teardownParticipant(participant))
    }
  }, [drawCanvasRef.current, participants])

  const setLocalCanvasNode = useCallback(
    (node, type) => {
      if (drawCanvasRef.current) {
        drawCanvasRef.current.setLocalCanvasNode(node, type)
      } else {
        console.warn('DrawCanvas not initialized, cannot set local canvas node yet')
      }
    },
    [drawCanvasRef.current]
  )

  const setParticipantCanvasNode = useCallback(
    (participant, index, node) => {
      if (drawCanvasRef.current) {
        drawCanvasRef.current.setParticipantCanvasNode(participant, index, node)
      } else {
        console.warn('DrawCanvas not initialized, cannot set participant canvas node yet')
      }
      
    },
    [drawCanvasRef.current]
  )

  const setVideoNode = useCallback(
    (ref) => {
      if (drawCanvasRef.current) {
        drawCanvasRef.current.setVideoNode(ref)
      } else {
        console.warn('DrawCanvas not initialized, cannot set local canvas node yet')
      }
    },
    [drawCanvasRef.current]
  )

  const setThumbnailNode = useCallback(
    (participantId, node, type) => {
      if (drawCanvasRef.current) {
        return drawCanvasRef.current.setThumbnailNode(participantId, node, type)
      } else {
        console.warn('DrawCanvas not initialized, cannot set thumbnail node yet')
        return undefined
      }
    },
    [drawCanvasRef.current]
  )

  return (
    <DrawCanvasDisplayProvider.Provider
      value={{
        setVideoNode,
        cleanUpCanvases,
        setThumbnailNode,
        setLocalCanvasNode,
        setParticipantCanvasNode
      }}
    >
      {children}
    </DrawCanvasDisplayProvider.Provider>
  )
}

export const useDrawCanvasDisplayContext = () => useContext(DrawCanvasDisplayProvider)
