import { Box, Grid, Button } from '@material-ui/core'
import React from 'react'
import CardText from '../../../CardText/CardText'
import CardSubtitle from '../../../CardSubtitle/CardSubtitle'

interface ClearChatWarningProps {
  onContinue: () => void
}

const ClearChatWarning: React.FC<ClearChatWarningProps> = ({ onContinue }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardSubtitle>Delete all chats?</CardSubtitle>
      </Grid>
      <Grid item xs={12} container justify="center">
        <Box maxWidth={380}>
          <CardText>
            All chats in this room will be permanently deleted.
          </CardText>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button color="secondary" variant="contained" fullWidth onClick={onContinue}>
          Delete
        </Button>
      </Grid>
    </Grid>
  )
}

export default React.memo(ClearChatWarning)
