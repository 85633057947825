import React, { createContext, useState, useCallback, useContext, useEffect } from 'react'
import Alert, { AlertType } from '../Components/Alert/Alert'
import { SnackbarOrigin, Snackbar } from '@material-ui/core'

export interface AlertMessage {
  message: string
  alertType: AlertType
}

type AlertFunction = (message: string, alertType: AlertType) => void

const alertsContext = createContext<AlertFunction>(null!)

export interface AlertsProviderProps {
  onAlert?: (alert: AlertMessage) => void
  anchorOrigin?: SnackbarOrigin
}

/**
 * Provide context for notification alerts usage
 * @param anchorOrigin    Screen position to display alerts, bottom-center by default
 * @param onAlert         Callback to be called when a new alert is pushed
 */
export const AlertsProvider: React.FC<AlertsProviderProps> = ({
  children,
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  onAlert
}) => {
  const [canOpen, setCanOpen] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [alert, setAlert] = useState<AlertMessage | null>(null)
  const [awaitingAlert, setAwaitingAlert] = useState<AlertMessage | null>(null)

  const alertFunction = useCallback(
    (message: string, alertType: AlertType) => {
      const alert = { message, alertType }
      setAwaitingAlert(alert)
      if (onAlert) {
        onAlert(alert)
      }
    },
    [setAwaitingAlert, onAlert]
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleExit = useCallback(() => {
    setAlert(null)
    setCanOpen(true)
  }, [setCanOpen, setAlert])

  useEffect(() => {
    if (canOpen && awaitingAlert) {
      setAlert(awaitingAlert)
      setAwaitingAlert(null)
      setIsOpen(true)
      setCanOpen(false)
    }
  }, [canOpen, awaitingAlert, setAlert, setAwaitingAlert, setIsOpen, setCanOpen])

  return (
    <>
      <alertsContext.Provider value={alertFunction}>{children}</alertsContext.Provider>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={isOpen}
        autoHideDuration={5000}
        onExited={handleExit}
        onClose={handleClose}
      >
        <>{alert && <Alert alertType={alert.alertType}>{alert.message}</Alert>}</>
      </Snackbar>
    </>
  )
}

export const useAlerts = () => {
  return useContext(alertsContext)
}
