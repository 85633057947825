import { useCallback, useEffect, useRef, useState } from 'react'
import { PayloadError, UploadableMap } from 'relay-runtime/lib/network/RelayNetworkTypes'
import { useCommitMutation } from './useCommitMutation'
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes'

/**
 * Represents the config that optionally might be passed as a second parameter of {@link useMutation}.
 * Useful when some actions need to be performed when mutation fails/succeeds.
 * Also, a way to go when there's a need for optimistic updates implementation and relay store updates.
 * More information: https://relay.dev/docs/en/mutations#arguments
 */
export interface UseMutationConfig<TData> {
  /**
   * Callback function executed if Relay encounters an error during the request.
   * Usually, something I/O related, network errors etc.
   */
  onError?: ((error: Error) => void) | null
  /**
   * Callback function executed when mutation succeeds.
   * The first parameter is the mutation result.
   * The second parameter - errors, an array containing any errors from the server.
   */
  onCompleted?:
    | ((response: TData, errors: ReadonlyArray<PayloadError> | null | undefined) => void)
    | null
  optimisticResponse?: TData
  optimisticUpdater?: SelectorStoreUpdater<TData> | null
  updater?: SelectorStoreUpdater<TData> | null
  uploadables?: UploadableMap | null
}

export interface GraphQLError extends PayloadError {
  errcode: string | null | undefined
}

/**
 * Represents the mutation state
 */
export interface MutationResult<TData> {
  isInProgress: boolean
  error: Error | null
  graphQLErrors: ReadonlyArray<GraphQLError> | null | undefined
  data: TData | null
}

/**
 * Return type of the {@link useMutation}.
 */
export type UseMutationResult<TVariables, TData> = [
  (variables?: TVariables | null | undefined) => Promise<TData | void>,
  MutationResult<TData>,
  () => void
]

/**
 * Utility hook that simplifies executing mutation on current relay environment.
 *
 * @example usage
 * const [executeGuestLoginMutation, { isInProgress, error, graphQLErrors, data }, cancel] =
 *  useMutation<GuestLoginInput, GuestLoginResponse>(GuestLoginMutation)
 *
 * @param mutation
 * @param config {UseMutationConfig} - optional config used to provide onCompleted/onError callbacks,
 *  optimistic updates implementation
 */
export const useMutation = <TVariables, TData>(
  mutation,
  config: UseMutationConfig<TData> = {} as UseMutationConfig<TData>
): UseMutationResult<TVariables, TData> => {
  const [isInProgress, setIsInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [graphQLErrors, setGraphQLErrors] = useState<
    ReadonlyArray<GraphQLError> | null | undefined
  >(null)
  const [data, setData] = useState<TData | null>(null)
  const isCancelledRef = useRef(false)

  const cancel = useCallback(() => {
    isCancelledRef.current = true
  }, [isCancelledRef])

  // runs when component using this hook unmounts
  useEffect(() => {
    return () => {
      isCancelledRef.current = true
    }
  }, [])

  const commitMutation = useCommitMutation<any>()
  const runMutation = useCallback(
    (variables?: TVariables | null | undefined) => {
      return new Promise<TData | void>((resolve, reject) => {
        setIsInProgress(true)

        const { onCompleted, onError } = config
        commitMutation({
          ...config,
          mutation,
          variables,
          onCompleted: (response, errors) => {
            if (isCancelledRef.current) {
              resolve()
              return
            }

            setIsInProgress(false)
            setData(response)
            setGraphQLErrors(errors as GraphQLError[])

            if (onCompleted) {
              onCompleted(response, errors)
            }

            resolve(response)
          },
          onError: (error) => {
            if (isCancelledRef.current) {
              resolve()
              return
            }

            setIsInProgress(false)
            setError(error)

            if (onError) {
              onError(error)
            }

            reject(error)
          }
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation, commitMutation, setIsInProgress, setData, setError, isCancelledRef]
  )

  return [runMutation, { isInProgress, error, graphQLErrors, data }, cancel]
}
