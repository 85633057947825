import React from 'react'
import CardNotification from '../../../../../Components/CardNotification/CardNotification'
import ErrorIcon from '@material-ui/icons/Error'

interface AcceptInviteErrorProps {
  error: string
}

/**
 *
 * @constructor
 */
const AcceptInviteError: React.FC<AcceptInviteErrorProps> = ({ error }) => {
  return (
    <CardNotification
      icon={<ErrorIcon />}
      title={error}
      description={<></>}
      footer={
        <>
          Need help?&nbsp;
          <a href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
              <strong>Click here</strong>
          </a>
        </>
      }
    />
  )
}

export default AcceptInviteError
