import { useCallback, useEffect, useState } from 'react'
import { head } from 'ramda'

import { useAlerts } from '../../../../../../../Providers/AlertsProvider'
import { AlertType } from '../../../../../../../Components/Alert/Alert'
import { useEffectNonNull } from '../../../../../../../Common/hooks/useEffectNonNull'
import { useDeleteLiveroomMutation } from './useDeleteLiveroomMutation'
import { logger } from '../../../../../../../Common/log/Log'
import { RoomRest } from '../../../../../../../Models/apiEntities'

export enum DeleteLiveroomState {
  awaiting,
  submitting,
  success
}

const defaultErrorText = 'Error deleting room!'

export const useDeleteLiveroom = (room: RoomRest, updateAvailableRoomsAfterDelete) => {
  const [state, setState] = useState(DeleteLiveroomState.awaiting)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const alert = useAlerts()

  const [
    executeDeleteLiveroomMutation,
    { error, graphQLErrors },
    cancel
  ] = useDeleteLiveroomMutation()

  // prevent error with unmounted component after room delete
  useEffect(() => () => cancel(), [cancel])

  const ERROR_CODES = {
    '40901': 'Rooms can\'t be deleted during an active session. Please ask all participants to exit your room and try again.'
  }

  useEffect(() => {
    if (graphQLErrors) {
      const error = head(graphQLErrors) || {}
      const apiError = ERROR_CODES[error.errcode]
      const errorToDisplay = apiError || defaultErrorText

      alert(errorToDisplay, AlertType.error)
      setState(DeleteLiveroomState.awaiting)
    }
  }, [graphQLErrors])

  /**
   * Handle potential I/O errors
   */
  useEffectNonNull(() => {
    alert('Something went wrong!', AlertType.error)
    setState(DeleteLiveroomState.awaiting)
  }, [error])

  const deleteLiveroom = useCallback(async () => {
    setState(DeleteLiveroomState.submitting)

    const variables = {
      input: {
        roomId: room.id
      }
    }

    try {
      await executeDeleteLiveroomMutation(variables)
      updateAvailableRoomsAfterDelete()
    } catch (e) {
      logger.error(e)
    }
  }, [executeDeleteLiveroomMutation, setState, room, updateAvailableRoomsAfterDelete])

  const showDeleteConfirmation = useCallback(async () => {
    setIsDeleteConfirmationOpen(true)
  }, [setIsDeleteConfirmationOpen])

  const closeDeleteConfirmation = useCallback(async () => {
    setIsDeleteConfirmationOpen(false)
  }, [setIsDeleteConfirmationOpen])

  return {
    deleteLiveroomState: state,
    deleteLiveroom,
    showDeleteConfirmation,
    closeDeleteConfirmation,
    isDeleteConfirmationOpen
  }
}
