import {useState, useEffect} from 'react';

export interface Size {
    width: number | undefined;
    height: number | undefined;
  }

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    return windowSize;
}