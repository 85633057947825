import { useEffect, useState, useRef } from 'react'
import Bowser from 'bowser'
import customProtocolCheck from 'custom-protocol-check'
import { getEnvName } from '../../../Common/environments/getEnvName'
import { getEnvironmentConfig } from '../../../Common/config/environmentConfig'
import { matchPath, useLocation } from 'react-router-dom'
import * as authedRoutes from '../AuthenticatedView/routes'
import * as unAuthedRoutes from '../UnauthenticatedView/routes'

const localStorageAppInstalledKey = 'desktop-launch-installed'
function setAppInstalled(installed: boolean) {
  if (installed) {
    localStorage.setItem(localStorageAppInstalledKey, 'true')
  } else {
    localStorage.removeItem(localStorageAppInstalledKey)
  }
}
function getAppInstalled(): boolean {
  return localStorage.getItem(localStorageAppInstalledKey) === 'true'
}

const localStorageDismissedKey = 'desktop-launch-dismissed'
function setDismissedRoom(id: string | false) {
  localStorage.setItem(localStorageDismissedKey, id || '')
}
function wasRoomDismissed(id: string): boolean {
  return localStorage.getItem(localStorageDismissedKey) === id
}

function getAPIHostSubDomain() {
  return new URL(getEnvironmentConfig(getEnvName()).apiUrl).hostname.replace(/(?:\.[^.]+){2}$/, "");
}

interface UseDesktopAppLaunchViewPrams {
  authenticated: boolean
}

/**
 * Determines if should display the desktop app launch view
 */
export const useDesktopAppLaunchView = ({ authenticated }: UseDesktopAppLaunchViewPrams) => {
  const [showLaunchView, setShouldShowLaunchView] = useState<undefined | boolean>(undefined)
  const { pathname } = useLocation()
  const appPossiblyUninstalled = useRef(false)
  const osName = Bowser.getParser(window.navigator.userAgent).getOSName(true)
  const isRoomPath =
    matchPath(pathname, {
      path: authedRoutes.routes.room.path,
      exact: true
    }) ??
    matchPath(pathname, {
      path: unAuthedRoutes.routes.room.path,
      exact: true
    })
  const mayLaunchApp = !!isRoomPath && (osName === 'windows' || osName === 'macos')
  const hash = isRoomPath?.params['hash']
  const roomLink = !!hash ? `evercast://${getAPIHostSubDomain()}/rooms/${hash}` : 'evercast://'

  useEffect(() => {
    // We only set a room as "not dismissed anymore", if the user is authenticated and is
    // currently on any room path, meaning that, once you dismiss the native app, you will
    // not be presented with the app landing page, until you actually join a room logged in...
    // that is, until you join the lobby:
    if (authenticated && mayLaunchApp) {
      setDismissedRoom(false)
    }
  }, [authenticated, mayLaunchApp])

  useEffect(() => {
    if ((document.hasFocus() || getAppInstalled()) && !wasRoomDismissed(hash) && mayLaunchApp) {
      if (getAppInstalled()) {
        setShouldShowLaunchView(true)
      } else {
        setShouldShowLaunchView(undefined)
      }

      setDismissedRoom(false)

      // In chrome, the only way to check for a custom protocol is the onBlur event
      // triggered by the "allow launch" popup or the focus of the application window opening:
      customProtocolCheck(
        roomLink,
        () => {
          // Couldn't detect protocol somehow.
          appPossiblyUninstalled.current = true
          if (!getAppInstalled()) {
            setDismissedRoom(hash)
            window.location.reload()
          }
        },
        () => {
          setShouldShowLaunchView(true)
          setAppInstalled(true)
        },
        // It the app is installed, set enough time to start it in case the user checks "always allow"
        // and the popup triggering "onBlur" doesn't show anymore:
        getAppInstalled() ? 10000 : 1000
      )
    } else {
      setShouldShowLaunchView(false)
    }
  }, [pathname, hash, roomLink, mayLaunchApp])

  const dismissNativeAppLaunch = () => {
    if (appPossiblyUninstalled.current) {
      setAppInstalled(false)
    }
    setDismissedRoom(hash)
    // We need to refresh the location after checking the protocol (Fixes: https://evercast.atlassian.net/browse/FE-2852)
    window.location.reload()
  }

  const retryNativeAppLaunch = () => {
    customProtocolCheck(
      roomLink,
      () => {
        appPossiblyUninstalled.current = true
      },
      undefined,
      3500
    )
  }

  return {
    showLaunchView: !!showLaunchView,
    waitingForProtocol: showLaunchView === undefined,
    dismissNativeAppLaunch,
    retryNativeAppLaunch
  }
}
