import React from 'react'
import classnames from 'classnames'
import { Box } from '@material-ui/core'
import './CardTitle.scss'

export interface CardTitleProps {
  className?: string | null
  icon?: React.ReactNode
  children: React.ReactNode
}

/**
 * Displays children styled as unauthenticated view card title
 * @param className - css classname which allows to customize styles, if needed
 * @param children
 * @constructor
 */
const CardTitle: React.FC<CardTitleProps> = ({ className, children, icon }) => {
  return (
    <span className={classnames('card-title', className)}>
      {icon && (
        <Box component="span" className="card-title-icon" marginRight={1} marginY={-2}>
          {icon}
        </Box>
      )}
      {children}
    </span>
  )
}

export default React.memo(CardTitle)
