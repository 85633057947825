import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M4 10H6.5V12H4V10Z" />
    <path d="M17.5 10H20V12H17.5V10Z" />
    <path d="M13 10H15.5V12H13V10Z" />
    <path d="M8.5 10H11V12H8.5V10Z" />
  </SvgIcon>
)

export default Icon
