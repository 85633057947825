import React from 'react'
import { Box } from '@material-ui/core'

import { Participant } from '../../Common/janus/clients/liveroom/LiveroomClient'
import './MainStreamPlayerCanvases.scss'
import { CanvasNodeType, IDrawCanvas } from '../../Common/drawtools/types'
import { videoWrapperClassName } from '../Watermark/toggleWatermark'

interface DrawerCanvasProps
  extends Pick<IDrawCanvas, 'setLocalCanvasNode' | 'setParticipantCanvasNode'> {
  participants: Pick<Participant, 'id'>[]
  isDrawerOpen?: boolean
}

/**
 * MainStreamPlayerCanvases adds all necessary canvas so the user can draw on the main stream
 * @param children,
 * @param participants array of participants,
 * @param setLocalCanvasNode function to set the publisher canvas node,
 * @param setParticipantCanvasNode function to set the participants canvas node,
 * @param isDrawerOpen flag that indicates if pointer should be initial or none
 * @constructor
 */
const MainStreamPlayerCanvases: React.FC<DrawerCanvasProps> = ({
  children,
  participants,
  setLocalCanvasNode,
  setParticipantCanvasNode,
  isDrawerOpen,
}) => (
  <Box
    width="100%"
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    minHeight={0}
    minWidth={0}
    position="relative"
    className={videoWrapperClassName} // this className is used to align the watermark
  >
    {children}
    <div
      className="video-room-canvas"
      data-testid="video-room-canvas"
      style={{
        pointerEvents: isDrawerOpen ? 'initial' : 'none',
      }}
      ref={(ref) => setLocalCanvasNode(ref, CanvasNodeType.Main)}
    />
    <div
      className="buffer-canvas"
      data-testid="buffer-canvas"
      ref={(ref) => setLocalCanvasNode(ref, CanvasNodeType.Buffer)}
    />
    {participants.map((participant) => (
      <canvas
        className="participant-canvas"
        data-testid="participant-canvas"
        id={`remote-canvas-${participant.id}`}
        key={participant.id}
        ref={(ref) => setParticipantCanvasNode(participant, participant.id, ref)}
      />
    ))}
  </Box>
)
export default MainStreamPlayerCanvases
