import {
  DamStream
} from "../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/DamStream";

export enum StreamState {
  available,
  obtaining,
  unavailable,
  error
}

export interface AvailableStream {
  state: StreamState.available
  current: MediaStream
  hasAudioTrack: boolean
  hasVideoTrack: boolean
  speaker: MediaDeviceInfo | null
}

export interface UndefinedStream {
  state: StreamState.obtaining | StreamState.unavailable
}

export interface ErrorStream {
  state: StreamState.error
  error: Error
}

/**
 * Stream with either audio track, video track or both when on available state
 */
export type Stream = AvailableStream | DamStream | UndefinedStream | ErrorStream

/**
 * Stream with guarantee audio track when on available state
 */
export type AudioStream =
  | (AvailableStream & { hasAudioTrack: true })
  | UndefinedStream
  | ErrorStream

/**
 * Stream with guarantee video track when on available state
 */
export type VideoStream =
  | (AvailableStream & { hasVideoTrack: true })
  | UndefinedStream
  | ErrorStream

export const UnavailableStream = {
  state: StreamState.unavailable
} as UndefinedStream

export const ObtainingStream = {
  state: StreamState.obtaining
} as UndefinedStream

export const errorStream = (error: Error): ErrorStream => {
  return {
    state: StreamState.error,
    error
  }
}

export const streamFrom = ({
  mediaStream,
  hasAudioTrack,
  hasVideoTrack,
  speaker
}: {
  mediaStream: MediaStream | null
  hasAudioTrack: boolean
  hasVideoTrack: boolean
  speaker: MediaDeviceInfo | null
}): Stream => {
  if (!mediaStream) {
    return UnavailableStream
  }
  return {
    state: StreamState.available,
    current: mediaStream,
    hasAudioTrack,
    hasVideoTrack,
    speaker
  }
}
