import useWebSocket from 'react-use-websocket'
import { WebSocketHook } from 'react-use-websocket/src/lib/types'
import { useEnvironment } from '../../Providers/EnvironmentProvider'

/**
 * Creates a web socket connection to the moderation service for a specific room
 *
 * @param   {string}  roomId  id of the room to connect to
 *
 * @param {function} filter filter to decide which messages to handle
 *
 * @return  {WebSocketHook}    web socket connection interface
 */
export const useModerationWebSocket = (roomId: string, filter: (message) => boolean) => {
  const environment = useEnvironment()
  const socketUrl = environment.moderationWebSocketUrl
  const queryParams = {
    roomId: roomId,
  };
  return useWebSocket(
    socketUrl,
    {
      queryParams,
      share: true,
      filter: filter,
      shouldReconnect: () => true,
  },);
};
