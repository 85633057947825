import React from 'react'
import { Typography, Box } from '@material-ui/core'
import AppleIcon from '@material-ui/icons/Apple'
import AppleTVLoginForm from './AppleTVLoginForm/AppleTVLoginForm'
import { useAppleTVLoginForm } from './hooks/useAppleTVLoginForm'
import { useLoginWithAppleTV } from './hooks/useLoginWithAppleTV'

interface AppleTVLoginProps {
  onSuccess?: () => void
  room: {
    id: string
    hash: string
  }
}

const AppleTVLogin: React.FC<AppleTVLoginProps> = ({ room, onSuccess }) => {
  const { isInProgress, performLoginWithAppleTV } = useLoginWithAppleTV(room.id, onSuccess)
  const { handleSubmit, inputs } = useAppleTVLoginForm({ performLoginWithAppleTV })

  return (
    <>
      <Box marginTop={1}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 'lighter'
          }}
        >
          <AppleIcon style={{ fontSize: '3.6rem' }} />
          Apple TV sign-in
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Typography variant="body2" align="center" gutterBottom style={{ fontWeight: 'lighter' }}>
          <strong>Step 1:</strong> Launch the Evercast app on your Apple TV.
        </Typography>
        <Typography variant="body2" align="center" gutterBottom style={{ fontWeight: 'lighter' }}>
          <strong>Step 2:</strong> Enter here the 5-digit code displayed on your TV screen.
        </Typography>
      </Box>
      <Box marginTop={4}>
        <AppleTVLoginForm inputs={inputs} onSubmit={handleSubmit} isInProgress={isInProgress} />
      </Box>
    </>
  )
}

export default React.memo(AppleTVLogin)
