import React, { useMemo, createContext, useContext } from 'react'
import { useEnvironment } from './EnvironmentProvider'
import { createRelayEnvironment } from '../Common/config/relayConfig'
import { Environment as RelayEnvironment } from 'react-relay'

const relayEnvironmentContext = createContext<RelayEnvironment>(null!)

export const RelayEnvironmentProvider: React.FC = ({ children }) => {
  const environment = useEnvironment()
  const relayEnvironment = useMemo(() => createRelayEnvironment(environment), [environment])
  return (
    <relayEnvironmentContext.Provider value={relayEnvironment}>
      {children}
    </relayEnvironmentContext.Provider>
  )
}

export const useRelayEnvironment = () => {
  return useContext(relayEnvironmentContext)
}
