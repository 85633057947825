import React, { useCallback } from 'react'
import { Box, MenuList, Popover } from '@material-ui/core'
import MenuItemWithSubMenu from '../../../../MenuItemWithSubMenu/MenuItemWithSubMenu'
import FontFamilyIcon from '../../../../assets/FontFamilyIcon'
import { SAFE_FONTS } from '../../../../../../../../../../../Common/constants/safeFontFamilies'
import { usePopover } from '../../../hooks/usePopover'
import { useDrawCanvasToolsContext } from '../../../../../../../../../../../Providers/DrawCanvasToolsProvider'

export const FontFamily: React.FC = () => {
  const { isOpen, anchorEl, handleClick, handleClose } = usePopover()
  const {
    drawPreferences: { drawFontFamily },
    setDrawFont
  } = useDrawCanvasToolsContext()

  const setDrawFontFamilyHandler = useCallback(
    (drawFontFamily) => () => {
      setDrawFont({ drawFontFamily })
      handleClose()
    },
    [handleClose, setDrawFont]
  )

  return (
    <div>
      <MenuItemWithSubMenu
        icon={<FontFamilyIcon />}
        label="Font family"
        style={{ fontFamily: drawFontFamily }}
        dense={true}
        onClick={handleClick}
        selected={isOpen}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className="customScroll"
      >
        <Box marginLeft={0.5}>
          <MenuList data-testid="font-family-fonts-selector">
            {SAFE_FONTS.map((font) => (
              <MenuItemWithSubMenu
                key={font}
                label={font}
                style={{ fontFamily: font }}
                onClick={setDrawFontFamilyHandler(font)}
                selected={drawFontFamily === font}
                dense={true}
              />
            ))}
          </MenuList>
        </Box>
      </Popover>
    </div>
  )
}

export default React.memo(FontFamily)
