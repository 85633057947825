import React, { useState, useEffect, useCallback } from 'react'
import CopyToClipboardInput from '../../CopyClipboardInput/CopyToClipboardInput'
import { useSpecialInvite } from './hooks/useSpecialInvite'
import { useSpecialInviteActivate } from './hooks/useSpecialInviteActivate'

interface RoomSpecialInviteInputProps {
  roomHash: string
  roomId: string
  toolTipDuration?: number
}

/**
 * An readonly input that generates a special invite code and appends it to the room url.
 * Allows the user to click in the Icon and copy the content to the clipboard.
 * @param roomHash Shareable content to be shown in the input and copied by the user
 * @param toolTipDuration Duration in miliseconds for the tooltip showing the content was copied
 */
const RoomSpecialInviteInput: React.FC<RoomSpecialInviteInputProps> = ({ roomId, roomHash }) => {
  const [roomAddress, setRoomAddress] = useState('')
  const [specialInviteCode, setSpecialInviteCode] = useState('')

  const generateInvite = useSpecialInvite(roomId, roomHash, setRoomAddress, setSpecialInviteCode)
  const activateSpecialInvite = useSpecialInviteActivate()

  const handleClicked = useCallback(async () => {
	if (specialInviteCode) {
		activateSpecialInvite(roomId, specialInviteCode)
	}

	generateInvite()
  }, [generateInvite, activateSpecialInvite, specialInviteCode, roomId])

  useEffect(() => {
		let active = true

		if (active && roomHash) {
			handleClicked()
		}

		return () => {
			active = false
		}
	}, [roomHash])

  return <div onClick={handleClicked}><CopyToClipboardInput content={roomAddress}/></div>
}

export default React.memo(RoomSpecialInviteInput)
