import { useCallback, useState, useEffect, useMemo } from 'react'
import { useEvercastAuthService, UnableToGetSSODetailException } from './useEvercastAuthService'
import { logger } from '../../../Common/log/Log'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../../Common/utils/formUtils'

/**
 * Handles presentation logic of SSO single sign-on form
 */
export function useSingleSignOnForm(errorMessage?: string) {
  const { getEvercastAuthService } = useEvercastAuthService()
  const alert = useAlerts()

  const [isLoading, setIsLoading] = useState(false)

  const showError = useCallback(
    (message) => {
      alert(message, AlertType.error)
    },
    [alert]
  )

  useEffect(() => {
    if (errorMessage) showError(errorMessage)
  }, [errorMessage, showError])

  const formMethods = useForm({ shouldFocusError: true })
  const { handleSubmit, formState, reset, clearErrors } = formMethods

  const { isSubmitted, isDirty, isValid } = formState

  const inputs = useFormValidation(formMethods, {
    email: {
      required: 'Please enter your email address',
      validate: (email) => {
        const emailValidationRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        if (!emailValidationRegex.test(email)) {
          return 'Please enter a valid email address'
        }
        return true
      }
    }
  })

  useEffect(() => {
    if (isDirty) {
      clearErrors()
    }
  }, [clearErrors, isDirty])

  useEffect(() => {
    if (isSubmitted && isValid) {
      reset(inputs.email, { errors: true })
    }
  }, [reset, isSubmitted, isValid, inputs])

  const _handleSubmit = useMemo(
    () =>
      handleSubmit(async ({ email }) => {
        setIsLoading(true)
        try {
          const evercastAuthService = await getEvercastAuthService(email)

          if (!evercastAuthService) throw new Error('EvercastAuthService instance is null')
          await evercastAuthService.logInFederated()
        } catch (error) {
          logger.error(error)

          if (error instanceof UnableToGetSSODetailException) {
            showError(error.message)
          } else {
            showError('Something went wrong. Please try again or contact support.')
          }

          // in case of error we remove the loading (success is redirected)
          setIsLoading(false)
        }
      }),
    [handleSubmit, showError, getEvercastAuthService]
  )

  return {
    handleSubmit: _handleSubmit,
    isLoading,
    inputs
  }
}
