import React, { ReactNode, useCallback } from 'react'
import { QueryRenderer as RelayQueryRenderer, GraphQLTaggedNode, Variables } from 'react-relay'
import { useRelayEnvironment } from '../Providers/RelayEnvironmentProvider'
import { logger } from '../Common/log/Log'

interface QueryRendererProps {
  query: GraphQLTaggedNode
  variables?: Variables
  renderLoading?: () => ReactNode
  renderError?: (error, retry) => ReactNode
  renderResult: (result, retry) => ReactNode
}

/**
 * Util component for rendering a query using current relay environment
 */
const QueryRenderer: React.FC<QueryRendererProps> = ({
  query,
  variables = {},
  renderResult,
  renderError,
  renderLoading
}) => {
  const relayEnvironment = useRelayEnvironment()

  return (
    <RelayQueryRenderer
      fetchPolicy={'store-and-network'}
      environment={relayEnvironment}
      query={query}
      variables={variables}
      render={useCallback(
        ({ error, props, retry }) => {
          if (error) {
            logger.error(error)

            return renderError ? renderError(error, retry) : null
          }

          if (props) {
            return renderResult(props, retry)
          }

          return renderLoading ? renderLoading() : null
        },
        [renderError, renderLoading, renderResult]
      )}
    />
  )
}

export default React.memo(QueryRenderer)
