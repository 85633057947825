import React from 'react'
import AuthenticatedView from './AuthenticatedView/AuthenticatedView'
import UnauthenticatedView from './UnauthenticatedView/UnauthenticatedView'
import NotFoundView from './NotFoundView/NotFoundView'
import { MainViewState, useMainView } from './hooks/useMainView'
import { AppFeatureFlagsProvider } from '../../Providers/AppFeatureFlagsProvider'
import BrowserNotSupported from '../../Components/BrowserNotSupported/BrowserNotSupported'
import { useCodeIntegrityMonitor } from '../../Common/hooks/useCodeIntegrityMonitor'
import { EvercastAuthProvider } from '../../Providers/EvercastAuthProvider'
import ErrorView from './ErrorView/ErrorView'
import AppleTVNotifyDialog from '../../Components/AppleTVLogin/AppleTVNotifyDialog/AppleTVNotifyDialog'
import LandingView from './LandingView/LandingView'
import MainLayout from './MainLayout/MainLayout'
import DesktopAppLaunchView from './DesktopAppLaunchView/DesktopAppLaunchView'

/**
 * Main entry point for application views.
 */
const MainView: React.FC = () => {
  const {
    state,
    userProfile,
    appFeatureFlags,
    ssoDetails,
    browserCapabilities,
    login,
    logout,
    dismissNativeAppLaunch,
    retryNativeAppLaunch
  } = useMainView()
  useCodeIntegrityMonitor()

  const renderMainContent = () => {
    if (state === MainViewState.nativeAppDetected) {
      return (
        <DesktopAppLaunchView
          continueToWebAppCallback={dismissNativeAppLaunch}
          retryNativeAppLaunch={retryNativeAppLaunch}
        />
      )
    }
    if (state === MainViewState.authenticated) {
      return (
        <>
          <AuthenticatedView userProfile={userProfile!} onLogout={logout} />
          <AppleTVNotifyDialog />
        </>
      )
    }
    if (state === MainViewState.error) {
      return (
        <LandingView>
          <ErrorView />
        </LandingView>
      )
    }
    if (state === MainViewState.unauthenticated) {
      return (
        <LandingView showNewUserActions>
          <UnauthenticatedView onLogin={login} />
        </LandingView>
      )
    }
    if (state === MainViewState.notFound) {
      return (
        <LandingView>
          <NotFoundView />
        </LandingView>
      )
    }
    if (state === MainViewState.unsupportedBrowser) {
      return (
        <LandingView>
          <BrowserNotSupported
            browserType={browserCapabilities.browserType}
            isSupported={browserCapabilities.isSupported}
          />
        </LandingView>
      )
    }
    return null
  }

  return (
    <AppFeatureFlagsProvider featureFlags={appFeatureFlags}>
      <EvercastAuthProvider ssoDetails={ssoDetails}>
        <MainLayout mainViewState={state}>{renderMainContent()}</MainLayout>
      </EvercastAuthProvider>
    </AppFeatureFlagsProvider>
  )
}

export default React.memo(MainView)
