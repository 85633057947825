/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingsView_currentProfile = {
    readonly id: unknown;
    readonly displayName: string;
    readonly " $refType": "SettingsView_currentProfile";
};
export type SettingsView_currentProfile$data = SettingsView_currentProfile;
export type SettingsView_currentProfile$key = {
    readonly " $data"?: SettingsView_currentProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"SettingsView_currentProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsView_currentProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": null
};
(node as any).hash = '7530e1760868abee6ac98ad6ed28a325';
export default node;
