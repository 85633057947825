import { MessageType } from '../../plugin/JanusClient'
import { VideoQuality } from '../../../video/videoQualityOptions'
import { BaseSharePublisher } from './BaseSharePublisher'

export const VIDEO_CODECS = 'vp8,vp9,h264'
export const AUDIO_CODECS = 'multiopus,opus'
export const MAX_PARTICIPANTS = 10
export const FIR_FREQUENCY = 10 // this
export const SCREENSHARE_FRAME_RATE = 30

/**
 * Screenshare is treated as another liveroom user, with the difference that it doesn’t care about seeing/hearing other participants
 */
export class ScreensharePublisher extends BaseSharePublisher {
  constructor({ janusSignallingConnection, roomId, roomHash, userId, displayName }) {
    super({ janusSignallingConnection, roomId, roomHash, userId, displayName, tag: 'Screenshare' })
  }

  /**
   * Publish media for sharescreen 'user'.
   * @param videoQuality - videoQuality used in the screenshare. Choosen in Screenshare options dialog.
   * @param stream - stream used in the screenshare. Choosen in Screenshare getMediaStream dialog.
   */
  innerPublish = async ({ video, stream }: { video: VideoQuality, stream: MediaStream }) => {
    const jsep = await this.janusClient.createOffer({
      customizeSdp: (jsepToCustomize) => {
        console.log(`[${this.tag}] Customizing SDP for screenshare`, jsepToCustomize.sdp)
      },
      media: {
        video: 'screen', // responsible for screenshare
        screenshareOptions: { video },
        audio: false,
        stereo: true,
        audioRecv: false,
        videoRecv: false,
        audioSend: false,
        videoSend: true,
        screenshareFrameRate: SCREENSHARE_FRAME_RATE
      },
      stream,
    })

    await this.janusClient.sendMessage({
      message: {
        request: MessageType.configure,
        audio: false,
        video: true,
        bitrate: video.bitrate,
        record: false
      },
      jsep
    })
  }

  protected sendStopMessage = async () => {
    const screenshareStop = {
      id: this.userId,
      request: MessageType.stopScreenshare,
      room: this.roomId,
      roomHash: this.roomHash
    }
    await this.janusClient.sendMessage({
      message: screenshareStop
    })
  }
}
