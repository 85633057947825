/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateLiveroomByIdInput = {
    clientMutationId?: string | null;
    liveroomPatch: LiveroomPatch;
    id: unknown;
};
export type LiveroomPatch = {
    id?: unknown | null;
    creatorId?: unknown | null;
    displayName?: string | null;
    isPublic?: boolean | null;
    hash?: unknown | null;
    createdAt?: unknown | null;
    updatedAt?: unknown | null;
    deletedAt?: unknown | null;
    organizationId?: unknown | null;
    isActive?: boolean | null;
    revenueType?: string | null;
    revenueRate?: number | null;
    serverAffinity?: string | null;
    useNewManager?: boolean | null;
    serverRegion?: string | null;
    roomLock?: boolean | null;
};
export type UpdateRoomMutationVariables = {
    input: UpdateLiveroomByIdInput;
};
export type UpdateRoomMutationResponse = {
    readonly updateLiveroomById: {
        readonly clientMutationId: string | null;
        readonly liveroom: {
            readonly id: unknown;
            readonly hash: unknown;
            readonly displayName: string;
            readonly isPublic: boolean;
        } | null;
        readonly profileByCreatorId: {
            readonly id: unknown;
            readonly displayName: string;
        } | null;
    } | null;
};
export type UpdateRoomMutation = {
    readonly response: UpdateRoomMutationResponse;
    readonly variables: UpdateRoomMutationVariables;
};



/*
mutation UpdateRoomMutation(
  $input: UpdateLiveroomByIdInput!
) {
  updateLiveroomById(input: $input) {
    clientMutationId
    liveroom {
      id
      hash
      displayName
      isPublic
    }
    profileByCreatorId {
      id
      displayName
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLiveroomPayload",
    "kind": "LinkedField",
    "name": "updateLiveroomById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Liveroom",
        "kind": "LinkedField",
        "name": "liveroom",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hash",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPublic",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "profileByCreatorId",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRoomMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRoomMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b82a6cfd110711662ff0dee6b9b26857",
    "id": null,
    "metadata": {},
    "name": "UpdateRoomMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRoomMutation(\n  $input: UpdateLiveroomByIdInput!\n) {\n  updateLiveroomById(input: $input) {\n    clientMutationId\n    liveroom {\n      id\n      hash\n      displayName\n      isPublic\n    }\n    profileByCreatorId {\n      id\n      displayName\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5ea52206bfecc3865f09e07f0926d864';
export default node;
