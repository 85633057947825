import './ThumbnailConnectivity.scss'
import React from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ThumbnailData } from '../ThumbnailData/ThumbnailData'

interface ThumbnailConnectivityProps {
  downloadBitRate?: string
  uploadBitRate?: string
  rtt?: string
  jitter?: string
  platform?: string
  operatingSystem?: string
  close: () => void
}

export const ThumbnailConnectivity = (props: ThumbnailConnectivityProps) => {
  const { downloadBitRate, uploadBitRate, rtt, jitter, platform, operatingSystem, close } = props
  return (
    <div className="thumbnail-connectivity-stats">
      <IconButton className="tcs-close" size="small" onClick={close}>
        <CloseIcon />
      </IconButton>
      <div className="tcs-item">DOWNLOAD SPEED: </div>
      <ThumbnailData data={downloadBitRate} />
      <div className="tcs-item">UPLOAD SPEED: </div>
      <ThumbnailData data={uploadBitRate} />
      <div className="tcs-item">ROUND TRIP TIME: </div>
      <ThumbnailData data={rtt} />
      <div className="tcs-item">JITTER: </div>
      <ThumbnailData data={jitter} />
      <div className="tcs-item">PLATFORM: </div>
      <ThumbnailData data={platform} />
      <div className="tcs-item">SYSTEM: </div>
      <ThumbnailData data={operatingSystem} />
    </div>
  )
}
