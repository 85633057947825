import { useState, useCallback } from 'react'

export const useRecordingsTab = () => {
  const [recordingsOption, setRecordingsOption] = useState(true)

  const handleRecordingsOptionChange = useCallback(
    (option) => {
      setRecordingsOption(option)
    },
    [setRecordingsOption]
  )

  return {
    recordingsOption,
    handleRecordingsOptionChange
  }
}
