import { differenceInMilliseconds } from 'date-fns'
import { useMemo } from 'react'
import { useEffectWithPredicate } from '../../../../../../Common/hooks/useEffectWithPredicate'
import { useRerender } from '../../../../../../Common/hooks/useRerender'
import { RoomSession } from '../../../../../../Models/apiEntities'
import { PlaybackState } from './usePlaybackController'

interface usePlaybackTimingParams {
  playbackState: PlaybackState
  session: RoomSession
  startTimeOffset: number
  offset: number
  resumedTime: Date | null
  stop: () => void
}

const seekTimeUpdatingInterval = 50

export const usePlaybackTiming = ({
  playbackState,
  session,
  startTimeOffset,
  offset,
  resumedTime,
  stop
}: usePlaybackTimingParams) => {
  const nowTime = new Date()
  const rerender = useRerender()

  // When state is playing force a rerender to update seekbar
  useEffectWithPredicate(
    {
      predicate: () => playbackState === PlaybackState.playing,
      effect: () => {
        const interval = setInterval(rerender, seekTimeUpdatingInterval)
        return () => clearInterval(interval)
      }
    },
    [playbackState]
  )

  const duration = useMemo(
    () => differenceInMilliseconds(new Date(session.stoppedAt), new Date(session.startedAt)),
    [session]
  )

  const currentTime = useMemo(() => {
    if (playbackState === PlaybackState.playing) {
      const timeSinceOffset = resumedTime ? nowTime.getTime() - resumedTime.getTime() : 0
      return Math.min(offset + timeSinceOffset, duration)
    }
    if (playbackState === PlaybackState.paused) {
      return offset
    }
    if (playbackState === PlaybackState.ended) {
      return duration
    }
    return startTimeOffset
  }, [nowTime, playbackState, duration, offset, resumedTime, startTimeOffset])

  useEffectWithPredicate(
    {
      predicate: () => currentTime === duration,
      effect: () => {
        stop()
      }
    },
    [currentTime, duration, stop]
  )

  return {
    duration: duration / 1000,
    currentTime: currentTime / 1000
  }
}
