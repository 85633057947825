import { EnvName } from './environments'

/**
 * Reads the REACT_APP_ENV and converts it to {@link EnvName}.
 * If environment is not set, application will fail to avoid deploying wrongly set environment to production.
 */
export const getEnvName = () => {
  let environment: EnvName

  const hostname = window.location.hostname ?? ''
  if (hostname.startsWith('dev')) {
    environment = EnvName.dev
  } else if (hostname.startsWith('app')) {
    environment = EnvName.prod
  } else if (hostname.startsWith('lite')) {
    environment = EnvName.lite
  } else if (hostname.startsWith('alpha')) {
    environment = EnvName.alpha
  } else if (hostname.startsWith('beta')) {
    environment = EnvName.beta
  } else if (hostname.startsWith('kite')) {
    environment = EnvName.kite
  } else if (hostname.startsWith('stage')) {
    environment = EnvName.stage
  } else if (hostname.startsWith('qa')) {
    environment = EnvName.qa
  } else {
    environment = EnvName.local
  }

  return environment
}
