import React, { useState } from 'react'
import LoginForm, { LoginState } from '../../../../Components/LoginForm/LoginForm'
import { useLoginAction } from '../../../../Components/LoginForm/hooks/useLoginAction'
import { UserProfile } from '../../../../Models/apiEntities'

export interface LoginViewProps {
  onLogin: (userProfile: UserProfile) => void
}

/**
 * Evercast login view accessible at {@link routes.login.path}
 * @param onLogin
 * @constructor
 */
const LoginView: React.FC<LoginViewProps> = ({ onLogin }) => {
  const [, setIsTwoFactorStarted] = useState(false)
  const [state, setState] = useState(LoginState.login)
  useLoginAction({ onLogin, setState })

  return (
    <LoginForm
      onLogin={onLogin}
      onTwoFactorStarted={() => setIsTwoFactorStarted(true)}
      onTwoFactorCanceled={() => setIsTwoFactorStarted(false)}
      loginState={[state, setState]}
    />
  )
}

export default React.memo(LoginView)
