import { useState } from 'react'
import { useAlerts } from '../../../Providers/AlertsProvider'
import { AlertType } from '../../Alert/Alert'
import { logger } from '../../../Common/log/Log'

export const useDoNotDisturb = (room, sendJsonMessage: (jsonMessage: any, keep?: boolean) => void) => {
  const [isInProgress, setIsInProgress] = useState(false)
  const [roomLock, setRoomLock] = useState(room.roomLock)
  const [participants, setParticipants] = useState<{ profileId: string }[]>([])
  const alert = useAlerts()

  const onConfirmDnd = async (roomId: string, roomLock: boolean) => {
    setIsInProgress(true)
    try {
      const message = {
        type: "dnd",
        action: roomLock ? "stopLockdown" : "startLockdown",
        timestamp: new Date().toISOString()
      }
      sendJsonMessage(message)
      setRoomLock(!roomLock)
    } catch (e) {
      alert('Oops, there was an error updating the Do not Disturb status for the room', AlertType.error)
      logger.error(e)
    } finally {
      setIsInProgress(false)
    }
  }

  const onDndUpdated = (roomLock: boolean) => {
    setRoomLock(roomLock)
  }
  
  const approveAccess = (profileId, roomId) => {
    const message = {
      type: "dnd",
      action: "approve",
      roomId,
      data: {
        profileId,
      },
      timestamp: new Date().toISOString()
    }
    sendJsonMessage(message)
    removeParticipant(profileId)
  }

  const denyAccess = (profileId, roomId) => {
    const message = {
      type: "dnd",
      action: "deny",
      roomId,
      data: {
        profileId,
      },
      timestamp: new Date().toISOString()
    }

    sendJsonMessage(message)
    removeParticipant(profileId)
  }
  
  const addParticipant = (participant) => {
    if (!participants.some(p => p.profileId === participant.profileId)) {
      setParticipants((prevParticipants) => ([
        ...prevParticipants,
        participant
      ]))
    }
  }
  
  const removeParticipant = (profileId) => {
    if (participants.some(p => p.profileId === profileId)) {
      setParticipants((prevParticipants) => (
        prevParticipants.filter(p => p.profileId !== profileId)
      ))
    }
  }
  
  return {
    roomLock,
    isInProgress,
    onConfirmDnd,
    onDndUpdated,
    approveAccess,
    denyAccess,
    addParticipant,
    removeParticipant,
    participants
  }
}
