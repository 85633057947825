import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'

import { Box, useTheme, Grow } from '@material-ui/core'

export enum AlertType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success'
}

interface AlertProps {
  alertType: AlertType
}

const alertTypeIcons = {
  [AlertType.info]: InfoIcon,
  [AlertType.warning]: WarningIcon,
  [AlertType.success]: CheckCircleIcon,
  [AlertType.error]: ErrorIcon
}

/**
 * Alert box with rounded corners of a given type
 * @param alertType   [info, warning, success, error]
 * @param children    alert content
 */
const Alert: React.FC<AlertProps> = ({ alertType, children }) => {
  const theme = useTheme()
  const Icon = alertTypeIcons[alertType]
  return (
    <Grow in={true}>
      <Box
        className={`alert-${alertType}`}
        padding={1.5}
        borderRadius={25}
        alignItems={'center'}
        justifyContent={'center'}
        display={'flex'}
        zIndex={9999}
        bgcolor={theme.palette[alertType].dark}
        color={theme.palette.text.primary}
      >
        <Icon style={{ marginRight: theme.spacing() }} />
        {children}
      </Box>
    </Grow>
  )
}

export default Alert
