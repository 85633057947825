/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateSessionInput = {
    clientMutationId?: string | null;
    session: SessionInput;
};
export type SessionInput = {
    id?: unknown | null;
    roomId: unknown;
    displayName: string;
    createdAt?: unknown | null;
    startedAt: unknown;
    stoppedAt?: unknown | null;
    recordingId: string;
    deletedAt?: unknown | null;
    isPublic?: boolean | null;
    profileId?: unknown | null;
};
export type CreateSessionMutationVariables = {
    input: CreateSessionInput;
    roomId: unknown;
};
export type CreateSessionMutationResponse = {
    readonly createSession: {
        readonly clientMutationId: string | null;
        readonly session: {
            readonly id: unknown;
            readonly recordingId: string;
        } | null;
        readonly query: {
            readonly liveroomById: {
                readonly sessionsByRoomId: {
                    readonly nodes: ReadonlyArray<{
                        readonly id: unknown;
                        readonly displayName: string;
                        readonly createdAt: unknown;
                        readonly stoppedAt: unknown | null;
                        readonly recordingId: string;
                    } | null>;
                };
            } | null;
        } | null;
    } | null;
};
export type CreateSessionMutation = {
    readonly response: CreateSessionMutationResponse;
    readonly variables: CreateSessionMutationVariables;
};



/*
mutation CreateSessionMutation(
  $input: CreateSessionInput!
  $roomId: UUID!
) {
  createSession(input: $input) {
    clientMutationId
    session {
      id
      recordingId
    }
    query {
      liveroomById(id: $roomId) {
        sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {
          nodes {
            id
            displayName
            createdAt
            stoppedAt
            recordingId
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roomId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recordingId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateSessionPayload",
    "kind": "LinkedField",
    "name": "createSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Session",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "roomId"
              }
            ],
            "concreteType": "Liveroom",
            "kind": "LinkedField",
            "name": "liveroomById",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": [
                      "CREATED_AT_DESC"
                    ]
                  }
                ],
                "concreteType": "SessionsConnection",
                "kind": "LinkedField",
                "name": "sessionsByRoomId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Session",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stoppedAt",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "sessionsByRoomId(orderBy:[\"CREATED_AT_DESC\"])"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSessionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSessionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "bb8ca1c7378b618f05f6bfaa7abbad76",
    "id": null,
    "metadata": {},
    "name": "CreateSessionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSessionMutation(\n  $input: CreateSessionInput!\n  $roomId: UUID!\n) {\n  createSession(input: $input) {\n    clientMutationId\n    session {\n      id\n      recordingId\n    }\n    query {\n      liveroomById(id: $roomId) {\n        sessionsByRoomId(orderBy: [CREATED_AT_DESC]) {\n          nodes {\n            id\n            displayName\n            createdAt\n            stoppedAt\n            recordingId\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4077d350c33e97e40cbdb652746f10e0';
export default node;
