import { Grid, Button } from '@material-ui/core'
import React, { useContext } from 'react'
import Formset from '../../../../Components/LoginForm/Formset'
import { ReactComponent as CompanyIcon } from '../../../../Static/images/company.svg'
import { ReactComponent as MicrosoftIcon } from '../../../../Static/images/microsoft.svg'
import { ReactComponent as AppleIcon } from '../../../../Static/images/apple-2.svg'
import { ReactComponent as GoogleIcon } from '../../../../Static/images/google-2.svg'
import { useLoginForm } from '../../../../Components/LoginForm/hooks/useLoginForm'
import { useLoginAction } from '../../../../Components/LoginForm/hooks/useLoginAction'
import { UnauthenticatedViewContext } from '../hooks/UnaunthentciatedViewContext'
import { Link } from 'react-router-dom'
import { routes } from '../routes'

const SSOLockup = (props) => {
  const { handleEnterKey, header, onLogin, setState, redirectToLogin, LoginState } = props
  const { setSignUpRedirect } = useContext(UnauthenticatedViewContext)

  const {
    actions: { performAuthentication },
  } = useLoginAction({ onLogin })

  const { handleAppleSubmit, handleGoogleSubmit, handleMicrosoftSubmit } = useLoginForm({
    performAuthentication,
  })

  return (
    <Grid container spacing={3} onKeyUp={handleEnterKey}>
      <Grid item xs={12}>
        <Formset>
          <legend>{header ? header : 'Or continue with'}</legend>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                className="social-icon google"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSubmit}
              >
                <span>Google</span>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                className="social-icon apple"
                startIcon={<AppleIcon />}
                onClick={handleAppleSubmit}
              >
                <span>Apple ID</span>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                className="social-icon microsoft"
                startIcon={<MicrosoftIcon />}
                onClick={handleMicrosoftSubmit}
              >
                <span>Microsoft 365</span>
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              {redirectToLogin ? (
                <Link to={routes.login.path} onClick={() => setSignUpRedirect(true)}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    className="social-icon company-sso"
                    startIcon={<CompanyIcon />}
                  >
                    <span>Company SSO</span>
                  </Button>
                </Link>
              ) : (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  className="social-icon company-sso"
                  startIcon={<CompanyIcon />}
                  onClick={() => setState(LoginState.sso)}
                >
                  <span>Company SSO</span>
                </Button>
              )}
            </Grid>
          </Grid>
        </Formset>
      </Grid>
    </Grid>
  )
}

export default SSOLockup
