import { useMemo } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import { routes } from '../../../routes'
import { tabsValues } from '../../../AccountView/AccountView'

export enum ConfigurationMessageState {
  CODE_SUCESS,
  TOKEN_EXPIRED,
}

const ConfigurationProps = {
  [ConfigurationMessageState.CODE_SUCESS]: {
    title: 'Success!',
    description: 'Your multi-factor authentication is now set up.',
    icon: CheckCircleIcon,
    routeTo: routes.dashboard.path,
    routeTextAction: 'Home',
    buttonText: 'Back to home',
  },
  [ConfigurationMessageState.TOKEN_EXPIRED]: {
    title: 'Token Expired!',
    description:
      'To get a new token, click the multi-factor authentication link on the account settings page.',
    icon: ErrorIcon,
    routeTo: routes.account.path.replace(':tab', tabsValues.settings),
    routeTextAction: 'settings',
    buttonText: 'Back to settings',
  },
}

export const useTwoFactorConfigurationMessageProps = (state: ConfigurationMessageState) => {
  const configurationProps = useMemo(() => ConfigurationProps[state], [state])

  return { configurationProps }
}
