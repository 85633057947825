import { useMemo } from 'react'
import { useDialog } from '../../../../../../../Components/RoomDialog/hooks/useDialog'

const useDialogControl = () => {
  const [isOpen, open, close] = useDialog()
  return useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [isOpen, open, close]
  )
}

export const useLiveroomDialogs = () => {
  const recordingDialog = useDialogControl()
  const roomSettingsDialog = useDialogControl()
  const roomAccessDialog = useDialogControl()
  const screenShareDialog = useDialogControl()
  const ebsKeysDialog = useDialogControl()
  const browserStreamDialog = useDialogControl()
  const appleTVLoginDialog = useDialogControl()
  const audioMixerDialog = useDialogControl()
  const userMutedDialog = useDialogControl()
  const networkConnectivityDialog = useDialogControl()
  const dndDialog = useDialogControl()

  return {
    recordingDialog,
    roomSettingsDialog,
    roomAccessDialog,
    screenShareDialog,
    ebsKeysDialog,
    browserStreamDialog,
    appleTVLoginDialog,
    audioMixerDialog,
    userMutedDialog,
    networkConnectivityDialog,
    dndDialog,
  }
}
